export const getPages = (length, max) => {
  const x = Math.ceil(length / max);
  return [...Array(x > 0 ? x : 1).keys()];
};

export const paginationMAX = 25;

export const paginationOptions = [
  { value: paginationMAX.toString(), id: paginationMAX },
  { value: (paginationMAX * 2).toString(), id: paginationMAX * 2 },
  { value: (paginationMAX * 4).toString(), id: paginationMAX * 4 },
];

export const getInitialData = (arr, max) => {
  return {
    length: arr.length || 0,
    records: arr.slice(0, max) || [],
  };
};

export const orderByPage = (arr, max, page) => {
  const arrBef = max * page - max;
  const arrAft = max * page;
  return arr.slice(arrBef, arrAft);
};
