/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import './styles.scss';
import Title from '../../../../../components/Title/index';
import SubTitle from '../../../../../components/SubTitle/index';
import Paragraph from '../../../../../components/Paragraph/index';
import Button from '../../../../../components/Button/index';
import {
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
} from '../../../../../types/index';

type Props = {
  history: Object,
  questionnaireContext: QuestionnaireExperienceContext,
  handleRedirect: () => void,
};

// Export this for unit testing more easily
export class Instructions extends PureComponent {
  props: Props;

  static defaultProps: {
    history: {},
  };

  render() {
    const { questionnaireContext } = this.props;
    const noticeParagraph = `Please note that in addition to this electronic Signature Package, we will also require an IRS Form W-9 (for U.S. investors) or the applicable IRS Form W-8 (for non-U.S. investors) to be submitted. Please send your tax form separately to 
    ${questionnaireContext.fund.taxFormsEmail
        ? questionnaireContext.fund.taxFormsEmail
        : 'Tax Forms Email'}.`;
    const generalnoticeParagraph =
      'THE LIMITED PARTNERSHIP INTERESTS REFERRED TO IN THIS INVESTOR QUESTIONNAIRE (THE “SECURITIES”) HAVE NOT BEEN REGISTERED UNDER THE UNITED STATES SECURITIES ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”), OR THE SECURITIES LAWS OF ANY OTHER STATE OR JURISDICTION. THE SECURITIES MAY NOT BE SOLD, OFFERED FOR SALE, PLEDGED OR HYPOTHECATED IN THE ABSENCE OF A REGISTRATION STATEMENT IN EFFECT WITH RESPECT TO SUCH INTERESTS UNDER THE SECURITIES ACT OR AN EXEMPTION THEREFROM. INVESTORS SHOULD BE AWARE THAT THEY WILL BE REQUIRED TO BEAR THE FINANCIAL RISKS OF THIS INVESTMENT FOR AN INDEFINITE PERIOD OF TIME. IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR OWN EXAMINATION OF THE FUND AND THE TERMS OF THE OFFERING, INCLUDING THE MERITS AND RISKS INVOLVED. INVESTORS ARE ENCOURAGED TO SEEK INDEPENDENT LEGAL, INVESTMENT AND TAX ADVICE REGARDING INDIVIDUAL CIRCUMSTANCES AND FINANCIAL OBJECTIVES IN DETERMINING WHETHER TO INVEST IN THE FUND. THE SECURITIES HAVE NOT BEEN APPROVED OR DISAPPROVED BY ANY FEDERAL OR STATE SECURITIES COMMISSION OR REGULATORY AUTHORITY NOR HAVE THE FOREGOING AUTHORITIES PASSED UPON THE ACCURACY OR ADEQUACY OF THE OFFERING MATERIALS. ANY REPRESENTATION TO THE CONTRARY IS A CRIMINAL OFFENSE.';
    const nonUsResidentNotice =
      'EXCEPT AS OTHERWISE INDICATED, NO ACTION HAS BEEN OR WILL BE TAKEN IN ANY JURISDICTION OUTSIDE THE UNITED STATES OF AMERICA THAT WOULD PERMIT AN OFFERING OF THESE SECURITIES, OR POSSESSION OR DISTRIBUTION OF OFFERING MATERIALS IN CONNECTION WITH THE ISSUE OF THESE SECURITIES, IN ANY COUNTRY OR JURISDICTION WHERE ACTION FOR THAT PURPOSE IS REQUIRED. IT IS THE RESPONSIBILITY OF ANY PERSON WISHING TO PURCHASE THESE SECURITIES TO SATISFY HIMSELF AS TO FULL OBSERVANCE OF THE LAWS OF ANY RELEVANT TERRITORY OUTSIDE THE UNITED STATES OF AMERICA IN CONNECTION WITH ANY SUCH PURCHASE, INCLUDING OBTAINING ANY REQUIRED GOVERNMENTAL OR OTHER CONSENTS OR OBSERVING ANY OTHER APPLICABLE FORMALITIES.';
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="content-bg main-content-area clearfix">
            <div className="row instructions-container">
              <Title text={questionnaireContext.fund.fundLegalName} />
              <Title text={'Signature Package'} />
              <br />
              <SubTitle isLink={false} text={'INSTRUCTIONS'} underline />
                <div>
                 <b>
                <Paragraph text={`IN ORDER TO INVEST IN ${questionnaireContext.fund.fundLegalName.toUpperCase()} (THE “FUND”), YOU MUST CAREFULLY COMPLETE THIS INVESTOR QUESTIONNAIRE BY COMPLETING THE GENERAL INFORMATION REQUIRED UNDER PART I, CHECKING THE APPROPRIATE BOXES IN PART II AND COMPLETING THE INFORMATION ON THE SIGNATURE PAGE. PLEASE RETURN THE COMPLETED QUESTIONNAIRE TO:`} />
                <br />
                <div style={{ textAlign: 'center' }}>
                  <p>Gunderson Dettmer <br />
                  550 Allerton Street <br />
                  Redwood City, CA 94063 <br />
                  Email: AccelFundsTeam@gunder.com</p>
                </div>
                 <br />
                <Paragraph text={`YOU MAY ALSO NEED TO PROVIDE ADDITIONAL “KNOW YOUR CLIENT” INFORMATION TO
                  THE FUND’S COMPLIANCE AGENT. ACCEL AND ALTER DOMUS WILL CONTACT YOU
                  DIRECTLY TO SET OUT WHAT INFORMATION WILL BE REQUIRED AND TO UNDERTAKE THE
                  COLLATION AND REVIEW OF THIS INFORMATION.`} />
                </b>
              <br />
             </div>
              <SubTitle isLink={false} text={'GENERAL NOTICE'} underline />
              <Paragraph text={generalnoticeParagraph} />
              <br />
              <div className={"text-center bigger-text"} >  
                <SubTitle text={'FOREIGN INVESTOR NOTICES'}  underline />
              </div>
              <SubTitle isLink={false} text={'NOTICE TO NON-UNITED STATES RESIDENTS'} underline />
              <Paragraph text={`NO ACTION HAS BEEN OR WILL BE TAKEN IN ANY JURISDICTION OUTSIDE THE UNITED
                STATES OF AMERICA THAT WOULD PERMIT AN OFFERING OF THESE SECURITIES, OR
                POSSESSION OR DISTRIBUTION OF OFFERING MATERIALS IN CONNECTION WITH THE ISSUE
                OF THESE SECURITIES, IN ANY COUNTRY OR JURISDICTION WHERE ACTION FOR THAT
                PURPOSE IS REQUIRED. IT IS THE RESPONSIBILITY OF ANY PERSON WISHING TO PURCHASE
                THESE SECURITIES TO SATISFY HIMSELF OR HERSELF AS TO FULL OBSERVANCE OF THE
                LAWS OF ANY RELEVANT TERRITORY OUTSIDE THE UNITED STATES OF AMERICA IN
                CONNECTION WITH ANY SUCH PURCHASE, INCLUDING OBTAINING ANY REQUIRED
                GOVERNMENTAL OR OTHER CONSENTS OR OBSERVING ANY OTHER APPLICABLE
                FORMALITIES.`} />
                 <br />
              <SubTitle isLink={false} text={'NOTICE TO RECIPIENTS IN THE EUROPEAN ECONOMIC AREA (EEA)'} underline />
              <Paragraph text={`THE FUND HAS NOT BEEN REGISTERED FOR MARKETING FOR THE PURPOSES OF THE EU
                ALTERNATIVE INVESTMENT FUND MANAGERS DIRECTIVE (AIFMD) IN ANY JURISDICTION IN
                THE EEA, COMPRISING THE EUROPEAN UNION, ICELAND, LIECHTENSTEIN AND NORWAY.
                THE DISTRIBUTION OF THIS DOCUMENT AND ANY OTHER MATERIAL RELATING TO THE
                FUND TO RECIPIENTS IN THE EEA IS ONLY PERMITTED IN RESPONSE TO THEIR EXPRESS
                REQUEST IN CIRCUMSTANCES THAT DO NOT CONSTITUTE MARKETING AS THAT TERM IS
                UNDERSTOOD UNDER AIFMD.
                `} />
                 <br />
              <SubTitle isLink={false} text={'NOTICE TO RECIPIENTS IN THE UNITED KINGDOM'} underline />
              <Paragraph text={`THIS DOCUMENT IS FOR DISTRIBUTION ONLY TO PERSONS WHO (I) HAVE PROFESSIONAL
                EXPERIENCE IN MATTERS RELATING TO INVESTMENTS FALLING WITHIN ARTICLE 19(5) OF
                THE FINANCIAL SERVICES AND MARKETS ACT 2000 (FINANCIAL PROMOTION) ORDER 2005 (AS
                AMENDED, THE “FINANCIAL PROMOTION ORDER”), (II) ARE PERSONS FALLING WITHIN
                ARTICLE 49(2)(A) TO (D) (“HIGH NET WORTH COMPANIES, UNINCORPORATED ASSOCIATIONS
                ETC”) OF THE FINANCIAL PROMOTION ORDER, OR (III) ARE PERSONS TO WHOM AN
                INVITATION OR INDUCEMENT TO ENGAGE IN INVESTMENT ACTIVITY (WITHIN THE
                MEANING OF SECTION 21 OF THE FINANCIAL SERVICES AND MARKETS ACT 2000) IN
                CONNECTION WITH THE ISSUE OR SALE OF ANY SECURITIES MAY OTHERWISE LAWFULLY
                BE COMMUNICATED OR CAUSED TO BE COMMUNICATED (ALL SUCH PERSONS TOGETHER
                BEING REFERRED TO AS “RELEVANT PERSONS”). THIS DOCUMENT IS DIRECTED ONLY AT
                RELEVANT PERSONS AND MUST NOT BE ACTED ON OR RELIED ON BY PERSONS WHO ARE
                NOT RELEVANT PERSONS. ANY INVESTMENT OR INVESTMENT ACTIVITY TO WHICH THIS
                DOCUMENT RELATES IS AVAILABLE ONLY TO RELEVANT PERSONS AND WILL BE ENGAGED
                IN ONLY WITH RELEVANT PERSONS.
                `} />
              <Paragraph text={`NO PROSPECTUS IS REQUIRED UNDER SECTION 85 OF THE FINANCIAL SERVICES AND
                MARKETS ACT 2000, AND ANY OFFER OF SECURITIES WILL BE:
                `} />
              <Paragraph text={`LIMITED TO FEWER THAN 100, OR, IF THE EEA STATE IN QUESTION HAS IMPLEMENTED
                THE RELEVANT PROVISION OF THE 2010 PD AMENDING DIRECTIVE, 150, PERSONS IN ANY
                EEA STATE, IN ADDITION TO QUALIFIED INVESTORS (AS DEFINED IN ARTICLE 2.1 OF THE
                EC PROSPECTUS DIRECTIVE); AND/OR MADE ON THE BASIS THAT THE MINIMUM
                CONSIDERATION PAYABLE BY ANY INVESTOR IN THE FUND WILL BE NOT LESS THAN
                €50,000, OR, IF THE EEA STATE IN QUESTION HAS IMPLEMENTED THE RELEVANT
                PROVISION OF THE 2010 PD AMENDING DIRECTIVE, €100,000, (OR, IN EACH CASE,
                EQUIVALENT AMOUNT),`} />
              <Paragraph text={`AND FOR THE PURPOSES OF THIS PROVISION THE EXPRESSION “EC PROSPECTUS
                DIRECTIVE” MEANS DIRECTIVE 2003/71/EC (AND AMENDMENTS THERETO, INCLUDING
                THE 2010 PD AMENDING DIRECTIVE, TO THE EXTENT IMPLEMENTED IN EEA STATES),
                AND INCLUDES ANY RELEVANT IMPLEMENTING MEASURE IN THE EEA STATE IN
                QUESTION, AND THE EXPRESSION “2010 PD AMENDING DIRECTIVE” MEANS DIRECTIVE
                2010/73/EU.`} />
                <br />
              <SubTitle isLink={false} text={'NOTICE TO RECIPIENTS GENERALLY'} underline />
              <Paragraph text={`THIS DOCUMENT, AS WELL AS ANY SUBSEQUENT SOLICITATION RELATED TO ANY
                INVESTMENT OPPORTUNITY, DOES NOT CONSTITUTE AN OFFER OR SOLICITATION IN ANY
                JURISDICTION IN WHICH SUCH AN OFFER OR SOLICITATION IS NOT AUTHORISED OR IN
                WHICH THE PERSON MAKING SUCH AN OFFER OR SOLICITATION IS NOT QUALIFIED TO DO
                SO OR WITH RESPECT TO ANY PERSON TO WHOM IT IS UNLAWFUL TO MAKE AN OFFER OR
                SOLICITATION. IT IS THE RESPONSIBILITY OF EACH INVESTOR (INCLUDING AN INVESTOR
                OUTSIDE OF THE UNITED KINGDOM) TO SATISFY ITSELF AS TO FULL COMPLIANCE WITH
                THE LAWS AND REGULATIONS OF THE RELEVANT JURISDICTION AND WHERE IN ANY DOUBT
                TO SEEK APPROPRIATE LEGAL ADVICE.`} />
            </div>
          </div>
          <br />
          <div className="next-btn">
            <Button
              inputData={{ id: 'next-btn', displayName: 'Next', type: 'button' }}
              clickHandler={event =>
                this.props.handleRedirect(event, questionnaireContext.currentStep + 1)
              }
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  null,
);

export default connector(Instructions);
