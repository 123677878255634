import React from 'react';
import './styles.scss';
import CustomTooltip from 'components/Tooltip';

type Props = { text: Object, className: Object, children: any };

const Paragraph = ({ text, className, id, children, tooltip }: Props) => {
  if (tooltip && tooltip !== '') {
    return (
      <div className="paragraph-container">
        <p id={id} className={className}>
          {text}
        </p>
        <CustomTooltip
          className="tooltip-positioning"
          data={{
            message: `${tooltip}`,
          }}
        />
      </div>
    );
  }
  return children ? (
    children
  ) : (
    <p id={id} className={className}>
      {text}
    </p>
  );
};

Paragraph.defaultProps = {
  text: 'You need to add some text',
  className: '',
  tooltip: '',
};

export default Paragraph;
