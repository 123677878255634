/**
 * Created by alego on 6/7/2017.
 */
import type { Dispatch, GetState, ThunkAction } from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

const API_URL = '/api';

function getConfig() {
  let authConfig = 'invalid';
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('rs-token')) {
      authConfig = localStorage.getItem('rs-token');
    } else if (localStorage.getItem('auth-token')) {
      authConfig = localStorage.getItem('auth-token');
    }
  }
  return {
    headers: { Authorization: `Bearer ${authConfig}` },
  };
}
function getCurrentToken() {
  let currentToken = '';
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('rs-token')) {
      currentToken = localStorage.getItem('rs-token');
    } else if (localStorage.getItem('auth-token')) {
      currentToken = localStorage.getItem('auth-token');
    }
  }
  return currentToken;
}
export const updateUser = (infoPayload, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.put(
      `${API_URL}/user`,
      { ...infoPayload, token: getCurrentToken() },
      getConfig()
    );
    if (res.data.success) {
      if (
        (typeof window !== 'undefined' && localStorage.getItem('rs-token')) ||
        infoPayload.passwordConfirm
      ) {
        localStorage.removeItem('rs-token');
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'success',
          message: res.data.message,
          title: 'Success',
        });
        setTimeout(() => {
          window.location.href = window.location.href;
          history.push('/admin');
        }, 2000);
      } else {
        dispatch({ type: 'SET_USER', user: res.data.user });
        dispatch({ type: 'SET_ROLE_USER', userRole: res.data.user.role });
        dispatch({ type: 'SET_PROFILE', profile: res.data.profile });
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'success',
          message: res.data.message,
          title: 'Success',
        });
      }
    } else {
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'error',
        message: res.data.message,
        title: 'Error',
      });
    }
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};
