/* eslint-disable react/sort-comp,max-len */
/* @flow */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'react-bootstrap';
import _ from 'lodash';
import { Route, Switch, Redirect } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import BlockUi from 'react-block-ui';
import ReactConfirmAlert, { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-block-ui/style.css';
import { Helmet } from 'react-helmet';
import * as action from './action';
import './styles.scss';
import {
  OrganizationContext as OrganizationContextType,
  AuthContainer as AuthContainerReType,
  Reducer,
  Dispatch,
} from '../../types/index';
import FundDashboardHeader from '../../components/FundDashboardHeader/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';
import NotFoundPage from './../NotFound';
import OrganizationDashboardPage from '../FundDashboard';
import LimitedPartnersPage from '../FundLimitedPartners';
import SettingsPage from '../FundSettings';
import HowToPage from '../HowTo';
import AddOrganizationPage from '../FundOrganization';
import Title from '../../components/Title';
import * as notify from '../../utils/notify';

type Props = {
  history: Object,
  organizationContext: OrganizationContextType,
  authContainerRe: AuthContainerReType,
  logout: () => void,
  refetchUser: () => void,
  sessionExpire: () => void,
  updateOnboardedStatus: () => void,
};

const OrganizationAuthenticatedContainer = (props) => {
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  useEffect(() => {
    if (
      props.organizationContext &&
      props.organizationContext.user &&
      props.organizationContext.user._id
    ) {
      checkUserIsAvailable();
    }
    if (
      typeof window !== 'undefined' &&
      !localStorage.getItem('organization-auth-token')
    ) {
      props.history.push('/');
    }
  }, [props.organizationContext]);
  const checkUserIsAvailable = () => {
    const { organizationContext, refetchUser, history } = props;
    if (!organizationContext.user._id) {
      refetchUser(history, handleOnboardInstructions);
    } else {
      const currentPath =
        typeof window !== 'undefined' && window.location
          ? window.location.pathname.split('/organization/')[1]
          : '/';
      if (currentPath !== 'add') {
        handleOnboardInstructions();
      }
    }
  };

  useEffect(() => {
    if (document.title !== 'Fund Formation Portal - Main')
      notify.hideNotification();
    checkUserIsAvailable();
  });

  useEffect(() => {
    if (props.authContainerRe.sessionTimeout) {
      confirmAlert({
        title: 'Session Expired',
        message: 'Your current session has expired. Please log back in.',
        buttons: [
          {
            label: 'Continue',
            onClick: () => handleSessionExpire(),
          },
          {
            label: 'Cancel',
            onClick: () => handleSessionExpire(),
          },
        ],
      });
    }
  }, [props.authContainerRe.sessionTimeout]);
  const handleLogout = (event) => {
    event.preventDefault();
    const { logout, history } = props;
    logout(history);
  };
  const handleSessionExpire = () => {
    const { sessionExpire, history } = props;
    sessionExpire(history);
  };

  const handleOnboardInstructions = () => {
    const { organizationContext, updateOnboardedStatus } = props;
    if (organizationContext.user && !organizationContext.user.onboarded) {
      const userClone = { ...organizationContext.user };
      userClone.onboarded = true;
      updateOnboardedStatus(userClone, () => setShowInstructionsModal(true));
    }
  };

  const organizationRoutes = () => {
    return [
      {
        path: '/organization/questionnaires',
        exact: true,
        component: OrganizationDashboardPage,
      },
      {
        path: '/organization/limitedPartners',
        exact: true,
        component: LimitedPartnersPage,
      },
      {
        path: '/organization/settings',
        exact: true,
        component: SettingsPage,
      },
      {
        path: '/organization/howtouse',
        exact: true,
        component: HowToPage,
      },
      {
        path: '/organization/add',
        exact: true,
        component: AddOrganizationPage,
      },
      {
        path: '*',
        component: NotFoundPage,
      },
    ];
  };

  const routeWithSubRoutes = (route) => {
    const { organizationContext } = props;
    return (
      <Route
        key={_.uniqueId()}
        exact={route.exact || false}
        path={route.path}
        render={(props) => {
          // check access is allowed else redirect to login
          if (!organizationContext.user._id) {
            return null;
          }
          return <route.component {...props} routes={route.routes || null} />;

          // Pass the sub-routes down to keep nesting
        }}
      />
    );
  };

  const renderOnboardingModal = () => {
    return (
      <Modal
        bsSize="large"
        show={showInstructionsModal}
        onHide={() => setShowInstructionsModal(false)}
      >
        <ModalBody bsClass="clearfix center-main-content">
          <div className="row">
            <div className="col-lg-12 text-center">
              <br />
              <br />
            </div>
          </div>
          <br />
          <br />
          <div className="col-lg-12">
            <h4>HOW TO USE THE FUND FORMATION PORTAL</h4>
            <Title text={'Quick Start Guide'} />
            <ul className="unorderList-instructions color-black">
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">
                      CREATE AT LEAST ONE INVESTOR:
                    </span>{' '}
                    Before you can begin filling out and signing Investor
                    Questionnaires (IQs) on the Fund Formation Portal, you will
                    need to create at least ONE Investor associated with your
                    organization. LPs are the entities or individuals you will
                    use to invest and for which you will need to complete IQs on
                    the Portal.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">TO FILL OUT IQS:</span>{' '}
                    Use the link you received to access and complete Investor
                    Questionnaires. The link may still be open in a separate
                    browser tab.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">LOG IN:</span> On the
                    right side, under{' '}
                    <b>FILLING OUT MULTIPLE INVESTOR QUESTIONNAIRES</b>, log in
                    with the email address and password you used to create your
                    account.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">
                      CHOOSE AN INVESTOR:
                    </span>{' '}
                    Once logged in, use the down arrow to select a previously
                    created investor for which you will fill out an IQ on the
                    Portal. Click Start. If you select an Investor for which you
                    have previously started, but not submitted, an IQ, you will
                    return to that same IQ and have the ability to edit your
                    answers.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">COMPLETE IQ:</span> Fill
                    out the IQ completely. At the end of the IQ, you will be
                    able to enter the name, title (if needed) and email address
                    of the person who should sign the IQ. The Portal will send
                    an email message to that person via DocuSign for electronic
                    signature. If YOU will be signing the IQ, enter your email
                    address. You will be able to sign via DocuSign within the
                    Portal.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    <span className="color-dark-green">ADDITIONAL IQS:</span>{' '}
                    You can return to the original emailed fund link to fill out
                    additional IQs for the same fund using additional LPs as
                    needed.
                  </p>
                </div>
              </li>
              <li>
                <div className="col-lg-12">
                  <p>
                    Complete instructions can be found{' '}
                    <a
                      href="#/"
                      onClick={() => props.history.push('howtouse')}
                      className="color-dark-green"
                    >
                      HERE
                    </a>
                    .
                  </p>
                  <p>
                    Please{' '}
                    <span>
                      <a
                        className="color-dark-green"
                        href="mailto:fundportaladmin@gunder.com"
                      >
                        contact
                      </a>
                    </span>{' '}
                    us for assistance.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <br />
          <br />
          <button
            id="organization-onboard-open-modal"
            onClick={() => setShowInstructionsModal(false)}
            style={{ fontSize: '14px' }}
          >
            Continue <span style={{ textTransform: 'lowercase' }}>to</span>{' '}
            Dashboard
          </button>
          <br />
          <br />
          <br />
        </ModalBody>
      </Modal>
    );
  };

  const { organizationContext, authContainerRe } = props;
  const url =
    typeof window !== 'undefined' && window.location
      ? window.location.pathname.split('/organization/')[1]
      : '/';
  return organizationContext.user._id &&
    organizationContext.organization &&
    organizationContext.organization._id ? (
    <div>
      <Helmet title={'Main'} />
      <div className="content-wrapper">
        {url === 'add/' || url === 'add' ? (
          <DynamicHeader type={'user'} homePath={'/'} />
        ) : (
          <FundDashboardHeader
            isAuthenticated={
              organizationContext.user._id &&
              organizationContext.organization.name
            }
            displayName={organizationContext.organization.name}
            logoutHandler={handleLogout}
            history={props.history}
          />
        )}
        <Notifications />
        <BlockUi tag="div" blocking={authContainerRe.isLoading}>
          <Switch>
            {organizationRoutes().map((route) => routeWithSubRoutes(route))}
          </Switch>
        </BlockUi>
        {renderOnboardingModal()}
      </div>
      <Footer />
    </div>
  ) : (
    <Redirect to="/" />
  );
};
const mapStateToProps = (state: Reducer) => ({
  context: state.context, // should not use it here
  organizationContext: state.organizationContext,
  authContainerRe: state.authContainerRe,
});

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    logout: (history) => dispatch(action.logout(history)),
    sessionExpire: (history) => dispatch(action.sessionExpire(history)),
    refetchUser: (history, callback) =>
      dispatch(action.refetchUser(history, callback)),
    updateOnboardedStatus: (payload, callback) =>
      dispatch(action.updateOnboardedStatus(payload, callback)),
  })
);

export default connector(OrganizationAuthenticatedContainer);
