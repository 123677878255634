import React from 'react';
import './styles.scss';


type Props = { inputData: Object, clickHandler: () => void };

const defaultClickHandle = () => {
};

const resolveButtonClass = (inputData) => {
  let buttonClassSet = '';
  if (inputData.isBack) {
    buttonClassSet = `${buttonClassSet} back`;
  }
  if (inputData.isActive) {
    buttonClassSet = `${buttonClassSet} active`;
  }
  if (inputData.icon && inputData.icon !== '') {
    buttonClassSet = `${buttonClassSet} large text-with-icon background-color-dark-green`;
  }
  if (inputData.size === 'small') {
    buttonClassSet = `${buttonClassSet} small-button`;
  }
  return buttonClassSet;
};

const renderIconContent = data => (<div>
  <span className="button-label pull-left">{data.displayName}</span>
  <i className={`fa ${data.icon} button-icon pull-right`} aria-hidden="true" />
</div>
  );

const Button = ({ inputData, clickHandler }: Props) => (
  <button
    id={inputData.id}
    type={inputData.type}
    className={resolveButtonClass(inputData)}
    disabled={inputData.isDisabled}
    data-identifier={inputData.dataIdentifier ? inputData.dataIdentifier : 'none'}
    onClick={inputData.type !== 'submit' ? clickHandler : null}
  >{(inputData.icon && inputData.icon !== '') ? renderIconContent(inputData) : inputData.displayName}
  </button>
);

Button.defaultProps = {
  inputData: {
    id: '',
    displayName: '',
    isActive: false,
    isDisabled: false,
    icon: '',
    isBack: false,
    type: 'button',
  },
  clickHandler: defaultClickHandle(),
};

export default Button;

