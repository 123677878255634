/* eslint-disable react/sort-comp,class-methods-use-this,no-param-reassign,no-cond-assign,radix,keyword-spacing,max-len,indent,no-unused-vars, jsx-a11y/anchor-is-valid */
/* @flow */

import * as _ from 'lodash';
import { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as utility from '../../utils/utility';
import * as notify from './../../utils/notify';
import * as action from './action';
import React from 'react';
import {
  getFormattedLocation,
  getFullFormattedLocation,
} from 'utils/location.util';
import Banner from '../../components/Banner/index';
import FilterBox from '../../components/FilterBox/index';
import Popup from '../../components/Popup/index';
import TableContainer from '../../components/TableContainer/index';
import { questions as questionsHCMv1 } from '../../utils/constants/questionnaire/hcm/v1/iq-questions.hcm.v1';
import * as iqID from '../../utils/constants/questionnaire/identifiers';
import DateTimeZone from '../../utils/date-time-zone';
const {
  getPages,
  getInitialData,
  orderByPage,
  paginationMAX,
} = require('../../utils/pagination');
const { getInvestorFundQuestions } = require('../../utils/iq.questions.array');
const { csvFields, filterStatus } = require('../../utils/constants/csv');
const { STATUS_TYPES } = require('../../utils/constants/status');
const {
  EXCLUDE_DATA,
  EXCLUDE_HEADERS,
  MOVE_HEADERS,
} = require('../../utils/constants/excludeQuestionsByVersion');
const { INVESTMENT_TYPES, FUND_TYPES } = require('../../utils/constants/types');
const {
  LimitedPartnerV1,
  LimitedPartnerV2,
  LimitedPartnerV3,
  LimitedPartnerV4,
  LimitedPartnerV5,
  LimitedPartnerV6,
  LimitedPartnerV7,
  LimitedPartnerV8,
  LimitedPartnerV8_1,
  LimitedPartnerV9,
  LimitedPartnerV10,
  LimitedPartnerV11,
  LimitedLiabilityCompanyV1,
  LimitedLiabilityCompanyV2,
  LimitedLiabilityCompanyV3,
  LimitedLiabilityCompanyV4,
  LimitedLiabilityCompanyV5,
  LimitedLiabilityCompanyV6,
  LimitedLiabilityCompanyV6_1,
  LimitedLiabilityCompanyV7,
  LimitedLiabilityCompanyV8,
  LimitedLiabilityCompanyV9,
  ForesiteV1,
  ForesiteV2,
  ForesiteV3,
  ForesiteV4,
  ForesiteV5,
  ForesiteV6,
  ForesiteV7,
  QuestaCapitalV1,
  QuestaCapitalV2,
  QuestaCapitalV3,
  QuestaCapitalV4,
  QuestaCapitalV5,
  QuestaCapitalV6,
  QuestaCapitalV7,
  InitializedV1,
  InitializedV2,
  InitializedV3,
  InitializedV4,
  InitializedV5,
  InitializedV6,
  InitializedV7,
  HCMV1,
  HCMV2,
  HCMV3,
  HCMV4,
  ForesiteLabsV1,
  ForesiteLabsV2,
  ForesiteLabsV3,
  ForesiteLabsV4,
  LPABBRV1,
  LPABBRV1_1,
  LPABBRV2,
  LPABBRV3,
  LPABBRV4,
  OSAGEV1,
  OSAGEV2,
  NewLimitedPartnerV1,
  NewLimitedPartnerV2,
  FireworkV1,
  FireworkV2,
  PrometheanV1,
  PrometheanV2,
  AccelV1,
  AccelV2,
  AccelV3,
  AccelMainV1,
  AccelEntrepreneursV1,
  AccelEtpnReturningV1,
  AccelEtpnReturningV2,
  LPMULTIFUNDV1,
  LPMULTIFUNDV2,
  LPMULTIFUNDV3,
  TcgCrossOverV1,
  TcgCrossOverV2,
  IOLARV1,
  IOLARV2,
  BITKRAFTV1,
  BITKRAFTV2,
  ElephantPartnerV1,
  ElephantPartnerV2,
  LimitedPartnershipMFV1,
  LimitedPartnershipMFV2,
  LimitedPartnershipMFV3,
  AudereV1,
  AudereV2,
  CaymanV1,
  CaymanV2,
  SROneV1,
  FrazierV1,
} = require('../../utils/constants/questionnaire/versions');

const { formatMoney } = require('../../utils/format');
const { adjustFormatMoney } = require('../../utils/adjust-format-money');

const oldVersionGovtControlled = [
  QuestaCapitalV2,
  QuestaCapitalV3,
  QuestaCapitalV4,
  QuestaCapitalV5,
  QuestaCapitalV6,
  InitializedV2,
  InitializedV3,
  InitializedV4,
  InitializedV5,
  InitializedV6,
  LimitedPartnerV3,
  LimitedPartnerV4,
  LimitedPartnerV5,
  LimitedPartnerV6,
  LimitedPartnerV7,
  LimitedPartnerV8,
  LimitedPartnerV8_1,
  LimitedLiabilityCompanyV2,
  LimitedLiabilityCompanyV3,
  LimitedLiabilityCompanyV4,
  LimitedLiabilityCompanyV5,
  LimitedLiabilityCompanyV6,
  LimitedLiabilityCompanyV6_1,
  LimitedPartnershipMFV1,
  PrometheanV1,
  FireworkV1,
  AccelMainV1,
  AudereV1,
  CaymanV1,
  OSAGEV1,
  HCMV1,
  HCMV2,
  HCMV3,
  ForesiteV1,
  ForesiteV2,
  ForesiteV3,
  ForesiteV4,
  ForesiteV5,
  ForesiteV6,
  ForesiteLabsV1,
  ForesiteLabsV2,
  ForesiteLabsV3,
];

let excludeByIdx = [];

/**
 * @typedef {Object} Props
 * @property {Gunderson.Administrator} admin
 * @property {Object} router
 * @property {Object} history
 * @property {Function} fetchFund
 * @property {Function} fetchInvestorFunds
 * @property {Function} replaceFund
 * @property {Function} updateInvestorFund
 * @property {Gunderson.Context} exportCSV
 * @property {Function} context
 * @property {Function} updateEmail
 * @property {Function} updateFundLastClosing
 * @property {Function} sendMailShareFund
 * 
*/

const initialState = {
  preloader: true,
  selectedInvestorFunds: [],
  page: 1,
  closedAmount: 0,
  allCapitalAmount: 0,
  readyToCloseAmount: 0,
  notReadyToCloseAmount: 0,
  currency: 'usd',
  sortKey: 'createdAt',
  sortByCol: 'created-at-col',
  sortDirection: 'desc',
  iQsPage: 1,
  iQsMaxItems: paginationMAX,
  iQsLength: 0,
  iQsPages: null,
  date: new DateTimeZone(),
  selectedIndex: -1,
  askDate: false,
};

const defaultHandler = (event) => {
  event.preventDefault();
};

// Export this for unit testing more easily
export class FundDetail extends PureComponent {
  /**
   * @param {Props} props
  */
  props

  static defaultProps = {
    admin: {},
    router: {},
    history: {},
    fetchFund: () => {},
    fetchInvestorFunds: () => {},
    replaceFund: () => {},
    updateInvestorFund: () => {},
    exportCSV: () => {},
    context: {},
    updateEmail: () => {},
    updateFundLastClosing: () => {},
    sendMailShareFund: () => {},
  };

  constructor(state) {
    super(state);
    this.state = initialState;
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.getData = this.getData.bind(this);
    this.setFund = this.setFund.bind(this);
    this.handleInvestorFundCheck = this.handleInvestorFundCheck.bind(this);
    this.setInvestorFund = this.setInvestorFund.bind(this);
    this.getMoreInvestorFunds = this.getMoreInvestorFunds.bind(this);
    this.handleEditField = this.handleEditField.bind(this);
    this.handleSaveField = this.handleSaveField.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.redirectToInvestorQuestionnaire =
      this.redirectToInvestorQuestionnaire.bind(this);
    this.setCheckedFunds = this.setCheckedFunds.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.emailHandler = this.emailHandler.bind(this);
    this.calculateCapitalAmount =
      this.calculateCapitalAmount.bind(this);
    this.handlerOnChange = this.handlerOnChange.bind(this);
    this.handleSendMail = this.handleSendMail.bind(this);
    this.handleButtonEditFund = this.handleButtonEditFund.bind(this);
    this.handleChangeFilterItem = this.handleChangeFilterItem.bind(this);
    this.isShortIQ = false;
  }

  componentDidMount() {
    this.getData();

    if (document.title !== 'Fund Formation Portal - FirmFundsDetail') {
      notify.hideNotification();
    }

    const clearSelectIndex = () => {
      this.setState({ selectedIndex: -1 });
    };
    // Detect all clicks on the document
    document.addEventListener('click', function (event) {
      // If user clicks inside the element, do nothing
      if (event.target.closest('.box-container-menu-statuses')) return;
      // If user clicks outside the element, hide it!
      clearSelectIndex();
    });
  }

  getData() {
    const { router, fetchFund } = this.props;
    const routLocation = router.location.pathname;
    const id = routLocation.split('fundDetail/')[1];
    if (id && id !== '') {
      fetchFund(id, this.setCheckedFunds);
    }
  }

  setCheckedFunds() {
    this.setState({ preloader: false }, () => {
      const { admin } = this.props;
      const selectedFunds = [...new Set(this.state.selectedInvestorFunds)];

      const hasInvestorFunds =
        admin.dashboardSelectedFund.investorFunds &&
        admin.dashboardSelectedFund.investorFunds.length;
      const hasLimitedPartnerFunds =
        admin.dashboardSelectedFund.limitedPartnerFunds &&
        admin.dashboardSelectedFund.limitedPartnerFunds.length;

      if (hasInvestorFunds) {
        admin.dashboardSelectedFund.investorFunds.forEach((inv) => {
          if (inv.status === STATUS_TYPES.READY_TO_CLOSE.label) {
            //eslint-disable-next-line
            inv.checked = true;
            this.setState(
              {
                ...this.state,
                selectedInvestorFunds: [...selectedFunds],
              },
              () => {
                this.setInvestorFund(inv);
              }
            );
          }
        });
      }
      if (hasLimitedPartnerFunds) {
        admin.dashboardSelectedFund.limitedPartnerFunds.forEach((inv) => {
          if (inv.status === STATUS_TYPES.READY_TO_CLOSE.label) {
            //eslint-disable-next-line
            inv.checked = true;
            this.setState(
              {
                ...this.state,
                selectedInvestorFunds: [...selectedFunds],
              },
              () => {
                this.setInvestorFund(inv);
              }
            );
          }
        });
      }
      if (hasInvestorFunds || hasLimitedPartnerFunds) {
        this.calculateCapitalAmount(admin.dashboardSelectedFund);
      }
    });
  }

  getMoreInvestorFunds(page) {
    const { fetchInvestorFunds } = this.props;
    fetchInvestorFunds(page);
  }

  setFund(fund) {
    this.setState({
      ...this.state,
      fund,
    });
  }
   
  //ACCEL MAIN V1
  RepWarranty7UKAnswer(_questions) {
    const RepWarranty7 = {
        'id_girw_7_a': '1',
        'id_girw_7_b': '2',
        'id_girw_7_c': '3',
        'id_girw_7_d': '4',
        'id_girw_7_e': '5',
        'id_girw_7_f': '6',
        'id_girw_7_g': '7',
        'id_girw_7_h': '8',
        'id_girw_7_i': '9',
        'id_girw_7_2_a': '10',
        'id_girw_7_2_b': '11',
        'id_girw_7_2_c': '12',
        'id_girw_7_2_d': '13',
        'id_girw_7_2_e': '14',
        'id_girw_7_2_f': '15',
    }

    const identifierCompanyBasis = {
      id_griw7_2CompanyBasis_a: '(a)',
      id_griw7_2CompanyBasis_b: '(b)',
      id_griw7_2CompanyBasis_c: '(c)',
    }

  let value = []
  let companyBasis = []

  const handleDescription = (key, _questions) => {
    return _questions[key].description && _questions[key].description !== '' ? `(${_questions[key].description})` : ''
  }

  Object.keys(identifierCompanyBasis).forEach((key) => {
    if(_questions[key] && _questions[key].answer === 'Yes') {
      companyBasis.push(identifierCompanyBasis[key])
    }
  })
  Object.keys(RepWarranty7).forEach((key) => {
      if(_questions[key] && _questions[key].answer === 'Yes') {
        switch (key) {
          case 'id_girw_7_i':
            value.push(`${RepWarranty7[key]} ${handleDescription(key, _questions)}`)
            break;
          case 'id_girw_7_2_f':
            value.push(`${RepWarranty7[key]} ${handleDescription(key, _questions)}`)
            break;
          case 'id_girw_7_2_d':
            value.push(`${RepWarranty7[key]} ${companyBasis.join(',')}`)
            break;
          default:
            value.push(RepWarranty7[key])
            break;
        }
          
       }
     })
     if (value.length === 1) {
      return value.join('')
     }

     return value.join(', ')
   
  }

  RepWarranty8SwitzerlandAnswer(_questions) {
    let value = []
     const RepWarranty8 = {
      'id_girw_8_1_a': '1',
      'id_girw_8_1_b': '2',
      'id_girw_8_1_c': '3',
      'id_girw_8_1_d': '4',
      'id_girw_8_1_e': '5',
      'id_girw_8_1_f': '6',
      'id_girw_8_1_g': '7',
      'id_girw_8_1_h': '8',
      'id_girw_8_1_i': '9',
      'id_girw_8_1_j': '10',
      'id_girw_8_1_k': '11',
      'id_girw_8_1_l': '12',
     }
     Object.keys(RepWarranty8).forEach((key) => {
      if(_questions[key] && _questions[key].answer === 'Yes') {
        value.push(RepWarranty8[key])
       }
     })
     
     return value.join(', ')
  }

  partAFinra5130Answer(_questions) {
    let val = ''

    // SR ONE Part A
    const ids = [
      'id_sr_one_part_a_3_a',
      'id_sr_one_part_a_3_b',
      'id_sr_one_part_a_3_c',
    ];

     const exhibitCAIds = [
      iqID.id_sr_one_a_exhibit_c_1,
      iqID.id_sr_one_a_exhibit_c_2,
      iqID.id_sr_one_a_exhibit_c_3,
      iqID.id_sr_one_a_exhibit_c_4,
      iqID.id_sr_one_a_exhibit_c_5,
      iqID.id_sr_one_a_exhibit_c_6,
      iqID.id_sr_one_a_exhibit_c_7,
      iqID.id_sr_one_a_exhibit_c_8,
      iqID.id_sr_one_a_exhibit_c_9,
      iqID.id_sr_one_a_exhibit_c_10,
     iqID. id_sr_one_a_exhibit_c_11,
    ]

      Object.entries(_questions).forEach(k => {
        if(k && k[1]) {
          if(k[0].indexOf('srOnePartA') > -1) {
            if(k[1].answer === 'Yes') {
               const index = k[1].descriptionIdentifier.replace('id_sr_one_part_a_', '');
               val = index && index === 'or' ? 4 : index.includes('_') ? index.replace(/_b|_c|_a/, '') : index;
              if (k[1].descriptionIdentifier.includes('id_sr_one_part_a_3')) {
               const question = _.find(_questions, (q) => q && q.answer && q.answer === 'Yes' && ids.includes(q.descriptionIdentifier));
               const identifier = question && question.descriptionIdentifier ? question.descriptionIdentifier.replace('id_sr_one_part_a_3_', '') : '';
               const answer = question && question.answer ? identifier : '';
              const exhibitCA = answer === 'b' ?  _.find(_questions, (q) => q && exhibitCAIds.includes(q.descriptionIdentifier) && q.answer === 'Yes') : null;
                val = val + ` ${answer}` + `${exhibitCA ? ` - exemption: ${exhibitCA.descriptionIdentifier.replace('id_sr_one_a_exhibit_c_','')}` : ''}`;
                return val
              } 
            }
          }
        }
      })
      return val
  }

   partBFinra5131Answer(_questions) {

    const question3  = [
      'id_sr_one_part_b_3_a',
      'id_sr_one_part_b_3_b',
      'id_sr_one_part_b_3_c',
    ]

    const question1 = [
       'id_sr_one_part_b_1_a',
      'id_sr_one_part_b_1_b',
      'id_sr_one_part_b_1_c',
    ]

     const exhibitCBIds = [
      iqID.id_sr_one_b_exhibit_c_1,
      iqID.id_sr_one_b_exhibit_c_2,
      iqID.id_sr_one_b_exhibit_c_3,
      iqID.id_sr_one_b_exhibit_c_4,
      iqID.id_sr_one_b_exhibit_c_5,
      iqID.id_sr_one_b_exhibit_c_6,
      iqID.id_sr_one_b_exhibit_c_7,
      iqID.id_sr_one_b_exhibit_c_8,
      iqID.id_sr_one_b_exhibit_c_9,
      iqID.id_sr_one_b_exhibit_c_10,
     iqID. id_sr_one_b_exhibit_c_11,
    ]

   let val = ''

      Object.entries(_questions).forEach(k => {
        if(k && k[1]) {
          if(k[0].indexOf('srOnePartB') > -1) {
            if(k[1].answer === 'Yes') {
               const index = k[1].descriptionIdentifier.replace('id_sr_one_part_b_', '');
               val = index && index === 'or' ? 4 : index.includes('_') ? index.replace(/_b|_c|_a/, '') : index;
              if (k[1].descriptionIdentifier.includes('id_sr_one_part_b_3')) {
               const question = _.find(_questions, (q) => q && q.answer && q.answer === 'Yes' && question3.includes(q.descriptionIdentifier));
               const identifier = question && question.descriptionIdentifier ? question.descriptionIdentifier.replace('id_sr_one_part_b_3_', '') : '';
               const answer = question && question.answer ? identifier : '';
               const exhibitCB = answer === 'b' ?  _.find(_questions, (q) => q && exhibitCBIds.includes(q.descriptionIdentifier) && q.answer === 'Yes') : null;
                val = val + ` ${answer}` + `${exhibitCB ? ` - exemption: ${exhibitCB.descriptionIdentifier.replace('id_sr_one_b_exhibit_c_','')}` : ''}`;
                return val
              } 
              if (k[1].descriptionIdentifier.includes('id_sr_one_part_b_1')) {
                const question = _.find(_questions, (q) => q && q.answer && q.answer === 'Yes' && question1.includes(q.descriptionIdentifier));
                const identifier = question && question.descriptionIdentifier ? question.descriptionIdentifier.replace('id_sr_one_part_b_1_', '') : '';
                const answer = question && question.answer ? identifier : '';
                 val = val + ` ${answer}`;
                 return val
              }
            }
          }
        }
      
      })
      return val
  }

  beneficialOwnershipAdvisersAnswer(_questions) {
        let val = ''
        Object.entries(_questions).forEach(k => {
          if(k && k[1]) {
            if(k[0].indexOf('investorStatus7TCG') > -1) {
              if(k[1].answer === 'Yes') {
                if(k[1].descriptionIdentifier === 'id_investor_status_7_tcg_m') {
                  val = 'm'+ `${k[1].description && k[1].description !== '' ? `(${k[1].description})` : ''}`
                } else if (k[1].descriptionIdentifier === 'id_investor_status_7_tcg_o') {
									val = 'o ' + `${k[1].description && k[1].description !== '' ? `(${k[1].description})` : ''}`;
								} else {
									val = k[1].descriptionIdentifier.replace('id_investor_status_7_tcg_', '');
								} 
              }
            }
          }
        
        })
        return val
  }

  restrictedPersonStatusAnswer(_questions) {
    let val = []
    Object.entries(_questions).forEach(k => {
      if(k && k[1]) {
        if(k[0].indexOf('restrictedPersonStatus') > -1) {
          if (k[1].answer === 'Yes' && k[1].descriptionIdentifier === 'id_restricted_person_status_or') {
           val.push('No')
          }else if (k[1].answer === 'Yes' && k[1].descriptionIdentifier === 'id_restricted_person_status_k'){
            let kDescription = k[1].description && k[1].description !== '' ? `(${k[1].description}${k[1].description.indexOf('%') <= -1 ? '%' : ''})` : ''
              val.push('k' + kDescription)
          }
          else if (k[1].answer === 'Yes') {
              val.push(k[1].descriptionIdentifier.replace('id_restricted_person_status_', ''))
              
            } 
          }
        }
      })
    return val.join(', ')
  }

  coveredInvestorStatusAnswer(_questions) {
    let val = ''
    Object.entries(_questions).forEach(k => {
      if(k && k[1]) {
        if(k[0].indexOf('coveredInvestorStatus') > -1) {
          if(k[1].descriptionIdentifier === 'id_covered_investor_status_a') {
            val = 'a' + `${k[1].description && k[1].description !== '' ? `(${k[1].description})` : ''}`
          } else if (k[1].descriptionIdentifier === 'id_covered_investor_status_b'){
            val = 'b' + `${k[1].description && k[1].description !== '' ? `(${k[1].description})` : ''}`
          } else if ( k[1].descriptionIdentifier === 'id_covered_investor_status_or') {
            val = 'No'
          } 
        }
      }
      })
    return val
  }

  determinationExemptedEntityAnswer(_questions) {
    let val = []
    Object.entries(_questions).forEach(k => {
      if (_questions.naturalPerson && _questions.naturalPerson.answer === 'No'){
      if(k && k[1]) {
        if(k[0].indexOf('exemptedEntityStatus') > -1) {
          if(k[1].answer === 'Yes') {
              val.push(k[1].descriptionIdentifier.replace('id_exempted_entity_status_', ''))
            } 
          } 
        }
      }else if (_questions.naturalPerson && _questions.naturalPerson.answer === 'Yes'){
        val.join('N/A ')
      }
      })
      
    return val.join(', ')
  }

  capitalCommitmentCurrency(value, fund) {
    const currency = fund && fund.currency ? fund.currency : 'usd';
    return adjustFormatMoney(`${formatMoney(value, 2, '.',',')}`, currency);
  }

  checkGeneralInfoCompleted = (info, requireCapitalCommitment) => {
  if (!info) return false

  const fields = [
    'name',
    'location',
    'namePrimaryContact',
    'emailPrimaryContact',
    'addressPrimaryContact',
  ]
  let generalInfoCompleted = true;

  fields.forEach(field => {
    if (!info[field] || info[field] === '' || info[field] === 'N/A') {
      generalInfoCompleted = false;
    }
  })

  if (!info.types || info.types.length === 0) {
    generalInfoCompleted = false;
  }
  if ((!info.capitalCommitment || info.capitalCommitment === '0') &&
      (!info.capitalCommitmentFund2 || info.capitalCommitmentFund2 === '0') &&
      (!info.capitalCommitmentFund3 || info.capitalCommitmentFund3 === '0')) {
        if (requireCapitalCommitment) {
          generalInfoCompleted = false;
        }
  }

  if (info.accelReturningInvestor) {
    return true;
  }

  return generalInfoCompleted;
}

  handleButton(event) {
    event.preventDefault();
    const { admin, replaceFund, updateInvestorFund, updateFundLastClosing } =
      this.props;
    const fund = admin.dashboardSelectedFund;
    const investorFundsToClose = [];
    const checkedInvestorFunds = fund.investorFunds.filter(
      (investFund) => investFund.checked
    );
    const checkedLimitedPartnerFunds = fund.limitedPartnerFunds.filter(
      (investFund) => investFund.checked
    );
    const investmentsTotal =
      checkedInvestorFunds.length + checkedLimitedPartnerFunds.length;
    const message =
      investmentsTotal > 0 && investmentsTotal < 2
        ? 'Are you sure you will like to close this account?'
        : 'Are you sure you will like to close these accounts?';
    if (investmentsTotal > 0) {
      //eslint-disable-next-line
      if (window.confirm(message)) {
        const updateInvestmentsRef = (investments) => {
          investments.forEach((investorFund, index) => {
            const invFund = investments[index];
            invFund.status = STATUS_TYPES.CLOSED.label;
            invFund.checked = false;
            invFund.closedAt = new DateTimeZone();
            invFund.statusChange = STATUS_TYPES.CLOSED.value;
            updateInvestorFund(invFund, this.setCheckedFunds);
            investorFundsToClose.push(invFund);
            // todo review this line
            if (index === investments.length - 1) replaceFund(fund);
          });
        };
        if (checkedInvestorFunds.length) {
          updateInvestmentsRef(checkedInvestorFunds);
        }
        if (checkedLimitedPartnerFunds.length) {
          updateInvestmentsRef(checkedLimitedPartnerFunds);
        }
        const tempFund = fund;
        tempFund.lastClosingDate = new DateTimeZone();
        updateFundLastClosing(tempFund);
      }
    }
  }

  handleLink(event) {
    event.preventDefault();
    const { history } = this.props;
    history.push('/admin/main/dashboard');
  }

  handleLoadMore(event) {
    event.preventDefault();
    const { admin } = this.props;
    if (admin.dashboardSelectedFund.canLoadInvestorFunds) {
      const newPage = this.state.page + 1;
      this.setState({ ...this.state, page: newPage });
      this.getMoreInvestorFunds(newPage);
    }
  }

  setInvestorFund(newInvestorFund) {
    const { admin, replaceFund } = this.props;
    const fund = admin.dashboardSelectedFund;
    if (newInvestorFund.investor) {
      const i = _.findIndex(
        fund.investorFunds,
        (investorFund) =>
          investorFund._id.toString() === newInvestorFund._id.toString()
      );
      if (i >= 0) {
        fund.investorFunds[i] = newInvestorFund;
        replaceFund(fund);
      }
    }
    if (newInvestorFund.limitedPartner) {
      const i = _.findIndex(
        fund.limitedPartnerFunds,
        (investorFund) =>
          investorFund._id.toString() === newInvestorFund._id.toString()
      );
      if (i >= 0) {
        fund.limitedPartnerFunds[i] = newInvestorFund;
        replaceFund(fund);
      }
    }
  }

  handleInvestorFundCheck(item, statusUpdate, closingDate, updateIndex) {
    const { admin, updateInvestorFund, updateFundLastClosing } = this.props;
    const investmentType = item.dataType;
    let investment = {};
    if (investmentType === INVESTMENT_TYPES.INVESTOR_FUND) {
      investment = _.find(
        admin.dashboardSelectedFund.investorFunds,
        (instance) => instance._id === item.dataTarget
      );
    }
    if (investmentType === INVESTMENT_TYPES.LIMITED_PARTNER_FUND) {
      investment = _.find(
        admin.dashboardSelectedFund.limitedPartnerFunds,
        (instance) => instance._id === item.dataTarget
      );
    }
    if (investmentType === INVESTMENT_TYPES.FIREWORK) {
      investment = _.find(
        admin.dashboardSelectedFund.limitedPartnerFunds,
        (instance) => instance._id === item.dataTarget
      );
    }
    if (investmentType === INVESTMENT_TYPES.PROMETHEAN) {
      investment = _.find(
        admin.dashboardSelectedFund.limitedPartnerFunds,
        (instance) => instance._id === item.dataTarget
      );
    }
    const selectedFunds = this.state.selectedInvestorFunds;
    const i = _.findIndex(selectedFunds, (fund) => fund._id === investment._id);
    if (i >= 0) selectedFunds.splice(i, 1);
    this.setState(
      {
        ...this.state,
        selectedInvestorFunds: selectedFunds,
        preloader: true,
      },
      () => {
        investment.checked = false;
        switch (statusUpdate) {
          case STATUS_TYPES.READY_TO_CLOSE.label:
            investment.status = STATUS_TYPES.READY_TO_CLOSE.label;
            investment.statusChange = STATUS_TYPES.READY_TO_CLOSE.value;
            break;
           case STATUS_TYPES.OMIT_FROM_GP_ACCESS.label:
              investment.status = STATUS_TYPES.OMIT_FROM_GP_ACCESS.value;
              investment.statusChange = STATUS_TYPES.OMIT_FROM_GP_ACCESS.value;
            break;
          case STATUS_TYPES.IN_PROGRESS.label:
            investment.status = STATUS_TYPES.IN_PROGRESS.label;
            investment.statusChange = STATUS_TYPES.IN_PROGRESS.value;
            break;
          case STATUS_TYPES.CLOSED.label:
            investment.closedAt = closingDate;
            const fund = admin.dashboardSelectedFund;
            const tempFund = fund;
            tempFund.lastClosingDate = closingDate;
            updateFundLastClosing(tempFund);
            investment.status = STATUS_TYPES.CLOSED.label;
            investment.statusChange = STATUS_TYPES.CLOSED.value;
            break;
          case STATUS_TYPES.SUBMITTED.label:
            investment.status = STATUS_TYPES.SUBMITTED.label;
            investment.statusChange = STATUS_TYPES.SUBMITTED.value;
            investment.submitted = true;
            break;
        }
        updateInvestorFund(investment, this.setCheckedFunds);
        this.setInvestorFund(investment);
        if (statusUpdate !== STATUS_TYPES.CLOSED.label || updateIndex) {
          this.setState({ selectedIndex: -1 });
        }
      }
    );
  }

  handleAddPaperIQ = async () => {
    const { addPaperIQ, admin, context, location } = this.props; 
    const isAdmin =  location.pathname.includes('/admin');
    const createdRole = isAdmin ? 'admin' : 'user'
    const data = await addPaperIQ(
      admin.dashboardSelectedFund,
      context.user.email,
      createdRole
    );
    if (data && data.limitedPartnerFund) {
      this.props.history.push(
        `/access/login/?lpf=${data.limitedPartnerFund._id}`
      );
    }
  };

  handleEditField(event) {
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    const investmentType = event.target.dataset.type;
    const input =
      typeof window !== 'undefined'
        ? document.getElementById(event.target.dataset.textareaRef)
        : null;
    setTimeout(() => {
      input.focus();
    }, 100);
    const { admin } = this.props;
    const fund = admin.dashboardSelectedFund;
    const property = event.target.dataset.property;
    const checkEditTarget = (invFund) => {
      if (invFund._id === fundId) {
        const newFund = invFund;
        switch (property) {
          case 'notes':
            newFund.editingNotes = true;
            break;
          case 'ofac':
            newFund.editingOFAC = true;
            break;
          case 'lpGroup':
            newFund.editingLpGroup = true;
            break;
          default:
            break;
        }
        this.setInvestorFund(newFund);
      }
    };
    if (investmentType === INVESTMENT_TYPES.INVESTOR_FUND) {
      fund.investorFunds.forEach((invFund) => {
        checkEditTarget(invFund);
      });
    }
    if (investmentType === INVESTMENT_TYPES.LIMITED_PARTNER_FUND) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkEditTarget(lpFund);
      });
    }
    if (investmentType === INVESTMENT_TYPES.FIREWORK) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkEditTarget(lpFund);
      });
    }
    if (investmentType === INVESTMENT_TYPES.PROMETHEAN) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkEditTarget(lpFund);
      });
    }
  }

  handleChangeField(event) {
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    const investmentType = event.target.dataset.type;

    const { admin } = this.props;
    const property = event.target.dataset.property;
    const fund = admin.dashboardSelectedFund;
    const checkChangeTarget = (invFund) => {
        const newFund = invFund;
        switch (property) {
          case 'notes':
            newFund.notes = event.target.value;
            break;
          case 'ofac':
            newFund.ofac = event.target.value;
            break;
          case 'lpGroup':
            newFund.lpGroup = event.target.value;
            break;
          default:
            break;
        }
        this.setInvestorFund(newFund);
    };

    if (investmentType === INVESTMENT_TYPES.INVESTOR_FUND) {
      const investorFund = fund.investorFunds.find((invFund) => invFund._id === fundId);
        checkChangeTarget(investorFund);
    }
    if (investmentType === INVESTMENT_TYPES.LIMITED_PARTNER_FUND) {
      const limitedPartnerFund = fund.limitedPartnerFunds.find((lpFund) => lpFund._id === fundId);
        checkChangeTarget(limitedPartnerFund);
    }
    if (investmentType === INVESTMENT_TYPES.FIREWORK) {
      const limitedPartnerFund = fund.limitedPartnerFunds.find((lpFund) => lpFund._id === fundId);
        checkChangeTarget(limitedPartnerFund);
    }
     if (investmentType === INVESTMENT_TYPES.PROMETHEAN) {
      const limitedPartnerFund = fund.limitedPartnerFunds.find((lpFund) => lpFund._id === fundId);
        checkChangeTarget(limitedPartnerFund);
    }
  }

  handleSaveField(event) {
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    const investmentType = event.target.dataset.type;
    const { admin, updateInvestorFund } = this.props;
    const property = event.target.dataset.property;
    const fund = admin.dashboardSelectedFund;
    const checkSaveTarget = (invFund) => {
      if (invFund._id === fundId) {
        const newFund = invFund;
        switch (property) {
          case 'notes':
            newFund.notes = event.target.value;
            newFund.editingNotes = false;
            break;
          case 'ofac':
            newFund.ofac = event.target.value;
            newFund.editingOFAC = false;
            break;
          case 'lpGroup':
            newFund.lpGroup = event.target.value;
            newFund.editingLpGroup = false;
            break;
          default:
            break;
        }
        this.setInvestorFund(newFund);
        updateInvestorFund(newFund, this.setCheckedFunds);
      }
    };
    if (investmentType === INVESTMENT_TYPES.INVESTOR_FUND) {
      fund.investorFunds.forEach((invFund) => {
        checkSaveTarget(invFund);
      });
    }
    if (investmentType === INVESTMENT_TYPES.LIMITED_PARTNER_FUND) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkSaveTarget(lpFund);
      });
    }
    if (investmentType === INVESTMENT_TYPES.FIREWORK) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkSaveTarget(lpFund);
      });
    }
     if (investmentType === INVESTMENT_TYPES.PROMETHEAN) {
      fund.limitedPartnerFunds.forEach((lpFund) => {
        checkSaveTarget(lpFund);
      });
    }
  }

  getInvestorFundQuestions(questionsArray, isShortIQ) {
    const questions = getInvestorFundQuestions(questionsArray, isShortIQ);
    questions.accredited = this.getAccreditedQuestion(questionsArray);
    questions.purchaser = this.getPurchaserQuestion(questionsArray);
    questions.client =
      this.getPurchaserQuestion(questionsArray) === 'Yes'
        ? 'Yes'
        : this.getClientQuestion(questionsArray); // GUN 409
    questions.governmentEntity = _.find(
      questionsArray,
      (item) => item.descriptionIdentifier === iqID.id_special_entities_3_a
    );

    return questions;
  }
  getAccreditedQuestion(questions) {
    let accredited = 'No';
    questions.forEach((question) => {
      if (
        question.answerIdentifier ===
          `${iqID.id_investor_representations_1}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_2}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_3}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_4}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_5}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_6}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_6}_extra_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_7}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_8}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_9}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_10}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_11}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_investor_representations_12}_yes`
      ) {
        accredited = 'Yes';
      }
    });
    return accredited;
  }

  getPurchaserQuestion(questions) {
    let purchaser = 'No';
    questions.forEach((question) => {
      if (
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_1}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_2}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_3}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_4}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_5}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_6}_yes` ||
        question.answerIdentifier ===
          `${iqID.id_purchase_representations_7}_yes`
      ) {
        purchaser = 'Yes';
      }
    });
    return purchaser;
  }

  getClientQuestion(questions) {
    let client = 'No';
    questions.forEach((question) => {
      if (
        question.answerIdentifier === `${iqID.id_client_representations_1}_yes` || question.answerIdentifier === `${iqID.id_client_representations_2}_yes`
      ) {
        client = 'Yes';
      }
    });
    return client;
  }

  redirectToInvestorQuestionnaire(event) {
    event.preventDefault();
    const investmentType = event.target.dataset.type;
    const queryParam =
      investmentType === INVESTMENT_TYPES.INVESTOR_FUND ? 'if=' : 'lpf=';
    if (typeof window !== 'undefined') {
      window.open(
        `/access/login/?${queryParam}${event.target.getAttribute(
          'data-identifier'
        )}`,
        '_blank'
      );
    }
  }

  handleChangeFilterItem(event) {
    event.preventDefault();
    const identifier = event.target.attributes['data-identifier'].value;

    if (this.state.sortByCol === identifier) {
      this.setState({
        sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortByCol: identifier,
        sortDirection: 'desc',
        sortKey: event.target.text,
      });
    }
  }

  // TODO, need to find a better way
  // to define table columns along with sorting functionality
  // currently this does not match filterStatus impl.
  renderTableContents() {
    const { admin } = this.props;
    const results = [];

    const fundIdentifier = admin.dashboardSelectedFund && admin.dashboardSelectedFund.fundType ? admin.dashboardSelectedFund.fundType.identifier : '';
    const { includesFormPFQuestion } = admin.dashboardSelectedFund;

    const isTypeOSAGE = fundIdentifier === FUND_TYPES.OSAGE.id;

    const isTypeMultiFund = 
    admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || //short multi-fund
      admin.dashboardSelectedFund.fundType &&   
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id;  //limited Partnership multi-fund
      
    const isTypeAccel = fundIdentifier === FUND_TYPES.ACCEL.id || fundIdentifier === FUND_TYPES.ACCEL_ENTREPRENEURS.id;
    const isTypeAccelMain = fundIdentifier === FUND_TYPES.ACCELMAIN.id;

    const isLimitedPartnership = fundIdentifier === FUND_TYPES.LIMITED_PARTNER.id;

    const isTypeTcgCrossOver =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.TCG_CROSSOVER.id;
    
    const isTypeBitKraft =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.BITKRAFT.id;
    
    const isTypeElephantPartner =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.ELEPHANT_PARTNER.id;

    const fundLegalName = isTypeMultiFund && admin.dashboardSelectedFund && admin.dashboardSelectedFund.fundLegalName;
    const fund2LegalName = isTypeMultiFund && admin.dashboardSelectedFund && admin.dashboardSelectedFund.fund2LegalName;
    const fund3LegalName = isTypeMultiFund && admin.dashboardSelectedFund && 
    admin.dashboardSelectedFund.fund3LegalName ? admin.dashboardSelectedFund.fund3LegalName : '';

    let addExtraField = false;
    if (admin.dashboardSelectedFund && admin.dashboardSelectedFund.fundType) {
      addExtraField =
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.LIMITED_PARTNER_ABBR.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.IOLAR.id ||
        admin.dashboardSelectedFund.fundType.identifier === 
        FUND_TYPES.HCM.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.FIREWORK.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.PROMETHEAN.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.INITIALIZED.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.QUESTACAPITAL.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.FORESITE.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
        FUND_TYPES.ACCEL.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
        FUND_TYPES.ACCEL_ENTREPRENEURS.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
        FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
        FUND_TYPES.ACCELMAIN.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
         FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id ||
        admin.dashboardSelectedFund.fundType.identifier ===
          FUND_TYPES.FORESITELABS.id;
    }

    // filter(Boolean) and update selected values dinamycally to void updating
    let headers = [
      {
        title: 'Status',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-status-col'
      },
      {
        title: 'LP Group',
        class: 'investment-sm horizontal-col sticky-col header-col-bg',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-lpgroup-col'
      },
      {
        title: 'Name',
        class: 'wider-investment-box investment-lg horizontal-col sticky-col header-col-bg',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-name'
      },
      {
        title: 'Notes to Client',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-notes-col'
      },
      {
        title: 'Login Email',
        class: 'wider-investment-box investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-email-address-res-col'   
      },
      {
        title: 'Login Method',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-login-method-col'
      },
      {
        title: 'Total All Funds',
        class: 'investment-md horizontal-col capital-right-alignment',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-total-all-fund-col'
      },
      {
        title: 'Subscription',
        class: 'investment-lg horizontal-col capital-right-alignment',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-subscription-col'
      },
      {
        title: `${isTypeMultiFund ? `${fundLegalName} ` : ''}Capital Committed`,
        class: 'investment-lg horizontal-col capital-right-alignment',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-capital-col'
      },
      isTypeMultiFund
        ? {
        title: `${fund2LegalName} Capital Committed`,
        class: 'investment-lg horizontal-col capital-right-alignment',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-capital-2-col'
        } : 
      null,
      isTypeMultiFund && fund3LegalName
        ? {
        title: `${fund3LegalName} Capital Committed`,
        class: 'investment-lg horizontal-col capital-right-alignment',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-capital-3-col'
        } : 
      null,
      {
        title: 'Investor type',
        class: 'wider-investment-box horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-investor-type-col'
      },
      {
        title: 'Contact Person',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-contact-person-col'
      },
      {
        title: 'Contact Email',
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-email-primary-col'
      },
      {
        title: 'Phone',
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-contact-pri-phone-col'
      },
      {
        title: 'Received',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-received-col'
      },
      {
        title: 'Signed',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-signed-col'
      },
      {
        title: 'Closing Date',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-closing-date-col'
      },
      {
        title: 'Place of Residence/Business',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-place-residence-col'
      },
      {
        title: 'Wire Info?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-wire-col'
      },
      {
        title: 'Natural Person',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-natural-person-col'
      },
      {
        title: 'US Person (SEC Act)?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-us-person-col'
      },
      {
        title: 'US Person (IRC)?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-us2-person-col'
      },
      {
        title: 'FINRA',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-finra-col'
      },
      {
        title: 'FOIA(a)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-a-col'
      },
      {
        title: 'FOIA(b)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-b-col'
      },
      {
        title: 'FOIA(c)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-c-col'
      },
      {
        title: 'FOIA(d)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-d-col'
      },
      {
        title: 'FOIA(e)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-e-col'
      },
      {
        title: 'FOIA(f)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foia-f-col'
      },
      {
        title: 'Bad Actor?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-bad-actor-col'
      },
       isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ?
       {
        title: 'CFIUS Foreign Person',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'cfiusForeignPerson-col'
      }
      : null,
       isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ?
      {
        title: 'Foreign Govt Controlled',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foreignGovtControlled-col'
      }
      : null,

      admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.HCM.id
        ? {
            title: 'HCM',
            class: 'investment-sm horizontal-col',
            filteringArrow: true,
            filteringHandler: this.handleChangeFilterItem,
            filteringOrder: this.state.sortDirection,
            filteringId: 'investment-hcm-col'
          }
        : null,
      {
        title: 'Accredited Investor?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-accredited-col'
      },
      {
        title: 'Qualified Purchaser?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-purchaser-col'
      },
      {
        title: 'Qualified Client?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-client-col'
      },
      {
        title: 'FATF Country',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-country-col'
      },
      {
        title: 'Customer of Wiring Bank?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-wiring-bank-col'
      },
      {
        title: 'If no, Country?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-customer-of-bank-col'
      },
      {
        title: 'ERISA(a)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-erisa-a-col'
      },
      {
        title: 'ERISA(b)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-erisa-b-col'
      },
      {
        title: '% of Plan Assets',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-plan-perc-col'
      },
      {
        title: 'Lookthrough (a)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-potential-look-a-col'
      },
      {
        title: 'Lookthrough (b)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-potential-look-b-col'
      },
      {
        title: 'Lookthrough (c)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-potential-look-c-col'
      },
      {
        title: 'Lookthrough (d)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-potential-look-d-col'
      },
      {
        title: 'Lookthrough (e)',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-potential-look-e-col'
      },
      {
        title: 'Government Entity?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-gov-col'
      },
      {
        title: 'BHCA Partner?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-bhca-col'
      },
      {
        title: 'Foundation Partner?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-foundation-col'
      },
      {
        title: 'Public Pension Partner?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-pension-col'
      },
      {
        title: 'Fund-of-Funds?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-fund-of-funds-col'
      },
      {
        title: 'Tax Exempt',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId:'investment-tax-exempt-col'
      },
      {
        title: 'Taxed as a Partnership',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-taxed-parter-col'
      },
      {
        title: '100% U.S. Beneficial Owners',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-us-beneficial-owner-col'
      },
      {
        title: 'Tax Exempt Beneficial Owners',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-tax-beneficial-owner-col'
      },
       isTypeTcgCrossOver ?
      {
        title: 'Determination of Restricted Person Status',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-restricted-person-status-col'
      }
      : null,
      isTypeTcgCrossOver ?
      {
        title: 'Determination of Covered Investor Status',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-covered-investor-status-col'
      }      
      : null,
      isTypeTcgCrossOver ?
      {
        title: 'Determination of Exempted Entity Status',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-bad-exempted-entity-status-col'
      }
      : null,
      {
        title: 'Escrow',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-ofac-col'
      },
      {
        title: 'Exceptions',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-exceptions-col'
      },
      {
        title: `Quarterly and Annual Reports ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'reports-primary-name-col'
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Quarterly and Annual Reports ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Quarterly Partner's Capital Statements ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Quarterly Partner's Capital Statements ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Tax Information/K-1s ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Tax Information/K-1s ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Capital Call Notices ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Capital Call Notices ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Cash and Stock Distributions ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Cash and Stock Distributions ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Legal Documents ${
          isTypeOSAGE ? 'Second' : 'Primary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: `Legal Documents ${
          isTypeOSAGE ? 'Third' : 'Secondary'
        } Contact Name`,
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: ''
      },
      {
        title: 'Email Address',
        class: 'investment-lg horizontal-col',
      },
      {
        title: 'Phone Number',
        class: 'investment-md horizontal-col',
      },
      {
        title: 'Bank Name',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'bank-name-col'
      },
      {
        title: `${isTypeAccel ? 'For Credit Account Name' : 'Accountholder Name'}`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'account-name-col'
      },
      {
        title: `${isTypeAccel ? 'Bank SWIFT' : 'Swift Code'}`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'swift-code-col'
      },
      {
        title: `${isTypeAccel ? 'For Credit Account Number' : 'Account Number'}`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'account-number-col'
      },
      {
        title: `${isTypeAccel ? 'Bank ABA' : 'Bank ABA#' }`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'bank-aba-col'
      },
      {
        title: `${isTypeAccel ? 'For Credit Account Name' : 'For Further Credit to' }`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'further-credit-col'
      },
      {
        title: `${isTypeAccel ? 'Branch Address' : 'City/State/Country' }`,
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'location-col'
      },
      {
        title: 'Finra Rule 5130 1',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'rule5130-finra-new-issues-1-col'
      },
      {
        title: 'Finra Rule 5130 2',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'rule5130-finra-new-issues-2-col'
      },
      {
        title: 'Finra Rule 5131 1',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'rule5131-finra-new-issues-1-col'
      },
      {
        title: 'Finra Rule 5131 2',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'rule5131-finra-new-issues-2-col'
      }, 
      isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ?
      null :
      {
        title: 'CFIUS Foreign Person',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'cfiusForeignPerson-col'
      },
      isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ?
      null :
      {
        title: 'Foreign Govt Controlled',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foreignGovtControlled-col'
      },
      {
        title: 'Institutional Account Status',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-institutional-account-status-col'
      },
      {
        title: 'Foresite Investor Type',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-investorType-col'
      },
      {
        title: 'Foresite FINRA Affiliation',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresiteFinraAffiliation-type'
      },
      {
        title: 'Foresite FINRA Affiliation Further Details',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresiteFinraAffiliationFurtherDetails-type'
      },
      {
        title: 'Foresite Public Company Affiliation',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresitePublicCompanyAffiliation-type'
      },
      {
        title: 'Foresite Public Company Affiliation Further Details',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresitePublicCompanyAffiliation_opt-type'
      },
      {
        title: 'Foresite Entities',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresiteEntities-type'
      },
      {
        title: 'Foresite Entities Further Details',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foresite-foresiteEntities_opt-type'
      },
      includesFormPFQuestion ?
      {
        title: 'Beneficial Ownership for Advisers?',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'investment-beneficial-ownership-col'
      }
      : null,
        isTypeAccelMain ?
      {
        title: 'Foreign Partner',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'foreign-partner-col'
      }
      : null,
        isTypeAccelMain ?
      {
        title: 'Retirement Plan',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'retirementPlan-col'
      }
      : null,
        isTypeAccelMain ?
      {
        title: 'Rep 7 EU and UK',
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'Rep7EUandUK-col'
      }
      : null,
      isTypeAccelMain ?
      {
        title: 'Rep 8 Switzerland',
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'Rep8Switzerland-col'
      } : null,
      {
        title: 'Part A: FINRA Rule 5130',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'part-a-finra-5130-col'
      },
      {
        title: 'Part B: FINRA Rule 5131',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'part-b-finra-5131-col'
      },
      {
        title: 'Contact Person Address',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'contact-person-address-col'
      },
      {
        title: 'Creation Date',
        class: 'investment-md horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        filteringOrder: this.state.sortDirection,
        filteringId: 'created-at-col'
      },
    ];

    headers = headers.filter(Boolean).map((_headerEntry, sortIndex) => {
      _headerEntry.selected = this.state.sortByCol === sortIndex;
      return _headerEntry;
    });

    const trackingSheetRowTemplate = (_investment, _questions) => {
      const emptyDefaultValue = _investment.submitted ? 'N/A' : '';
      let place = 'N/A';
      let countryPlace = null;
      let location = null;
      let IQ_VERSION = LimitedPartnerV1;

      if (isTypeAccelMain && _questions) {
        _questions.look_b = _questions.look_e;
      }

     function handleDefaultValue(iqId, govt) {
      if (_investment.questionnaire && _investment.questionnaire.questions) {
          if (govt && govt === 'GovtControlled') {
            const iq = _.find(_investment.questionnaire.questions, (instance) => instance.descriptionIdentifier === iqId);
            if (!iq || oldVersionGovtControlled.includes(_investment.questionnaire.iqVersion)) {
              return _.find(_investment.questionnaire.questions, 
                (instance) => instance.answerIdentifier === `${iqID.id_foreign_person_2_or}_yes`)
                ? 'Yes'
                : 'No';
            }
            return iq && iq.answerIdentifier === `${iqId}_yes` ? 'Yes' : 'No';
          } else {
            const iq = _.find(_investment.questionnaire.questions, (instance) => instance.descriptionIdentifier === iqId);
            return iq && iq.answerIdentifier === `${iqId}_yes` ? 'No' : 'Yes';
          }
        } 
      }
 

      if (_investment.questionnaire && _investment.questionnaire.iqVersion) {
        IQ_VERSION = _investment.questionnaire.iqVersion;
      }
      if (_investment.generalInfo) {
        countryPlace = _investment.generalInfo.countryPlace ? _investment.generalInfo.countryPlace : 'US';
        if (
          _questions.naturalPerson &&
          _questions.naturalPerson.answer === 'Yes'
        ) {
          location = _investment.generalInfo.principalPlaceOfResidencePrimaryContact;
          place = _investment.generalInfo ? getFormattedLocation(
            countryPlace,
            location,
            _investment.generalInfo.principalPlaceOfResidence
          ) : 'N/A';
        } else {
          location = _investment.generalInfo.principalPlaceOfBusinessPrimaryContact;
          place = _investment.generalInfo ? getFormattedLocation(
            countryPlace,
            location,
            _investment.generalInfo.principalPlaceOfBusiness
          ) : 'N/A';
        }
      }

      let cfiusForeignPerson = emptyDefaultValue;
      let foreignGovtControlled = emptyDefaultValue;

      let foresiteInvestorType =  emptyDefaultValue;
      let foresiteFinraAffiliation =  emptyDefaultValue;
      let foresiteFinraAffiliationFurtherDetails =emptyDefaultValue;
      let foresitePublicCompanyAffiliation =  emptyDefaultValue;
      let foresitePublicCompanyAffiliation_opt = emptyDefaultValue;
      let foresiteEntities = emptyDefaultValue;
      let foresiteEntities_opt = emptyDefaultValue;
      let hcm = emptyDefaultValue;

      const tcgCross = _investment && admin.dashboardSelectedFund.fundType && admin.dashboardSelectedFund.fundType.identifier === 'tcg-crossover'
      const accelMain = _investment && admin.dashboardSelectedFund.fundType && admin.dashboardSelectedFund.fundType.identifier === 'accel-main'
      const isSrOne = _investment && admin.dashboardSelectedFund.fundType && admin.dashboardSelectedFund.fundType.identifier === 'sr-one'

      let beneficialOwnershipAdvisers = includesFormPFQuestion ? this.beneficialOwnershipAdvisersAnswer(_questions) : emptyDefaultValue;
      let restrictedPersonStatus =  tcgCross ? this.restrictedPersonStatusAnswer(_questions) : emptyDefaultValue;
      let coveredInvestorStatus =  tcgCross ? this.coveredInvestorStatusAnswer(_questions) : emptyDefaultValue;
      let determinationExemptedEntity =  tcgCross ? this.determinationExemptedEntityAnswer(_questions)  : emptyDefaultValue;
    
      let RepWarranty7UK = accelMain ? this.RepWarranty7UKAnswer(_questions) : emptyDefaultValue;
      let RepWarranty8Switzerland = accelMain ? this.RepWarranty8SwitzerlandAnswer(_questions)   : emptyDefaultValue;

      const partAFinra5130 = isSrOne ? this.partAFinra5130Answer(_questions) : emptyDefaultValue;
      const partBFinra5131 = isSrOne ? this.partBFinra5131Answer(_questions) : emptyDefaultValue;

      const parsedQuestions = getInvestorFundQuestions(
        _investment.questionnaire ? _investment.questionnaire.questions : [],
        this.isShortIQ
      );
      if (_investment.submitted) {
        switch (IQ_VERSION) {
          
          case LimitedPartnerV2:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
          break;
          
          case SROneV1:
          case CaymanV1:
          case CaymanV2:
          case AudereV1:
          case AudereV2:
          case LimitedPartnershipMFV1:
          case LimitedPartnershipMFV2:
          case LimitedPartnershipMFV3:  
          case PrometheanV1:
          case PrometheanV2:
          case FrazierV1:
          case LimitedPartnerV3:
          case LimitedPartnerV4:
          case LimitedPartnerV5:
          case LimitedPartnerV6:
          case LimitedPartnerV7:
          case LimitedPartnerV8:
          case LimitedPartnerV8_1:
          case LimitedPartnerV9:
          case LimitedPartnerV10:
          case LimitedPartnerV11:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
            }
          break;

          case LimitedLiabilityCompanyV1:
          case LimitedLiabilityCompanyV2:
          case LimitedLiabilityCompanyV3:
          case LimitedLiabilityCompanyV4:
          case LimitedLiabilityCompanyV5:
          case LimitedLiabilityCompanyV6:
          case LimitedLiabilityCompanyV6_1:
          case LimitedLiabilityCompanyV7:
          case LimitedLiabilityCompanyV8:
          case LimitedLiabilityCompanyV9:
          case QuestaCapitalV1:
          case QuestaCapitalV2:
          case QuestaCapitalV3:
          case QuestaCapitalV4:
          case QuestaCapitalV5:
          case QuestaCapitalV6:
          case QuestaCapitalV7:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
            }
          break;

         
          case ForesiteV1:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);

            if (parsedQuestions.foresite_investor_type) {
              foresiteInvestorType =
                parsedQuestions.foresite_investor_type.displayName;
            }

            if (parsedQuestions.foresiteFinraAffiliation) {
              foresiteFinraAffiliation =
                parsedQuestions.foresiteFinraAffiliation.answer;
            }

            if (parsedQuestions.foresiteFinraAffiliationFurtherDetails) {
              foresiteFinraAffiliationFurtherDetails =
                parsedQuestions.foresiteFinraAffiliationFurtherDetails.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation) {
              foresitePublicCompanyAffiliation =
                parsedQuestions.foresitePublicCompanyAffiliation.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation_opt) {
              foresitePublicCompanyAffiliation_opt =
                parsedQuestions.foresitePublicCompanyAffiliation_opt.answer;
            }

            if (parsedQuestions.foresiteEntities) {
              foresiteEntities = parsedQuestions.foresiteEntities.answer;
            }

            if (parsedQuestions.foresiteEntities_opt) {
              foresiteEntities_opt =
                parsedQuestions.foresiteEntities_opt.answer;
            }
            break;

          case ForesiteV2:
          case ForesiteV3:
          case ForesiteV4:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
          

            if (parsedQuestions.foresite_investor_type) {
              foresiteInvestorType =
                parsedQuestions.foresite_investor_type.displayName;
            }

            if (parsedQuestions.foresiteFinraAffiliation) {
              foresiteFinraAffiliation =
                parsedQuestions.foresiteFinraAffiliation.answer;
            }

            if (parsedQuestions.foresiteFinraAffiliationFurtherDetails) {
              foresiteFinraAffiliationFurtherDetails =
                parsedQuestions.foresiteFinraAffiliationFurtherDetails.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation) {
              foresitePublicCompanyAffiliation =
                parsedQuestions.foresitePublicCompanyAffiliation.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation_opt) {
              foresitePublicCompanyAffiliation_opt =
                parsedQuestions.foresitePublicCompanyAffiliation_opt.answer;
            }

            if (parsedQuestions.foresiteEntities) {
              foresiteEntities = parsedQuestions.foresiteEntities.answer;
            }

            if (parsedQuestions.foresiteEntities_opt) {
              foresiteEntities_opt =
                parsedQuestions.foresiteEntities_opt.answer;
            }

            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
           
            }
            break;

          case ForesiteV4:
          case ForesiteV5:
          case ForesiteV6:
          case ForesiteV7:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);

            if (parsedQuestions.foresite_investor_type) {
              foresiteInvestorType =
                parsedQuestions.foresite_investor_type.displayName;
            }

            if (parsedQuestions.foresiteFinraAffiliation) {
              foresiteFinraAffiliation =
                parsedQuestions.foresiteFinraAffiliation.answer;
            }

            if (parsedQuestions.foresiteFinraAffiliationFurtherDetails) {
              foresiteFinraAffiliationFurtherDetails =
                parsedQuestions.foresiteFinraAffiliationFurtherDetails.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation) {
              foresitePublicCompanyAffiliation =
                parsedQuestions.foresitePublicCompanyAffiliation.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation_opt) {
              foresitePublicCompanyAffiliation_opt =
                parsedQuestions.foresitePublicCompanyAffiliation_opt.answer;
            }

            if (parsedQuestions.foresiteEntities) {
              foresiteEntities = parsedQuestions.foresiteEntities.answer;
            }

            if (parsedQuestions.foresiteEntities_opt) {
              foresiteEntities_opt =
                parsedQuestions.foresiteEntities_opt.answer;
            }

            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
              
            }
            break;

          case InitializedV1:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
           
            break;

          case InitializedV2:
          case InitializedV3:
          case InitializedV4:
          case InitializedV5:
          case InitializedV6:
          case InitializedV7:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
           
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
             
            }
            break;
          case HCMV1:
          case HCMV2:
          case HCMV3:
          case HCMV4:
            //based on the selection find the answer data from the config file in this case the questions for this specific version
            if (parsedQuestions.hcm && parsedQuestions.hcm.length) {
              const mainQuestion = parsedQuestions.hcm[0];
              const mainHCMQuestionDescription =
                questionsHCMv1.identificationofInvestorStatus.find(
                  (q) => q.id === 'id_investor_status_7_hcm'
                );
              const selectedOptionDescription =
                mainHCMQuestionDescription.options.find(
                  (sopt) => sopt.id === mainQuestion.descriptionIdentifier
                );

              const triggerQuestion = parsedQuestions.hcm[1];
              hcm = `${selectedOptionDescription.displayName}${
                triggerQuestion && triggerQuestion.answer
                  ? ` - ${triggerQuestion.answer}`
                  : ''
              }`;
            }

            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
          
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
           
            }
            break;
          case ForesiteLabsV1:
          case ForesiteLabsV2:
          case ForesiteLabsV3:
          case ForesiteLabsV4:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);

            if (parsedQuestions.foresite_investor_type) {
              foresiteInvestorType =
                parsedQuestions.foresite_investor_type.displayName;
            }

            if (parsedQuestions.foresiteFinraAffiliation) {
              foresiteFinraAffiliation =
                parsedQuestions.foresiteFinraAffiliation.answer;
            }

            if (parsedQuestions.foresiteFinraAffiliationFurtherDetails) {
              foresiteFinraAffiliationFurtherDetails =
                parsedQuestions.foresiteFinraAffiliationFurtherDetails.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation) {
              foresitePublicCompanyAffiliation =
                parsedQuestions.foresitePublicCompanyAffiliation.answer;
            }

            if (parsedQuestions.foresitePublicCompanyAffiliation_opt) {
              foresitePublicCompanyAffiliation_opt =
                parsedQuestions.foresitePublicCompanyAffiliation_opt.answer;
            }

            if (parsedQuestions.foresiteEntities) {
              foresiteEntities = parsedQuestions.foresiteEntities.answer;
            }

            if (parsedQuestions.foresiteEntities_opt) {
              foresiteEntities_opt =
                parsedQuestions.foresiteEntities_opt.answer;
            }

            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
               
            }
            break;

          case LPABBRV1:
          case LPABBRV1_1:
          case LPABBRV2:
          case LPABBRV3:
          case LPABBRV4:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
            this.isShortIQ = true;
           
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
              
            }
            break;
          
          case IOLARV1:
          case IOLARV2:
            this.isShortIQ = true;
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
               foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
            }
            break;

          case OSAGEV1:
          case OSAGEV2:
          case BITKRAFTV1:
          case BITKRAFTV2:
          case ElephantPartnerV1:
          case ElephantPartnerV2:
          case TcgCrossOverV1:
          case TcgCrossOverV2:
          case NewLimitedPartnerV1:
          case NewLimitedPartnerV2:
          case FireworkV1:
          case FireworkV2:
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
            
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
               
            }
            break;

          case AccelV1:
          case AccelV2:
          case AccelV3:
          case AccelMainV1:
          case AccelEntrepreneursV1:
          case AccelEtpnReturningV1:
          case AccelEtpnReturningV2:
          case LPMULTIFUNDV1:
          case LPMULTIFUNDV2:
          case LPMULTIFUNDV3:
            this.isShortIQ = true;
            cfiusForeignPerson = handleDefaultValue(iqID.id_foreign_person_or);
    
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              foreignGovtControlled = handleDefaultValue(iqID.id_foreign_person_2_1, 'GovtControlled');
              
            }
            break;

          default:
            break;
        }
      }
      let investmentEmailAddressResCol = 'N/A';
      let investmentLoginMethod = 'Account';

      if (_investment.investor && _investment.investor.username) {
        investmentEmailAddressResCol = _investment.investor.username;
        investmentLoginMethod = 'Guest';
      } else if (_investment.limitedPartner) {
        if (_investment.limitedPartner.email) {
          investmentEmailAddressResCol = _investment.limitedPartner.email;
        } else if (
          _investment.limitedPartner.organization.iqUsers[0] &&
          _investment.limitedPartner.organization.iqUsers[0].email
        ) {
          investmentEmailAddressResCol =
            _investment.limitedPartner.organization.iqUsers[0].email;
        }
      }

       const isTypeTcgCrossOver =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.TCG_CROSSOVER.id;

      const isTypeBitKraft =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.BITKRAFT.id;

      const isTypeElephantPartner =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.ELEPHANT_PARTNER.id;

      const isTypeHCM =
        admin.dashboardSelectedFund &&
        admin.dashboardSelectedFund.fundType &&
        admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.HCM.id;

      results.push(
        [
          {
            id: 'investment-status-col',
            displayName: _investment.status
              ? _investment.status
              : STATUS_TYPES.IN_PROGRESS.label,
            type: 'status',
            class: `investment-md horizontal-col ${
              this.state.preloader && 'disabled-col-bg'
            }`,
            // icon: `fa ${_investment.icon} investment-check ${
            //   this.state.preloader && 'disabled-col-bg'
            // }`,
            // iconPos: 'left',
            dataTarget: _investment._id,
            closedAt: _investment.closedAt,
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            clickHandler: this.handleInvestorFundCheck,
          },
          {
            id: 'investment-lpgroup-col',
            displayName: _investment.lpGroup ? _investment.lpGroup : '',
            type: 'description',
            class: 'investment-sm horizontal-col sticky-col white-col-bg',
            editable: true,
            editing: _investment.editingLpGroup,
            actionResult: true,
            editingProperty: 'lpGroup',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            editFieldHandler: this.handleEditField,
            actionResultIdentifier: _investment._id,
            inputIdentifier: `${_investment._id}-lpgroup`,
            actionCallback: this.handleSaveField,
            inputChangeHandler: this.handleChangeField,
            emptyText: 'N/A',
          },
          {
            id: 'investment-name',
            displayName: _investment.generalInfo && _investment.generalInfo.name
                ? _investment.generalInfo.name
                : emptyDefaultValue,
            actionResult: true,
            actionResultIdentifier: _investment._id,
            actionResultText: '',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            actionCallback: this.redirectToInvestorQuestionnaire,
            type: 'item',
            class: 'wider-investment-box investment-md horizontal-col sticky-col white-col-bg align-with-header',
          },
          {
            id: 'investment-notes-col',
            displayName: _investment.notes ? _investment.notes : '',
            type: 'description',
            class: 'investment-sm horizontal-col',
            editable: true,
            editing: _investment.editingNotes,
            actionResult: true,
            editingProperty: 'notes',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            editFieldHandler: this.handleEditField,
            actionResultIdentifier: _investment._id,
            inputIdentifier: `${_investment._id}-notes`,
            actionCallback: this.handleSaveField,
            inputChangeHandler: this.handleChangeField,
            emptyText: 'N/A',
          },
          {
            id: 'investment-email-address-res-col',
            displayName: investmentEmailAddressResCol,
            actionResult: true,
            actionResultIdentifier: _investment._id,
            actionResultText: '',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            actionCallback: this.redirectToInvestorQuestionnaire,
            type: 'description',
            class: 'wider-investment-box investment-lg horizontal-col overflow-scroll-large-content align-with-header',
          },
          {
            id: 'investment-login-method-col',
            displayName: investmentLoginMethod,
            actionResult: true,
            actionResultIdentifier: _investment._id,
            actionResultText: '',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            actionCallback: this.redirectToInvestorQuestionnaire,
            type: 'description',
            class: 'investment-sm horizontal-col overflow-scroll-large-content align-with-header',
          },
          {
            id: 'investment-total-all-fund-col',
            displayName: _investment.generalInfo && _investment.generalInfo.totalAllFund &&
              _investment.generalInfo.totalAllFund !== ('0' || 0 || '0.00')
                ? this.capitalCommitmentCurrency(_investment.generalInfo.totalAllFund, admin.dashboardSelectedFund)
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col capital-right-alignment',
          },
          {
            id: 'investment-subcription-col',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalCommitment &&
              (_investment.generalInfo.capitalCommitment !== '0' || _investment.generalInfo.capitalCommitment !== '0.00')
              ? this.capitalCommitmentCurrency(_investment.generalInfo.capitalCommitment, admin.dashboardSelectedFund)
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col capital-right-alignment',
          },
          {
            id: 'investment-capital-col',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalCommitment &&
              (_investment.generalInfo.capitalCommitment !== '0' || _investment.generalInfo.capitalCommitment !== '0.00')
              ? this.capitalCommitmentCurrency(_investment.generalInfo.capitalCommitment, admin.dashboardSelectedFund)
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col capital-right-alignment',
          },
           isTypeMultiFund
            ? {
                 id: 'investment-capital-2-col',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalCommitmentFund2 &&
              (_investment.generalInfo.capitalCommitmentFund2  !== '0' || _investment.generalInfo.capitalCommitmentFund2 !== '0.00')
                ? this.capitalCommitmentCurrency(_investment.generalInfo.capitalCommitmentFund2, admin.dashboardSelectedFund)
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col capital-right-alignment',
              }
            : null,
          isTypeMultiFund && fund3LegalName
            ? {
                 id: 'investment-capital-3-col',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalCommitmentFund3 &&
              (_investment.generalInfo.capitalCommitmentFund3 !== '0' || _investment.generalInfo.capitalCommitmentFund3 !== '0.00')
                ? this.capitalCommitmentCurrency(_investment.generalInfo.capitalCommitmentFund3, admin.dashboardSelectedFund)
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col capital-right-alignment',
              }
            : null,
          {
            id: 'investment-investor-type-col',
            displayName: _investment.generalInfo && _investment.generalInfo.types.length
              ? _investment.generalInfo.types[0]
              : emptyDefaultValue,
            type: 'description',
            class: 'wider-investment-box horizontal-col',
          },
          {
            id: 'investment-contact-person-col',
            displayName: _investment.generalInfo && _investment.generalInfo.namePrimaryContact
              ? _investment.generalInfo.namePrimaryContact
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-email-primary-col',
            displayName: _investment.generalInfo && _investment.generalInfo.emailPrimaryContact
              ? _investment.generalInfo.emailPrimaryContact
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'investment-contact-pri-phone-col',
            displayName: _investment.generalInfo && _investment.generalInfo.phonePrimaryContact
              ? _investment.generalInfo.phonePrimaryContact
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'investment-received-col',
            displayName: _investment.submittedAt && _investment.submittedAt !== ''
              ? new DateTimeZone(_investment.submittedAt).toDateString()
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-signed-col',
            displayName: _investment.signedAt && _investment.signedAt !== ''
                ? new DateTimeZone(_investment.signedAt).toDateString()
                : 'No',
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-closing-date-col',
            displayName: _investment.closedAt && _investment.closedAt !== '' && _investment.status === STATUS_TYPES.CLOSED.label
                ? new DateTimeZone(_investment.closedAt).toDateString()
                : 'No',
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-place-residence-col',
            displayName: place && place !== ''
                ? place
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-wire-col',
            displayName: _investment.generalInfo &&
              _investment.generalInfo.bankName &&
              _investment.generalInfo.acctNumber &&
              _investment.generalInfo.swiftCode
              ? 'Yes'
              : _investment.submitted ? 'No' : '',
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-natural-person-col',
            displayName: _questions.naturalPerson && _questions.naturalPerson.answer && _questions.naturalPerson.answer !== ''
              ? _questions.naturalPerson.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-us-person-col',
            displayName: _questions.usPerson && _questions.usPerson.answer && _questions.usPerson.answer !== ''
              ? _questions.usPerson.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-us2-person-col',
            displayName: _questions.usPersonIRC && _questions.usPersonIRC.answer && _questions.usPersonIRC.answer !== ''
              ? _questions.usPersonIRC.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-finra-col',
            displayName: _questions.finraAffiliation && _questions.finraAffiliation.answer && _questions.finraAffiliation.answer !== ''
              ? _questions.finraAffiliation.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-a-col',
            displayName: _questions.foia_a && _questions.foia_a.answer && _questions.foia_a.answer !== ''
              ? _questions.foia_a.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-b-col',
            displayName: _questions.foia_b && _questions.foia_b.answer && _questions.foia_b.answer !== ''
              ? _questions.foia_b.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-c-col',
            displayName: _questions.foia_c &&  _questions.foia_c.answer && _questions.foia_c.answer !== ''
              ? _questions.foia_c.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-d-col',
            displayName: _questions.foia_d && _questions.foia_d.answer && _questions.foia_d.answer !== ''
              ? _questions.foia_d.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-e-col',
            displayName: _questions.foia_e && _questions.foia_e.answer  && _questions.foia_e.answer !== ''
              ? _questions.foia_e.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foia-f-col',
            displayName: _questions.foia_f && _questions.foia_f.answer && _questions.foia_f.answer !== ''
              ? _questions.foia_f.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-bad-actor-col',
            displayName: _questions.badActor && _questions.badActor.answer && _questions.badActor.answer !== ''
              ? _questions.badActor.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ? 
          {
            id: 'cfiusForeignPerson-col',
            displayName: cfiusForeignPerson,
            type: 'description',
            class: 'investment-md horizontal-col',
          } : null,
          isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ? 
          {
            id: 'foreignGovtControlled-col',
            displayName: foreignGovtControlled,
            type: 'description',
            class: 'investment-md horizontal-col',
          } : null,
         
          isTypeHCM
            ? {
                id: 'investment-hcm-col',
                displayName: hcm,
                type: 'description',
                class: 'investment-sm horizontal-col',
              }
            : null,
          {
            id: 'investment-accredited-col',
            displayName: _questions.accredited && _questions.accredited !== ''
              ? _questions.accredited
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-purchaser-col',
            displayName: _questions.purchaser && _questions.purchaser !== ''
              ? _questions.purchaser
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-client-col',
            displayName: _questions.client && _questions.client !== ''
              ? _questions.client
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
           {
            id: 'investment-country-col',
            displayName: _questions.wiringBankCountry && _questions.wiringBankCountry.answer && _questions.wiringBankCountry.answer !== ''
              ? _questions.wiringBankCountry.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-wiring-bank-col',
            displayName: _questions.wiringBankFATF && _questions.wiringBankFATF.answer && _questions.wiringBankFATF.answer !== ''
              ? _questions.wiringBankFATF.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-customer-of-bank-col',
            displayName: _questions.customerOfBank && _questions.customerOfBank.answer && _questions.customerOfBank.answer !== ''
              ? _questions.customerOfBank.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-erisa-a-col',
            displayName: _questions.erisa_a && _questions.erisa_a.answer && _questions.erisa_a.answer !== ''
              ? _questions.erisa_a.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-erisa-b-col',
            displayName: _questions.erisa_b && _questions.erisa_b.answer && _questions.erisa_b.answer !== ''
              ? _questions.erisa_b.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-plan-perc-col',
            displayName: _questions.erisaPercentage && _questions.erisaPercentage.answer && _questions.erisaPercentage.answer !== ''
              ? _questions.erisaPercentage.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-potential-look-a-col',
            displayName: _questions.look_a && _questions.look_a.answer && _questions.look_a.answer !== ''
              ? _questions.look_a.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-potential-look-b-col',
            displayName: _questions.look_b && _questions.look_b.answer && _questions.look_b.answer !== ''
              ? _questions.look_b.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-potential-look-c-col',
            displayName: _questions.look_c && _questions.look_c.answer && _questions.look_c.answer !== ''
              ? _questions.look_c.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-potential-look-d-col',
            displayName: _questions.look_d &&  _questions.look_d.answer && _questions.look_d.answer !== ''
              ? _questions.look_d.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-potential-look-e-col',
            displayName: _questions.look_e && _questions.look_e.answer && _questions.look_e.answer !== ''
              ? _questions.look_e.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-gov-col',
            displayName: _questions.governmentEntity && _questions.governmentEntity.answer && _questions.governmentEntity.answer !== ''
              ? _questions.governmentEntity.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-bhca-col',
            displayName: _questions.bhcaPartner && _questions.bhcaPartner.answer && _questions.bhcaPartner.answer !== ''
              ? _questions.bhcaPartner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-foundation-col',
            displayName: _questions.privateFoundationPartner && _questions.privateFoundationPartner.answer && _questions.privateFoundationPartner.answer !== ''
              ? _questions.privateFoundationPartner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-pension-col',
            displayName: _questions.publicPensionPartner && _questions.publicPensionPartner.answer && _questions.publicPensionPartner.answer !== ''
              ? _questions.publicPensionPartner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-fund-of-funds-col',
            displayName: _questions.fundOfFunds && _questions.fundOfFunds.answer && _questions.fundOfFunds.answer !== ''
              ? _questions.fundOfFunds.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-tax-exempt-col',
            displayName: _questions.taxExemptPartner && _questions.taxExemptPartner.answer && _questions.taxExemptPartner.answer !== ''
              ? _questions.taxExemptPartner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-taxed-parter-col',
            displayName: _questions.taxedPartnership && _questions.taxedPartnership.answer && _questions.taxedPartnership.answer !== ''
              ? _questions.taxedPartnership.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-us-beneficial-owner-col',
            displayName: _questions.usBeneficial && _questions.usBeneficial.answer && _questions.usBeneficial.answer !== ''
              ? _questions.usBeneficial.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'investment-tax-beneficial-owner-col',
            displayName: _questions.beneficialOwner && _questions.beneficialOwner.answer && _questions.beneficialOwner.answer !== ''
              ? _questions.beneficialOwner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
            isTypeTcgCrossOver ? 
          {
            id: 'investment-restricted-person-status-col',
            displayName: restrictedPersonStatus,
            type: 'description',
            class: 'investment-sm horizontal-col',
          } : null,
          isTypeTcgCrossOver ? 
          {
            id: 'investment-covered-investor-status-col',
            displayName: coveredInvestorStatus,
            type: 'description',
            class: 'investment-sm horizontal-col',
          } : null,
          isTypeTcgCrossOver ? 
          {
            id: 'investment-bad-exempted-entity-status-col',
            displayName: determinationExemptedEntity,
            type: 'description',
            class: 'investment-sm horizontal-col',
          } : null,
          {
            id: 'investment-ofac-col',
            displayName: _investment.ofac ? _investment.ofac : '',
            type: 'description',
            class: 'investment-md horizontal-col',
            editable: true,
            editing: _investment.editingOFAC,
            actionResult: true,
            editingProperty: 'ofac',
            dataType: _investment.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
            editFieldHandler: this.handleEditField,
            actionResultIdentifier: _investment._id,
            inputIdentifier: `${_investment._id}-ofac`,
            actionCallback: this.handleSaveField,
            inputChangeHandler: this.handleChangeField,
            emptyText: _investment.submitted ? 'N/A' : '',
          },
          {
            id: 'investment-exceptions-col',
            // eslint-disable-next-line
            displayName: _questions.exceptions && _questions.exceptions.answer && _questions.exceptions.answer !== "" 
              ? `${_questions.exceptions.answer.substring(0, 20)}${ _questions.exceptions.answer.length > 20 ? '...' : ''}` 
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'reports-primary-name-col',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsPrimaryContactName &&
            _investment.generalInfo.reportsPrimaryContactName !== ''
              ? _investment.generalInfo.reportsPrimaryContactName
              : emptyDefaultValue,
          type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'reports-primary-email-col',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsPrimaryContactEmail &&
            _investment.generalInfo.reportsPrimaryContactEmail !== ''
                ? _investment.generalInfo.reportsPrimaryContactEmail
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'reports-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsPrimaryContactPhone &&
            _investment.generalInfo.reportsPrimaryContactPhone !== ''
              ? _investment.generalInfo.reportsPrimaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'reports-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsSecondaryContactName && 
            _investment.generalInfo.reportsSecondaryContactName !== ''
              ? _investment.generalInfo.reportsSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'reports-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsSecondaryContactEmail &&
            _investment.generalInfo.reportsSecondaryContactEmail !== ''
              ? _investment.generalInfo.reportsSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'reports-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.reportsSecondaryContactPhone && 
            _investment.generalInfo.reportsSecondaryContactPhone !== ''
              ? _investment.generalInfo.reportsSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'partnerStatementsPrimaryContactName-primary',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsPrimaryContactName &&
            _investment.generalInfo.partnerStatementsPrimaryContactName !== ''
              ? _investment.generalInfo.partnerStatementsPrimaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'partnerStatementsPrimaryContact-primary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsPrimaryContactEmail &&
            _investment.generalInfo.partnerStatementsPrimaryContactEmail !== ''
              ? _investment.generalInfo.partnerStatementsPrimaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'partnerStatementsPrimaryContact-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsPrimaryContactPhone &&
            _investment.generalInfo.partnerStatementsPrimaryContactPhone !== ''
                ? _investment.generalInfo.partnerStatementsPrimaryContactPhone
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'partnerStatementsSecondaryContactName-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsSecondaryContactName &&
            _investment.generalInfo.partnerStatementsSecondaryContactName !== ''
              ? _investment.generalInfo.partnerStatementsSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'partnerStatementsSecondaryContact-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsSecondaryContactEmail &&
            _investment.generalInfo.partnerStatementsSecondaryContactEmail !== ''
              ? _investment.generalInfo.partnerStatementsSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'partnerStatementsSecondaryContact-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.partnerStatementsSecondaryContactPhone &&
            _investment.generalInfo.partnerStatementsSecondaryContactPhone !== ''
              ? _investment.generalInfo.partnerStatementsSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'tax-primary',
            displayName: _investment.generalInfo && _investment.generalInfo.taxPrimaryContactName &&
            _investment.generalInfo.taxPrimaryContactName !== ''
              ? _investment.generalInfo.taxPrimaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'tax-primary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.taxPrimaryContactEmail && 
            _investment.generalInfo.taxPrimaryContactEmail !== ''
              ? _investment.generalInfo.taxPrimaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'tax-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.taxPrimaryContactPhone &&
            _investment.generalInfo.taxPrimaryContactPhone !== ''
              ? _investment.generalInfo.taxPrimaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'tax-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.taxSecondaryContactName &&
            _investment.generalInfo.taxSecondaryContactName !== ''
              ? _investment.generalInfo.taxSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'tax-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.taxSecondaryContactEmail &&
            _investment.generalInfo.taxSecondaryContactEmail !== ''
              ? _investment.generalInfo.taxSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'tax-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.taxSecondaryContactPhone &&
            _investment.generalInfo.taxSecondaryContactPhone !== ''
              ? _investment.generalInfo.taxSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'capital-primary',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalPrimaryContactName &&
            _investment.generalInfo.capitalPrimaryContactName !== ''
              ? _investment.generalInfo.capitalPrimaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'capital-primary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalPrimaryContactEmail &&
            _investment.generalInfo.capitalPrimaryContactEmail !== ''
              ? _investment.generalInfo.capitalPrimaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'capital-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalPrimaryContactPhone &&
            _investment.generalInfo.capitalPrimaryContactPhone !== ''
              ? _investment.generalInfo.capitalPrimaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'capital-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalSecondaryContactName &&
            _investment.generalInfo.capitalSecondaryContactName !== ''
              ? _investment.generalInfo.capitalSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'capital-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalSecondaryContactEmail &&
            _investment.generalInfo.capitalSecondaryContactEmail !== ''
              ? _investment.generalInfo.capitalSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'capital-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.capitalSecondaryContactPhone &&
            _investment.generalInfo.capitalSecondaryContactPhone !== ''
              ? _investment.generalInfo.capitalSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'stock-primary',
            displayName: _investment.generalInfo && _investment.generalInfo.stockPrimaryContactName &&
            _investment.generalInfo.stockPrimaryContactName !== ''
              ? _investment.generalInfo.stockPrimaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'stock-primary-email',
            displayName:_investment.generalInfo && _investment.generalInfo.stockPrimaryContactEmail &&
            _investment.generalInfo.stockPrimaryContactEmail !== ''
              ? _investment.generalInfo.stockPrimaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'stock-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.stockPrimaryContactPhone &&
            _investment.generalInfo.stockPrimaryContactPhone !== ''
              ? _investment.generalInfo.stockPrimaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'stock-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.stockSecondaryContactName &&
            _investment.generalInfo.stockSecondaryContactName !== ''
              ? _investment.generalInfo.stockSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'stock-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.stockSecondaryContactEmail &&
            _investment.generalInfo.stockSecondaryContactEmail !== ''
              ? _investment.generalInfo.stockSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'stock-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.stockSecondaryContactPhone &&
            _investment.generalInfo.stockSecondaryContactPhone !== ''
              ? _investment.generalInfo.stockSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'legal-primary',
            displayName: _investment.generalInfo && _investment.generalInfo.legalPrimaryContactName &&
            _investment.generalInfo.legalPrimaryContactName !== ''
              ? _investment.generalInfo.legalPrimaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'legal-primary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.legalPrimaryContactEmail &&
            _investment.generalInfo.legalPrimaryContactEmail !== ''
              ? _investment.generalInfo.legalPrimaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'legal-primary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.legalPrimaryContactPhone &&
            _investment.generalInfo.legalPrimaryContactPhone !== ''
              ? _investment.generalInfo.legalPrimaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'legal-secondary',
            displayName: _investment.generalInfo && _investment.generalInfo.legalSecondaryContactName &&
            _investment.generalInfo.legalSecondaryContactName !== ''
              ? _investment.generalInfo.legalSecondaryContactName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'legal-secondary-email',
            displayName: _investment.generalInfo && _investment.generalInfo.legalSecondaryContactEmail &&
            _investment.generalInfo.legalSecondaryContactEmail !== ''
              ? _investment.generalInfo.legalSecondaryContactEmail
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-lg horizontal-col',
          },
          {
            id: 'legal-secondary-phone',
            displayName: _investment.generalInfo && _investment.generalInfo.legalSecondaryContactPhone &&
            _investment.generalInfo.legalSecondaryContactPhone !== ''
              ? _investment.generalInfo.legalSecondaryContactPhone
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'bank-name-col',
            displayName: _investment.generalInfo && _investment.generalInfo.bankName &&
              _investment.generalInfo.bankName !== ''
              ? _investment.generalInfo.bankName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'account-name-col',
            displayName: _investment.generalInfo && _investment.generalInfo.acctName &&
            _investment.generalInfo.acctName !== ''
              ? _investment.generalInfo.acctName
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'swift-code-col',
            displayName: _investment.generalInfo && _investment.generalInfo.swiftCode &&
            _investment.generalInfo.swiftCode !== ''
              ? _investment.generalInfo.swiftCode
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'account-number-col',
            displayName: _investment.generalInfo && _investment.generalInfo.acctNumber &&
            _investment.generalInfo.acctNumber !== ''
              ? _investment.generalInfo.acctNumber
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'bank-aba-col',
            displayName: _investment.generalInfo && _investment.generalInfo.bankaba &&
            _investment.generalInfo.bankaba !== ''
              ? _investment.generalInfo.bankaba
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'further-credit-col',
            displayName: _investment.generalInfo && _investment.generalInfo.furtherCredit &&
            _investment.generalInfo.furtherCredit !== ''
              ? _investment.generalInfo.furtherCredit
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'location-col',
            displayName: _investment.generalInfo && _investment.generalInfo.location &&
            _investment.generalInfo.location !== ''
              ? _investment.generalInfo.location
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'rule5130-finra-new-issues-1-col',
            displayName: _questions.rule5130_finraNewIssues_1 && _questions.rule5130_finraNewIssues_1.answer &&
            _questions.rule5130_finraNewIssues_1.answer !== ''
              ? _questions.rule5130_finraNewIssues_1.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'rule5130-finra-new-issues-2-col',
            displayName: _questions.rule5130_finraNewIssues_2 && _questions.rule5130_finraNewIssues_2.answer &&
            _questions.rule5130_finraNewIssues_2.answer !== ''
              ? _questions.rule5130_finraNewIssues_2.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'rule5131-finra-new-issues-1-col',
            displayName: _questions.rule5131_finraNewIssues_1 && _questions.rule5131_finraNewIssues_1.answer &&
            _questions.rule5131_finraNewIssues_1.answer !== ''
              ? _questions.rule5131_finraNewIssues_1.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'rule5131-finra-new-issues-2-col',
            displayName: _questions.rule5131_finraNewIssues_2 && _questions.rule5131_finraNewIssues_2.answer &&
            _questions.rule5131_finraNewIssues_2.answer !== ''
              ? _questions.rule5131_finraNewIssues_2.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ? 
          null :
          {
            id: 'cfiusForeignPerson-col',
            displayName: cfiusForeignPerson,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          isTypeTcgCrossOver || isTypeBitKraft || isTypeElephantPartner ?
          null :
          {
            id: 'foreignGovtControlled-col',
            displayName: foreignGovtControlled,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'investment-institutional-account-status-col',
            displayName: _questions.institutionalAccountStatus && _questions.institutionalAccountStatus.answer &&
            _questions.institutionalAccountStatus.answer !== ''
                ? _questions.institutionalAccountStatus.answer
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-sm horizontal-col',
          },
          {
            id: 'foresite-investorType-col',
            displayName: foresiteInvestorType,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresiteFinraAffiliation-type',
            displayName: foresiteFinraAffiliation,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresiteFinraAffiliationFurtherDetails-type',
            displayName: foresiteFinraAffiliationFurtherDetails  && foresiteFinraAffiliationFurtherDetails !== ''
                ? `${foresiteFinraAffiliationFurtherDetails.substring(0, 20)} ${foresiteFinraAffiliationFurtherDetails.length > 20 ? '...' : ''}`
                : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresitePublicCompanyAffiliation-type',
            displayName: foresitePublicCompanyAffiliation,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresitePublicCompanyAffiliation_opt-type',
            displayName: foresitePublicCompanyAffiliation_opt && foresitePublicCompanyAffiliation_opt !== ''
              ? `${foresitePublicCompanyAffiliation_opt.substring(0, 20)} ${foresitePublicCompanyAffiliation_opt.length > 20 ? '...' : ''}`
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresiteEntities-type',
            displayName: foresiteEntities,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'foresite-foresiteEntities_opt-type',
            displayName: foresiteEntities_opt && foresiteEntities_opt !== ''
              ? `${foresiteEntities_opt.substring(0, 20)} ${foresiteEntities_opt.length > 20 ? '...' : ''}`
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          includesFormPFQuestion ? 
          {
            id: 'investment-beneficial-ownership-col',
            displayName: beneficialOwnershipAdvisers,
            type: 'description',
            class: 'investment-sm horizontal-col',
          } : null,
           isTypeAccelMain ?
          {
            id: 'foreign-partner-col', 
            displayName: _questions.foreignPartner && _questions.foreignPartner.answer &&
            _questions.foreignPartner.answer !== ''
              ? _questions.foreignPartner.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          }
          : null,
          isTypeAccelMain ?
          {
            id: 'retirementPlan-col', 
            displayName: _questions.retirementPlan && _questions.retirementPlan.answer &&
            _questions.retirementPlan.answer !== ''
              ? _questions.retirementPlan.answer
              : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          }
          : null,
          isTypeAccelMain ?
          {
            id: 'Rep7EUandUK-col',
            displayName: RepWarranty7UK,
            type: 'description',
            class: 'investment-lg horizontal-col',
          }
          : null,
          isTypeAccelMain ?
          {
            id: 'Rep8Switzerland-col',
            displayName: RepWarranty8Switzerland,
            type: 'description',
            class: 'investment-lg horizontal-col',
          }
          : null,
          {
            id: 'part-a-finra-5130-col',
            displayName: partAFinra5130,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
           {
            id: 'part-b-finra-5131-col',
            displayName: partBFinra5131,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'contact-person-address-col',
            displayName: _investment.generalInfo && _investment.generalInfo.addressPrimaryContact ?
              _investment.generalInfo.addressPrimaryContact : emptyDefaultValue,
            type: 'description',
            class: 'investment-md horizontal-col',
          },
          {
            id: 'created-at-col',
            displayName: new DateTimeZone(_investment.createdAt).toFormat(
              'MM/dd/yyyy hh:mm:ss'
            ),
            type: 'description',
            class: 'investment-md horizontal-col',
          },
        ].filter(Boolean)
      );
    };

    if (admin.dashboardSelectedFund) {
      if (
        admin.dashboardSelectedFund.investorFunds &&
        typeof admin.dashboardSelectedFund.investorFunds[0] === 'object'
      ) {
        for (
          let i = 0;
          i < admin.dashboardSelectedFund.investorFunds.length;
          i += 1
        ) {
          const investorFund = admin.dashboardSelectedFund.investorFunds[i];
          let questions = [];
          investorFund.icon =
            investorFund.status === STATUS_TYPES.CLOSED.label
              ? 'fa-check'
              : 'fa-square-o';
          if (investorFund.checked) investorFund.icon = 'fa-check-square-o';
          if (
            investorFund.questionnaire &&
            investorFund.questionnaire.questions
          ) {
            questions = this.getInvestorFundQuestions(
              investorFund.questionnaire.questions,
              this.isShortIQ
            );
          }
          trackingSheetRowTemplate(investorFund, questions, i);
        }
      }
      if (
        admin.dashboardSelectedFund.limitedPartnerFunds &&
        typeof admin.dashboardSelectedFund.limitedPartnerFunds[0] === 'object'
      ) {
        for (
          let i = 0;
          i < admin.dashboardSelectedFund.limitedPartnerFunds.length;
          i += 1
        ) {
          const investorFund =
            admin.dashboardSelectedFund.limitedPartnerFunds[i];
          let questions = [];
          investorFund.icon =
            investorFund.status === STATUS_TYPES.CLOSED.label
              ? 'fa-check'
              : 'fa-square-o';
          if (investorFund.checked) investorFund.icon = 'fa-check-square-o';
          if (
            investorFund.questionnaire &&
            investorFund.questionnaire.questions
          ) {
            questions = this.getInvestorFundQuestions(
              investorFund.questionnaire.questions,
              this.isShortIQ
            );
          }
          trackingSheetRowTemplate(investorFund, questions, i);
        }
      }
    }
    const routLocation = this.props.router.location.pathname;
    const id = routLocation.split('fundDetail/')[1];

    const { sortByCol, sortKey, iQsPages, iQsMaxItems, iQsPage } = this.state;
    const paginationData = getInitialData(results, iQsMaxItems);


    if (iQsPage > 1) {
      paginationData.records = orderByPage(results, iQsMaxItems, iQsPage);
    }
    if (!iQsPages || id !== this.state.id || paginationData.length !== this.state.iQsLength) {
      this.setState({
        iQsPages: getPages(paginationData.length, iQsMaxItems) || [],
        iQsLength: paginationData.length,
        id: id
      });
    }
    const _results = this.filterResultsByVersion(paginationData.records);

    const sortResults = this.state.sortByCol !== '-1' ? utility.gunderSort(_results, sortByCol, sortKey) : _results;

    return {
      headers: this.filterHeadersByVersion(headers),
      results:
        this.state.sortDirection === 'desc' ? sortResults : sortResults.reverse(),
    };
  }

  filterResultsByVersion = (results) => {
    const { fundType } = this.props.admin.dashboardSelectedFund;

    results.forEach((iq, index) => {
      results[index] = iq.filter((item, idx) => !excludeByIdx.includes(idx));
    });

    const insert = (arr, index, newItem) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ]

    if (fundType) {
      switch (fundType.identifier) {
        case FUND_TYPES.LIMITED_PARTNER.id:
          results.forEach((iq, index) => {
           const moveHeader = MOVE_HEADERS.LIMITED_PARTNER.find((header) => header.move === iq.find((item) => item.id === header.move).id);
            
            if (moveHeader) {
              const fieldToMove = iq.find((item) => item.id === moveHeader.move);
              const oldArr = iq.filter((item) => moveHeader.move !== item.id);
              const afterIndex = oldArr.findIndex((a) => a.id === moveHeader.after);
              const newArr = insert(oldArr, afterIndex + 1, fieldToMove);
              results[index] = newArr;
            }
          });
          break;
        case FUND_TYPES.FRAZIER.id:
          results.forEach((iq, index) => {
           const moveHeader = MOVE_HEADERS.FRAZIER.find((header) => header.move === iq.find((item) => item.id === header.move).id);
            
            if (moveHeader) {
              const fieldToMove = iq.find((item) => item.id === moveHeader.move);
              const oldArr = iq.filter((item) => moveHeader.move !== item.id);
              const afterIndex = oldArr.findIndex((a) => a.id === moveHeader.after);
              const newArr = insert(oldArr, afterIndex + 1, fieldToMove);
              results[index] = newArr;
            }
          });
          break;
        default:
          break;
      }
    }
    return results;
  };

  filterHeadersByVersion = (headers) => {
    const { fundType } = this.props.admin.dashboardSelectedFund;
    const newArr = [];
    excludeByIdx = [];
    if (fundType) {
      switch (fundType.identifier) {
        case FUND_TYPES.LIMITED_PARTNER.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.LIMITED_PARTNER.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          MOVE_HEADERS.LIMITED_PARTNER.forEach((header) => {
            const headerIndex = newArr.findIndex((item) => item.filteringId === header.move);
            const item = newArr[headerIndex];
            if (headerIndex && item) {
              newArr.splice(headerIndex, 1);
              const moveAfterIndex = newArr.findIndex((i) => i.filteringId === header.after);
              newArr.splice(moveAfterIndex + 1, 0, item);
            }
          });

          break;
        
        case FUND_TYPES.FRAZIER.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.FRAZIER.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          MOVE_HEADERS.FRAZIER.forEach((header) => {
            const headerIndex = newArr.findIndex((item) => item.filteringId === header.move);
            const item = newArr[headerIndex];
            if (headerIndex && item) {
              newArr.splice(headerIndex, 1);
              const moveAfterIndex = newArr.findIndex((i) => i.filteringId === header.after);
              newArr.splice(moveAfterIndex + 1, 0, item);
            }
          });

          break;

       case FUND_TYPES.SR_ONE.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.SR_ONE.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        
        case FUND_TYPES.PROMETHEAN.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.PROMETHEAN.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        case FUND_TYPES.CAYMAN.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.CAYMAN.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        case FUND_TYPES.LIMITED_LIABILITY_COMPANY.id:
          headers.map((item, idx) => {
            if (
              !EXCLUDE_HEADERS.LIMITED_LIABILITY_COMPANY.includes(item.title)
            ) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.FORESITE.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.FORESITE.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.QUESTACAPITAL.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.QUESTACAPITAL.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          return newArr;

        case FUND_TYPES.INITIALIZED.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.INITIALIZED.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.HCM.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.HCM.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.FORESITELABS.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.FORESITELABS.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.LIMITED_PARTNER_ABBR.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.LIMITED_PARTNER_ABBR.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.IOLAR.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.IOLAR.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.OSAGE.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.OSAGE.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.NEW_LIMITED_PARTNER.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.NEW_LIMITED_PARTNER.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        
        case FUND_TYPES.TCG_CROSSOVER.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.TCG_CROSSOVER.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
        break;

        case FUND_TYPES.BITKRAFT.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.BITKRAFT.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
        break;

        case FUND_TYPES.ELEPHANT_PARTNER.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.ELEPHANT_PARTNER.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
        break;

        case FUND_TYPES.FIREWORK.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.FIREWORK.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.PROMETHEAN.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.PROMETHEAN.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        
        case FUND_TYPES.ACCEL.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.ACCEL.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.ACCELMAIN.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.ACCELMAIN.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.ACCEL_ENTREPRENEURS.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.ACCEL_ENTREPRENEURS.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.ACCEL_ENTREPRENEURS_RETURNING.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.LIMITED_PARTNER_MULTI_FUND.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        
         case FUND_TYPES.AUDERE.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.AUDERE.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;

        case FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id:
          headers.map((item, idx) => {
            if (!EXCLUDE_HEADERS.LIMITED_PARTNERSHIP_MULTI_FUND.includes(item.title)) {
              newArr.push(item);
            } else {
              if (item) {
                excludeByIdx.push(idx);
              }
            }
          });
          break;
        
        default:
          return headers;
      }
      return newArr;
    } else {
      return headers;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  exportToCSV(e) {
    e.preventDefault();
    const { admin, exportCSV } = this.props;
    const { includesFormPFQuestion } = admin.dashboardSelectedFund;

    const requireCapitalCommitment = admin.dashboardSelectedFund && 
      admin.dashboardSelectedFund.requireCapitalCommitment &&
      admin.dashboardSelectedFund.requireCapitalCommitment === 'yes' ? true : false;

    const isTypeMultiFund = 
    admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || //short multi-fund
      admin.dashboardSelectedFund.fundType &&   
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id;  //limited Partnership multi-fund
      
      //limited Partnership multi-fund
    const isTypeLPMultiFund = 
    admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id;

    const isTypeAccel = 
    admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.ACCEL.id;
    
    const isTypeAccelMain =
    admin.dashboardSelectedFund &&
    admin.dashboardSelectedFund.fundType &&
    admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.ACCELMAIN.id;

    const isTypeFrazier =
    admin.dashboardSelectedFund &&
    admin.dashboardSelectedFund.fundType &&
    admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.FRAZIER.id;

     const isTypeTcgCrossOver =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.TCG_CROSSOVER.id;
    
    const isTypeBitKraft =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.BITKRAFT.id;

    const isTypeElephantPartner =
         admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.ELEPHANT_PARTNER.id;

    const isLimitedParnetship =
			admin.dashboardSelectedFund &&
			admin.dashboardSelectedFund.fundType &&
			admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNER.id;
    
    const isCayman =
			admin.dashboardSelectedFund &&
			admin.dashboardSelectedFund.fundType &&
			admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.CAYMAN.id;

    const fundLegalName = admin.dashboardSelectedFund ? admin.dashboardSelectedFund.fundLegalName : '';
    const fund2LegalName = isTypeMultiFund && admin.dashboardSelectedFund ? admin.dashboardSelectedFund.fund2LegalName : '';
    const fund3LegalName = isTypeMultiFund && admin.dashboardSelectedFund && 
    admin.dashboardSelectedFund.fund3LegalName ? admin.dashboardSelectedFund.fund3LegalName : '';

    let fields = csvFields.fields;
    const arrayRemove = (arr, value) => { 
        return arr.filter((ele) => { 
            return !value.includes(ele); 
        });
    }
    const arrayAdd = (arr, key,value) => { 
        return arr.map((ele) => { 
          if (ele === key){
            return value; 
          }
          else {
              return ele; 
            }
        });
    }
    
    const fieldNames = csvFields.fieldNames;
    
    const capitalname = `${fundLegalName} capitalCommitted`;
    const capitalname2 = `${fund2LegalName} capitalCommitted`;
    const capitalname3 = `${fund3LegalName} capitalCommitted`;


    //TODO: Refactor these deleting and updates to CSV fields
    if (isTypeMultiFund) {
      fields = arrayAdd(fields, 'capitalCommitted', capitalname); // capitalcommitmet2
      fields = arrayAdd(fields, 'capitalCommittedFund2', capitalname2);

      if(!fund3LegalName) {
        fields = arrayRemove(fields, ['capitalCommittedFund3']);
      } else {
        fields = arrayAdd(fields, 'capitalCommittedFund3', capitalname3);
      }
      
    }
    if (!isTypeMultiFund) {
      fields = arrayRemove(fields, ['capitalCommittedFund2','capitalCommittedFund3']);
    }

    function handleDefaultValue(investorFund, iqId, govt) {
      if (investorFund.questionnaire && investorFund.questionnaire.questions) {
          if (govt && govt === 'GovtControlled') {
            const iq = _.find(investorFund.questionnaire.questions, (instance) => instance.descriptionIdentifier === iqId);
            if (!iq || oldVersionGovtControlled.includes(investorFund.questionnaire.iqVersion)) {
              return _.find(investorFund.questionnaire.questions, 
                (instance) => instance.answerIdentifier === `${iqID.id_foreign_person_2_or}_yes`)
                ? 'Yes'
                : 'No';
            }
            return iq && iq.answerIdentifier === `${iqId}_yes` ? 'Yes' : 'No';
          } else {
            const iq = _.find(investorFund.questionnaire.questions, (instance) => instance.descriptionIdentifier === iqId);
            return iq && iq.answerIdentifier === `${iqId}_yes` ? 'No' : 'Yes';
          }
      }
    }

    if (!includesFormPFQuestion) {
      fields = arrayRemove(fields, ['beneficialOwnershipAdvisers']);
    }

    if (!isTypeTcgCrossOver && !isTypeBitKraft && !isTypeElephantPartner) {
       fields = arrayRemove(fields, ['taxExemptOwners', 'restrictedPersonStatus', 'coveredInvestorStatus', 'determinationExemptedEntity']);

    }
    if (isTypeBitKraft || isTypeElephantPartner) {
       fields = arrayRemove(fields, ['taxExemptOwners', 'restrictedPersonStatus', 'coveredInvestorStatus']);
     
    }
    
    if (isLimitedParnetship || isCayman || isTypeFrazier) {
       const newFields = ['bankLocation', 'cfiusForeignPerson', 'foreignGovtControlled', 'contactPersonAddress'];
      fields = arrayRemove(fields, newFields);
      // add new fields to the end of the array
      fields.splice(fields.length - 2, 0, ...newFields);
		}

    if (isLimitedParnetship) {
      fields = arrayRemove(fields, ['notes']);
      const escrowIndex = fields.indexOf('escrow');
      fields.splice(escrowIndex + 1, 0, 'notes');
    }

    const investorFunds = [];
    const createTrackSheetTemplate = (investorFund, _questions) => {
      const tracksheetEntry = {};

      tracksheetEntry.status = investorFund.status
        ? investorFund.status
        : STATUS_TYPES.IN_PROGRESS.label;
      tracksheetEntry.lpGroup = investorFund.lpGroup
        ? investorFund.lpGroup
        : 'N/A';
      // tracksheetEntry.loginEmail =
      //   investorFund.investor && investorFund.investor.username
      //     ? investorFund.investor.username
      //     : investorFund.limitedPartner && investorFund.limitedPartner.organization
      //       ? investorFund.limitedPartner.organization.iqUsers[0].email : investorFund.submitted ? ' ' : '';
      tracksheetEntry.name = investorFund.generalInfo && investorFund.generalInfo.name
          ? investorFund.generalInfo.name.trim()
          : 'N/A';

      tracksheetEntry.contactEmail = investorFund.generalInfo &&
          investorFund.generalInfo.emailPrimaryContact
            ? investorFund.generalInfo.emailPrimaryContact
            : 'N/A';

      tracksheetEntry.investorType = investorFund.generalInfo && investorFund.generalInfo.types.length
            ? investorFund.generalInfo.types[0]
            : 'N/A';

      if (!isTypeMultiFund || !isTypeFrazier)  {
        tracksheetEntry.capitalCommitted = investorFund.generalInfo && investorFund.generalInfo.capitalCommitment
            ? this.capitalCommitmentCurrency(investorFund.generalInfo.capitalCommitment, admin.dashboardSelectedFund)
            : 'N/A'; 
      }

      if (isTypeFrazier) {
        tracksheetEntry.subscription = investorFund.generalInfo && investorFund.generalInfo.capitalCommitment
            ? this.capitalCommitmentCurrency(investorFund.generalInfo.capitalCommitment, admin.dashboardSelectedFund)
            : 'N/A'; 
      }

      if (isTypeMultiFund) {
          tracksheetEntry[capitalname] =
          investorFund.generalInfo && investorFund.generalInfo.capitalCommitment
            ? this.capitalCommitmentCurrency(investorFund.generalInfo.capitalCommitment, admin.dashboardSelectedFund)
            : 'N/A';
          tracksheetEntry[capitalname2] =
          investorFund.generalInfo && investorFund.generalInfo.capitalCommitmentFund2
            ?  this.capitalCommitmentCurrency(investorFund.generalInfo.capitalCommitmentFund2, admin.dashboardSelectedFund)
            : 'N/A';
        }
        if (isTypeLPMultiFund && fund3LegalName) {
           tracksheetEntry[capitalname3] =
          investorFund.generalInfo && investorFund.generalInfo.capitalCommitmentFund3 
            ? this.capitalCommitmentCurrency(investorFund.generalInfo.capitalCommitmentFund3, admin.dashboardSelectedFund)
            : 'N/A';
      }

      tracksheetEntry.contactPerson = investorFund.generalInfo &&
          investorFund.generalInfo.namePrimaryContact
            ? investorFund.generalInfo.namePrimaryContact
            : 'N/A';
      tracksheetEntry.contactPersonPhone = investorFund.generalInfo &&
        investorFund.generalInfo.phonePrimaryContact
          ? investorFund.generalInfo.phonePrimaryContact
          : 'N/A';
      tracksheetEntry.email = investorFund.generalInfo &&
        investorFund.generalInfo.emailPrimaryContact
          ? investorFund.generalInfo.emailPrimaryContact
          : 'N/A';
      tracksheetEntry.contactPersonAddress = investorFund.generalInfo &&
        investorFund.generalInfo.addressPrimaryContact
          ? investorFund.generalInfo.addressPrimaryContact
          : 'N/A';

      tracksheetEntry.received = investorFund.submittedAt
        ? new DateTimeZone(investorFund.submittedAt).toLocaleDateString("en-US")
        : 'No';
        
      if (
          _questions.naturalPerson &&
          _questions.naturalPerson.answer === 'Yes'
        ) {
          tracksheetEntry.location = investorFund.generalInfo 
          ? getFullFormattedLocation(
            investorFund.generalInfo.countryPlace ? investorFund.generalInfo.countryPlace : 'US',
            investorFund.generalInfo.principalPlaceOfResidencePrimaryContact,
            investorFund.generalInfo.principalPlaceOfResidence) 
          : 'N/A';
        } else {
          tracksheetEntry.location = investorFund.generalInfo 
          ? getFullFormattedLocation(
            investorFund.generalInfo.countryPlace ? investorFund.generalInfo.countryPlace : 'US',
            investorFund.generalInfo.principalPlaceOfBusinessPrimaryContact,
            investorFund.generalInfo.principalPlaceOfBusiness) 
          : 'N/A';
      }  

      if (investorFund.submitted) {
        
        if (isTypeAccel)  {
        tracksheetEntry.totalAllFund =
          investorFund.generalInfo && investorFund.generalInfo.totalAllFund &&
          investorFund.generalInfo.totalAllFund !== ('0' || 0 || '0.00')
           ? this.capitalCommitmentCurrency(investorFund.generalInfo.totalAllFund, admin.dashboardSelectedFund)
            : 'N/A'; 
        }
        
        tracksheetEntry.signed = investorFund.signedAt
          ? new DateTimeZone(investorFund.signedAt).toLocaleDateString("en-US")
          : 'No';
        tracksheetEntry.closing = investorFund.closedAt && investorFund.status === STATUS_TYPES.CLOSED.label
          ? new DateTimeZone(investorFund.closedAt).toLocaleDateString("en-US")
          : 'No';

        if (
          _questions.naturalPerson &&
          _questions.naturalPerson.answer === 'Yes'
        ) {
          tracksheetEntry.foreignGovtControlled = 'N/A';
        } 
        tracksheetEntry.wireInfo =
          investorFund.generalInfo &&
          investorFund.generalInfo.bankName &&
          investorFund.generalInfo.acctNumber &&
          investorFund.generalInfo.swiftCode
            ? 'Yes'
            : 'No';
        tracksheetEntry.naturalPerson = _questions.naturalPerson
          ? _questions.naturalPerson.answer
          : 'N/A';
        tracksheetEntry.usPersonSecAct = _questions.usPerson
          ? _questions.usPerson.answer
          : 'N/A';
        tracksheetEntry.usPersonIrc = _questions.usPersonIRC
          ? _questions.usPersonIRC.answer
          : 'N/A';
        tracksheetEntry.finra = _questions.finraAffiliation
          ? _questions.finraAffiliation.answer
          : 'N/A';
        tracksheetEntry.foia_a = _questions.foia_a
          ? _questions.foia_a.answer
          : 'N/A';
        tracksheetEntry.foia_b = _questions.foia_b
          ? _questions.foia_b.answer
          : 'N/A';
        tracksheetEntry.foia_c = _questions.foia_c
          ? _questions.foia_c.answer
          : 'N/A';
        tracksheetEntry.foia_d = _questions.foia_d
          ? _questions.foia_d.answer
          : 'N/A';
        tracksheetEntry.foia_e = _questions.foia_e
          ? _questions.foia_e.answer
          : 'N/A';
        tracksheetEntry.foia_f = _questions.foia_f
          ? _questions.foia_f.answer
          : 'N/A';
        tracksheetEntry.badActor = _questions.badActor
          ? _questions.badActor.answer
          : 'N/A';   
        tracksheetEntry.institutionalAccountStatus =
          _questions.institutionalAccountStatus
            ? _questions.institutionalAccountStatus.answer
            : 'N/A';
        tracksheetEntry.accreditedInvestor = _questions.accredited
          ? _questions.accredited
          : 'N/A';
        tracksheetEntry.qualifiedPurchaser = _questions.purchaser
          ? _questions.purchaser
          : 'N/A';
        tracksheetEntry.qualifiedClient = _questions.client
          ? _questions.client
          : 'N/A';
        tracksheetEntry.fatfCountry = _questions.wiringBankFATF
          ? _questions.wiringBankFATF.answer
          : 'N/A';
        tracksheetEntry.country = _questions.wiringBankCountry
          ? _questions.wiringBankCountry.answer
          : 'N/A';
        tracksheetEntry.bankLocation = investorFund.generalInfo 
          && investorFund.generalInfo.location
          ? investorFund.generalInfo.location
          : 'N/A';
        tracksheetEntry.wiringBankCustomer = _questions.customerOfBank
          ? _questions.customerOfBank.answer
          : 'N/A';
        tracksheetEntry.erisa_a = _questions.erisa_a
          ? _questions.erisa_a.answer
          : 'N/A';
        tracksheetEntry.erisa_b = _questions.erisa_b
          ? _questions.erisa_b.answer
          : 'N/A';
        tracksheetEntry.plannedAssetsPerc = _questions.erisaPercentage
          ? _questions.erisaPercentage.answer
          : 'N/A';
        tracksheetEntry.look_a = _questions.look_a
          ? _questions.look_a.answer
          : 'N/A';
        tracksheetEntry.look_b = _questions.look_b
          ? _questions.look_b.answer
          : 'N/A';
        tracksheetEntry.look_c = _questions.look_c
          ? _questions.look_c.answer
          : 'N/A';
        tracksheetEntry.look_d = _questions.look_d
          ? _questions.look_d.answer
          : 'N/A';
        tracksheetEntry.look_e = _questions.look_e
          ? _questions.look_e.answer
          : 'N/A';
        tracksheetEntry.governmentEntity = _questions.governmentEntity
          ? _questions.governmentEntity.answer
          : 'N/A';
        tracksheetEntry.bhcaPartner = _questions.bhcaPartner
          ? _questions.bhcaPartner.answer
          : 'N/A';
        tracksheetEntry.foundationPartner = _questions.privateFoundationPartner
          ? _questions.privateFoundationPartner.answer
          : 'N/A';
        tracksheetEntry.publicPensionPartner = _questions.publicPensionPartner
          ? _questions.publicPensionPartner.answer
          : 'N/A';
        tracksheetEntry.fundOfFunds = _questions.fundOfFunds
          ? _questions.fundOfFunds.answer
          : 'N/A';
        tracksheetEntry.taxExempt = _questions.taxExemptPartner
          ? _questions.taxExemptPartner.answer
          : 'N/A';
        tracksheetEntry.taxedAsPartnership = _questions.taxedPartnership
          ? _questions.taxedPartnership.answer
          : 'N/A';
        tracksheetEntry.usBeneficialOwners = _questions.usBeneficial
          ? _questions.usBeneficial.answer
          : 'N/A';
        tracksheetEntry.taxExemptOwners = _questions.beneficialOwner
          ? _questions.beneficialOwner.answer
          : 'N/A';

        tracksheetEntry.exemptedEntityStatus = _questions.beneficialOwner 
          ? _questions.beneficialOwner.answer : 'N/A';
        
        if(isTypeTcgCrossOver) {
           tracksheetEntry.restrictedPersonStatus = this.restrictedPersonStatusAnswer(_questions);
           tracksheetEntry.coveredInvestorStatus = this.coveredInvestorStatusAnswer(_questions);
           tracksheetEntry.determinationExemptedEntity = this.determinationExemptedEntityAnswer(_questions) 
            ? this.determinationExemptedEntityAnswer(_questions)
					  : 'N/A';
        }

        if (includesFormPFQuestion) {
          tracksheetEntry.beneficialOwnershipAdvisers = this.beneficialOwnershipAdvisersAnswer(_questions);
        }

        if (isTypeAccelMain) {
          tracksheetEntry.Rep7EUandUK = this.RepWarranty7UKAnswer(_questions);
          tracksheetEntry.Rep8Switzerland = this.RepWarranty8SwitzerlandAnswer(_questions);
          tracksheetEntry.retirementPlan = _questions.retirementPlan 
          ? _questions.retirementPlan.answer 
          : 'N/A';
        
          tracksheetEntry.foreignPartner = _questions.foreignPartner
          ? _questions.foreignPartner.answer
          : 'N/A';

          tracksheetEntry.look_b = _questions.look_e
          ? _questions.look_e.answer
          : 'N/A';
        }

        tracksheetEntry.escrow = investorFund.ofac ? investorFund.ofac : '';
        tracksheetEntry.notes = investorFund.notes ? investorFund.notes : '';
        tracksheetEntry.reportsPrimaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.reportsPrimaryContactName
          ? investorFund.generalInfo.reportsPrimaryContactName
          : 'N/A';
        tracksheetEntry.reportsPrimaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.reportsPrimaryContactEmail
          ? investorFund.generalInfo.reportsPrimaryContactEmail
          : 'N/A';
        tracksheetEntry.reportsPrimaryContactPhone = investorFund.generalInfo &&
         investorFund.generalInfo.reportsPrimaryContactPhone
          ? investorFund.generalInfo.reportsPrimaryContactPhone
          : 'N/A';
        tracksheetEntry.reportsSecondaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.reportsSecondaryContactName
          ? investorFund.generalInfo.reportsSecondaryContactName
          : 'N/A';
        tracksheetEntry.reportsSecondaryContactEmail = investorFund.generalInfo &&
        investorFund.generalInfo.reportsSecondaryContactEmail
          ? investorFund.generalInfo.reportsSecondaryContactEmail
          : 'N/A';
        tracksheetEntry.reportsSecondaryContactPhone = investorFund.generalInfo &&
         investorFund.generalInfo.reportsSecondaryContactPhone
          ? investorFund.generalInfo.reportsSecondaryContactPhone
          : 'N/A';

        tracksheetEntry.taxPrimaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.taxPrimaryContactName
          ? investorFund.generalInfo.taxPrimaryContactName
          : 'N/A';
        tracksheetEntry.taxPrimaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.taxPrimaryContactEmail
          ? investorFund.generalInfo.taxPrimaryContactEmail
          : 'N/A';
        tracksheetEntry.taxPrimaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.taxPrimaryContactPhone
          ? investorFund.generalInfo.taxPrimaryContactPhone
          : 'N/A';
        tracksheetEntry.taxSecondaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.taxSecondaryContactName
          ? investorFund.generalInfo.taxSecondaryContactName
          : 'N/A';
        tracksheetEntry.taxSecondaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.taxSecondaryContactEmail
          ? investorFund.generalInfo.taxSecondaryContactEmail
          : 'N/A';
        tracksheetEntry.taxSecondaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.taxSecondaryContactPhone
          ? investorFund.generalInfo.taxSecondaryContactPhone
          : 'N/A';
        tracksheetEntry.capitalPrimaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.capitalPrimaryContactName
          ? investorFund.generalInfo.capitalPrimaryContactName
          : 'N/A';
        tracksheetEntry.capitalPrimaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.capitalPrimaryContactEmail
          ? investorFund.generalInfo.capitalPrimaryContactEmail
          : 'N/A';
        tracksheetEntry.capitalPrimaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.capitalPrimaryContactPhone
          ? investorFund.generalInfo.capitalPrimaryContactPhone
          : 'N/A';
        tracksheetEntry.capitalSecondaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.capitalSecondaryContactName
          ? investorFund.generalInfo.capitalSecondaryContactName
          : 'N/A';
        tracksheetEntry.capitalSecondaryContactEmail = investorFund.generalInfo &&
         investorFund.generalInfo.capitalSecondaryContactEmail
          ? investorFund.generalInfo.capitalSecondaryContactEmail
          : 'N/A';
        tracksheetEntry.capitalSecondaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.capitalSecondaryContactPhone
          ? investorFund.generalInfo.capitalSecondaryContactPhone
          : 'N/A';
        tracksheetEntry.stockPrimaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.stockPrimaryContactName
          ? investorFund.generalInfo.stockPrimaryContactName
          : 'N/A';
        tracksheetEntry.stockPrimaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.stockPrimaryContactEmail
          ? investorFund.generalInfo.stockPrimaryContactEmail
          : 'N/A';
        tracksheetEntry.stockPrimaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.stockPrimaryContactPhone
          ? investorFund.generalInfo.stockPrimaryContactPhone
          : 'N/A';
        tracksheetEntry.stockSecondaryContactName = investorFund.generalInfo &&
         investorFund.generalInfo.stockSecondaryContactName
          ? investorFund.generalInfo.stockSecondaryContactName
          : 'N/A';
        tracksheetEntry.stockSecondaryContactEmail = investorFund.generalInfo &&
         investorFund.generalInfo.stockSecondaryContactEmail
          ? investorFund.generalInfo.stockSecondaryContactEmail
          : 'N/A';
        tracksheetEntry.stockSecondaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.stockSecondaryContactPhone
          ? investorFund.generalInfo.stockSecondaryContactPhone
          : 'N/A';
        tracksheetEntry.legalPrimaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.legalPrimaryContactName
          ? investorFund.generalInfo.legalPrimaryContactName
          : 'N/A';
        tracksheetEntry.legalPrimaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.legalPrimaryContactEmail
          ? investorFund.generalInfo.legalPrimaryContactEmail
          : 'N/A';
        tracksheetEntry.legalPrimaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.legalPrimaryContactPhone
          ? investorFund.generalInfo.legalPrimaryContactPhone
          : 'N/A';
        tracksheetEntry.legalSecondaryContactName = investorFund.generalInfo && 
        investorFund.generalInfo.legalSecondaryContactName
          ? investorFund.generalInfo.legalSecondaryContactName
          : 'N/A';
        tracksheetEntry.legalSecondaryContactEmail = investorFund.generalInfo && 
        investorFund.generalInfo.legalSecondaryContactEmail
          ? investorFund.generalInfo.legalSecondaryContactEmail
          : 'N/A';
        tracksheetEntry.legalSecondaryContactPhone = investorFund.generalInfo && 
        investorFund.generalInfo.legalSecondaryContactPhone
          ? investorFund.generalInfo.legalSecondaryContactPhone
          : 'N/A';
        tracksheetEntry.bankName = investorFund.generalInfo && investorFund.generalInfo.bankName
          ? investorFund.generalInfo.bankName
          : 'N/A';
        tracksheetEntry.acctName = investorFund.generalInfo && investorFund.generalInfo.acctName
          ? investorFund.generalInfo.acctName
          : 'N/A';
        tracksheetEntry.swiftCode = investorFund.generalInfo && investorFund.generalInfo.swiftCode
          ? investorFund.generalInfo.swiftCode
          : 'N/A';
        tracksheetEntry.acctNumber = investorFund.generalInfo && investorFund.generalInfo.acctNumber
          ? investorFund.generalInfo.acctNumber
          : 'N/A';
        tracksheetEntry.bankaba = investorFund.generalInfo && investorFund.generalInfo.bankaba
          ? investorFund.generalInfo.bankaba
          : 'N/A';
        tracksheetEntry.furtherCredit = investorFund.generalInfo && investorFund.generalInfo.furtherCredit
          ? investorFund.generalInfo.furtherCredit
          : 'N/A';
        tracksheetEntry.bankLocation = investorFund.generalInfo && investorFund.generalInfo.location
          ? investorFund.generalInfo.location
          : 'N/A';
       

        // VERSIONING_IQs
        let IQ_VERSION = LimitedPartnerV1;

        if (
          investorFund.questionnaire &&
          investorFund.questionnaire.iqVersion
        ) {
          IQ_VERSION = investorFund.questionnaire.iqVersion;
        }

        switch (IQ_VERSION) {
          case LimitedPartnerV1:
            tracksheetEntry.cfiusForeignPerson = 'N/A';
            break;

          case QuestaCapitalV1:
          case InitializedV1:
          case LimitedLiabilityCompanyV1:
          case LimitedPartnerV2:
            tracksheetEntry.cfiusForeignPerson = handleDefaultValue(investorFund, iqID.id_foreign_person_or);
          
          break;

          case HCMV1:
          case HCMV2:
          case HCMV3:
          case HCMV4:
            if (_questions.hcm && _questions.hcm.length) {
              const mainQuestion = _questions.hcm[0];
              const mainHCMQuestionDescription =
                questionsHCMv1.identificationofInvestorStatus.find(
                  (q) => q.id === 'id_investor_status_7_hcm'
                );
              const selectedOptionDescription =
                mainHCMQuestionDescription.options.find(
                  (sopt) => sopt.id === mainQuestion.descriptionIdentifier
                );

              const triggerQuestion = _questions.hcm[1];
              tracksheetEntry.hcm = `${selectedOptionDescription.displayName}${
                triggerQuestion && triggerQuestion.answer
                  ? ` - ${triggerQuestion.answer}`
                  : ''
              }`;
            }

            tracksheetEntry.cfiusForeignPerson = handleDefaultValue(investorFund, iqID.id_foreign_person_or);
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              tracksheetEntry.foreignGovtControlled =  handleDefaultValue(investorFund, iqID.id_foreign_person_2_1, 'GovtControlled');
                
            }
            break;

          case ForesiteLabsV1:
          case ForesiteLabsV2:
          case ForesiteLabsV3:
          case ForesiteLabsV4:
            const parsedQuestions = getInvestorFundQuestions(
              investorFund.questionnaire.questions,
              this.isShortIQ
            );

            tracksheetEntry.cfiusForeignPerson = handleDefaultValue(investorFund, iqID.id_foreign_person_or);

            tracksheetEntry.foresite_investor_type =
              parsedQuestions.foresite_investor_type
                ? parsedQuestions.foresite_investor_type.displayName
                : 'N/A';

            tracksheetEntry.foresiteFinraAffiliation = handleDefaultValue(investorFund, iqID.id_foresiteFinraAffiliation);
              

            tracksheetEntry.foresiteFinraAffiliationFurtherDetails =
              parsedQuestions.foresiteFinraAffiliationFurtherDetails
                ? parsedQuestions.foresiteFinraAffiliationFurtherDetails.answer
                : 'N/A';

            tracksheetEntry.foresitePublicCompanyAffiliation = handleDefaultValue(investorFund, iqID.id_foresitePublicCompanyAffiliation);

            tracksheetEntry.foresitePublicCompanyAffiliationFurtherDetails =
              parsedQuestions.foresitePublicCompanyAffiliation_opt
                ? parsedQuestions.foresitePublicCompanyAffiliation_opt.answer
                : 'N/A';

            tracksheetEntry.foresiteEntities = handleDefaultValue(investorFund, iqID.id_foresiteEntities);
            

            tracksheetEntry.foresiteEntitiesFurtherDetails =
              parsedQuestions.foresiteEntities_opt
                ? parsedQuestions.foresiteEntities_opt.answer
                : 'N/A';

            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              tracksheetEntry.foreignGovtControlled = handleDefaultValue(investorFund, iqID.id_foreign_person_2_1, 'GovtControlled');
             
            }
            break;

          case OSAGEV1:
          case OSAGEV2:
            tracksheetEntry.cfiusForeignPerson = handleDefaultValue(investorFund, iqID.id_foreign_person_or);
              
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              tracksheetEntry.foreignGovtControlled = handleDefaultValue(investorFund, iqID.id_foreign_person_2_1, 'GovtControlled');
              
            }

            tracksheetEntry.partnerStatementsPrimaryContactName = investorFund.generalInfo && 
            investorFund.generalInfo.partnerStatementsPrimaryContactName
              ? investorFund.generalInfo.partnerStatementsPrimaryContactName
              : 'N/A';
            tracksheetEntry.partnerStatementsPrimaryContactEmail = investorFund.generalInfo &&
             investorFund.generalInfo.partnerStatementsPrimaryContactEmail
              ? investorFund.generalInfo.partnerStatementsPrimaryContactEmail
              : 'N/A';
            tracksheetEntry.partnerStatementsPrimaryContactPhone = investorFund.generalInfo && 
            investorFund.generalInfo.partnerStatementsPrimaryContactPhone
              ? investorFund.generalInfo.partnerStatementsPrimaryContactPhone
              : 'N/A';
            tracksheetEntry.partnerStatementsSecondaryContactName = investorFund.generalInfo &&
            investorFund.generalInfo.partnerStatementsSecondaryContactName
              ? investorFund.generalInfo.partnerStatementsSecondaryContactName
              : 'N/A';
            tracksheetEntry.partnerStatementsSecondaryContactEmail = investorFund.generalInfo &&
              investorFund.generalInfo.partnerStatementsSecondaryContactEmail
                ? investorFund.generalInfo
                    .partnerStatementsSecondaryContactEmail
                : 'N/A';
            tracksheetEntry.partnerStatementsSecondaryContactPhone = investorFund.generalInfo &&
              investorFund.generalInfo.partnerStatementsSecondaryContactPhone
                ? investorFund.generalInfo
                    .partnerStatementsSecondaryContactPhone
                : 'N/A';

            tracksheetEntry.rule5130_finraNewIssues_1 =
              (_questions.rule5130_finraNewIssues_1 &&
                _questions.rule5130_finraNewIssues_1.answer === 'Yes') ||
              (_questions.rule5130_finraNewIssues_1_opt_1 &&
                _questions.rule5130_finraNewIssues_1_opt_1.answer === 'Yes') ||
              (_questions.rule5130_finraNewIssues_1_opt_2 &&
                _questions.rule5130_finraNewIssues_1_opt_2.answer === 'Yes') ||
              (_questions.rule5130_finraNewIssues_1_opt_3 &&
                _questions.rule5130_finraNewIssues_1_opt_3.answer === 'Yes')
                ? 'Yes'
                : _questions.rule5130_finraNewIssues_1_opt_3 && _questions.rule5130_finraNewIssues_1_opt_3.answer === 'No' ? 'No' : 'N/A';
            tracksheetEntry.rule5130_finraNewIssues_2 =
              _questions.rule5130_finraNewIssues_2 &&
              _questions.rule5130_finraNewIssues_2.answer === 'Yes'
                ? 'Yes'
                : _questions.rule5130_finraNewIssues_2 && _questions.rule5130_finraNewIssues_2.answer === 'No' ? 'No' : 'N/A';

            tracksheetEntry.rule5131_finraNewIssues_1 =
              _questions.rule5131_finraNewIssues_1 &&
              _questions.rule5131_finraNewIssues_1.answer === 'Yes'
                ? 'Yes'
                : _questions.rule5131_finraNewIssues_1 && _questions.rule5131_finraNewIssues_1.answer === 'No' ? 'No' : 'N/A';
            tracksheetEntry.rule5131_finraNewIssues_2 =
              _questions.rule5131_finraNewIssues_2 &&
              _questions.rule5131_finraNewIssues_2.answer === 'Yes'
                ? 'Yes'
                : _questions.rule5131_finraNewIssues_2 && _questions.rule5131_finraNewIssues_2.answer === 'No' ? 'No' : 'N/A';

            break;
          
          case SROneV1:
            tracksheetEntry.PartAFinra = this.partAFinra5130Answer(_questions);
            tracksheetEntry.PartBFinra = this.partBFinra5131Answer(_questions);
          break;
          
          case QuestaCapitalV2:
          case QuestaCapitalV3:
          case QuestaCapitalV4:
          case QuestaCapitalV5:
          case QuestaCapitalV6:
          case QuestaCapitalV7:
          case InitializedV2:
          case InitializedV3:
          case InitializedV4:
          case InitializedV5:
          case InitializedV6:
          case InitializedV7:
          case FrazierV1:
          case LimitedPartnerV3:
          case LimitedPartnerV4:
          case LimitedPartnerV5:
          case LimitedPartnerV6:
          case LimitedPartnerV7:
          case LimitedPartnerV8:
          case LimitedPartnerV8_1:
          case LimitedPartnerV9:
          case LimitedPartnerV10:
          case LimitedPartnerV11:
          case LimitedLiabilityCompanyV2:
          case LimitedLiabilityCompanyV3:
          case LimitedLiabilityCompanyV4:
          case LimitedLiabilityCompanyV5:
          case LimitedLiabilityCompanyV6:
          case LimitedLiabilityCompanyV6_1:
          case LimitedLiabilityCompanyV7:
          case LimitedLiabilityCompanyV8:
          case LimitedLiabilityCompanyV9:
          case LimitedPartnershipMFV1:
          case LimitedPartnershipMFV2:
          case LimitedPartnershipMFV3:
          case PrometheanV1:
          case PrometheanV2:
          case BITKRAFTV1:
          case BITKRAFTV2:
          case ElephantPartnerV1:
          case ElephantPartnerV2:
          case TcgCrossOverV1:
          case TcgCrossOverV2:
          case NewLimitedPartnerV1:
          case NewLimitedPartnerV2:
          case FireworkV1:
          case FireworkV2:
          case AccelV1:
          case AccelV2:
          case AccelV3:
          case AccelMainV1:
          case AccelEntrepreneursV1:
          case AccelEtpnReturningV1:
          case AccelEtpnReturningV2:
          case LPMULTIFUNDV1:
          case LPMULTIFUNDV2:
          case LPMULTIFUNDV3:
          case LPABBRV1:
          case LPABBRV1_1:
          case LPABBRV2:
          case LPABBRV3:
          case LPABBRV4:
          case IOLARV1:
          case IOLARV2:
          case AudereV1:
          case AudereV2:
          case CaymanV1:
          case CaymanV2:
          case SROneV1:
            tracksheetEntry.cfiusForeignPerson = handleDefaultValue(investorFund, iqID.id_foreign_person_or);
            if (
              _questions.naturalPerson &&
              _questions.naturalPerson.answer === 'No'
            ) {
              tracksheetEntry.foreignGovtControlled = handleDefaultValue(investorFund, iqID.id_foreign_person_2_1, 'GovtControlled');
            }

            break;
            
          default:
            tracksheetEntry.cfiusForeignPerson = 'N/A';
            break;
        }
      }
      const omitFromCsv = tracksheetEntry.status === STATUS_TYPES.OMIT_FROM_GP_ACCESS.value;
      const accelReturningInvestor = investorFund.questionnaire && (
        investorFund.questionnaire.iqVersion.includes(AccelEtpnReturningV1) ||
        investorFund.questionnaire.iqVersion.includes(AccelEtpnReturningV2));

      const checkGeneralInfoCompleted = this.checkGeneralInfoCompleted(
        { ...investorFund.generalInfo, location: tracksheetEntry.location, accelReturningInvestor }, requireCapitalCommitment);

      if (checkGeneralInfoCompleted && !omitFromCsv) {
        investorFunds.push(tracksheetEntry);
      }
    };
    if (
      admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.investorFunds &&
      typeof admin.dashboardSelectedFund.investorFunds[0] === 'object'
    ) {
      for (
        let i = 0;
        i < admin.dashboardSelectedFund.investorFunds.length;
        i += 1
      ) {
        const investorFund = admin.dashboardSelectedFund.investorFunds[i];
        let questionsArray = [];
        if (investorFund.submitted && investorFund.questionnaire) {
          questionsArray = this.getInvestorFundQuestions(
            investorFund.questionnaire.questions,
            this.isShortIQ
          );
        }
        createTrackSheetTemplate(investorFund, questionsArray);
      }
    }
    if (
      admin.dashboardSelectedFund &&
      admin.dashboardSelectedFund.limitedPartnerFunds &&
      typeof admin.dashboardSelectedFund.limitedPartnerFunds[0] === 'object'
    ) {
      for (
        let i = 0;
        i < admin.dashboardSelectedFund.limitedPartnerFunds.length;
        i += 1
      ) {
        const lpInvestFund = admin.dashboardSelectedFund.limitedPartnerFunds[i];
        let questionsArray = [];
        if (lpInvestFund.submitted && lpInvestFund.questionnaire) {
          questionsArray = this.getInvestorFundQuestions(
            lpInvestFund.questionnaire.questions,
            this.isShortIQ
          );
        }
        createTrackSheetTemplate(lpInvestFund, questionsArray);
      }
    }

    const csvPayload = {
      data: investorFunds,
      opts: {
        fields: this.filterDataByVersion(fields),
        fieldNames,
        withBOM: true,
        fundLegalName: fundLegalName,
        fund2LegalName: fund2LegalName,
        fund3LegalName: fund3LegalName,
      },
    };
    csvPayload.data.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    exportCSV(csvPayload);
  }

  filterDataByVersion = (data) => {
    const { fundType } = this.props.admin.dashboardSelectedFund;
    const newArr = [];
    switch (fundType.identifier) {
      case FUND_TYPES.LIMITED_PARTNER.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.LIMITED_PARTNER.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.FRAZIER.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.FRAZIER.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

     case FUND_TYPES.SR_ONE.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.SR_ONE.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.CAYMAN.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.CAYMAN.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

       case FUND_TYPES.AUDERE.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.AUDERE.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      case FUND_TYPES.PROMETHEAN.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.PROMETHEAN.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      case FUND_TYPES.LIMITED_LIABILITY_COMPANY.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.LIMITED_LIABILITY_COMPANY.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.FORESITE.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.FORESITE.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.QUESTACAPITAL.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.QUESTACAPITAL.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.INITIALIZED.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.INITIALIZED.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.HCM.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.HCM.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.FORESITELABS.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.FORESITELABS.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.LIMITED_PARTNER_ABBR.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.LIMITED_PARTNER_ABBR.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.IOLAR.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.IOLAR.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.OSAGE.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.OSAGE.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.NEW_LIMITED_PARTNER.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.NEW_LIMITED_PARTNER.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
      case FUND_TYPES.TCG_CROSSOVER.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.TCG_CROSSOVER.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.BITKRAFT.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.BITKRAFT.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
      case FUND_TYPES.ELEPHANT_PARTNER.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.ELEPHANT_PARTNER.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.FIREWORK.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.FIREWORK.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
      case FUND_TYPES.PROMETHEAN.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.PROMETHEAN.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      case FUND_TYPES.ACCEL.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.ACCEL.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.ACCEL_ENTREPRENEURS.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.ACCEL_ENTREPRENEURS.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
      case FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.ACCEL_ENTREPRENEURS_RETURNING.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;

      case FUND_TYPES.ACCELMAIN.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.ACCELMAIN.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
       case FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.LIMITED_PARTNER_MULTI_FUND.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      case FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id:
        data.map((item) => {
          if (!EXCLUDE_DATA.LIMITED_PARTNERSHIP_MULTI_FUND.includes(item)) {
            newArr.push(item);
          }
        });
        return newArr;
      
      default:
        return data;
    }
  };

  submit = (event) => {
    event.preventDefault();
    confirmAlert({
      title: 'Confirm VC Update',
      message:
        'Are you sure you want to send an email update to your contact(s) at this fund?',
      childrenElement: () => (
        <div className="text-center">
          <br />
          <br />
          <b>{this.props.admin.dashboardSelectedFund.fundLegalName}</b>
        </div>
      ),
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      onConfirm: () => this.emailHandler(),
    });
  };

  emailHandler() {
    const { updateEmail, admin } = this.props;
    updateEmail(admin.dashboardSelectedFund._id);
  }

  // eslint-disable-next-line class-methods-use-this
  /**
   * @param {Models.Fund} fund
  */
  calculateCapitalAmount(fund) {
    let closedAmt = 0;
    let allAmt = 0;
    let readyToClose = 0;
    let notReadyToClose = 0;
    if (fund) {
      fund.investorFunds.forEach((inv) => {
        const capital =
          inv.generalInfo && inv.generalInfo.capitalCommitment
            ? inv.generalInfo.capitalCommitment
            : 0;
        allAmt += Number(capital);
        switch (inv.status) {
          case STATUS_TYPES.CLOSED.label:
            closedAmt += Number(capital);
          break;
          case STATUS_TYPES.READY_TO_CLOSE.label:
            readyToClose += Number(capital);
          break;
          case STATUS_TYPES.IN_PROGRESS.label:
          case STATUS_TYPES.PENDING.label:
          case STATUS_TYPES.UNDER_REVIEW.label:
          case STATUS_TYPES.SUBMITTED.label:
            notReadyToClose += Number(capital);
          break;
          case STATUS_TYPES.OMIT_FROM_GP_ACCESS.value:
            allAmt -= Number(capital);
            break;
          default:
            return
        }
        
      });
      fund.limitedPartnerFunds.forEach((inv) => {
        const capital =
          inv.generalInfo && inv.generalInfo.capitalCommitment
            ? inv.generalInfo.capitalCommitment
            : 0;
       allAmt += Number(capital);
        
        switch (inv.status) {
          case STATUS_TYPES.CLOSED.label:
            closedAmt += Number(capital);
          break;
          case STATUS_TYPES.READY_TO_CLOSE.label:
            readyToClose += Number(capital);
          break;
          case STATUS_TYPES.OMIT_FROM_GP_ACCESS.value:
            allAmt -= Number(capital);
            break;
          default:
            // Status undefined or in progress are summed up as not ready to close
            notReadyToClose += Number(capital);
            return
        }
      });
      this.setState({
        ...this.state,
        closedAmount: closedAmt,
        allCapitalAmount: allAmt,
        readyToCloseAmount: readyToClose,
        notReadyToCloseAmount: notReadyToClose,
      });
    }
    return closedAmt;
  }

  handleButtonEditFund(event) {
    event.preventDefault();
    this.props.history.push(
      `/admin/main/editFund/${document.baseURI.split('fundDetail/')[1]}`
    );
  }

  handlerOnChange(event) {
    const eName = event.target.name;
    this.setState({
      ...this.state,
      [eName]: event.target.value,
    });
  }

  handleSendMail() {
    if (this.state.emailsText) {
      const { sendMailShareFund } = this.props;
      const to = _.split(this.state.emailsText, ',', 100);
      _.forEach(to, (mail) => {
        const refMail = mail.split(' ')[1];
        sendMailShareFund(
          refMail || mail,
          this.state.messageText,
          this.props.admin.dashboardSelectedFund._id,
          this.props.admin.dashboardSelectedFund.fundLegalName
        );
      });
    }
  }

  setPaginationsItems = (event) => {
    const maxItems = event.target.value;

    const { router, fetchFund } = this.props;
    const routLocation = router.location.pathname;
    const id = routLocation.split('fundDetail/')[1];

    if(id) {
      this.setState(
      { ...this.state, iQsMaxItems: maxItems, iQsPage: 1, iQsPages: null },
      () => {
        fetchFund(id, this.setCheckedFunds);
      }
    );
    }
  };

  selectPage = (page) => {
    const { router, fetchFund } = this.props;
    const routLocation = router.location.pathname;
    const id = routLocation.split('fundDetail/')[1];

    if (id) {
      this.setState({ ...this.state, iQsPage: page }, () => {
        fetchFund(id, this.setCheckedFunds);
      });
    }
  };

  render() {
    const { admin, context } = this.props;
    const { iQsMaxItems, iQsPages, iQsPage } = this.state;

     const isTypeMultiFund = admin.dashboardSelectedFund &&
      (admin.dashboardSelectedFund.fundType &&
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id) || //short multi-fund
      (admin.dashboardSelectedFund.fundType &&   
      admin.dashboardSelectedFund.fundType.identifier === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id);  //limited Partnership multi-fund

     const displayNameFund2 = isTypeMultiFund && admin.dashboardSelectedFund && admin.dashboardSelectedFund.fund2LegalName 
     ? ` ${admin.dashboardSelectedFund.fund2LegalName}` : '';

     const displayNameFund3 = isTypeMultiFund && admin.dashboardSelectedFund && admin.dashboardSelectedFund.fund3LegalName 
     ? ` ${admin.dashboardSelectedFund.fund3LegalName}` : '';

    const handleShareLink = (fund) => {
      if (!fund) return '';
      const url = typeof window !== 'undefined' ? document.URL.split('/admin')[0] : '';
      return `${url}/access/login?f=${fund._id}`;
    }

    return (
      <div>
        <Helmet title="FirmFundsDetail" />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <Banner
          bannerType={'fund'}
          actionHandler={this.exportToCSV}
          handleAddPaperIQ={this.handleAddPaperIQ}
          emailHandler={this.submit}
          handleEditButton={this.handleButtonEditFund}
          shareTarget={'modalShareFund'}
          shareLink={handleShareLink(admin.dashboardSelectedFund)}
          content={{
            displayName: admin.dashboardSelectedFund
              ? admin.dashboardSelectedFund.fundLegalName
              : '',
            displayNameFund2,
            displayNameFund3,
            description: admin.dashboardSelectedFund
              ? admin.dashboardSelectedFund.generalPartnerLegalName
              : '',
            contactInfo: admin.dashboardSelectedFund
              ? [admin.dashboardSelectedFund.generalPartnerEmailAddress]
              : [],
          }}
        />
        <div className="main-container container-pull">
          <div className="container space-bottom">
            <FilterBox
              data={{
                boxTitle: 'Back to Dashboard',
                boxTitleIsLink: true,
                // buttonId: 'newFund-btn',
                // buttonDisplayName: 'Close on LPs',
                // buttonIcon: '',
                // buttonType: 'button',
                filterType: 'detail',
                detail: {
                  closedAmount: this.state.closedAmount,
                  allCapitalAmount: this.state.allCapitalAmount,
                  readyToCloseAmount: this.state.readyToCloseAmount,
                  notReadyToCloseAmount: this.state.notReadyToCloseAmount,
                  currency: admin.dashboardSelectedFund.currency,
                },
                page: 'firmFunds',
              }}
              // buttonHandler={this.handleButton}
              linkHandler={this.handleLink}
            />
            <TableContainer
              content={this.renderTableContents()}
              horizontalScroll
              statusMenuCallback={(idx) => {
                this.setState({ selectedIndex: idx });
              }}
              selectedIndex={this.state.selectedIndex}
              scrollable
              paginationConf={{
                data: iQsPages,
                currentPage: iQsPage,
                max: iQsMaxItems,
                additionalClass: '-tracking-sheet',
                refreshData: this.setPaginationsItems,
                selectPage: this.selectPage,
              }}
            />
          </div>
        </div>
        <Popup
          data={{
            id: 'modalShareFund',
            shareId: admin.dashboardSelectedFund
              ? admin.dashboardSelectedFund._id
              : '',
            displayName: admin.dashboardSelectedFund
              ? admin.dashboardSelectedFund.fundLegalName
              : '',
            displayNameFund2,
            displayNameFund3,
            popupType: 'share',
            title: 'Share this Fund',
            messageValue: this.state.messageText,
            pathValue:
              typeof window !== 'undefined'
                ? document.URL.split('/admin')[0]
                : '',
          }}
          emailChangeHandler={this.handlerOnChange}
          messageChangeHandler={this.handlerOnChange}
          shareLinkChangeHandler={this.handlerOnChange}
          actionCallback={this.handleSendMail}
        />
      </div>
    );
  }
}
/**
 * @param {Gunderson.Reducer} state
 */
const mapStateToProps = (state) => ({
  admin: state.admin,
  router: state.router,
  context: state.context,
});

/**
 * @type {import("react-redux").Connect<Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
   * @param {import('../../types/index').Dispatch}
  */
  (dispatch) => ({
    fetchFund: (id, callback) => dispatch(action.fetchFund(id, callback)),
    replaceFund: (fund) =>
      dispatch({ type: 'SET_DASHBOARD_FUND', dashboardSelectedFund: fund }),
    updateFundLastClosing: (fund) =>
      dispatch(action.updateFundLastClosing(fund)),
    fetchInvestorFunds: (page) => dispatch(action.fetchInvestorFunds(page)),
    updateInvestorFund: (investorFund, callback) =>
      dispatch(action.updateInvestorFund(investorFund, callback)),
    exportCSV: (csv) => dispatch(action.exportCSV(csv)),
    updateEmail: (fundId) => dispatch(action.updateEmail(fundId)),
    updateInvestorFundsSequentally: (investorFunds) =>
      dispatch(action.updateInvestorFundsSequentally(investorFunds)),
    sendMailShareFund: (to, text, fundId, fundName) =>
      dispatch(action.sendMailShareFund(to, text, fundId, fundName)),
    addPaperIQ: (fund, loggedUserEmail, createdRole) =>
      dispatch(action.addPaperIQ(fund, loggedUserEmail, createdRole)),
  })
);

export default connector(FundDetail);
