const iqID = require('../../identifiers');

const parseRadioIds = (_id) => [`${_id}_yes`, `${_id}_no`];

module.exports = {
  questions: {
    generalInvestorRepresentationsandWarranties: [],
    identificationofInvestorStatus: [],
     categoriesOfBeneficialOwnershipQuestions: [
       {
        id: iqID.id_investor_status_7_tcg_a,
        identifier: 'E_7_a_tcg',
        displayName: 'Individual that is a United States person (or a trust of such person)',
        helpText: `“United States person,” for purposes of this question, has the meaning 
        provided in rule 203(m)-1 under the Investment Advisers Act of 1940, which includes, among others, 
        any natural person that is resident in the United States of America, its territories and possessions, any state of 
        the United States, or the District of Columbia.`,
        isSelected: false,
        showHelp: true,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_b,
        identifier: 'E_7_b_tcg',
        displayName: 'Individual that is not a United States person (or a trust of such person)',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_c,
        identifier: 'E_7_c_tcg',
        displayName: 'Broker-dealer',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_d,
        identifier: 'E_7_d_tcg',
        displayName: 'Insurance company',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_e,
        identifier: 'E_7_e_tcg',
        displayName: 'Investment company registered with the SEC',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_f,
        identifier: 'E_7_f_tcg',
        displayName: 'Private fund',
        helpText: `“Private fund,” for purposes of this question, means any issuer that would 
        be an investment company as defined in Section 3 of the Investment Company Act of 1940 but for Section 3(c)(1) or 3(c)(7) of that Act.`,
        isSelected: false,
        showHelp: true,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_g,
        identifier: 'E_7_g_tcg',
        displayName: 'Non-profit',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_h,
        identifier: 'E_7_h_tcg',
        displayName: 'Pension plan (excluding governmental pension plan)',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_i,
        identifier: 'E_7_i_tcg',
        displayName: 'Banking or thrift institution (proprietary)',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_j,
        helpText: `“Government entity,” for purposes of this question, means any U.S. state (including any U.S. state, the District of Columbia, 
          Puerto Rico, the U.S. Virgin Islands or any other possession of the United States) or political subdivision of a state, including: (i) 
          any agency, authority or instrumentality of the state or political subdivision; (ii) a plan or pool of assets controlled by the state or 
          political subdivision or any agency, authority or instrumentality thereof; and (iii) any officer, agent, or employee of the state or political 
          subdivision or any agency, authority or instrumentality thereof, acting in his or her official capacity.`,
        identifier: 'E_7_j_tcg',
        displayName: 'State or municipal government entity (excluding any governmental pension plan)',
        isSelected: false,
        showHelp: true,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_k,
        identifier: 'E_7_k_tcg',
        displayName: 'State or municipal governmental pension plan',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_l,
        identifier: 'E_7_l_tcg',
        displayName: 'Sovereign wealth fund or foreign official institution',
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_m,
        identifier: 'E_7_m_tcg',
        displayName: `An entity wholly owned by a person or entity described 
        in (a)-(l) above or trust whose sole beneficiary is described in (a)-(l) 
        above 
        <br></br> 
        If the above box is checked, please indicate which category in (a)-(l) above 
        best describes the sole owner or beneficiary:`,
        helpText: '',
        isSelected: false,
        showHelp: false,
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_n,
        helpText: '',
        isSelected: false,
        showHelp: false,
        identifier: 'E_7_n_tcg',
        displayName: 'A person or entity (other than as described in (m) above) that is not a United States person and about which the foregoing beneficial ownership information is not known and cannot reasonably be obtained because the beneficial interest is held through a chain involving one or more third-party intermediaries',
        name: '',
      },
      {
        id: iqID.id_investor_status_7_tcg_o,
        helpText: '',
        isSelected: false,
        identifier: 'E_7_o_tcg',
        name: '',
        displayName: 'Other',
        showHelp: false,
      },
    ],
    accreditedInvestorRepresentations: [],
    qualifiedClientRepresentations: [],
    qualifiedPurchaserRepresentations: [],
    foreignPersonStatusRepresentations: [],
    foreignPersonStatusRepresentations2: [],
    wiringBankStatus: [],
    identificationofSpecialEntities: [],
    warrantiesExceptions: [],
    additionalContactInfo: [],
    miscellaneous: [],
    additionalDistributionInfo: [
      {
        id: iqID.id_additional_contact_info,
        showHelp: false,
        helpText: '',
        radioIdentifier: parseRadioIds(iqID.id_additional_contact_info),
        displayName:'Same as Currently on File with the Fund Manager?',
        isSelected: false,
        tooltip: '',
        name: 'additional_contact_info',
        size: 'col-lg-10 col-md-10 col-sm-10 col-xs-10 general-information-question',
        identifier: 'additional_contact_info',
        answer: '',
      },
    ],
  },
};
