import React from 'react';
import './styles.scss';


type Props = { data: Object, clickHandler: () => {} };

function defaultClick(e) {
  e.preventDefault();
}

const Part = ({ data, clickHandler }: Props) => (
  <li id={data.id} className={`part-container ${data.status}`} data-position={data.id}>
    <a onClick={clickHandler} href="#/" data-position={data.id}>
      <span className="part-heading" data-position={data.id}>{data.heading}</span>
      <span className="part-title" data-position={data.id}>{data.title}</span>
    </a>
  </li>
);

Part.defaultProps = {
  data: {
    heading: 'Default Heading',
    title: 'Default title',
    id: '',
    status: '',
  },
  clickHandler: defaultClick,
};

export default Part;

