export const EXCLUDE_DATA = {
  LIMITED_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  FRAZIER: [
     'foresite_investor_type',
     'capitalCommitted',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
  ],
  SR_ONE: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'subscription',
  ],
  CAYMAN: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  AUDERE: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  LIMITED_LIABILITY_COMPANY: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  FORESITE: [
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  QUESTACAPITAL: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  INITIALIZED: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  HCM: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'institutionalAccountStatus',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  FORESITELABS: [
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  LIMITED_PARTNER_ABBR: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  IOLAR: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  OSAGE: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  NEW_LIMITED_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
   TCG_CROSSOVER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
   BITKRAFT: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  ELEPHANT_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  FIREWORK: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
   PROMETHEAN: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  ACCEL: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  ACCELMAIN: [
    'beneficialOwner',
    'usBeneficial',
    'taxedPartnership',
    'taxedAsPartnership',
    'usBeneficialOwners',
    'exemptedEntityStatus',
    'look_c',
    'look_d',
    'look_e',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  ACCEL_ENTREPRENEURS: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  ACCEL_ENTREPRENEURS_RETURNING: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'contactPersonPhone',
    'location',
    'wireInfo',
    'naturalPerson',
    'usPersonSecAct',
    'usPersonIrc',
    'finra',
    'foia_a',
    'foia_b',
    'foia_c',
    'foia_d',
    'foia_e',
    'foia_f',
    'badActor',
    'accreditedInvestor',
    'qualifiedPurchaser',
    'qualifiedClient',
    'fatfCountry',
    'country',
    'bankLocation',
    'wiringBankCustomer',
    'erisa_a',
    'erisa_b',
    'plannedAssetsPerc',
    'look_a',
    'look_b',
    'look_c',
    'look_d',
    'look_e',
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'fundOfFunds',
    'taxExempt',
    'taxedAsPartnership',
    'taxExemptOwners',
    'escrow',
    'reportsPrimaryContactName',
    'reportsPrimaryContactEmail',
    'reportsPrimaryContactPhone',
    'reportsSecondaryContactName',
    'reportsSecondaryContactEmail',
    'reportsSecondaryContactPhone',
    'taxPrimaryContactName',
    'taxPrimaryContactEmail',
    'taxPrimaryContactPhone',
    'taxSecondaryContactName',
    'taxSecondaryContactEmail',
    'taxSecondaryContactPhone',
    'capitalPrimaryContactName',
    'capitalPrimaryContactEmail',
    'capitalPrimaryContactPhone',
    'capitalSecondaryContactName',
    'capitalSecondaryContactEmail',
    'capitalSecondaryContactPhone',
    'stockPrimaryContactName',
    'stockPrimaryContactEmail',
    'stockPrimaryContactPhone',
    'stockSecondaryContactName',
    'stockSecondaryContactEmail',
    'stockSecondaryContactPhone',
    'legalPrimaryContactName',
    'legalPrimaryContactEmail',
    'legalPrimaryContactPhone',
    'legalSecondaryContactName',
    'legalSecondaryContactEmail',
    'legalSecondaryContactPhone',
    'bankName',
    'acctName',
    'swiftCode',
    'acctNumber',
    'bankaba',
    'furtherCredit',
    'cfiusForeignPerson',
    'institutionalAccountStatus',
    'exemptedEntityStatus',
    'usBeneficialOwners',
    'foreignGovtControlled',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  LIMITED_PARTNER_MULTI_FUND: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
  LIMITED_PARTNERSHIP_MULTI_FUND: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
    'totalAllFund',
    'PartAFinra',
    'PartBFinra',
    'subscription',
  ],
};

export const EXCLUDE_HEADERS = {
  LIMITED_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   FRAZIER: [
    'Capital Committed',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
  ],
  SR_ONE: [
   'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Subscription',
  ],
  CAYMAN: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   AUDERE: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  LIMITED_LIABILITY_COMPANY: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  FORESITE: [
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  QUESTACAPITAL: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  INITIALIZED: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  HCM: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  FORESITELABS: [
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  LIMITED_PARTNER_ABBR: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   IOLAR: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
    'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  OSAGE: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  NEW_LIMITED_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   TCG_CROSSOVER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  BITKRAFT: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   ELEPHANT_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   FIREWORK: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  PROMETHEAN: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
   ACCEL: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  ACCELMAIN: [
    'Taxed as a Partnership',
    '100% U.S. Beneficial Owners',
    'Tax Exempt Beneficial Owners',
    'Lookthrough (c)',
    'Lookthrough (d)',
    'Lookthrough (e)',
     'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  ACCEL_ENTREPRENEURS: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  ACCEL_ENTREPRENEURS_RETURNING: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Total All Funds',
    'City/State/Country',
    'For Further Credit to',
    'Bank ABA#',
    'Account Number',
    'Swift Code',
    'Accountholder Name',
    'Bank Name',
    'Phone Number',
    'Email Address',
    'Legal Documents Secondary Contact Name',
    'Phone Number',
    'Email Address',
    'Phone Number',
    'Email Address',
    'Legal Documents Primary Contact Name',
    'Phone Number',
    'Cash and Stock Distributions Secondary Contact Name',
    'Cash and Stock Distributions Primary Contact Name',
    'Capital Call Notices Secondary Contact Name',
    'Capital Call Notices Primary Contact Name',
    'Tax Information/K-1s Secondary Contact Name',
    'Tax Information/K-1s Primary Contact Name',
    `Quarterly Partner's Capital Statements Secondary Contact Name`,
    `Quarterly Partner's Capital Statements Primary Contact Name`,
    'Quarterly and Annual Reports Secondary Contact Name',
    'Quarterly and Annual Reports Primary Contact Name',
    'Exceptions',
    'Escrow',
    'Tax Exempt Beneficial Owners',
    '100% U.S. Beneficial Owners',
    'Taxed as a Partnership',
    'Tax Exempt',
    'Fund-of-Funds?',
    'Lookthrough (e)',
    'Lookthrough (d)',
    'Lookthrough (c)',
    'Lookthrough (b)',
    'Lookthrough (a)',
    '% of Plan Assets',
    'ERISA(b)',
    'ERISA(a)',
    'Qualified Client?',
    'Qualified Purchaser?',
    'Accredited Investor?',
    'FOIA(f)',
    'FOIA(e)',
    'FOIA(d)',
    'FOIA(c)',
    'FOIA(b)',
    'FOIA(a)',
    'US Person (IRC)?',
    'Natural Person',
    'Place of Residence/Business',
    'CFIUS Foreign Person',
    'Foreign Govt Controlled',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  LIMITED_PARTNER_MULTI_FUND: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
     'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
  LIMITED_PARTNERSHIP_MULTI_FUND: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
    'Returning Investor',
    'Total All Funds',
    'Contact Person Address',
    'Part A: FINRA Rule 5130',
    'Part B: FINRA Rule 5131',
    'Subscription',
  ],
};

// using "filteringId"
// move is the field that will be move and after where it will be moves
export const MOVE_HEADERS = {
  LIMITED_PARTNER: [
   { move: 'investment-notes-col', after: 'investment-ofac-col' },
   { move: 'cfiusForeignPerson-col', after: 'location-col' },
   { move: 'foreignGovtControlled-col', after: 'cfiusForeignPerson-col' },
  ],
  FRAZIER: [
    { move: 'investment-notes-col', after: 'investment-ofac-col' },
    { move: 'cfiusForeignPerson-col', after: 'location-col' },
    { move: 'foreignGovtControlled-col', after: 'cfiusForeignPerson-col' },
  ],
};