/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import config from '../../config';
import './theme/app.css';
import AdminLoginPage from './containers/AdminLogin';
import OrganizationLoginPage from './containers/OrganizationLogin';
import AuthenticatedContainerLayout from './containers/AuthenticatedContainer';
import OrganizationAuthenticatedContainerLayout from './containers/OrganizationAuthenticatedContainer';
import AccessContainerLayout from './containers/AccessContainer';
import SignaturesCallbackPage from './containers/QuestionnaireExperience/SignaturesWebhook';
import NotFoundPage from './containers/NotFound';
import SignUpPage from './containers/FundSignUp';
import ForgotPass from './containers/ForgotPassword';
import ResetPass from './containers/ResetPassword';
import TermsOfUsePage from './containers/Terms';
import PrivacyPolicyPage from './containers/PrivacyPolicy';
import Alert from './components/Alert/index';

type Props = {
  history: Object,
  match: Object,
  location: Object,
  context: any,
};
const App = (props: Props) => {
  const { context } = props;
  return (
    <div>
      <Switch>
        <Route exact path="/" component={OrganizationLoginPage} />
        <Route exact path="/admin" component={AdminLoginPage} />
        <Route path="/admin/main" component={AuthenticatedContainerLayout} />
        <Route
          path="/organization"
          component={OrganizationAuthenticatedContainerLayout}
        />
        <Route path="/access" component={AccessContainerLayout} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/forgotPassword" component={ForgotPass} />
        <Route path="/resetPassword" component={ResetPass} />
        <Route path="/signatures_callback" component={SignaturesCallbackPage} />
        <Route path="/terms" component={TermsOfUsePage} />
        <Route path="/privacy" component={PrivacyPolicyPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      {/* {context && context.message && context.message !== '' && (
        <Alert
          inputData={{
            showAlways: context.showAlways,
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        />
      )} */}
      <ToastContainer
        autoClose={context.showAlways ? !context.showAlways : 6000}
      />
    </div>
  );
};
//

const mapStateToProps = (state) => ({
  router: state.router,
  context: state.context,
});

export default withRouter(connect(mapStateToProps, null)(App));
