/* eslint-disable no-unused-vars */
import type { Dispatch, GetState, ThunkAction } from '../../types';
import * as utility from './../../utils/utility';
import { SET_ORGANIZATION_SIGN_UP_CONFIRM } from '../OrganizationLogin/action';

const API_URL = '/api';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const signupIQUser = (payload: {}, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const invitationFollowUp = utility.getUrlParameter('continue');
    const res = await axios.post(
      `${API_URL}/iqUsers/signup${
        invitationFollowUp && invitationFollowUp !== ''
          ? `?invite=${invitationFollowUp}`
          : ''
      }`,
      payload,
      {}
    );
    if (res.data.success) {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'success',
        message: 'Access your email to complete the signup process',
        title: 'User verification',
      });
      dispatch({
        type: 'SET_ORGANIZATION_SIGN_UP_CONFIRM',
        orgSignUpConfirm: true,
      });
      dispatch({
        type: 'SET_ORGANIZATION_SIGN_UP_USER',
        orgSignUpUser: res.data.user,
      });
    } else {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: res.data.message,
        title: '',
      });
      return res;
    }
    return res;
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};

export const resendVerificationEmail = (payload: {}): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.post(
      `${API_URL}/iqUsers/resend/verification`,
      payload,
      {}
    );
    if (res.data.success) {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'success',
        message: res.data.message,
        title: 'User verification',
      });
    } else {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: res.data.message,
        title: 'User verification',
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};

export const processInviteIQUser = (payload: {}, cb): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.post(
      `${API_URL}/iqUsers/processInvite`,
      payload,
      {}
    );
    if (res.data.success) {
      cb(res.data.user);
    }
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};
