/* @flow */

import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  showNotification,
  hideNotification,
  notifyTimeout,
} from 'utils/notify';
import type { QuestionnaireExperience, Action } from '../../types';

export const IQ_SET_CURRENT_STEP = 'IQ_SELECT_STEP';
export const IQ_SET_LAST_FOCUSED_FIELD = 'IQ_SET_LAST_FOCUSED_FIELD';
export const IQ_SET_GENERAL_INFORMATION = 'IQ_SET_GENERAL_INFORMATION';
export const IQ_SET_TRIGGER_GENERAL_INFORMATION =
  'IQ_SET_TRIGGER_GENERAL_INFORMATION';
export const IQ_IS_SAVING_GENERAL_INFORMATION =
  'IQ_IS_SAVING_GENERAL_INFORMATION';
export const IQ_IS_REMOVING_QUESTIONS = 'IQ_IS_REMOVING_QUESTIONS';

export const IQ_SET_QUESTIONNAIRE = 'IQ_SET_QUESTIONNAIRE';
export const IQ_SET_CURRENT_VERSION_QUESTIONS =
  'IQ_SET_CURRENT_VERSION_QUESTIONS';
export const IQ_SET_CURRENT_VERSION = 'IQ_SET_CURRENT_VERSION';
export const IQ_SHOW_EXPIRATION_NOTICE = 'IQ_SHOW_EXPIRATION_NOTICE';
export const IQ_SET_VALIDATION_REQUIRED_PARTS =
  'IQ_SET_VALIDATION_REQUIRED_PARTS';
export const IQ_SHOW_SIGNATURE_INSTRUCTIONS = 'IQ_SHOW_SIGNATURE_INSTRUCTIONS';
export const IQ_SET_AUTHENTICATION_STEP = 'IQ_SET_AUTHENTICATION_STEP';
export const IQ_IS_MULTI_ACCESS = 'IQ_IS_MULTI_ACCESS';
export const IQ_SET_FUND = 'IQ_SET_FUND';
export const IQ_SET_INVESTOR = 'IQ_SET_INVESTOR';
export const IQ_SET_INVESTOR_FUND = 'IQ_SET_INVESTOR_FUND';
export const IQ_SET_MULTI_SELECT_FORM_METADATA =
  'IQ_SET_MULTI_SELECT_FORM_METADATA';
export const IQ_IS_INITIALIZATION_COMPLETE = 'IQ_IS_INITIALIZATION_COMPLETE';
export const IQ_IS_NATURAL_PERSON = 'IQ_IS_NATURAL_PERSON';
export const IQ_IS_CURRENT_CLOSED_STATUS = 'IQ_IS_CURRENT_CLOSED_STATUS';
export const IQ_IS_SIGNATURES_LOADING = 'IQ_IS_SIGNATURES_LOADING';
export const IQ_IS_SIGNATURES_ENABLED = 'IQ_IS_SIGNATURES_ENABLED';
export const IQ_SET_SIGNATURES_METHOD = 'IQ_SET_SIGNATURES_METHOD';
export const IQ_SET_SIGNATURES_URL = 'IQ_SET_SIGNATURES_URL';

export const IQ_IS_ADDITIONAL_INFO = 'IQ_IS_ADDITIONAL_INFO';

export const IQ_SAVE_COMPATIBILITY_CLIENT_IQ =
  'IQ_SAVE_COMPATIBILITY_CLIENT_IQ';

export const SET_ROLE_USER = 'SET_ROLE_USER';

type State = QuestionnaireExperience;

const initialState = {
  currentStep: 0,
  lastFocusedField: {},
  generalInformation: {}, // generalInfo
  updateGeneralInformationTrigger: false, // updateGeneralInfoTrigger
  savingGeneralInformation: false, // savingInfo
  // SignaturesContext
  signaturesLoading: false, // experienceLoading
  signaturesEnabled: false,
  signaturesMethod: '', // signMethod
  signaturesUrl: '', // signatureUrl
  // FundAccess
  investor: {},
  fund: {},
  investorFund: {},
  isNaturalPerson: {},
  multiSelectFormMetadata: {},
  initializationComplete: false, // ready
  investorFundClosed: false,
  multiAccess: false, // multiIQAccess
  authenticationStep: 1,
  questionnaire: {}, // clientQuestionnaire
  // QuestionTracker
  currentVersionQuestions: {}, // questions
  currentVersion: 'invalid', // this is new instead of IQ_VERSION, also init in zero a invalid
  currentVersionInitialized: false,
  removingQuestions: false, // removing

  nameLetter: '',
  AdditionalContactInfo: false, // additional data Accel
  userRole: '',

  questionnaireAccessExpired: false, // iqAccessExpired
  showInstructionsModal: false,
  missingQuestionnaireParts: [], // missingParts
  // this part is just to temporarily make clientQuestionnaire so its compatible but ideally we should send all the questions from top level
  clientQuestionnaire: {},
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_ROLE_USER: // TRACKER_NEXT_STEP:
      return _.assign({}, state, { userRole: action.userRole });
    case IQ_SET_CURRENT_STEP: // TRACKER_NEXT_STEP:
      return _.assign({}, state, { currentStep: action.currentStep });
    case IQ_SET_LAST_FOCUSED_FIELD: // SET_LAST_FOCUSED_FIELD:
      return _.assign({}, state, { lastFocusedField: action.lastFocusedField });
    case IQ_SET_GENERAL_INFORMATION: // SET_GENERAL_INFO:
      return _.assign({}, state, { generalInformation: action.generalInfo });
    case IQ_SET_QUESTIONNAIRE: // SET_QUESTIONNAIRE:
      return _.assign({}, state, { questionnaire: action.questionnaire });
    case IQ_SET_MULTI_SELECT_FORM_METADATA: //
      return _.assign({}, state, {
        multiSelectFormMetadata: action.multiSelectFormMetadata,
      });
    case IQ_SET_TRIGGER_GENERAL_INFORMATION: // UPDATE_GENERAL_INFO_TRIGGER:
      return _.assign({}, state, {
        updateGeneralInformationTrigger: action.updateGeneralInformationTrigger,
      });
    case IQ_IS_SAVING_GENERAL_INFORMATION: // SET_SAVING_INFO:
      return _.assign({}, state, {
        savingGeneralInformation: action.savingGeneralInformation,
      });
    case IQ_IS_NATURAL_PERSON: // SET_SAVING_INFO:
      return _.assign({}, state, { isNaturalPerson: action.isNaturalPerson });
    case IQ_SHOW_EXPIRATION_NOTICE: // 'SHOW_IQ_EXPIRED_ALERT':
      return _.assign({}, state, {
        questionnaireAccessExpired: action.questionnaireAccessExpired,
      });
    case IQ_SET_VALIDATION_REQUIRED_PARTS: // 'IQ_MISSING_PARTS':
      return _.assign({}, state, {
        missingQuestionnaireParts: action.missingQuestionnaireParts,
      });
    case IQ_SHOW_SIGNATURE_INSTRUCTIONS: // 'SHOW_SIGNATURE_INSTRUCTIONS':
      return _.assign({}, state, {
        showInstructionsModal: action.showInstructionsModal,
      });
    case IQ_SAVE_COMPATIBILITY_CLIENT_IQ:
      return _.assign({}, state, {
        clientQuestionnaire: action.questionsObject,
      });

    // fund login reducer

    case IQ_SET_AUTHENTICATION_STEP: // ACCESS_AUTHENTICATION_STEP:
      return _.assign({}, state, {
        authenticationStep: action.authenticationStep,
      });
    case IQ_IS_MULTI_ACCESS: // MULTI_IQ_ACCESS:
      return _.assign({}, state, { multiAccess: action.multiAccess });
    case IQ_SET_FUND: // SELECT_FUND:
      return _.assign({}, state, { fund: action.fund });
    case IQ_SET_INVESTOR: // SELECT_INVESTOR:
      return _.assign({}, state, { investor: action.investor });
    case IQ_IS_INITIALIZATION_COMPLETE: // ACCESS_READY:
      return _.assign({}, state, {
        initializationComplete: action.initializationComplete,
      });
    case IQ_IS_CURRENT_CLOSED_STATUS: // INVESTOR_FUND_STATUS:
      return _.assign({}, state, {
        investorFundClosed: action.investorFundClosed,
      });
    case IQ_SET_INVESTOR_FUND: // SELECT_INVESTOR_FUND:
      return _.assign({}, state, { investorFund: action.investorFund });
    case IQ_SET_CURRENT_VERSION_QUESTIONS: // 'this is where version changes are set':
      return _.assign({}, state, {
        currentVersionQuestions: action.currentVersionQuestions,
        currentVersionInitialized: true,
      });
    case IQ_SET_CURRENT_VERSION: // 'this is where version changes are set':
      return _.assign({}, state, {
        currentVersion: action.currentVersion,
      });
    case IQ_IS_REMOVING_QUESTIONS: // removing
      return _.assign({}, state, {
        removingQuestions: action.removingQuestions,
      });

    // signatures reducer
    case IQ_IS_SIGNATURES_LOADING: // SET_SIGNATURES_LOADING:
      return _.assign({}, state, {
        signaturesLoading: action.signaturesLoading,
      });
    case IQ_IS_SIGNATURES_ENABLED: // SET_SIGNATURES_ENABLED:
      return _.assign({}, state, {
        signaturesEnabled: action.signaturesEnabled,
      });
    case IQ_SET_SIGNATURES_METHOD: // SET_SIGNATURES_SIGN_METHOD:
      return _.assign({}, state, { signaturesMethod: action.signaturesMethod });
    case IQ_SET_SIGNATURES_URL: // SET_SIGNATURES_URL:
      return _.assign({}, state, { signaturesUrl: action.signaturesUrl });

     case IQ_IS_ADDITIONAL_INFO: // removing
      return _.assign({}, state, {
        AdditionalContactInfo: action.AdditionalContactInfo,
      });

    default:
      return state;
  }
};
