import React from 'react';
import './styles.scss';
import IndexSubsection from '../IndexSubsection/index';

type Props = { data: Object };

const addSubsections = (hasSubsections, subSections, id) => {
  if (hasSubsections) {
    return <IndexSubsection subSections={subSections} id={id} />;
  }
  return <div />;
};
const IndexSection = ({ data }: Props) => (
  <li key={data.contentId} className={`questionaire-index-section ${data.status}`} tabIndex={-1}>
    <a tabIndex={-1} key={data.contentId} id={`index${data.contentId}`} href="#/" onClick={data.clickHandler}>{data.sectionTitle}</a>
    {addSubsections(data.hasSubsection, data.subSections, data.contentId)}
  </li>
);

IndexSection.defaultProps = {
  data: {
    sectionTitle: 'Default title',
    contentId: '',
    hasSubsection: false,
    subSections: [],
    status: '',
    clickHandler: () => { },
  },
};

export default IndexSection;

