import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import mask from './mask.js';

// Number.parseFloat = parseFloat;

type Props = {
  onChange: Function,
  value: Number | String,
  decimalSeparator: String,
  thousandSeparator: String,
  precision: Number | String,
  inputType: String,
  allowNegative: Boolean,
  allowEmpty: Boolean,
  prefix: String,
  suffix: String,
  selectAllOnFocus: Boolean,
};
class CurrencyInput extends Component {
  constructor(props: Props) {
    super(props);
    this.prepareProps = this.prepareProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.setSelectionRange = this.setSelectionRange.bind(this);
    this.state = this.prepareProps(this.props);

    this.inputSelectionStart = 1;
    this.inputSelectionEnd = 1;
  }

  getMaskedValue = () => this.state.maskedValue;

  prepareProps = (props) => {
    const customProps = { ...props }; // babeljs converts to Object.assign, then polyfills.
    delete customProps.onChange;
    delete customProps.onChangeEvent;
    delete customProps.value;
    delete customProps.decimalSeparator;
    delete customProps.thousandSeparator;
    delete customProps.precision;
    delete customProps.inputType;
    delete customProps.allowNegative;
    delete customProps.allowEmpty;
    delete customProps.prefix;
    delete customProps.suffix;
    delete customProps.selectAllOnFocus;
    delete customProps.autoFocus;

    let initialValue = props.value;
    if (initialValue === null) {
      initialValue = props.allowEmpty ? null : '';
    } else {
      if (typeof initialValue === 'string') {
        // Some people, when confronted with a problem, think "I know, I'll use regular expressions."
        // Now they have two problems.

        // Strip out thousand separators, prefix, and suffix, etc.
        if (props.thousandSeparator === '.') {
          // special handle the . thousand separator
          initialValue = initialValue.replace(/\./g, '');
        }

        if (props.decimalSeparator !== '.') {
          // fix the decimal separator
          initialValue = initialValue.replace(new RegExp(props.decimalSeparator, 'g'), '.');
        }

        // Strip out anything that is not a digit, -, or decimal separator
        initialValue = initialValue.replace(/[^0-9-.]/g, '');

        // now we can parse.
        initialValue = Number.parseFloat(initialValue);
      }
      initialValue = Number(initialValue).toLocaleString(undefined, {
        style: 'decimal',
        minimumFractionDigits: props.precision,
        maximumFractionDigits: props.precision,
      });
    }

    const { maskedValue, value } = mask(
      initialValue,
      props.precision,
      props.decimalSeparator,
      props.thousandSeparator,
      props.allowNegative,
      props.prefix,
      props.suffix,
    );

    return { maskedValue, value, customProps };
  };

  componentWillReceiveProps(nextProps) {
    this.setState(this.prepareProps(nextProps));
  }
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.theInput);
    let selectionStart,
      selectionEnd;

    if (this.props.autoFocus) {
      this.theInput.focus();
      selectionEnd = this.state.maskedValue.length - this.props.suffix.length;
      selectionStart = selectionEnd;
    } else {
      selectionEnd = Math.min(
        node.selectionEnd,
        this.theInput.value.length - this.props.suffix.length,
      );
      selectionStart = Math.min(node.selectionStart, selectionEnd);
    }

    this.setSelectionRange(node, selectionStart, selectionEnd);
  }

  componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this.theInput);
    this.inputSelectionStart = node.selectionStart;
    this.inputSelectionEnd = node.selectionEnd;
  }

  componentDidUpdate(prevProps, prevState) {
    const { decimalSeparator } = this.props;
    const node = ReactDOM.findDOMNode(this.theInput);
    const isNegative = (this.theInput.value.match(/-/g) || []).length % 2 === 1;
    const minPos = this.props.prefix.length + (isNegative ? 1 : 0);
    let selectionEnd = Math.max(
      minPos,
      Math.min(this.inputSelectionEnd, this.theInput.value.length - this.props.suffix.length),
    );
    let selectionStart = Math.max(minPos, Math.min(this.inputSelectionEnd, selectionEnd));

    const regexEscapeRegex = /[-[\]{}()*+?.,\\^$|#\s]/g;
    const separatorsRegex = new RegExp(
      `${decimalSeparator.replace(regexEscapeRegex, '\\$&')}|${this.props.thousandSeparator.replace(
        regexEscapeRegex,
        '\\$&',
      )}`,
      'g',
    );
    const currSeparatorCount = (this.state.maskedValue.match(separatorsRegex) || []).length;
    const prevSeparatorCount = (prevState.maskedValue.match(separatorsRegex) || []).length;
    const adjustment = Math.max(currSeparatorCount - prevSeparatorCount, 0);

    selectionEnd += adjustment;
    selectionStart += adjustment;

    const precision = Number(this.props.precision);

    const baselength =
      this.props.suffix.length +
      this.props.prefix.length +
      (precision > 0 ? decimalSeparator.length : 0) + // if precision is 0 there will be no decimal part
      precision +
      1; // This is to account for the default '0' value that comes before the decimal separator

    if (this.state.maskedValue.length === baselength) {
      // if we are already at base length, position the cursor at the end.
      selectionEnd = this.theInput.value.length - this.props.suffix.length;
      selectionStart = selectionEnd;
    }

    this.setSelectionRange(node, selectionStart, selectionEnd);
    this.inputSelectionStart = selectionStart;
    this.inputSelectionEnd = selectionEnd;
  }

  setSelectionRange = (node, start, end) => {
    if (document.activeElement === node) {
      node.setSelectionRange(start, end);
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { maskedValue, value } = mask(
      event.target.value,
      this.props.precision,
      this.props.decimalSeparator,
      this.props.thousandSeparator,
      this.props.allowNegative,
      this.props.prefix,
      this.props.suffix,
    );

    event.persist(); // fixes issue #23

    this.setState({ maskedValue, value }, () => {
      this.props.onChange(maskedValue, value, event);
      this.props.onChangeEvent(event, maskedValue, value);
    });
  };

  handleFocus = (event) => {
    if (!this.theInput) return;

    // Whenever we receive focus check to see if the position is before the suffix, if not, move it.
    const selectionEnd = this.theInput.value.length - this.props.suffix.length;
    const isNegative = (this.theInput.value.match(/-/g) || []).length % 2 === 1;
    const selectionStart = this.props.prefix.length + (isNegative ? 1 : 0);
    this.props.selectAllOnFocus && event.target.setSelectionRange(selectionStart, selectionEnd);
    this.inputSelectionStart = selectionStart;
    this.inputSelectionEnd = selectionEnd;
  };

  handleBlur = (event) => {
    this.inputSelectionStart = 0;
    this.inputSelectionEnd = 0;
  };

  render() {
    return (
      <input
        ref={(input) => {
          this.theInput = input;
        }}
        type={this.props.inputType}
        value={this.state.maskedValue}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onMouseUp={this.handleFocus}
        {...this.state.customProps}
      />
    );
  }
}

CurrencyInput.defaultProps = {
  onChange: (maskValue, value, event) => { },
  onChangeEvent: (event, maskValue, value) => { },
  autoFocus: false,
  value: '0',
  decimalSeparator: '.',
  thousandSeparator: ',',
  precision: '2',
  inputType: 'text',
  allowNegative: false,
  prefix: '',
  suffix: '',
  selectAllOnFocus: false,
};

export default CurrencyInput;
