
import type {
  Dispatch,
  GetState,
  ThunkAction,
} from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const SET_FUNDS = 'SET_FUNDS';
export const SET_FIRMS = 'SET_FIRMS';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';


const API_URL = '/api';
function getConfig() {
  return {
    headers: { Authorization: `Bearer ${(typeof window !== 'undefined' && localStorage.getItem('auth-token')) ? localStorage.getItem('auth-token') : 'invalid'}` },
  };
}

export const saveFund = (fundPayload, cb): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.post(`${API_URL}/fund/`, fundPayload, getConfig());
      if (res.data.success) {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Save Success' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
        cb();
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const fetchFirms = (): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.get(`${API_URL}/firm/`, getConfig());
      dispatch({ type: SET_FIRMS, firms: res.data.firms });
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const editFund = (updatePayload, cb): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.put(`${API_URL}/fund/`, updatePayload, getConfig());
      if (res.data.success) {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Edit Success' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
        cb();
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const getFundById = (fundId, updateFormCallback: () => void): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.get(`${API_URL}/fund/${fundId}`, getConfig());
      if (res.data.success) {
        updateFormCallback(res.data.fund);
      }
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };
