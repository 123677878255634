/* eslint-disable react/sort-comp, react/no-direct-mutation-state */
/* @flow */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import type { Connector } from 'react-redux';
import Helmet from 'react-helmet';
import Button from '../../components/Button/index';
import { Context as ContextType, Reducer, Dispatch } from '../../types/index';
import TextInput from '../../components/TextInput/index';
import Alert from '../../components/Alert/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';
import { Link } from 'react-router-dom';
import { sendEmailToResetPassword } from './action';
import { getUrlParameter } from '../../utils/utility';

type Props = {
  history: Object,
  context: ContextType,
  sendEmail: () => {},
  notifyFillData: () => {},
};

const initialState = {
  emailAddress: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  step: 1,
};
const API_URL = '/api';
const ForgotPassword = (props: Props) => {
  const [emailField, setEmailField] = useState({
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  });
  const [step, setStep] = useState(1);
  const [validVerification, setValidVerification] = useState(true);
  const dispatch = useDispatch();

  const renderTextInput = (
    id,
    type,
    displayName,
    placeholder,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    disabled
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      noTitle: true,
      errorMessage,
      placeholder,
      disabled,
    };
    return (
      <TextInput inputData={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  const handleEmailAddressChange = (event) => {
    event.preventDefault();
    setEmailField({
      ...emailField,
      value: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (step === 1) {
      if (emailField.value !== '') {
        dispatch({
          type: 'RENDER_NOTIFICATION',
          notificationType: 'success',
          message: 'If an account exists for this user, an email will be sent.',
          title: 'Password Reset Successful',
        });
        const res = await axios.post(`${API_URL}/iqUsers/resetPassword`, {
          email: emailField.value,
        });
        if (res.data.success) {
          const nextStep = step + 1;
          setStep(nextStep);
        }
      } else {
        dispatch({
          type: 'RENDER_NOTIFICATION',
          notificationType: 'error',
          message: 'Please complete all the required fields',
          title: 'Fields Required',
        });
      }
    } else if (step === 2) {
      props.history.push('/');
    }
  };

  const sendEmailAction = (event) => {
    if (event) event.preventDefault();
    const { sendEmail } = props;
    sendEmail({ email: emailField.value });
  };

  const enterEmailContent = () => {
    return (
      <div>
        <p>
          Please enter your email address below. We will send you a link to
          reset your password.
        </p>
        <br />
        <div className="row no-padding no-border">
          <div className="col-lg-3 col-md-3 col-sm-3" />
          <div className="col-lg-6 col-md-6 col-sm-6 no-padding no-border">
            <p className="color-black">Email Address</p>
            {renderTextInput(
              'emailAddress',
              'text',
              'Email',
              'Email Address',
              true,
              'large',
              !emailField.valid,
              emailField.errorMessage,
              handleEmailAddressChange
            )}
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3" />
        </div>
      </div>
    );
  };

  const resetPasswordContent = () => {
    return (
      <div>
        <p>
          An email with a link to reset your password has been sent to{' '}
          {emailField.value}.
        </p>
        <p>
          Didn't receive the password reset email?{' '}
          <a href="#/" onClick={sendEmailAction}>
            Resend.
          </a>
        </p>
        <br />
      </div>
    );
  };

  const { context } = props;
  return (
    <div className="main-container">
      <DynamicHeader type={'user'} homePath={'/'} />
      <Helmet title="Forgot Password" />
      <div className="container">
        {/* <Alert
          inputData={{
            showAlways: context.showAlways,
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="page-top-row">
          <Link to="/" className="back-to color-black">
            <i className="fa fa-angle-left" aria-hidden="true" /> Back
          </Link>
          <h3
            style={{ fontWeight: '300' }}
            className="color-dark-green text-align-center "
          >
            Fund Formation Portal Dashboard
          </h3>
        </div>
        <br />
        <div className="userlogin-section">
          <div className="row">
            <div className="col-lg-7 col-md-9 col-sm-11 col-xs-12 center-block">
              <form onSubmit={handleSubmit}>
                <div className="userlogin-main text-align-center content-bg">
                  <h5
                    style={{ marginTop: '-27px', marginBottom: '-5px' }}
                    className="color-dark-green"
                  >
                    RESET PASSWORD
                  </h5>
                  <hr />
                  {step === 1 && <div>{enterEmailContent()}</div>}
                  {step === 2 && <div>{resetPasswordContent()}</div>}
                  <Button
                    inputData={{
                      id: 'admin-user-submit',
                      displayName: step === 2 ? 'Back to Sign In' : 'Submit',
                      type: 'submit',
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};
const mapStateToProps = (state: Reducer) => ({ context: state.context });

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    sendEmail: (payload) => dispatch(sendEmailToResetPassword(payload)),
    notifyFillData: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Please complete all the required fields',
        title: 'Fields Required',
      }),
  })
);

export default connector(ForgotPassword);
