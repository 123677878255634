/* @flow */

import _ from 'lodash';

import {
  SET_FIRMS,
  SET_FUNDS,
  SET_TAB,
  SET_CAN_LOAD_FUNDS,
  SET_CAN_LOAD_FIRMS,
  SET_DASHBOARD_FUND,
} from './action';
import type { Administrator, Action } from '../../types';

type State = Administrator;

const initialState = {
  user: {},
  firms: [],
  funds: [],
  editFirm: {},
  currentTab: 'funds',
  canLoadFunds: true,
  canLoadFirms: true,
  dashboardSelectedFund: {},
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_FIRMS:
      return _.assign({}, state, { firms: action.firms });
    case SET_FUNDS:
      return _.assign({}, state, { funds: action.funds });
    case SET_TAB:
      return _.assign({}, state, { currentTab: action.selectedTab });
    case SET_CAN_LOAD_FUNDS:
      return _.assign({}, state, { canLoadFunds: action.canLoadFunds });
    case SET_CAN_LOAD_FIRMS:
      return _.assign({}, state, { canLoadFirms: action.canLoadFirms });
    case SET_DASHBOARD_FUND:
      return _.assign({}, state, { dashboardSelectedFund: action.dashboardSelectedFund });
    default:
      return state;
  }
};
