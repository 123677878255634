/* eslint-disable react/sort-comp,class-methods-use-this,no-cond-assign,radix,no-param-reassign */
/* @flow */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as _ from 'lodash';
import './styles.scss';
import * as action from './action';
import * as notify from '../../utils/notify';
import {
  Context as ContextType,
  Administrator as AdministratorType,
  Reducer,
  Dispatch,
} from '../../types/index';

import Banner from '../../components/Banner/index';
import FilterBox from '../../components/FilterBox/index';
import TableContainer from '../../components/TableContainer/index';
import Alert from '../../components/Alert/index';
import { STATUS_TYPES } from '../../utils/constants/status';
import { formatMoney } from '../../utils/format';
import { adjustFormatMoney } from '../../utils/adjust-format-money';
import DateTimeZone from '../../utils/date-time-zone';

/**
 * @typedef {object} Props
 * @property {object} history
 * @property {object} router
 * @property {ContextType} context
 * @property {AdministratorType} admin
 * @property {Function} sendMailShareFund
 * @property {Function} fetchFirm
*/

const FirmFundsDetail = (props) => {
  const [firm, setFirm] = useState({});
  const [funds, setFunds] = useState([]);
  const [emailsText, setEmailsText] = useState('');
  const [messageText, setMessageText] = useState('');
  // const [shareLink_text, setShareLink_text] = useState('');
  const [selectedFundId, setSelectedFundId] = useState('');
  const [selectedFundName, setSelectedFundName] = useState('');

  const getData = () => {
    const { router, fetchFirm } = props;
    const routLocation = router.location.pathname;
    const firmId = routLocation.split('firmDetail/')[1];
    if (!firm._id) {
      fetchFirm(firmId, (firmData) => {
        setFirm(firmData);
        setFunds(firmData.funds);
      });
    }
  };

  useEffect(() => {
    getData();
    if (document.title !== 'Fund Formation Portal - Firm Detail') {
      notify.hideNotification();
    }
    document.body.scrollTop = 0;
  });

  const handleButton = (event) => {
    event.preventDefault();
    const { history } = props;
    history.push(`/admin/main/newFund?firm=${firm._id}`);
  };

  const handleLink = (event) => {
    event.preventDefault();
    const { history } = props;
    history.push('/admin/main/dashboard');
  };

  const handleFirmEditButton = (event) => {
    event.preventDefault();
    const { history } = props;
    history.push(`/admin/main/editFirm/${firm._id}`);
  };

  const handleFundEdit = (event) => {
    const { history } = props;
    event.preventDefault();
    history.push(
      `/admin/main/editFund/${event.target.getAttribute('data-identifier')}`
    );
  };

  const handleSendMail = () => {
    if (emailsText) {
      const { sendMailShareFund } = props;
      const to = _.split(emailsText, ',', 100);
      _.forEach(to, (mail) => {
        const refMail = mail.split(' ')[1];
        sendMailShareFund(
          refMail || mail,
          messageText,
          selectedFundId,
          selectedFundName
        );
      });
    }
  };

  const handleSelectFund = (event) => {
    const id = event.target.id.split('/')[1];
    const name = event.target.getAttribute('data')
      ? event.target.getAttribute('data').split(', ')[0]
      : '';
    setSelectedFundId(id);
    setSelectedFundName(name);
    setEmailsText('');
    setMessageText('');
  };

  const handlerOnChange = (event) => {
    const eName = event.target.name;
    switch (eName) {
      case 'emailsText':
        setEmailsText(event.target.value);
        break;
      case 'messageText':
        setMessageText(event.target.value);
        break;

      default:
        break;
    }
  };

  const renderTableContents = () => {
    const results = [];
    const rowActions = [];
    const headers = [
      {
        title: 'Fund',
        filteringArrow: true,
      },
      {
        title: 'Last Closing',
        filteringArrow: false,
      },
      {
        title: 'Commitments Closed to Date',
        filteringArrow: false,
      },
    ];
    for (let i = 0; i < funds.length; i += 1) {
      const fund = funds[i];
      results.push([
        {
          id: 'fund-name-res-col',
          displayName: fund.fundLegalName,
          type: 'item',
          actionResult: true,
          actionResultIdentifier: fund._id,
          actionResultText: 'Edit',
          actionCallback: handleFundEdit,
          link: handleFundDetail,
        },
        {
          id: 'last-closing-col',
          displayName: fund.lastClosingDate
            ? new DateTimeZone(fund.lastClosingDate).toDateString()
            : 'N/A',
          type: 'description',
        },
        {
          id: fund._id,
          displayName: `${calculateAmountClosedToDate(fund)}`,
          type: 'description',
          icon: 'fa fa-share-square-o color-light-black',
          dataToggle: 'modal',
          dataTarget: `modalShareFund-${fund._id}`,
          modalDisplayName: `${fund.fundLegalName}, ${firm.name}`,
          modalTitle: 'Share this Fund',
          messageValue: messageText,
          pathValue: document.URL.split('/admin')[0],
          popup: true,
          iconPos: 'right',
          actionCallback: handleSendMail,
          emailChangeHandler: handlerOnChange,
          messageChangeHandler: handlerOnChange,
          shareLinkChangeHandler: handlerOnChange,
          clickHandler: handleSelectFund,
          class: 'capital-committed-width',
        },
      ]);
    }
    return { headers, results, rowActions };
  };
  // eslint-disable-next-line class-methods-use-this
  const calculateAmountClosedToDate = (fund) => {
    let closedAmt = 0;
    if (fund) {
      fund.investorFunds.forEach((inv) => {
        const capital =
          inv.generalInfo && inv.generalInfo.capitalCommitment
            ? inv.generalInfo.capitalCommitment
            : 0;
        if (inv.status === STATUS_TYPES.CLOSED.label) {
          //eslint-disable-next-line
          closedAmt += Number(capital);
        }
      });
    }
    return adjustFormatMoney(formatMoney(closedAmt, 2, '.', ','), fund.currency);
  };

  const handleFundDetail = (event) => {
    const { history } = props;
    event.preventDefault();
    history.push(`/admin/main/fundDetail/${event.target.dataset.identifier}`);
  };

  return (
    <div>
      <Helmet title="Firm Detail" />
      <Banner
        bannerType={'info'}
        hasAction
        actionHandler={handleFirmEditButton}
        content={{
          displayName: firm.name,
          description: firm.address,
          contactInfo: [firm.emailPrimaryContact, firm.phonePrimaryContact],
        }}
      />
      {/* <Alert
        inputData={{
          type: context.notificationType,
          title: context.title,
          message: context.message
        }}
      /> */}
      <div className="main-container container-pull">
        <div className="container">
          <FilterBox
            data={{
              boxTitle: 'Back to Dashboard',
              boxTitleIsLink: true,
              buttonId: 'newFund-btn',
              buttonDisplayName: 'Add Fund',
              buttonIcon: 'fa-plus-circle',
              buttonType: 'button',
              page: 'firmFunds',
            }}
            buttonHandler={handleButton}
            linkHandler={handleLink}
            buttonEditHandler={handleFirmEditButton}
          />
          <TableContainer content={renderTableContents()} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

/**
 * @param {Reducer} state
*/
const mapStateToProps = (state) => ({
  admin: state.admin,
  router: state.router,
  context: state.context,
});

/**
 * @type {import('react-redux').Connect<Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
   * @param {Dispatch} dispatch
  */
  (dispatch) => ({
    sendMailShareFund: (to, text, fundId, fundName) =>
      dispatch(action.sendMailShareFund(to, text, fundId, fundName)),
    fetchFirm: (id, callback) => dispatch(action.fetchFirm(id, callback)),
  })
);

export default connector(FirmFundsDetail);
