/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';
import * as action from './action';
import * as validations from './../../utils/form-validation';
import * as notify from './../../utils/notify';
import './styles.scss';
import {
  Context as ContextType,
  FirmForm as FirmFormType,
  Reducer,
  Dispatch,
} from '../../types/index';
import TextInput from '../../components/TextInput/index';
import Button from '../../components/Button/index';
import TextArea from '../../components/TextArea/index';
import FilterBox from '../../components/FilterBox/index';
import axios from 'axios';
import { getConfig } from 'containers/AdminDashboard/action';
import clone from 'clone';
import DateTimeZone from './../../utils/date-time-zone';

type Props = {
  router: Object,
  history: Object,
  context: ContextType,
  firmForm: FirmFormType,
  saveFirm: () => {},
  editFirm: () => {},
  getFirmById: () => {},
  clearFirm: () => {},
  setUpdatingStatus: () => {},
};

const emptyClient = { firstName: '', lastName: '', email: '' };
let invitesErrorMessage = null;

const initialState = {
  name: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Name',
    validators: ['required'],
  },
  streetAddress: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Street Address',
    validators: [''],
  },
  cityTown: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'City/Town',
    validators: [''],
  },
  country: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Country',
    validators: [''],
  },
  state: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'State',
    validators: [''],
  },
  zip: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Zipcode',
    validators: [''],
  },
  pcName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Primary contact',
    validators: [''],
  },
  pcPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Primary contact phone',
    validators: [''],
  },
  pcEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Primary contact email',
    validators: [''],
  },
  scName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Secondary contact',
    validators: [''],
  },
  scPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Secondary contact phone',
    validators: [''],
  },
  scEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Secondary contact email',
    validators: [''],
  },
  clients: {
    data: [emptyClient],
    validators: [''],
  },
  notes: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Notes',
    validators: [],
  },
  formType: '', // create or edit
  firmId: '', // create or edit
};

// Export this for unit testing more easily
export class FirmForm extends PureComponent {
  props: Props;

  static defaultProps: {
    router: {},
    history: {},
    firmForm: {},
    saveFirm: () => {},
    editFirm: () => {},
    context: {},
    getFirmById: () => {},
    clearFirm: () => {},
    setUpdatingStatus: () => {},
  };

  constructor(state) {
    super(state);
    this.state = initialState;
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.verifyFormValidity = this.verifyFormValidity.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.editFirmFieldMapping = this.editFirmFieldMapping.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleClientInputChange = this.handleClientInputChange.bind(this);
  }

  componentDidMount() {
    const { router, getFirmById, clearFirm } = this.props;
    const routLocation = router.location.pathname;

    if (routLocation.indexOf('editFirm') > -1) {
      // this could be obtained from in memory firms but assures model is fresh
      const firmId = routLocation.split('editFirm/')[1];
      getFirmById(firmId, this.editFirmFieldMapping);
    } else {
      clearFirm();
      this.setFormType('create');
    }
    // hide notify Alert
    if (document.title !== 'Fund Formation Portal - New Firm')
      notify.hideNotification();
  }

  setFormType(type) {
    this.setState({
      ...this.state,
      formType: type,
      clients: {
        ...this.state.clients,
        data: [emptyClient],
      },
    });
  }

  editFirmFieldMapping(fetchedFirm) {
    this.setState({
      formType: 'edit',
      firmId: fetchedFirm._id,
      name: {
        ...this.state.name,
        value: fetchedFirm.name,
      },
      streetAddress: {
        ...this.state.streetAddress,
        value: fetchedFirm.address,
      },
      cityTown: {
        ...this.state.cityTown,
        value: fetchedFirm.city,
      },
      country: {
        ...this.state.country,
        value: fetchedFirm.country,
      },
      zip: {
        ...this.state.zip,
        value: fetchedFirm.zip,
      },
      state: {
        ...this.state.state,
        value: fetchedFirm.state,
      },
      pcName: {
        ...this.state.pcName,
        value: fetchedFirm.namePrimaryContact,
      },
      pcPhone: {
        ...this.state.pcPhone,
        value: fetchedFirm.phonePrimaryContact,
      },
      pcEmail: {
        ...this.state.pcEmail,
        value: fetchedFirm.emailPrimaryContact,
      },
      scName: {
        ...this.state.scName,
        value: fetchedFirm.nameSecondaryContact,
      },
      scPhone: {
        ...this.state.scPhone,
        value: fetchedFirm.phoneSecondaryContact,
      },
      scEmail: {
        ...this.state.scEmail,
        value: fetchedFirm.emailSecondaryContact,
      },
      notes: {
        ...this.state.notes,
        value: fetchedFirm.notes,
      },
      clients: {
        ...this.state.notes,
        data:
          [emptyClient],
      },
    });
  }

  handleTextInputChange(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      [event.target.id]: {
        ...this.state[event.target.id],
        value: event.target.value,
      },
    });
  }

  handleClientInputChange(event, idx) {
    event.preventDefault();
    const clients = clone(this.state.clients.data);
    const key = event.target.id.split('].')[1];
    const value = event.target.value;
    const item = { ...clients[idx] };
    item[key] = value;
    clients[idx] = item;
    this.setState({
      clients: {
        ...this.state.clients,
        data: clients,
      },
    });
  }

  handleNotesChange(event) {
    event.preventDefault();
    this.setState({
      notes: {
        ...this.state.notes,
        value: event.target.value,
      },
    });
  }

  inviteMannually = async () => {
    const { firmForm, getFirmById, context } = this.props;
    const { data: _data } = this.state.clients;
    const invitedEmails = _data.map((item) => item.email).join(', ');
    const adminUser = context.profile;

    if (firmForm.editFirm && firmForm.editFirm._id) {
      const payloadInvite = {
        invitedBy: adminUser._id,
        firm: firmForm.editFirm._id,
        invites: _data,
      };
      const { data } = await axios.post(
        '/api/clients/invite',
        payloadInvite,
        getConfig()
      );
      if (data.success) {
        const keys = Object.keys(data.invitesMap);
        const values = Object.values(data.invitesMap);
        keys.map((email, idx) => {
          if (!values[idx].success) {
            Swal.fire(values[idx].message, email, 'error');
          } else {
            Swal.fire(`Invitation sent to:`, invitedEmails, 'success');
          }
        });
        getFirmById(payloadInvite.firm, () => {});
        this.setState({
          clients: {
            ...this.state.clients,
            data: [emptyClient],
          },
        });
      } else {
        Swal.fire(data.message, '', 'error');
      }
    }
  };

  async handleFormSubmit(event) {
    event.preventDefault();
    const { saveFirm, editFirm, firmForm, history, setUpdatingStatus } =
      this.props;
    const payload = {
      name: this.state.name.value,
      city: this.state.cityTown.value,
      country: this.state.country.value,
      state: this.state.state.value,
      address: this.state.streetAddress.value,
      zip: this.state.zip.value,
      namePrimaryContact: this.state.pcName.value,
      phonePrimaryContact: this.state.pcPhone.value,
      emailPrimaryContact: this.state.pcEmail.value,
      nameSecondaryContact: this.state.scName.value,
      phoneSecondaryContact: this.state.scPhone.value,
      emailSecondaryContact: this.state.scEmail.value,
      notes: this.state.notes.value,
      invites: this.state.clients.data,
    };
    if (this.verifyFormValidity() && this.verifyClientInvites()) {
      setUpdatingStatus(true);
      invitesErrorMessage = false;

      const { router, context } = this.props;
      const adminUser = context.profile;
      const { formType } = this.state;
      const { data: _data } = this.state.clients;
      const invitedEmails = _data.map((item) => item.email).join(', ');
      const firmId = router.location.pathname.split('editFirm/')[1];

      if (formType === 'edit') {
        if (firmForm.editFirm && firmForm.editFirm._id) {
          let payloadInvite = {
            invitedBy: adminUser._id,
            firm: firmForm.editFirm._id,
            invites: _data,
          };

          if (payloadInvite.invites && payloadInvite.invites.length > 0 && payloadInvite.invites[0].email !== '') {
            const { data } = await axios.post(
              '/api/clients/invite',
              payloadInvite,
              getConfig()
            )
            if (data.success) {
              const keys = Object.keys(data.invitesMap);
              const values = Object.values(data.invitesMap);
              keys.map((email, idx) => {
                if (!values[idx].success) {
                  Swal.fire(values[idx].message, email, 'error');
                } else {
                  Swal.fire(`Invitation sent to:`, invitedEmails, 'success');
                }
              });
            }
          }

            payload._id = firmForm.editFirm._id;
            editFirm(payload, history, `/admin/main/firmDetail/${firmId}`);
            setUpdatingStatus(false);
            history.goBack();
          }
      } else if (formType === 'create') {
        payload.invitedBy = adminUser._id;
        const response = await saveFirm(payload, history);
        if (response.data.success) {
          setUpdatingStatus(false);
          history.goBack();
        }
      }
    }
  }

  handleBackButton(event) {
    event.preventDefault();
    const { history } = this.props;
    if (this.state.formType === 'create') {
      history.push('/admin/main/dashboard'); // fixme use redux router
    } else {
      history.push(`/admin/main/firmDetail/${this.state.firmId}`); // fixme use redux router
    }
  }

  handleFieldBlur(event) {
    event.preventDefault();
    this.runFieldValidations(event.target.id, event.target.value);
  }

  renderTextInput = (
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    disableOnBlur
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: 'col-content',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={!disableOnBlur ? this.handleFieldBlur : null}
      />
    );
  };

  renderTextArea = (
    id,
    displayName,
    value,
    title,
    hasError,
    errorMessage,
    isRequired,
    changeHandler
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      hasError,
      isRequired,
      errorMessage,
      value,
      title,
    };
    return (
      <TextArea
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleFieldBlur}
      />
    );
  };

  handleClientAction = async (e, deactivate, accessDisabled) => {
    e.preventDefault();
    const { firmForm, getFirmById, context } = this.props;

    const id = e.target.dataset.id;
    const email = e.target.dataset.email;
    const firstName = e.target.dataset.firstname;
    const lastName = e.target.dataset.lastname;
    const adminUser = context.profile;

    const payload = {
      invitedBy: adminUser._id,
      resend: true,
      invites: [
        {
          email,
          firstName,
          lastName 
        }
      ],
      firm: firmForm.editFirm._id,
    };
    if (deactivate) {
      await axios.post(
        `/api/admin/${accessDisabled ? 'enable' : 'disable'}-firm-user`,
        {
          user: id,
        },
        getConfig()
      );
      getFirmById(firmForm.editFirm._id, this.editFirmFieldMapping);
    } else {
      const {data} = await axios.post('/api/clients/invite', payload, getConfig());
       if (data.success) {
        const keys = Object.keys(data.invitesMap);
        const values = Object.values(data.invitesMap);
        keys.map((email, idx) => {
          if (!values[idx].success) {
            Swal.fire(values[idx].message, email, 'error');
          } else {
            Swal.fire('Resend Successful', '', 'success');
          }
        });
      } else {
        Swal.fire(data.message, '', 'error');
      }
    }
  };

  verifyFormValidity = () => {
    for (let i = 0; i < Object.keys(this.state).length; i += 1) {
      const keyName = Object.keys(this.state)[i];
      /** formValid is not a field * */
      if (
        keyName !== 'formValid' &&
        keyName !== 'formType' &&
        keyName !== 'firmId'
      ) {
        const validationPassed = this.runFieldValidations(
          keyName,
          this.state[keyName].value
        );
        if (!validationPassed) {
          return false;
        }
      }
    }
    return true;
  };

  verifyClientInvites = () => {
    const { data } = this.state.clients;
    let valid = true;
    if (
      data[0].firstName === '' &&
      data[0].lastName === '' &&
      data[0].email === ''
    ) {
      return valid;
    } else {
      data.map((item) => {
        if (item.email === '') {
          invitesErrorMessage = 'Please add a valid email address';
          valid = false;
        }
      });
    }
    return valid;
  };

  runFieldValidations = (fieldName, value) => {
    let validationResult = {};
    for (let i = 0; i < this.state[fieldName].validators.length; i += 1) {
      const validator = this.state[fieldName].validators[i];
      switch (validator) {
        case 'required':
          validationResult = validations.required(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} is required`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'email':
          validationResult = validations.email(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false ? 'Wrong email format' : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'number':
          validationResult = validations.number(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} must be a number`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        default:
          return true;
      }
    }
    return true;
  };

  addClient = () => {
    const { data } = this.state.clients;
    const _clients = clone(data);
    _clients.push(emptyClient);
    this.setState({
      clients: {
        ...this.state.clients,
        data: _clients,
      },
    });
  };

  removeClient = (idx) => {
    const { data } = this.state.clients;
    const _clients = clone(data);
    _clients.splice(idx, 1);
    this.setState({
      clients: {
        ...this.state.clients,
        data: _clients,
      },
    });
  };

  render() {
    const { context, firmForm } = this.props;
    const { data: clients } = this.state.clients;
    const formType = this.state.formType;
    const firmName = this.state.name.value;
    return (
      <div className="main-container firms-container">
        <Helmet title="New Firm" />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="top-overlay" style={{ backgroundColor: '#285E83' }} />
        <div className="container">
          <div className="content-bg">
            <FilterBox
              data={{
                boxTitle: 'Back to Dashboard',
                boxTitleIsLink: true,
                infoText: formType === 'create' ? 'NEW FIRM' : firmName,
                buttonId: 'back-btn-filter-dash',
                buttonType: 'button',
                filterType: 'info',
              }}
              linkHandler={this.handleBackButton}
              buttonHandler={this.handleBackButton}
            />
            <form onSubmit={this.handleFormSubmit}>
              <div className="adminform-content content-bg">
                <div className="row">
                  <div className="col-lg-9 col-md-10 col-sm-11 col-xs-12 center-block">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content-title">
                          <h5 className="color-dark-green">Basic Info</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 no-padding">
                        {this.renderTextInput(
                          'name',
                          'text',
                          this.state.name.value,
                          'Firm Name',
                          true,
                          'col-lg-6 col-md-6 col-sm-6 col-xs-12',
                          !this.state.name.valid,
                          this.state.name.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'streetAddress',
                          'text',
                          this.state.streetAddress.value,
                          'Street Address',
                          false,
                          'col-lg-6 col-md-6 col-sm-6 col-xs-12',
                          !this.state.streetAddress.valid,
                          this.state.streetAddress.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 nopadding">
                        {this.renderTextInput(
                          'country',
                          'text',
                          this.state.country.value,
                          'Country',
                          false,
                          'col-lg-8 col-md-6 col-sm-6 col-xs-12',
                          !this.state.country.valid,
                          this.state.country.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'cityTown',
                          'text',
                          this.state.cityTown.value,
                          'City/Town',
                          false,
                          'col-lg-4 col-md-6 col-sm-6 col-xs-12',
                          !this.state.cityTown.valid,
                          this.state.cityTown.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 nopadding">
                        {this.renderTextInput(
                          'state',
                          'text',
                          this.state.state.value,
                          'State',
                          false,
                          'col-lg-6 col-md-6 col-sm-6 col-xs-12',
                          !this.state.state.valid,
                          this.state.state.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'zip',
                          'text',
                          this.state.zip.value,
                          'Zip Code',
                          false,
                          'col-lg-6 col-md-6 col-sm-6 col-xs-12',
                          !this.state.zip.valid,
                          this.state.zip.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content-title">
                          <h5 className="color-dark-green">Primary Contact</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'pcName',
                        'text',
                        this.state.pcName.value,
                        'Name',
                        false,
                        'col-lg-6 col-md-6 col-sm-4 col-xs-12',
                        !this.state.pcName.valid,
                        this.state.pcName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'pcPhone',
                        'text',
                        this.state.pcPhone.value,
                        'Phone',
                        false,
                        'col-lg-3 col-md-3 col-sm-4 col-xs-12',
                        !this.state.pcPhone.valid,
                        this.state.pcPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'pcEmail',
                        'email',
                        this.state.pcEmail.value,
                        'Email Address',
                        false,
                        'col-lg-3 col-md-3 col-sm-4 col-xs-12',
                        !this.state.pcEmail.valid,
                        this.state.pcEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content-title">
                          <h5 className="color-dark-green">
                            Secondary Contact
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row bottom-space">
                      {this.renderTextInput(
                        'scName',
                        'text',
                        this.state.scName.value,
                        'Name',
                        false,
                        'col-lg-6 col-md-6 col-sm-4 col-xs-12',
                        !this.state.scName.valid,
                        this.state.scName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'scPhone',
                        'text',
                        this.state.scPhone.value,
                        'Phone',
                        false,
                        'col-lg-3 col-md-3 col-sm-4 col-xs-12',
                        !this.state.scPhone.valid,
                        this.state.scPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'scEmail',
                        'email',
                        this.state.scEmail.value,
                        'Email Address',
                        false,
                        'col-lg-3 col-md-3 col-sm-4 col-xs-12',
                        !this.state.scEmail.valid,
                        this.state.scEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content-title">
                          <h5 className="color-dark-green">
                            Grant Client Access
                          </h5>
                        </div>
                      </div>
                    </div>
                    {firmForm &&
                      firmForm.editFirm &&
                      firmForm.editFirm.clients &&
                      firmForm.editFirm.clients.map((client) => (
                       client.email || client.firstName ?
                        <div
                          className="row bottom-space"
                          key={`key-${client._id}`}
                        >
                          <div className="col-lg-2">
                            {client.firstName && client.lastName ? <p>
                              {client.firstName} {client.lastName}
                            </p>
                            : <p>N/A</p> 
                            }
                          </div>
                          <div className="col-lg-1">
                            <span>
                              {client.verified || client.accessFirstTime
                                ? 'Active'
                                : 'Pending'}
                            </span>
                          </div>
                          <div className="col-lg-3">
                            <p>{client.email}</p>
                          </div>
                          <div className="col-lg-2">
                            <span>
                              <a
                              style={{ cursor: 'pointer' }}
                                data-email={client.email}
                                data-firstname={client.firstName}
                                data-lastname={client.lastName}
                                data-id={client._id}
                                onClick={(e) =>
                                  this.handleClientAction(
                                    e,
                                    client.verified || client.accessFirstTime,
                                    client.accessDisabled
                                  )
                                }
                                className="color-dark-green"
                              >
                                {client.verified || client.accessFirstTime
                                  ? client.accessDisabled
                                    ? 'Enable'
                                    : 'Disable'
                                  : 'Resend'}
                              </a>
                            </span>
                          </div>
                          <div className="col-lg-2">
                              <span>
                                {client.invitedAt ? new DateTimeZone(client.invitedAt).toLocaleDateString('en-US') : ''}
                              </span>
                            </div>
                          <div className="col-lg-2">
                              {client.invitedBy ?
                              <p>
                                {client.invitedBy.emailAddress}
                              </p> 
                              : 
                              client.invitedClient ? 
                              <p>
                                {client.invitedClient.email}
                              </p> : '' 
                              }
                            </div>
                        </div>
                      : '') 
                      )}
                    {clients && clients.map((client, idx) => (
                      <>
                        <div
                          className="row bottom-space"
                          key={`client-key${idx}`}
                        >
                          {this.renderTextInput(
                            `clients[${idx}].firstName`,
                            'text',
                            clients[idx].firstName,
                            'First Name',
                            false,
                            'col-lg-3 col-md-3 col-sm-3 col-xs-12',
                            false,
                            '',
                            (e) => this.handleClientInputChange(e, idx),
                            true
                          )}
                          {this.renderTextInput(
                            `clients[${idx}].lastName`,
                            'text',
                            clients[idx].lastName,
                            'Last Name',
                            false,
                            'col-lg-3 col-md-3 col-sm-3 col-xs-12',
                            false,
                            '',
                            (e) => this.handleClientInputChange(e, idx),
                            true
                          )}
                          {this.renderTextInput(
                            `clients[${idx}].email`,
                            'email',
                            clients[idx].email,
                            'Email Address',
                            false,
                            'col-lg-6 col-md-6 col-sm-6 col-xs-12',
                            false,
                            '',
                            (e) => this.handleClientInputChange(e, idx),
                            true
                          )}
                        </div>
                        {clients.length > 1 && idx !== 0 && (
                          <a
                            className="color-dark-green custom-remove-link"
                            onClick={() => this.removeClient(idx)}
                          >
                            <i
                              className="fa fa-trash fa-lg"
                              aria-hidden="true"
                            />
                          </a>
                        )}
                      </>
                    ))}
                    <a
                      className="color-dark-green custom-link"
                      onClick={() => this.addClient()}
                    >
                      + Add another invite
                    </a>

                    {formType === 'edit' && (
                      <div className="submit-invite-btn">
                        <Button
                          inputData={{
                            id: 'invite-btn',
                            displayName: 'Invite',
                            type: 'button',
                          }}
                          clickHandler={() => this.inviteMannually()}
                        />
                      </div>
                    )}

                    {invitesErrorMessage && (
                      <div className="error-message text-center">
                        <p>{invitesErrorMessage}</p>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content-title">
                          <h5 className="color-dark-green">Notes</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="col-content-title">
                        <h5 className="color-black"> Add Notes </h5>
                        {this.renderTextArea(
                          'notes',
                          'Add Notes',
                          this.state.notes.value,
                          'Notes',
                          !this.state.notes.valid,
                          this.state.notes.errorMessage,
                          false,
                          this.handleNotesChange
                        )}
                      </div>
                    </div>
                    <div className="row adminform-footer text-align-center">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Button
                          inputData={{
                            id: 'new-fund-back',
                            displayName: 'Back',
                            isBack: true,
                            type: 'button',
                          }}
                          clickHandler={this.handleBackButton}
                        />
                        <Button
                          inputData={{
                            id: 'new-fund-submit',
                            displayName: 'Submit',
                            type: 'submit',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  router: state.router,
  firmForm: state.firmForm,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    saveFirm: (firmPayload, history) =>
      dispatch(action.saveFirm(firmPayload, history)),
    editFirm: (updatePayload, history, returnUrl) =>
      dispatch(action.editFirm(updatePayload, history, returnUrl)),
    setUpdatingStatus: (isLoading) =>
      dispatch({ type: 'UPDATE_LOADER_STATUS', isLoading }),
    getFirmById: (firmId, callback) =>
      dispatch(action.getFirmById(firmId, callback)),
    clearFirm: () => dispatch(action.clearFirm()),
  })
);
export default connector(FirmForm);
