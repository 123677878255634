/* @flow */

import _ from 'lodash';
import { toast } from 'react-toastify';
import * as notify from './../../utils/notify';

import {
  SET_ORGANIZATION_USER,
  SET_ORGANIZATION,
  SET_LOGIN_STATUS,
  SET_PROFILE,
  RENDER_NOTIFICATION,
  SET_ORGANIZATION_SETTINGS_PATH,
  SET_ORGANIZATION_SIGN_UP_CONFIRM,
  SET_ORGANIZATION_SIGN_UP_USER,
} from './action';
import type { OrganizationContext, Action } from '../../types';

type State = OrganizationContext;

const initialState = {
  user: {},
  loginErrorStatus: '',
  profile: {},
  notificationType: '',
  message: '',
  title: '',
  showAlways: false,
  canLogIn: true,
  organization: {},
  orgSettingsPath: 'profile',
  orgSignUpConfirm: false,
  orgSignUpUser: {},
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_ORGANIZATION_SIGN_UP_CONFIRM:
      return _.assign({}, state, { orgSignUpConfirm: action.orgSignUpConfirm });
    case SET_ORGANIZATION_SIGN_UP_USER:
      return _.assign({}, state, { orgSignUpUser: action.orgSignUpUser });
    case SET_ORGANIZATION_SETTINGS_PATH:
      return _.assign({}, state, { orgSettingsPath: action.current });
    case SET_ORGANIZATION_USER:
      return _.assign({}, state, { user: action.user });
    case SET_ORGANIZATION:
      return _.assign({}, state, { organization: action.organization });
    case SET_PROFILE:
      return _.assign({}, state, { profile: action.profile });
    case SET_LOGIN_STATUS:
      return _.assign({}, state, { loginErrorStatus: action.status });

    case 'SET_CAN_LOG_IN':
      return _.assign({}, state, { canLogIn: action.canLogIn });
    default:
      return state;
  }
};
