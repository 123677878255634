/* @flow */

import _ from 'lodash';

import { SET_LP_FORM, RESET_FORMS_LP } from './action';
import type { FundLimitedPartners, Action } from '../../types';
import { USA } from 'utils/location.util';

type State = FundLimitedPartners;

const initialState = {
  form: {
    name: {
      displayName: 'Name',
      value: '',
      valid: true,
      isRequired: true,
      templateItem: true,
      errorMessage: '',
      validators: ['required'],
    },
    entityType: {
      displayName: 'Entity Type',
      value: '',
      valid: true,
      isRequired: true,
      templateItem: true,
      errorMessage: '',
      validators: ['required'],
    },
    otherValue: {
      displayName: 'Other value',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      optional: true, // logic triggered field
      errorMessage: '',
      validators: [''],
    },
    citizenship: {
      displayName: 'Citizenship',
      value: 'not used anymore',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    country: {
      displayName: 'Country of Citizenship',
      value: USA,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    countryPlace: {
      displayName: 'Country Place',
      value: USA,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    principalPlaceOfResidence: {
      displayName: 'Principal Place of Residence (State/Country)',
      value: USA,
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    principalPlaceOfBusiness: {
      displayName: 'Principal Place of Business (State/Country)',
      value: USA,
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    principalPlaceOfResidencePrimaryContact: {
      displayName: 'Principal Place of Residence (State/Country)',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    principalPlaceOfBusinessPrimaryContact: {
      displayName: 'Principal Place of Business (State/Country)',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    namePrincipalContact: {
      displayName: 'Principal Contact Name',
      value: '',
      valid: true,
      isRequired: true,
      templateItem: true,
      errorMessage: '',
      validators: ['required'],
    },
    emailPrincipalContact: {
      displayName: 'Principal Contact Email',
      value: '',
      valid: true,
      isRequired: true,
      templateItem: true,
      errorMessage: '',
      validators: ['required'],
    },
    phonePrincipalContact: {
      displayName: 'Principal Contact Phone',
      value: '',
      valid: true,
      isRequired: true,
      templateItem: true,
      errorMessage: '',
      validators: ['required'],
    },

    reportsPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    reportsPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    reportsPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    reportsSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    reportsSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    reportsSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    partnerStatementsPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    partnerStatementsPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    partnerStatementsPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    partnerStatementsSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    partnerStatementsSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    partnerStatementsSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    taxPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    taxPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    taxPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    taxSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    taxSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    taxSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    capitalPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    capitalPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    capitalPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    capitalSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    capitalSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    capitalSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    stockPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    stockPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    stockPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    stockSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    stockSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    stockSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    legalPrimaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    legalPrimaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    legalPrimaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    legalSecondaryContactName: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    legalSecondaryContactEmail: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    legalSecondaryContactPhone: {
      displayName: '',
      value: '',
      valid: true,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },

    // additionalNamePrincipalContact: {
    //   displayName: 'Additional Principal Contact Name',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // additionalEmailPrincipalContact: {
    //   displayName: 'Additional Principal Contact Email',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // additionalPhonePrincipalContact: {
    //   displayName: 'Additional Principal Contact Phone',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // additionalSecondaryNamePrincipalContact: {
    //   displayName: 'Additional Secondary Principal Contact Name',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // additionalSecondaryEmailPrincipalContact: {
    //   displayName: 'Additional Secondary Principal Contact Email',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // additionalSecondaryPhonePrincipalContact: {
    //   displayName: 'Additional Secondary Principal Contact Phone',
    //   value: '',
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },

    // responsabilitesQuarterly: {
    //   displayName: 'Quarterly and Annual reports',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesTaxInfo: {
    //   displayName: 'Tax Information/k-1S',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesCapitalCall: {
    //   displayName: 'Capital Call Notices',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesCashAndStock: {
    //   displayName: 'Cash and Stock Distributions',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesLegalDocuments: {
    //   displayName: 'Legal Documents',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },

    // responsabilitesSecondaryQuarterly: {
    //   displayName: 'Secondary Quarterly and Annual reports',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesSecondaryTaxInfo: {
    //   displayName: 'Secondary Tax Information/k-1S',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesSecondaryCapitalCall: {
    //   displayName: 'Secondary Capital Call Notices',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesSecondaryCashAndStock: {
    //   displayName: 'Secondary Cash and Stock Distributions',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },
    // responsabilitesSecondaryLegalDocuments: {
    //   displayName: 'Secondary Legal Documents',
    //   isSelected: false,
    //   valid: true,
    //   isRequired: false,
    //   errorMessage: '',
    //   validators: [''],
    // },

    id_investor_representations_1: {
      type: 'investor_representations',
      helpText:
        'For purposes of calculating the net worth of a natural person, the amount of any mortgage or other indebtedness secured by such person’s primary residence must be netted against the value of such residence. If the amount of such indebtedness is less than the estimated fair market value of such residence, it need not be considered as a liability deducted from such natural person’s net worth (except that if the amount of indebtedness outstanding at the time of the sale of securities exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability). If the amount of such indebtedness exceeds the estimated fair market value of such residence, then that excess liability must be deducted from such natural person’s net worth.',
      displayName:
        'A natural person whose individual net worth, or joint net worth with that person’s spouse, at the time of his purchase exceeds $1,000,000 (excluding the value of the primary residence of such natural person).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_2: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'A natural person who had an individual income in excess of $200,000 in each of the two most recent years or joint income with that person’s spouse in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_3: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'A bank as defined in section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity; any broker or dealer registered pursuant to section 15 of the Securities Exchange Act of 1934; any insurance company as defined in section 2(13) of the Securities Act; any investment company registered under the 1940 Act or a business development company as defined in section 2(a)(48) of that Act; any Small Business Investment Company licensed by the U.S. Small Business Administration under section 301(c) or (d) of the Small Business Investment Act of 1958; any plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions, for the benefit of its employees, if such plan has total assets in excess of $5,000,000; any employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974 if the investment decision is made by a plan fiduciary, as defined in section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons that are accredited investors.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_4: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'A private business development company as defined in section 202(a)(22) of the Investment Advisers Act of 1940.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_5: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'An organization described in section 501(c)(3) of the Internal Revenue Code, corporation, Massachusetts or similar business trust, limited liability company or partnership, not formed for the specific purpose of acquiring the securities offered, with total assets in excess of $5,000,000.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_6: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'A trust, with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the securities offered, whose purchase is directed by a sophisticated person as described in Rule 506(b)(2)(ii).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_7: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'An employee benefit plan or retirement plan in which all of the participants are accredited investors.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_8: {
      type: 'investor_representations',
      helpText:
        'The term “revocable grantor trust” means a trust that is a “grantor trust” for U.S. federal income tax purposes, with the grantor or grantors (a) being the sole funding source of the trust, (b) serving as a trustee, or co-trustee, of the trust, (c) having sole investment discretion on behalf of the trust at the time the investment in the Fund is made and (d) having the right to amend or revoke the trust during the life of the grantor.',
      displayName:
        'A revocable grantor trust of which each settlor (i.e., grantor) is a natural person who is an accredited investor.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_9: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'An entity in which all of the equity owners are accredited investors.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_representations_or: {
      type: 'investor_representations',
      helpText: '',
      displayName:
        'Or, the undersigned hereby represents and warrants that: The Undersigned Is NOT An Accredited Investor.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },

    id_purchase_representations_1: {
      type: 'purchase_representations',
      helpText:
        'The term “investments” is generally restricted to the following: (a) stock in publicly held corporations, (b) limited partnership interests in private investment partnerships such as venture capital, private equity or hedge funds, (c) interests in registered investment companies such as mutual funds or exchange-traded funds, (d) cash, commodity interests, and physical commodities held for investment purposes, (e) real estate held for investment purposes (excluding real estate used for personal purposes or as a place of business) and (f) stock in privately-held corporations provided that each such privately-held corporation has shareholder’s equity, determined in accordance with GAAP and as of the date of its most recent financial statements (within the preceding 16 months), in excess of $50 million.',
      displayName:
        'A natural person (including a natural person investing through an individual retirement account or a self-directed employee benefit plan ) who owns at least $5,000,000 in investments (as defined in Rule 2a51-1 under the 1940 Act).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_2: {
      type: 'purchase_representations',
      helpText: '',
      displayName:
        'A natural person or a company that was not formed for the specific purpose of investing in the Fund, acting for its own account or the accounts of other qualified purchasers, and who in the aggregate owns and invests on a discretionary basis, at least $25,000,000 in investments (as defined in Rule 2a51-1 under the 1940 Act).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_3: {
      type: 'purchase_representations',
      helpText:
        'The term “company” means a corporation, a partnership, an association, a joint-stock company, a trust, a fund, or any organized group of persons whether incorporated or not; or any receiver, trustee in bankruptcy or similar official or any liquidating agent for any of the foregoing, in his or her capacity as such.',
      displayName:
        'A family owned company that (i) was not formed for the specific purpose of investing in the Fund, (ii) owns at least $5,000,000 in investments, and (iii) is owned directly or indirectly by or for two or more natural persons who are related as siblings or spouses (including former spouses), or direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such persons.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_4: {
      type: 'purchase_representations',
      helpText: '',
      displayName:
        'A trust that was not formed for the specific purpose of investing in the Fund, and the trustee or other person authorized to make decisions with respect to the trust and each settlor or other person who has contributed assets to the trust, is a qualified purchaser described in clauses (i), (ii), or (iv) of Section 2(a)(51) of the 1940 Act.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_5: {
      type: 'purchase_representations',
      helpText: '',
      displayName:
        'A qualified institutional buyer as defined in Rule 144A under the Securities Act (not formed for the specific purpose of investing in the Fund), acting for its own account, the account of another qualified institutional buyer, or the account of a qualified purchaser, provided that the undersigned is not (1) a dealer described in Rule 144A(a)(1)(ii), that owns and invests on a discretionary basis less than $25,000,000 in securities of issuers that are not affiliated persons of the dealer, or (2) a plan referred to in Rule 144A(a)(1)(i)(D) or (E), or a trust fund referred to in Rule 144A(a)(1)(i)(F) that holds the assets of such a plan, the investment decisions with respect to which are made by the beneficiaries of the plan, unless the investment decision to invest in the Fund is made solely by the fiduciary, trustee, or sponsor of such plan.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_6: {
      type: 'purchase_representations',
      helpText: '',
      displayName:
        'The undersigned is a company all of whose outstanding securities are beneficially owned by qualified purchasers.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_7: {
      type: 'purchase_representations',
      helpText: '',
      displayName: 'An individual retirement account or 401(k) plan directed by and for the benefit of a single natural person that is a qualified purchaser.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_purchase_representations_or: {
      type: 'purchase_representations',
      helpText: '',
      displayName:
        'Or, the undersigned hereby represents and warrants that: The Undersigned Is NOT A Qualified Purchaser.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },

    id_client_representations_1: {
      type: 'client_representations',
      helpText: '',
      displayName:
        'A natural person or company that will make a capital commitment to the Fund of at least $1,000,000.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_client_representations_2: {
      type: 'client_representations',
      helpText:
        'For purposes of calculating the net worth of a natural person, the amount of any mortgage or other indebtedness secured by such person’s primary residence must be netted against the value of such residence. If the amount of such indebtedness is less than the estimated fair market value of such residence, it need not be considered as a liability deducted from such natural person’s net worth (except that if the amount of indebtedness outstanding at the time of calculation exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess must be included as a liability). If the amount of such indebtedness exceeds the estimated fair market value of such residence, then that excess liability must be deducted from such natural person’s net worth.',
      displayName:
        'A natural person or company that has a net worth (together, in the case of a natural person, with assets jointly held with a spouse) of more than $2,100,000 (excluding the value of the primary residence of such natural person).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_client_representations_or: {
      type: 'client_representations',
      helpText: '',
      displayName:
        'Or, the undersigned hereby represents and warrants that: The Undersigned Is NOT A Qualified Client.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },

    id_foreign_person_1: {
      type: 'foreign_person_1',
      helpText: '',
      displayName:
        'A foreign national (i.e., an individual that is a citizen of a foreign (i.e., non-U.S.) country, even if such person is also a U.S. citizen or green card holder, or an individual who does not owe his or her sole allegiance to the United States).',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_2: {
      type: 'foreign_person_1',
      helpText: '',
      displayName:
        'A foreign government (i.e., any non-U.S. government or body exercising governmental functions), including any national and subnational governments and their respective departments, agencies, and instrumentalities.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_3: {
      type: 'foreign_person_1',
      helpText: '',
      displayName:
        'A foreign entity, including any branch, partnership, group or sub-group, association, estate, trust, corporation, or division of a corporation, or organization organized or incorporated under the laws of a foreign (i.e., non-U.S.) jurisdiction, if either (a) its principal place of business is outside the United States or (b) its equity securities are primarily traded on one or more foreign exchanges, unless a majority of the equity interest in such entity is ultimately owned by U.S. nationals. For purposes of this question, “principal place of business” means the primary location where an entity’s management directs, controls, or coordinates the entity’s activities, or, in the case of an investment fund, where the fund’s activities and investments are primarily directed, controlled, or coordinated by or on behalf of the general partner, managing member, or equivalent.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_4: {
      type: 'foreign_person_1',
      helpText: '',
      displayName:
        'An entity over which a foreign person exercises or could exercise control (including a trust, corporation, partnership, limited liability company, or equivalent legal entity organized or incorporated under the laws of any state of the United States over which control is exercised or may be exercisable, in any form, by a foreign national, foreign government or foreign entity) (e.g., has a foreign manager or general partner; a foreign person otherwise participates in important decision-making processes; etc.)). For purposes of this question, “control” means the power, direct or indirect, whether exercised or not exercised, to determine, direct, or decide important matters affecting an entity, and includes negative control (i.e., the ability to prevent an entity from making a particular decision)',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_or: {
      type: 'foreign_person_1',
      helpText: '',
      displayName:
        'Not a foreign person under any of the previous definitions included in this Section F or otherwise as defined under Section 721 of the Defense Production Act of 1950, as amended, including all implementing regulations thereof.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },

    id_foreign_person_2_1: {
      type: 'foreign_person_2_1',
      helpText: '',
      displayName:
        'The undersigned is not an investment fund and no national or subnational governments of a single foreign (i.e., non-U.S.) state holds a voting interest, direct or indirect, of 49 percent or more in the undersigned.',
      isSelected: [false, false],
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_2_2: {
      type: 'foreign_person_2',
      helpText: '',
      displayName:
        'The undersigned is an investment fund and no national or subnational governments of a single foreign (i.e., non-U.S.) state holds 49 percent or more of the interest in the general partner, managing member, or equivalent of the undersigned.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_foreign_person_2_or: {
      type: 'foreign_person_2',
      helpText: '',
      displayName:
        'The undersigned is an entity and is unable to make either of the representations above.',
      isSelected: false,
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },

    cityTown: {
      displayName: 'City/Town',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    state: {
      displayName: 'State',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    zip: {
      displayName: 'Zip Code',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    streetAddress: {
      displayName: 'Street Address',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    addressPrincipalContact: {
      displayName: 'Principal Contact Address',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    bankName: {
      displayName: 'Bank Name',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    acctName: {
      displayName: 'Accountholder Name',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    acctNumber: {
      displayName: 'Account Number',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    swiftCode: {
      displayName: 'Swift Code',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    bankaba: {
      displayName: 'Bank ABA#',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    furtherCredit: {
      displayName: 'For further credit to',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    furtherCreditNumber: {
      displayName: 'For further credit Account Number/IBAN',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    location: {
      displayName: 'Location',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: false,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_1: {
      displayName: 'Natural Person',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_2: {
      displayName: 'US Person',
      isSelected: [false, false],
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_3: {
      displayName: 'FINRA Affiliation',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_4: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_a: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_b: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_c: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_d: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_e: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_5_f: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      optional: true,
      errorMessage: '',
      validators: [''],
    },
    id_investor_status_6: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_wiring_bank_status_1: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_wiring_bank_status_1_opt: {
      displayName: '',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      optional: true, // logic triggered field
      errorMessage: '',
      validators: [''],
    },
    id_wiring_bank_status_2: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_1_a: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_1_b: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_2_a: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_2_b: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_2_c: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_2_d: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_1_b_opt: {
      displayName: '',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      optional: true, // logic triggered field
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_2_e_opt: {
      displayName: '',
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      optional: true, // logic triggered field
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_3_a: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_4: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_5: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_6: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_7: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_8: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_9_a: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    },
    id_special_entities_9_b: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
      optional: true, // logic triggered field
    },
    id_special_entities_9_c: {
      displayName: '',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
      optional: true, // logic triggered field
    },
    id_warranties_exceptions: {
      displayName: '',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [''],
    },
    id_additional_contact_info: {
      displayName: 'Additional ContactInfo',
      isSelected: [false, false],
      value: '',
      valid: true,
      isRequired: false,
      templateItem: true,
      errorMessage: '',
      validators: [''],
    }
  },
};

// eslint-disable-next-line consistent-return
export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_LP_FORM:
      return _.assign({}, state, {
        form: {
          ...state.form,
          [action.fieldName]: {
            ...state.form[action.fieldName],
            [action.fieldProperty]: action.fieldPropertyValue,
          },
        },
      });
    case RESET_FORMS_LP:
      // eslint-disable-next-line no-param-reassign
      state = initialState;
      return state;
    default:
      return state;
  }
};
