/* eslint-disable react/sort-comp,
import/prefer-default-export,react/prefer-stateless-function,react/prop-types,
 max-len */
/* @flow */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import './styles.scss';
import { Dispatch, Reducer } from '../../types';
import * as action from './action';
import type { Connector } from 'react-redux';
import { connect } from 'react-redux';

type Props = {
  displayName: Object,
  logoutHandler: () => void,
  profileHandler: () => void,
  updateAccessFirstTime: () => void,
  history: Object,
};
class OrgUserProfile extends Component {
  props: Props;

  state = {
    opened: false,
  };

  static defaultProps = {
    displayName: 'Not provided',
    logoutHandler: () => { },
    updateAccessFirstTime: () => { },
    history: {},
  };

  componentDidMount() {
    const { user } = this.props.organizationContext;
    const { updateAccessFirstTime } = this.props;
    if (!user.accessFirstTime) {
      updateAccessFirstTime(user.email);
    }
  }

  componentWillUnmount() { }

  handleClickOutside = () => {
    if (this.state.opened) {
      this.setState({ opened: false });
    }
  };

  handleProfileClose = (e) => {
    e.preventDefault();

    if (this.node.contains(e.target)) {
      return;
    } else if (this.state.opened) {
      this.setState({ opened: false });
    }
  };

  handleProfileOpen = (e) => {
    e.preventDefault();
    this.setState({ opened: !this.state.opened });
  };

  render() {
    const { logoutHandler, profileHandler } = this.props;
    const currentPath =
      typeof window !== 'undefined' && window.location
        ? window.location.pathname.split('/organization/')[1]
        : '/';

    const openClientEmail = () => {
      const { user, organization } = this.props.organizationContext;
      const subject = `Fund Formation Portal - Support Message - ${user.email} (Multi-IQ User) [${organization.name}]`;
      const message = 'Please describe the issue you are currently facing and what you were trying to do on the Fund Formation Portal before you encountered the issue.';
      window.location.href = `mailto:fundportaladmin@gunder.com?subject=${subject}&body=${message}`;
    };

    return (
      <div
        ref={(node) => this.node = node}
        className={`custom-dropdown-menu dropdown-header-line-space ${this.state.opened ? 'custom-dropdown-menu-opened' : 'custom-dropdown-menu-closed'}`}
        onClick={this.handleProfileOpen}
      >
        <a href="#/" className={`hovered-option custom-dropdown-menu-main menu-item ${currentPath === 'settings' && 'bold-text'}`}>
          {this.props.displayName}
        </a>
        <div className={`${this.state.opened ? 'display-dropdown' : 'hidden-dropdown'} custom-dropdown-menu-dropdown`}>
          {this.props.type === 'admin' ? (
            <ul>
              <li>
                <a className="hovered-option" onClick={profileHandler} href="#/">
                  Profile
                </a>
              </li>
              <span>_______________</span> <br />
              <br />
              <li>
                <a className="hovered-option" onClick={logoutHandler} href="#/">
                  Logout
                </a>
              </li>
            </ul>
          ) : (
              <ul>
                {currentPath !== 'add' ? (
                  <div>
                    <li>
                      <NavLink className="hovered-option" to="settings">
                        Manage Account
                    </NavLink>
                    </li>
                  </div>
                ) : null}
                <li>
                  <a href="#!" onClick={openClientEmail} className="hovered-option">Support</a>
                </li>
                <span>_______________</span> <br />
                <br />
                <li>
                  <a className="hovered-option" onClick={logoutHandler} href="#/">
                    Logout
                </a>
                </li>
              </ul>
            )}
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  organizationContext: state.organizationContext,
});

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    updateAccessFirstTime: email => dispatch(action.updateAccessFirstTime(email)),
  }),
);
export default connector(onClickOutside(OrgUserProfile));
