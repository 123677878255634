import type { Dispatch, GetState, ThunkAction } from '../../types';
const API_URL = '/api';
export const sendEmailToResetPassword = (
  payload: Object
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.post(`${API_URL}/iqUsers/resetPassword`, payload);
    if (res.data.success) {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'success',
        message: res.data.message,
        title: 'Password Reset Successful',
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};
