/* eslint-disable*/
export const verifyFormValidity = () => {
  for (let i = 0; i < Object.keys(this.state).length; i += 1) {
    const keyName = Object.keys(this.state)[i];
    if (keyName !== 'formValid') {
      const validationPassed = this.runFieldValidations(keyName, this.state[keyName].value);
      if (!validationPassed) {
        return false;
      }
    }
  }
  return true;
};

export const runFieldValidations = (field, fieldName, value, updateCallback) => {
  let validationResult = {};
  let errMessage = '';
  for (let i = 0; i < field.validators.length; i += 1) {
    const validator = field.validators[i];
    switch (validator) {
      case 'required':
        validationResult = required(value);
        const formatFieldName =
          fieldName === 'emailAddress'
            ? 'Email Address'
            : fieldName.replace(/^\w/, c => c.toUpperCase());
        errMessage = validationResult === false ? `${formatFieldName} is required` : '';
        updateCallback(validationResult, fieldName, value, errMessage);
        if (!validationResult) {
          return false;
        }
        break;
      case 'email':
        validationResult = email(value);
        errMessage = validationResult === false ? 'Wrong email format' : '';
        updateCallback(validationResult, fieldName, value, errMessage);
        if (!validationResult) {
          return false;
        }
        break;
      case 'number':
        validationResult = number(value);
        errMessage = validationResult === false ? `${fieldName} must be a number` : '';
        updateCallback(validationResult, fieldName, value, errMessage);
        if (!validationResult) {
          return false;
        }
        break;
      case 'phone':
        validationResult = phone(value);
        errMessage =
          validationResult === false ? `Invalid phone format expecting: ### ### ####` : '';
        updateCallback(validationResult, fieldName, value, errMessage);
        if (!validationResult) {
          return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};
export const required = text => {
  if (text && text !== '') {
    return true;
  }
  return false;
};

export const email = value => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const number = value => {
  const re = /^[0-9]+$/;
  return re.test(value);
};
export const phone = value => {
  const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneNumberRegex.test(value);
};
