/* @flow */

import _ from 'lodash';

import {
  SET_EDIT_FIRM,
} from './action';
import type { FirmForm, Action } from '../../types';

type State = FirmForm;

const initialState = {
  editFirm: {
    name: '',
    // clientNumber: 0,
    // matterNumber: 0,
    city: '',
    state: '',
    address: '',
    zip: '',
    namePrimaryContact: '',
    phonePrimaryContact: '',
    emailPrimaryContact: '',
    nameSecondaryContact: '',
    phoneSecondaryContact: '',
    emailSecondaryContact: '',
    notes: '',
  },
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_EDIT_FIRM:
// eslint-disable-next-line
      return _.assign({}, state, { editFirm: action.firm });
    default:
      return state;
  }
};
