/* eslint-disable react/sort-comp,react/no-unused-prop-types */
/* @flow */
import React from 'react';
import axios from 'axios';
import { PureComponent } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Modal, ModalBody } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { AccelEtpnReturningV1, AccelEtpnReturningV2 } from 'utils/constants/questionnaire/versions';
import DateTimeZone from '../../../utils/date-time-zone';
import {
  API_URL,
  SERVICE_CONFIG_QUESTIONNAIRE,
} from '../../../utils/service-commons';
import {
  IQ_IS_SIGNATURES_ENABLED,
  IQ_IS_SIGNATURES_LOADING,
  IQ_SET_SIGNATURES_METHOD,
  IQ_SET_SIGNATURES_URL,
} from '../reducer';
import Button from './../../../components/Button/index';
import TextInput from './../../../components/TextInput/index';
import Title from './../../../components/Title/index';
import Tooltip from './../../../components/Tooltip/index';
import * as validations from './../../../utils/form-validation';
import './styles.scss';

/**
 * @typedef {Object} Props
 * @property {Gunderson.OrganizationContext} organizationContext
 * @property {Gunderson.QuestionnaireExperience} questionnaireContext
 * @property {function} requestEmailSignature
 * @property {function} setSignaturesMethod
 * @property {function} isSignaturesLoading
 * @property {function} setSignaturesUrl
 * @property {boolean} showInstructionsModal
 */

// fixme remove unused state variables signmethod etc
const initialState = {
  signatureUrl: '',
  signatureEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  signMethod: '', // email, app
  // new html
  signatoryName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  signatoryTitle: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  signatoryEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required', 'email'],
  },
  signatureEnabled: true,
  experienceLoading: false,
  missingParts: [],
};
const DATE_FORMAT = 'LL';

// const submitQuestionnaire = (props) => {
//   const {
//     questionnaireContext,
//     questionnaireContext: { investorFund },
//   } = props;
//   const payload = { status: false };
//   if (!questionnaireContext.multiAccess) {
//     payload.investorFund = investorFund;
//   } else {
//     payload.limitedPartnerFund = investorFund;
//   }
//   payload.submitTimestamp = newnew DateTimeZone();
//   return axios.post(
//     `${API_URL()}/investorFund/submit`,
//     payload,
//     SERVICE_CONFIG_QUESTIONNAIRE()
//   );
// };

export class Signatures extends PureComponent {
  /**
   * @type {Props}
  */
  props
  state = initialState;

  static defaultProps = {
    organizationContext: {},
    showInstructionsModal: false,
  };

  componentWillUnmount() { }

  loadSignatureViaDocusign = async (
    signatoryInfo,
    questionList,
    IQ_VERSION
  ) => {
    const { questionnaireContext } = this.props;
    questionList = questionnaireContext.currentVersionQuestions;

    const payload = {
      fund: questionnaireContext.fund,
      signatoryInfo,
      // questionList:questionList.map(q=>({

      // })),
      IQ_VERSION,
    };
    if (!questionnaireContext.multiAccess) {
      payload.investorFund = {
        _id: questionnaireContext.investorFund._id.toString(),
      };
      payload.investor = questionnaireContext.investor;
    } else {
      // only needs _id
      payload.limitedPartnerFund = {
        _id: questionnaireContext.investorFund._id.toString(),
      };
      payload.limitedPartner = questionnaireContext.investor;
    }
    payload.submitTimestamp = new DateTimeZone();

    try {
      const res = await axios.post(
        `${API_URL()}/docSignatures`,
        payload,
        SERVICE_CONFIG_QUESTIONNAIRE()
      );
      this.props.isSignaturesLoading(false);
      this.props.setSignaturesMethod('app');
      this.props.setSignaturesUrl(res.data.embedUrl);
    } catch (err) {
      throw err;
    }
  };

  loadSignatureViaEmail = async (signatoryInfo, questionList, IQ_VERSION) => {
    const {
      questionnaireContext,
      questionnaireContext: {
        currentVersionQuestions,
        investor,
        fund,
        investorFund,
      },
    } = this.props;

    questionList = currentVersionQuestions;
    const payload = { fund, signatoryInfo, IQ_VERSION };
    const multiIQUser = questionnaireContext.multiAccess;
    if (!multiIQUser) {
      payload.investorFund = {
        _id: questionnaireContext.investorFund._id.toString(),
      };
      payload.investor = investor;
    } else {
      payload.limitedPartnerFund = {
        _id: questionnaireContext.investorFund._id.toString(),
      };
      payload.limitedPartner = investor;
    }
    payload.submitTimestamp = new DateTimeZone();

    await axios.post(
      `${API_URL()}/emailDocSignatures`,
      payload,
      SERVICE_CONFIG_QUESTIONNAIRE()
    );
    this.props.renderNotification(
      'success',
      'Signature request',
      'Document to sign sent'
    );
    this.props.isSignaturesLoading(false);
    this.props.setSignaturesMethod('email');
  };

  handleSignatoryChange = (event) => {
    event.preventDefault();
    const eName = event.target.name;
    this.setState({
      ...this.state,
      [eName]: {
        ...this.state[eName],
        value: event.target.value,
      },
    });
  };

  handleSignatures = (e) => {
    const { questionnaireContext, organizationContext } = this.props;
    const currentIQVersion = questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.iqVersion;
    const accelReturningInvestor = currentIQVersion.includes(AccelEtpnReturningV1) || currentIQVersion.includes(AccelEtpnReturningV2);
    const signatoryEmail = this.state.signatoryEmail.value.toLowerCase();
    e.preventDefault();

    this.setState(
      {
        ...this.state,
        signatoryTitle: {
          ...this.state.signatoryTitle,
          validators: questionnaireContext.isNaturalPerson || accelReturningInvestor ? [] : ['required'],
        },
      },
      () => {
        const signatoryInfo = {
          name: this.state.signatoryName.value,
          email: this.state.signatoryEmail.value,
          title: this.state.signatoryTitle.value,
        };
        if (this.verifyFormValidity() && questionnaireContext.userRole !== 'admin') {
          if (
            (organizationContext.user && organizationContext.user.email &&
              organizationContext.user.email.toLowerCase() === signatoryEmail) ||
            (questionnaireContext.investor && questionnaireContext.investor.username &&
              questionnaireContext.investor.username.toLowerCase() === signatoryEmail)
          ) {
            this.props.isSignaturesLoading(true);
            this.loadSignatureViaDocusign(
              signatoryInfo,
              questionnaireContext.currentVersionQuestions,
              questionnaireContext.currentVersion
            );
          } else {
            this.props.isSignaturesLoading(true);
            this.loadSignatureViaEmail(
              signatoryInfo,
              questionnaireContext.currentVersionQuestions,
              questionnaireContext.currentVersion
            );
          }
        } else if (this.verifyFormValidity()) {
          this.props.isSignaturesLoading(true);
          this.loadSignatureViaEmail(
            signatoryInfo,
            questionnaireContext.currentVersionQuestions,
            questionnaireContext.currentVersion
          );
        } else {
          this.props.renderNotification(
            'error',
            'Signature request',
            'Please fill out all the fields'
          );
        }
      }
    );

    //   return new Promise((resolve, reject) => {
    //   const requiresSubmission = questionnaireContext.investorFund.status !== STATUS_TYPES.SUBMITTED.label;
    //   if (requiresSubmission) {
    //     submitQuestionnaire(this.props)
    //       .then((res) => {
    //         let resultAfterSubmit = {};
    //         if (questionnaireContext.multiAccess) {
    //           resultAfterSubmit = res.data.limitedPartnerFund;
    //         } else {
    //           resultAfterSubmit = res.data.investorFund;
    //         }
    //         this.props.setInvestorFund(resultAfterSubmit);
    //         resolve();
    //       })
    //       .catch((err) => {
    //         console.error(
    //           `Error submitted questionnaire for ${
    //             questionnaireContext.multiAccess
    //               ? `'Limited Partner Fund' ${questionnaireContext.investorFund._id}`
    //               : `'Investor Fund' ${questionnaireContext.investorFund._id}`
    //           }`
    //         );
    //       });
    //   } else {
    //     resolve();
    //   }
    // });

  };

  handleSignatoryEmailChange = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      signatureEmail: {
        ...this.state.signatureEmail,
        value: e.target.value,
      },
    });
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    placeholder,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    disabled,
    helpText
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      placeholder,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };

  verifyFormValidity = () => {
    for (let i = 0; i < Object.keys(this.state).length; i += 1) {
      const keyName = Object.keys(this.state)[i];
      /** formValid is not a field * */
      if (
        keyName !== 'signatureUrl' &&
        keyName !== 'signatureEmail' &&
        keyName !== 'signMethod' &&
        keyName !== 'signatureEnabled' &&
        keyName !== 'missingParts' &&
        keyName !== 'showInstructionsModal' &&
        keyName !== 'experienceLoading'
      ) {
        const validationPassed = this.runFieldValidations(
          keyName,
          this.state[keyName].value
        );
        if (!validationPassed) {
          return false;
        }
      }
    }
    return true;
  };

  runFieldValidations = (fieldName, value) => {
    let validationResult = {};
    for (let i = 0; i < this.state[fieldName].validators.length; i += 1) {
      const validator = this.state[fieldName].validators[i];
      switch (validator) {
        case 'required':
          validationResult = validations.required(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false ? `${fieldName} is required` : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'email':
          validationResult = validations.email(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false ? 'Wrong email format' : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'number':
          validationResult = validations.number(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${fieldName} must be a number`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  };

  getTaxFormsEmail = () => {
    const { questionnaireContext } = this.props;
    return questionnaireContext.investorFund.fund.taxFormsEmail
      ? questionnaireContext.investorFund.fund.taxFormsEmail
      : '';
  };
  closeInstructionsModal = () => {
    this.props.showSignaturesInstructions(false);
  };
  openInstructionsModal = (e) => {
    e.preventDefault();
    this.props.showSignaturesInstructions(true);
  };
  render() {
    const { questionnaireContext } = this.props;
    const isNaturalPerson = questionnaireContext.isNaturalPerson;
    const currentIQVersion = questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.iqVersion;
    /**
     * @type {boolean}
     * @description isIolar is true if the current version of the questionnaire is IOLARV1.
     * This variable was used to render the correct instructions for the Iolar questionnaire, when the tax form text was placed in the instructions
    */
    const isIolar = questionnaireContext && questionnaireContext.currentVersion === 'IOLVARV1';
    /**
     * @type {boolean}
    */
    const accelReturningInvestor = currentIQVersion.includes(AccelEtpnReturningV1) || currentIQVersion.includes(AccelEtpnReturningV2);

    return (
      <div className="main-container">
        {questionnaireContext.signaturesMethod === 'app' ? (
          <iframe
            id="iframe-signatures-context"
            title="DocuSign signatures"
            src={questionnaireContext.signaturesUrl}
            width="100%"
            height="700px"
            type="text/html"
          >
            Alternative Content
          </iframe>
        ) : null}
        <Helmet title="Flow Home Signatures" />
        <div>
          <div className="content-bg main-content-area clearfix center-main-content">
            {questionnaireContext.signaturesMethod === 'email' ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h4 className="color-dark-green submitted-message">
                    Thank you for using Gunderson Dettmer’s Fund Formation Portal.
                    Your document has been sent via DocuSign for signature to the email address of
                    the signatory that you provided. Please have the signatory check their email to complete the
                    DocuSign process.
                    <br />
                    <br />
                    The signatory will receive a copy of their completed documents via email.
                    Our team will review the documents once they are completed. You
                    may now close your browser tab if you need nothing further.&nbsp;
                    <br />
                    <br />
                    <u>
                      Please have the signatory check their email to complete
                      the DocuSign process.
                    </u>
                    <br />
                    <br />
                    How do I make a change to my subscription documents?
                    <br />
                    <br />
                    <ol className="dots-ol">
                      <li>
                        <strong>For Guest Checkout Users,</strong> return to the original link you received
                        via email. You will be able to access your questionnaire using
                        the same email address you originally used.
                      </li>
                      <li>
                        <strong>For Account Users,</strong> log in to your account
                        at <a href="https://www.iq.gunder.com"> www.iq.gunder.com</a> to select your submission and make changes.
                      </li>
                    </ol>
                    <br />
                    <br />
                    How do I fill out a questionnaire for this fund for an additional investor entity?
                    <br />
                    <br />
                    <ol className="dots-ol">
                      <li>
                        <strong>For Guest Checkout Users,</strong>  return to the original link you received via email, but use
                        an <u>alternate email address</u> to access the questionnaire. You must use different email
                        addresses for any additional subscriptions to this fund, unless you’d like to create an
                        account. To create an account, go to
                        <a href='https://www.iq.gunder.com/signup'> www.iq.gunder.com/signup</a>.
                      </li>
                      <li>
                        <strong> For Account Users investing with an existing investor profile,</strong> simply return
                        to the original link you received via email, log back into your account, and choose
                        the desired investor profile to complete the process.
                      </li>
                      <li>
                        <strong>For Account Users investing with a new investor entity,</strong> log into your account at
                        <a href="https://www.iq.gunder.com"> www.iq.gunder.com</a> and create your new investor profile(s).
                        Once you create the investor profile(s), return to the original fund link you
                        received via email, log in again, and select the desired
                        investor profile to complete the process.
                      </li>
                    </ol>

                    <br />
                    <br />
                    <br />
                    <p>
                      For technical support, contact{' '}
                      <a href="mailto:fundportaladmin@gunder.com">
                        fundportaladmin@gunder.com
                      </a>
                      .
                    </p>
                  </h4>
                </div>
              </div>
            ) : null}
            {questionnaireContext.signaturesMethod !== 'app' &&
              questionnaireContext.signaturesMethod !== 'email' ? (
              <div className="newHtml">
                <BlockUi
                  tag="div"
                  blocking={questionnaireContext.signaturesLoading}
                >
                  <br />
                  <br />
                  <Title text={'SIGNATURES'} />
                  {questionnaireContext.missingQuestionnaireParts.length ? (
                    <p className="signatureDisabled">
                      {' '}
                      Signatures disabled until all the questions are answered
                      from{' '}
                      {questionnaireContext.missingQuestionnaireParts.length >=
                        2
                        ? `${questionnaireContext.missingQuestionnaireParts[0]} & ${questionnaireContext.missingQuestionnaireParts[1]}`
                        : questionnaireContext
                          .missingQuestionnaireParts[0]}{' '}
                    </p>
                  ) : null}
                  <hr />
                  <a
                    href="#/"
                    onClick={this.openInstructionsModal}
                    className="instructionsclass-green-color"
                  >
                    Instructions
                  </a>
                  <br />
                  <br />
                  <p>
                    Please fill out the following information about the
                    signatory of this form before signing.
                  </p>
                  {accelReturningInvestor && this.getTaxFormsEmail() !== '' ?
                    <p>
                      For any questions, please reach out to the Gunderson legal team at{' '}
                      <a href={`mailto:${this.getTaxFormsEmail()}`}>
                        <u>{this.getTaxFormsEmail()}</u>.
                      </a>
                    </p>
                    : null
                  }
                  <form onSubmit={this.handleSignatures}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" />
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        {this.renderTextInput(
                          'signatoryName',
                          'signatoryName',
                          'text',
                          this.state.signatoryName.value,
                          '',
                          'Name of Signatory',
                          true,
                          'large',
                          !this.state.signatoryName.valid,
                          this.state.signatoryName.errorMessage,
                          this.handleSignatoryChange,
                          !questionnaireContext.signaturesEnabled
                        )}
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" />
                    </div>
                    {!isNaturalPerson || accelReturningInvestor ? (
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" />
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          {this.renderTextInput(
                            'signatoryTitle',
                            'signatoryTitle',
                            'text',
                            this.state.signatoryTitle.value,
                            '',
                            'Title',
                            true,
                            'large',
                            !this.state.signatoryTitle.valid,
                            this.state.signatoryTitle.errorMessage,
                            this.handleSignatoryChange,
                            !questionnaireContext.signaturesEnabled
                          )}
                        </div>
                        <div className="title-tooltip col-lg-1 col-md-1 col-sm-1 col-xs-1">
                          <Tooltip
                            data={{
                              message: !accelReturningInvestor ?
                                'Please enter the capacity in which the signatory will be signing on behalf of the investing entity (e.g., Member, Manager, Trustee, Chief Executive Officer, President, etc.)'
                                :
                                'Please provide your title if the investor is an entity and you are signing as an officer, director, trustee or other authorized signatory of the entity investor. If you are investing as a natural person, you may leave the “Title” field blank.'
                            }}
                          />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" />
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" />
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        {this.renderTextInput(
                          'signatoryEmail',
                          'signatoryEmail',
                          'email',
                          this.state.signatoryEmail.value,
                          '',
                          'Signatory Email Address',
                          true,
                          'large',
                          !this.state.signatoryEmail.valid,
                          this.state.signatoryEmail.errorMessage,
                          this.handleSignatoryChange,
                          !questionnaireContext.signaturesEnabled
                        )}
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" />
                    </div>
                    <div className="row">
                      <br />
                      <Button
                        inputData={{
                          id: 'sign-btn',
                          displayName: 'Send',
                          type: 'submit',
                          isDisabled: !questionnaireContext.signaturesEnabled,
                        }}
                      />
                    </div>
                    <br />
                    <br />
                  </form>
                </BlockUi>
              </div>
            ) : null}
          </div>

          <Modal
            id="myModal"
            bsSize="large"
            show={questionnaireContext.showInstructionsModal}
            onHide={this.closeInstructionsModal}
          >
            <ModalBody bsClass="clearfix center-main-content">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <br />
                  <br />
                  <Title text={'SIGNING INSTRUCTIONS'} />
                </div>
              </div>
              <br />
              <br />
              <div className="col-lg-12">
                <ul className="unorderList-instructions color-black">
                  <li>
                    <div className="col-lg-1 point">•</div>
                    <div className="col-lg-11">
                      <p>
                        If you are investing in your name and will be signing
                        the subscription documents yourself, please enter your
                        name (as it should appear on the signature page) and
                        your email address. You will be able to sign the
                        subscription documents via DocuSign.
                      </p>
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="col-lg-1 point">•</div>
                    <div className="col-lg-11">
                      <p>
                        If you are investing through an entity and you are
                        signing the subscription documents on behalf of that
                        entity, please enter your name and title (as they should
                        appear on the signature page) and your email address.
                        You will be able to sign the subscription documents via
                        DocuSign.
                      </p>
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="col-lg-1 point">•</div>
                    <div className="col-lg-11">
                      <p>
                        If you completed the subscription documents on behalf of
                        another person or entity and someone else will sign the
                        subscription documents, then enter the name, title (if
                        applicable), and email address of the person who will
                        sign the subscription documents. The recipient will
                        receive a DocuSign link via email and will be able to
                        sign the subscription documents. For example, if you
                        completed the subscription documents on behalf of
                        another person and the other person will sign the
                        subscription documents, then please enter that person’s
                        information in the relevant fields.
                      </p>
                      <br />
                      <br />
                      <br />
                    </div>
                  </li>
                </ul>
              </div>
              <button
                id="instructions-button-open-modal"
                href="#/"
                onClick={this.closeInstructionsModal}
              >
                Proceed
              </button>
              <br />
              <br />
              <br />
              <br />
            </ModalBody>
          </Modal>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
/**
 * @param {Reducer} state
*/

const mapStateToProps = (state) => ({
  organizationContext: state.organizationContext,
  questionnaireContext: state.questionnaireExperience,
});

/**
 * @type {Connector<{}, Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
 * @param {Dispatch} dispatch
*/
  (dispatch) => ({
    isSignaturesLoading: (_loading) =>
      dispatch({ type: IQ_IS_SIGNATURES_LOADING, signaturesLoading: _loading }),
    isSignaturesEnabled: (_enabled) =>
      dispatch({ type: IQ_IS_SIGNATURES_ENABLED, signaturesEnabled: _enabled }),
    setSignaturesMethod: (_method) =>
      dispatch({ type: IQ_SET_SIGNATURES_METHOD, signaturesMethod: _method }),
    setSignaturesUrl: (_url) =>
      dispatch({ type: IQ_SET_SIGNATURES_URL, signaturesUrl: _url }),
    // new additions above
  })
);
export default connector(Signatures);
