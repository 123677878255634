import React from 'react';
import Tooltip from 'rc-tooltip';
import './styles.scss';
import ReactHtmlParser from 'react-html-parser';

type Props = { data: Object, className: String, overlayExtraClass: String };

const CustomTooltip = ({ data, className, overlayExtraClass }: Props) => (
  <div
    className={`tooltip-positioning  ${data.extraClass} ${data.className} ${className}`}
  >
    <Tooltip
      placement="bottom"
      trigger={['click']}
      overlay={<div>{ReactHtmlParser(data.message)}</div>}
      overlayClassName={overlayExtraClass || ''}
    >
      <a
        href="#/"
        className={'tipicon'}
        data-tip={data.message}
        data-event="click focus"
        data-scroll-hide="false"
        tabIndex={-1}
      >
        {' '}
        <i>i</i>{' '}
      </a>
    </Tooltip>
  </div>
);

CustomTooltip.defaultProps = {
  data: {
    message: 'default',
    className: '',
    extraClass: '',
  },
};

export default CustomTooltip;
