/* eslint-disable react/sort-comp,react/no-unused-prop-types */
/* @flow */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactConfirmAlert from 'react-confirm-alert';
import Beforeunload from 'react-beforeunload';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Progress } from './QuestionnaireSteps/Progress/index';
import { QuestionnaireSteps } from './QuestionnaireSteps/index';
import * as action from '../FundForm/action';
// ACCEL STEPS 
import { ProgressAccel } from './QuestionnaireStepsAccel/Progress/index';
import { QuestionnaireStepsAccel } from './QuestionnaireStepsAccel/index';

import { ProgressAccelEtpnReturning } from './QuestionnaireStepsAccelEtpnReturning/Progress';
import { QuestionnaireStepsAccelEtpnReturning } from './QuestionnaireStepsAccelEtpnReturning/index';

import Popup from '../../components/Popup/index';

import InstructionsPageLP from './Versions/LP/Instructions/index';
import GeneralInfoPageLP from './Versions/LP/GeneralInformation/index';
import QuestionnairePageLPv1 from './Versions/LP/v1/QuestionnaireForm/index';
import QuestionnairePageLPv2 from './Versions/LP/v2/QuestionnaireForm/index.v2';
import QuestionnairePageLPv3 from './Versions/LP/v3/QuestionnaireForm/index.v3';
import QuestionnairePageLPv4 from './Versions/LP/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageLPv4 from './Versions/LP/v4/GeneralInformation/index.v4';
import GeneralInfoPageLPv5 from './Versions/LP/v5/GeneralInformation/index.v5';
import QuestionnairePageLPv5 from './Versions/LP/v5/QuestionnaireForm/index.v5';
import GeneralInfoPageLPv6 from './Versions/LP/v6/GeneralInformation/index.v6';
import QuestionnairePageLPv6 from './Versions/LP/v6/QuestionnaireForm/index.v6';
import GeneralInfoPageLPv7 from './Versions/LP/v7/GeneralInformation/index.v7';
import QuestionnairePageLPv7 from './Versions/LP/v7/QuestionnaireForm/index.v7';
import GeneralInfoPageLPv8 from './Versions/LP/v8/GeneralInformation/index.v8';
import QuestionnairePageLPv8 from './Versions/LP/v8/QuestionnaireForm/index.v8';
import GeneralInfoPageLPv8_1 from './Versions/LP/v8_1/GeneralInformation/index.v8_1';
import QuestionnairePageLPv8_1 from './Versions/LP/v8_1/QuestionnaireForm/index.v8_1';
import GeneralInfoPageLPv9 from './Versions/LP/v9/GeneralInformation/index.v9';
import QuestionnairePageLPv9 from './Versions/LP/v9/QuestionnaireForm/index.v9';
import GeneralInfoPageLPv10 from './Versions/LP/v10/GeneralInformation/index.v10';
import QuestionnairePageLPv10 from './Versions/LP/v10/QuestionnaireForm/index.v10';
import GeneralInfoPageLPv11 from './Versions/LP/v11/GeneralInformation/index.v11';
import QuestionnairePageLPv11 from './Versions/LP/v11/QuestionnaireForm/index.v11';

import InstructionsPageFrazier from './Versions/frazier/Instructions/index';
import GeneralInfoPageLFrazierv1 from './Versions/frazier/v1/GeneralInformation/index.v1';
import QuestionnairePageFrazierv1 from './Versions/frazier/v1/QuestionnaireForm/index.v1';

import GeneralInfoPageLLC from './Versions/LLC/GeneralInformation/index';
import InstructionsPageLLC from './Versions/LLC/Instructions/index';
import QuestionnairePageLLCv1 from './Versions/LLC/v1/QuestionnaireForm/index';
import QuestionnairePageLLCv2 from './Versions/LLC/v2/QuestionnaireForm/index.v2';
import QuestionnairePageLLCv3 from './Versions/LLC/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageLLCv3 from './Versions/LLC/v3/GeneralInformation/index.v3';
import QuestionnairePageLLCv4 from './Versions/LLC/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageLLCv4 from './Versions/LLC/v4/GeneralInformation/index.v4';
import QuestionnairePageLLCv5 from './Versions/LLC/v5/QuestionnaireForm/index.v5';
import GeneralInfoPageLLCv5 from './Versions/LLC/v5/GeneralInformation/index.v5';
import QuestionnairePageLLCv6 from './Versions/LLC/v6/QuestionnaireForm/index.v6';
import GeneralInfoPageLLCv6 from './Versions/LLC/v6/GeneralInformation/index.v6';
import QuestionnairePageLLCv6_1 from './Versions/LLC/v6_1/QuestionnaireForm/index.v6_1';
import GeneralInfoPageLLCv6_1 from './Versions/LLC/v6_1/GeneralInformation/index.v6_1';
import QuestionnairePageLLCv7 from './Versions/LLC/v7/QuestionnaireForm/index.v7';
import GeneralInfoPageLLCv7 from './Versions/LLC/v7/GeneralInformation/index.v7';
import QuestionnairePageLLCv8 from './Versions/LLC/v8/QuestionnaireForm/index.v8';
import GeneralInfoPageLLCv8 from './Versions/LLC/v8/GeneralInformation/index.v8';
import QuestionnairePageLLCv9 from './Versions/LLC/v9/QuestionnaireForm/index.v9';
import GeneralInfoPageLLCv9 from './Versions/LLC/v9/GeneralInformation/index.v9';

import InstructionsPageQuestaCapital from './Versions/QuestaCapital/Instructions/index';
import GeneralInfoPageQuestaCapital from './Versions/QuestaCapital/GeneralInformation/index';
import QuestionnairePageQuestaCapitalv1 from './Versions/QuestaCapital/v1/QuestionnaireForm/index';
import QuestionnairePageQuestaCapitalv2 from './Versions/QuestaCapital/v2/QuestionnaireForm/index.v2';
import QuestionnairePageQuestaCapitalv3 from './Versions/QuestaCapital/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageQuestaCapitalv3 from './Versions/QuestaCapital/v3/GeneralInformation/index.v3';
import QuestionnairePageQuestaCapitalv4 from './Versions/QuestaCapital/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageQuestaCapitalv4 from './Versions/QuestaCapital/v4/GeneralInformation/index.v4';
import QuestionnairePageQuestaCapitalv5 from './Versions/QuestaCapital/v5/QuestionnaireForm/index.v5';
import GeneralInfoPageQuestaCapitalv5 from './Versions/QuestaCapital/v5/GeneralInformation/index.v5';
import QuestionnairePageQuestaCapitalv6 from './Versions/QuestaCapital/v6/QuestionnaireForm/index.v6';
import GeneralInfoPageQuestaCapitalv6 from './Versions/QuestaCapital/v6/GeneralInformation/index.v6';
import QuestionnairePageQuestaCapitalv7 from './Versions/QuestaCapital/v7/QuestionnaireForm/index.v7';
import GeneralInfoPageQuestaCapitalv7 from './Versions/QuestaCapital/v7/GeneralInformation/index.v7';

import InstructionsPageForesite from './Versions/Foresite/Instructions/index';
import GeneralInfoPageForesite from './Versions/Foresite/GeneralInformation/index';
import QuestionnairePageForesitev1 from './Versions/Foresite/v1/QuestionnaireForm/index';
import QuestionnairePageForesitev2 from './Versions/Foresite/v2/QuestionnaireForm/index.v2';
import QuestionnairePageForesitev3 from './Versions/Foresite/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageForesitev3 from './Versions/Foresite/v3/GeneralInformation/index.v3';
import QuestionnairePageForesitev4 from './Versions/Foresite/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageForesitev4 from './Versions/Foresite/v4/GeneralInformation/index.v4';
import QuestionnairePageForesitev5 from './Versions/Foresite/v5/QuestionnaireForm/index.v5';
import GeneralInfoPageForesitev5 from './Versions/Foresite/v5/GeneralInformation/index.v5';
import QuestionnairePageForesitev6 from './Versions/Foresite/v6/QuestionnaireForm/index.v6';
import GeneralInfoPageForesitev6 from './Versions/Foresite/v6/GeneralInformation/index.v6';
import QuestionnairePageForesitev7 from './Versions/Foresite/v7/QuestionnaireForm/index.v7';
import GeneralInfoPageForesitev7 from './Versions/Foresite/v7/GeneralInformation/index.v7';

import InstructionsPageInitialized from './Versions/Initialized/Instructions/index';
import GeneralInfoPageInitialized from './Versions/Initialized/GeneralInformation/index';
import QuestionnairePageInitializedv1 from './Versions/Initialized/v1/QuestionnaireForm/index';
import QuestionnairePageInitializedv2 from './Versions/Initialized/v2/QuestionnaireForm/index.v2';
import QuestionnairePageInitializedv3 from './Versions/Initialized/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageInitializedV3 from './Versions/Initialized/v3/GeneralInformation/index.v3';
import QuestionnairePageInitializedv4 from './Versions/Initialized/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageInitializedV4 from './Versions/Initialized/v4/GeneralInformation/index.v4';
import QuestionnairePageInitializedv5 from './Versions/Initialized/v5/QuestionnaireForm/index.v5';
import GeneralInfoPageInitializedV5 from './Versions/Initialized/v5/GeneralInformation/index.v5';
import QuestionnairePageInitializedv6 from './Versions/Initialized/v6/QuestionnaireForm/index.v6';
import GeneralInfoPageInitializedV6 from './Versions/Initialized/v6/GeneralInformation/index.v6';
import QuestionnairePageInitializedv7 from './Versions/Initialized/v7/QuestionnaireForm/index.v7';
import GeneralInfoPageInitializedV7 from './Versions/Initialized/v7/GeneralInformation/index.v7';

import InstructionsPageHCM from './Versions/HCM/Instructions/index';
import QuestionnairePageHCMv1 from './Versions/HCM/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageHCMv1 from './Versions/HCM/v1/GeneralInformation/index.v1';
import QuestionnairePageHCMv2 from './Versions/HCM/v2/QuestionnaireForm/index.v2';
import GeneralInfoPageHCMv2 from './Versions/HCM/v2/GeneralInformation/index.v2';
import QuestionnairePageHCMv3 from './Versions/HCM/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageHCMv3 from './Versions/HCM/v3/GeneralInformation/index.v3';
import QuestionnairePageHCMv4 from './Versions/HCM/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageHCMv4 from './Versions/HCM/v4/GeneralInformation/index.v4';

import InstructionsPageForesiteLabs from './Versions/ForesiteLabs/Instructions';
import QuestionnairePageForesiteLabsv1 from './Versions/ForesiteLabs/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageForesiteLabsv1 from './Versions/ForesiteLabs/v1/GeneralInformation/index.v1';
import QuestionnairePageForesiteLabsv2 from './Versions/ForesiteLabs/v2/QuestionnaireForm/index.v2';
import GeneralInfoPageForesiteLabsv2 from './Versions/ForesiteLabs/v2/GeneralInformation/index.v2';
import QuestionnairePageForesiteLabsv3 from './Versions/ForesiteLabs/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageForesiteLabsv3 from './Versions/ForesiteLabs/v3/GeneralInformation/index.v3';
import QuestionnairePageForesiteLabsv4 from './Versions/ForesiteLabs/v4/QuestionnaireForm/index.v4';
import GeneralInfoPageForesiteLabsv4 from './Versions/ForesiteLabs/v4/GeneralInformation/index.v4';

import GeneralInfoPageLPAbbrv1 from './Versions/LPAbbr/v1/GeneralInformation/index.v1';
import QuestionnairePageLPAbbrv1 from './Versions/LPAbbr/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageLPAbbrv1_1 from './Versions/LPAbbr/v1_1/GeneralInformation/index.v1_1';
import QuestionnairePageLPAbbrv1_1 from './Versions/LPAbbr/v1_1/QuestionnaireForm/index.v1_1';
import GeneralInfoPageLPAbbrv2 from './Versions/LPAbbr/v2/GeneralInformation/index.v2';
import QuestionnairePageLPAbbrv2 from './Versions/LPAbbr/v2/QuestionnaireForm/index.v2';
import GeneralInfoPageLPAbbrv3 from './Versions/LPAbbr/v3/GeneralInformation/index.v3';
import QuestionnairePageLPAbbrv3 from './Versions/LPAbbr/v3/QuestionnaireForm/index.v3';
import GeneralInfoPageLPAbbrv4 from './Versions/LPAbbr/v4/GeneralInformation/index.v4';
import QuestionnairePageLPAbbrv4 from './Versions/LPAbbr/v4/QuestionnaireForm/index.v4';

import GeneralInfoPageIolarv1 from './Versions/Iolar/v1/GeneralInformation/index.v1';
import QuestionnairePageIolarv1 from './Versions/Iolar/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageIolarv2 from './Versions/Iolar/v2/GeneralInformation/index.v2';
import QuestionnairePageIolarv2 from './Versions/Iolar/v2/QuestionnaireForm/index.v2';
import InstructionsPageIolar from './Versions/Iolar/Instructions/index';

import GeneralInfoPageOSAGEv1 from './Versions/OSAGE/v1/GeneralInformation/index.v1';
import QuestionnairePageOSAGEv1 from './Versions/OSAGE/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageOSAGEv2 from './Versions/OSAGE/v2/GeneralInformation/index.v2';
import QuestionnairePageOSAGEv2 from './Versions/OSAGE/v2/QuestionnaireForm/index.v2';

import GeneralInfoPageNewLimitedPartnerv1 from './Versions/NewLimitedPartner/v1/GeneralInformation/index.v1';
import QuestionnairePageNewLimitedPartnerv1 from './Versions/NewLimitedPartner/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageNewLimitedPartnerv2 from './Versions/NewLimitedPartner/v2/GeneralInformation/index.v2';
import QuestionnairePageNewLimitedPartnerv2 from './Versions/NewLimitedPartner/v2/QuestionnaireForm/index.v2';

import InstructionsPageTcgCrossOver from './Versions/LP/Instructions/index';
import GeneralInfoPageTcgCrossOverv1 from './Versions/TcgCrossOver/v1/GeneralInformation/index.v1';
import QuestionnairePageTcgCrossOverv1 from './Versions/TcgCrossOver/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageTcgCrossOverv2 from './Versions/TcgCrossOver/v2/GeneralInformation/index.v2';
import QuestionnairePageTcgCrossOverv2 from './Versions/TcgCrossOver/v2/QuestionnaireForm/index.v2';

import InstructionsPageBitKraft from './Versions/BITKRAFT/Instructions/index';
import GeneralInfoPageBitKraftv1 from './Versions/BITKRAFT/v1/GeneralInformation/index.v1';
import QuestionnairePageBitKraftv1 from './Versions/BITKRAFT/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageBitKraftv2 from './Versions/BITKRAFT/v2/GeneralInformation/index.v2';
import QuestionnairePageBitKraftv2 from './Versions/BITKRAFT/v2/QuestionnaireForm/index.v2';

import InstructionsPageElephantPartner from './Versions/ElephantPartner/Instructions/index';
import GeneralInfoPageElephantPartnerv1 from './Versions/ElephantPartner/v1/GeneralInformation/index.v1';
import QuestionnairePageElephantPartnerv1 from './Versions/ElephantPartner/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageElephantPartnerv2 from './Versions/ElephantPartner/v2/GeneralInformation/index.v2';
import QuestionnairePageElephantPartnerv2 from './Versions/ElephantPartner/v2/QuestionnaireForm/index.v2';

import GeneralInfoPageFireworkv1 from './Versions/Firework/v1/GeneralInformation/index.v1';
import QuestionnairePageFireworkv1 from './Versions/Firework/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageFireworkv2 from './Versions/Firework/v2/GeneralInformation/index.v2';
import QuestionnairePageFireworkv2 from './Versions/Firework/v2/QuestionnaireForm/index.v2';

import QuestionnairePrometheanv1 from './Versions/promethean/v1/QuestionnaireForm/index.v1';
import GeneralInfoPagePrometheanv1 from './Versions/promethean/v1/GeneralInformation/index.v1';
import QuestionnairePrometheanv2 from './Versions/promethean/v2/QuestionnaireForm/index.v2';
import GeneralInfoPagePrometheanv2 from './Versions/promethean/v2/GeneralInformation/index.v2';

import InstructionsPageAccel from './Versions/accel/Instructions/index';
import InterestLetterPageAccelv1 from './Versions/accel/v1/InvestmentInterestLetter/index.v1';
import GeneralInfoPageAccelv1 from './Versions/accel/v1/GeneralInformation/index.v1';
import QuestionnairePageAccelv1 from './Versions/accel/v1/QuestionnaireForm/index.v1';
import InterestLetterPageAccelv2 from './Versions/accel/v2/InvestmentInterestLetter/index.v2';
import GeneralInfoPageAccelv2 from './Versions/accel/v2/GeneralInformation/index.v2';
import QuestionnairePageAccelv2 from './Versions/accel/v2/QuestionnaireForm/index.v2';
import InterestLetterPageAccelv3 from './Versions/accel/v3/InvestmentInterestLetter/index.v3';
import GeneralInfoPageAccelv3 from './Versions/accel/v3/GeneralInformation/index.v3';
import QuestionnairePageAccelv3 from './Versions/accel/v3/QuestionnaireForm/index.v3';

import InstructionsPageAccelMain from './Versions/accelMain/Instructions/index';
import GeneralInfoPageAccelMainv1 from './Versions/accelMain/v1/GeneralInformation/index.v1';
import QuestionnairePageAccelMainv1 from './Versions/accelMain/v1/QuestionnaireForm/index.v1';

import InstructionsPageAccelEntrepreneurs from './Versions/accelEntrepreneurs/Instructions/index';
import GeneralInfoPageAccelEntrepreneursv1 from './Versions/accelEntrepreneurs/v1/GeneralInformation/index.v1';
import QuestionnairePageAccelEntrepreneursv1 from './Versions/accelEntrepreneurs/v1/QuestionnaireForm/index.v1';

import GeneralInfoPageAccelEtpnReturningv1 from './Versions/accelEtpnReturning/v1/GeneralInformation/index.v1';
import GeneralInfoPageAccelEtpnReturningv2 from './Versions/accelEtpnReturning/v2/GeneralInformation/index.v2';

import InstructionsPageAudere from './Versions/Audere/Instructions/index';
import GeneralInfoPageAuderev1 from './Versions/Audere/v1/GeneralInformation/index.v1';
import QuestionnairePageAuderev1 from './Versions/Audere/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageAuderev2 from './Versions/Audere/v2/GeneralInformation/index.v2';
import QuestionnairePageAuderev2 from './Versions/Audere/v2/QuestionnaireForm/index.v2';

import InstructionsPageCayman from './Versions/cayman/Instructions/index';
import GeneralInfoPageCaymanv1 from './Versions/cayman/v1/GeneralInformation/index.v1';
import QuestionnairePageCaymanv1 from './Versions/cayman/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageCaymanv2 from './Versions/cayman/v2/GeneralInformation/index.v2';
import QuestionnairePageCaymanv2 from './Versions/cayman/v2/QuestionnaireForm/index.v2';

import GeneralInfoPageLPMultiFundv1 from './Versions/LPMultiFund/v1/GeneralInformation/index.v1';
import QuestionnairePageLPMultiFundv1 from './Versions/LPMultiFund/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageLPMultiFundv2 from './Versions/LPMultiFund/v2/GeneralInformation/index.v2';
import QuestionnairePageLPMultiFundv2 from './Versions/LPMultiFund/v2/QuestionnaireForm/index.v2';
import InstructionsLPMultiFundv2 from './Versions/LPMultiFund/v2/Instructions';
import GeneralInfoPageLPMultiFundv3 from './Versions/LPMultiFund/v3/GeneralInformation/index.v3';
import QuestionnairePageLPMultiFundv3 from './Versions/LPMultiFund/v3/QuestionnaireForm/index.v3';
import InstructionsLPMultiFundv3 from './Versions/LPMultiFund/v3/Instructions';
import InstructionsLPMultiFund from './Versions/LPMultiFund/Instructions';

import GeneralInfoPageLimitedPartnershipMFv1 from './Versions/limitedPartnershipMF/v1/GeneralInformation/index.v1';
import QuestionnairePageLimitedPartnershipMFv1 from './Versions/limitedPartnershipMF/v1/QuestionnaireForm/index.v1';
import GeneralInfoPageLimitedPartnershipMFv2 from './Versions/limitedPartnershipMF/v2/GeneralInformation/index.v2';
import QuestionnairePageLimitedPartnershipMFv2 from './Versions/limitedPartnershipMF/v2/QuestionnaireForm/index.v2';
import GeneralInfoPageLimitedPartnershipMFv3 from './Versions/limitedPartnershipMF/v3/GeneralInformation/index.v3';
import QuestionnairePageLimitedPartnershipMFv3 from './Versions/limitedPartnershipMF/v3/QuestionnaireForm/index.v3';
import InstructionsLimitedPartnershipMF from './Versions/limitedPartnershipMF/Instructions';

import GeneralInfoPageSROnev1 from './Versions/SROne/v1/GeneralInformation/index.v1';
import QuestionnairePageSROnev1 from './Versions/SROne/v1/QuestionnaireForm/index.v1';
import InstructionsSROne from './Versions/SROne/Instructions';

import SignaturesPage from './Signatures';
import NotFoundPage from './../NotFound';
import {
  IQ_SET_CURRENT_STEP,
  IQ_SET_INVESTOR_FUND,
  IQ_SET_FUND,
  IQ_SET_INVESTOR,
  IQ_SHOW_EXPIRATION_NOTICE,
  IQ_SET_TRIGGER_GENERAL_INFORMATION,
  IQ_IS_SIGNATURES_ENABLED,
  IQ_SET_VALIDATION_REQUIRED_PARTS,
  IQ_SHOW_SIGNATURE_INSTRUCTIONS,
  IQ_SET_CURRENT_VERSION,
  IQ_SET_CURRENT_VERSION_QUESTIONS,
  IQ_IS_NATURAL_PERSON,
  IQ_SET_MULTI_SELECT_FORM_METADATA,
} from './reducer';
import {
  API_URL,
  SERVICE_CONFIG_QUESTIONNAIRE,
  SERVICE_CONFIG,
} from '../../utils/service-commons';
import * as iqID from '../../utils/constants/questionnaire/identifiers';

import { questions as questionsLPv1 } from '../../utils/constants/questionnaire/limitedPartner/v1/iq-questions.lp.v1';
import { questions as questionsLPv2 } from '../../utils/constants/questionnaire/limitedPartner/v2/iq-questions.lp.v2';
import { questions as questionsLPv3 } from '../../utils/constants/questionnaire/limitedPartner/v3/iq-questions.lp.v3';
import { questions as questionsLPv4 } from '../../utils/constants/questionnaire/limitedPartner/v4/iq-questions.lp.v4';
import { questions as questionsLPv5 } from '../../utils/constants/questionnaire/limitedPartner/v5/iq-questions.lp.v5';
import { questions as questionsLPv6 } from '../../utils/constants/questionnaire/limitedPartner/v6/iq-questions.lp.v6';
import { questions as questionsLPv7 } from '../../utils/constants/questionnaire/limitedPartner/v7/iq-questions.lp.v7';
import { questions as questionsLPv8 } from '../../utils/constants/questionnaire/limitedPartner/v8/iq-questions.lp.v8';
import { questions as questionsLPv8_1 } from '../../utils/constants/questionnaire/limitedPartner/v8_1/iq-questions.lp.v8_1';
import { questions as questionsLPv9 } from '../../utils/constants/questionnaire/limitedPartner/v9/iq-questions.lp.v9';
import { questions as questionsLPv10 } from '../../utils/constants/questionnaire/limitedPartner/v10/iq-questions.lp.v10';
import { questions as questionsLPv11 } from '../../utils/constants/questionnaire/limitedPartner/v11/iq-questions.lp.v11';

import { questions as questionsFrazierv1 } from '../../utils/constants/questionnaire/frazier/v1/iq-questions.v1';

import { questions as questionsLLCv1 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v1/iq-questions.llc.v1';
import { questions as questionsLLCv2 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v2/iq-questions.llc.v2';
import { questions as questionsLLCv3 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v3/iq-questions.llc.v3';
import { questions as questionsLLCv4 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v4/iq-questions.llc.v4';
import { questions as questionsLLCv5 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v5/iq-questions.llc.v5';
import { questions as questionsLLCv6 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v6/iq-questions.llc.v6';
import { questions as questionsLLCv6_1 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v6_1/iq-questions.llc.v6_1';
import { questions as questionsLLCv7 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v7/iq-questions.llc.v7';
import { questions as questionsLLCv8 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v8/iq-questions.llc.v8';
import { questions as questionsLLCv9 } from '../../utils/constants/questionnaire/limitedliabilitycompany/v9/iq-questions.llc.v9';

import { questions as questionsForesitev1 } from '../../utils/constants/questionnaire/foresite/v1/iq-questions.foresite.v1';
import { questions as questionsForesitev2 } from '../../utils/constants/questionnaire/foresite/v2/iq-questions.foresite.v2';
import { questions as questionsForesitev3 } from '../../utils/constants/questionnaire/foresite/v3/iq-questions.foresite.v3';
import { questions as questionsForesitev4 } from '../../utils/constants/questionnaire/foresite/v4/iq-questions.foresite.v4';
import { questions as questionsForesitev5 } from '../../utils/constants/questionnaire/foresite/v5/iq-questions.foresite.v5';
import { questions as questionsForesitev6 } from '../../utils/constants/questionnaire/foresite/v6/iq-questions.foresite.v6';
import { questions as questionsForesitev7 } from '../../utils/constants/questionnaire/foresite/v7/iq-questions.foresite.v7';

import { questions as questionsInitializedv1 } from '../../utils/constants/questionnaire/initialized/v1/iq-questions.initialized.v1';
import { questions as questionsInitializedv2 } from '../../utils/constants/questionnaire/initialized/v2/iq-questions.initialized.v2';
import { questions as questionsInitializedv3 } from '../../utils/constants/questionnaire/initialized/v3/iq-questions.initialized.v3';
import { questions as questionsInitializedv4 } from '../../utils/constants/questionnaire/initialized/v4/iq-questions.initialized.v4';
import { questions as questionsInitializedv5 } from '../../utils/constants/questionnaire/initialized/v5/iq-questions.initialized.v5';
import { questions as questionsInitializedv6 } from '../../utils/constants/questionnaire/initialized/v6/iq-questions.initialized.v6';
import { questions as questionsInitializedv7 } from '../../utils/constants/questionnaire/initialized/v7/iq-questions.initialized.v7';

import { questions as questionsQuestaCapitalv1 } from '../../utils/constants/questionnaire/questaCapital/v1/iq-questions.questaCapital.v1';
import { questions as questionsQuestaCapitalv2 } from '../../utils/constants/questionnaire/questaCapital/v2/iq-questions.questaCapital.v2';
import { questions as questionsQuestaCapitalv3 } from '../../utils/constants/questionnaire/questaCapital/v3/iq-questions.questaCapital.v3';
import { questions as questionsQuestaCapitalv4 } from '../../utils/constants/questionnaire/questaCapital/v4/iq-questions.questaCapital.v4';
import { questions as questionsQuestaCapitalv5 } from '../../utils/constants/questionnaire/questaCapital/v5/iq-questions.questaCapital.v5';
import { questions as questionsQuestaCapitalv6 } from '../../utils/constants/questionnaire/questaCapital/v6/iq-questions.questaCapital.v6';
import { questions as questionsQuestaCapitalv7 } from '../../utils/constants/questionnaire/questaCapital/v7/iq-questions.questaCapital.v7';

import { questions as questionsHCMv1 } from '../../utils/constants/questionnaire/hcm/v1/iq-questions.hcm.v1';
import { questions as questionsHCMv2 } from '../../utils/constants/questionnaire/hcm/v2/iq-questions.hcm.v2';
import { questions as questionsHCMv3 } from '../../utils/constants/questionnaire/hcm/v3/iq-questions.hcm.v3';
import { questions as questionsHCMv4 } from '../../utils/constants/questionnaire/hcm/v4/iq-questions.hcm.v4';

import { questions as questionsForesiteLabsv1 } from '../../utils/constants/questionnaire/foresiteLabs/v1/iq-questions.foresiteLabs.v1';
import { questions as questionsForesiteLabsv2 } from '../../utils/constants/questionnaire/foresiteLabs/v2/iq-questions.foresiteLabs.v2';
import { questions as questionsForesiteLabsv3 } from '../../utils/constants/questionnaire/foresiteLabs/v3/iq-questions.foresiteLabs.v3';
import { questions as questionsForesiteLabsv4 } from '../../utils/constants/questionnaire/foresiteLabs/v4/iq-questions.foresiteLabs.v4';

import { questions as questionsAuderev1 } from '../../utils/constants/questionnaire/Audere/v1/iq-questions.v1';
import { questions as questionsAuderev2 } from '../../utils/constants/questionnaire/Audere/v2/iq-questions.v2';

import { questions as questionsLPAbbrv1 } from '../../utils/constants/questionnaire/lpabbr/v1/iq-questions.lpabbr.v1';
import { questions as questionsLPAbbrv1_1 } from '../../utils/constants/questionnaire/lpabbr/v1_1/iq-questions.lpabbr.v1_1';
import { questions as questionsLPAbbrv2 } from '../../utils/constants/questionnaire/lpabbr/v2/iq-questions.lpabbr.v2';
import { questions as questionsLPAbbrv3 } from '../../utils/constants/questionnaire/lpabbr/v3/iq-questions.lpabbr.v3';
import { questions as questionsLPAbbrv4 } from '../../utils/constants/questionnaire/lpabbr/v4/iq-questions.lpabbr.v4';

import { questions as questionsIolarv1 } from '../../utils/constants/questionnaire/Iolar/v1/iq-questions.iolar.v1';
import { questions as questionsIolarv2 } from '../../utils/constants/questionnaire/Iolar/v2/iq-questions.iolar.v2';

import { questions as questionsOSAGEv1 } from '../../utils/constants/questionnaire/osage/v1/iq-questions.osage.v1';
import { questions as questionsOSAGEv2 } from '../../utils/constants/questionnaire/osage/v2/iq-questions.osage.v2';

import { questions as questionsNewLimitedPartnerv1 } from '../../utils/constants/questionnaire/newLimitedPartner/v1/iq-questions.newLp.v1';
import { questions as questionsNewLimitedPartnerv2 } from '../../utils/constants/questionnaire/newLimitedPartner/v2/iq-questions.newLp.v2';

import { questions as questionsTcgCrossOverv1 } from '../../utils/constants/questionnaire/tcgCrossOver/v1/iq-questions.v1';
import { questions as questionsTcgCrossOverv2 } from '../../utils/constants/questionnaire/tcgCrossOver/v2/iq-questions.v2';

import { questions as questionsBitKraftv1 } from '../../utils/constants/questionnaire/BITKRAFT/v1/iq-questions.v1';
import { questions as questionsBitKraftv2 } from '../../utils/constants/questionnaire/BITKRAFT/v2/iq-questions.v2';

import { questions as questionsElephantPartnerv1 } from '../../utils/constants/questionnaire/ElephantPartner/v1/iq-questions.v1';
import { questions as questionsElephantPartnerv2 } from '../../utils/constants/questionnaire/ElephantPartner/v2/iq-questions.v2';

import { questions as questionsFLPv1 } from '../../utils/constants/questionnaire/firework/v1/iq-questions.lp.v1';
import { questions as questionsFLPv2 } from '../../utils/constants/questionnaire/firework/v2/iq-questions.lp.v2';

import { questions as questionsPrometheanv1 } from '../../utils/constants/questionnaire/promethean/v1/iq-questions.promethean.v1';
import { questions as questionsPrometheanv2 } from '../../utils/constants/questionnaire/promethean/v2/iq-questions.promethean.v2';

import { questions as questionsAccelv1 } from '../../utils/constants/questionnaire/accel/v1/iq-questions.accel.v1';
import { questions as questionsAccelv2 } from '../../utils/constants/questionnaire/accel/v2/iq-questions.accel.v2';
import { questions as questionsAccelv3 } from '../../utils/constants/questionnaire/accel/v3/iq-questions.accel.v3';

import { questions as questionsAccelMainv1 } from '../../utils/constants/questionnaire/accelMain/v1/iq-questions.accel-main.v1';

import { questions as questionsAccelEntrepreneursv1 } from '../../utils/constants/questionnaire/accelEntrepreneurs/v1/iq-questions.accel-entrepreneurs.v1';

import { questions as questionsAccelEtpnReturningv1 } from '../../utils/constants/questionnaire/accelEtpnReturning/v1/iq-questions.accel-etpn-returning.v1.js';
import { questions as questionsAccelEtpnReturningv2 } from '../../utils/constants/questionnaire/accelEtpnReturning/v2/iq-questions.accel-etpn-returning.v2.js';

import { questions as questionsLPMultiFundv1 } from '../../utils/constants/questionnaire/LPMultiFund/v1/iq-questions.lpmultifund.v1';
import { questions as questionsLPMultiFundv2 } from '../../utils/constants/questionnaire/LPMultiFund/v2/iq-questions.lpmultifund.v2';
import { questions as questionsLPMultiFundv3 } from '../../utils/constants/questionnaire/LPMultiFund/v3/iq-questions.lpmultifund.v3';

import { questions as questionsLimitedPartnershipMFv1 } from '../../utils/constants/questionnaire/limitedPartnershipMF/v1/iq-questions.lpmf.v1';
import { questions as questionsLimitedPartnershipMFv2 } from '../../utils/constants/questionnaire/limitedPartnershipMF/v2/iq-questions.lpmf.v2';
import { questions as questionsLimitedPartnershipMFv3 } from '../../utils/constants/questionnaire/limitedPartnershipMF/v3/iq-questions.lpmf.v3';

import { questions as questionsCaymanv1 } from '../../utils/constants/questionnaire/cayman/v1/iq-questions.v1';
import { questions as questionsCaymanv2 } from '../../utils/constants/questionnaire/cayman/v2/iq-questions.v2';

import { questions as questionsSROnev1 } from '../../utils/constants/questionnaire/SROne/v1/iq-questions.sr-one.v1';

import {
  yesOrNoQuestions,
  textFieldQuestions,
  multiSelectQuestions,
} from './../../utils/constants/questionnaire/disabledQuestions';

import {
  LimitedPartnerV1,
  LimitedPartnerV2,
  LimitedPartnerV3,
  LimitedPartnerV4,
  LimitedPartnerV5,
  LimitedPartnerV6,
  LimitedPartnerV7,
  LimitedPartnerV8,
  LimitedPartnerV8_1,
  LimitedPartnerV9,
  LimitedPartnerV10,
  LimitedPartnerV11,
  LimitedLiabilityCompanyV1,
  LimitedLiabilityCompanyV2,
  LimitedLiabilityCompanyV3,
  LimitedLiabilityCompanyV4,
  LimitedLiabilityCompanyV5,
  LimitedLiabilityCompanyV6,
  LimitedLiabilityCompanyV6_1,
  LimitedLiabilityCompanyV7,
  LimitedLiabilityCompanyV8,
  LimitedLiabilityCompanyV9,
  ForesiteV1,
  ForesiteV2,
  ForesiteV3,
  ForesiteV4,
  ForesiteV5,
  ForesiteV6,
  ForesiteV7,
  QuestaCapitalV1,
  QuestaCapitalV2,
  QuestaCapitalV3,
  QuestaCapitalV4,
  QuestaCapitalV5,
  QuestaCapitalV6,
  QuestaCapitalV7,
  InitializedV1,
  InitializedV2,
  InitializedV3,
  InitializedV4,
  InitializedV5,
  InitializedV6,
  InitializedV7,
  HCMV1,
  HCMV2,
  HCMV3,
  HCMV4,
  ForesiteLabsV1,
  ForesiteLabsV2,
  ForesiteLabsV3,
  ForesiteLabsV4,
  LPABBRV1,
  LPABBRV1_1,
  LPABBRV2,
  LPABBRV3,
  LPABBRV4,
  OSAGEV1,
  OSAGEV2,
  NewLimitedPartnerV1,
  NewLimitedPartnerV2,
  FireworkV1,
  FireworkV2,
  PrometheanV1,
  PrometheanV2,
  AccelV1,
  AccelV2,
  AccelV3,
  AccelMainV1,
  AccelEntrepreneursV1,
  AccelEtpnReturningV1,
  AccelEtpnReturningV2,
  LPMULTIFUNDV1,
  LPMULTIFUNDV2,
  LPMULTIFUNDV3,
  TcgCrossOverV1,
  TcgCrossOverV2,
  IOLARV1,
  IOLARV2,
  BITKRAFTV1,
  BITKRAFTV2,
  ElephantPartnerV1,
  ElephantPartnerV2,
  LimitedPartnershipMFV1,
  LimitedPartnershipMFV2,
  LimitedPartnershipMFV3,
  AudereV1,
  AudereV2,
  CaymanV1,
  CaymanV2,
  SROneV1,
  FrazierV1
} from '../../utils/constants/questionnaire/versions';
import { IQ_SET_SIGNATURES_METHOD } from './reducer';
import { confirmDocCanBeSigned, confirmReturningInvestorCanSign } from './util';
import { handleQuestionnaireError } from '../../utils/requestErrorHandler';

/**
 * @typedef {Object} Props
 * @property {Object} history
 * @property {Gunderson.Context} questionnaireContext
*/

export const iq_last_step_token = 'iq-last-step';
export const iq_cb_token = 'iq-cb-token';
export const iq_cb_user_token = 'iq-cb-user';
export const iq_general_info_token = 'generalInfo';

let restrictAccess = false;

/**
 * @class 
 * @extends {Component<Props>}
*/
export class QuestionnaireExperienceHome extends Component {
  static defaultProps = {
    history: {},
  };

  componentDidMount() {
    const { investorFund } = this.props.questionnaireContext;
    if (investorFund && investorFund.questionnaire) {
      this.props.setVersion(
        investorFund.questionnaire.iqVersion || LimitedPartnerV1
      );
    }

    const iqToken = sessionStorage.getItem('iq_cb_token');
    const userToken = sessionStorage.getItem('iq_cb_user_token');

    if (iqToken && userToken) {
      window.addEventListener('beforeunload', (event) => {
        event.returnValue = 'Are you sure you want to leave?';
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { questionnaireContext } = this.props;
    if (
      prevProps.questionnaireContext.currentVersion !==
        questionnaireContext.currentVersion ||
      (questionnaireContext.currentVersion !== '' &&
        !questionnaireContext.currentVersionInitialized &&
        !questionnaireContext.currentVersionQuestions.length)
    ) {
      let targetVersionQuestions = null;
      switch (questionnaireContext.currentVersion) {
        case LimitedPartnerV1:
          targetVersionQuestions = questionsLPv1;
          break;
        case LimitedPartnerV2:
          targetVersionQuestions = questionsLPv2;
          break;
        case LimitedPartnerV3:
          targetVersionQuestions = questionsLPv3;
          break;
        case LimitedPartnerV4:
          targetVersionQuestions = questionsLPv4;
          break;
        case LimitedPartnerV5:
          targetVersionQuestions = questionsLPv5;
          break;
        case LimitedPartnerV6:
          targetVersionQuestions = questionsLPv6;
          break;
        case LimitedPartnerV7:
          targetVersionQuestions = questionsLPv7;
          break;
        case LimitedPartnerV8:
          targetVersionQuestions = questionsLPv8;
          break;
        case LimitedPartnerV8_1:
          targetVersionQuestions = questionsLPv8_1;
          break;
        case LimitedPartnerV9:
          targetVersionQuestions = questionsLPv9;
          break;
        case LimitedPartnerV10:
          targetVersionQuestions = questionsLPv10;
          break;
        case LimitedPartnerV11:
          targetVersionQuestions = questionsLPv11;
          break;

        
        case FrazierV1:
          targetVersionQuestions = questionsFrazierv1;
          break;


        case LimitedLiabilityCompanyV1:
          targetVersionQuestions = questionsLLCv1;
          break;
        case LimitedLiabilityCompanyV2:
          targetVersionQuestions = questionsLLCv2;
          break;
        case LimitedLiabilityCompanyV3:
          targetVersionQuestions = questionsLLCv3;
          break;
        case LimitedLiabilityCompanyV4:
          targetVersionQuestions = questionsLLCv4;
          break;
        case LimitedLiabilityCompanyV5:
          targetVersionQuestions = questionsLLCv5;
          break;
        case LimitedLiabilityCompanyV6:
          targetVersionQuestions = questionsLLCv6;
          break;
        case LimitedLiabilityCompanyV6_1:
          targetVersionQuestions = questionsLLCv6_1;
          break;
        case LimitedLiabilityCompanyV7:
          targetVersionQuestions = questionsLLCv7;
          break;
        case LimitedLiabilityCompanyV8:
          targetVersionQuestions = questionsLLCv8;
          break;
        case LimitedLiabilityCompanyV9:
          targetVersionQuestions = questionsLLCv9;
          break;

        case ForesiteV1:
          targetVersionQuestions = questionsForesitev1;
          break;
        case ForesiteV2:
          targetVersionQuestions = questionsForesitev2;
          break;
        case ForesiteV3:
          targetVersionQuestions = questionsForesitev3;
          break;
        case ForesiteV4:
          targetVersionQuestions = questionsForesitev4;
          break;
        case ForesiteV5:
          targetVersionQuestions = questionsForesitev5;
          break;
        case ForesiteV6:
          targetVersionQuestions = questionsForesitev6;
          break;
         case ForesiteV7:
          targetVersionQuestions = questionsForesitev7;
          break;

        case QuestaCapitalV1:
          targetVersionQuestions = questionsQuestaCapitalv1;
          break;
        case QuestaCapitalV2:
          targetVersionQuestions = questionsQuestaCapitalv2;
          break;
        case QuestaCapitalV3:
          targetVersionQuestions = questionsQuestaCapitalv3;
          break;
        case QuestaCapitalV4:
          targetVersionQuestions = questionsQuestaCapitalv4;
          break;
        case QuestaCapitalV5:
          targetVersionQuestions = questionsQuestaCapitalv5;
          break;
        case QuestaCapitalV6:
          targetVersionQuestions = questionsQuestaCapitalv6;
          break;
        case QuestaCapitalV7:
          targetVersionQuestions = questionsQuestaCapitalv7;
          break;

        case InitializedV1:
          targetVersionQuestions = questionsInitializedv1;
          break;
        case InitializedV2:
          targetVersionQuestions = questionsInitializedv2;
          break;
        case InitializedV3:
          targetVersionQuestions = questionsInitializedv3;
          break;
        case InitializedV4:
          targetVersionQuestions = questionsInitializedv4;
          break;
        case InitializedV5:
          targetVersionQuestions = questionsInitializedv5;
          break;
        case InitializedV6:
          targetVersionQuestions = questionsInitializedv6;
          break;
        case InitializedV7:
          targetVersionQuestions = questionsInitializedv7;
          break;

        case HCMV1:
          targetVersionQuestions = questionsHCMv1;
          break;
        case HCMV2:
          targetVersionQuestions = questionsHCMv2;
          break;
        case HCMV3:
          targetVersionQuestions = questionsHCMv3;
          break;
        case HCMV4:
          targetVersionQuestions = questionsHCMv4;
          break;

        case ForesiteLabsV1:
          targetVersionQuestions = questionsForesiteLabsv1;
          break;
        case ForesiteLabsV2:
          targetVersionQuestions = questionsForesiteLabsv2;
          break;
        case ForesiteLabsV3:
          targetVersionQuestions = questionsForesiteLabsv3;
          break;
        case ForesiteLabsV4:
          targetVersionQuestions = questionsForesiteLabsv4;
          break;

        case AudereV1:
          targetVersionQuestions = questionsAuderev1;
          break;
        case AudereV2:
          targetVersionQuestions = questionsAuderev2;
          break;

        case CaymanV1:
          targetVersionQuestions = questionsCaymanv1;
          break;
        case CaymanV2:
          targetVersionQuestions = questionsCaymanv2;
          break;

        case LPABBRV1:
          targetVersionQuestions = questionsLPAbbrv1;
          break;
        case LPABBRV1_1:
          targetVersionQuestions = questionsLPAbbrv1_1;
          break;
        case LPABBRV2:
          targetVersionQuestions = questionsLPAbbrv2;
          break;
        case LPABBRV3:
          targetVersionQuestions = questionsLPAbbrv3;
          break;
        case LPABBRV4:
          targetVersionQuestions = questionsLPAbbrv4;
          break;
        
        case IOLARV1:
          targetVersionQuestions = questionsIolarv1;
          break;
        case IOLARV2:
          targetVersionQuestions = questionsIolarv2;
          break;

        case OSAGEV1:
          targetVersionQuestions = questionsOSAGEv1;
          break;
        case OSAGEV2:
          targetVersionQuestions = questionsOSAGEv2;
          break;

        case NewLimitedPartnerV1:
          targetVersionQuestions = questionsNewLimitedPartnerv1;
          break;
        case NewLimitedPartnerV2:
          targetVersionQuestions = questionsNewLimitedPartnerv2;
          break;

        case TcgCrossOverV1:
          targetVersionQuestions = questionsTcgCrossOverv1;
          break;
         case TcgCrossOverV2:
          targetVersionQuestions = questionsTcgCrossOverv2;
          break;

        case BITKRAFTV1:
          targetVersionQuestions = questionsBitKraftv1;
          break;
        case BITKRAFTV2:
          targetVersionQuestions = questionsBitKraftv2;
          break;

        case ElephantPartnerV1:
           targetVersionQuestions = questionsElephantPartnerv1;
          break;
        case ElephantPartnerV2:
           targetVersionQuestions = questionsElephantPartnerv2;
          break;

        case FireworkV1:
          targetVersionQuestions = questionsFLPv1;
          break;
        case FireworkV2:
          targetVersionQuestions = questionsFLPv2;
          break;

        case PrometheanV1:
          targetVersionQuestions = questionsPrometheanv1;
          break;
        case PrometheanV2:
          targetVersionQuestions = questionsPrometheanv2;
          break;
        
        case AccelV1:
          targetVersionQuestions = questionsAccelv1;
          break;
        case AccelV2:
          targetVersionQuestions = questionsAccelv2;
          break;
        case AccelV3:
          targetVersionQuestions = questionsAccelv3;
          break;
        
        case AccelMainV1:
          targetVersionQuestions = questionsAccelMainv1;
          break;

        case AccelEntrepreneursV1:
          targetVersionQuestions = questionsAccelEntrepreneursv1;
          break;
        
        case AccelEtpnReturningV1:
          targetVersionQuestions = questionsAccelEtpnReturningv1;
          break;
        case AccelEtpnReturningV2:
          targetVersionQuestions = questionsAccelEtpnReturningv2;
          break;

        case LPMULTIFUNDV1:
          targetVersionQuestions = questionsLPMultiFundv1;
          break;
        case LPMULTIFUNDV2:
          targetVersionQuestions = questionsLPMultiFundv2;
          break;
        case LPMULTIFUNDV3:
          targetVersionQuestions = questionsLPMultiFundv3;
          break;

        case LimitedPartnershipMFV1:
          targetVersionQuestions = questionsLimitedPartnershipMFv1;
          break;
        case LimitedPartnershipMFV2:
          targetVersionQuestions = questionsLimitedPartnershipMFv2;
          break;
        case LimitedPartnershipMFV3:
          targetVersionQuestions = questionsLimitedPartnershipMFv3;
          break;

        case SROneV1:
          targetVersionQuestions = questionsSROnev1;
          break;

        default:
          console.error('No current version to set the version questions !!!!');
      }

      if (!questionnaireContext.currentVersionQuestions.length) {
        this.props.setVersionQuestions(targetVersionQuestions);
      }
      if (questionnaireContext.investorFund.generalInfo) {
        const isNatural = this.isNaturalPerson();
        this.props.isNaturalPerson(isNatural);

        this.evaluateMultiSelectQuestions(isNatural, targetVersionQuestions);
      }
    }
    // currently it doesn't trigger if the entity doesn't change from natural to corporation , check if it is needed
    const hasNewTypes =
      JSON.stringify(
        prevProps.questionnaireContext.investorFund.generalInfo
      ) !== JSON.stringify(questionnaireContext.investorFund.generalInfo);
    if (hasNewTypes) {
      // console.log('General Information has changed Types');
    }
    const questionnaireHasUpdates =
      prevProps.questionnaireContext.investorFund.questionnaire &&
      prevProps.questionnaireContext.investorFund.questionnaire.questions &&
      JSON.stringify(
        prevProps.questionnaireContext.investorFund.questionnaire.questions
      ) !==
        JSON.stringify(
          questionnaireContext.investorFund.questionnaire.questions
        );

    if (
      questionnaireHasUpdates &&
      Object.entries(questionnaireContext.currentVersionQuestions).length
    ) {
      const currentIsNaturalPerson = this.isNaturalPerson();
      this.evaluateMultiSelectQuestions(
        currentIsNaturalPerson,
        questionnaireContext.currentVersionQuestions
      );
      this.props.isNaturalPerson(currentIsNaturalPerson);
    }
  }
  componentWillUnmount() {}

  fetchInvestorInfo = async () => {
    const { investor, investorFund } = this.props.questionnaireContext;
    const questionnaireTab = window.location.href.includes('questionnaire');
    if (
      investorFund.updateInvestorInfo &&
      questionnaireTab &&
      !restrictAccess
    ) {
      restrictAccess = true;
      let updateData = {};
      updateData.questionFields = [];
      updateData._id = investorFund._id;
      updateData.limitedPartnerFund = investorFund._id;
      updateData.iqVersion = investorFund.questionnaire.iqVersion;
      const { data } = await axios.post(
        `${API_URL()}/access/questionnaire/updateInvestorInfo`,
        updateData,
        SERVICE_CONFIG_QUESTIONNAIRE()
      );

      if (data.success) {
        this.props.setInvestorFund(data.investorFund);
      } else {
        this.props.renderNotification(
          'error',
          'Error updating questionnaire field',
          data.message
        );
      }
    }
  };

  isNaturalPerson = () => {
    const {
      questionnaireContext: {
        investorFund: { generalInfo },
      },
    } = this.props;
    return (
      generalInfo &&
      generalInfo.types &&
      (generalInfo.types.indexOf('Natural Person') > -1 ||
        generalInfo.types.indexOf('IRA') > -1 ||
        generalInfo.types.indexOf('Tenants in Common') > -1 ||
        generalInfo.types.indexOf('Joint Tenants with Right of Survivorship') >
          -1 ||
        generalInfo.types.indexOf('Other - Individual') > -1)
    );
  };

  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };

  questionIsAnswered = (_identifier) =>
    !!this.parseQuestionDataFromId(_identifier);
  // in case of changing entities or different answers that require removing a certain questions
  cleanupInvalidQuestions = (_isNaturalPerson, _currentVersionQuestions) => {
    const {
      accreditedInvestorRepresentations,
      qualifiedPurchaserRepresentations,
      foreignPersonStatusRepresentations,
      foreignPersonStatusRepresentations2,
      identificationofSpecialEntities,
    } = _currentVersionQuestions;

    let isEntityIRA = false;
    const generalInfoJSON = JSON.parse(sessionStorage.getItem('generalInfo'));
    if (generalInfoJSON && generalInfoJSON.types) {
      isEntityIRA = generalInfoJSON.types.includes('IRA');
    }

    const removeList = [];
    if (_isNaturalPerson) {
      // clean all specialEntities
      for (const specialEntityQuestion of identificationofSpecialEntities) {
        if (this.questionIsAnswered(specialEntityQuestion.id)) {
          removeList.push(specialEntityQuestion.id);
        }
      }
      // foreign 2,3,4
      if (foreignPersonStatusRepresentations) {
        for (const foreignPersonQuestion of foreignPersonStatusRepresentations) {
          if (
            foreignPersonQuestion.id === iqID.id_foreign_person_2 ||
            foreignPersonQuestion.id === iqID.id_foreign_person_3 ||
            foreignPersonQuestion.id === iqID.id_foreign_person_4
          ) {
            if (this.questionIsAnswered(foreignPersonQuestion.id)) {
              removeList.push(foreignPersonQuestion.id);
            }
          }
        }
      }
      if (foreignPersonStatusRepresentations2) {
        for (const foreignPersonQuestion2 of foreignPersonStatusRepresentations2) {
          if (
            foreignPersonQuestion2.id === iqID.id_foreign_person_2_1 ||
            foreignPersonQuestion2.id === iqID.id_foreign_person_2_2 ||
            foreignPersonQuestion2.id === iqID.id_foreign_person_2_or
          ) {
            if (this.questionIsAnswered(foreignPersonQuestion2.id)) {
              removeList.push(foreignPersonQuestion2.id);
            }
          }
        }
      }
      // qualifiedPurchaser 3,4,5,6
      for (const qualifiedPurchaserQuestion of qualifiedPurchaserRepresentations) {
        if (
          qualifiedPurchaserQuestion.id ===
            iqID.id_purchase_representations_3 ||
          qualifiedPurchaserQuestion.id ===
            iqID.id_purchase_representations_4 ||
          qualifiedPurchaserQuestion.id ===
            iqID.id_purchase_representations_5 ||
          qualifiedPurchaserQuestion.id === 
            iqID.id_purchase_representations_6 ||
          qualifiedPurchaserQuestion.id === 
            iqID.id_purchase_representations_7
        ) {
          if (this.questionIsAnswered(qualifiedPurchaserQuestion.id)) {
            removeList.push(qualifiedPurchaserQuestion.id);
          }
        }
      }
      // accreditedInvestorReps 3,4,5,6,7,8,
      for (const accreditedInvestorQuestion of accreditedInvestorRepresentations) {
        if (
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_3 ||
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_4 ||
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_5 ||
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_6 ||
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_7 ||
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_8 ||
          accreditedInvestorQuestion.id === iqID.id_investor_representations_9
        ) {
          if (this.questionIsAnswered(accreditedInvestorQuestion.id)) {
            removeList.push(accreditedInvestorQuestion.id);
          }
        }
      }
    } else if (!_isNaturalPerson) {
      // foreign 1 ??
      if (foreignPersonStatusRepresentations) {
        for (const foreignPersonQuestion of foreignPersonStatusRepresentations) {
          if (foreignPersonQuestion.id === iqID.id_foreign_person_1) {
            if (this.questionIsAnswered(foreignPersonQuestion.id)) {
              removeList.push(foreignPersonQuestion.id);
            }
          }
        }
      }

      // qualifiedPurchaser 1
      for (const qualifiedPurchaserQuestion of qualifiedPurchaserRepresentations) {
        if (
          qualifiedPurchaserQuestion.id === iqID.id_purchase_representations_1
        ) {
          if (
            this.questionIsAnswered(qualifiedPurchaserQuestion.id) &&
            !isEntityIRA
          ) {
            removeList.push(qualifiedPurchaserQuestion.id);
          }
        }
      }
      // accreditedInvestorReps 1,2
      for (const accreditedInvestorQuestion of accreditedInvestorRepresentations) {
        if (
          accreditedInvestorQuestion.id ===
            iqID.id_investor_representations_1 ||
          accreditedInvestorQuestion.id === iqID.id_investor_representations_2
        ) {
          if (this.questionIsAnswered(accreditedInvestorQuestion.id)) {
            removeList.push(accreditedInvestorQuestion.id);
          }
        }
      }
    }
    return removeList;
  };

  evaluateMultiSelectQuestions = (
    _isNaturalPerson,
    _currentVersionQuestions
  ) => {
    const {
      questionnaireContext: {
        investorFund: { questionnaire: currentSavedQuestionnaire },
        fund,
      },
    } = this.props;
    const {
      accreditedInvestorRepresentations,
      qualifiedClientRepresentations,
      qualifiedPurchaserRepresentations,
      foreignPersonStatusRepresentations,
      foreignPersonStatusRepresentations2,
      identificationofSpecialEntities,
      wiringBankStatus,
      exemptedEntityStatus,
    } = _currentVersionQuestions;
    const optionalsList = [];
    const removeList = []; // answers that need to be removed entirely , verify its not  the same behavior from clearAnswer list
    const valueUpdateList = [];
    const disabledList = []; // list of what should be enabled or disabled in the questionnaire

    let isEntityIRA = false;
    const generalInfoJSON = JSON.parse(sessionStorage.getItem('generalInfo'));
    if (generalInfoJSON && generalInfoJSON.types) {
      isEntityIRA = generalInfoJSON.types.includes('IRA');
    }

    if (
      currentSavedQuestionnaire &&
      currentSavedQuestionnaire.questions &&
      Object.entries(_currentVersionQuestions).length
    ) {
      const wiringBank1 = this.parseQuestionDataFromId(
        iqID.id_wiring_bank_status_1
      );
      if (!wiringBank1 || wiringBank1.answer === 'Yes') {
        const wiringBank1Opt = this.parseQuestionDataFromId(
          iqID.id_wiring_bank_status_1_opt
        );
        if (wiringBank1Opt) {
          removeList.push(iqID.id_wiring_bank_status_1_opt);
        }
      }

      const id_investor_status_5_a = this.parseQuestionDataFromId(
        iqID.id_investor_status_5_a
      );
      const id_investor_status_5_b = this.parseQuestionDataFromId(
        iqID.id_investor_status_5_b
      );
      const id_investor_status_5_c = this.parseQuestionDataFromId(
        iqID.id_investor_status_5_c
      );
      const id_investor_status_5_d = this.parseQuestionDataFromId(
        iqID.id_investor_status_5_d
      );
      const id_investor_status_5_e = this.parseQuestionDataFromId(
        iqID.id_investor_status_5_e
      );

      if (
        id_investor_status_5_a &&
        id_investor_status_5_a.answer === 'No' &&
        id_investor_status_5_b &&
        id_investor_status_5_b.answer === 'No' &&
        id_investor_status_5_c &&
        id_investor_status_5_c.answer === 'No' &&
        id_investor_status_5_d &&
        id_investor_status_5_d.answer === 'No' &&
        id_investor_status_5_e &&
        id_investor_status_5_e.answer === 'No'
      ) {
        removeList.push(iqID.id_investor_status_5_f);
      }

      // if the entity changes clear all needed questions
      const id_investor_status_1_Data = this.parseQuestionDataFromId(
        iqID.id_investor_status_1
      );

      if (
        _isNaturalPerson &&
        id_investor_status_1_Data &&
        id_investor_status_1_Data.answer === 'No'
      ) {
        valueUpdateList.push({ id: iqID.id_investor_status_1, value: 'Yes' });
      } else if (
        !_isNaturalPerson &&
        id_investor_status_1_Data &&
        id_investor_status_1_Data.answer === 'Yes'
      ) {
        valueUpdateList.push({ id: iqID.id_investor_status_1, value: 'No' });
      }


       const id_covered_investor_status_or_Data = this.parseQuestionDataFromId(
        iqID.id_covered_investor_status_or
      );   

      const id_restricted_person_status_or_Data = this.parseQuestionDataFromId(
        iqID.id_restricted_person_status_or
      );   

       const { questionnaireContext } = this.props;
        const SROne = questionnaireContext.currentVersion && questionnaireContext.currentVersion === SROneV1;

      if (SROne) {

        if (_isNaturalPerson) {
          const toDisable = [
          iqID.id_sr_one_part_a_3,
          iqID.id_sr_one_part_a_3_or,
          iqID.id_sr_one_part_a_3_a,
          iqID.id_sr_one_part_a_3_b,
          iqID.id_sr_one_part_a_3_c,
          iqID.id_sr_one_part_b_3,
          iqID.id_sr_one_part_b_3_or,
          iqID.id_sr_one_part_b_3_a,
          iqID.id_sr_one_part_b_3_b,
          iqID.id_sr_one_part_b_3_c,
          iqID.id_sr_one_part_b_or,
          iqID.id_sr_one_part_a_or,
        ]
        disabledList.push(...toDisable);
        removeList.push(...toDisable);
        } else if (!_isNaturalPerson) {
          const toDisable = [
          iqID.id_sr_one_part_a_1,
          iqID.id_sr_one_part_a_2,
          iqID.id_sr_one_part_b_1,
          iqID.id_sr_one_part_b_2,
          iqID.id_sr_one_part_b_1_a,
          iqID.id_sr_one_part_b_1_b,
          iqID.id_sr_one_part_b_1_c,
          iqID.id_sr_one_part_b_1_text_note,
          ];
         disabledList.push(...toDisable);
         removeList.push(...toDisable);
        }
        
      }


      if(exemptedEntityStatus) {
        for (let i = 0; i < exemptedEntityStatus.length; i += 1) {
          if (_isNaturalPerson) {
              disabledList.push(exemptedEntityStatus[i].id);
            }
          else if (!_isNaturalPerson &&
            id_covered_investor_status_or_Data && 
            id_covered_investor_status_or_Data.answer === 'Yes' &&
            id_restricted_person_status_or_Data && 
            id_restricted_person_status_or_Data.answer === 'Yes'
            ) {
              disabledList.push(exemptedEntityStatus[i].id);
          }  
        }
      }

      if (accreditedInvestorRepresentations) {
        for (let i = 0; i < accreditedInvestorRepresentations.length; i += 1) {
          const question = this.parseQuestionDataFromId(
            accreditedInvestorRepresentations[i].id
          );

          if (_isNaturalPerson) {
            if (
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_3 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_4 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_5 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_6 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_6_extra ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_7 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_8 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_9 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_11
            ) {
              if (question && question.answer === 'Yes') {
                removeList.push(accreditedInvestorRepresentations[i].id);
              }
            }
          }
          if (!_isNaturalPerson) {
            if (
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_1 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_2 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_10
            ) {
              if (question && question.answer === 'Yes') {
                removeList.push(accreditedInvestorRepresentations[i].id);
              }
            }
          }
        }
        for (let i = 0; i < accreditedInvestorRepresentations.length; i += 1) {
          if (_isNaturalPerson) {
            if (
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_3 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_4 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_5 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_6 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_6_extra ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_7 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_8 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_9 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_11
            ) {
              disabledList.push(accreditedInvestorRepresentations[i].id);
            }
          }
          if (!_isNaturalPerson) {
            if (
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_1 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_2 ||
              accreditedInvestorRepresentations[i].id ===
                iqID.id_investor_representations_10
            ) {
              disabledList.push(accreditedInvestorRepresentations[i].id);
            }
          }
          const id_investor_representations_or_Data =
            this.parseQuestionDataFromId(iqID.id_investor_representations_or);
          if (
            id_investor_representations_or_Data &&
            id_investor_representations_or_Data.answer === 'Yes'
          ) {
            accreditedInvestorRepresentations.forEach((item) => {
              if (item.id !== iqID.id_investor_representations_or) {
                if (
                  !disabledList.includes(
                    'accredited-investor-principal-introduction'
                  )
                ) {
                  disabledList.push(
                    'accredited-investor-principal-introduction'
                  );
                }
              }
            });
          }
        }
      }

      if (foreignPersonStatusRepresentations) {
        for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
          if (
            _isNaturalPerson &&
            (foreignPersonStatusRepresentations[i].id ===
              iqID.id_foreign_person_2 ||
              foreignPersonStatusRepresentations[i].id ===
                iqID.id_foreign_person_3 ||
              foreignPersonStatusRepresentations[i].id ===
                iqID.id_foreign_person_4)
          ) {
            disabledList.push(foreignPersonStatusRepresentations[i].id);
          } else if (
            !_isNaturalPerson &&
            foreignPersonStatusRepresentations[i].id ===
              iqID.id_foreign_person_1
          ) {
            disabledList.push(foreignPersonStatusRepresentations[i].id);
          }
        }
      }

      if (foreignPersonStatusRepresentations2) {
        for (
          let i = 0;
          i < foreignPersonStatusRepresentations2.length;
          i += 1
        ) {
          if (
            _isNaturalPerson &&
            (foreignPersonStatusRepresentations2[i].id ===
              iqID.id_foreign_person_2_1 ||
              foreignPersonStatusRepresentations2[i].id ===
                iqID.id_foreign_person_2_2 ||
              foreignPersonStatusRepresentations2[i].id ===
                iqID.id_foreign_person_2_or)
          ) {
            disabledList.push(foreignPersonStatusRepresentations2[i].id);
          }
        }
      }

      if (qualifiedPurchaserRepresentations) {
        let isQualifiedPurchaser = false;
        for (const qualifiedPurchaserQuestion of qualifiedPurchaserRepresentations) {
          const questionData = this.parseQuestionDataFromId(
            qualifiedPurchaserQuestion.id
          );
          if (
            qualifiedPurchaserQuestion.id !==
              iqID.id_purchase_representations_or &&
            questionData &&
            questionData.answer === 'Yes'
          ) {
            isQualifiedPurchaser = true;
          }
        }
        if (isQualifiedPurchaser) {
          qualifiedClientRepresentations.forEach((item) => {
            disabledList.push(item.id);
            // if any questions are answered from clientReps need to remove
            if (this.questionIsAnswered(item.id)) {
              removeList.push(item.id);
            }
          });
          disabledList.push('qualified-client-principal-introduction');
        }

        for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
          if (_isNaturalPerson) {
            if (
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_3 ||
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_4 ||
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_5 ||
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_6 ||
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_7
            ) {
              disabledList.push(qualifiedPurchaserRepresentations[i].id);
            }
          }
          if (!_isNaturalPerson) {
            if (
              qualifiedPurchaserRepresentations[i].id ===
                iqID.id_purchase_representations_1 &&
              !isEntityIRA
            ) {
              disabledList.push(qualifiedPurchaserRepresentations[i].id);
            }
          }
          const id_purchase_representations_or_Data =
            this.parseQuestionDataFromId(iqID.id_purchase_representations_or);
          if (
            id_purchase_representations_or_Data &&
            id_purchase_representations_or_Data.answer === 'Yes'
          ) {
            qualifiedPurchaserRepresentations.forEach((item) => {
              if (item.id !== iqID.id_purchase_representations_or) {
                if (
                  !disabledList.includes(
                    'qualified-purchaser-principal-introduction'
                  )
                ) {
                  disabledList.push(
                    'qualified-purchaser-principal-introduction'
                  );
                }
              }
            });
          }
        }
      }

      // checkBadActorQuestionAbility =()=> availability? FIXME
      // check this part better looks likes if id_investor_status_5_f, and the rest isnt selected the it disables the other set of questions

      if (identificationofSpecialEntities) {
        // special entities optionals logic
        const id_special_entities_1_b_Data = this.parseQuestionDataFromId(
          iqID.id_special_entities_1_b
        );
        if (
          id_special_entities_1_b_Data &&
          id_special_entities_1_b_Data.answer === 'Yes'
        ) {
          optionalsList.push(iqID.id_special_entities_1_b_opt);
        }

        let clearPotentialLookthroughEntity_e_opt = true;
        for (const specialEntity of identificationofSpecialEntities) {
          if (specialEntity.id.includes(iqID.id_special_entities_2)) {
            const currentQuestion = this.parseQuestionDataFromId(
              specialEntity.id
            );
            if (currentQuestion && currentQuestion.answer === 'Yes') {
              clearPotentialLookthroughEntity_e_opt = false;
              if (!optionalsList.includes(iqID.id_special_entities_2_e_opt)) {
                optionalsList.push(iqID.id_special_entities_2_e_opt);
              }
            }
          }
        }
        if (clearPotentialLookthroughEntity_e_opt) {
          const special2eOpt = this.parseQuestionDataFromId(
            iqID.id_special_entities_2_e_opt
          );
          if (special2eOpt) {
            removeList.push(iqID.id_special_entities_2_e_opt);
          }
        }

        const { questionnaireContext } = this.props;
        const isAccelMain = questionnaireContext.currentVersion && questionnaireContext.currentVersion === AccelMainV1;

        const erisaA = this.parseQuestionDataFromId(
          iqID.id_special_entities_1_a
        );
        const erisaB = this.parseQuestionDataFromId(
          iqID.id_special_entities_1_b
        );
        const erisaPerc = this.parseQuestionDataFromId(
          iqID.id_special_entities_1_b_opt
        );

        if (erisaA && erisaA.answer === 'Yes' && !isAccelMain) {
          disabledList.push(iqID.id_special_entities_1_b);
          if (erisaB) {
            removeList.push(iqID.id_special_entities_1_b);
          }
          if (!erisaPerc || erisaPerc.answer !== '100') {
            valueUpdateList.push({
              id: iqID.id_special_entities_1_b_opt,
              value: '100',
            });
          }
        }

        if (erisaA && erisaA.answer === 'No') {
          if (
            (erisaPerc && erisaPerc.answer === '100') ||
            (erisaPerc && erisaB.answer === 'No')
          ) {
            removeList.push(iqID.id_special_entities_1_b_opt);
          }
        }
        const specialEntity9A = this.parseQuestionDataFromId(
          iqID.id_special_entities_9_a
        );
        if (specialEntity9A && specialEntity9A.answer === 'No') {
          disabledList.push(iqID.id_special_entities_9_b);
          if (this.questionIsAnswered(iqID.id_special_entities_9_b)) {
            removeList.push(iqID.id_special_entities_9_b);
          }

          disabledList.push(iqID.id_special_entities_9_c);
          if (this.questionIsAnswered(iqID.id_special_entities_9_c)) {
            removeList.push(iqID.id_special_entities_9_c);
          }
        }
        if (_isNaturalPerson) {
          identificationofSpecialEntities.forEach((item) => {
            // this part looks odd check it better
            // already managed on cleanup method ?
            if (this.parseQuestionDataFromId(item.id)) {
              removeList.push(item.id);
            }
            disabledList.push(item.id);
            disabledList.push('special-entities-introduction-p');
            disabledList.push('special-entities-lost-descrip-quest');
          });
          disabledList.push('id_special_entities_1-title');
          disabledList.push('id_special_entities_2-title');
          disabledList.push('id_special_entities_3-title');
          disabledList.push('id_special_entities_9-title');
        }
      }
      if (wiringBankStatus) {
        const id_wiring_bank_status_1_Data = this.parseQuestionDataFromId(
          iqID.id_wiring_bank_status_1
        );
        if (
          id_wiring_bank_status_1_Data &&
          id_wiring_bank_status_1_Data.answer === 'No'
        ) {
          optionalsList.push(iqID.id_wiring_bank_status_1_opt);
        }
      }

      const cleanupListFromEntity = this.cleanupInvalidQuestions(
        _isNaturalPerson,
        _currentVersionQuestions
      );
      const _removeList = [
        ...new Set([...removeList, ...cleanupListFromEntity]),
      ];
      const _disabledList = [...new Set(disabledList)];
      const _valueUpdateList = [...new Set(valueUpdateList)];
      const _optionalsList = [...new Set(optionalsList)];

      if (_removeList.length) {
        this.removeBulkMultiSelectFormAnswer(_removeList);
      }
      this.props.setMultiSelectFormMetadata({
        disabled: _disabledList,
        valueUpdate: _valueUpdateList,
        optionals: _optionalsList,
      });
    }
  };

  updateMultiSelectFormAnswer = async (_identifier, _answer, _optionValue) => {
    const { questionnaireContext } = this.props;
    // description could be found in currentVersionQuestions or in iq.questions.array ?
    const description = '';
    const answerIdentifier = () => {
      if (_answer === 'Yes') {
        return `${_identifier}_yes`;
      }
      if (_answer === 'No') {
        return `${_identifier}_no`;
      }
      return '';
    };

    const updateData = {
      description,
      descriptionIdentifier: _identifier,
      answerIdentifier: answerIdentifier(),
      answer: _answer,
      description: _optionValue ? _optionValue : '',
    };
    if (questionnaireContext.multiAccess) {
      updateData.limitedPartnerFund = questionnaireContext.investorFund._id;
    } else {
      updateData.investorFund = questionnaireContext.investorFund._id;
    }

    try {
      const { data } = await axios.post(
        `${API_URL()}/access/questionnaire/field`,
        updateData,
        SERVICE_CONFIG_QUESTIONNAIRE()
      );
      if (data.success) {
        this.props.setInvestorFund(data.investorFund);
      } else {
        this.props.renderNotification(
          'error',
          'Error updating questionnaire field',
          data.message
        );
      }
    } catch (err) {
      this.props.logQuestionnaireError(err, this.props.questionnaireContext);
    }
  };
  updateSingleSelectFormAnswer = async (_identifier, _answer) => {
    const { questionnaireContext } = this.props;
    // description could be found in currentVersionQuestions or in iq.questions.array ?
    const description = '';
    const answerIdentifier = () => {
      if (_answer === 'Yes') {
        return `${_identifier}_yes`;
      }
      if (_answer === 'No') {
        return `${_identifier}_no`;
      }
      return '';
    };

    const updateData = {
      description,
      descriptionIdentifier: _identifier,
      answerIdentifier: answerIdentifier(),
      answer: _answer,
    };
    if (questionnaireContext.multiAccess) {
      updateData.limitedPartnerFund = questionnaireContext.investorFund._id;
    } else {
      updateData.investorFund = questionnaireContext.investorFund._id;
    }
    try {
      const { data } = await axios.post(
        `${API_URL()}/access/questionnaire/field`,
        updateData,
        SERVICE_CONFIG_QUESTIONNAIRE()
      );
      if (data.success) {
        this.props.setInvestorFund(data.investorFund);
      } else {
        this.props.renderNotification(
          'error',
          'Error updating questionnaire field',
          data.message
        );
      }
    } catch (err) {
      this.props.logQuestionnaireError(err, this.props.questionnaireContext);
    }
  };

  removeMultiSelectFormAnswer = async (
    _identifier,
    _questionnaireIdentifier
  ) => {
    try {
      const { data } = await axios.post(
        `${API_URL()}/access/questionnaire/field/remove`,
        {
          descriptionIdentifier: _identifier,
          questionnaire: _questionnaireIdentifier,
        },
        SERVICE_CONFIG_QUESTIONNAIRE()
      );
      if (data.success) {
        this.props.setInvestorFund(data.investorFund);
      } else {
        this.props.renderNotification(
          'error',
          'Error removing questionnaire field',
          data.message
        );
      }
    } catch (err) {
      this.props.logQuestionnaireError(err, this.props.questionnaireContext);
    }
  };
  removeBulkMultiSelectFormAnswer = async (_identifierList) => {
    const { questionnaireContext } = this.props;
    try {
      const signatureTab = window.location.href.includes('signatures');
      if (!signatureTab) {
        const { data } = await axios.post(
          `${API_URL()}/access/questionnaire/fields/remove`,
          {
            fields: _identifierList,
            questionnaire: questionnaireContext.investorFund.questionnaire._id,
          },
          SERVICE_CONFIG_QUESTIONNAIRE()
        );

        if (data.success) {
          this.props.setInvestorFund(data.investorFund);
          // dispatch removing ???????
        } else {
          this.props.renderNotification(
            'error',
            'Error removing bulk questionnaire fields',
            data.message
          );
        }
      }
    } catch (err) {
      this.props.logQuestionnaireError(err, this.props.questionnaireContext);
    }
  };

  triggerNextStep = (e) => {
    const targetContainerId = e.target.getAttribute('data-position');
    const nextStep = Number(targetContainerId.split('step-')[1]);
    this.handleStepRedirect(e, nextStep);
  };
  nextStep = (e) => {
    const { questionnaireContext } = this.props;
    this.handleStepRedirect(e, questionnaireContext.currentStep + 1);
  };
  saveStepStorageInformation = (followingStep) => {
    // need to check why on FlowHome general information was getting saved along saving this data most likely to save the sessionStorage generalInformation present, looks like it happen only when the last step was the general information
    sessionStorage.setItem(iq_last_step_token, followingStep);
  };
  sessionStorageCleanup = async () => {
    const res = await axios.post(
      `${API_URL()}/access/leaveQuestionnaire`,
      {
        iq: sessionStorage.getItem(iq_cb_token),
        user: sessionStorage.getItem(iq_cb_user_token),
      },
      SERVICE_CONFIG_QUESTIONNAIRE()
    );
    sessionStorage.removeItem(iq_last_step_token);
    sessionStorage.removeItem(iq_cb_token);
    sessionStorage.removeItem(iq_cb_user_token);
    sessionStorage.removeItem(iq_general_info_token);
  };
  leaveQuestionnaire = (e) => {
    e.preventDefault();
    // FIXME need to save all related Progress here
    // generalInfo and Questionnaire
    const { questionnaireContext, history } = this.props;
    // eslint-disable-next-line
    if (window.confirm('Are you sure you want to leave the Questionnaire ?')) {
      this.sessionStorageCleanup();
      history.push(`/access/login?f=${questionnaireContext.fund._id}`);
      this.closeButton.click();
    }
  };

  handleIQAccessExpiration = () => {
    const { questionnaireContext, history } = this.props;
    this.props.isQuestionnaireExpired(false);
    this.sessionStorageCleanup();
    history.push(`/access/login?f=${questionnaireContext.fund._id}`);
  };

  saveGeneralInformation = async (_payload) => {
    this.props.setSavingInfo(true);
    try {
      const res = await axios.post(
        `${API_URL()}/access/generalInfo`,
        _payload,
        SERVICE_CONFIG_QUESTIONNAIRE()
      );
      if (res.data.success) {
        this.props.setInvestorFund(res.data.investorFund);
        this.props.setSavingInfo(false);
        this.fetchInvestorInfo();
      }
    } catch (err) {
      this.props.setSavingInfo(false);
      this.props.logQuestionnaireError(err, this.props.questionnaireContext);
    }
  };

  handleStepRedirect = async (event, nextStep) => {
    const { history, questionnaireContext } = this.props;
    const currentIQVersion = questionnaireContext.investorFund && 
      questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.iqVersion;
    event.preventDefault();
    this.props.setCurrentStep(nextStep);
    this.saveStepStorageInformation(nextStep);

    this.saveGeneralInformation(
      JSON.parse(sessionStorage.getItem('generalInfo'))
    );

    if (nextStep !== 3) {
      if (questionnaireContext.signaturesMethod !== '') {
        this.props.setSignaturesMethod('');
      }
    }
    if (currentIQVersion.includes(AccelV1) || 
        currentIQVersion.includes(AccelV2) || 
        currentIQVersion.includes(AccelV3)) {
      switch (nextStep) {
      case 0:
        history.push('/access/home/instructions');
        break;
      case 1:
        history.push('/access/home/letter');
        break;
      case 2:
        history.push('/access/home/information');
        break;
      case 3:
        history.push('/access/home/questionnaire');
        break;
      case 4:
        await confirmDocCanBeSigned(this.props);
        history.push('/access/home/signatures');
        break;
      default:
        history.push('/access/home/instructions');
        break;
      }
    } else if (currentIQVersion.includes(AccelEtpnReturningV1) || 
        currentIQVersion.includes(AccelEtpnReturningV2)) {
        switch (nextStep) {
          case 0:
            history.push('/access/home/information');
          break;
          case 1:
            confirmReturningInvestorCanSign(this.props);
            history.push('/access/home/signatures');
            break;
          default:
            history.push('/access/home/information');
            break;
        }
    } else {
      switch (nextStep) {
      case 0:
        history.push('/access/home/instructions');
        break;
      case 1:
        history.push('/access/home/information');
        break;
      case 2:
        history.push('/access/home/questionnaire');
        break;
      case 3:
        await confirmDocCanBeSigned(this.props);
        history.push('/access/home/signatures');
        break;
      default:
        history.push('/access/home/instructions');
        break;
      }
    }
    
    window.scrollTo(0, 0);
  };

  accessHomeRoutes = () => {
    const { questionnaireContext } = this.props;
    let IQ_VERSION =
      questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.iqVersion;
    if (!IQ_VERSION) {
      IQ_VERSION = LimitedPartnerV1;
    }
    const commonProps = {
      handleRedirect: this.handleStepRedirect,
      logQuestionnaireError: this.props.logQuestionnaireError,
      setFund: this.props.setFund,
      setInvestor: this.props.setInvestor,
      setInvestorFund: this.props.setInvestorFund,
      setCurrentStep: this.props.setCurrentStep,
      isQuestionnaireExpired: this.props.isQuestionnaireExpired,
      setSavingInfo: this.props.setSavingInfo,
      setGeneralInformationTrigger: this.props.setGeneralInformationTrigger,
      isSignaturesEnabled: this.props.isSignaturesEnabled,
      setValidationRequiredParts: this.props.setValidationRequiredParts,
      showSignaturesInstructions: this.props.showSignaturesInstructions,
      renderNotification: this.props.renderNotification,
    };
    let IQ_current_version = null;
    let INSTRUCTIONS_current_version = null;
    let GENERAL_INFO_current_version = null;
    let INTEREST_LETTER_current_version = null;
    switch (IQ_VERSION) {
      case LimitedPartnerV1:
        IQ_current_version = (
          <QuestionnairePageLPv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLP
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedPartnerV2:
        IQ_current_version = (
          <QuestionnairePageLPv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLP
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedPartnerV3:
        IQ_current_version = (
          <QuestionnairePageLPv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLP
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedPartnerV4:
        IQ_current_version = (
          <QuestionnairePageLPv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedPartnerV5:
        IQ_current_version = (
          <QuestionnairePageLPv5
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv5
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedPartnerV6:
        IQ_current_version = (
          <QuestionnairePageLPv6
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv6
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedPartnerV7:
        IQ_current_version = (
          <QuestionnairePageLPv7
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv7
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedPartnerV8:
        IQ_current_version = (
          <QuestionnairePageLPv8
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv8
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedPartnerV8_1:
        IQ_current_version = (
          <QuestionnairePageLPv8_1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv8_1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedPartnerV9:
        IQ_current_version = (
          <QuestionnairePageLPv9
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv9
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case LimitedPartnerV10:
        IQ_current_version = (
          <QuestionnairePageLPv10
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv10
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
        
      case LimitedPartnerV11:
        IQ_current_version = (
          <QuestionnairePageLPv11
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPv11
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break; 


      case FrazierV1:
        IQ_current_version = (
          <QuestionnairePageFrazierv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageFrazier {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLFrazierv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break; 
      
      case QuestaCapitalV1:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapital
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case QuestaCapitalV2:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapital
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case QuestaCapitalV3:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapitalv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case QuestaCapitalV4:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapitalv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case QuestaCapitalV5:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv5
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapitalv5
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case QuestaCapitalV6:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv6
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapitalv6
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case QuestaCapitalV7:
        IQ_current_version = (
          <QuestionnairePageQuestaCapitalv7
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageQuestaCapital {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageQuestaCapitalv7
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV1:
        IQ_current_version = (
          <QuestionnairePageLLCv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLC
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedLiabilityCompanyV2:
        IQ_current_version = (
          <QuestionnairePageLLCv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLC
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedLiabilityCompanyV3:
        IQ_current_version = (
          <QuestionnairePageLLCv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedLiabilityCompanyV4:
        IQ_current_version = (
          <QuestionnairePageLLCv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV5:
        IQ_current_version = (
          <QuestionnairePageLLCv5
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv5
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV6:
        IQ_current_version = (
          <QuestionnairePageLLCv6
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv6
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV6_1:
        IQ_current_version = (
          <QuestionnairePageLLCv6_1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv6_1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV7:
        IQ_current_version = (
          <QuestionnairePageLLCv7
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv7
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case LimitedLiabilityCompanyV8:
        IQ_current_version = (
          <QuestionnairePageLLCv8
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv8
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedLiabilityCompanyV9:
        IQ_current_version = (
          <QuestionnairePageLLCv9
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLLC {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLLCv9
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      
      case ForesiteV1:
        IQ_current_version = (
          <QuestionnairePageForesitev1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesite
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteV2:
        IQ_current_version = (
          <QuestionnairePageForesitev2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesite
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteV3:
        IQ_current_version = (
          <QuestionnairePageForesitev3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesitev3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteV4:
        IQ_current_version = (
          <QuestionnairePageForesitev4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesitev4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteV5:
        IQ_current_version = (
          <QuestionnairePageForesitev5
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesitev5
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case ForesiteV6:
        IQ_current_version = (
          <QuestionnairePageForesitev6
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesitev6
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case ForesiteV7:
        IQ_current_version = (
          <QuestionnairePageForesitev7
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesite {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesitev7
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case InitializedV1:
        IQ_current_version = (
          <QuestionnairePageInitializedv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitialized
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case InitializedV2:
        IQ_current_version = (
          <QuestionnairePageInitializedv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitialized
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case InitializedV3:
        IQ_current_version = (
          <QuestionnairePageInitializedv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitializedV3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case InitializedV4:
        IQ_current_version = (
          <QuestionnairePageInitializedv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitializedV4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case InitializedV5:
        IQ_current_version = (
          <QuestionnairePageInitializedv5
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitializedV5
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case InitializedV6:
        IQ_current_version = (
          <QuestionnairePageInitializedv6
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitializedV6
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case InitializedV7:
        IQ_current_version = (
          <QuestionnairePageInitializedv7
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageInitialized {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageInitializedV7
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case HCMV1:
        IQ_current_version = (
          <QuestionnairePageHCMv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageHCM {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageHCMv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case HCMV2:
        IQ_current_version = (
          <QuestionnairePageHCMv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageHCM {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageHCMv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case HCMV3:
        IQ_current_version = (
          <QuestionnairePageHCMv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageHCM {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageHCMv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case HCMV4:
        IQ_current_version = (
          <QuestionnairePageHCMv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageHCM {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageHCMv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteLabsV1:
        IQ_current_version = (
          <QuestionnairePageForesiteLabsv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesiteLabs {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesiteLabsv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case ForesiteLabsV2:
        IQ_current_version = (
          <QuestionnairePageForesiteLabsv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesiteLabs {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesiteLabsv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      case ForesiteLabsV3:
        IQ_current_version = (
          <QuestionnairePageForesiteLabsv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesiteLabs {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesiteLabsv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ForesiteLabsV4:
        IQ_current_version = (
          <QuestionnairePageForesiteLabsv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            removeBulkMultiSelectFormAnswer={
              this.removeBulkMultiSelectFormAnswer
            }
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = (
          <InstructionsPageForesiteLabs {...commonProps} />
        );
        GENERAL_INFO_current_version = (
          <GeneralInfoPageForesiteLabsv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LPABBRV1:
        IQ_current_version = (
          <QuestionnairePageLPAbbrv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPAbbrv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

        case LPABBRV1_1:
        IQ_current_version = (
          <QuestionnairePageLPAbbrv1_1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPAbbrv1_1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case LPABBRV2:
        IQ_current_version = (
          <QuestionnairePageLPAbbrv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPAbbrv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LPABBRV3:
        IQ_current_version = (
          <QuestionnairePageLPAbbrv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPAbbrv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case LPABBRV4:
        IQ_current_version = (
          <QuestionnairePageLPAbbrv4
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPAbbrv4
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case IOLARV1:
        IQ_current_version = (
          <QuestionnairePageIolarv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageIolar {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageIolarv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

       case IOLARV2:
        IQ_current_version = (
          <QuestionnairePageIolarv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageIolar {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageIolarv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case OSAGEV1:
        IQ_current_version = (
          <QuestionnairePageOSAGEv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageOSAGEv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case OSAGEV2:
        IQ_current_version = (
          <QuestionnairePageOSAGEv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageOSAGEv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case NewLimitedPartnerV1:
        IQ_current_version = (
          <QuestionnairePageNewLimitedPartnerv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageNewLimitedPartnerv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case NewLimitedPartnerV2:
        IQ_current_version = (
          <QuestionnairePageNewLimitedPartnerv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageNewLimitedPartnerv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case TcgCrossOverV1:
        IQ_current_version = (
          <QuestionnairePageTcgCrossOverv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageTcgCrossOver {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageTcgCrossOverv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case TcgCrossOverV2:
        IQ_current_version = (
          <QuestionnairePageTcgCrossOverv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageTcgCrossOver {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageTcgCrossOverv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case BITKRAFTV1:
        IQ_current_version = (
          <QuestionnairePageBitKraftv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageBitKraft {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageBitKraftv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case BITKRAFTV2:
        IQ_current_version = (
          <QuestionnairePageBitKraftv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageBitKraft {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageBitKraftv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case CaymanV1:
        IQ_current_version = (
          <QuestionnairePageCaymanv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageCayman {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageCaymanv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case CaymanV2:
        IQ_current_version = (
          <QuestionnairePageCaymanv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageCayman {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageCaymanv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case ElephantPartnerV1:
        IQ_current_version = (
          <QuestionnairePageElephantPartnerv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageElephantPartner {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageElephantPartnerv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
       case ElephantPartnerV2:
        IQ_current_version = (
          <QuestionnairePageElephantPartnerv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            updateSingleSelectFormAnswer={this.updateSingleSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageElephantPartner {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageElephantPartnerv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case FireworkV1:
        IQ_current_version = (
          <QuestionnairePageFireworkv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageFireworkv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

         case FireworkV2:
        IQ_current_version = (
          <QuestionnairePageFireworkv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageFireworkv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

        case PrometheanV1:
        IQ_current_version = (
          <QuestionnairePrometheanv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPagePrometheanv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

       case PrometheanV2:
        IQ_current_version = (
          <QuestionnairePrometheanv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageLP {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPagePrometheanv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AccelV1:
        IQ_current_version = (
          <QuestionnairePageAccelv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INTEREST_LETTER_current_version = (
        <InterestLetterPageAccelv1
         updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps} 
          />
        );

        INSTRUCTIONS_current_version = <InstructionsPageAccel {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AccelV2:
        IQ_current_version = (
          <QuestionnairePageAccelv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INTEREST_LETTER_current_version = (
        <InterestLetterPageAccelv2
         updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps} 
          />
        );

        INSTRUCTIONS_current_version = <InstructionsPageAccel {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case AccelV3:
        IQ_current_version = (
          <QuestionnairePageAccelv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INTEREST_LETTER_current_version = (
        <InterestLetterPageAccelv3
         updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps} 
          />
        );

        INSTRUCTIONS_current_version = <InstructionsPageAccel {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AccelMainV1:
        IQ_current_version = (
          <QuestionnairePageAccelMainv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageAccelMain {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelMainv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AccelEntrepreneursV1:
        IQ_current_version = (
          <QuestionnairePageAccelEntrepreneursv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageAccelEntrepreneurs {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelEntrepreneursv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AccelEtpnReturningV1:
        IQ_current_version = <div>none</div>
        INSTRUCTIONS_current_version = (
          <GeneralInfoPageAccelEtpnReturningv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />);
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelEtpnReturningv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        break;

      case AccelEtpnReturningV2:
        IQ_current_version = <div>none</div>
        INSTRUCTIONS_current_version = (
          <GeneralInfoPageAccelEtpnReturningv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />);
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAccelEtpnReturningv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        break;

      case LPMULTIFUNDV1:
        IQ_current_version = (
          <QuestionnairePageLPMultiFundv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLPMultiFund {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPMultiFundv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case LPMULTIFUNDV2:
        IQ_current_version = (
          <QuestionnairePageLPMultiFundv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLPMultiFundv2 {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPMultiFundv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

       case LPMULTIFUNDV3:
        IQ_current_version = (
          <QuestionnairePageLPMultiFundv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLPMultiFundv3 {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLPMultiFundv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      case AudereV1:
        IQ_current_version = (
          <QuestionnairePageAuderev1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageAudere {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAuderev1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case AudereV2:
        IQ_current_version = (
          <QuestionnairePageAuderev2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsPageAudere {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageAuderev2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
        
      case LimitedPartnershipMFV1:
        IQ_current_version = (
          <QuestionnairePageLimitedPartnershipMFv1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLimitedPartnershipMF {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLimitedPartnershipMFv1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;

      case LimitedPartnershipMFV2:
        IQ_current_version = (
          <QuestionnairePageLimitedPartnershipMFv2
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLimitedPartnershipMF {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLimitedPartnershipMFv2
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
       case LimitedPartnershipMFV3:
        IQ_current_version = (
          <QuestionnairePageLimitedPartnershipMFv3
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsLimitedPartnershipMF {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageLimitedPartnershipMFv3
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
        
       case SROneV1:
        IQ_current_version = (
          <QuestionnairePageSROnev1
            {...commonProps}
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            history={this.props.history}
          />
        );
        INSTRUCTIONS_current_version = <InstructionsSROne {...commonProps} />;
        GENERAL_INFO_current_version = (
          <GeneralInfoPageSROnev1
            updateMultiSelectFormAnswer={this.updateMultiSelectFormAnswer}
            {...commonProps}
          />
        );
        break;
      
      default:
        console.error('IQ version needs to be provided');
        break;
    }

    const pathRender = [ 
      {
        path: '/access/home/instructions',
        exact: true,
        render: INSTRUCTIONS_current_version,
      },
      {
        path: '/access/home/information',
        exact: true,
        render: GENERAL_INFO_current_version,
      },
      {
        path: '/access/home/questionnaire',
        exact: true,
        render: IQ_current_version,
      },
      {
        path: '/access/home/signatures',
        exact: true,
        render: <SignaturesPage {...commonProps} />,
      },
      {
        path: '*',
        component: NotFoundPage,
      },
    ];

    if (IQ_VERSION.includes(AccelV1) || 
        IQ_VERSION.includes(AccelV2) || 
        IQ_VERSION.includes(AccelV3)) {
        pathRender.splice(1, 0,  
          {
          path: '/access/home/letter',
          exact: true,
          render: INTEREST_LETTER_current_version,
          }
        )
    }

    if (IQ_VERSION.includes(AccelEtpnReturningV1) || IQ_VERSION.includes(AccelEtpnReturningV2)) {
      pathRender.splice(0, 1);
      pathRender.splice(1, 1);
    } 

    return pathRender
      
  };

  // Steppers component & progress bar per IQ version
    renderQuestionnaire = () => {
      const { questionnaireContext } = this.props;
      const IQ_VERSION = questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.iqVersion;

      switch (IQ_VERSION) {
        case AccelEtpnReturningV1:
        case AccelEtpnReturningV2:
          return {
            QuestionnaireStepper: QuestionnaireStepsAccelEtpnReturning,
            ProgressBar: ProgressAccelEtpnReturning,
          }
        case AccelV1:
        case AccelV2:
        case AccelV3:
          return {
            QuestionnaireStepper: QuestionnaireStepsAccel,
            ProgressBar: ProgressAccel,
          }
        default:
          return {
            QuestionnaireStepper: QuestionnaireSteps,
            ProgressBar: Progress,
          }
      }
    }

    



  renderHomeRoutes = (saving) =>
    saving ? (
      <div className="loader">
        <Switch>
          {this.accessHomeRoutes().map((route) =>
            this.routeWithSubRoutes(route)
          )}
        </Switch>
      </div>
    ) : (
      <Switch>
        {this.accessHomeRoutes().map((route) => this.routeWithSubRoutes(route))}
      </Switch>
    );

  routeWithSubRoutes = (route) => (
    <Route
      key={_.uniqueId()}
      exact={route.exact || false}
      path={route.path}
      render={(props) =>
        route.component ? (
          <route.component {...props} routes={route.routes} />
        ) : (
          route.render
        )
      }
    />
  );
  handleBrowserClose = (e) => {
    e.preventDefault();
  };

  render() {
    const { questionnaireContext } = this.props;
    const { QuestionnaireStepper, ProgressBar } = this.renderQuestionnaire();

    if (questionnaireContext.initializationComplete) {
      return (
        <Beforeunload onBeforeunload={this.handleBrowserClose}>
          <div className="main-container">
            <div className="container">
              <Helmet title="Fund Flow Home" />

              <ProgressBar step={questionnaireContext.currentStep} />
              <QuestionnaireStepper
                currentStep={questionnaireContext.currentStep}
                clickHandler={this.triggerNextStep}
              /> 

              {/* fixme savingGeneral Info may need a proper naming to be more generic */}
              {questionnaireContext.currentVersionInitialized ? (
                this.renderHomeRoutes(questionnaireContext.savingGeneralInfo)
              ) : (
                <p>...</p>
              )}
            </div>
            {questionnaireContext.questionnaireAccessExpired ? (
              <ReactConfirmAlert
                title="Session Expired"
                message="Your IQ access has expired. Please log back in."
                confirmLabel="Accept"
                cancelLabel="Cancel"
                onConfirm={this.handleIQAccessExpiration}
                onCancel={this.handleIQAccessExpiration}
              />
            ) : null}
            <Popup
              data={{
                id: 'saveLaterPopup',
                popupType: 'info',
                title: 'Progress Saved',
              }}
              leaveAction={this.leaveQuestionnaire}
            />
            <a
              href="#/"
              className="hidden"
              data-dismiss="modal"
              ref={(close) => {
                this.closeButton = close;
              }}
            >
              Close popup
            </a>
          </div>
        </Beforeunload>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/access/login',
        }}
      />
    );
  }
}

/**
 * @param {Gunderson.Reducer} state
*/

const mapStateToProps = (state) => ({
  questionnaireContext: state.questionnaireExperience,
});

/**
 * @type {Gunderson.Connector<{}, Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
   * @param {Gunderson.Dispatch} dispatch
  */
  (dispatch) => ({
    logQuestionnaireError: (_error, _context) =>
      handleQuestionnaireError(_error, _context, dispatch),
    setVersion: (_version) =>
      dispatch({ type: IQ_SET_CURRENT_VERSION, currentVersion: _version }),
    setVersionQuestions: (_versionQuestions) =>
      dispatch({
        type: IQ_SET_CURRENT_VERSION_QUESTIONS,
        currentVersionQuestions: _versionQuestions,
      }),
    setFund: (_fund) => dispatch({ type: IQ_SET_FUND, fund: _fund }),
    setSignaturesMethod: (_method) =>
      dispatch({ type: IQ_SET_SIGNATURES_METHOD, signaturesMethod: _method }),
    setInvestor: (_investor) =>
      dispatch({ type: IQ_SET_INVESTOR, investor: _investor }),
    setInvestorFund: (_investorFund) =>
      dispatch({ type: IQ_SET_INVESTOR_FUND, investorFund: _investorFund }),
    setCurrentStep: (_step) =>
      dispatch({ type: IQ_SET_CURRENT_STEP, currentStep: _step }),
    setMultiSelectFormMetadata: (_metadata) =>
      dispatch({
        type: IQ_SET_MULTI_SELECT_FORM_METADATA,
        multiSelectFormMetadata: _metadata,
      }),
    isQuestionnaireExpired: (_expired) =>
      dispatch({
        type: IQ_SHOW_EXPIRATION_NOTICE,
        questionnaireAccessExpired: _expired,
      }),
    setSavingInfo: (_saving) =>
      dispatch({
        type: IQ_SET_TRIGGER_GENERAL_INFORMATION,
        updateGeneralInformationTrigger: _saving,
      }),
    isSignaturesEnabled: (_enabled) =>
      dispatch({ type: IQ_IS_SIGNATURES_ENABLED, signaturesEnabled: _enabled }),
    isNaturalPerson: (_is) =>
      dispatch({ type: IQ_IS_NATURAL_PERSON, isNaturalPerson: _is }),
    setValidationRequiredParts: (_requiredItems) =>
      dispatch({
        type: IQ_SET_VALIDATION_REQUIRED_PARTS,
        missingQuestionnaireParts: _requiredItems,
      }),
    showSignaturesInstructions: (_show) =>
      dispatch({
        type: IQ_SHOW_SIGNATURE_INSTRUCTIONS,
        showInstructionsModal: _show,
      }),
    renderNotification: (_type, _title, _message) =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: _type,
        message: _message,
        title: _title,
      }),
    getFundById: (firmId, callback) =>
      dispatch(action.getFundById(firmId, callback)),
    // new items added above
  })
);
export default withRouter(connector(QuestionnaireExperienceHome));
