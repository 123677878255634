module.exports = {
  STATUS_TYPES: {
    CLOSED: { label: 'Closed', value: 'closed' },
    PENDING: { label: 'Pending', value: 'pending' },
    READY_TO_CLOSE: { label: 'Ready to close', value: 'ready' },
    OMIT_FROM_GP_ACCESS: { label: 'Omit from GP Access', value: 'omitted' },
    UNDER_REVIEW: { label: 'Under Review', value: 'review' },
    IN_PROGRESS: { label: 'In Progress', value: 'progress' },
    SUBMITTED: { label: 'Submitted', value: 'submitted' },
  },
};
