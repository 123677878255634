import { format, toDate } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';

export default class DateTimeZone {
    constructor(pDate, useDefault = false) {
      this.date = pDate || new Date();
      this.timeZone = 'America/Los_Angeles';
      this.zonedDate = toDate(this.date, this.timeZone);
      return !pDate || useDefault ? this.zonedDate : null; 
   }

   toFormat(dateFormat) {
      return format(this.zonedDate, dateFormat, { timeZone: this.timeZone, locale: enUS });
   }
   toDateString() {
      return format(this.zonedDate, 'iii MMM dd yyyy', { timeZone: this.timeZone, locale: enUS });
   }
   toLocaleDateString() {
      return format(this.zonedDate, 'yyyy/MM/dd', { timeZone: this.timeZone, locale: enUS });
   }
   getLocalDate() {
      return format(this.zonedDate, 'M/d/yyyy HH:mm:ss', { timeZone: this.timeZone, locale: enUS });
   }
   getMonth() {
      return Number(format(this.zonedDate, 'MM', { timeZone: this.timeZone, locale: enUS }));
   }
   getYear() {
      return Number(format(this.zonedDate, 'yy', { timeZone: this.timeZone, locale: enUS }));
   }
   getFullYear() {
      return Number(format(this.zonedDate, 'yyyy', { timeZone: this.timeZone, locale: enUS }));
   }
   getDate() {
      return Number(format(this.zonedDate, 'd', { timeZone: this.timeZone, locale: enUS }));
   }
   getHours() {
      return Number(format(this.zonedDate, 'h', { timeZone: this.timeZone, locale: enUS }));
   }
   getMinutes() {
      return Number(format(this.zonedDate, 'm', { timeZone: this.timeZone, locale: enUS }));
   }
   getSeconds() {
      return Number(format(this.zonedDate, 's', { timeZone: this.timeZone, locale: enUS }));
   }

}

