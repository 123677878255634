import React from 'react';
// import Mailto from 'react-mailto';
import './styles.scss';

const Footer = () => (
  <footer className="footer-wrap background-color-black">
    <div className="container">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <nav className="footer-nav text-align-center">
          <ul>
            <li className="color-white">
              <a className="color-white" href="mailto:fundportaladmin@gunder.com">
                Questions/Support
              </a>
            </li>
            <li className="color-white">
              <a
                href="https://www.gunder.com/legal"
                target="_blank"
                rel="noopener noreferrer"
                className="color-white"
              >
                Legal Notices & Attorney Advertising
              </a>
            </li>
            <li className="color-white">
              <a
                href={`${window.location.protocol}//${window.location.hostname}:${
                  window.location.port
                  }/terms`}
                target="_blank"
                rel="noopener noreferrer"
                className="color-white"
              >
                Terms of Use
              </a>
            </li>
            <li className="color-white">
              <a
                href={`${window.location.protocol}//${window.location.hostname}:${
                  window.location.port
                  }/privacy`}
                target="_blank"
                rel="noopener noreferrer"
                className="color-white"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </nav>
        <div className="description color-white text-align-center">
          <p>Gunderson Dettmer Stough Villeneuve Franklin & Hachigian, LLP</p>
          <p>Copyright {new Date().getFullYear()}. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
);

Footer.defaultProps = {
  data: {},
};

export default Footer;
