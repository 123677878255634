/* eslint-disable react/sort-comp */
/* @flow */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import type { Connector } from 'react-redux';
import Helmet from 'react-helmet';
import Button from '../../components/Button/index';
import { Context as ContextType, Reducer, Dispatch } from '../../types/index';
import TextInput from '../../components/TextInput/index';
import Alert from '../../components/Alert/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import { Link } from 'react-router-dom';
import {
  PASSWORD_LENGTH_MAX_MESSAGE,
  PASSWORD_LENGTH_MIN_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
} from './../../utils/constants/messages';
import * as action from './action';
import {
  getUrlParameter,
  passwordStrengthValidation,
} from '../../utils/utility';

type Props = {
  history: Object,
  context: ContextType,
  notifyPasswordDontMatch: () => {},
  resetPassword: () => {},
};

const initialState = {
  password: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  confirmPassword: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  verified: false,
};
const API_URL = '/api';

const ResetPassword = (props: Props) => {
  const dispatch = useDispatch();
  const [passwordField, setPasswordField] = useState({
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  });
  const [confirmPasswordField, setConfirmPasswordField] = useState({
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  });
  const [validVerification, setValidVerification] = useState(true);
  const [verified, setVerified] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  useEffect(() => {
    const { history } = props;
    const asyncWrap = async (rs) => {
      const res = await axios.post(`${API_URL}/iqUsers/checkRSPasswordToken`, {
        rsToken: rs,
      });
      // res.data.email
      if (res.data.success) {
        setVerified(true);
        setUserEmail(res.data.email);
        dispatch({
          type: 'RENDER_NOTIFICATION',
          notificationType: 'success',
          message: res.data.message,
          title: 'Success',
        });
      } else {
        setValidVerification(false);
        dispatch({
          type: 'RENDER_NOTIFICATION',
          notificationType: 'error',
          message:
            'Verification failed! If you encounter any issues, please contact fundportaladmin@gunder.com.',
          title: 'Error',
        });
        history.push('/');
      }
    };
    if (getUrlParameter('rs')) {
      if (validVerification && !verified) {
        asyncWrap(getUrlParameter('rs'));
      }
    } else {
      history.push('/');
    }
  });

  const renderTextInput = (
    id,
    type,
    displayName,
    placeholder,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    disabled
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      noTitle: true,
      errorMessage,
      placeholder,
      disabled,
    };
    return (
      <TextInput inputData={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { resetPassword, history } = props;
    const pass = event.target.elements[1].value;
    const confirmPass = event.target.elements[3].value;
    const rsToken = getUrlParameter('rs');

    if (validateConfirmPassword(pass, confirmPass)) {
      const res = await axios.put(`${API_URL}/iqUsers/resetPassword`, {
        password: pass,
        confirmPassword: confirmPass,
        email: userEmail,
        token: rsToken,
      });
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: res.data.success ? 'success' : 'error',
        message: res.data.message,
        title: res.data.success ? 'Success' : 'Error',
      });
      if (res.data.success) {
        // clean any tokens etc
        history.push('/');
      }
    }
  };

  const validateConfirmPassword = (pass, rePass) => {
    let validatePayload = null;
    if (pass.length < 14) {
      validatePayload = {
        ...passwordField,
        valid: false,
        errorMessage: PASSWORD_LENGTH_MIN_MESSAGE,
      };
      setPasswordField(validatePayload);
      return false;
    }
    if (pass.length > 48) {
      validatePayload = {
        ...passwordField,
        valid: false,
        errorMessage: PASSWORD_LENGTH_MAX_MESSAGE,
      };
      setPasswordField(validatePayload);
      return false;
    }

    if (pass === rePass) {
      return true;
    } else {
      setPasswordField({
        ...passwordField,
        valid: false,
        errorMessage: PASSWORD_NOT_MATCH_MESSAGE,
      });
      // props.notifyPasswordDontMatch();
      return false;
    }
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPasswordField({
      ...passwordField,
      value: event.target.value,
    });
  };

  const handleConfirmPasswordChange = (event) => {
    event.preventDefault();
    setConfirmPasswordField({
      ...confirmPasswordField,
      value: event.target.value,
    });
  };

  const { context } = props;

  return (
    <div className="main-container">
      <DynamicHeader type={'user'} homePath={'/'} />
      <Helmet title="Reset password" />
      <div className="container">
        {/* <Alert
          inputData={{
            showAlways: context.showAlways,
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="page-top-row">
              <Link to="/" className="back-to color-black">
                <i className="fa fa-angle-left" aria-hidden="true" /> Back
              </Link>
              <h3
                style={{ fontWeight: 'normal' }}
                className="color-dark-green text-align-center "
              >
                Fund Formation Portal
              </h3>
            </div>
          </div>
        </div>
        <br />
        <div className="userlogin-section">
          <div className="row">
            <div className="col-lg-7 col-md-9 col-sm-11 col-xs-12 center-block">
              <form onSubmit={handleSubmit}>
                <div className="userlogin-main text-align-center content-bg">
                  <h5
                    style={{ marginTop: '-27px', marginBottom: '-5px' }}
                    className="color-dark-green"
                  >
                    RESET PASSWORD
                  </h5>
                  <hr />
                  <div>
                    <p>Please enter a new password below and click submit.</p>
                    <br />
                    <div className="row no-padding no-border">
                      <div className="col-lg-3 col-md-3 col-sm-3" />
                      <div className="col-lg-6 col-md-6 col-sm-6 no-padding no-border">
                        <p className="color-black">New password</p>
                        {verified &&
                          renderTextInput(
                            'password',
                            'password',
                            'password',
                            'New password',
                            true,
                            'large',
                            !passwordField.valid,
                            passwordField.errorMessage,
                            handlePasswordChange
                          )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3" />
                    </div>
                    <div className="row no-padding no-border">
                      <div className="col-lg-3 col-md-3 col-sm-3" />
                      <div className="col-lg-6 col-md-6 col-sm-6 no-padding no-border">
                        <p className="color-black">Confirm password</p>
                        {verified &&
                          renderTextInput(
                            'confirmPassword',
                            'password',
                            'confirmPassword',
                            'Confirm Password',
                            true,
                            'large',
                            !confirmPasswordField.valid,
                            confirmPasswordField.errorMessage,
                            handleConfirmPasswordChange
                          )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3" />
                    </div>
                  </div>
                  <Button
                    inputData={{
                      id: 'admin-user-submit',
                      displayName: 'Submit',
                      type: 'submit',
                      isDisabled: !verified,
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: Reducer) => ({ context: state.context });

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    notifyPasswordDontMatch: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Passwords not match!',
        title: 'Error',
      }),
    resetPassword: (password, confirmPassword, email, rsToken, history) =>
      dispatch(
        action.resetPassword(password, confirmPassword, email, rsToken, history)
      ),
  })
);

export default connector(ResetPassword);
