/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */

import React from 'react';
import './styles.scss';
import HeaderLogo from '../HeaderLogo/index';
import { NavLink } from 'react-router-dom';
import OrgUserProfile from '../OrgUserProfile';
import { webDomainClient } from '../../utils/client-access';

type Props = {
  type: string,
  homePath: string,
  data: Object
};
const renderLogo = (hp) => <HeaderLogo logoCentered homePath={hp}  />;

const UserMenu = (type, homePath) => {
  const currentPath = (typeof window !== 'undefined' && window.location) ? window.location.pathname : '/';
  return (
    <header className={`header-wrap ${type === 'authenticated' ? 'no-spacer' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 visible-xs-block">
            {renderLogo(homePath)}
          </div>
          <div className="col-lg-4 col-md-3 col-sm-3 hidden-xs">
            {renderLogo(homePath)}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
          </div>
          <div className="menu-item-wrapper col-lg-4 col-md-3 col-sm-3 col-xs-6"> 
            {currentPath === '/' 
            ?
            <a className="go-to-client-link menu-item" href={webDomainClient}>Go to Client Login</a>
            :
            <NavLink to={currentPath === '/' ? '/signup' : '/'} className="menu-item">{currentPath === '/' ? 'Sign Up' : 'Sign In'}</NavLink>
            }
          </div>
        </div>
      </div>
    </header>
  )
}

const DynamicHeader = ({ type, data, homePath }: Props) => (
  type === 'user' ? UserMenu(type, homePath) :
    <header className={`header-wrap ${type === 'authenticated' ? 'no-spacer' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 visible-xs-block">
            {renderLogo(homePath)}
          </div>
          <div className="col-lg-4 col-md-3 col-sm-3 col-xs-6" />
          <div className="col-lg-4 col-md-6 col-sm-6 hidden-xs">
            {renderLogo(homePath)}
          </div>
          <div className="col-lg-4 col-md-3 col-sm-3 col-xs-6">
            {type === 'authenticated' ?
              <OrgUserProfile
                displayName={data.name}
                profileHandler={data.profileHandler}
                type="admin"
                logoutHandler={data.logoutHandler} />
              : null}
          </div>
        </div>
      </div>
    </header>
);

DynamicHeader.defaultProps = {
  type: 'simple', // simple | authenticated
  data: {},
};

export default DynamicHeader;

