import React from 'react';
import './styles.scss';

type Props = { logoCentered: Boolean, homePath: string };
const HeaderLogo = ({ logoCentered, homePath }: Props) => (
	<div className={`logo ${logoCentered ? 'logo-main' : ''}`}>
		<a href={homePath}>
			<img src={require('./assets/GD_Logo_New-3.svg')} alt='Gunderson Dettmer' title='Gunderson Dettmer' />
		</a>
	</div>
);

export default HeaderLogo;
