import React from 'react';
import './styles.scss';


type Props = { data: Object };

const TableColumn = ({ data }: Props) => (
  <div className={`col-box ${data.class}`}>
    {data.filteringArrow ? <a href="#/" onClick={data.filteringHandler} data-identifier={data.filteringId} data-parent={data.filteringParent} data-entity={data.filteringEntity} data-order={data.filteringOrder} data-identifier-type={data.filteringType} data-selected={data.selected} className={`${data.filteringOrder === 'desc' ? 'list-dropdown' : 'list-dropup'} ${data.selected ? 'list-selected' : ''}`}>{data.title}</a> :
      data.title}
  </div>
);

TableColumn.defaultProps = {
  data: {
    title: '',
    class: '',
    filteringArrow: false,
    filteringId: '',
    filteringParent: '',
    filteringType: '',
    filteringEntity: '',
    filteringOrder: '',
    selected: false,
    filteringHandler: () => { },
  },
};

export default TableColumn;

