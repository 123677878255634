import React from 'react';
import './styles.scss';
import TabIndexSection from '../TabIndexSection/index';

type Props = { sections: Array<Object> };

const renderSectionData = (name, active, id) => ({ displayName: name, active, id });

const TabIndexContainer = ({ sections }: Props) => (
  <ul className="tab-index">
    { sections.map(section => (<TabIndexSection
      key={section.displayName}
      data={renderSectionData(section.displayName, section.active, section.id)}
      clickHandler={section.clickHandler}
    />))}
  </ul>
);

TabIndexContainer.defaultProps = {
  sections: [],
};

export default TabIndexContainer;

