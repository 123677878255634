/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// import * as _ from 'lodash';
import Helmet from 'react-helmet';
import * as action from './action';
import * as notify from './../../utils/notify';
import {
  PASSWORD_LENGTH_MAX_MESSAGE,
  PASSWORD_LENGTH_MIN_MESSAGE,
  PASSWORD_UPDATED_SUCCESFUL_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
} from './../../utils/constants/messages';
import './styles.scss';
import { Reducer, Dispatch } from '../../types/index';
import Button from '../../components/Button/index';
import TextInput from '../../components/TextInput/index';
import Alert from '../../components/Alert/index';
import * as utility from '../../utils/utility';

type Props = {
  history: Object,
  // admin: AdministratorType,
  updateUser: () => {},
  context: () => {},
};

const initialState = {
  name: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  duoUser: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  lastName: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  email: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  password: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  confirmPassword: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  emailAddress: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: [''],
  },
  matching: { error: false, message: '' },
  loading: { submit: false, data: false },
};

// Export this for unit testing more easily
export class AdminProfile extends PureComponent {
  props: Props;

  static defaultProps: {
    history: {},
    admin: {},
    updateUser: () => {},
    context: () => {},
  };

  constructor(state) {
    super(state);
    this.state = initialState;
    this.handleLink = this.handleLink.bind(this);
    this.handlerTextInput = this.handlerTextInput.bind(this);
    this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
    this.getInputValue = this.getInputValue.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
  }

  componentDidMount() {
    if (
      document.title === 'Fund Formation Portal - Admin Dashboard' ||
      document.title === 'Fund Formation Portal - Firm Detail'
    )
      notify.hideNotification();
    this.clearInputs();
  }

  handleLink(event) {
    if (event) event.preventDefault();
    const { history } = this.props;
    const temporalLogin = Boolean(utility.getUrlParameter('tmp'));
    if (!temporalLogin) {
      history.push('/admin/main/dashboard');
    } else {
      history.push('/admin');
    }
  }

  handlerTextInput(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      [event.target.id]: {
        ...this.state[event.target.id],
        value: event.target.value,
      },
    });
  }

  validateConfirmPassword(pass, rePass) {
    if (pass && pass !== '') {
      if (pass.length < 14) {
        this.setState({
          ...this.state,
          matching: {
            ...this.state.matching,
            error: true,
            message: PASSWORD_LENGTH_MIN_MESSAGE,
          },
        });
        return false;
      }
      if (pass.length > 48) {
        this.setState({
          ...this.state,
          matching: {
            ...this.state.matching,
            error: true,
            message: PASSWORD_LENGTH_MAX_MESSAGE,
          },
        });
        return false;
      }

      if (pass === rePass) {
        this.setState({
          ...this.state,
          matching: {
            ...this.state.matching,
            error: false,
            message: PASSWORD_UPDATED_SUCCESFUL_MESSAGE,
          },
        });
        return true;
      } else {
        this.setState({
          ...this.state,
          matching: {
            ...this.state.matching,
            error: true,
            message: PASSWORD_NOT_MATCH_MESSAGE,
          },
        });
        return false;
      }
    } else {
      return false;
    }
  }

  handlerFormSubmit(event) {
    event.preventDefault();

    this.setState({
      ...this.state,
      loading: { ...this.state.loading, submit: true },
    });
    const { context, updateUser, history } = this.props;
    const payload = {
      name:
        this.state.name.value === ''
          ? context.profile.name
          : this.state.name.value,
      duoUser:
        this.state.duoUser.value === ''
          ? context.user.duoUser
          : this.state.duoUser.value,
      lastName:
        this.state.lastName.value === ''
          ? context.profile.lastName
          : this.state.lastName.value,
      email:
        this.state.email.value === ''
          ? context.user.email
          : this.state.email.value,
      emailAddress:
        this.state.emailAddress.value === ''
          ? context.profile.emailAddress
          : this.state.emailAddress.value,
    };
    const updatePayload = () => {
      this.setState({
        ...this.state,
        loading: { ...this.state.loading, submit: false },
      });
      updateUser(payload, history);
    };
    if (
      this.state.confirmPassword.value !== '' ||
      this.state.password.value !== ''
    ) {
      const pass = this.state.password.value;
      const rePass = this.state.confirmPassword.value;
      if (this.validateConfirmPassword(pass, rePass)) {
        payload.passwordConfirm = true;
        if (payload.passwordConfirm) {
          payload.password = this.state.password.value;
          payload.signInCount = context.user.signInCount + 1;
          payload.usesDefPass = false;
          payload.passwordChangeRequired = false;
          // update signincount to avoid password change message changing again
          updatePayload();
        }
      }
    } else if (
      payload.name !== context.profile.name ||
      payload.lastName !== context.profile.lastName ||
      payload.emailAddress !== context.profile.emailAddress
    ) {
      updatePayload();
    } else {
      this.clearInputs();
    }
  }

  clearInputs() {
    const { context } = this.props;
    this.setState({
      ...this.state,
      name: {
        ...this.state.name,
        value: context.profile.name,
      },
      duoUser: {
        ...this.state.duoUser,
        value: context.user.duoUser,
      },
      lastName: {
        ...this.state.lastName,
        value: context.profile.lastName,
      },
      email: {
        ...this.state.email,
        value: context.user.email,
      },
      password: {
        ...this.state.password,
        value: '',
      },
      confirmPassword: {
        ...this.state.confirmPassword,
        value: '',
      },
      emailAddress: {
        ...this.state.emailAddress,
        value: context.profile.emailAddress,
      },
    });
  }

  getInputValue(identifier) {
    const { context } = this.props;
    const attr = context.user[identifier];
    if (attr) {
      return context.user[identifier] === this.state[identifier].value ||
        this.state[identifier].value === ''
        ? context.user[identifier]
        : this.state[identifier].value;
    }
    return context.profile[identifier] === this.state[identifier].value ||
      this.state[identifier].value === ''
      ? context.profile[identifier]
      : this.state[identifier].value;
  }

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={this.handlerTextInput}
      />
    );
  };

  render() {
    const { context } = this.props;
    return (
      <div className="main-container">
        <div className="container">
          <Helmet title="Admin Profile" />
          {/* <Alert
            inputData={{
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="page-top-row">
                <a
                  href="#/"
                  onClick={this.handleLink}
                  className="back-to color-black"
                >
                  <i className="fa fa-angle-left" aria-hidden="true" /> Back
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <form onSubmit={this.handlerFormSubmit}>
                <div className="content-bg profile-main">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 className="color-dark-green">PROFILE</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="profile-main-content">
                        {this.renderTextInput(
                          'name',
                          'name',
                          'text',
                          this.state.name.value,
                          'First Name',
                          false,
                          'large',
                          !this.state.name.valid,
                          this.state.name.errorMessage,
                          ''
                        )}
                        {this.renderTextInput(
                          'lastName',
                          'lastName',
                          'text',
                          this.state.lastName.value,
                          'Last Name',
                          false,
                          'large',
                          !this.state.lastName.valid,
                          this.state.lastName.errorMessage,
                          ''
                        )}
                        {this.renderTextInput(
                          'email',
                          'email',
                          'text',
                          this.state.email.value,
                          'Username',
                          false,
                          'large',
                          !this.state.email.valid,
                          this.state.email.errorMessage,
                          "You can't edit this field.",
                          true
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="profile-main-content">
                        {this.renderTextInput(
                          'password',
                          'password',
                          'password',
                          this.state.password.value,
                          'New Password',
                          false,
                          'large',
                          !this.state.password.valid,
                          this.state.password.errorMessage,
                          'Your password must be 14 characters long.'
                        )}
                        {this.renderTextInput(
                          'confirmPassword',
                          'confirmPassword',
                          'password',
                          this.state.confirmPassword.value,
                          'Confirm Password',
                          false,
                          'large',
                          !this.state.confirmPassword.valid,
                          this.state.confirmPassword.errorMessage,
                          ''
                        )}
                        {this.state.matching.error ? (
                          <div className="error-message not-match-pass red-color">
                            <span className="text">
                              {this.state.matching.message}
                            </span>
                          </div>
                        ) : (
                          <div className="error-message not-match-pass green-color">
                            <span className="text">
                              {this.state.matching.message}
                            </span>
                          </div>
                        )}
                      </div>
                      {this.renderTextInput(
                        'emailAddress',
                        'emailAddress',
                        'emailAddress',
                        this.state.emailAddress.value,
                        'Email Address',
                        false,
                        'large',
                        !this.state.emailAddress.valid,
                        this.state.emailAddress.errorMessage,
                        ''
                      )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <hr className="center-block" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
                      {!this.state.loading.submit ? (
                        <Button
                          inputData={{
                            id: 'admin-user-submit',
                            displayName: 'Submit',
                            type: 'submit',
                          }}
                        />
                      ) : (
                        <Button
                          inputData={{
                            id: 'submit-disabled',
                            displayName: 'Submit',
                            type: 'submit',
                            isDisabled: true,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: Reducer) => ({
  admin: state.admin,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    updateUser: (dataPayLoad, history) =>
      dispatch(action.updateUser(dataPayLoad, history)),
  })
);
export default connector(AdminProfile);
