import React, { Fragment } from 'react';
import './styles.scss';
import RadioButton from '../Radio/index';
import { resolveSizeClass } from './../utils/common';
import TextInput from '../TextInput/index';
import Dropdown from '../Dropdown/index';
import TextInputRef from '../TextInput/index.uncontrolled';

type Props = { data: Object, content: [], onChangeHandler: () => void };

const MultiSelect = ({
  data,
  content,
  subheaderText,
  onChangeHandler,
}: Props) => (
  <div>
    <div
      key={data.id}
      id={data.id}
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
    >
      <div className="">
        <h5 className="color-black tooltip-positioning">{data.displayName}</h5>
      </div>
      {content
        ? content.map((item) => (
            <div
              key={item.id + item.displayName}
              className={`${resolveSizeClass(data.size)} no-move-center-align`}
              style={item.letter && { display: 'grid', 'gridTemplateColumns': '20px 100%' , 'gap': '2%' }}
            > 
           {item.letter && <p style={{ marginLeft: '2%' }}>({item.letter.toLowerCase()})</p>}
              {/* FIXME: this seems to be related to iqs specific versions , should be moved as an option of the question itself */}
              {subheaderText &&
                item.identifier.includes('For_Per_Stat_2-or') && (
                  <p>{subheaderText}</p>
                )}
            {item.onlyText ? 
             <p className={item.className}>{item.onlyText}</p>
             : <RadioButton
                key={item.id}
                inputData={item}
                onChangeHandler={onChangeHandler}
              />}
              {item.triggerOptions && (
                <>
                  {item.triggerOptions.input === 'text' && item.isSelected && (
                    <div style={{ marginLeft: '8%' }}>
                      <TextInput
                        inputData={{
                          id: item.triggerOptions.id || '',
                          noTitle: true,
                          displayName: 'Other',
                          size: 'medium',
                          style: item.triggerOptions.style || {},
                          type: 'text',
                          value: item.triggerOptions.value || '',
                          wrapperClass: '',
                        }}
                        reference={item.triggerOptions.reference}
                        onChangeHandler={item.triggerOptions.changeHandler}
                        onBlurHandler={item.triggerOptions.blurHandler}
                      />
                    </div>
                  )}
                  {item.triggerOptions.input === 'text2' && (item.isSelected || item.triggerOptions.showInput) && (
                    <>
                  {item.triggerOptions.extraText && <div style={{  marginLeft: '7%' }}>{item.triggerOptions.extraText}</div>}
                    <div style={{ marginLeft: '8%' }}>
                      <TextInputRef
                        inputData={{
                          id: item.triggerOptions.id || '',
                          isRequired: false,
                          size: 'large',
                          hasError: false,
                          style: item.triggerOptions.style || {},
                          type: 'text',
                          percentage: item.triggerOptions.percentage,
                        }}
                        reference={item.triggerOptions.reference}
                        onBlurHandler={item.triggerOptions.blurHandler}
                      />
                    </div>
                    </>
                  )}
                  {item.extraText && <div style={{ marginBottom: '6%', marginLeft: '8%' }}>{item.extraText}</div>}
                  {item.triggerOptions.input === 'select' && item.isSelected && (
                    <div style={{ marginLeft: '8%' }}>
                      <Dropdown
                        inputData={{
                          id: item.triggerOptions.id || '',
                          name: item.triggerOptions.id || '',
                          value: item.triggerOptions.value,
                          displayName: '',
                          title: '',
                          style: item.triggerOptions.style || {},
                          size: 'medium',
                          options: item.triggerOptions.selectOptions.map(
                            (item) => {
                              return {
                                id: item,
                                value: item,
                              };
                            }
                          ),
                          hasError: false, // if value is selected and nothing is written
                          errorMessage: '',
                          showHelp: false,
                          helpText: '',
                        }}
                        onChangeHandler={item.triggerOptions.changeHandler}
                        onBlurHandler={item.triggerOptions.blurHandler}
                      />
                    </div>
                  )}
                  {item.triggerOptions.input === 'singleSelect' && (item.isSelected || item.triggerOptions.showInput) && (
                     <div style={{ marginLeft: '8%' }}>
                      {item.triggerOptions.selectOptions.map((selectItem) => (
                        <Fragment key={selectItem.id}>
                        {selectItem.type !== 'dropdown' && selectItem.type !== 'text'&& selectItem.type !== 'radioButton' && (
                            <RadioButton
                              key={selectItem.id}
                              inputData={selectItem}
                              onChangeHandler={item.triggerOptions.changeHandler}
                            />
                        )}
                          {selectItem.type === 'radioButton' && selectItem.isSelected && (
                              <div style={{ marginLeft: '8%' }} key={selectItem.id}>
                                {selectItem.options && selectItem.options.map((option) => (
                                  <RadioButton
                                    key={option.id}
                                    inputData={option}
                                    onChangeHandler={selectItem.changeHandler}
                                />
                                ))}
                              </div>
                          )}
                          {selectItem.isSelected && selectItem.type === 'dropdown' && (
                            <div className='row' key={selectItem.id}>
                            {selectItem.displayName && <p className='col-lg-6'>{selectItem.displayName}</p>}
                              <Dropdown
                                inputData={{
                                  id: selectItem.id || '',
                                  name: selectItem.id || '',
                                  value: selectItem.value,
                                  displayName: '',
                                  title: '',
                                  style: selectItem.style || {},
                                  size: selectItem.size || 'medium',
                                  options: selectItem.options.map(
                                    (item) => {
                                      return {
                                        id: item,
                                        value: item,
                                      };
                                    }
                                  ),
                                  hasError: false, // if value is selected and nothing is written
                                  errorMessage: '',
                                  showHelp: false,
                                  helpText: '',
                                }}
                                reference={selectItem.reference}
                                onChangeHandler={selectItem.changeHandler}
                                onBlurHandler={selectItem.blurHandler}
                              />
                              {selectItem.displayName2 && <p className=''>{selectItem.displayName2}</p>}
                            </div>
                        )}
                        {selectItem.type === 'text' && (
                            <>
                              {selectItem.inputText && <div className={`${selectItem.disabled ? 'color-gray' : ''} `}>{selectItem.inputText}</div>}
                              <TextInputRef
                                inputData={{
                                  id: selectItem.id || '',
                                  isRequired: false,
                                  size: 'large',
                                  hasError: false,
                                  style: selectItem.style || {},
                                  type: 'text',
                                  value: selectItem.value || '',
                                  disabled: selectItem.disabled || false,
                                }}
                                reference={selectItem.reference}
                                onChangeHandler={selectItem.changeHandler}
                                onBlurHandler={selectItem.blurHandler}
                              />
                            </>
                            )}
                       </Fragment>
                       ))}
                     </div>
                  )}
                </>
              )}
            </div>
          ))
        : null}
      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 no-padding">
        {data.hasOtherButton ? (
          <div
            key={'org-type-radio-otherOther'}
            className="col-lg-3 col-md-2 col-sm-3 col-xs-3"
          >
            <RadioButton
              key={'org-type-radio-other'}
              inputData={{
                id: 'org-type-radio-other',
                displayName: 'Other',
                isSelected: data.otherSelected,
                name: '',
                identifier: 'Other',
              }}
              onChangeHandler={onChangeHandler}
            />{' '}
          </div>
        ) : null}
        {data.otherSelected ? (
          <div className="col-lg-8 col-md-5 col-sm-6 col-xs-7" style={{ marginLeft: '53.3rem' }}>
            <TextInput
              inputData={{
                id: 'other-txt',
                noTitle: true,
                displayName: 'Other',
                size: 'large',
                type: 'text',
                value: data.otherValue,
                wrapperClass: '',
              }}
              onChangeHandler={data.otherValueChangeHandler}
            />
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

MultiSelect.defaultProps = {
  data: {
    id: '',
    displayName: '',
    hasOtherButton: false,
    otherSelected: false,
    otherValueChangeHandler: () => {},
    otherValue: '',
    helpText: '',
    showHelp: false,
    size: '',
    letter: '',
  },
  subheaderText: '',
  content: [],
  onChangeHandler: () => {},
};

export default MultiSelect;
