/* eslint-disable react/sort-comp */
/* @flow */

import React from 'react';
import Helmet from 'react-helmet';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';

const PrivacyPolicy = () => (
  <div className="main-container">
    <DynamicHeader />
    <Helmet title="Privacy Policy" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h4>Privacy Policy</h4>
          <h4 className="color-dark-green landing-section-title">Last Updated.</h4>
          <p>
            November 25, 2018
          </p>
          <h4 className="color-dark-green landing-section-title">Service.</h4>
          <p>
            Your use of the Services is at all times subject to the <a href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/terms`}>Terms of Use</a>, which incorporates this Privacy Policy.  Any terms used and not defined in this Privacy Policy have the definitions given to them in the <a href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/terms`}>Terms of Use</a>.
          </p>
          <h4 className="color-dark-green landing-section-title">Acceptance of Privacy Policy.</h4>
          <p>
            The following policy (<b>Privacy Policy</b>) explains how Gunderson collects, uses and disseminates personally identifiable information (PII) and other information obtained from or about you when you access or use the Services.
          </p>
          <p>
            <b>
              BY USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE AND AGREE THAT YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY.
            </b>
          </p>
          <p>
            This Privacy Policy does not apply to information that you provide to Gunderson, or that Gunderson collects, through means other than the Service.  For example, if you provide information to Gunderson offline then that information is not subject to this Privacy Policy.  However, that information may be subject to another binding agreement between you and Gunderson.
          </p>
          <h4 className="color-dark-green landing-section-title">Collection and Use of Information.</h4>
          <p>
            <u>
              Information You Provide to Gunderson
            </u>
          </p>
          <p>
            Gunderson receives and stores any information you knowingly provide to us through the Services. For example, through the registration process, Gunderson may collect certain contact and financial information if requested by the fund(s) to which you would like to submit information. Gunderson may communicate with you if you've provided the means to do so.  For example, if you've provided your email address, Gunderson or the applicable fund may send you informational emails.
          </p>
          <p>
            Gunderson may use your PII and other information to develop aggregate analytics (in combination with other personal data), as long as your identity is not disclosed to any third party.
          </p>
          <p>
            <u>
              Information Collected Automatically
            </u>
          </p>
          <p>
            Whenever you interact with the Services, Gunderson automatically receives and records information on its server logs from your browser or device, which may include your IP address, "cookie" information, the type of browser and/or device you're using to access the Services and identification of the page or feature you requested.  <b>Cookies</b> are identifiers that Gunderson transfers to your browser or device, which allow us to recognize your browser or device and tell us how and when pages and features in the Service are visited and by how many people.  You may be able to change the preferences on your browser or device to prevent or limit acceptance of cookies, but this may prevent you from taking advantage of some features of the Service.
          </p>
          <p>
            Also, if you click on a link to a third party website or service, a third party may also transmit cookies to you.  This Privacy Policy does not cover the use of cookies by third parties, and Gunderson is not responsible for their privacy policies and practices.  Please be aware that cookies placed by third parties may continue to track your online activities even after you have left the Services, and those third parties may not honor "Do Not Track" requests you have set using your browser or device.
          </p>
          <p>
            Gunderson may use this traffic data to help diagnose problems with its web servers, to administer the Services, to analyze user trends and behaviors and to improve the Services.  For example, Gunderson can use this data to determine how often a particular feature is used and that knowledge could be used to make the Services more useful or interesting to as many users as possible.
          </p>
          <h4 className="color-dark-green landing-section-title">Sharing of Personal Data.</h4>
          <p>
            Gunderson will not share your information with any third party other than as set forth in this section.
          </p>
          <p>
            We may share information, including PII, with the applicable fund(s) you designate as the recipient(s) of the information through the Services, as well as such funds’ general partner(s), management company, affiliated funds, and third-party service providers such as operations, accounting, legal, and management service providers.
          </p>
          <p>
            Your information, including PII, may also be shared with third parties in order to provide the Services; for example, certain information may be hosted on third-party systems, or provided to vendors in order to make certain features of the Services (such as electronic signature management) available to you. These third parties do not have the right to use your information beyond what is necessary to provide the applicable Services.
          </p>
          <p>
            Finally, Gunderson may choose to buy or sell assets, and may share and/or transfer information (including PII) in connection with the evaluation of and entry into such transactions. Also, if Gunderson (or its assets) is acquired, or goes out of business, enters bankruptcy, or goes through some other change of control, your information (including PII) could be one of the assets transferred to or acquired by a third party.
          </p>
          <h4 className="color-dark-green landing-section-title">User Rights.</h4>
          <p>
            If you have any questions about your information that Gunderson has on file, including questions relating to updating, deleting, or obtaining a copy of such information, or otherwise exercising any of the other rights outlined in this section, please contact <a href="mailto:madams@gunder.com">Marya Adams</a>. In certain cases, you may be able to:
          </p>
          <ul>
            <li>
              Request more information about your PII held by Gunderson and request a copy of such PII. You can also access certain of your PII by creating an account and logging in to the Services to review data you have submitted.
            </li>
            <li>
              Request that Gunderson corrects or supplements any of your PII that is incomplete or inaccurate. You can also correct some of this information directly by logging in to your account on the Services.
            </li>
            <li>
              Request that Gunderson erase some or all of your PII from our systems.
            </li>
            <li>
              Withdraw your consent to certain processing of your PII by Gunderson; however, if you withdraw a legally required consent, then you may have to provide express consent on a case-by-case basis for the use or disclosure of certain of your PII, if such use or disclosure is necessary to enable you to utilize some or all of our Services.
            </li>
            <li>
              Ask for a copy of your PII in a machine-readable format, and/or request that Gunderson transmit the data to another party where technically feasible.
            </li>
            <li>
              Object to the further use or disclosure of your PII for certain purposes, such as for direct marketing purposes.
            </li>
            <li>
              Ask Gunderson to restrict further processing of your PII.
            </li>
            <li>
              Lodge a complaint about Gunderson’s practices with respect to your PII with an appropriate government or quasi-government authority.
            </li>
          </ul>
          <h4 className="color-dark-green landing-section-title">Do Not Track Policy.</h4>
          <p>
            Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities, over time and across different websites.  Do Not Track signals are set on a browser-by-browser basis, so you must set them on every browser you use if you do not wish to be tracked.
          </p>
          <p>
            At this time, Gunderson does not track your online activities outside of the Services.  Therefore, setting or releasing your Do Not Track signal will not affect how the Services operate.
          </p>
          <p>
            For clarity, this is just Gunderson’s Do Not Track Policy.  Gunderson cannot and does not make any promises about how third parties react when you set Do Not Track signals on your browser.  In addition, the Do Not Track signal does not impact the use of information collected prior to such request.
          </p>
          <h4 className="color-dark-green landing-section-title">California Residents.</h4>
          <p>
            Under California Civil Code Section 1798.83 ("Shine the Light" law), California residents who provide personal information in obtaining products or services from Gunderson are entitled to request and obtain (once per calendar year) information about the PII that Gunderson shared, if any, with other businesses for their own direct marketing uses.  If applicable, this information would include the categories of shared information and the names and addresses of those businesses with which Gunderson shared such information for the immediately prior calendar year (e.g., requests made in 2017 will receive information regarding 2016 sharing activities). If you are a California resident and would like a copy of this information, please submit a written request to:
          </p>
          <div className="landing-contact-info">
            <p>
              Gunderson Dettmer <br />
              Attn: Marya Adams <br />
              550 Allerton Street <br />
              Redwood City, CA 94063
            </p>
          </div>
          <h4 className="color-dark-green landing-section-title">Security.</h4>
          <p>
            Information collected by Gunderson is stored in operating environments that are secured using commercially available tools and procedures, and which are not made generally available to the public.  Unfortunately, no data transmission over the Internet and/or wireless networks can be guaranteed to be 100% secure.  As a result, Gunderson cannot unequivocally guarantee the security of any information you provide, and you do so at your own risk.  Once Gunderson receives your information, it will make reasonable efforts to ensure its security on Gunderson’s systems. For more information about our security policies and measures used to protect your data, please contact <a href="mailto:madams@gunder.com">Marya Adams</a>.
          </p>
          <h4 className="color-dark-green landing-section-title">Data Retention.</h4>
          <p>
            Gunderson retains PII for as long as you have an open account on the Services or as otherwise necessary to provide you Services.  In some cases Gunderson may retain PII for longer, if doing so is necessary to comply with legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, Gunderson reserves the right to retain and use your information in a depersonalized or aggregated form (but not in a way that would identify you personally) in accordance with the Terms of Use and Privacy Policy.
          </p>
          <h4 className="color-dark-green landing-section-title">Non-US Users.</h4>
          <p>
            YOU EXPRESSLY CONSENT TO THE TRANSMISSION, COLLECTION, STORAGE, PROCESSING AND USE OF THE YOUR PII (AND ANALYTICS DERIVED FROM YOUR PII) IN ACCORDANCE WITH THIS PRIVACY POLICY.  YOUR PII MAY BE COLLECTED, STORED, PROCESSED, USED AND TRANSMITTED WITHIN, FROM AND TO THE COUNTRY WHERE IT WAS COLLECTED AND THE UNITED STATES.  UNITED STATES LAWS REGARDING THE USE OF PII MAY BE LESS STRINGENT THAN THE LAWS IN YOUR COUNTRY.
          </p>
          <h4 className="color-dark-green landing-section-title">Changes.</h4>
          <p>
            Gunderson reserves the right, at its sole discretion, to modify or replace this Privacy Policy, in whole or in part, at any time.  It is your responsibility to check the Privacy Policy periodically for changes.  Your continued use of the Service following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.  The Privacy Policy (as it applies to you) may not otherwise be amended except by written instrument executed by you and Gunderson.
          </p>
          <h4 className="color-dark-green landing-section-title">Contact.</h4>
          <p>
            If you have questions regarding this Privacy Policy, you may contact Gunderson at the following address:
          </p>
          <div className="landing-contact-info">
            <p>
              Gunderson Dettmer <br />
              ATTN: Marya Adams <br />
              550 Allerton Street <br />
              Redwood City, CA 94063 <br />
              Tel: 650-321-2400 <br />
              Email: fundportaladmin@gunder.com
            </p>
          </div>
        </div>
      </div>
      <br/>
    </div>
    <Footer />
  </div>
);

export default PrivacyPolicy;
