/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './styles.scss';

function clickHandle(event) {
  event.preventDefault();
}

const CollapseContainer = () => (
  <a href="#/" onClick={clickHandle} data-toggle="collapse" data-target="#collapse-toggler" className="toggle-btn" />
);

CollapseContainer.defaultProps = {
  containerContent: {},
};

export default CollapseContainer;

