import React from 'react';
import Tooltip from '../../components/Tooltip/index';
import { resolveSizeClass } from './../utils/common';
import './styles.scss';

type Props = { data: () => void, onChangeHandler: () => {} };

const ConfirmCheckBox = ({ data, onChangeHandler, forwardRef }: Props) => {
  let ref1 = null;
  let ref2 = null;
  if (forwardRef) {
    if (forwardRef.ref1) {
      ref1 = forwardRef.ref1;
    }
    if (forwardRef.ref2) {
      ref2 = forwardRef.ref2;
    }
  }

  return (
    <div className="row input-group-style">
      <div
        id={data.id[2]}
        className={`${resolveSizeClass(data.size)} bottom-space`}
      >
        <div>
          <p
            id={`title-${data.id[2]}`}
            className={`${
              data.isDisabled[0] && data.isDisabled[1] ? 'color-gray' : ''
            } tooltip-positioning col-lg-11 col-md-11 col-sm-11 col-xs-12`}
          >
            {data.title}
          </p>
          {data.showHelp ? (
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
              <Tooltip
                className="tooltip-positioning"
                data={{ message: data.helpText }}
              />
            </div>
          ) : null}
        </div>
        <div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <fieldset>
              <input
                type="checkbox"
                name={data.name}
                id={data.id[0]}
                defaultChecked={data.isSelected[0]}
                disabled={data.isDisabled[0]}
                onChange={onChangeHandler}
                ref={ref1}
              />
              <label
                id={`check${data.id[0]}`}
                htmlFor={data.id[0]}
                className={`${data.isDisabled[0] ? 'color-gray' : ''}`}
              >
                Yes
              </label>
            </fieldset>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <fieldset>
              <input
                type="checkbox"
                name={data.name}
                id={data.id[1]}
                defaultChecked={data.isSelected[1]}
                disabled={data.isDisabled[1]}
                onChange={onChangeHandler}
                ref={ref2}
              />
              <label
                id={`check${data.id[1]}`}
                htmlFor={data.id[1]}
                className={`${data.isDisabled[1] ? 'color-gray' : ''}`}
              >
                No
              </label>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmCheckBox.defaultProps = {
  data: {
    id: ['', ''],
    title: '',
    isSelected: [false, false],
    isDisabled: [false, false],
    name: '',
    size: '', // use other values for a custom size
    helpText: 'tooltip text',
    showHelp: true,
  },
  onChangeHandler: () => {},
};

export default ConfirmCheckBox;
