import React from 'react';
import './styles.scss';
// import TextInput from '../TextInput/index';
import Button from '../Button/index';

type Props = {
  inputData: Object,
  clickHandler: () => void,
  onChangeHandler: () => void,
};

/* const renderInput = (id, displayName, type, onChangeHandler) => {
  const inputDataTemp = {
    id,
    displayName,
    type,
    noTitle: true,
  };
  return <TextInput inputData={inputDataTemp} onChangeHandler={onChangeHandler} />;
}; */

const renderSubmit = (id, displayName, type, clickHandle, isDisabled) => {
  const inputDataTemp = {
    id,
    displayName,
    type,
    isDisabled,
  };
  return <Button inputData={inputDataTemp} clickHandler={clickHandle} />;
};
// FIXME need receive props for chilren info
const InlineSubmit = ({ inputData, clickHandler, onChangeHandler }: Props) => (
  <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 center-block">
    <div className="clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h6 className="color-black">{inputData.displayName}</h6>
      </div>
      <form onSubmit={clickHandler}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <br />
          <fieldset>
            <input
              onChange={onChangeHandler}
              onBlur={onChangeHandler}
              className="form-control input-style"
              placeholder="Email Address"
              type="email"
              disabled={inputData.disabled}
            />
          </fieldset>
          <br />
          {renderSubmit(
            'subid',
            'Submit',
            'submit',
            clickHandler,
            inputData.disabled
          )}
        </div>
      </form>
    </div>
  </div>
);

InlineSubmit.defaultProps = {
  data: {
    displayName: '',
    inputType: '',
    submitText: '',
    disabled: false,
  },
  clickHandler: () => {},
  onChangeHandler: () => {},
};

export default InlineSubmit;
