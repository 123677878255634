import type { Dispatch, GetState, ThunkAction } from '../../types';
export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER';

const API_URL = '/api';

export const updateAccessFirstTime = (email): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    const { data } = await axios.put(`${API_URL}/iqUsers/accessFirstTime`, { email });
    if (data.success) {
      dispatch({
        type: SET_ORGANIZATION_USER,
        notificationType: 'success',
        user: data.user
      });
    }
  };
