/* @flow */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import fundSettings from '../containers/FundSettings/reducer';
import fundLimitedPartners from '../containers/FundLimitedPartners/reducer';
import admin from '../containers/AdminDashboard/reducer';
import context from '../containers/AdminLogin/reducer';
import organizationContext from '../containers/OrganizationLogin/reducer';
import firmForm from '../containers/FirmForm/reducer';
import authContainerRe from '../containers/AuthenticatedContainer/reducer';
import questionnaireExperience from './../containers/QuestionnaireExperience/reducer';

export default history =>
  combineReducers({
    context,
    organizationContext,
    admin,
    fundSettings,
    fundLimitedPartners,
    firmForm,
    router: connectRouter(history),
    authContainerRe,
    questionnaireExperience,
  });
