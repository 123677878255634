import React from 'react';
import './styles.scss';


type Props = { data: Object, clickHandler: ()=>{} };

const IconTextLink = ({ data, clickHandler }: Props) => (
  <span id={data.id} className="icon-text-link">
    <a
      id={data.id}
      href={data.href}
      onClick={clickHandler}
      className={data.color}
      data-toggle={data.dataToggle}
      data-target={data.dataTarget}
    >
      <i id={data.id} className={`fa ${data.icon}`} aria-hidden="true" />
      <i id={data.id} className="fa-text">{data.text}</i>
    </a>
  </span>
);

IconTextLink.defaultProps = {
  data: {
    id: '',
    icon: 'fa-print',
    text: 'default',
    color: 'color-gray',
    dataToggle: '',
    dataTarget: '',
    href: '',
  },
  clickHandler: () => {},
};

export default IconTextLink;

