/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */
import React, { PureComponent } from 'react';

type Props = {};

export class HowTo extends PureComponent {
  props: Props;

  static defaultProps: {};

  componentDidMount() { }

  render() {
    return (
      <div className="container" style={{ fontSize: '16px' }}>
        <div className="row">
          <br />
          <br />
          <br />
          <div className="col-lg-12">
            <h4>HOW TO USE THE FUND FORMATION PORTAL</h4>
            <br />
          </div>
          <div className="col-lg-12">
            <p>
              <span className="color-dark-green">CREATE AT LEAST ONE INVESTOR:</span> Before you can
              begin filling out and signing Investor Questionnaires (IQs) on the portal, you will
              need to create at least ONE Investor associated with your organization. Investors are
              the entities or individuals you will use to invest and for which you need to complete
              IQs on the Portal. Click the <span className="color-dark-green">+&nbsp;Investor</span>{' '}
              button in the upper right of the Investor Questionnaire or Investors screen.
            </p>
            <img
              style={{width: '190px'}}
              src={require('./../../assets/images/Add_Investor_Image.png')}
              width="80px"
              alt="Add_Investor"
            />
            <br />
            <br />

            <p>
              Fill out the name of the Investor, the entity type, and all the relevant information
              requested. This will pre-populate your first IQ and the IQs of any subsequent funds
              the investor invests in on the Gunderson Dettmer Fund Formation Portal.
            </p>
            <p>
              <span className="color-dark-green">TO FILL OUT INVESTOR QUESTIONNAIRES:</span> 
              {' '}You’ll need to return to the 
              fund-specific link you received via email in order log in with your new account and complete the 
              subscription process. Note: The link may still be open in a separate browser tab. 
              The link will look something like this:
            </p>
            <img src={require('./../../assets/images/Example_IQ_Link_Screenshot.png')} width="350px" alt="" />
            <br />
            <br />
            <p>
              <span className="color-dark-green">SIGN IN:</span> On the right side, under FILL OUT
              MULTIPLE INVESTOR QUESTIONNAIRES, log in with the email address and password you used
              to create your account.
            </p>
            <img src={require('./../../assets/images/Login_Image.png')} width="350px" alt="" />
            <br />
            <br />

            <p>
              <span className="color-dark-green">CHOOSE AN INVESTOR:</span> Once logged in, use the
              down arrow to select a previously created Investor for which you will fill out an IQ
              on the Fund Formation Portal. Click <span className="color-dark-green">Start</span>.
              Note: If you still need to create an investor, click{' '}
              <span className="color-dark-green">Create an Investor</span> first.
            </p>
            <img
              src={require('./../../assets/images/Select_Investor_Image.png')}
              width="50%"
              alt=""
            />
            <br />
            <br />

            <p>
              If you select an Investor for which you have previously started, but not submitted, an
              IQ, you will return to that same IQ and have the ability to edit your answers. Once
              the Gunderson Fund Formation team has reviewed your subscription, the IQ will be
              locked and you'll need to contact us if changes need to be made. Contact
              the Gunderson Dettmer team member with whom you are working.
            </p>
            <p>
              <span className="color-dark-green">COMPLETE THE INVESTOR QUESTIONNAIRE:</span> Fill
              out the IQ completely. At the end of the IQ, you'll be able to enter the name, title
              (if needed) and email address of the person who should sign the IQ. The Portal will
              send an email to that person via DocuSign for electronic signature. If YOU will be
              signing the IQ, enter your email address. You will be able to sign via DocuSign within
              the Portal.
            </p>
            <p>
              <span className="color-dark-green">ADDITIONAL INVESTOR QUESTIONNAIRES:</span> You can
              return to the original emailed fund link to fill out additional IQs for the same fund
              using additional investors as needed.
            </p>
            <p>
              <span className="color-dark-green">MANAGE ACTIVE INVESTOR QUESTIONNAIRES:</span>{' '}
              Creating an IQ places it in a dashboard listing the Fund, the Investor, and the
              Status.
            </p>
            <img src={require('./../../assets/images/IQ_Content_Image.png')} width="100%" alt="" />
            <br />
            <br />

            <p>
              All IQs for your Investors can be found under <b>INVESTOR QUESTIONNAIRES</b>, with a
              status of:
            </p>
            <ul>
              <li>
                (1) <b>In Progress:</b> has been started, but not yet completed and signed by you;
              </li>
              <li>
                (2) <b>Under Review:</b> the Gunderson Dettmer Fund Formation team has received your
                IQ, and is reviewing it;
              </li>
              <li>
                (3) <b>Ready to Close:</b> your IQ has been reviewed and is ready to close, or
              </li>
              <li>
                (4) <b>Closed:</b> your investment has closed.
              </li>
            </ul>
            <p>Note: only IQs with a status of In Progress or Under Review remain editable.</p>
            <br />

            <p>
              <span className="color-dark-green">ACCESSING THE PORTAL: </span>
              You can access the Portal again at any time here:&nbsp;
              <a href="http://www.iq.gunder.com" className="color-dark-green">
                <u>http://www.iq.gunder.com</u>
              </a>
              , using the email address and password you created.
            </p>

            <p>
              <span className="color-dark-green">SUPPORT:</span>
              {' '} Contact the Gunderson Dettmer team with questions. For technical questions, contact {' '}
              <a href="mailto:fundportaladmin@gunder.com" className="color-dark-green">
                <u>fundportaladmin@gunder.com.</u>
              </a>
            </p>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default HowTo;
