import React from 'react';
import './styles.scss';


type Props = { data: Object, clickHandler: () => {} };

const TabIndexSection = ({ data, clickHandler }: Props) => (
  <li id={data.id} key={`${data.displayName}li`} className={`tab-index-section ${data.active}`}>
    <a id={data.id} onClick={clickHandler} href="#/" >{data.displayName}</a>
  </li>
);

TabIndexSection.defaultProps = {
  data: {
    displayName: '',
    active: '',
    id: '',
  },
  clickHandler: () => { },
};

export default TabIndexSection;

