/* eslint-disable react/sort-comp,
import/prefer-default-export,react/prefer-stateless-function,react/prop-types */
/* @flow */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import HeaderLogo from '../HeaderLogo/index';
import OrgUserProfile from '../OrgUserProfile';
import { getUrlParameter } from 'utils/utility';

const renderLogo = () => <HeaderLogo homePath="/" />;
type Props = {
  displayName: Object,
  isAuthenticated: boolean,
  logoutHandler: () => void,
  history: Object,
};
class FundDashboardHeader extends Component {
  props: Props;

  state = {
    opened: false,
  };
  static defaultProps = {
    displayName: 'Not provided',
    logoutHandler: () => {},
    history: {},
  };
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { logoutHandler } = this.props;
    const currentPath =
      typeof window !== 'undefined' && window.location
        ? window.location.pathname.split('/organization/')[1]
        : '/';
    return (
      <header className="header-wrap no-spacer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 visible-xs-block">
              {renderLogo()}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 hidden-xs">
              {renderLogo()}
            </div>
            {this.props.isAuthenticated && (
              <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                <OrgUserProfile
                  displayName={this.props.displayName}
                  logoutHandler={logoutHandler}
                  history={this.props.history}
                />
                {currentPath !== 'add' ? (
                  <div>
                    <div className="menu-item-wrapper">
                      <NavLink
                        to="howtouse"
                        className={`menu-item ${
                          currentPath === 'howtouse' ? 'active-tab' : ''
                        }`}
                      >
                        HOW TO USE
                      </NavLink>
                    </div>
                    <div className="menu-item-wrapper">
                      <NavLink
                        to="limitedPartners"
                        className={`menu-item ${
                          currentPath === 'limitedPartners' ? 'active-tab' : ''
                        }`}
                      >
                        INVESTORS
                      </NavLink>
                    </div>
                    <div className="menu-item-wrapper">
                      <NavLink
                        to="questionnaires"
                        className={`menu-item ${
                          currentPath === 'questionnaires' ? 'active-tab' : ''
                        }`}
                      >
                        INVESTOR QUESTIONNAIRES
                      </NavLink>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

export default FundDashboardHeader;
