// import Cookies from 'universal-cookie';
import type {
  Dispatch,
  GetState,
  ThunkAction,
  // Reducer,
} from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const SET_FUNDS = 'SET_FUNDS';
export const SET_FIRMS = 'SET_FIRMS';
export const SET_EDIT_FIRM = 'SET_EDIT_FIRM';
export const SET_USER = 'SET_USER';
export const SET_TAB = 'SET_TAB';
export const SET_CAN_LOAD_FUNDS = 'SET_CAN_LOAD_FUNDS';
export const SET_CAN_LOAD_FIRMS = 'SET_CAN_LOAD_FIRMS';
export const SET_DASHBOARD_FUND = 'SET_DASHBOARD_FUND';
export const LOAD_LENGTH = 15;

const API_URL = '/api';
export const getConfig = () => {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
      }`,
    },
  };
};

export const setTab = (tab): ThunkAction => (dispatch: Dispatch) => {
  dispatch({ type: SET_TAB, selectedTab: tab });
};

export const resetLoadMore = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch({ type: SET_CAN_LOAD_FUNDS, canLoadFunds: true });
  dispatch({ type: SET_CAN_LOAD_FIRMS, canLoadFirms: true });
};

/* istanbul ignore next */
export const searchFirms = (term): ThunkAction =>
  // eslint-disable-next-line arrow-body-style
  (dispatch: Dispatch, getState: GetState, axios: any) => {
    return axios
      .get(`${API_URL}/firm/search/${term}`, getConfig())
      .then((res) => {
        dispatch({ type: SET_FIRMS, firms: res.data.firms });
        // if (res.data.firms.length < LOAD_LENGTH) {
        //   dispatch({ type: SET_CAN_LOAD_FIRMS, canLoadFirms: false });
        // }
      })
      .catch((err) => {
        handleError(err, getState(), dispatch);
      });
  };

/* istanbul ignore next */
export const searchFunds = (term): ThunkAction =>
  // eslint-disable-next-line arrow-body-style
  (dispatch: Dispatch, getState: GetState, axios: any) => {
    return axios
      .get(`${API_URL}/fund/search/${term}`, getConfig())
      .then((res) => {
        dispatch({ type: SET_FUNDS, funds: res.data.funds });
        // if (res.data.firms.length < LOAD_LENGTH) {
        //   dispatch({ type: SET_CAN_LOAD_FIRMS, canLoadFirms: false });
        // }
      })
      .catch((err) => {
        handleError(err, getState(), dispatch);
      });
  };
