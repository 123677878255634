import React from 'react';
import './styles.scss';

type Props = { active: String, clickHandler: () => void };

const defaultClickHandle = () => { };


const VerticalMenu = ({ active, clickHandler }: Props) => (
  <div className="vertical-menu">
    <ul className="vertical-menu-container">
      <li><a className={active === 'profile' ? 'active' : ''} name="profile" onClick={clickHandler} href="#/">Profile</a></li>
      <li><a className={active === 'organization' ? 'active' : ''} name="organization" onClick={clickHandler} href="#/">Organization</a></li>
      <li><a className={active === 'users' ? 'active' : ''} name="users" onClick={clickHandler} href="#/">Users</a></li>
    </ul>
  </div>
);

VerticalMenu.defaultProps = {
  active: '',
  clickHandler: defaultClickHandle(),
};

export default VerticalMenu;

