/* eslint-disable no-unused-vars */
import type { Dispatch, GetState, ThunkAction } from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const SET_LP_FORM = 'SET_LP_FORM';
export const RESET_FORMS_LP = 'RESET_FORMS_LP';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

const API_URL = '/api';

function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' &&
        localStorage.getItem('organization-auth-token')
          ? localStorage.getItem('organization-auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const saveLimitedPartner = (lpPayload, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.post(
      `${API_URL}/organizations/${lpPayload.organization}/lp/`,
      lpPayload,
      getConfig()
    );
    if (res.data.success) {
      dispatch({
        type: 'SET_ORGANIZATION',
        organization: res.data.organization,
      });
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'success',
        message: res.data.message,
        title: 'Investor saved successfully',
      });
    } else {
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'error',
        message: res.data.message,
        title: 'Something went wrong',
      });
    }
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

export const editLimitedPartner = (updatePayload): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.put(
      `${API_URL}/organizations/${updatePayload.organization}/lp/`,
      updatePayload,
      getConfig()
    );
    getState().addInvestor = false;
    // document.getElementById('close-lp-modal').click();
    if (res.data.success) {
      dispatch({
        type: 'SET_ORGANIZATION',
        organization: res.data.organization,
      });
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'success',
        message: res.data.message,
        title: 'Edit Success',
      });
    } else {
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'error',
        message: res.data.message,
        title: 'Something went wrong',
      });
    }
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};
