/* eslint-disable react/sort-comp */
/* @flow */

import React from 'react';
import Helmet from 'react-helmet';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';

// Export this for unit testing more easily
const TermsOfUse = () => (
  <div className="main-container">
    <DynamicHeader />
    <Helmet title="Terms of Use" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h4>Terms of Use</h4>
          <h4 className="color-dark-green landing-section-title">Acceptance of Terms.</h4>
          <p>
            The following terms and conditions govern all use of the website located at <a href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/privacy`}>Privacy Policy</a> and any related products, services, and applications (the <b>Services</b>), including all of the materials, documents, text, images, graphics, animation, videos and other information and content included in or available through the Services (<b>Content</b>).  The Services are owned and operated by Gunderson Dettmer Stough Villeneuve Franklin & Hachigian LLP (<b>Gunderson</b>).
          </p>
          <p>
            The Service is offered and provided subject to acceptance of all of the terms and conditions contained herein (<b>Terms of Use</b>).  The Terms of Use shall be deemed to include all other operating rules, conditions, policies and procedures that are referred to herein or that may otherwise be published on the Services by Gunderson from time to time, including without limitation, the Privacy Policy (collectively, <b>Policies</b>).
          </p>
          <p>
            The Service is available only to individuals who are at least 18 years old, who are acting for themselves or in their capacity as an employee or representative of a business entity or other organization (in which case you represent and warrant that you are authorized to agree to the Terms of Use on behalf of yourself and such entity or organization).  Gunderson may refuse to offer the Service to any individual or entity and may change its eligibility criteria, at any time, in its sole discretion.
          </p>
          <p>
            <b>
              IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE, OR IF YOU ARE NOT ELIGIBLE OR AUTHORIZED TO DO SO, THEN DO NOT REGISTER FOR, ACCESS OR USE THE SERVICE.  COMPLETING ANY REGISTRATION PROCESS OR OTHERWISE ACCESSING OR USING ANY PART OF THE SERVICE WILL CONSTITUTE ACCEPTANCE OF, AND CREATE A LEGALLY ENFORCEABLE CONTRACT UNDER WHICH YOU AGREE TO BE BOUND BY, ALL OF THE TERMS OF USE, WITHOUT MODIFICATION.
            </b>
          </p>
          <h4 className="color-dark-green landing-section-title">Changes.</h4>
          <p>
            Gunderson reserves the right, at its sole discretion, to modify or replace any of the Terms of Use (including any Policy) at any time.  It is your responsibility to check the Terms of Use periodically for changes.  Your continued use of the Service following the posting of any changes to the Terms of Use constitutes acceptance of those changes.  The Terms of Use (as it applies to you) may not otherwise be amended except by written instrument executed by you and Gunderson.
          </p>
          <h4 className="color-dark-green landing-section-title">Legal Information.</h4>
          <p>
            All Content is provided for informational purposes only and may not reflect the most current legal developments. The Content may be considered advertising for legal services under the laws and rules of professional conduct of the jurisdictions in which we practice. The Content is not offered as legal or any other advice on any particular matter. The transmission and receipt of information through the Services, in whole or in part, or communication with Gunderson via the Internet or e-mail through the Services, is not intended to create and does not constitute a lawyer-client relationship between you and Gunderson. You should not act or refrain from acting on the basis of any Content without seeking the appropriate legal or professional advice on the particular facts and circumstances at issue.
          </p>
          <h4 className="color-dark-green landing-section-title">Registration.</h4>
          <p>
            From time to time, at its sole discretion, Gunderson may make all or any part of the Service available only to registered users (such as, for example, to apply for, subscribe or gain access to various information services).  You may elect to register or not, at your discretion, but if you elect not to register then you may not be able to access or use certain features of the Service.  The registration process may require you to provide your name, company name, address, telephone number, email address and certain additional personal data or other information (such as, for example, your preferred contact method).
          </p>
          <h4 className="color-dark-green landing-section-title">Privacy.</h4>
          <p>
            Your use of the Services and any personal information you provide through the Services is subject to the privacy policy available at <a href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/privacy`}>{`${window.location.protocol}//${window.location.hostname}:${window.location.port}/privacy`}</a> (the <b>Privacy Policy</b>), which is incorporated by this reference.
          </p>
          <h4 className="color-dark-green landing-section-title">Rules and Conduct.</h4>
          <p>
            As conditions of your use:  you promise not to use the Service for any purpose that is unlawful or prohibited by the Terms of Use, or any other purpose not reasonably intended by Gunderson; and you agree to abide by all applicable local, state, national and international laws and regulations applicable to your use of the Service. You acknowledge that you, and not Gunderson, are entirely responsible for all information, data text, messages and/or other materials that you upload, post, e-mail, transmit or otherwise make available through the Services.
          </p>
          <p>
            By way of example and not limitation, you agree not to take any action, or submit, upload or otherwise distribute any content or information, that:
          </p>
          <ul>
            <li>
              is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, profane or which otherwise violates the Terms of Use;
            </li>
            <li>
              infringes or violates any trademark, trade secret, copyright or other intellectual property or proprietary right (including rights of publicity or privacy);
            </li>
            <li>
              imposes an unreasonable or disproportionately large load on Gunderson’s computing, storage or communications infrastructure, or attempts to gain unauthorized access to the Service, other accounts, computer systems or networks connected to the Service, through password mining or otherwise;
            </li>
            <li>
              contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data or other information of Gunderson or any third party;
            </li>
            <li>
              harvests, scrapes or collects any information from the Services; or
            </li>
            <li>
              impersonates any person or entity, including any employee or representative of Gunderson.
            </li>
          </ul>
          <h4 className="color-dark-green landing-section-title">Proprietary Rights.</h4>
          <p>
            You agree that the Content and all other aspects of the Service are protected by copyrights, trademarks, service marks, trade secrets and/or other proprietary rights and laws.  Unless and only to the extent expressly authorized by Gunderson in writing, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from the Service.  Systematic retrieval of data or other Content to create or compile, directly or indirectly, a collection, database or directory without written permission from Gunderson is prohibited.
          </p>
          <p>
            Except as expressly specified in the Terms of Use, no other license is granted (by implication, estoppel or otherwise), no other use is permitted and Gunderson (and its licensors) shall own and retain all rights, title and interests in and to the Content and all other aspects of the Service.
          </p>
          <h4 className="color-dark-green landing-section-title">Termination.</h4>
          <p>
            Gunderson may remove any Content or disable the Service (or terminate your access to all or any part of the Service) at any time, with or without cause, with or without notice, effective immediately.  You may discontinue using the Service at any time.  Upon any termination or discontinuance, you will immediately cease using the Services and Content.
          </p>
          <h4 className="color-dark-green landing-section-title">No Warranties.</h4>
          <p>
            THE SERVICES AND CONTENT ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.  NEITHER GUNDERSON NOR ANY OF ITS PARTNERS, LICENSORS, OR AFFILIATES, REPRESENT OR WARRANT THAT: (A) THE SERVICE WILL BE TIMELY, ACCURATE, COMPLETE, RELIABLE OR CORRECT; (B) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (C) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (D) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (E) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS.  YOUR USE OF THE SERVICE IS VOLUNTARY AND SOLELY AT YOUR OWN RISK.
          </p>
          <h4 className="color-dark-green landing-section-title">Limitation of Liability.</h4>
          <p>
            IN NO EVENT SHALL GUNDERSON, OR ITS PARTNERS, LICENSORS, OR AFFILIATES, BE LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR ANY (A) MATTER BEYOND ITS REASONABLE CONTROL, (B) LOSS OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGY, GOODS OR SERVICES, (C) INVESTMENT LOSSES OR INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES, PROFITS OR GOODWILL), OR (D) DAMAGES, IN THE AGGREGATE, IN EXCESS OF US$100, EVEN IF GUNDERSON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN.
          </p>
          <p>
            SOME STATES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <h4 className="color-dark-green landing-section-title">Indemnification.</h4>
          <p>
            You agree to indemnify and hold harmless Gunderson, its partners and affiliates, and its and their officers, directors, employees and representatives, from all liabilities, claims, and expenses, including attorneys' fees and expenses, that arise from your use or misuse of the Service or violation of these Terms of Use.  Gunderson reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Gunderson in asserting any available defenses. You shall not settle any claim without the express written consent of Gunderson, which may be granted in Gunderson’s sole discretion.
          </p>
          <h4 className="color-dark-green landing-section-title">International Use.</h4>
          <p>
            You expressly consent to the transmission, collection, storage, processing and use of your data from, to and within the United States, in addition to the country where you are located (if not the United States).  gunderson makes no representation that the Service is accessible, appropriate or legally available for use in locations outside the United States, and accessing and using the Service (including with regard to the provision, collection or processing of data) is prohibited from territories where doing so would be illegal.  If you access or use the Service from other locations, then you do so at your own initiative and risk and are solely responsible for compliance with local laws.
          </p>
          <h4 className="color-dark-green landing-section-title">Dispute Resolution.</h4>
          <p>
            A printed version of the Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Service or Terms of Use (including any Policy) to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  You and Gunderson agree that any claim or cause of action arising out of or related to the Service must be commenced within 1 year after the claim or cause of action arose.  Otherwise, such claim or cause of action is permanently barred.
          </p>
          <p>
            The Terms of Use shall be governed by and construed in accordance with the laws of the State of California, USA, excluding its conflicts of law rules.  In the event of any conflict between US and foreign laws, regulations and rules, US laws, regulations and rules shall govern.
          </p>
          <p>
            Except that either party may seek an injunction or other equitable relief at any time from any court of competent jurisdiction, all disputes between the parties arising out of or in relation to or in connection with the Service or Terms of Use shall be settled by binding arbitration in accordance with the JAMS commercial arbitration rules and procedures then in force, by one neutral arbitrator appointed in accordance with the rules.  The arbitration shall take place in San Francisco, California, USA.  The proceedings shall be in English, all evidence shall be in English (or translated into English) and the governing law shall be as set forth herein.  The arbitrator's decision shall be in writing and shall comply with all terms and conditions in the applicable version of the Terms of Use.  The decision and award rendered shall be final and binding on both parties.  The parties acknowledge and agree that the Terms of Use and any award rendered pursuant hereto shall be governed by the UN Convention on the Recognition and Enforcement of Foreign Arbitral Awards.  Judgment on the award may be entered in any court of competent jurisdiction.
          </p>
          <p>
            <b>
              ANY ARBITRATION UNDER THE TERMS OF USE WILL TAKE PLACE ONLY ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.  YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THE TERMS OF USE OR USING THE SERVICE, YOU AND GUNDERSON ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY AND TO PARTICIPATE IN A CLASS ACTION.
            </b>
          </p>
          <p>
            Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of the Terms of Use, including without limitation, this section.
          </p>
          <h4 className="color-dark-green landing-section-title">Integration and Severability.</h4>
          <p>
            The Terms of Use are the entire agreement, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic), between you and Gunderson with respect to the Service.  If any provision of the Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Use will otherwise remain in full force and effect and enforceable.
          </p>
          <h4 className="color-dark-green landing-section-title">Miscellaneous.</h4>
          <p>
            The Terms of Use and access to the Service contemplated hereunder are personal to you, and are not assignable, transferable or sublicensable by you except with Gunderson’s prior written consent.  Gunderson may freely assign, transfer or delegate any of its rights or obligations hereunder without your consent.  All waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.  No agency, partnership, joint venture, or employment relationship is created hereunder and neither party has any authority of any kind to bind the other in any respect.  The failure of either you or Gunderson to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder.  All notices hereunder will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or email; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service.
          </p>
          <h4 className="color-dark-green landing-section-title">Contact.</h4>
          <p>
            If you have questions regarding the Terms of Use, you may contact Gunderson at the following address:
          </p>

          <div className="landing-contact-info">
            <p>
              Gunderson Dettmer <br />
              ATTN: Marya Adams <br />
              550 Allerton Street <br />
              Redwood City, CA 94063 <br />
              Tel: 650-321-2400 <br />
              Email: fundportaladmin@gunder.com
            </p>
          </div>
        </div>
      </div>
      <br/>
    </div>
    <Footer />
  </div>
);

export default TermsOfUse;
