import React from 'react';
import './styles.scss';
import Tooltip from '../Tooltip/index';

type Props = {
  inputData: Object,
  onChangeHandler: () => {},
  onBlurHandler: () => {},
};

const TextArea = ({
  inputData,
  reference,
  onChangeHandler,
  onBlurHandler,
}: Props) => (
  <div
    className={`form-group input-group-style ${
      inputData.hasError ? 'error' : ''
    }`}
  >
    {inputData.showHelp ? (
      <Tooltip
        className="tooltip-positioning"
        data={{ message: inputData.helpText, extraClass: 'tooltip-margin' }}
      />
    ) : null}
    {inputData.description && inputData.description !== '' ? (
      <div className="text-area-description">
        <h5 className={`color-black ${inputData.disabled ? 'color-gray' : ''}`}>{inputData.title}</h5>
        <p className={`color-black ${inputData.disabled ? 'color-gray' : ''}`}>{inputData.description}</p>
      </div>
    ) : null}
    <fieldset>
      <textarea
        id={inputData.id}
        cols=""
        rows=""
        ref={reference}
        className="form-control input-style"
        onBlur={onBlurHandler}
        placeholder={inputData.displayName}
        onChange={onChangeHandler}
        disabled={inputData.disabled}
        value={inputData.value}
      />
    </fieldset>
    {inputData.hasError ? (
      <div className="error-message">
        <span className="text">{inputData.errorMessage}</span>
      </div>
    ) : null}
  </div>
);

TextArea.defaultProps = {
  data: {
    id: '',
    displayName: '',
    description: '',
    title: '',
    size: '',
    isRequired: false,
    hasError: false,
    errorMessage: '',
    value: '',
    wrapperClass: '',
    helpText: 'tooltip text',
    showHelp: false,
  },
  onBlurHandler: () => {},
};

export default TextArea;
