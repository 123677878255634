import React from 'react';
import './styles.scss';

type Props = { progress: Object };
const ProgressBarDescription = ({ progress }: Props) => (
  <div className="progress-description">
    PROGRESS <span>{progress}%</span>
  </div>
);

export default ProgressBarDescription;
