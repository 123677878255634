/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */

import React, { PureComponent } from 'react';
import './../../../styles_common/questionnaire_index.scss';
import IndexSection from '../../../../../components/IndexSection/index';

type Props = {};

const initialState = {
  indexSelection: '',
};
// Export this for unit testing more easily
export class QuestionnaireIndex extends PureComponent {
  props: Props;
  state = initialState;

  static defaultProps: {};

  componentDidMount() {
    this.addScrollListener();
    this.verifyIndexToHighlight();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.pageScrollHandler);
  }

  addScrollListener = () => {
    document.addEventListener('scroll', this.pageScrollHandler);
  };

  // eslint-disable-next-line class-methods-use-this
  pageScrollHandler = (e) => {
    e.preventDefault();
    this.verifyIndexToHighlight();
  };

  verifyIndexToHighlight = () => {
    function isScrolledIntoView(el) {
      const elemTop = el.getBoundingClientRect().top;

      const elementShouldBeHighlighted = elemTop >= -50 && elemTop <= 50;
      return elementShouldBeHighlighted;
    }
    if (isScrolledIntoView(document.getElementById('content1'))) {
      this.setState({ indexSelection: 'content1' });
    }
    if (isScrolledIntoView(document.getElementById('content2'))) {
      this.setState({ indexSelection: 'content2' });
    }
    if (isScrolledIntoView(document.getElementById('content3'))) {
      this.setState({ indexSelection: 'content3' });
    }
    if (isScrolledIntoView(document.getElementById('content4'))) {
      this.setState({ indexSelection: 'content4' });
    }
    if (isScrolledIntoView(document.getElementById('content5'))) {
      this.setState({ indexSelection: 'content5' });
    }
    if (isScrolledIntoView(document.getElementById('content6'))) {
      this.setState({ indexSelection: 'content6' });
    }
    if (isScrolledIntoView(document.getElementById('content7'))) {
      this.setState({ indexSelection: 'content7' });
    }
    if (isScrolledIntoView(document.getElementById('content8'))) {
      this.setState({ indexSelection: 'content8' });
    }
    if (isScrolledIntoView(document.getElementById('content9'))) {
      this.setState({ indexSelection: 'content9' });
    }
    if (isScrolledIntoView(document.getElementById('content10'))) {
      this.setState({ indexSelection: 'content10' });
    }
  };

  resolveSectionData = (
    sectionTitle,
    contentId,
    hasSubsection,
    subSections,
    status,
    clickHandler
  ) => {
    const sectionData = {
      sectionTitle,
      contentId,
      hasSubsection,
      subSections,
      status,
      clickHandler,
    };
    return sectionData;
  };

  renderSection = (sectionData) => (
    <IndexSection
      key={sectionData.contentId}
      data={this.resolveSectionData(
        sectionData.sectionTitle,
        sectionData.contentId,
        sectionData.hasSubsection,
        sectionData.subSections,
        sectionData.status,
        sectionData.clickHandler
      )}
    />
  );

  handleIndexClick = (e) => {
    e.preventDefault();
    const contentId =
      e.target.id.indexOf('subIndex') > -1
        ? e.target.id.split('subIndex')[1]
        : e.target.id.split('index')[1];
    const scrollToSection = document.getElementById(contentId);
    this.setState({ indexSelection: contentId }, () => {
      scrollToSection.scrollIntoView(true);
    });
  };
  // FIXME render section with list.map approach
  render() {
    const templateSection1 = {
      sectionTitle: 'A. General Investor Representations and Warranties',
      contentId: 'content1',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content1' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection2 = {
      sectionTitle: 'B. Identification of Investor Status',
      contentId: 'content2',
      hasSubsection: true,
      subSections: [],
      status: this.state.indexSelection === 'content2' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection3 = {
      sectionTitle: 'C. Accredited Investor Representations',
      contentId: 'content3',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content3' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection4 = {
      sectionTitle: 'D. Qualified Purchaser Representations',
      contentId: 'content4',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content4' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection5 = {
      sectionTitle: 'E. Qualified Client Representations',
      contentId: 'content5',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content5' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection6 = {
      sectionTitle: 'F. Committee on Foreign Investment',
      contentId: 'content6',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content6' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection7 = {
      sectionTitle: 'G. Wiring Bank Status',
      contentId: 'content7',
      hasSubsection: true,
      subSections: [],
      status: this.state.indexSelection === 'content7' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection8 = {
      sectionTitle: 'H. Identification of Special Entities',
      contentId: 'content8',
      hasSubsection: true,
      subSections: [],
      status: this.state.indexSelection === 'content8' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection9 = {
      sectionTitle: 'I. Miscellaneous',
      contentId: 'content9',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content9' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    const templateSection10 = {
      sectionTitle: 'J. Exceptions to Representations and Warranties',
      contentId: 'content10',
      hasSubsection: false,
      subSections: [],
      status: this.state.indexSelection === 'content10' ? 'active' : '',
      clickHandler: this.handleIndexClick,
    };
    return (
      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div
          className="affix-container"
          ref={(container) => {
            this.container = container;
          }}
        >
          <aside className="sidebar">
            <ul className="questionaire-index">
              {this.renderSection(templateSection1)}
              {this.renderSection(templateSection2)}
              {this.renderSection(templateSection3)}
              {this.renderSection(templateSection4)}
              {this.renderSection(templateSection5)}
              {this.renderSection(templateSection6)}
              {this.renderSection(templateSection7)}
              {this.renderSection(templateSection8)}
              {this.renderSection(templateSection9)}
              {this.renderSection(templateSection10)}
            </ul>
          </aside>
        </div>
      </div>
    );
  }
}

/* const connector: Connector<{}, Props> = connect(
 ({ home }: Reducer) => ({ home }),
 (dispatch: Dispatch) => ({
 fetchUsersIfNeeded: () => dispatch(action.fetchUsersIfNeeded()),
 }),
 );

 export default connector(Home); */
