/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import _ from 'lodash';
import { Route, Switch, withRouter } from 'react-router-dom';
import './styles.scss';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';
import NotFoundPage from './../NotFound';
import { Reducer } from '../../types';

import QuestionnaireHome from './../QuestionnaireExperience';
import QuestionnaireLogin from './../QuestionnaireExperience/Login';

type Props = {
  context: any,
};
export class AccessContainer extends PureComponent {
  props: Props;

  static defaultProps: {};

  accessRoutes = () => [
    {
      path: '/access/login',
      exact: true,
      component: QuestionnaireLogin,
    },
    {
      path: '/access/home',
      component: QuestionnaireHome,
    },
    {
      path: '*',
      component: NotFoundPage,
    },
  ];
  routeWithSubRoutes = route => (
    <Route
      key={_.uniqueId()}
      exact={route.exact || false}
      path={route.path}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );

  render() {
    return (
      <div>
        <div className="content-wrapper">
          <DynamicHeader homePath={'/'} />
          <Switch>{this.accessRoutes().map(route => this.routeWithSubRoutes(route))}</Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state: Reducer) => ({ context: state.context });

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default withRouter(connector(AccessContainer));
