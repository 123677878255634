import type { Dispatch, GetState, ThunkAction } from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const SET_EDIT_FIRM = 'SET_EDIT_FIRM';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

const API_URL = '/api';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const saveFirm =
  (firmPayload, history): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.post(
        `${API_URL}/firm/`,
        firmPayload,
        getConfig()
      );
      if (res.data.success) {
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'success',
          message: res.data.message,
          title: 'Save Success',
        });
      } else {
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'error',
          message: res.data.message,
          title: 'Something went wrong',
        });
        dispatch({ type: 'UPDATE_LOADER_STATUS', isLoading: false });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const editFirm =
  (updatePayload, history, returnUrl): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.put(
        `${API_URL}/firm/`,
        updatePayload,
        getConfig()
      );
      if (res.data.success) {
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'success',
          message: res.data.message,
          title: 'Save Success',
        });
        setTimeout(() => {
          const path = history.location.pathname.split(updatePayload._id)[0];
          if (path === '/admin/main/editFirm/') {
            history.push(returnUrl);
            dispatch({ type: 'UPDATE_LOADER_STATUS', isLoading: false });
          }
        }, 1000);
      } else {
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'error',
          message: res.data.message,
          title: 'Something went wrong',
        });
        dispatch({ type: 'UPDATE_LOADER_STATUS', isLoading: false });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const getFirmById =
  (firmId, updateFormCallback: () => void): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.get(`${API_URL}/firm/${firmId}`, getConfig());
      if (res.data.success) {
        dispatch({ type: SET_EDIT_FIRM, firm: res.data.firm });
        updateFormCallback(res.data.firm);
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const clearFirm = (): ThunkAction => async (dispatch: Dispatch) => {
  dispatch({ type: SET_EDIT_FIRM, firm: {} });
};
