/* eslint-disable react/sort-comp,import/prefer-default-export,no-shadow, no-loop-func,
 no-nested-ternary */

/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import './../../../../styles_common/questionnaire_form.scss';
import * as notify from '../../../../../../utils/notify';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';
import TextInput from '../../../../../../components/TextInput/index.uncontrolled';
import Button from '../../../../../../components/Button/index';
import TextArea from '../../../../../../components/TextArea/index.uncontrolled';
import Title from '../../../../../../components/Title/index';
import ConfirmCheckbox from '../../../../../../components/ConfirmCheckbox/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import { QuestionnaireIndex } from '../QuestionnaireIndex/index.v1';
import Alert from '../../../../../../components/Alert/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import FundTool from '../../../../../../components/FundTool/index';
import Tooltip from '../../../../../../components/Tooltip/index';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import DateTimeZone from '../../../../../../utils/date-time-zone';

type Props = {
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};

export class QuestionnaireForm extends PureComponent {
  props: Props;

  static defaultProps: {
    context: {},
  };

  componentDidMount() {
    this.updateTextAnswersState();
    notify.hideNotification();
  }

  updateTextAnswersState = () => {
    // repopulate text inputs values if they exist
    const id_wiring_bank_status_Data = this.parseQuestionDataFromId(
      iqID.id_wiring_bank_status_1_opt
    );
    const id_special_entities_1_b_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_1_b_opt
    );
    const id_special_entities_2_e_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_2_e_opt
    );
    const id_warranties_exceptions_Data = this.parseQuestionDataFromId(
      iqID.id_warranties_exceptions
    );

    if (this.id_wiring_bank_status_1_opt_Ref) {
      this.id_wiring_bank_status_1_opt_Ref.value = id_wiring_bank_status_Data
        ? id_wiring_bank_status_Data.answer
        : '';
    }
    if (this.id_special_entities_1_b_opt_Ref) {
      this.id_special_entities_1_b_opt_Ref.value =
        id_special_entities_1_b_opt_Data
          ? id_special_entities_1_b_opt_Data.answer
          : '';
    }

    if (this.id_special_entities_2_e_opt_Ref) {
      this.id_special_entities_2_e_opt_Ref.value =
        id_special_entities_2_e_opt_Data
          ? id_special_entities_2_e_opt_Data.answer
          : '';
    }

    this.id_warranties_exceptions_Ref.value = id_warranties_exceptions_Data
      ? id_warranties_exceptions_Data.answer
      : '';
  };
  handlerFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    const nextStep = questionnaireContext.currentStep + 1;
    this.props.handleRedirect(event, nextStep);
  };
  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
  };

  parseCheckboxStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );

      if (targetQuestion) {
        return [
          targetQuestion.answer === 'Yes',
          targetQuestion.answer === 'No',
        ];
      }
      return [false, false];
    }
    return [false, false];
  };
  parseMultiSelectStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion.answer === 'Yes';
      }
      return false;
    }
    return false;
  };
  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };
  handlerConfirm = (event) => {
    event.preventDefault();
    const answer = event.target.nextSibling.innerHTML;
    const descriptionIdentifier =
      event.target.closest('div').parentElement.parentElement.id;
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };
  orQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id !== _identifier &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // right now this is saving one by one . But ideally we should do bulks updates or at least Promise all
        const answer = 'No';
        const descriptionIdentifier = item.id;

        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  regularQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id.includes('_or') &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // can be simplified with the orQuestionSelected on top look for better naming
        const answer = 'No';
        const descriptionIdentifier = item.id;
        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  // send the belonging section here
  handlerMultiSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    if (event.target.id.includes('_or')) {
      this.orQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    } else {
      // if any other is marked verify that or question is not selected from the targetSection
      this.regularQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    }
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  // send the belonging section here
  handlerSingleSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    this.orQuestionSelected(
      event.target.checked,
      event.target.id,
      _targetSection
    );
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  accreditedInvestorRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const accreditedInvestorRepresentationsQuestions =
      questionnaireContext.currentVersionQuestions
        .accreditedInvestorRepresentations;
    const results = [];
    for (
      let i = 0;
      i < accreditedInvestorRepresentationsQuestions.length;
      i += 1
    ) {
      results.push({
        id: accreditedInvestorRepresentationsQuestions[i].id,
        displayName: ReactHtmlParser(
          accreditedInvestorRepresentationsQuestions[i].displayName
        ),
        name: accreditedInvestorRepresentationsQuestions[i].name,
        identifier: accreditedInvestorRepresentationsQuestions[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          accreditedInvestorRepresentationsQuestions[i].id
        ),
        helpText: accreditedInvestorRepresentationsQuestions[i].helpText,
        showHelp: accreditedInvestorRepresentationsQuestions[i].showHelp,
        disabled: disabledList.includes(
          accreditedInvestorRepresentationsQuestions[i].id
        ), // this part should come from top part lists
      });
    }
    return results;
  };

  qualifiedPurchaserRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const results = [];
    const qualifiedPurchaserRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedPurchaserRepresentations;
    for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
      results.push({
        id: qualifiedPurchaserRepresentations[i].id,
        displayName: qualifiedPurchaserRepresentations[i].displayName,
        name: qualifiedPurchaserRepresentations[i].name,
        identifier: qualifiedPurchaserRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedPurchaserRepresentations[i].id
        ),
        helpText: qualifiedPurchaserRepresentations[i].helpText,
        showHelp: qualifiedPurchaserRepresentations[i].showHelp,
        disabled: disabledList.includes(
          qualifiedPurchaserRepresentations[i].id
        ),
      });
    }
    return results;
  };

  qualifiedClientRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const qualifiedClientRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedClientRepresentations;
    const results = [];
    for (let i = 0; i < qualifiedClientRepresentations.length; i += 1) {
      results.push({
        id: qualifiedClientRepresentations[i].id,
        displayName: qualifiedClientRepresentations[i].displayName,
        name: qualifiedClientRepresentations[i].name,
        identifier: qualifiedClientRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedClientRepresentations[i].id
        ),
        helpText: qualifiedClientRepresentations[i].helpText,
        showHelp: qualifiedClientRepresentations[i].showHelp,
        disabled: disabledList.includes(qualifiedClientRepresentations[i].id),
      });
    }
    return results;
  };

  finraNewIssuesContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const finraNewIssues =
      questionnaireContext.currentVersionQuestions.finraNewIssues;
    const results = [];
    for (let i = 0; i < finraNewIssues.length; i += 1) {
      if (
        (questionnaireContext.isNaturalPerson ||
          finraNewIssues[i].displayNameAlternate !== '') &&
        (!questionnaireContext.isNaturalPerson ||
          finraNewIssues[i].naturalPerson)
      ) {
        results.push({
          id: finraNewIssues[i].id,
          displayName: questionnaireContext.isNaturalPerson
            ? finraNewIssues[i].displayName
            : finraNewIssues[i].displayNameAlternate,
          name: finraNewIssues[i].name,
          identifier: finraNewIssues[i].identifier,
          // helpText: finraNewIssues[i].helpText,
          // showHelp: finraNewIssues[i].showHelp,
          isSelected: this.parseMultiSelectStateFromId(finraNewIssues[i].id),
          disabled: disabledList.includes(finraNewIssues[i].id),
        });
      }
    }
    return results;
  };

  finraNewIssuesContent2 = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const finraNewIssues_2 =
      questionnaireContext.currentVersionQuestions.finraNewIssues_2;
    const results = [];
    for (let i = 0; i < finraNewIssues_2.length; i += 1) {
      results.push({
        id: finraNewIssues_2[i].id,
        displayName: questionnaireContext.isNaturalPerson
          ? finraNewIssues_2[i].displayName
          : finraNewIssues_2[i].displayNameAlternate,
        name: finraNewIssues_2[i].name,
        identifier: finraNewIssues_2[i].identifier,
        helpText: finraNewIssues_2[i].helpText,
        showHelp: finraNewIssues_2[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(finraNewIssues_2[i].id),
        disabled: disabledList.includes(finraNewIssues_2[i].id),
      });
    }
    return results;
  };

  foreignPersonStatusContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations[i].id,
        displayName: foreignPersonStatusRepresentations[i].displayName,
        name: foreignPersonStatusRepresentations[i].name,
        identifier: foreignPersonStatusRepresentations[i].identifier,
        helpText: foreignPersonStatusRepresentations[i].helpText,
        showHelp: foreignPersonStatusRepresentations[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations[i].id
        ),
      });
    }
    return results;
  };

  foreignPersonStatusContent2 = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations2 =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations2.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations2[i].id,
        displayName: foreignPersonStatusRepresentations2[i].displayName,
        name: foreignPersonStatusRepresentations2[i].name,
        identifier: foreignPersonStatusRepresentations2[i].identifier,
        helpText: foreignPersonStatusRepresentations2[i].helpText,
        showHelp: foreignPersonStatusRepresentations2[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations2[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations2[i].id
        ),
      });
    }
    return results;
  };
  // This one should be handled as part of the optional questions set at the
  checkBadActorQuestionAbility = (identifier) => {
    if (identifier === iqID.id_investor_status_5_f) {
      if (
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_a) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_b) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_c) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_d) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_e)
      ) {
        return false; // to show return true;
      }
    }
    return true;
  };

  handleTextInputBlur = (event, currentValue) => {
    event.preventDefault();
    const answer = event.target.value;
    const descriptionIdentifier = event.target.id;
    const current = this.parseQuestionDataFromId(descriptionIdentifier);
    if (!current || current.answer !== currentValue) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
    }
  };
  renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled
  ) => {
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
    };
    return (
      <ConfirmCheckbox data={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  render() {
    const {
      context,
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { optionals, disabled: disabledList },
      },
    } = this.props;
    const readableDate =
      questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.updatedAt
        ? new DateTimeZone(questionnaireContext.investorFund.questionnaire.updatedAt)
        : '';
   const formattedDate = readableDate !== ''
        ? readableDate.getLocalDate()
        : readableDate;

    return questionnaireContext.investorFund.questionnaire.questions ? (
      <div className="margin-bottom-spacing">
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          printHandler={null}
          saveLaterHandler={null}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="row">
          {' '}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content-bg main-content-area clearfix">
              <div className="row">
                <QuestionnaireIndex />
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <div className="main">
                    <h4 className="color-dark-green align-header-text-sub-header questionnaire-header">
                      Questionnaire
                    </h4>
                    <b>
                      <Paragraph
                        text={
                          'In connection with the proposed investment in the Fund, except as otherwise specified herein (including in Section K), the undersigned hereby represents as follows:'
                        }
                        className="align-header-text-sub-header"
                      />
                    </b>
                    <br />
                    <form onSubmit={this.handlerFormSubmit}>
                      <Title
                        text={
                          'A. General Investor Representations and Warranties'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content1'}
                      />
                      {questionnaireContext.currentVersionQuestions.generalInvestorRepresentationsandWarranties.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <Paragraph text={item.displayName} />
                            {item.toolTip && (
                              <Tooltip
                                data={{
                                  message: item.toolTip,
                                  className: 'position-absolute-tool-tip',
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                      <br />
                      <Title
                        text={'B. Identification of Investor Status'}
                        className="align-header-text-sub-header"
                        contentId={'content2'}
                      />
                      {questionnaireContext.currentVersionQuestions.identificationofInvestorStatus.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <div id={item.id}>
                              {item.type === 'title' && (
                                <div className="align-text-push">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Paragraph text={item.displayName} />
                                </div>
                              )}
                              {item.type === 'quest' &&
                              this.checkBadActorQuestionAbility(item.id) &&
                              item.id !== iqID.id_investor_status_1
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm
                                  )
                                : item.type === 'quest' &&
                                  this.checkBadActorQuestionAbility(item.id) &&
                                  item.id === iqID.id_investor_status_1
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm,
                                    [
                                      !questionnaireContext.isNaturalPerson,
                                      questionnaireContext.isNaturalPerson,
                                    ]
                                  )
                                : null}
                              {/* this one may not be needed */}
                              {item.type === 'opt' &&
                              this.parseMultiSelectStateFromId(
                                item.id.split('_opt')[0]
                              ) === item.conditional
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm
                                  )
                                : null}
                            </div>
                          </div>
                        )
                      )}
                      <br />
                      <Title
                        text={'C. Accredited Investor Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content3'}
                      />
                      <div className="row">
                        <p id="accredited-investor-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is an Accredited Investor under Rule
                            501 of Regulation D promulgated by the Securities
                            and Exchange Commission and that the undersigned
                            qualifies as such because the undersigned is (check
                            applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.accreditedInvestorRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'accreditedInvestorRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'D. Qualified Purchaser Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content4'}
                      />
                      <div className="row">
                        <p id="qualified-purchaser-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Purchaser under
                            Section 2(a)(51) of the 1940 Act and that the
                            undersigned qualifies as such because the
                            undersigned is (check applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.qualifiedPurchaserRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'qualifiedPurchaserRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'E. Qualified Client Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content5'}
                      />
                      <div>
                        <div
                          id="qualified-client-principal-introduction"
                          className={`row ${
                            disabledList.includes(
                              'qualified-client-principal-introduction'
                            )
                              ? 'color-gray'
                              : ''
                          }`}
                        >
                          <p>
                            <b>
                              If the undersigned is a qualified purchaser (and
                              the undersigned indicated the basis under which it
                              qualifies as such in the immediately preceding
                              Section D), the undersigned is not required to
                              complete this Section E and should skip to Section
                              F.
                            </b>
                          </p>
                          <p>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Client under Rule
                            205-3 of the Advisers Act promulgated by the U.S.
                            Securities and Exchange Commission, and that the
                            undersigned qualifies as such because the
                            undersigned is (check all applicable boxes):
                          </p>
                        </div>
                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect3',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.qualifiedClientRepresentationsContent()}
                            onChangeHandler={(e) =>
                              this.handlerMultiSelect(
                                e,
                                'qualifiedClientRepresentations'
                              )
                            }
                          />
                        </div>
                      </div>

                      <br />
                      <Title
                        text={
                          'F. Committee on Foreign Investment in the United States (“CFIUS”) Foreign Person Status Representations'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content6'}
                      />
                      <Paragraph
                        text={
                          '1. The undersigned hereby represents and warrants that the undersigned is (check applicable box):'
                        }
                        className="align-header-text-sub-header"
                      />
                      <div className="row bottom-space">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent()}
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations'
                            )
                          }
                        />
                      </div>

                      <Paragraph
                        text={
                          '2. If the undersigned is an entity (i.e., not a natural person), the undersigned further represents and warrants that:'
                        }
                        tooltip={`
                        For purposes of this Section F, "investment fund" means any entity that is an "investment company," as defined in Section 3(a) of the 1940 Act, or would be an "investment company" but for one or more of the exemptions provided in Section 3(b) or 3(c) thereunder.

                        <br /><br />

For purposes of determining the percentage of voting interest held indirectly by one entity in another entity, any voting interest of a parent will be deemed to be a 100 percent voting interest in any entity of which it is a parent.
`}
                        className={`align-header-text-sub-header ${
                          questionnaireContext.isNaturalPerson && 'color-gray'
                        }`}
                      />
                      <div
                        className={`row ${
                          questionnaireContext.isNaturalPerson && 'color-gray'
                        }`}
                      >
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius-2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent2()}
                          subheaderText="Or, the undersigned hereby represents and warrants that:"
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations2'
                            )
                          }
                        />
                      </div>

                      <Title
                        text={'G. FINRA New Issues Questionnaire'}
                        className="align-header-text-sub-header"
                        contentId={'content7'}
                      />

                      <div>
                        <div
                          id="firna-new-issues-p"
                          className={`${
                            disabledList.includes('firna-new-issues-p')
                              ? 'color-gray'
                              : ''
                          } row`}
                        >
                          <p>
                            Please complete this Section G in order for the Fund
                            to be able to determine the extent to which the
                            undersigned may participate in the Fund’s purchase
                            of “new issue” securities in accordance with the
                            Financial Industry Regulatory Authority, Inc.
                            (“FINRA”) Rule 5130 (Restrictions on the Purchase
                            and Sale of Initial Equity Public Offerings) and
                            Rule 5131 (New Issue Allocations and Distributions).
                            All italicized terms are defined in Annex A below.
                          </p>
                        </div>

                        <p>1. FINRA Rule 5130:</p>

                        {!questionnaireContext.isNaturalPerson && (
                          <div className="row align-header-text-sub-header">
                            <p>
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; The undersigned
                              is an entity that is eligible to participate in
                              new issue allocations because:
                            </p>
                          </div>
                        )}
                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect-finra-new-issues',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.finraNewIssuesContent()}
                            onChangeHandler={(e) =>
                              questionnaireContext.isNaturalPerson
                                ? this.handlerSingleSelect(e, 'finraNewIssues')
                                : this.handlerMultiSelect(e, 'finraNewIssues')
                            }
                          />
                        </div>

                        <p>2. FINRA Rule 5131:</p>

                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect-finra-new-issues-2',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.finraNewIssuesContent2()}
                            onChangeHandler={(e) =>
                              this.handlerSingleSelect(e, 'finraNewIssues_2')
                            }
                          />
                        </div>

                        <div
                          className={`${
                            disabledList.includes('firna-new-issues-p')
                              ? 'color-gray'
                              : ''
                          } row`}
                        >
                          <br />
                          <p className="text-center">
                            <b>Annex A to FINRA New Issues Questionnaire</b>
                          </p>
                          <p>
                            Restricted Person. A restricted person is any of the
                            following:
                          </p>

                          <ol className="custom-text-list">
                            <li>A FINRA member firm or other broker-dealer.</li>
                            <li>
                              An officer, director, general partner, associated
                              person or employee of a FINRA member firm or any
                              other broker-dealer (other than a limited business
                              broker-dealer).
                            </li>
                            <li>
                              An agent of a FINRA member firm or any other
                              broker-dealer (other than a limited business
                              broker-dealer) that is engaged in the investment
                              banking or securities business.
                            </li>
                            <li>
                              With respect to the security being offered, a
                              finder or any person acting in a fiduciary
                              capacity to the managing underwriter, including,
                              but not limited to, attorneys, accountants and
                              financial consultants.
                            </li>
                            <li>
                              A person who has authority to buy or sell
                              securities for a bank, savings and loan
                              institution, insurance company, investment
                              company, investment advisor or collective
                              investment account.
                            </li>
                            <li>
                              Other than a sovereign entity, a person listed, or
                              required to be listed, in one of the following
                              schedules of a Form BD as filed, or required to be
                              filed, with the SEC by a broker-dealer (other than
                              with respect to a limited business broker-dealer):
                              (i) Schedule A, unless the person is identified by
                              an ownership code of less than 10%; or (ii)
                              Schedule B, unless the person’s listing on
                              Schedule B relates to an ownership interest in a
                              person that is listed on Schedule A and identified
                              by an ownership code of less than 10%.
                            </li>
                            <li>
                              Other than a sovereign entity, a person that
                              directly or indirectly owns an interest, in the
                              amounts specified below, of a public reporting
                              company listed, or required to be listed, in
                              Schedule A or B of a Form BD relating to a
                              broker-dealer (other than a reporting company that
                              is listed on a national securities exchange or
                              other than with respect to a limited business
                              broker-dealer): (i) 10% or more of a public
                              reporting company listed, or required to be
                              listed, on Schedule A; or (ii) 25% or more of a
                              public reporting company listed, or required to be
                              listed, on Schedule B.
                            </li>
                            <li>
                              (i) An immediate family member of a person
                              specified in item 2 or 3 or if such person
                              materially supports, or receives material support
                              from, the immediate family member, is employed by
                              or associated with the FINRA member or its
                              affiliate selling the new issue to the immediate
                              family member, or has an ability to control the
                              allocation of the new issue, (ii) An immediate
                              family member of a person specified in item 4 if
                              such person materially supports, or receives
                              material support from, the immediate family
                              member, (iii) An immediate family member of a
                              person specified in item 5 that materially
                              supports, or receives material support from, such
                              person, or (iv) An immediate family member of a
                              person specified in item 6 or 7 unless the person
                              owning the broker-dealer does not materially
                              support, or receives material support from, the
                              immediate family member, is not an owner or
                              affiliate of the FINRA member selling the new
                              issue to the immediate family member, and has no
                              ability to control the allocation of the new
                              issue.
                            </li>
                          </ol>

                          <p>
                            Covered Person. A covered person is any of the
                            following:
                          </p>

                          <ol className="custom-text-list">
                            <li>
                              An executive officer or director of a public
                              company.
                            </li>
                            <li>
                              An executive officer or director of a covered
                              non-public company.
                            </li>
                            <li>
                              A person materially supported by an executive
                              officer or director of a public company or a
                              covered non-public company.
                            </li>
                          </ol>

                          <p>
                            Other Definitions used the FINRA New Issues
                            Questionnaire:
                          </p>

                          <p>
                            Associated person or employee of a FINRA member
                            firm. (1) Any natural person registered with FINRA
                            and (2) any natural person, whether or not
                            registered or exempt from registration with FINRA,
                            who is a sole proprietor, partner, officer,
                            director, or branch manager of a FINRA member firm,
                            or any natural person occupying a similar status or
                            performing similar functions, or any natural person
                            engaged in the investment banking or securities
                            business who is directly or indirectly controlling
                            or controlled by a FINRA member firm (for example,
                            any employee).
                          </p>
                          <p>
                            Beneficial interest. Any economic interest,
                            including the right to share in gains or losses,
                            other than management or performance based fees for
                            operating a collective investment account, or other
                            fees for acting in a fiduciary capacity.
                          </p>
                          <p>
                            Collective investment account. Any hedge fund,
                            investment partnership, investment corporation, or
                            any other collective investment vehicle that is
                            engaged primarily in the purchase and sale of
                            securities, but not: (1) a family investment
                            vehicle, which is a legal entity that is
                            beneficially owned solely by one or more of (A)
                            immediate family members, or (B) family members or
                            family clients as defined by Rule 202(a)(11)(G)-1 of
                            the Advisers Act; or (2) an investment club
                            comprising a group of friends, neighbors, business
                            associates or others who pool their money to invest
                            in stock or other securities and are collectively
                            responsible for making investment decisions.
                          </p>
                          <p>
                            Covered non-public company. Any non-public company,
                            except for an unaffiliated charitable organization,
                            satisfying the following criteria: (i) income of at
                            least $1 million in the last fiscal year or in two
                            of the last three fiscal years and shareholders’
                            equity of at least $15 million; (ii) shareholders’
                            equity of at least $30 million and a two-year
                            operating history; or (iii) total assets and total
                            revenue of at least $75 million in the latest fiscal
                            year or in two of the last three fiscal years.
                          </p>
                          <p>
                            Entity. Any person (other than a natural person),
                            including without limitation a corporation, a
                            partnership, a limited liability company, a
                            collective investment vehicle or arrangement, a
                            trust, an unincorporated organization, a government
                            or any department or agency thereof, or any entity
                            similar to any of the foregoing, whether foreign or
                            domestic.
                          </p>
                          <p>
                            Executive officer or director. Any (i) person named
                            as an executive officer or director in a U.S. public
                            company’s most recent proxy filed with the SEC or in
                            an annual report filed with the SEC on Form 10-K or
                            Form 20-F, (ii) executive officer or director of a
                            foreign company that is registered with the SEC
                            under the ‘34 Act, as amended, or (iii) executive
                            officer or director of a covered non-public company
                          </p>
                          <p>
                            Finder. A person who receives compensation for
                            identifying potential investors in an offering.
                          </p>
                          <p>
                            FINRA Member. A member of the Financial Industry
                            Regulatory Authority or any person or entity
                            associated with a FINRA member firm.
                          </p>
                          <p>
                            Immediate family member. A person’s parents,
                            mother-in-law or father-in-law, spouse, brother or
                            sister, brother-in-law or sister-in-law, son-in-law
                            or daughter-in-law and children, and any other
                            individual to whom the person provides material
                            support.
                          </p>
                          <p>
                            Limited business broker-dealer. Any broker-dealer
                            whose authorization to engage in the securities
                            business is limited solely to the purchase and sale
                            of investment company/variable contracts securities
                            and direct participation program securities.
                          </p>
                          <p>
                            Material support. Directly or indirectly providing
                            more than 25% of a person’s income in the prior
                            calendar year. Members of the immediate family
                            living in the same household are deemed to be
                            providing each other with material support.
                          </p>
                          <p>
                            New issue. Any initial public offering of an equity
                            security as defined in Section 3(a)(11) of the
                            Securities Exchange Act of 1934, made pursuant to a
                            registration statement or offering circular;
                            provided, however, that a new issue shall not
                            include:
                          </p>
                          <ul className="unorderList custom-text-list">
                            <li>
                              (a) offerings made pursuant to an exemption under
                              Section 4(a)(1), 4(a)(2) or 4(a)(5) of the
                              Securities Act, or Securities Act Rule 504 if the
                              securities are “restricted securities” under
                              Securities Act Rule 144(a)(3), or Rule 144A or
                              Rule 505 or Rule 506 adopted thereunder, or
                              offerings made under Regulation S of the
                              Securities Act or otherwise made outside of the
                              United States or its territories unless the
                              securities offered and sold in the Regulation S
                              offering or other offering made outside of the
                              United States are also registered for sale in the
                              United States under the Securities Act in
                              connection with a concurrent initial public
                              offering of an equity security in the United
                              States;
                            </li>
                            <li>
                              (b) offerings of exempted securities as defined in
                              Section 3(a)(12) of the Securities Exchange Act of
                              1934, and rules promulgated thereunder;
                            </li>
                            <li>
                              (c) offerings of securities of a commodity pool
                              operated by a commodity pool operator as defined
                              under Section 1a(5) of the Commodity Exchange Act;
                            </li>
                            <li>
                              (d) rights offerings, exchange offers, or
                              offerings made pursuant to a merger or
                              acquisition;
                            </li>
                            <li>
                              (e) offerings of investment grade asset-backed
                              securities;
                            </li>
                            <li>(f) offerings of convertible securities; </li>
                            <li>(g) offerings of preferred securities;</li>
                            <li>
                              (h) offerings of an investment company registered
                              under the Investment Company Act;
                            </li>
                            <li>
                              (i) offerings of securities (in ordinary share
                              form or ADRs registered on Form F-6) that have a
                              pre-existing market outside of the United States;
                              and
                            </li>
                            <li>
                              (j) offerings of a special purpose acquisition
                              company subject to Securities and Exchange
                              Commission rules and regulations, a business
                              development company as defined in Section 2(a)(48)
                              of the Investment Company Act, a direct
                              participation program as defined in Rule 2310(a)
                              or a real estate investment trust as defined in
                              Section 856 of the Internal Revenue Code.{' '}
                            </li>
                          </ul>

                          <p>
                            Public Company. Any company that is registered under
                            Section 12 of the Securities Exchange Act or files
                            period reports pursuant to Section 15(d) thereof.
                          </p>
                          <p>
                            Sovereign Entity. A sovereign nation or its
                            political subdivisions, agencies or
                            instrumentalities, or a pool of capital or an
                            investment fund or other vehicle owned or controlled
                            by a sovereign nation or its political subdivisions,
                            agencies or instrumentalities and created for the
                            purpose of making investments on behalf or for the
                            benefit of the sovereign nation or its political
                            subdivisions, agencies or instrumentalities.
                          </p>
                        </div>
                      </div>

                      <Title
                        text={'H. Wiring Bank Status'}
                        className="align-header-text-sub-header"
                        contentId={'content8'}
                      />
                      <div className="row">
                        {questionnaireContext.currentVersionQuestions.wiringBankStatus.map(
                          (item) => (
                            <div
                              key={item.id + item.displayName}
                              className="row"
                            >
                              <div id={item.id}>
                                {item.type === 'title' ? (
                                  <Paragraph text={item.displayName} />
                                ) : null}
                                <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                  {item.type === 'quest'
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        item.displayName,
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm
                                      )
                                    : null}
                                </div>
                                {item.type === 'opt' &&
                                item.id === iqID.id_wiring_bank_status_1_opt &&
                                optionals.includes(item.id) ? (
                                  <div>
                                    <Paragraph
                                      className="align-text-push reduce-bottom-margin"
                                      text={item.displayName}
                                    />
                                    <TextInput
                                      inputData={{
                                        id: item.id,
                                        isRequired: false,
                                        size: item.size,
                                        hasError: false,
                                        type: 'text',
                                      }}
                                      reference={(_node) => {
                                        this.id_wiring_bank_status_1_opt_Ref =
                                          _node;
                                      }}
                                      onBlurHandler={(e) =>
                                        this.handleTextInputBlur(
                                          e,
                                          this.id_wiring_bank_status_1_opt_Ref
                                            .value
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <br />
                      <Title
                        text={'I. Identification of Special Entities'}
                        className="align-header-text-sub-header"
                        contentId={'content9'}
                      />

                      <div>
                        <div
                          id="special-entities-introduction-p"
                          className={`${
                            disabledList.includes(
                              'special-entities-introduction-p'
                            )
                              ? 'color-gray'
                              : ''
                          } row`}
                        >
                          <p>
                            <b>
                              If the undersigned is a natural person (and
                              indicated such by answering “Yes” to Question 1 in
                              Section B above), the undersigned is not required
                              to complete this Section I and should skip to
                              Section J.
                            </b>
                          </p>
                          <p>
                            In order to identify certain special entities whose
                            investment may require special treatment by the
                            Fund, please carefully answer each of the following
                            questions:
                          </p>
                        </div>
                        <div className="row">
                          {questionnaireContext.currentVersionQuestions.identificationofSpecialEntities.map(
                            (item) => (
                              <div
                                key={item.id + item.displayName}
                                className="row"
                              >
                                {item.id === iqID.id_special_entities_3_a ? (
                                  <div
                                    className={`${
                                      disabledList.includes(
                                        'special-entities-lost-descrip-quest'
                                      )
                                        ? 'color-gray'
                                        : ''
                                    } col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space`}
                                    id="special-entities-lost-descrip-quest"
                                  >
                                    <Paragraph
                                      text={
                                        '(a). any agency, authority or instrumentality of the state or political subdivision;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(b). a pool of assets sponsored or established by the state or political subdivision or any agency, authority or instrumentality thereof, including but not limited to a “defined benefit plan” as defined in section 414(j) of the Internal Revenue Code, or a state general fund;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(c). a plan or program of a government entity; and'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(d). officers, agents or employees of the state or political subdivision or any agency, authority or instrumentality thereof, acting in their official capacity?'
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div id={item.id}>
                                  {item.type === 'title' ? (
                                    <div className="align-text-push-max">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Paragraph
                                        id={`${item.id}-title`}
                                        className={`${
                                          disabledList.includes(
                                            `${item.id}-title`
                                          )
                                            ? 'color-gray'
                                            : ''
                                        }`}
                                        text={item.displayName}
                                      />
                                    </div>
                                  ) : null}
                                  <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                    {item.type === 'quest'
                                      ? this.renderConfirm(
                                          [
                                            item.radioIdentifier[0],
                                            item.radioIdentifier[1],
                                            item.id,
                                          ],
                                          item.displayName,
                                          this.parseCheckboxStateFromId(
                                            item.id
                                          ),
                                          item.name,
                                          item.size,
                                          item.tooltip,
                                          this.handlerConfirm,
                                          [
                                            disabledList.includes(item.id),
                                            disabledList.includes(item.id),
                                          ]
                                        )
                                      : null}
                                  </div>
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_1_b_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <TextInput
                                        inputData={{
                                          id: item.id,
                                          isRequired: false,
                                          size: item.size,
                                          hasError: false,
                                          type: 'text',
                                        }}
                                        reference={(_node) => {
                                          this.id_special_entities_1_b_opt_Ref =
                                            _node;
                                        }}
                                        onBlurHandler={(e) =>
                                          this.handleTextInputBlur(
                                            e,
                                            this.id_special_entities_1_b_opt_Ref
                                              .value
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_2_e_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin align-text-push"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <div className="align-text-push">
                                        <TextInput
                                          inputData={{
                                            id: item.id,
                                            isRequired: false,
                                            size: item.size,
                                            hasError: false,
                                            type: 'number',
                                          }}
                                          reference={(_node) => {
                                            this.id_special_entities_2_e_opt_Ref =
                                              _node;
                                          }}
                                          onBlurHandler={(e) =>
                                            this.handleTextInputBlur(
                                              e,
                                              this
                                                .id_special_entities_2_e_opt_Ref
                                                .value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <br />
                      <Title
                        text={'J. Miscellaneous'}
                        className="align-header-text-sub-header"
                        contentId={'content10'}
                      />
                      <div className="row">
                        <p>
                          1. The person signing this Investor Questionnaire on
                          behalf of the undersigned is duly authorized to sign
                          and enter into this Investor Questionnaire on behalf
                          of the undersigned.
                        </p>
                        <p>
                          2. Neither this Investor Questionnaire nor any term
                          hereof may be changed, waived, discharged or
                          terminated orally but only with the written consent of
                          the undersigned and the General Partner.
                        </p>
                        <p>
                          3. The undersigned acknowledges that this Investor
                          Questionnaire contains certain representations and
                          warranties made by the undersigned to the Fund. The
                          undersigned acknowledges that the undersigned
                          understands the meaning and legal consequences of the
                          representations and warranties made by the undersigned
                          herein and that the Fund is relying on such
                          representations and warranties in making its
                          determination to admit the undersigned to the Fund.
                        </p>
                        <p>
                          4. The undersigned hereby agrees to indemnify and hold
                          harmless the Fund, the General Partner, and each
                          member, partner, director, officer or employee of the
                          General Partner from and against any and all loss,
                          damage, liability or expenses, including legal fees
                          and expenses, due to or arising out of a breach of any
                          representation or warranty of the undersigned.
                        </p>
                        <p>
                          5. This Investor Questionnaire shall be construed in
                          accordance with and governed by the laws of the State
                          of Delaware.
                        </p>
                        <p>
                          6. This Investor Questionnaire may be executed in any
                          number of counterparts, each of which shall be an
                          original but all of which taken together shall
                          constitute one agreement.
                        </p>
                        <p>
                          7. The headings in this Investor Questionnaire are for
                          convenience of reference, and shall not by themselves
                          determine the meaning of this Investor Questionnaire
                          or of any part thereof.
                        </p>
                        <p>
                          8. The English language version of this Investor
                          Questionnaire shall govern all questions of
                          interpretation relating to this Investor
                          Questionnaire, notwithstanding that this Investor
                          Questionnaire may have been translated into, and
                          executed in, other languages.
                        </p>
                      </div>
                      <br />
                      <Title
                        text={'K. Exceptions to Representations and Warranties'}
                        className="align-header-text-sub-header"
                        contentId={'content11'}
                      />
                      <div className="row">
                        <TextArea
                          inputData={{
                            id: questionnaireContext.currentVersionQuestions
                              .warrantiesExceptions[0].id,
                            displayName:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].displayName,
                            isRequired: false,
                            hasError: false,
                            title: '',
                            description:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].description,
                          }}
                          reference={(_node) => {
                            this.id_warranties_exceptions_Ref = _node;
                          }}
                          onBlurHandler={(e) =>
                            this.handleTextInputBlur(
                              e,
                              this.id_warranties_exceptions_Ref.value
                            )
                          }
                        />
                      </div>
                      <div className="margin-bottom-spacing">
                        <div className="back-btn">
                          <Button
                            inputData={{
                              id: 'back-btn',
                              displayName: 'Back',
                              type: 'button',
                            }}
                            clickHandler={this.backStep}
                          />
                        </div>
                        <div className="next-btn">
                          <Button
                            inputData={{
                              id: 'next-btn',
                              displayName: 'Next',
                              type: 'submit',
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p>'...'</p>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({})
);

export default connector(QuestionnaireForm);
