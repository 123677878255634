const prefix = {
  usd: '$',
  eur: '€'
}

module.exports = {
  adjustFormatMoney: (n, currency) => {
    currency = !currency ? 'usd' : currency
    if (n.replace(/\s/g,'') === 0.00 || n.replace(/\s/g,'') === '$0.00' || n.replace(/\s/g,'') === '€0.00') {
      return ' '
    } else {
      return  `${prefix[currency]}${n.replace(/\s|\$/g, '')}`
    }
  }
}