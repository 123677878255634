import type { Dispatch, GetState, ThunkAction } from '../../types';
import { RENDER_NOTIFICATION } from '../FundSettings/action';
import { handleError } from '../../utils/requestErrorHandler';

const API_URL = '/api';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' &&
        localStorage.getItem('organization-auth-token')
          ? localStorage.getItem('organization-auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const updateOnboardedStatus = (
  updatePayload,
  callback
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.put(
      `${API_URL}/iqUsers/`,
      updatePayload,
      getConfig()
    );
    if (res.data.success) {
      dispatch({
        type: 'SET_ORGANIZATION',
        organization: res.data.organization,
      });
      dispatch({ type: 'SET_ORGANIZATION_USER', user: res.data.user });
      callback();
    } else {
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: 'error',
        message: res.data.message,
        title: 'Something went wrong',
      });
    }
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

export const refetchUser = (history, callback): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('organization-auth-token')) {
      const res = await axios.post(`${API_URL}/iqUsers/recover`, {
        token:
          typeof window !== 'undefined'
            ? localStorage.getItem('organization-auth-token')
            : '',
      });
      if (res.data.success) {
        dispatch({ type: 'SET_ORGANIZATION_USER', user: res.data.user });
        dispatch({
          type: 'SET_ORGANIZATION',
          organization: res.data.user.organization,
        });
        dispatch({ type: 'SET_LOGIN_STATUS', status: '' });
        // this are the instructions modal
        if (!res.data.user.onboarded) {
          callback();
        }
      } else {
        localStorage.removeItem('organization-auth-token');
        history.push('/');
      }
    }
  }
};

export const logout = (history: Object): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  await axios.post(`${API_URL}/iqUsers/logout`, {
    token:
      typeof window !== 'undefined'
        ? localStorage.getItem('organization-auth-token')
        : '',
  });
  if (typeof window !== 'undefined')
    localStorage.removeItem('organization-auth-token');
  dispatch({ type: 'SET_ORGANIZATION_USER', user: {} });
  dispatch({ type: 'SET_ORGANIZATION', organization: {} });
  dispatch({ type: 'HIDE_NOTIFICATION' });
  history.push('/');
};

export const sessionExpire = (history: Object): ThunkAction => (
  dispatch: Dispatch
) => {
  history.push('/');
  if (typeof window !== 'undefined')
    localStorage.removeItem('organization-auth-token');
  dispatch({ type: 'SHOW_SESSION_TIMEOUT_ALERT', sessionTimeout: false });
  dispatch({ type: 'SET_ORGANIZATION_USER', user: {} });
  dispatch({ type: 'SET_ORGANIZATION', organization: {} });
  dispatch({ type: 'HIDE_NOTIFICATION' });
};
