/* eslint-disable no-plusplus */
import React from 'react';
import './styles.scss';

type Props = { subSections: [], id: String };

const resolveSubsections = subsections => (<div>
  {subsections.map(sub => <li key={sub.contentId} className={sub.status}><a key={sub.contentId} href="#/" id={`subIndex${sub.contentId}`} onClick={sub.clickHandler}> {sub.title} </a></li>)}
</div>);

const IndexSubsection = ({ subSections, id }: Props) => (
  <ul key={id} className="questionaire-index-subsection">
    {resolveSubsections(subSections)}
  </ul>
);

IndexSubsection.defaultProps = {
  subSections: ['default 1', 'default 2', 'default 3'],
  id: '',
};

export default IndexSubsection;

