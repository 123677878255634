/* eslint-disable react/sort-comp,import/prefer-default-export,class-methods-use-this,max-len,spaced-comment,consistent-return */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import * as notify from '../../../../../../utils/notify';
import TextInput from '../../../../../../components/TextInput/index';
import Title from '../../../../../../components/Title/index';
import '../../../LP/GeneralInformation/styles.scss';
import Button from '../../../../../../components/Button/index';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';

import FundTool from '../../../../../../components/FundTool/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import {
  IQ_SET_LAST_FOCUSED_FIELD,
  IQ_IS_SAVING_GENERAL_INFORMATION,
  IQ_IS_ADDITIONAL_INFO,
} from '../../../../reducer';
import { saveGeneralInformation } from '../../../../util';
import DateTimeZone from '../../../../../../utils/date-time-zone';

type Props = {
  history: Object,
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};


const initialState = {
  nameLetter: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
   totalAllFund: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required', 'number'],
  },
};
// Export this for unit testing more easily
export class InvestmentInterestLetter extends PureComponent {
  props: Props;
  state = initialState;
  static defaultProps: {
    history: {},
    context: {},
  };

  componentDidMount() {
    notify.hideNotification();
    this.checkPreviousInformation();
  }

  mapDateOfBirthFields = (birth) => {
    if (birth && birth.length) {
      const splittedResult = birth.split('-');
      const day = splittedResult[1];
      const month = splittedResult[0];
      const year = splittedResult[2];
      return {
        dayBirth: day,
        monthBirth: month,
        yearBirth: year,
      };
    }
  };

  checkPreviousInformation = () => {
    const { questionnaireContext } = this.props;
    if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo._id
    ) {
     
      this.setState(
        {
          nameLetter: {
            ...this.state.nameLetter,
            value: questionnaireContext.investorFund.generalInfo.nameLetter,
          },
          
          totalAllFund: {
            ...this.state.totalAllFund,
            value: questionnaireContext.investorFund.generalInfo.totalAllFund,
          
          },
        },
        () => {
          sessionStorage.setItem(
            'interestLetter',
            JSON.stringify(questionnaireContext.investorFund.generalInfo)
          );
        }
      );
    } else {
      // fixme needs to set an initial generalInfo with the initialState info
      // fixme this part can be simplified
      const templateInterestLetter = {
        nameLetter: this.state.nameLetter.value,
        totalAllFund: this.state.totalAllFund.value,
      };
      sessionStorage.setItem(
        'interestLetter',
        JSON.stringify(templateInterestLetter)
      );
    }
  };


  handleSaveFieldOnBlur = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem('interestLetter')) {
      const info = JSON.parse(sessionStorage.getItem('interestLetter'));
      if (event.target.name === 'totalAllFund') { 
        info[event.target.name] = this.state.totalAllFund.value;
      } 
      if (event.target.name === 'nameLetter') { 
        info[event.target.name] = this.state.nameLetter.value;
      } 
      else {
        info[event.target.name] = event.target.value;
      }
      sessionStorage.setItem('interestLetter', JSON.stringify(info));
    }
  };

  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
    /*if (document.baseURI.split('/access/home/')[1] !== 'information') {
      window.scrollTo(0, 0);
    } */
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    helpText,
    disabled,
    extraText,
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
      extraText,
      moneyInput: id === 'totalAllFund',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };

  handleFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    event.preventDefault();
    this.saveAllFields();
    if (event.target.id !== 'saveLater') {
      this.props.handleRedirect(event, questionnaireContext.currentStep + 1);
      // this.nextStep(event);
    }
  };

  

  saveAllFields = () => {
    const { questionnaireContext } = this.props;
    if (!questionnaireContext.savingGeneralInformation) {
      const payload = {
        unsavedInfo: false,
        nameLetter: this.state.nameLetter.value,
        totalAllFund: this.state.totalAllFund.value,
     
       
      };
      if (this.state.previousInfo) {
        payload._id = questionnaireContext.investorFund.generalInfo._id;
      }
      if (questionnaireContext.multiAccess) {
        payload.limitedPartnerFund =
          questionnaireContext.investorFund._id.toString();
      } else {
        payload.investorFund = questionnaireContext.investorFund._id.toString();
      }

    const generalInfo = questionnaireContext.investorFund.generalInfo;
    payload.name = generalInfo.name;
    payload.signatory = generalInfo.signatory;
    payload.tax = generalInfo.tax;
    payload.country = generalInfo.country;
    payload.countryPlace = generalInfo.countryPlace;
    payload.types = generalInfo.types;
    payload.otherValue = generalInfo.otherValue;
    payload.namePrimaryContact = generalInfo.namePrimaryContact;
    payload.emailPrimaryContact = generalInfo.emailPrimaryContact;
    payload.addressPrimaryContact = generalInfo.addressPrimaryContact;
    payload.phonePrimaryContact = generalInfo.phonePrimaryContact;
    payload.capitalCommitment = generalInfo.capitalCommitment;
    payload.additionalContactInfo = generalInfo.additionalContactInfo;
    payload.additionalDistributionInfo = generalInfo.additionalDistributionInfo;
    // eslint-disable-next-line
    payload.principalPlaceOfBusiness =
      generalInfo.principalPlaceOfBusiness || '';
    payload.principalPlaceOfBusinessPrimaryContact =
      generalInfo.principalPlaceOfBusinessPrimaryContact || '';
    // eslint-disable-next-line
    payload.principalPlaceOfResidence =
      generalInfo.principalPlaceOfResidence || '';
    payload.principalPlaceOfResidencePrimaryContact =
      generalInfo.principalPlaceOfResidencePrimaryContact || '';
    // eslint-disable-next-line
    payload.jurisdictionOrganitzationPrimaryContact =
      generalInfo.jurisdictionOrganitzationPrimaryContact;
    payload.reportsPrimaryContactName = generalInfo.reportsPrimaryContactName;
    payload.reportsPrimaryContactEmail = generalInfo.reportsPrimaryContactEmail;
    payload.reportsPrimaryContactPhone = generalInfo.reportsPrimaryContactPhone;
    payload.reportsSecondaryContactName =
      generalInfo.reportsSecondaryContactName;
    payload.reportsSecondaryContactEmail =
      generalInfo.reportsSecondaryContactEmail;
    payload.reportsSecondaryContactPhone =
      generalInfo.reportsSecondaryContactPhone;
    payload.taxPrimaryContactName = generalInfo.taxPrimaryContactName;
    payload.taxPrimaryContactEmail = generalInfo.taxPrimaryContactEmail;
    payload.taxPrimaryContactPhone = generalInfo.taxPrimaryContactPhone;
    payload.taxSecondaryContactName = generalInfo.taxSecondaryContactName;
    payload.taxSecondaryContactEmail = generalInfo.taxSecondaryContactEmail;
    payload.taxSecondaryContactPhone = generalInfo.taxSecondaryContactPhone;
    payload.capitalPrimaryContactName = generalInfo.capitalPrimaryContactName;
    payload.capitalPrimaryContactEmail = generalInfo.capitalPrimaryContactEmail;
    payload.capitalPrimaryContactPhone = generalInfo.capitalPrimaryContactPhone;
    payload.capitalSecondaryContactName =
      generalInfo.capitalSecondaryContactName;
    payload.capitalSecondaryContactEmail =
      generalInfo.capitalSecondaryContactEmail;
    payload.capitalSecondaryContactPhone =
      generalInfo.capitalSecondaryContactPhone;
    payload.stockPrimaryContactName = generalInfo.stockPrimaryContactName;
    payload.stockPrimaryContactEmail = generalInfo.stockPrimaryContactEmail;
    payload.stockPrimaryContactPhone = generalInfo.stockPrimaryContactPhone;
    payload.stockSecondaryContactName = generalInfo.stockSecondaryContactName;
    payload.stockSecondaryContactEmail = generalInfo.stockSecondaryContactEmail;
    payload.stockSecondaryContactPhone = generalInfo.stockSecondaryContactPhone;
    payload.legalPrimaryContactName = generalInfo.legalPrimaryContactName;
    payload.legalPrimaryContactEmail = generalInfo.legalPrimaryContactEmail;
    payload.legalPrimaryContactPhone = generalInfo.legalPrimaryContactPhone;
    payload.legalSecondaryContactName = generalInfo.legalSecondaryContactName;
    payload.legalSecondaryContactEmail = generalInfo.legalSecondaryContactEmail;
    payload.legalSecondaryContactPhone = generalInfo.legalSecondaryContactPhone;
    payload.bankName = generalInfo.bankName;
    payload.acctName = generalInfo.acctName;
    payload.swiftCode = generalInfo.swiftCode;
    payload.acctNumber = generalInfo.acctNumber;
    payload.bankaba = generalInfo.bankaba;
    payload.furtherCredit = generalInfo.furtherCredit;
    payload.location = generalInfo.location;

    saveGeneralInformation(payload, this.props);

  }
    
};
 
  handleAllFundChange = (maskedValue, floatValue, event) => {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        totalAllFund: {
          ...this.state.totalAllFund,
          value: floatValue,
        },
      },
      () => {
        if (sessionStorage.getItem('interestLetter')) {
          const info = JSON.parse(sessionStorage.getItem('interestLetter'));
          info.totalAllFund = floatValue;
          sessionStorage.setItem('interestLetter', JSON.stringify(info));
        }
      }
    );
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      unsavedInfo: true,
      [event.target.name]: {
        ...this.state[event.target.name],
        value: event.target.value,
      },
    });
  };


  render() {
    const { questionnaireContext } = this.props;
 
    const readableDate =
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.updatedAt
        ? new DateTimeZone(questionnaireContext.investorFund.generalInfo.updatedAt)
        : '';
     const formattedDate = readableDate !== ''
        ? readableDate.getLocalDate()
        : readableDate;
   
    const letterParagraph = ``;
    return (
      <div>
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          saveLaterHandler={this.handleFormSubmit}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        {/* <BlockUi tag="div" blocking={tracker.savingInfo}> */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
              <div className="content-bg main-content-area clearfix">
                <div className="row container-content-padding">
                  <Title text={questionnaireContext.fund.fundLegalName} />
                  <Title text={'Investment Interest Letter'} />
                  <br />
                  <div >
                    <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="170" height="45" viewBox="0 0 344.000000 114.000000"
                      preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,114.000000) scale(0.100000,-0.100000)"
                      fill="#3E4047" stroke="none">
                      <path d="M590 1091 c-77 -20 -106 -32 -108 -44 -2 -10 3 -17 11 -17 8 0 21 -8
                      30 -18 16 -17 6 -44 -167 -446 -121 -281 -193 -437 -211 -457 -16 -16 -43 -32
                      -62 -35 -18 -3 -33 -12 -33 -20 0 -11 32 -14 170 -14 144 0 170 2 170 15 0 10
                      -10 15 -32 15 -38 0 -98 33 -98 54 0 16 75 235 84 243 6 7 386 102 407 103 13
                      0 29 -34 63 -126 26 -69 52 -148 58 -175 10 -43 9 -51 -7 -69 -10 -11 -36 -23
                      -57 -26 -23 -4 -38 -12 -38 -20 0 -11 40 -14 220 -14 189 0 220 2 220 15 0 8
                      -9 15 -20 15 -32 0 -76 26 -99 59 -12 16 -103 242 -202 503 -99 260 -184 476
                      -187 479 -4 3 -54 -6 -112 -20z m-29 -613 c-102 -26 -187 -46 -188 -44 -2 2
                      64 166 194 476 l14 35 83 -210 82 -210 -185 -47z"/>
                      <path d="M3200 1098 c-141 -36 -150 -39 -150 -53 0 -8 6 -15 14 -15 7 0 25
                      -11 40 -25 l26 -24 0 -398 c0 -242 -4 -413 -10 -435 -12 -42 -52 -78 -86 -78
                      -15 0 -24 -6 -24 -15 0 -13 28 -15 190 -15 155 0 190 3 190 14 0 8 -15 17 -34
                      20 -18 4 -42 16 -52 27 -18 20 -19 49 -24 518 l-5 497 -75 -18z"/>
                      <path d="M1370 757 c-119 -28 -201 -91 -252 -195 -31 -62 -33 -74 -33 -167 0
                      -87 3 -107 26 -155 40 -84 90 -135 174 -176 68 -34 82 -37 157 -37 110 1 184
                      29 255 96 29 27 53 58 53 68 0 24 -12 24 -35 -1 -34 -37 -122 -62 -201 -58
                      -162 10 -256 125 -255 311 1 161 90 287 205 287 78 0 116 -42 116 -130 0 -44
                      2 -51 18 -46 9 3 37 10 62 16 88 22 82 16 60 60 -50 98 -214 158 -350 127z"/>
                      <path d="M2037 760 c-73 -13 -149 -54 -200 -109 -57 -61 -83 -112 -98 -193
                      -23 -126 20 -248 118 -338 95 -87 231 -116 363 -77 89 27 210 131 183 158 -9
                      9 -16 7 -28 -5 -57 -57 -197 -83 -278 -52 -91 34 -143 91 -171 186 -43 145 17
                      329 125 385 41 22 111 19 142 -5 33 -26 51 -81 42 -127 -5 -29 -4 -35 8 -30 8
                      3 43 11 76 18 34 6 64 15 67 20 9 15 -24 66 -66 102 -66 56 -186 84 -283 67z"/>
                      <path d="M2683 760 c-136 -28 -250 -132 -283 -259 -17 -65 -14 -181 7 -234 41
                      -104 128 -190 228 -223 71 -24 184 -22 257 6 65 24 168 111 168 141 0 25 -3
                      24 -50 -10 -51 -37 -126 -55 -200 -48 -122 11 -206 87 -236 214 l-12 53 34 9
                      c19 5 132 33 250 62 240 58 232 53 195 135 -52 114 -215 184 -358 154z m168
                      -58 c25 -23 33 -38 36 -78 8 -80 -12 -94 -184 -137 l-143 -35 6 42 c10 69 64
                      167 112 204 59 44 127 45 173 4z"/>
                      </g>
                  </svg>
                </div>
                  <br />
                  <Title text={'Indication of Investment in Accel Entrepreneurs Funds'} />
                  <Paragraph text={'You  are  an  important  partner  to  Accel,  and  we  look  forward  to  extending  that  partnership  via  your investment in the Accel Entrepreneurs Funds.  This opportunity is made available to a limited number of key  individuals  that  have  been,  and  will  continue  to  be,  important  to  Accel  globally.   The  opportunity contemplates a series of investments in what are expected to be five Accel Entrepreneurs Funds alongside the corresponding family of Accel global funds.'} />
                  <Paragraph text={'Please indicate your total desired investment amount below.  Our goal is to allocate your desired amount proportionately  based  on  fund  size  among  the  upcoming  vintage  of  Accel  Funds  globally.    The  first opportunity will be an investment alongside our recently closed $4 billion Accel Leaders 4 fund.  Over the next 12 - 24 months, we expect to close our next generation of early stage funds for the US, London, and India, as well as our Global Growth fund.  We will be in touch with you and confirm your formal subscription and commitment  amount  to  each  subsequent  fund  in  connection  with  the  organization  and  final  closing  of such Fund.'} />
                  <Paragraph text={'As we have discussed with you and/or your advisors, one of our goals in implementing this program is to make the investment process as seamless as possible.   Accordingly, to the extent possible, our counsel will utilize the information provided in connection with the Leaders 4 Entrepreneurs Fund to simplify the subscription process to the other funds when they are formed (due to the different geographies in which some funds are formed or managed, we may request additional information or documentation from you at a later date).'} />
                  <br />
                  <br />
                  <span style={{justifyContent: 'right', display: 'grid', marginRight: '20rem'}}>The Partners of Accel</span>
                  <Paragraph text={letterParagraph} />
             
                   <div className="row">
                    {this.renderTextInput(
                      'nameLetter',
                      'nameLetter',
                      'text',
                      this.state.nameLetter.value,
                      'Name',
                      false,
                      'medium',
                      !this.state.nameLetter.valid,
                      this.state.nameLetter.errorMessage,
                      this.handleTextInputChange,
                      '',
                    )}
                  </div>
                  
                  <div className="row">
                    {this.renderTextInput(
                      'totalAllFund',
                      'totalAllFund',
                      'text',
                      this.state.totalAllFund.value,
                      'Investment Total for All Funds ($)',
                      false,
                      'medium',
                      !this.state.totalAllFund.valid,
                      this.state.totalAllFund.errorMessage,
                      this.handleAllFundChange,
                      'Enter amount in US Dollars',
                    )}
                  </div>
                 <br />
                 <br />
                 <br />
                <p style={{textAlign: 'center'}}>CONFIDENTIAL</p>
                  
                    </div>  
              </div>
              <br />
              <div className="back-btn">
                <Button
                  inputData={{
                    id: 'back-btn',
                    displayName: 'Back',
                    type: 'button',
                  }}
                  clickHandler={this.backStep}
                />
              </div>
              <div className="next-btn">
                <Button
                  inputData={{
                    id: 'next-btn',
                    displayName: 'Next',
                    type: 'button',
                  }}
                  clickHandler={this.handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </BlockUi> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    setLastFocusedField: (_info) =>
      dispatch({ type: IQ_SET_LAST_FOCUSED_FIELD, lastFocusedField: _info }),
    isAdditionalContactInfo: (_enableInfo) =>
      dispatch({ type: IQ_IS_ADDITIONAL_INFO, AdditionalContactInfo: _enableInfo }),
    setSavingInfo: (_saving) =>
      dispatch({
        type: IQ_IS_SAVING_GENERAL_INFORMATION,
        savingGeneralInformation: _saving,
      }),
    // new items above
  })
);

export default connector(InvestmentInterestLetter);
