import React from 'react';

type Props = { text: Object, contentId: Object, className: Object};

const Title = ({ text, contentId, className }: Props) => (
  <h4 id={contentId} className={`color-dark-green ${className}`}>{text}</h4>
);

Title.defaultProps = {
  text: '',
  contentId: '',
  className: '',
};

export default Title;

