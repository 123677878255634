import React from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../TextInput/index';
import Button from '../Button/index';

type Props = {
  multiAccessLoginHandler: () => void,
  changeHandler: () => void,
  disabledFields: Boolean,
};

const MultiAccessLogin = ({
  multiAccessLoginHandler,
  changeHandler,
  disabledInputs,
}: Props) => (
  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-block">
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
      <p className="color-black" style={{ fontSize: '12px' }}>
        <span className="color-dark-green">
          <b>SIGN IN TO YOUR ACCOUNT:</b>
        </span>
        &nbsp;FILL OUT MULTIPLE INVESTOR QUESTIONNAIRES FOR A SINGLE FUND.
      </p>
    </div>
    <form onSubmit={multiAccessLoginHandler}>
      <div
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style={{ marginLeft: '-16px' }}
      >
        <div className="row">
          <div className="col-lg-8">
            <TextInput
              inputData={{
                id: 'username-input',
                placeholder: 'Email Address',
                type: 'text',
                noTitle: true,
                disabled: disabledInputs,
              }}
              onChangeHandler={changeHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <TextInput
              inputData={{
                id: 'password-input',
                placeholder: 'Password',
                type: 'password',
                noTitle: true,
                disabled: disabledInputs,
              }}
              onChangeHandler={changeHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <Button
              inputData={{
                id: 'login-input-multi-iq-access',
                displayName: 'Sign In',
                type: 'submit',
                isDisabled: disabledInputs,
              }}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <p className="sign-up-sec-wrapper-fund">
              Don&#39;t have an account?
              {/* <Link to='/signup?tab=1'> signup </Link>  */}
              <a href="/?tab=1" target="_blank">
                {' '}
                Sign up
              </a>
              .
              <br />
              <Link to="/forgotPassword" target="_blank">
                Forgot password?
              </Link>
            </p>
          </div>
        </div>
        <br />
      </div>
    </form>
  </div>
);

export default MultiAccessLogin;
