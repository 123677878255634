/* eslint-disable react/sort-comp,no-restricted-syntax,no-param-reassign,no-cond-assign,radix, react/no-direct-mutation-state, jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
/* @flow */

import axios from 'axios';
import { editFund } from 'containers/FundForm/action';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Banner from '../../components/Banner/index';
import FilterBox from '../../components/FilterBox/index';
import TabIndexContainer from '../../components/TabIndexContainer/index';
import TableContainer from '../../components/TableContainer/index';
import * as notify from './../../utils/notify';
import * as utility from './../../utils/utility';
import * as action from './action';
import './styles.scss';

const { STATUS_TYPES } = require('../../utils/constants/status');
const { formatMoney } = require('../../utils/format');
const { adjustFormatMoney } = require('../../utils/adjust-format-money');
const { getPages, paginationMAX } = require('../../utils/pagination');
const { FUND_TYPES, INVESTMENT_TYPES } = require('../../utils/constants/types');

/**
 * @typedef {object} Props
 * @property {object} history
 * @property {Gunderson.Administrator} admin
 * @property {Gunderson.Context} context
 * @property {Function} addAdminFirms
 * @property {Function} addAdminFunds
 * @property {Function} setTab
 * @property {Function} resetLoadMore
 * @property {Function} updateFirms
 * @property {Function} updateFunds
 * @property {Function} searchFirms
 * @property {Function} searchFunds
 * @property {Function} setCanLoadFirms
 * @property {Function} setCanLoadFunds
 * @property {Function} notifyPasswordChange
 * @property {Function} editFund
 * @property {Function} notifyIE
 * @property {Function} notifyPasswordChange
*/

// Export this for unit testing more easily

/**
 * @param {Props} props
*/
const AdminDashboard = (props) => {
  const [tableMaxItems, setTableMaxItems] = useState(paginationMAX);
  const [firmsCurrentPage, setFirmsCurrentPage] = useState(1);
  const [fundsCurrentPage, setFundsCurrentPage] = useState(1);
  const [firms, setFirms] = useState([]);
  /**
   * @type {[Models.Fund[], (arr: Models.Fund[]) => void]}
  */
  const [funds, setFunds] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortByColFunds, setSortByColFunds] = useState('-1');
  const [sortDirectionFunds, setSortDirectionFunds] = useState('desc');
  const [sortByColFirms, setSortByColFirms] = useState('-1');
  const [sortKey, setSortKey] = useState('');
  const [sortDirectionFirms, setSortDirectionFirms] = useState('desc');
  const [fundTotalPages, setFundTotalPages] = useState([]);
  const [firmTotalPages, setFirmTotalPages] = useState([]);
  const [initialLoadFirms, setInitialLoadFirms] = useState(false);
  const [initialLoadFunds, setInitialLoadFunds] = useState(false);

  useEffect(() => {
    const { context, notifyPasswordChange, history } = props;
    //  resetLoadMore();
    // hide notify Alert
    if (document.title !== 'Fund Formation Portal - Fund Tracker') {
      notify.hideNotification();
    }

    document.body.scrollTop = 0;
    const user = context.user;
    if (user && user.passwordChangeRequired) {
      notifyPasswordChange();
      history.push('/admin/main/profile');
    }
  });
  // initial load
  useEffect(() => {
    if (!initialLoadFirms) {
      if (!firms.length) {
        fetchFirms(1, true);
      }
    }
  }, []);
  // initial load
  useEffect(() => {
    if (!initialLoadFunds) {
      if (!funds.length) {
        fetchFunds(1, true);
      }
    }
  }, []);

  useEffect(() => {
    setFunds(props.admin.funds);
  }, [props.admin.funds]);
  useEffect(() => {
    setFirms(props.admin.firms);
  }, [props.admin.firms]);

  const fetchFunds = async (pageNumber, isInitialLoad = false) => {
    const { data } = await axios.get(
      `/api/funds/${pageNumber}/${tableMaxItems}`,
      action.getConfig()
    );
    if (isInitialLoad) {
      setInitialLoadFunds(true);
    }

    setFundTotalPages(getPages(data.fundsLength, tableMaxItems));
    setFundsCurrentPage(pageNumber);
    props.addAdminFunds(data.funds);
  };
  const fetchFirms = async (pageNumber, isInitialLoad = false) => {
    const { data } = await axios.get(
      `/api/firms/${pageNumber}/${tableMaxItems}`,
      action.getConfig()
    );
    if (isInitialLoad) {
      setInitialLoadFirms(true);
    }
    setFirmTotalPages(getPages(data.firmsLength, tableMaxItems));
    setFirmsCurrentPage(pageNumber);
    props.addAdminFirms(data.firms);
  };

  const handleSectionSelection = async (event) => {
    const { setTab, resetLoadMore } = props;
    event.preventDefault();
    setSearchValue('');
    resetLoadMore();

    const elId = event.target.id;
    if (elId === 'firmSection') {
      setTab('firms');
      if (!firms.length) {
        setFirmsCurrentPage(1);
        await fetchFirms(1);
      }
    } else {
      setTab('funds');
      if (!funds.length) {
        setFundsCurrentPage(1);
        await fetchFunds(1);
      }
    }
  };

  const handleFilterBoxAction = (event) => {
    event.preventDefault();
    const { admin, history, resetLoadMore } = props;
    const actionLocation =
      admin.currentTab === 'firms'
        ? '/admin/main/newFirm'
        : '/admin/main/newFund';
    resetLoadMore();
    history.push(actionLocation);
  };

  const handleFilterBoxSearch = (event) => {
    event.preventDefault();
    search();
  };

  const searchFirmsByText = async (query) => {
    const { data } = await axios.get(
      `/api//firm/search/${query}`,
      action.getConfig()
    );
    setFirms(data.firms);
  };
  const searchFundsByText = async (query) => {
    const { data } = await axios.get(
      `/api/fund/search/${query}`,
      action.getConfig()
    );
    setFunds(data.funds);
  };
  const search = async (value) => {
    if (!value) {
      value = searchValue;
    }
    const { admin, setCanLoadFirms, setCanLoadFunds, resetLoadMore } = props;
    if (admin.currentTab === 'firms') {
      if (value !== '') {
        await searchFirmsByText(value);
        setCanLoadFirms(false);
      } else {
        await fetchFirms(1);
        resetLoadMore();
      }
    } else if (admin.currentTab === 'funds') {
      if (value !== '') {
        await searchFundsByText(value);
        setCanLoadFunds(false);
      } else {
        await fetchFunds(1);
        resetLoadMore();
      }
    }
  };

  const searchChangeHandler = (event) => {
    event.preventDefault();
    const searchValue = event.target.value;
    const { resetLoadMore } = props;
    setSearchValue(searchValue);
    if (searchValue === '') {
      search('');
      resetLoadMore();
    }
  };

  const handleLoadMore = async (event) => {
    event.preventDefault();
    const { admin } = props;
    if (admin.currentTab === 'firms') {
      if (admin.canLoadFirms) {
        const newPage = firmsCurrentPage + 1;
        await fetchFirms(newPage);
      }
    } else if (admin.canLoadFunds) {
      const newPage = fundsCurrentPage + 1;
      await fetchFunds(newPage);
    }
  };

  const handleFirmFundsDetail = (event, id) => {
    const { history } = props;
    event.preventDefault();
    history.push(`/admin/main/firmDetail/${id}`);
  };

  const handleFundDetail = (event, id) => {
    const { history } = props;
    event.preventDefault();
    history.push(`/admin/main/fundDetail/${id}`);
  };

  const redirectToFirmFundsDetail = (event) => {
    const { history } = props;
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    history.push(`/admin/main/firmDetail/${fundId}`);
  }

  const redirectToFundsDetail = (event) => {
    const { history } = props;
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    history.push(`/admin/main/fundDetail/${fundId}`);
  }

  const handleChangeField = (event) => {
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    const updatedFunds = funds.map(fund => {
      if (fund._id === fundId) {
        fund.fundNote = event.target.value;
        return fund
      }
      return fund
    })
    setFunds(updatedFunds);

  };

  const handleEditField = (event) => {
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    const input =
      typeof window !== 'undefined'
        ? document.getElementById(event.target.dataset.textareaRef)
        : null;
    setTimeout(() => {
      input.focus();
    }, 50);
  
    const updatedFunds = funds.map(fund => {
      if (fund._id === fundId) {
        fund.editingNote = true;
        return fund
      }
      return fund
    })
    setFunds(updatedFunds);
   
  };

  const handleSaveField = (event) => {
    const { editFund, history } = props
    event.preventDefault();
    const fundId = event.target.dataset.identifier;
    
     const updatedFunds = funds.map(fund => {
      if (fund._id === fundId) {
        fund.fundNote = event.target.value;
        fund.editingNote = false;
        return fund
      }
      return fund
    })
    setFunds(updatedFunds);

    if (fundId) {
      const fund = updatedFunds.find(fund => fund._id === fundId);
      editFund(fund, history, `/admin/main/firmDetail/${fundId}`);
    }
    
  }

  const capitalCommitmentCurrency = (value, fund) => {
    const currency = fund && fund.currency ? fund.currency : 'usd';
    return adjustFormatMoney(`${formatMoney(value, 2, '.',',')}`, currency);
  }

  // eslint-disable-next-line class-methods-use-this
  /**
   * @param {Models.Fund} fund
  */
  const calculateAmountsToDate = (fund) => {
    let closedAmt = 0;
    let readyAmt = 0;
    let notYetReadyToCloseAmt = 0;


    const setAmounts = (status, capital) => {
      switch (status) {
          case STATUS_TYPES.CLOSED.label:
            //eslint-disable-next-line
            closedAmt += Number(capital);
            break;
          case STATUS_TYPES.READY_TO_CLOSE.label:
            //eslint-disable-next-line
            readyAmt += Number(capital);
            break;
          default:
            notYetReadyToCloseAmt += Number(capital);
            return; 
        }
    }

    if (fund) {
      fund.investorFunds.forEach((inv) => {
        const capital =
          inv.generalInfo && inv.generalInfo.capitalCommitment
            ? inv.generalInfo.capitalCommitment
            : 0;
        return setAmounts(inv.status, capital);
      });
      fund.limitedPartnerFunds.forEach((inv) => {
        const capital =
          inv.generalInfo && inv.generalInfo.capitalCommitment
            ? inv.generalInfo.capitalCommitment
            : 0;
        return setAmounts(inv.status, capital);
      });
    }
    return {
			closedAmount: capitalCommitmentCurrency(closedAmt,fund),
			readyToCloseAmount: capitalCommitmentCurrency(readyAmt,fund),
			notYetReadyToCloseAmount: capitalCommitmentCurrency(notYetReadyToCloseAmt,fund),
		};
  };

  const handleChangeFilterItemForFirms = (event) => {
    event.preventDefault();
    let index = '-1';
    switch (event.target.innerText) {
      case 'Name':
        index = '0';
        break;
      case 'Location':
        index = '1';
        break;
      default:
        index = '-1';
        break;
    }
    if (sortByColFirms === index) {
      setSortDirectionFirms(sortDirectionFirms === 'asc' ? 'desc' : 'asc');
    } else {
      setSortByColFirms(index.toString());
      setSortDirectionFirms('desc');
    }
  };

  const renderTableContents = () => {
    const { admin } = props;
    let headers;
    const results = [];
    const rowActions = [];
    let sortData = null;
    let sortResults = [];
    if (admin.currentTab === 'firms') {
      headers = [
        {
          title: 'Name',
          filteringArrow: true,
          class: 'name-col-firm-table-item',
          filteringHandler: handleChangeFilterItemForFirms,
          selected: sortByColFirms === '0',
          filteringOrder: sortDirectionFirms,
        },
        {
          title: 'Location',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFirms,
          selected: sortByColFirms === '1',
          filteringOrder: sortDirectionFirms,
        },
        // { title: 'Capital Raised', filteringArrow:
        // true, filteringId: 'capital', filteringEntity:
        // 'firms', filteringHandler: handleFilterResults,
        // filteringOrder: options.capital.order,
        // selected: options.capital.selected },
        { title: 'Contact', filteringArrow: false, class: 'firm-table-item' },
      ];
      if (firms) {
        for (let i = 0; i < firms.length; i += 1) {
          const firm = firms[i];
          results.push([
            {
              id: 'firm-name-res-col',
              displayName: firm.name,
              type: 'item',
              class: 'name-col-firm-table-item',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFirmFundsDetail,
              actionResultIdentifier: firm._id,
            },
            {
              id: 'firm-city-res-col',
              displayName: `${
                firm.city && firm.state
                  ? `${firm.city}, ${firm.state}`
                  : firm.city + firm.state
              }`,
              type: 'description',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFirmFundsDetail,
              actionResultIdentifier: firm._id,
            },
            // { id: 'firm-capital-res-col', displayName:
            // '$11,234,569', type: 'description' },
            {
              id: 'firm-contact-res-col',
              displayName: firm.emailPrimaryContact,
              type: 'description',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFirmFundsDetail,
              actionResultIdentifier: firm._id,
              class: 'firm-table-item',
            },
          ]);
          rowActions.push({
            actionIdentifier: firm._id,
            actionRowClickHandler: handleFirmFundsDetail,
          });
        }
      }
      sortData =
        sortByColFirms !== '-1'
          ? utility.gunderSort(results, sortByColFirms)
          : results;
      sortResults =
        sortDirectionFirms === 'desc' ? sortData : sortData.reverse();
    } else if (admin.currentTab === 'funds') {
      headers = [
        {
          filteringId: 'fund-name-res-col',
          title: 'Fund Name',
          class: 'fund-table-item investment-xl horizontal-col fund-table-item-top-align',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '0',
          filteringOrder: sortDirectionFunds,
        },
        {
          filteringId: 'firm-name-res-col',
          title: 'Firm',
          class: 'fund-table-item investment-xl horizontal-col fund-table-item-top-align',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '1',
          filteringOrder: sortDirectionFunds,
        },
        {
          filteringId: 'not-ready-to-close-amt-col',
          title: 'Not Yet Ready to Close',
          class: 'fund-table-item fund-table-item-top-align investment-xl horizontal-col',
          type: 'description',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '3',
          filteringOrder: sortDirectionFunds,
        },
        { 
          filteringId: 'ready-close-amt-col',
          title: 'Amount Ready to Close',
          class: 'fund-table-item fund-table-item-top-align investment-xl horizontal-col',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '4',
          filteringOrder: sortDirectionFunds,
        },
        {
          filteringId: 'closed-amt-col',
          title: 'Amount Closed to Date',
          class: 'fund-table-item fund-table-item-top-align investment-xl horizontal-col',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '2',
          filteringOrder: sortDirectionFunds,
        },
        {
          filteringId: 'fund-type-col',
          title: 'Fund Type',
          class: 'fund-table-item investment-2xl horizontal-col',
          filteringArrow: true,
          filteringHandler: handleChangeFilterItemForFunds,
          selected: sortByColFunds === '0',
          filteringOrder: sortDirectionFunds,
        },
        {
          filteringId: 'fund-notes-col',
          title: 'Notes',
          class: 'fund-table-item investment-xl horizontal-col',
        },
      ];
      if (funds) {
        for (let i = 0; i < funds.length; i += 1) {
          const fund = funds[i];
          const fundTypeIdentifier = fund.fundType && fund.fundType.identifier ? fund.fundType.identifier : '';
          const fundIdentifier = Object.keys(FUND_TYPES).find(type => FUND_TYPES[type].id === fundTypeIdentifier);
          const fundType = fundIdentifier && FUND_TYPES[fundIdentifier] ? FUND_TYPES[fundIdentifier].displayName : '';
          results.push([
            {
              id: 'fund-name-res-col',
              displayName: fund.fundLegalName,
              type: 'item',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFundsDetail,
              actionResultIdentifier: fund._id,
              class: `fund-table-item investment-xl horizontal-col remove-scroll-bar ${fund.fundLegalName.length > 20 ? 'fund-table-item-padding-align' : ''}`,
            },
            {
              id: 'firm-name-res-col',
              displayName: fund.firm.name,
              type: 'description',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFirmFundsDetail,
              actionResultIdentifier: fund.firm._id,
              class: 'fund-table-item investment-xl horizontal-col',
            },
            {
              id: 'not-ready-to-close-amt-col',
              displayName: `${calculateAmountsToDate(fund).notYetReadyToCloseAmount}`,
              type: 'description',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFundsDetail,
              actionResultIdentifier: fund._id,
              class: 'fund-table-item investment-xl horizontal-col',
            },
            {
              id: 'ready-close-amt-col',
              displayName: `${
                calculateAmountsToDate(fund).readyToCloseAmount
              }`,
              type: 'description',
              class: 'fund-table-item investment-xl horizontal-col',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFundsDetail,
              actionResultIdentifier: fund._id,
            },
            {
              id: 'closed-amt-col',
              displayName: `${calculateAmountsToDate(fund).closedAmount}`,
              type: 'description',
              class: 'fund-table-item investment-xl horizontal-col',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFundsDetail,
              actionResultIdentifier: fund._id,
            },
            {
              id: 'fund-type-col',
              displayName: fundType,
              type: 'item',
              class: 'fund-table-item investment-2xl horizontal-col investment-large-text',
              actionResult: true,
              actionResultText: '',
              actionCallback: redirectToFundsDetail,
              actionResultIdentifier: fund._id,
            },
            {
              id: 'fund-notes-col',
              displayName: fund.fundNote ? fund.fundNote : '',
              type: 'description',
              editable: true,
              actionResult: true,
              editing: fund.editingNote,
              editingProperty: 'notes',
              emptyText: 'N/A',
              actionResultIdentifier: fund._id,
              inputIdentifier: `${fund._id}-notes`,
              dataType: fund.investor
              ? INVESTMENT_TYPES.INVESTOR_FUND
              : INVESTMENT_TYPES.LIMITED_PARTNER_FUND,
              inputChangeHandler: handleChangeField,
              editFieldHandler: handleEditField,
              actionCallback: handleSaveField,
              class: 'fund-table-item investment-xl horizontal-col',
            }
          ]);
          rowActions.push({
            actionIdentifier: fund._id,
            actionRowClickHandler: handleFundDetail,
          });
        }
      }
      sortData =
        sortByColFunds !== '-1'
          ? utility.gunderSort(results, sortByColFunds, sortKey)
          : results;
      sortResults =
        sortDirectionFunds === 'desc' ? sortData : sortData.reverse();
    }
    // [actionRowNoAnchor] remove the anchor link for all table rows
    return { headers, results: sortResults, rowActions, actionRowNoAnchor: true };
  };

  useEffect(() => {
    const asyncWrap = async () => {
      if (props.admin.currentTab === 'funds') {
        if (initialLoadFunds) {
          setFundsCurrentPage(1);
          await fetchFunds(1);
        }
      } else {
        if (initialLoadFirms) {
          setFirmsCurrentPage(1);
          await fetchFirms(1);
        }
      }
    };
    asyncWrap();
  }, [tableMaxItems]);
  const setPaginationsItems = async (event) => {
    // const { admin } = props;
    const maxItems = event.target.value;
    setTableMaxItems(maxItems);
  };

  const selectPage = async (page) => {
    const { admin } = props;

    if (admin.currentTab === 'funds') {
      await fetchFunds(page);
    } else {
      await fetchFirms(page);
    }
  };

  const handleChangeFilterItemForFunds = (event) => {
    event.preventDefault();
    let index = '-1';
    const identifier = event.target.getAttribute('data-identifier');
    setSortKey(event.target.innerText);
    switch (event.target.innerText) {
      case 'Fund Name':
      case 'Fund Type':
      case 'Name':
        index = '0';
        break;
      case 'Firm':
      case 'Location':
        index = '1';
        break;
      case 'Amount Closed to Date':
         setSortKey('Amount');
        index = '2';
        break;
      case 'Not Yet Ready to Close':
         setSortKey('Amount');
        index = '3';
        break;
      case 'Amount Ready to Close':
        setSortKey('Amount');
        index = '4';
        break;
      default:
        index = '-1';
        break;
    }
    if (sortByColFunds === identifier) {
      setSortDirectionFunds(sortDirectionFunds === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirectionFunds('desc');
      setSortByColFunds(identifier);
    }
  };

  const { admin, context } = props;
  const tableContents = renderTableContents();
  return (
    <div>
      <Helmet title="Admin Dashboard" />
      <Banner
        imageRefClass={
          admin.currentTab === 'funds'
            ? 'funds-image-banner'
            : 'firms-image-banner'
        }
        content={
          <TabIndexContainer
            sections={[
              {
                id: 'fundSection',
                displayName: 'Funds',
                active: admin.currentTab === 'funds' ? 'active' : '',
                clickHandler: handleSectionSelection,
              },
              {
                id: 'firmSection',
                displayName: 'Firms',
                active: admin.currentTab === 'firms' ? 'active' : '',
                clickHandler: handleSectionSelection,
              },
            ]}
          />
        }
      />
      {/* <Alert
        inputData={{
          type: context.notificationType,
          title: context.title,
          message: context.message,
        }}
      /> */}
      <div className="main-container container-pull main-dashboard">
        <div className="container">
          <FilterBox
            data={{
              boxTitle:
                admin.currentTab === 'firms' ? 'All Firms' : 'All Funds',
              buttonId: 'add-btn',
              buttonDisplayName:
                admin.currentTab === 'firms' ? 'Add Firm' : 'Add Fund',
              buttonIcon: 'fa-plus-circle',
              buttonType: 'button',
              filterType: 'search',
            }}
            changeHandler={searchChangeHandler}
            searchHandler={handleFilterBoxSearch}
            buttonHandler={handleFilterBoxAction}
            searchValue={searchValue}
            enterHandler={handleFilterBoxSearch}
          />
          <TableContainer
            content={tableContents}
            loadMoreHandler={handleLoadMore}
            horizontalScroll
            scrollable
            paginationConf={{
              data:
                admin.currentTab === 'funds' ? fundTotalPages : firmTotalPages,
              currentPage:
                admin.currentTab === 'funds'
                  ? fundsCurrentPage
                  : firmsCurrentPage,
              max: tableMaxItems,
              refreshData: setPaginationsItems,
              selectPage: selectPage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * @param {Gunderson.Reducer} state
*/
const mapStateToProps = (state) => ({
  admin: state.admin,
  context: state.context,
});

/**
 * @type {import('react-redux').Connect<Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
   * @param {Gunderson.Dispatch} dispatch
  */
  (dispatch) => ({
    addAdminFirms: (_firms) => {
      dispatch({ type: action.SET_FIRMS, firms: _firms });
      if (!_firms || _firms.length < action.LOAD_LENGTH) {
        dispatch({ type: action.SET_CAN_LOAD_FIRMS, canLoadFirms: false });
      }
    },
    addAdminFunds: (_funds) => {
      dispatch({ type: action.SET_FUNDS, funds: _funds });
      if (!_funds || _funds.length < action.LOAD_LENGTH) {
        dispatch({ type: action.SET_CAN_LOAD_FUNDS, canLoadFunds: false });
      }
    },
    editFund: (fundPayload, history, returnUrl, updatingCb) =>
      dispatch(editFund(fundPayload, history, returnUrl, updatingCb)),
    setTab: (tab) => dispatch(action.setTab(tab)),
    resetLoadMore: () => dispatch(action.resetLoadMore()),
    setCanLoadFirms: (canLoad) =>
      dispatch({ type: 'SET_CAN_LOAD_FIRMS', canLoadFirms: canLoad }),
    setCanLoadFunds: (canLoad) =>
      dispatch({ type: 'SET_CAN_LOAD_FUNDS', canLoadFunds: canLoad }),
    notifyPasswordChange: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'warning',
        message: 'For security purposes, please update your password.',
        title: 'Account Security',
      }),
  })
);
export default React.memo(connector(AdminDashboard));
