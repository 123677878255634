import type { Dispatch, GetState, ThunkAction } from '../../types';

export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';
export const SET_ORGANIZATION_SETTINGS_PATH = 'SET_ORGANIZATION_SETTINGS_PATH';
export const SET_ORGANIZATION_SIGN_UP_CONFIRM =
  'SET_ORGANIZATION_SIGN_UP_CONFIRM';
export const SET_ORGANIZATION_SIGN_UP_USER = 'SET_ORGANIZATION_SIGN_UP_USER';

const API_URL = '/api';

export const login = (
  username: string,
  password: string,
  history
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(
    `${API_URL}/iqUsers/admin/login/`,
    { email: username, encryptedPassword: password },
    {}
  );
  if (res.data.success) {
    if (res.data.user.role.toLowerCase() === 'admin') {
      if (typeof window !== 'undefined') {
        localStorage.setItem('organization-auth-token', res.data.token);
      }
      dispatch({ type: SET_ORGANIZATION_USER, user: res.data.user });
      if (res.data.user.organization) {
        dispatch({
          type: SET_ORGANIZATION,
          organization: res.data.user.organization,
        });
        history.push('/organization/questionnaires');
      } else {
        dispatch({
          type: SET_ORGANIZATION,
          organization: { _id: 'temp-acces-id' },
        });
        setTimeout(() => {
          history.push('/organization/add');
        }, 1000);
      }
      dispatch({ type: SET_LOGIN_STATUS, status: '' });
    }
  } else {
    dispatch({ type: SET_LOGIN_STATUS, status: 'Authentication failed' });
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'error',
      message: res.data.message,
      title: 'Login Error',
    });
  }
  return res;
};

export const loginToken = (history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('organization-auth-token')) {
      const res = await axios.post(`${API_URL}/iqUsers/recover`, {
        token:
          typeof window !== 'undefined'
            ? localStorage.getItem('organization-auth-token')
            : '',
      });
      if (res.data.success) {
        dispatch({ type: 'SET_ORGANIZATION_USER', user: res.data.user });
        dispatch({
          type: 'SET_ORGANIZATION',
          organization: res.data.user.organization,
        });
        dispatch({ type: 'SET_LOGIN_STATUS', status: '' });
        history.push('/organization/questionnaires');
      } else {
        localStorage.removeItem('organization-auth-token');
      }
      return res;
    }
  }
};

export const temporalLogin = (email, rs, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(`${API_URL}/temporalLogin`, {
    email,
    rsToken: rs,
  });
  dispatch({ type: SET_ORGANIZATION_USER, user: res.data.user });
  dispatch({
    type: SET_ORGANIZATION,
    organization: res.data.user.organization,
  });
  dispatch({ type: SET_LOGIN_STATUS, status: '' });
  if (typeof window !== 'undefined') {
    localStorage.setItem('rs-token', rs);
  }
  history.push('/admin/main/profile');
  return res;
};

export const sendVerificationEmail = (to): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(`${API_URL}/sendVerificationEmailOrganization`, {
    email: to,
  });
  if (res.data.success) {
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'success',
      message: res.data.message,
      title: 'Password Reset Successful',
    });
  } else {
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'error',
      message: res.data.message,
      title: 'Something went wrong',
    });
  }
  return res;
};

export const verifyIQUser = (
  email: string,
  verifyToken: string,
  history
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    //FIXME check backend
    const res = await axios.post(
      `${API_URL}/iqUsers/verify?verify=${verifyToken}`,
      {}
    );

    if (res.data.success) {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'success',
        message: res.data.message,
        title: 'User verification success',
      });
      dispatch({ type: SET_LOGIN_STATUS, status: '' });
      if (res.data.iqUser) {
        if (res.data.iqUser.role.toLowerCase() === 'admin') {
          dispatch({ type: SET_ORGANIZATION_USER, user: res.data.iqUser });
          if (typeof window !== 'undefined') {
            if (res.data.token) {
              localStorage.setItem('organization-auth-token', res.data.token);
            }
          }
          if (res.data.foundOrganization) {
            dispatch({
              type: SET_ORGANIZATION,
              organization: res.data.iqUser.organization,
            });
            history.push('/organization/questionnaires');
          } else {
            dispatch({
              type: SET_ORGANIZATION,
              organization: { _id: 'temp-acces-id' },
            });
            setTimeout(() => {
              history.push('/organization/add');
            }, 1000);
          }
        }
      }
    } else {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: res.data.message,
        title: 'Error',
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};
