/* eslint-disable no-param-reassign */
// import Cookies from 'universal-cookie';
/**
 * @typedef {Gunderson.Dispatch} Dispatch
 * @typedef {Gunderson.GetState} GetState
 * @typedef {Gunderson.ThunkAction} ThunkAction
 * @typedef {(dispatch: Dispatch, getState: GetState, axios: any) => Promise<ThunkAction>} ThunkActionFunction
*/
import { handleError } from './../../utils/requestErrorHandler';

export const LOAD_LENGTH = 3;

const API_URL = '/api';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

/**
 
 * @returns {ThunkAction}
*/
export const sendMailShareFund = (
  to,
  text,
  fundId,
  fundName
) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const res = await axios.post(
      `${API_URL}/sendMailShareFund`,
      { to, text, fundId, fundName },
      getConfig()
    );
    const closeModal = document.getElementById(`close_modal_icon${fundId}`);
    if (closeModal) closeModal.click();
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: res.data.success ? 'success' : 'error',
      message: res.data.message,
      title: `Share Fund ${res.data.success ? 'Success' : 'Failed'}`,
    });
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/**
 * @returns {ThunkActionFunction}
*/
export const exportCSV = (csvPayload) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const csvCreatedResponse = await axios.post(
      `${API_URL}/fund/createcsv`,
      csvPayload,
      {
        ...getConfig(),
        responseType: 'arraybuffer',
      }
    );
    const file = new Blob([csvCreatedResponse.data], {
      type: csvCreatedResponse.headers['content-type'],
    });
    const fileURL = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = fileURL;
    a.download = csvCreatedResponse.headers['content-disposition']
      .split('filename=')[1]
      .replace(/['"]+/g, '');
    a.click();
    window.URL.revokeObjectURL(fileURL);
    return csvCreatedResponse;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/* istanbul ignore next */
/**
 * @returns {ThunkActionFunction}
*/
export const updateEmail = (fundId) =>
  // eslint-disable-next-line arrow-body-style
  async (dispatch, getState, axios) => {
    try {
      const res = await axios.post(
        `${API_URL}/dailyNotification/${fundId}`,
        {},
        getConfig()
      );
      if (res.data.success) {
        dispatch({
          type: RENDER_NOTIFICATION,
          notificationType: 'success',
          message: res.data.message,
          title: 'Send VC Update',
        });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

/**
 * @returns {ThunkActionFunction}
*/
export const fetchInvestorFunds = (page) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const currentFund = getState().admin.dashboardSelectedFund;
    const res = await axios.get(
      `${API_URL}/fund/${currentFund._id}/investorFunds/${page}`,
      getConfig()
    );
    if (!currentFund.investorFunds) currentFund.investorFunds = [];
    if (!currentFund.limitedPartnerFunds) currentFund.limitedPartnerFunds = [];
    currentFund.investorFunds.concat(res.data.investorFunds);
    currentFund.limitedPartnerFunds.concat(res.data.limitedPartnerFunds);
    if (
      res.data.investorFunds.length + res.data.limitedPartnerFunds.length <
      LOAD_LENGTH
    ) {
      currentFund.canLoadInvestorFunds = false;
    }
    dispatch({
      type: 'SET_DASHBOARD_FUND',
      dashboardSelectedFund: currentFund,
    });
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/**
 * @returns {ThunkActionFunction}
*/
export const fetchFund = (id, callback) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const res = await axios.get(`${API_URL}/fund/${id}`, getConfig());
    if (res.data.fund.investorFunds.length < LOAD_LENGTH) {
      res.data.fund.canLoadInvestorFunds = false;
    } else {
      res.data.fund.canLoadInvestorFunds = true;
    }
    dispatch({
      type: 'SET_DASHBOARD_FUND',
      dashboardSelectedFund: res.data.fund,
    });
    callback(res.data.fund);
    return res;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/**
 * @returns {ThunkActionFunction}
*/
export const updateInvestorFund = (
  updatePayload,
  callback
) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const res = await axios.put(
      `${API_URL}/investorFund/`,
      updatePayload,
      getConfig()
    );
    const resp = await axios.get(
      `${API_URL}/fund/${res.data.fund._id}`,
      getConfig()
    );
    if (
      resp.data.fund.investorFunds.length +
        resp.data.fund.limitedPartnerFunds.length <
      LOAD_LENGTH
    ) {
      resp.data.fund.canLoadInvestorFunds = false;
    } else {
      resp.data.fund.canLoadInvestorFunds = true;
    }
    dispatch({
      type: 'SET_DASHBOARD_FUND',
      dashboardSelectedFund: resp.data.fund,
    });
    callback(resp.data.fund);
    return resp;
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/**
 * @returns {ThunkActionFunction}
*/
export const updateFundLastClosing = (updatePayload) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    await axios.put(`${API_URL}/fund/`, updatePayload, getConfig());
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};

/**
 * @returns {ThunkActionFunction}
*/
export const updateInvestorFundsSequentally = (investorFunds) => (
  dispatch,
  getState,
  axios
) => {
  investorFunds.reduce(async (curr, next) => {
    await axios.put(`${API_URL}/investorFund/`, curr, getConfig());
    next();
  });
};

/**
 * @returns {ThunkActionFunction}
*/
export const addPaperIQ = (fund, loggedUserEmail, createdRole) => async (
  dispatch,
  getState,
  axios
) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/addPaperIQ/`,
      { fund, loggedUserEmail, createdRole },
      getConfig()
    );
    if (window.Cypress) {
      return data;
    }
    window.open(`/access/login/?lpf=${data.limitedPartnerFund._id}`, '_blank');
  } catch (err) {
    handleError(err, getState(), dispatch);
  }
};
