import React from 'react';
import './styles.scss';

type Props = { containerContent: Object, direction: string };

const ScrollableContainer = ({ containerContent, direction }: Props) => (
  <div>
    <div className={direction === 'horizontal' ? 'horizontal-pane' : 'scroll-pane'}>
      {containerContent}
    </div>
  </div>
);

ScrollableContainer.defaultProps = {
  containerContent: {},
};

export default ScrollableContainer;

