import React from 'react';
import './styles.scss';


type Props = { searchHandler: ()=>{}, changeHandler: ()=>{},
  searchValue: string, enterHandler: ()=>{}, };

const SearchBox = ({ searchHandler, changeHandler, searchValue, enterHandler }: Props) => (
  <div className="search-box large">
    <form onSubmit={enterHandler}>
      <input type="text" placeholder="Search" onChange={changeHandler} value={searchValue} />
      <button onClick={searchHandler} type="button" >
        <i className="fa fa-search" aria-hidden="true" />
      </button>
    </form>
  </div>
);

SearchBox.defaultProps = {
  searchHandler: () => {},
};

export default SearchBox;

