module.exports = {
  LimitedPartnerV1: 'LimitedPartnerV1',
  LimitedPartnerV2: 'LimitedPartnerV2',
  LimitedPartnerV3: 'LimitedPartnerV3',
  LimitedPartnerV4: 'LimitedPartnerV4',
  LimitedPartnerV5: 'LimitedPartnerV5',
  LimitedPartnerV6: 'LimitedPartnerV6',
  LimitedPartnerV7: 'LimitedPartnerV7',
  LimitedPartnerV8: 'LimitedPartnerV8',
  LimitedPartnerV8_1: 'LimitedPartnerV8_1',

  // Versioning by new fund created
  LimitedPartnerV9: 'LimitedPartnerV9',
  LimitedPartnerV10: 'LimitedPartnerV10',
  LimitedPartnerV11: 'LimitedPartnerV11',

  LimitedLiabilityCompanyV1: 'LimitedLiabilityCompanyV1',
  LimitedLiabilityCompanyV2: 'LimitedLiabilityCompanyV2',
  LimitedLiabilityCompanyV3: 'LimitedLiabilityCompanyV3',
  LimitedLiabilityCompanyV4: 'LimitedLiabilityCompanyV4',
  LimitedLiabilityCompanyV5: 'LimitedLiabilityCompanyV5',
  LimitedLiabilityCompanyV6: 'LimitedLiabilityCompanyV6',
  LimitedLiabilityCompanyV6_1: 'LimitedLiabilityCompanyV6_1',

  // Versioning by new fund created
  LimitedLiabilityCompanyV7: 'LimitedLiabilityCompanyV7',
  LimitedLiabilityCompanyV8: 'LimitedLiabilityCompanyV8',
  LimitedLiabilityCompanyV9: 'LimitedLiabilityCompanyV9',

  ForesiteV1: 'ForesiteV1',
  ForesiteV2: 'ForesiteV2',
  ForesiteV3: 'ForesiteV3',
  ForesiteV4: 'ForesiteV4',
  ForesiteV5: 'ForesiteV5',
  ForesiteV6: 'ForesiteV6',
  ForesiteV7: 'ForesiteV7',

  QuestaCapitalV1: 'QuestaCapitalV1',
  QuestaCapitalV2: 'QuestaCapitalV2',
  QuestaCapitalV3: 'QuestaCapitalV3',
  QuestaCapitalV4: 'QuestaCapitalV4',
  QuestaCapitalV5: 'QuestaCapitalV5',
  QuestaCapitalV6: 'QuestaCapitalV6',
  QuestaCapitalV7: 'QuestaCapitalV7',

  InitializedV1: 'InitializedV1',
  InitializedV2: 'InitializedV2',
  InitializedV3: 'InitializedV3',
  InitializedV4: 'InitializedV4',
  InitializedV5: 'InitializedV5',
  InitializedV6: 'InitializedV6',
  InitializedV7: 'InitializedV7',

  HCMV1: 'HCMV1',
  HCMV2: 'HCMV2',
  HCMV3: 'HCMV3',
  HCMV4: 'HCMV4',

  ForesiteLabsV1: 'ForesiteLabsV1',
  ForesiteLabsV2: 'ForesiteLabsV2',
  ForesiteLabsV3: 'ForesiteLabsV3',
  ForesiteLabsV4: 'ForesiteLabsV4',

  LPABBRV1: 'LPABBRV1',
  LPABBRV1_1: 'LPABBRV1_1',

  // Versioning by new fund created
  LPABBRV2: 'LPABBRV2',
  LPABBRV3: 'LPABBRV3',
  LPABBRV4: 'LPABBRV4',

  IOLARV1: 'IOLVARV1',
  IOLARV2: 'IOLVARV2',

  OSAGEV1: 'OSAGEV1',
  OSAGEV2: 'OSAGEV2',

  NewLimitedPartnerV1: 'NewLimitedPartnerV1',
  NewLimitedPartnerV2: 'NewLimitedPartnerV2',

  TcgCrossOverV1: 'TcgCrossOverV1',
  TcgCrossOverV2: 'TcgCrossOverV2',

  BITKRAFTV1: 'BITKRAFTV1',
  BITKRAFTV2: 'BITKRAFTV2',

  ElephantPartnerV1: 'ElephantPartnerV1',
  ElephantPartnerV2: 'ElephantPartnerV2',

  FireworkV1: 'FireworkV1',
  FireworkV2: 'FireworkV2',

  PrometheanV1: 'PrometheanV1',
  PrometheanV2: 'PrometheanV2',

  AccelV1: 'AccelV1',
  AccelV2: 'AccelV2',
  AccelV3: 'AccelV3',

  AccelMainV1: 'AccelMainV1',

  AccelEntrepreneursV1: 'AccelEntrepreneursV1',

  AccelEtpnReturningV1: 'AccelEtpnReturningV1',
  AccelEtpnReturningV2: 'AccelEtpnReturningV2',

  //Short MultiFund
  LPMULTIFUNDV1: 'LPMULTIFUNDV1',

  // Versioning by new fund created
  LPMULTIFUNDV2: 'LPMULTIFUNDV2',
  LPMULTIFUNDV3: 'LPMULTIFUNDV3',

  LimitedPartnershipMFV1: 'LimitedPartnershipMFV1',
  LimitedPartnershipMFV2: 'LimitedPartnershipMFV2',
  LimitedPartnershipMFV3: 'LimitedPartnershipMFV3',

  AudereV1: 'AudereV1',
  AudereV2: 'AudereV2',

  CaymanV1: 'CaymanV1',
  CaymanV2: 'CaymanV2',

  SROneV1: 'SROneV1',

  FrazierV1: 'FrazierV1',
};
