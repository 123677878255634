/* @flow */

import _ from 'lodash';

import {
SET_CONTENT,
SET_USER_FORM,
SET_INVITE_FORM,
SET_ORGANIZATION_FORM,
  RESET_FORMS,
} from './action';
import type { FundSettings, Action } from '../../types';

type State = FundSettings;

const initialState = {
  userForm: {
    firstName: { displayName: 'First Name', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    lastName: { displayName: 'Last Name', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    email: { displayName: 'Email Address', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required', 'email'] },
    password: { displayName: 'Password', value: '', valid: true, isRequired: true, errorMessage: '', validators: [''] },
    confirmPassword: { displayName: 'Confirm Password', value: '', valid: true, isRequired: true, errorMessage: '', validators: [''] },
    matching: { error: false, message: '' },
    loading: { submit: false, data: false },
  },
  organizationForm: {
    organizationName: { displayName: 'Name', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    city: { displayName: 'City', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    state: { displayName: 'State', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    country: { displayName: 'Country', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
  },
  inviteForm: {
    firstName: { displayName: 'First Name', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    lastName: { displayName: 'Last Name', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required'] },
    emailAddress: { displayName: 'Email Address', value: '', valid: true, isRequired: true, errorMessage: '', validators: ['required', 'email'] },
  },
  content: 'profile', // profile, organization, users
};

// eslint-disable-next-line consistent-return
export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_CONTENT:
      return _.assign({}, state, { content: action.content });
    case SET_USER_FORM:
      return _.assign({}, state, {
        userForm: {
          ...state.userForm,
          [action.fieldName]: {
            ...state.userForm[action.fieldName],
            [action.fieldProperty]: action.fieldPropertyValue,
          },
        },
      });
    case SET_INVITE_FORM:
      return _.assign({}, state, {
        inviteForm: {
          ...state.inviteForm,
          [action.fieldName]: {
            ...state.inviteForm[action.fieldName],
            [action.fieldProperty]: action.fieldPropertyValue,
          },
        } });
    case SET_ORGANIZATION_FORM:
      return _.assign({}, state, {
        organizationForm: {
          ...state.organizationForm,
          [action.fieldName]: {
            ...state.organizationForm[action.fieldName],
            [action.fieldProperty]: action.fieldPropertyValue,
          },
        } });
    case RESET_FORMS:
// eslint-disable-next-line no-param-reassign
      state = initialState;
      return state;
    case 'RESET_INVITE_FORM':
      return _.assign({}, state, {
        inviteForm: { ...initialState.inviteForm },
      });
    default:
      return state;
  }
};
