/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */

import React, { PureComponent } from 'react';
import './styles.scss';
import Part from '../../../components/Part/index';

type Props = {
  currentStep: number,
  clickHandler: () => void,
};
export class QuestionnaireStepsAccelEtpnReturning extends PureComponent {
  props: Props;

  static defaultProps: {
    currentStep: 0,
    clickHandler: () => {},
  };

  componentDidMount() { }

  getStepStatus = (stepId) => {
    const { currentStep } = this.props;
    const stepIdSequence = Number(stepId.split('step-')[1]);
    if (stepIdSequence === currentStep) {
      return 'active';
    } else if (stepIdSequence < currentStep) {
      return 'inactive';
    }
  };

  render() {
    const { clickHandler } = this.props;
    
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <ul className="questionare-parts-container clearfix">
            <Part
              data={{
                heading: 'Part 1',
                title: 'Investor Questionnaire',
                id: 'step-0',
                status: this.getStepStatus('step-0'),
              }}
              clickHandler={clickHandler}
            />
            <Part
              data={{
                heading: 'Part 2',
                title: 'Sign and Submit',
                id: 'step-1',
                status: this.getStepStatus('step-1'),
              }}
              clickHandler={clickHandler}
            />
          </ul>
        </div>
      </div>
    );
  }
}
