/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import DuoWebSDK from 'duo_web_sdk';
import Helmet from 'react-helmet';
import * as action from './action';
import * as validator from './../../utils/form-validation';
import * as utility from './../../utils/utility';
import * as notify from './../../utils/notify';
import './styles.scss';
import { Context as ContextType, Dispatch, Reducer } from '../../types/index';
import Button from '../../components/Button/index';
import Footer from '../../components/Footer/index';
import TextInput from '../../components/TextInput/index';
import Alert from '../../components/Alert/index';

type Props = {
  history: Object,
  context: ContextType,
  login: () => void,
  loginToken: () => void,
  sendVerificationEmail: () => void,
  temporalLogin: () => void,
  verifyDuoResponse: () => void,
  notifyIE: () => {},
  disableLogin: () => {},
};

const initialState = {
  username: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  duoUsername: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  password: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    validators: ['required'],
  },
  forgotPass: { error: false, message: '', type: '', title: '' },
  duoReady: false,
  primaryAuthReady: false,
  duoSignRequest: {},
};

// Export this for unit testing more easily
export class AdminLogin extends PureComponent {
  props: Props;

  static defaultProps: {
    history: {},
    context: { canLogIn: true },
    login: () => {},
    loginToken: () => {},
    sendVerificationEmail: () => {},
    temporalLogin: () => {},
    verifyDuoResponse: () => {},
    notifyIE: () => {},
    disableLogin: () => {},
  };

  constructor(state) {
    super(state);
    this.state = initialState;
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.updateStateFromValidation = this.updateStateFromValidation.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.duoPostAction = this.duoPostAction.bind(this);
    this.handleDuoUsernameChange = this.handleDuoUsernameChange.bind(this);
  }

  componentDidMount() {
    const { loginToken, temporalLogin, history } = this.props;
    loginToken(history);
    if (utility.getUrlParameter('rs')) {
      temporalLogin(
        utility.getUrlParameter('e'),
        utility.getUrlParameter('rs'),
        history
      );
    }
    if (document.title !== 'Fund Formation Portal - Fund Tracker')
      notify.hideNotification();
    if (window) {
      if (
        navigator.userAgent.indexOf('MSIE ') > -1 ||
        navigator.userAgent.indexOf('Trident') > -1 ||
        /MSIE 10/i.test(navigator.userAgent) ||
        /MSIE 9/i.test(navigator.userAgent) ||
        /rv:11.0/i.test(navigator.userAgent)
      ) {
        this.props.notifyIE();
        this.props.disableLogin();
      }
    }
  }

  duoPostAction(form) {
    const { verifyDuoResponse, context, history } = this.props;
    verifyDuoResponse(
      form[0].value,
      context.primaryAuthReady.username,
      (value) => console.log(value, 'completed verification'),
      history
    );
  }

  initDuoWebSDK = (signRequest, host) => {
    setTimeout(() => {
      DuoWebSDK.init({
        iframe: 'duo-frame',
        host: host,
        sig_request: signRequest,
        submit_callback: this.duoPostAction,
      });
    }, 500);
  };

  handleUsernameChange(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        username: {
          ...this.state.username,
          value: event.target.value,
        },
      },
      () => {
        validator.runFieldValidations(
          this.state.username,
          'username',
          this.state.username.value,
          this.updateStateFromValidation
        );
      }
    );
  }
  handleDuoUsernameChange(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        duoUsername: {
          ...this.state.duoUsername,
          value: event.target.value,
        },
      },
      () => {
        validator.runFieldValidations(
          this.state.duoUsername,
          'duoUsername',
          this.state.duoUsername.value,
          this.updateStateFromValidation
        );
      }
    );
  }

  handlePasswordChange(event) {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        password: {
          ...this.state.password,
          value: event.target.value,
        },
      },
      () => {
        validator.runFieldValidations(
          this.state.password,
          'password',
          this.state.password.value,
          this.updateStateFromValidation
        );
      }
    );
  }
  handleForgotPassword(event) {
    event.preventDefault();
    const { sendVerificationEmail } = this.props;
    const forgotLink = document.getElementById('forgotPasswordLink');
    forgotLink.style.opacity = 0.3;
    if (this.state.username.value === '') {
      this.setState({
        ...this.state,
        forgotPass: {
          ...this.state.forgotPass,
          error: true,
          message: 'Enter your username to reset your password!',
          type: 'warning',
          title: 'Warning',
        },
      });
    } else {
      sendVerificationEmail(this.state.username.value.toLowerCase());
    }
    setTimeout(() => {
      this.setState({
        ...this.state,
        forgotPass: {
          ...this.state.forgotPass,
          error: false,
          message: '',
          type: '',
          title: '',
        },
      });
      forgotLink.style.opacity = 1;
    }, 3000);
  }
  handleLoginSubmit(event) {
    event.preventDefault();
    const { login, history } = this.props;
    login(
      this.state.username.value.toLowerCase(),
      this.state.password.value,
      history,
      this.initDuoWebSDK
    );
  }

  validateEmail() {
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.state.username.value) === false) {
      return false;
    }
    return true;
  }
  renderTextInput = (
    id,
    type,
    displayName,
    placeholder,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    disabled
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      noTitle: true,
      errorMessage,
      placeholder,
      disabled,
    };
    return (
      <TextInput inputData={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  updateStateFromValidation(validationResult, fieldName, value, errMessage) {
    this.setState({
      ...this.state,
      [fieldName]: {
        ...this.state[fieldName],
        value,
        valid: validationResult,
        errorMessage: errMessage,
      },
    });
  }
  generateDuoFrame = () => (
    <iframe id="duo-frame" title="DUO" className="login-frame" />
  );
  render() {
    const { context } = this.props;
    const disabledInputs = !context.canLogIn;
    return (
      <div className="main-container">
        <Helmet title="Admin Login" />
        <div className="container">
          {/* <Alert
            inputData={{
              showAlways: context.showAlways,
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}
          <div className="userlogin-section">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3 className="color-dark-green text-align-center">
                  Fund Formation Portal Administration
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-9 col-sm-11 col-xs-12 center-block">
                <form onSubmit={this.handleLoginSubmit}>
                  <div className="userlogin-main text-align-center content-bg">
                    <h6 className="color-black">Admin Sign In</h6>
                    {!context.duoReady
                      ? this.renderTextInput(
                          'login-email',
                          'text',
                          'Email',
                          'Username',
                          true,
                          'large',
                          !this.state.username.valid,
                          this.state.username.errorMessage,
                          this.handleUsernameChange,
                          disabledInputs
                        )
                      : null}
                    {!context.duoReady
                      ? this.renderTextInput(
                          'login-password',
                          'password',
                          'Password',
                          'Password',
                          true,
                          'large',
                          !this.state.password.valid,
                          this.state.password.errorMessage,
                          this.handlePasswordChange,
                          disabledInputs
                        )
                      : null}
                    {context.duoReady ? this.generateDuoFrame() : null}
                    <div className="spacer">
                      <br />
                    </div>
                    {!context.duoReady ? (
                      <div className="forgot-password-container">
                        <a
                          id="forgotPasswordLink"
                          onClick={this.handleForgotPassword}
                          href="#/"
                        >
                          Forgot password?
                        </a>
                        <br />
                        <br />
                        {this.state.forgotPass.error ? (
                          <div className="error-message not-match-pass red-color">
                            <span className="text">
                              {this.state.forgotPass.message}
                            </span>
                          </div>
                        ) : (
                          <div className="error-message not-match-pass green-color">
                            <span className="text">
                              {this.state.forgotPass.message}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <hr />
                    {!context.duoReady ? (
                      <Button
                        inputData={{
                          id: 'admin-user-submit',
                          displayName: 'Submit',
                          type: 'submit',
                          isDisabled: disabledInputs,
                        }}
                        clickHandler={this.handleLoginSubmit}
                      />
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state: Reducer) => ({ context: state.context });
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    login: (username, password, history, initDuo) =>
      dispatch(action.login(username, password, history, initDuo)),
    verifyDuoResponse: (signedResponse, email, callback, history) =>
      dispatch(
        action.verifyDuoResponse(signedResponse, email, callback, history)
      ),
    loginToken: (history) => dispatch(action.loginToken(history)),
    sendVerificationEmail: (to) => dispatch(action.sendVerificationEmail(to)),
    temporalLogin: (email, rs, history) =>
      dispatch(action.temporalLogin(email, rs, history)),
    notifyIE: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Please change to Chrome or Firefox',
        title: 'You are using an unsupported browser',
        showAlways: true,
      }),
    disableLogin: () => dispatch({ type: 'SET_CAN_LOG_IN', canLogIn: false }),
  })
);
export default connector(AdminLogin);
