/**
 * Created by alego on 6/13/2017.
 */

export const notifyTimeout = 12000;

export const showNotification = (timeout) => {
  // eslint-disable-next-line
  if (!timeout) timeout = notifyTimeout;
  const notification = document.getElementById('container-notify');
  if (timeout !== 'infinite') {
    if (notification) {
      notification.hidden = false;
      notification.style.animationName = 'alertHide';
      setTimeout(() => {
        notification.hidden = true;
        notification.style.animationName = '';
      }, timeout);
    }
  } else if (notification) {
    notification.hidden = false;
    notification.style.animationName = 'alertHide';
  }
};

export const hideNotification = (e) => {
  if (e) {
    e.preventDefault();
  }
  const notification = document.getElementsByClassName(
    'notification-alert-container'
  );
  if (notification) notification.hidden = true;
};
