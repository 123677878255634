/* eslint-disable react/sort-comp,import/prefer-default-export,no-shadow, no-loop-func,
 no-nested-ternary */

/* @flow */

import React, { PureComponent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import Button from '../../../../../../components/Button/index';
import ConfirmCheckbox from '../../../../../../components/ConfirmCheckbox/index';
import FundTool from '../../../../../../components/FundTool/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import TextArea from '../../../../../../components/TextArea/index.uncontrolled';
import TextInput from '../../../../../../components/TextInput/index.uncontrolled';
import Title from '../../../../../../components/Title/index';
import Tooltip from '../../../../../../components/Tooltip/index';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import DateTimeZone from '../../../../../../utils/date-time-zone';
import * as notify from '../../../../../../utils/notify';
import { saveGeneralInformation } from '../../../../util';
import { QuestionnaireIndex } from '../QuestionnaireIndex/index.v1';
import './../../../../styles_common/questionnaire_form.scss';
import './styles.scss';
import HtmlParser from 'react-html-parser';

/**
 * @typedef {Object} Props
 * @property {Gunderson.Context} context
 * @property {Gunderson.QuestionnaireExperience} questionnaireContext
*/

export class QuestionnaireForm extends PureComponent {
  /**
   * @type {Props}
  */
  props

  static defaultProps = {
    context: {},
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    const { questionnaireContext } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    const payload = {};
    if (questionnaireContext.multiAccess) {
      payload.limitedPartnerFund =
        questionnaireContext.investorFund._id.toString();
    } else {
      payload.investorFund = questionnaireContext.investorFund._id.toString();
    }

    const generalInfo = questionnaireContext.investorFund.generalInfo;
    payload.name = generalInfo.name;
    payload.signatory = generalInfo.signatory;
    payload.tax = generalInfo.tax;
    payload.country = generalInfo.country;
    payload.countryPlace = generalInfo.countryPlace;
    payload.types = generalInfo.types;
    payload.otherValue = generalInfo.otherValue;
    payload.namePrimaryContact = generalInfo.namePrimaryContact;
    payload.emailPrimaryContact = generalInfo.emailPrimaryContact;
    payload.addressPrimaryContact = generalInfo.addressPrimaryContact;
    payload.phonePrimaryContact = generalInfo.phonePrimaryContact;
    payload.capitalCommitment = generalInfo.capitalCommitment;
    payload.nameLetter = generalInfo.nameLetter;
    payload.totalAllFund = generalInfo.totalAllFund;
    payload.additionalContactInfo = generalInfo.additionalContactInfo;
    payload.additionalDistributionInfo = generalInfo.additionalDistributionInfo;
    // eslint-disable-next-line
    payload.principalPlaceOfBusiness =
      generalInfo.principalPlaceOfBusiness || '';
    payload.principalPlaceOfBusinessPrimaryContact =
      generalInfo.principalPlaceOfBusinessPrimaryContact || '';
    // eslint-disable-next-line
    payload.principalPlaceOfResidence =
      generalInfo.principalPlaceOfResidence || '';
    payload.principalPlaceOfResidencePrimaryContact =
      generalInfo.principalPlaceOfResidencePrimaryContact || '';
    // eslint-disable-next-line
    payload.jurisdictionOrganitzationPrimaryContact =
      generalInfo.jurisdictionOrganitzationPrimaryContact;
    payload.reportsPrimaryContactName = generalInfo.reportsPrimaryContactName;
    payload.reportsPrimaryContactEmail = generalInfo.reportsPrimaryContactEmail;
    payload.reportsPrimaryContactPhone = generalInfo.reportsPrimaryContactPhone;
    payload.reportsSecondaryContactName =
      generalInfo.reportsSecondaryContactName;
    payload.reportsSecondaryContactEmail =
      generalInfo.reportsSecondaryContactEmail;
    payload.reportsSecondaryContactPhone =
      generalInfo.reportsSecondaryContactPhone;
    payload.taxPrimaryContactName = generalInfo.taxPrimaryContactName;
    payload.taxPrimaryContactEmail = generalInfo.taxPrimaryContactEmail;
    payload.taxPrimaryContactPhone = generalInfo.taxPrimaryContactPhone;
    payload.taxSecondaryContactName = generalInfo.taxSecondaryContactName;
    payload.taxSecondaryContactEmail = generalInfo.taxSecondaryContactEmail;
    payload.taxSecondaryContactPhone = generalInfo.taxSecondaryContactPhone;
    payload.capitalPrimaryContactName = generalInfo.capitalPrimaryContactName;
    payload.capitalPrimaryContactEmail = generalInfo.capitalPrimaryContactEmail;
    payload.capitalPrimaryContactPhone = generalInfo.capitalPrimaryContactPhone;
    payload.capitalSecondaryContactName =
      generalInfo.capitalSecondaryContactName;
    payload.capitalSecondaryContactEmail =
      generalInfo.capitalSecondaryContactEmail;
    payload.capitalSecondaryContactPhone =
      generalInfo.capitalSecondaryContactPhone;
    payload.stockPrimaryContactName = generalInfo.stockPrimaryContactName;
    payload.stockPrimaryContactEmail = generalInfo.stockPrimaryContactEmail;
    payload.stockPrimaryContactPhone = generalInfo.stockPrimaryContactPhone;
    payload.stockSecondaryContactName = generalInfo.stockSecondaryContactName;
    payload.stockSecondaryContactEmail = generalInfo.stockSecondaryContactEmail;
    payload.stockSecondaryContactPhone = generalInfo.stockSecondaryContactPhone;
    payload.legalPrimaryContactName = generalInfo.legalPrimaryContactName;
    payload.legalPrimaryContactEmail = generalInfo.legalPrimaryContactEmail;
    payload.legalPrimaryContactPhone = generalInfo.legalPrimaryContactPhone;
    payload.legalSecondaryContactName = generalInfo.legalSecondaryContactName;
    payload.legalSecondaryContactEmail = generalInfo.legalSecondaryContactEmail;
    payload.legalSecondaryContactPhone = generalInfo.legalSecondaryContactPhone;
    payload.bankName = generalInfo.bankName;
    payload.acctName = generalInfo.acctName;
    payload.swiftCode = generalInfo.swiftCode;
    payload.ibanNumber = generalInfo.ibanNumber;
    payload.interBankAcctNumber = generalInfo.interBankAcctNumber;
    payload.interBankAcctName = generalInfo.interBankAcctName;
    payload.interBankAba = generalInfo.interBankAba;
    payload.interBankName = generalInfo.interBankName;
    payload.interBankSwift = generalInfo.interBankSwift;
    payload.additionalComment = generalInfo.additionalComment;
    payload.acctNumber = generalInfo.acctNumber;
    payload.bankaba = generalInfo.bankaba;
    payload.furtherCredit = generalInfo.furtherCredit;
    payload.furtherCreditNumber = generalInfo.furtherCreditNumber;
    payload.location = generalInfo.location;

    switch (name) {
      case 'legalNameBeneficalOwner':
        payload.legalNameBeneficalOwner = value;
        payload.residenceBeneficalOwner =
          questionnaireContext.investorFund.generalInfo.residenceBeneficalOwner;

        break;
      case 'residenceBeneficalOwner':
        payload.residenceBeneficalOwner = value;
        payload.legalNameBeneficalOwner =
          questionnaireContext.investorFund.generalInfo.legalNameBeneficalOwner;

        break;
    }
    saveGeneralInformation(payload, this.props);
  };

  componentDidMount() {
    this.updateTextAnswersState();
    notify.hideNotification();
  }

  updateTextAnswersState = () => {

    const id_investor_status_7_tcg_o_Data = this.parseQuestionDataFromId(
      iqID.id_investor_status_7_tcg_o
    );

    if (this.id_investor_status_7_tcg_o_Ref) {
      this.id_investor_status_7_tcg_o_Ref.value = id_investor_status_7_tcg_o_Data && id_investor_status_7_tcg_o_Data.answer
        ? id_investor_status_7_tcg_o_Data.description
        : ' ';
    }

    // repopulate text inputs values if they exist
    const id_wiring_bank_status_Data = this.parseQuestionDataFromId(
      iqID.id_wiring_bank_status_1_opt
    );
    const id_special_entities_1_b_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_1_b_opt
    );
    const id_special_entities_2_e_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_2_e_opt
    );
    const id_warranties_exceptions_Data = this.parseQuestionDataFromId(
      iqID.id_warranties_exceptions
    );

    const id_sr_one_part_b_4_name_of_company_1_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_name_of_company_1
    );
    const id_sr_one_part_b_4_name_of_company_2_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_name_of_company_2
    );
    const id_sr_one_part_b_4_name_of_company_3_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_name_of_company_3
    );

    const id_sr_one_part_b_4_beneficial_interest_1_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_beneficial_interest_1
    );
    const id_sr_one_part_b_4_beneficial_interest_2_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_beneficial_interest_2
    );
    const id_sr_one_part_b_4_beneficial_interest_3_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_4_beneficial_interest_3
    );

    const id_sr_one_part_b_1_text_note_Data = this.parseQuestionDataFromId(
      iqID.id_sr_one_part_b_1_text_note
    );

    if (this.id_sr_one_part_b_1_text_note_Ref) {
      this.id_sr_one_part_b_1_text_note_Ref.value = id_sr_one_part_b_1_text_note_Data
        ? id_sr_one_part_b_1_text_note_Data.answer
        : '';
    }

    if (this.id_sr_one_part_b_4_beneficial_interest_1_Ref) {
      this.id_sr_one_part_b_4_beneficial_interest_1_Ref.value = id_sr_one_part_b_4_beneficial_interest_1_Data
        ? id_sr_one_part_b_4_beneficial_interest_1_Data.answer
        : '';
    }
    if (this.id_sr_one_part_b_4_beneficial_interest_2_Ref) {
      this.id_sr_one_part_b_4_beneficial_interest_2_Ref.value = id_sr_one_part_b_4_beneficial_interest_2_Data
        ? id_sr_one_part_b_4_beneficial_interest_2_Data.answer
        : '';
    }
    if (this.id_sr_one_part_b_4_beneficial_interest_3_Ref) {
      this.id_sr_one_part_b_4_beneficial_interest_3_Ref.value = id_sr_one_part_b_4_beneficial_interest_3_Data
        ? id_sr_one_part_b_4_beneficial_interest_3_Data.answer
        : '';
    }

    if (this.id_sr_one_part_b_4_name_of_company_1_Ref) {
      this.id_sr_one_part_b_4_name_of_company_1_Ref.value = id_sr_one_part_b_4_name_of_company_1_Data
        ? id_sr_one_part_b_4_name_of_company_1_Data.answer
        : '';
    }
     if (this.id_sr_one_part_b_4_name_of_company_2_Ref) {
      this.id_sr_one_part_b_4_name_of_company_2_Ref.value = id_sr_one_part_b_4_name_of_company_2_Data
        ? id_sr_one_part_b_4_name_of_company_2_Data.answer
        : '';
    }
     if (this.id_sr_one_part_b_4_name_of_company_3_Ref) {
      this.id_sr_one_part_b_4_name_of_company_3_Ref.value = id_sr_one_part_b_4_name_of_company_3_Data
        ? id_sr_one_part_b_4_name_of_company_3_Data.answer
        : '';
    }

    if (this.id_wiring_bank_status_1_opt_Ref) {
      this.id_wiring_bank_status_1_opt_Ref.value = id_wiring_bank_status_Data
        ? id_wiring_bank_status_Data.answer
        : '';
    }
    if (this.id_special_entities_1_b_opt_Ref) {
      this.id_special_entities_1_b_opt_Ref.value =
        id_special_entities_1_b_opt_Data
          ? id_special_entities_1_b_opt_Data.answer
          : '';
    }

    if (this.id_special_entities_2_e_opt_Ref) {
      this.id_special_entities_2_e_opt_Ref.value =
        id_special_entities_2_e_opt_Data
          ? id_special_entities_2_e_opt_Data.answer
          : '';
    }

    this.id_warranties_exceptions_Ref.value = id_warranties_exceptions_Data
      ? id_warranties_exceptions_Data.answer
      : '';
  };
  handlerFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    const nextStep = questionnaireContext.currentStep + 1;
    this.props.handleRedirect(event, nextStep);
  };
  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
  };

  parseCheckboxStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return [
          targetQuestion.answer === 'Yes',
          targetQuestion.answer === 'No',
        ];
      }
      return [false, false];
    }
    return [false, false];
  };
  parseMultiSelectStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion.answer === 'Yes';
      }
      return false;
    }
    return false;
  };
  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };
  handlerConfirm = (event) => {
    event.preventDefault();
    const answer = event.target.nextSibling.innerHTML;
    const descriptionIdentifier = event.target.closest('div').parentElement.parentElement.id;
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };
  orQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id !== _identifier &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // right now this is saving one by one . But ideally we should do bulks updates or at least Promise all
        const answer = 'No';
        const descriptionIdentifier = item.id;

        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };

  categoriesOfBeneficialOwnership = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList }, x
      },
    } = this.props;
    const categoriesOfBeneficialOwnershipQuestions = questionnaireContext &&
      questionnaireContext.currentVersionQuestions.categoriesOfBeneficialOwnershipQuestions;

    const indexToAlpha = (num = 1) => {
      // ASCII value of first character
      const A = 'A'.charCodeAt(0);
      let numberToCharacter = number => {
        return String.fromCharCode(A + number);
      };
      return numberToCharacter(num);
    };

    const getValue = (value) => this.parseQuestionDataFromId(value) ? this.parseQuestionDataFromId(value).description : ''

    const triggerOptions = (id) => {
      if (id === 'id_investor_status_7_tcg_m') {
        return {
          value: getValue('id_investor_status_7_tcg_m'), id: `id_investor_status_7_tcg_m`, input: 'select',
          selectOptions: ['', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'], changeHandler: this.handleTextInputBlur, style: { width: '60px' }
        }
      } else if (id === 'id_investor_status_7_tcg_o') {
        return {
          id: `id_investor_status_7_tcg_o`, style: { width: '500px' }, input: 'text2',
          reference: (_node) => this.id_investor_status_7_tcg_o_Ref = _node,
          blurHandler: (e) => this.handleTextInputBlur(e, this.id_investor_status_7_tcg_o_Ref.value)
        }

      } else {
        return {}
      }
    }

    const results = [];
    if (categoriesOfBeneficialOwnershipQuestions) {
      for (
        let i = 0;
        i < categoriesOfBeneficialOwnershipQuestions.length;
        i += 1
      ) {
        results.push({
          id: categoriesOfBeneficialOwnershipQuestions[i].id,
          displayName: ReactHtmlParser(
            categoriesOfBeneficialOwnershipQuestions[i].displayName
          ),
          name: categoriesOfBeneficialOwnershipQuestions[i].name,
          identifier: categoriesOfBeneficialOwnershipQuestions[i].identifier,
          isSelected: this.parseMultiSelectStateFromId(
            categoriesOfBeneficialOwnershipQuestions[i].id
          ),
          helpText: categoriesOfBeneficialOwnershipQuestions[i].helpText,
          showHelp: categoriesOfBeneficialOwnershipQuestions[i].showHelp,
          disabled: disabledList.includes(categoriesOfBeneficialOwnershipQuestions[i].id),
          triggerOptions: triggerOptions(categoriesOfBeneficialOwnershipQuestions[i].id),
          letter: indexToAlpha(i),
          // this part should come from top part lists
        });
      }
      return results;
    }
  };

  regularQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id.includes('_or') &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // can be simplified with the orQuestionSelected on top look for better naming
        const answer = 'No';
        const descriptionIdentifier = item.id;
        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  // send the belonging section here
  handlerMultiSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    if (event.target.id.includes('_or')) {
      this.orQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    } else {
      // if any other is marked verify that or question is not selected from the targetSection
      this.regularQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    }
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  // send the belonging section here
  handlerSingleSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    this.orQuestionSelected(
      event.target.checked,
      event.target.id,
      _targetSection
    );
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  accreditedInvestorRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const accreditedInvestorRepresentationsQuestions =
      questionnaireContext.currentVersionQuestions
        .accreditedInvestorRepresentations;
    const results = [];
    for (
      let i = 0;
      i < accreditedInvestorRepresentationsQuestions.length;
      i += 1
    ) {
      results.push({
        id: accreditedInvestorRepresentationsQuestions[i].id,
        displayName: ReactHtmlParser(
          accreditedInvestorRepresentationsQuestions[i].displayName
        ),
        name: accreditedInvestorRepresentationsQuestions[i].name,
        identifier: accreditedInvestorRepresentationsQuestions[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          accreditedInvestorRepresentationsQuestions[i].id
        ),
        helpText: accreditedInvestorRepresentationsQuestions[i].helpText,
        showHelp: accreditedInvestorRepresentationsQuestions[i].showHelp,
        disabled: disabledList.includes(
          accreditedInvestorRepresentationsQuestions[i].id
        ), // this part should come from top part lists
      });
    }
    return results;
  };

  qualifiedPurchaserRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const results = [];
    const qualifiedPurchaserRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedPurchaserRepresentations;
    for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
      results.push({
        id: qualifiedPurchaserRepresentations[i].id,
        displayName: qualifiedPurchaserRepresentations[i].displayName,
        name: qualifiedPurchaserRepresentations[i].name,
        identifier: qualifiedPurchaserRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedPurchaserRepresentations[i].id
        ),
        helpText: qualifiedPurchaserRepresentations[i].helpText,
        showHelp: qualifiedPurchaserRepresentations[i].showHelp,
        disabled: disabledList.includes(
          qualifiedPurchaserRepresentations[i].id
        ),
      });
    }
    return results;
  };

  qualifiedClientRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const qualifiedClientRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedClientRepresentations;
    const results = [];
    for (let i = 0; i < qualifiedClientRepresentations.length; i += 1) {
      results.push({
        id: qualifiedClientRepresentations[i].id,
        displayName: qualifiedClientRepresentations[i].displayName,
        name: qualifiedClientRepresentations[i].name,
        identifier: qualifiedClientRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedClientRepresentations[i].id
        ),
        helpText: qualifiedClientRepresentations[i].helpText,
        showHelp: qualifiedClientRepresentations[i].showHelp,
        disabled: disabledList.includes(qualifiedClientRepresentations[i].id),
      });
    }
    return results;
  };

  foreignPersonStatusContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations[i].id,
        displayName: foreignPersonStatusRepresentations[i].displayName,
        name: foreignPersonStatusRepresentations[i].name,
        identifier: foreignPersonStatusRepresentations[i].identifier,
        helpText: foreignPersonStatusRepresentations[i].helpText,
        showHelp: foreignPersonStatusRepresentations[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations[i].id
        ),
      });
    }
    return results;
  };
  foreignPersonStatusContent2 = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations2 =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations2.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations2[i].id,
        displayName: foreignPersonStatusRepresentations2[i].displayName,
        name: foreignPersonStatusRepresentations2[i].name,
        identifier: foreignPersonStatusRepresentations2[i].identifier,
        helpText: foreignPersonStatusRepresentations2[i].helpText,
        showHelp: foreignPersonStatusRepresentations2[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations2[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations2[i].id
        ),
      });
    }
    return results;
  };
  // This one should be handled as part of the optional questions set at the
  checkBadActorQuestionAbility = (identifier) => {
    if (identifier === iqID.id_investor_status_5_f) {
      if (
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_a) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_b) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_c) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_d) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_e)
      ) {
        return false; // to show return true;
      }
    }
    return true;
  };

  handleTextInputBlur = (event, currentValue) => {
    event.preventDefault();
    const hasId = [
      'id_investor_status_7_tcg_o',
      'id_investor_status_7_tcg_m',
    ]

    const answer = event.target.value;
    const descriptionIdentifier = event.target.id;
    const tcgCross = hasId.includes(descriptionIdentifier)

    const current = this.parseQuestionDataFromId(descriptionIdentifier);
    if (!current || current.answer !== currentValue && !tcgCross) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
    }
    if ((!current || current.answer) && tcgCross) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, 'Yes', answer);

    }

  };

  renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled
  ) => {
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
    };
    return (
      <ConfirmCheckbox data={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

 newIssuesQuestionnaireContentQuestions = (questionsName) => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const newIssuesQuestionnaires = questionnaireContext.currentVersionQuestions &&
     questionnaireContext.currentVersionQuestions[questionsName] &&
     questionnaireContext.currentVersionQuestions[questionsName];

     const exhibitCA = questionnaireContext.currentVersionQuestions &&
      questionnaireContext.currentVersionQuestions.exhibitCA &&
      questionnaireContext.currentVersionQuestions.exhibitCA || [];

     const exhibitCB = questionnaireContext.currentVersionQuestions &&
      questionnaireContext.currentVersionQuestions.exhibitCB &&
      questionnaireContext.currentVersionQuestions.exhibitCB || [];

     const triggerOptions = (index) => {
      const question = newIssuesQuestionnaires[index];

      if (question.id === iqID.id_sr_one_part_a_3) {
        const nested = questionnaireContext.currentVersionQuestions.srOnePartA3 || [];
        const index = nested.findIndex((item) => item.id === iqID.id_sr_one_part_a_3_b);

        const srOnePartA3Options = nested.map((item) => (
          {
            ...item, 
            isSelected: this.parseMultiSelectStateFromId(item.id), 
            disabled: disabledList.includes(iqID.id_sr_one_part_a_3) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_a_3),
          }
        ))

        const exhibitCAOptions = exhibitCA.map((item) => (
          {
            ...item, 
            isSelected: this.parseMultiSelectStateFromId(item.id), 
            disabled: disabledList.includes(iqID.id_sr_one_part_a_3) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_a_3),
          }
        ))

        srOnePartA3Options.splice(index + 1, 0, {
            type: 'radioButton',
            options: exhibitCAOptions,
            isSelected: this.parseMultiSelectStateFromId(iqID.id_sr_one_part_a_3_b),
            changeHandler: (e) => this.handlerSingleSelect(e, 'exhibitCA'),
          })

        return {
          input: 'singleSelect',
          showInput: true,
          selectOptions: srOnePartA3Options || [],
          changeHandler: (e) => this.handlerSingleSelect(e, 'srOnePartA3'),
        }
      }

      if (question.id ===  iqID.id_sr_one_part_b_1) {
        const nested = questionnaireContext.currentVersionQuestions.srOnePartB1 || [];
        
        const srOnePartA3Options = nested.map((item) => (
              {
                ...item, 
                isSelected: this.parseMultiSelectStateFromId(item.id), 
                disabled: disabledList.includes(iqID.id_sr_one_part_b_1) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_1),
              }
            ))

        srOnePartA3Options.push({ 
            type: 'text',
            id: iqID.id_sr_one_part_b_1_text_note,
            name: iqID.id_sr_one_part_b_1_text_note,
            disabled: disabledList.includes(iqID.id_sr_one_part_b_1_text_note) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_1),
            inputText: HtmlParser(`<strong>Note:</strong> If any of the boxes above are checked, please indicate the names of all such associated public companies and covered non-public companies: `),
            reference: (_node) => this.id_sr_one_part_b_1_text_note_Ref = _node,
            blurHandler: (e) => this.handleTextInputBlur(e,  this.id_sr_one_part_b_1_text_note_Ref.value),
          })

        return {
          showInput: true,
          input: 'singleSelect',
          selectOptions: srOnePartA3Options || [],
          changeHandler: (e) => this.handlerMultiSelect(e, 'srOnePartB1'),
        }
      }

      if (question.id ===  iqID.id_sr_one_part_b_3) {
        const nested = questionnaireContext.currentVersionQuestions.srOnePartB3 || [];
        const index = nested.findIndex((item) => item.id === iqID.id_sr_one_part_b_3_b);

         const srOnePartB3Options = nested.map((item) => (
              {
                ...item, 
                isSelected: this.parseMultiSelectStateFromId(item.id), 
                disabled: disabledList.includes(iqID.id_sr_one_part_b_3) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_3),
              }
            ))
        
        const exhibitCBOptions = exhibitCB.map((item) => (
          {
            ...item, 
            isSelected: this.parseMultiSelectStateFromId(item.id), 
            disabled: disabledList.includes(iqID.id_sr_one_part_b_3) || !this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_3),
          }
        ))

        srOnePartB3Options.splice(index + 1, 0, {
            type: 'radioButton',
            options: exhibitCBOptions,
            isSelected: this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_3_b),
            changeHandler: (e) => this.handlerSingleSelect(e, 'exhibitCB'),
          })


        return {
          input: 'singleSelect',
          showInput: true,
          selectOptions: srOnePartB3Options || [],
          changeHandler: (e) => this.handlerSingleSelect(e, 'srOnePartB3'),
        }
      }
     }

    const results = [];
    for (let i = 0; i < newIssuesQuestionnaires.length; i += 1) {
      results.push({
        id: newIssuesQuestionnaires[i].id,
        displayName: HtmlParser(newIssuesQuestionnaires[i].displayName),
        name: newIssuesQuestionnaires[i].name,
        identifier: newIssuesQuestionnaires[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          newIssuesQuestionnaires[i].id
        ),
        triggerOptions: triggerOptions(i),
        helpText: newIssuesQuestionnaires[i].helpText,
        showHelp: newIssuesQuestionnaires[i].showHelp,
        disabled: disabledList.includes(newIssuesQuestionnaires[i].id),
      });
    }
    return results;
  };

  /**
   * @param {Array} questions
  */
  newIssuesQuestionnaireContent = (questions) => {
     const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;

    const onKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.target.id === 'id_sr_one_part_b_4_name_of_company_1') {
          e.preventDefault();
          e.target.blur();
          setTimeout(() => {
            document.getElementById("id_sr_one_part_b_4_beneficial_interest_1").focus();
          }, 1000)
        }
        if (e.target.id === 'id_sr_one_part_b_4_beneficial_interest_1') {
          e.preventDefault();
          e.target.blur();
          setTimeout(() => {
            document.getElementById("id_sr_one_part_b_4_name_of_company_2").focus();
          }, 1000)
        }
        if (e.target.id === 'id_sr_one_part_b_4_name_of_company_2') {
          e.preventDefault();
          e.target.blur();
          setTimeout(() => {
            document.getElementById("id_sr_one_part_b_4_beneficial_interest_2").focus();
          }, 1000)
        }
        if (e.target.id === 'id_sr_one_part_b_4_beneficial_interest_2') {
          e.preventDefault();
          e.target.blur();
          setTimeout(() => {
            document.getElementById("id_sr_one_part_b_4_name_of_company_3").focus();
          }, 1000)
        }
        if (e.target.id === 'id_sr_one_part_b_4_name_of_company_3') {
          e.preventDefault();
          e.target.blur();
          setTimeout(() => {
            document.getElementById("id_sr_one_part_b_4_beneficial_interest_3").focus();
          }, 1000)
        }
      }
      
    }
    

    return (
      <>
          <div className="row align-text-push">
           <div className="two-columns-font-bold">
              <Paragraph text={'PART A.'} />
              <p>FINRA RULE 5130<br/> RESTRICTIONS ON THE PURCHASE AND SALE OF INITIAL EQUITY PUBLIC OFFERINGS</p>
            </div>
            <div className="flex-row">
               <Paragraph text={'The undersigned (check applicable box):'} />
                <Tooltip data={{message: `
                 <strong>Restricted Person. </strong>  A restricted person is any of the following:
                  <br /><br />
                  <div style="display: grid;grid-template-columns: repeat(2, 1fr);gap: 2rem;">
                    <div>
                      1. A FINRA member firm or other broker-dealer.
                      <br />
                      2. An officer, director, general partner, associated person or employee of a FINRA member firm or any other broker-dealer (other than a limited business broker-dealer).
                      <br />
                      3. An agent of a FINRA member firm or any other broker-dealer (other than a limited business broker-dealer) that is engaged in the investment banking or securities business.
                      <br />
                       4. With respect to the security being offered, a finder or any person acting in a fiduciary capacity to the managing underwriter, including, but not limited to, attorneys, accountants and financial consultants.
                      <br />
                      5. A person who has authority to buy or sell securities for a bank, savings and loan institution, insurance company, investment company, investment advisor or collective investment account.
                     <br />
                      6. Other than a sovereign entity, a person listed, or required to be listed, in one of the following schedules of a Form BD as filed, or required to be filed, with the SEC by a broker-dealer (other than with respect to a limited business broker-dealer):  (i) Schedule A, unless the person is identified by an ownership code of less than 10%; or (ii) Schedule B, unless the person’s listing on Schedule B relates to an ownership interest in a person that is listed on Schedule A and identified by an ownership code of less than 10%.
                    </div>
                    <div>
                      7. Other than a sovereign entity, a person that directly or indirectly owns an interest, in the amounts specified below, of a public reporting company listed, or required to be listed, in Schedule A or B of a Form BD relating to a broker-dealer (other than a reporting company that is listed on a national securities exchange or other than with respect to a limited business broker-dealer):  (i) 10% or more of a public reporting company listed, or required to be listed, on Schedule A; or (ii) 25% or more of a public reporting company listed, or required to be listed, on Schedule B.
                      <br />
                      8. (i) An immediate family member of a person specified in item 2 or 3 or if such person materially supports, or receives material support from, the immediate family member, is employed by or associated with the FINRA member or its affiliate selling the new issue to the immediate family member, or has an ability to control the allocation of the new issue, (ii) An immediate family member of a person specified in item 4 if such person materially supports, or receives material support from, the immediate family member, (iii) An immediate family member of a person specified in item 5 that materially supports, or receives material support from, such person, or (iv) An immediate family member of a person specified in item 6 or 7 unless the person owning the broker-dealer does not materially support, or receives material support from, the immediate family member, is not an owner or affiliate of the FINRA member selling the new issue to the immediate family member, and has no ability to control the allocation of the new issue.
                    </div>
                  </div>
                `}} />
            </div>
              <MultiSelect
                data={{
                  id: 'org-types-multiselect',
                  size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }}
                content={this.newIssuesQuestionnaireContentQuestions('newIssuesQuestionnaireA')}
                onChangeHandler={(e) =>
                 this.handlerSingleSelect(
                    e,
                    'newIssuesQuestionnaireA'
                  )
                }
              />
          </div>
          <div className="row align-text-push">
                   <div className="two-columns-font-bold">
                    <Paragraph text={'PART B.'} />
                    <p>FINRA RULE 5131<br/> NEW ISSUE ALLOCATIONS AND DISTRIBUTIONS</p>
                  </div>
                  <div className="flex-row">
                    <Paragraph text={'The undersigned (check applicable box):'} />
                    <Tooltip data={{message: `
                     <strong>Covered Person.</strong> A covered person is any of the following:
                     <br /><br />

                     <div style="display: grid;grid-template-columns: repeat(2, 1fr);gap: 2rem;">
                      <div>
                            1. An executive officer or director of a public company.
                            <br />
                            2. An executive officer or director of a covered non-public company.
                      </div>
                      <div>
                          3. A person materially supported by an executive officer or director of a public company or a covered non-public company.
                      </div>
                     </div>
                    `}} />
                  </div>
            <MultiSelect
              data={{
                id: 'org-types-multiselect',
                size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }}
              content={this.newIssuesQuestionnaireContentQuestions('newIssuesQuestionnaireB')}
              onChangeHandler={(e) =>
                this.handlerSingleSelect(
                  e,
                  'newIssuesQuestionnaireB'
                )
              }
            />
            {this.parseMultiSelectStateFromId(iqID.id_sr_one_part_b_or) && !disabledList.includes(iqID.id_sr_one_part_b_or) && (
                <div className="row">
                <div>
                  <TextInput
                    inputData={{
                      id: iqID.id_sr_one_part_b_4_name_of_company_1,
                      displayName: 'Name of Company',
                      isRequired: false,
                      size: 'medium',
                      onKeyDown: onKeyDown,
                      hasError: false,
                      type: 'text',
                    }}
                    reference={(_node) => {
                      this.id_sr_one_part_b_4_name_of_company_1_Ref = _node;
                    }}
                    onBlurHandler={(e) =>{
                      this.handleTextInputBlur(
                        e,
                        this.id_sr_one_part_b_4_name_of_company_1_Ref.value
                      )
                    }}
                  />
                  <TextInput
                    inputData={{
                      id: iqID.id_sr_one_part_b_4_beneficial_interest_1,
                      displayName: 'Respective Beneficial Interest of such Entity (%)',
                      isRequired: false,
                      size: 'medium',
                      onKeyDown: onKeyDown,
                      hasError: false,
                      type: 'text',
                    }}
                    reference={(_node) => {
                      this.id_sr_one_part_b_4_beneficial_interest_1_Ref = _node;
                    }}
                    onBlurHandler={(e) => {
                      this.handleTextInputBlur(
                        e,
                        this.id_sr_one_part_b_4_beneficial_interest_1_Ref
                          .value
                      )
                    }}
                  />
                  <TextInput
                    inputData={{
                      id: iqID.id_sr_one_part_b_4_name_of_company_2,
                      displayName: '',
                      isRequired: false,
                      onKeyDown: onKeyDown,
                      noTitle: true,
                      size: 'medium',
                      hasError: false,
                      type: 'text',
                    }}
                    reference={(_node) => {
                      this.id_sr_one_part_b_4_name_of_company_2_Ref = _node;
                    }}
                    onBlurHandler={(e) => {
                      this.handleTextInputBlur(
                        e,
                        this.id_sr_one_part_b_4_name_of_company_2_Ref.value
                      )
                    }}
                  />
                    <TextInput
                      inputData={{
                        id: iqID.id_sr_one_part_b_4_beneficial_interest_2,
                        displayName: '',
                        noTitle: true,
                        isRequired: false,
                        onKeyDown: onKeyDown,
                        size: 'medium',
                        hasError: false,
                        type: 'text',
                      }}
                      reference={(_node) => {
                        this.id_sr_one_part_b_4_beneficial_interest_2_Ref = _node;
                      }}
                      onBlurHandler={(e) =>
                        this.handleTextInputBlur(
                          e,
                          this.id_sr_one_part_b_4_beneficial_interest_2_Ref
                            .value
                        )
                      }
                    />
                  <TextInput
                    inputData={{
                      id: iqID.id_sr_one_part_b_4_name_of_company_3,
                      displayName: '',
                      noTitle: true,
                      onKeyDown: onKeyDown,
                      isRequired: false,
                      size: 'medium',
                      hasError: false,
                      type: 'text',
                    }}
                    reference={(_node) => {
                      this.id_sr_one_part_b_4_name_of_company_3_Ref = _node;
                    }}
                    onBlurHandler={(e) =>
                      this.handleTextInputBlur(
                        e,
                        this.id_sr_one_part_b_4_name_of_company_3_Ref.value
                      )
                    }
                  />
              <TextInput
                inputData={{
                  id: iqID.id_sr_one_part_b_4_beneficial_interest_3,
                  displayName: '',
                  noTitle: true,
                  isRequired: false,
                  onKeyDown: onKeyDown,
                  size: 'medium',
                  hasError: false,
                  type: 'text',
                }}
                reference={(_node) => {
                  this.id_sr_one_part_b_4_beneficial_interest_3_Ref = _node;
                }}
                onBlurHandler={(e) =>
                  this.handleTextInputBlur(
                    e,
                    this.id_sr_one_part_b_4_beneficial_interest_3_Ref
                      .value
                  )
                }
              />
              </div>
                </div>
              )
        }
            <p>Other Definitions used in Section I <Tooltip data={{
              message: `
              <div>
                 <u>Associated person or employee of a FINRA member firm.</u>   (1) Any natural person registered with FINRA and (2) any natural person, whether or not registered or exempt from registration with FINRA, who is a sole proprietor, partner, officer, director, or branch manager of a FINRA member firm, or any natural person occupying a similar status or performing similar functions, or any natural person engaged in the investment banking or securities business who is directly or indirectly controlling or controlled by a FINRA member firm (for example, any employee).
                  <br/>
                  <u>Beneficial interest.</u>  Any economic interest, including the right to share in gains or losses, other than management or performance based fees for operating a collective investment account, or other fees for acting in a fiduciary capacity.
                  <br/>
                  <u>Collective investment account.</u>  Any hedge fund, investment partnership, investment corporation, or any other collective investment vehicle that is engaged primarily in the purchase and sale of securities, but not: (1) a family investment vehicle, which is a legal entity that arrangement, a trust, an unincorporated organization, a government or any department or agency thereof, or any entity similar to any of the foregoing, whether foreign or domestic.
                  <br/>
                <u>Executive officer or director.</u>  Any (i) person named as an executive officer or director in a U.S. public company’s most recent proxy filed with the SEC or in an annual report filed with the SEC on Form 10-K or Form 20-F, (ii) executive officer or director of a foreign company that is registered with the SEC under the ‘34 Act, as amended, or (iii) executive officer or director of a covered non-public company.
                   <br/>
                 <u>Finder.</u>  A person who receives compensation for identifying potential investors in an offering.
                   <br/>
                  <u>FINRA Member.</u>  A member of the Financial Industry Regulatory Authority or any person or entity associated with a FINRA member firm.
                   <br/>
                 <u>Immediate family member.</u>  A person’s parents, mother-in-law or father-in-law, spouse, brother or sister, brother-in-law or sister-in-law, son-in-law or daughter-in-law and children, and any other individual to whom the person provides material support.
                   <br/>
                  <u>Limited business broker-dealer.</u>  Any broker-dealer whose authorization to engage in the securities business is limited solely to the purchase and sale of investment company/variable contracts securities and direct participation program securities.
                   <br/>
                 <u>Material support.</u>  Directly or indirectly providing more than 25% of a person’s income in the prior calendar year.  Members of the immediate family living in the same household are deemed to be providing each other with material support.
                   <br/>
                 <u> New issue.</u>  Any initial public offering of an equity security as defined in Section 3(a)(11) of the Exchange Act, made pursuant to a registration statement or offering circular; provided, however, that a new issue shall not include: 
                 <br/>
                  <ol style="list-style-type: lower-alpha;">
                    <li>offerings made pursuant to an exemption under Section 4(a)(1), 4(a)(2) or 4(a)(5) of the Securities Act, or Securities Act Rule 504 if the securities are “restricted securities” under Securities Act Rule 144(a)(3), or Rule 144A or Rule 505 or Rule 506 adopted thereunder, or offerings made under Regulation S of the Securities Act or otherwise made outside of the United States or its territories unless the securities offered and sold in the Regulation S offering or other offering made outside of the United States are also registered for sale in the United States under the Securities Act in connection with a concurrent initial public offering of an equity security in the United States; </li>
                    <li>offerings of exempted securities as defined in Section 3(a)(12) of the Exchange Act, and rules promulgated thereunder; </li>
                    <li>offerings of securities of a commodity pool operated by a commodity pool operator as defined under Section 1a(5) of the Commodity Exchange Act; </li>
                    <li>rights offerings, exchange offers, or offerings made pursuant to a merger or acquisition; </li>
                    <li>offerings of investment grade asset-backed securities; </li>
                    <li>offerings of convertible securities; </li>
                    <li>offerings of preferred securities; </li>
                    <li>offerings of an investment company registered under the Investment Company Act; </li>
                    <li>offerings of securities (in ordinary share form or ADRs registered on Form F-6) that have a pre-existing market outside of the United States; and </li>
                    <li>offerings of a special purpose acquisition company subject to Securities and Exchange Commission rules and regulations, a business development company as defined in Section 2(a)(48) of the Investment Company Act, a direct participation program as defined in Rule 2310(a) or a real estate investment trust as defined in Section 856 of the Internal Revenue Code. </li>
                  </ol>
                  <u>Public Company.</u> Any company that is registered under Section 12 of the Securities Exchange or files period reports pursuant to Section 15(d) thereof.
                   <br/>
                  <u>Sovereign Entity.</u> A sovereign nation or its political subdivisions, agencies or instrumentalities, or a pool of capital or an investment fund or other vehicle owned or controlled by a sovereign nation or its political subdivisions, agencies or instrumentalities and created for the purpose of making investments on behalf or for the benefit of the sovereign nation or its political subdivisions, agencies or instrumentalities.
              </div>
              `,
              
              
              }} /></p>

            <p className="">
              The undersigned acknowledges that, in making representations to brokers and other third parties, the Fund
              will be relying upon the information provided by the undersigned in this Section I. <u>If such representations
              shall cease to be true and accurate in any respect, the undersigned shall give immediate notice of such fact
              to the Fund.</u>
            </p>
      </div>
      </>
    )
  }

  render() {
    const {
      context,
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { optionals, disabled: disabledList },
      },
    } = this.props;
    const readableDate =
      questionnaireContext.investorFund.questionnaire &&
        questionnaireContext.investorFund.questionnaire.updatedAt
        ? new DateTimeZone(questionnaireContext.investorFund.questionnaire.updatedAt)
        : '';
    const { includesFormPFQuestion, includesRegulatoryFund } = questionnaireContext.fund;
    const formattedDate = readableDate !== ''
      ? readableDate.getLocalDate()
      : readableDate;
    const foreignYesOrNo =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2[0];

    return questionnaireContext.investorFund.questionnaire.questions ? (
      <div className="margin-bottom-spacing">
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          printHandler={null}
          saveLaterHandler={null}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="row">
          {' '}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content-bg main-content-area clearfix">
              <div className="row">
                <QuestionnaireIndex />
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <div className="main">
                    <h4 className="color-dark-green align-header-text-sub-header questionnaire-header">
                      Questionnaire
                    </h4>
                    <b>
                      <Paragraph
                        text={
                          'In connection with the proposed investment in the Fund, except as otherwise specified herein (including in Section J), the undersigned hereby represents as follows:'
                        }
                        className="align-header-text-sub-header"
                      />
                    </b>
                    <br />
                    <form onSubmit={this.handlerFormSubmit}>
                      <Title
                        text={
                          'A. General Investor Representations and Warranties'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content1'}
                      />
                      {questionnaireContext.currentVersionQuestions.generalInvestorRepresentationsandWarranties.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <Paragraph text={item.displayName} />
                            {item.toolTip && (
                              <Tooltip
                                data={{
                                  message: item.toolTip,
                                  className: 'position-absolute-tool-tip',
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                      <br />
                      <Title
                        text={'B. Identification of Investor Status'}
                        className="align-header-text-sub-header"
                        contentId={'content2'}
                      />
                      {questionnaireContext.currentVersionQuestions.identificationofInvestorStatus.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <div id={item.id}>
                              {item.type === 'title' && (
                                <div className="align-text-push">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Paragraph text={item.displayName} />
                                </div>
                              )}
                              {item.type === 'quest' &&
                                this.checkBadActorQuestionAbility(item.id) &&
                                item.id !== iqID.id_investor_status_1
                                ? this.renderConfirm(
                                  [
                                    item.radioIdentifier[0],
                                    item.radioIdentifier[1],
                                    item.id,
                                  ],
                                  item.displayName,
                                  this.parseCheckboxStateFromId(item.id),
                                  item.name,
                                  item.size,
                                  item.tooltip,
                                  this.handlerConfirm
                                )
                                : item.type === 'quest' &&
                                  this.checkBadActorQuestionAbility(item.id) &&
                                  item.id === iqID.id_investor_status_1
                                  ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm,
                                    [
                                      !questionnaireContext.isNaturalPerson,
                                      questionnaireContext.isNaturalPerson,
                                    ]
                                  )
                                  : null}
                              {/* this one may not be needed */}
                              {item.type === 'opt' &&
                                this.parseMultiSelectStateFromId(
                                  item.id.split('_opt')[0]
                                ) === item.conditional
                                ? this.renderConfirm(
                                  [
                                    item.radioIdentifier[0],
                                    item.radioIdentifier[1],
                                    item.id,
                                  ],
                                  item.displayName,
                                  this.parseCheckboxStateFromId(item.id),
                                  item.name,
                                  item.size,
                                  item.tooltip,
                                  this.handlerConfirm
                                )
                                : null}
                            </div>
                          </div>
                        )
                      )}
                      {includesFormPFQuestion &&
                        <div className="row">
                          <div>
                            <div className="align-text-push">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <p>
                                6. Categories of Beneficial Ownership
                                for Advisers Act Reporting: {' '}
                                The Fund’s management
                                company or an affiliate thereof may be required,
                                pursuant to the Investment Advisers Act
                                of 1940 (as amended, the “Advisers Act”), to make periodic filings on Form PF with the
                                Securities  and Exchange  Commission.  Form PF requires disclosure of, among other information,
                                the percentage composition of the Fund’s equity by the type of beneficial owner.
                                The undersigned hereby represents and warrants that the undersigned is one of the following
                                (check exactly one (1) applicable box that best describes the undersigned):
                              </p>
                            </div>
                          </div>
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.categoriesOfBeneficialOwnership()}
                            onChangeHandler={(e) =>
                              this.handlerSingleSelect(
                                e,
                                'categoriesOfBeneficialOwnershipQuestions'
                              )
                            } />
                        </div>}
                      <br />
                      <Title
                        text={'C. Accredited Investor Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content3'}
                      />
                      <div className="row">
                        <p id="accredited-investor-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is an Accredited Investor under Rule
                            501 of Regulation D promulgated by the Securities
                            and Exchange Commission and that the undersigned
                            qualifies as such because the undersigned is (check
                            applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.accreditedInvestorRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'accreditedInvestorRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'D. Qualified Purchaser Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content4'}
                      />
                      <div className="row">
                        <p id="qualified-purchaser-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Purchaser under
                            Section 2(a)(51) of the 1940 Act and that the
                            undersigned qualifies as such because the
                            undersigned is (check applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.qualifiedPurchaserRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'qualifiedPurchaserRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'E. Qualified Client Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content5'}
                      />
                      <div>
                        <div
                          id="qualified-client-principal-introduction"
                          className={`row ${disabledList.includes(
                            'qualified-client-principal-introduction'
                          )
                            ? 'color-gray'
                            : ''
                            }`}
                        >
                          <p>
                            <b>
                              If the undersigned is a qualified purchaser (and
                              the undersigned indicated the basis under which it
                              qualifies as such in the immediately preceding
                              Section D), the undersigned is not required to
                              complete this Section E and should skip to Section
                              F.
                            </b>
                          </p>
                          <p>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Client under Rule
                            205-3 of the Advisers Act promulgated by the U.S.
                            Securities and Exchange Commission, and that the
                            undersigned qualifies as such because the
                            undersigned is (check all applicable boxes):
                          </p>
                        </div>
                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect3',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.qualifiedClientRepresentationsContent()}
                            onChangeHandler={(e) =>
                              this.handlerMultiSelect(
                                e,
                                'qualifiedClientRepresentations'
                              )
                            }
                          />
                        </div>
                      </div>

                      <br />
                      <Title
                        text={
                          'F. Committee on Foreign Investment in the United States (“CFIUS”) Foreign Person Status Representations'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content6'}
                      />
                      <Paragraph
                        text={
                          '1. The undersigned hereby represents and warrants that the undersigned is (check applicable box):'
                        }
                        className="align-header-text-sub-header"
                      />
                      <div className="row bottom-space">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent()}
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations'
                            )
                          }
                        />
                      </div>

                      <br />
                      <div
                        className={`row ${questionnaireContext.isNaturalPerson && 'color-gray'
                          }`}
                      >
                        <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12 bottom-space">
                          {foreignYesOrNo ?
                            this.renderConfirm(
                              [
                                foreignYesOrNo.radioIdentifier[0],
                                foreignYesOrNo.radioIdentifier[1],
                                foreignYesOrNo.id,
                              ],
                              foreignYesOrNo.displayName,
                              this.parseCheckboxStateFromId(foreignYesOrNo.id),
                              foreignYesOrNo.name,
                              foreignYesOrNo.size,
                              foreignYesOrNo.tooltip,
                              this.handlerConfirm,
                              [
                                disabledList.includes(foreignYesOrNo.id),
                                disabledList.includes(foreignYesOrNo.id),
                              ]
                            ) : null}
                        </div>
                            <br />
                      </div>

                      <Title
                        text={'G. Wiring Bank Status'}
                        className="align-header-text-sub-header"
                        contentId={'content7'}
                      />
                      <div className="row">
                        {questionnaireContext.currentVersionQuestions.wiringBankStatus.map(
                          (item) => (
                            <div
                              key={item.id + item.displayName}
                              className="row"
                            >
                              <div id={item.id}>
                                {item.type === 'title' ? (
                                  <Paragraph text={item.displayName} />
                                ) : null}
                                <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                  {item.type === 'quest'
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        item.displayName,
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm
                                      )
                                    : null}
                                </div>
                                {item.type === 'opt' &&
                                item.id === iqID.id_wiring_bank_status_1_opt &&
                                optionals.includes(item.id) ? (
                                  <div>
                                    <Paragraph
                                      className="align-text-push reduce-bottom-margin"
                                      text={item.displayName}
                                    />
                                    <TextInput
                                      inputData={{
                                        id: item.id,
                                        isRequired: false,
                                        size: item.size,
                                        hasError: false,
                                        type: 'text',
                                      }}
                                      reference={(_node) => {
                                        this.id_wiring_bank_status_1_opt_Ref =
                                          _node;
                                      }}
                                      onBlurHandler={(e) =>
                                        this.handleTextInputBlur(
                                          e,
                                          this.id_wiring_bank_status_1_opt_Ref
                                            .value
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                     
                      <br />
                      <Title
                        text={'H. Identification of Special Entities'}
                        className="align-header-text-sub-header"
                        contentId={'content8'}
                      />

                      <div>
                        <div
                          id="special-entities-introduction-p"
                          className={`${
                            disabledList.includes(
                              'special-entities-introduction-p'
                            )
                              ? 'color-gray'
                              : ''
                          } row`}
                        >
                          <p>
                            <b>
                              If the undersigned is a natural person (and
                              indicated such by answering “Yes” to Question 1 in
                              Section B above), the undersigned is not required
                              to complete this Section H and should skip to
                              Section I.
                            </b>
                          </p>
                          <p>
                            In order to identify certain special entities whose
                            investment may require special treatment by the
                            Fund, please carefully answer each of the following
                            questions:
                          </p>
                        </div>
                        <div className="row">
                          {questionnaireContext.currentVersionQuestions.identificationofSpecialEntities.map(
                            (item) => (
                              <div
                                key={item.id + item.displayName}
                                className="row"
                              >
                                {item.id === iqID.id_special_entities_3_a ? (
                                  <div
                                    className={`${
                                      disabledList.includes(
                                        'special-entities-lost-descrip-quest'
                                      )
                                        ? 'color-gray'
                                        : ''
                                    } col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space`}
                                    id="special-entities-lost-descrip-quest"
                                  >
                                    <Paragraph
                                      text={
                                        '(a). any agency, authority or instrumentality of the state or political subdivision;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(b). a pool of assets sponsored or established by the state or political subdivision or any agency, authority or instrumentality thereof, including but not limited to a “defined benefit plan” as defined in section 414(j) of the Internal Revenue Code, or a state general fund;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(c). a plan or program of a government entity; and'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(d). officers, agents or employees of the state or political subdivision or any agency, authority or instrumentality thereof, acting in their official capacity?'
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div id={item.id}>
                                  {item.type === 'title' ? (
                                    <div className="align-text-push-max">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Paragraph
                                        id={`${item.id}-title`}
                                        className={`${
                                          disabledList.includes(
                                            `${item.id}-title`
                                          )
                                            ? 'color-gray'
                                            : ''
                                        }`}
                                        text={item.displayName}
                                      />
                                    </div>
                                  ) : null}
                                  <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                    {item.type === 'quest'
                                      ? this.renderConfirm(
                                          [
                                            item.radioIdentifier[0],
                                            item.radioIdentifier[1],
                                            item.id,
                                          ],
                                          item.displayName,
                                          this.parseCheckboxStateFromId(
                                            item.id
                                          ),
                                          item.name,
                                          item.size,
                                          item.tooltip,
                                          this.handlerConfirm,
                                          [
                                            disabledList.includes(item.id),
                                            disabledList.includes(item.id),
                                          ]
                                        )
                                      : null}
                                  </div>
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_1_b_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <TextInput
                                        inputData={{
                                          id: item.id,
                                          isRequired: false,
                                          size: item.size,
                                          hasError: false,
                                          type: 'text',
                                        }}
                                        reference={(_node) => {
                                          this.id_special_entities_1_b_opt_Ref =
                                            _node;
                                        }}
                                        onBlurHandler={(e) =>
                                          this.handleTextInputBlur(
                                            e,
                                            this.id_special_entities_1_b_opt_Ref
                                              .value
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_2_e_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin align-text-push"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <div className="align-text-push">
                                        <TextInput
                                          inputData={{
                                            id: item.id,
                                            isRequired: false,
                                            size: item.size,
                                            hasError: false,
                                            type: 'number',
                                          }}
                                          reference={(_node) => {
                                            this.id_special_entities_2_e_opt_Ref =
                                              _node;
                                          }}
                                          onBlurHandler={(e) =>
                                            this.handleTextInputBlur(
                                              e,
                                              this
                                                .id_special_entities_2_e_opt_Ref
                                                .value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <br />
                      {/*  I. New Issues Questionnaire (all italicized terms are defined in Exhibit C)  */}
                      <Title
                        text={'I. New Issues Questionnaire'}
                        className="align-header-text-sub-header"
                        contentId={'content9'}
                      />
                      <div className='row'>
                      {this.newIssuesQuestionnaireContent()}
                      </div>

                      <br />
                      <Title
                        text={'J. Miscellaneous'}
                        className="align-header-text-sub-header"
                        contentId={'content10'}
                      />
                      <div className="row">
                        {includesRegulatoryFund &&
                          <p>
                            1. If the General Partner forms a Regulatory Fund (as defined in the Fund Agreement)
                            for the purpose of seeking an alternate exemption from registration under the 1940 Act
                            to the exemption sought by the Fund (such Regulatory Fund, the “1940 Act Regulatory  Fund”),
                            the  undersigned  agrees  that  the  General  Partner  may,  in  its sole and absolute
                            discretion, elect to admit the undersigned as a limited partner of the 1940 Act Regulatory
                            Fund in lieu of admitting the undersigned as a Limited Partner of the Fund; provided,
                            that the material economic terms of the 1940 Act Regulatory Fund shall be substantially
                            identical to those of the Fund Agreement.  If  the  General  Partner  elects  to  admit
                            the  undersigned  to  such  a  1940  Act Regulatory Fund, the undersigned further
                            acknowledges and agrees that (a) all of the  covenants,  representations  and
                            warranties  made  by  the  undersigned  in  this Investor   Questionnaire
                            and   all   exhibits   and   other   documents   information accompanying
                            the  same  shall  be  deemed  to  have  been  delivered  to  the  General
                            Partner with respect to such 1940 Act Regulatory Fund and all references
                            to “the Fund” herein shall be deemed to apply to such 1940 Act Regulatory
                            Fund, and (b) the undersigned’s signature page to the Fund Agreement executed
                            in connection with the undersigned’s subscription for an interest in the Fund
                            shall be deemed to be a counterpart signature page to the limited partnership
                            agreement of such 1940 Act Regulatory Fund and may be attached as a counterpart
                            signature page to such agreement  (in  which  case  the  undersigned  agrees
                            that  the  undersigned  shall  be deemed to have made, and shall be subject to,
                            all of the covenants, representations, warranties  and  agreements  of  the
                            limited  partners  set  forth  in  the  limited partnership  agreement  of
                            the  1940  Act  Regulatory  Fund  to  the  extent  such covenants,
                            representations,  warranties  and  agreements  are  substantially
                            identical in form and substance to the covenants, representations,
                            warranties and agreements of  the  limited  partners  set  forth  in
                            the  Fund  Agreement).   In  such  event,  the undersigned hereby
                            authorizes the General Partner to redact or otherwise cross out any
                            reference  to  the  name  of  the  Fund  on  any  such  signature
                            page  to  the  Fund Agreement, and to insert the name of the 1940
                            Act Regulatory Fund in its place.
                          </p>}

                        {questionnaireContext.currentVersionQuestions.miscellaneous.map(
                          (item, i) => (
                            <p>
                              {includesRegulatoryFund ? i + 2 : i + 1}. {item.displayName}
                            </p>
                          ))}
                      </div>
                      <br />
                      <Title
                        text={'K. Exceptions to Representations and Warranties'}
                        className="align-header-text-sub-header"
                        contentId={'content11'}
                      />
                      <div className="row">
                        <TextArea
                          inputData={{
                            id: questionnaireContext.currentVersionQuestions
                              .warrantiesExceptions[0].id,
                            displayName:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].displayName,
                            isRequired: false,
                            hasError: false,
                            title: '',
                            description:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].description,
                          }}
                          reference={(_node) => {
                            this.id_warranties_exceptions_Ref = _node;
                          }}
                          onBlurHandler={(e) =>
                            this.handleTextInputBlur(
                              e,
                              this.id_warranties_exceptions_Ref.value
                            )
                          }
                        />
                      </div>
                      <div className="margin-bottom-spacing">
                        <div className="back-btn">
                          <Button
                            inputData={{
                              id: 'back-btn',
                              displayName: 'Back',
                              type: 'button',
                            }}
                            clickHandler={this.backStep}
                          />
                        </div>
                        <div className="next-btn">
                          <Button
                            inputData={{
                              id: 'next-btn',
                              displayName: 'Next',
                              type: 'submit',
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p>'...'</p>
    );
  }
}

/**
 * @param {Gunderson.Reducer} state
*/
const mapStateToProps = (state) => ({
  context: state.context,
  questionnaireContext: state.questionnaireExperience,
});

/**
 * @type {Gunderson.Connector<{}, Props>}
*/
const connector = connect(
  mapStateToProps,
  /**
   * @param {Gunderson.Dispatch} dispatch
  */
  (dispatch) => ({})
);

export default connector(QuestionnaireForm);
