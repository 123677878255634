/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import Dropdown from '../../components/Dropdown/index';
import './styles.scss';
const { paginationOptions } = require('../../utils/pagination');

type Props = {
  data: Object,
  currentPage: Number,
  max: Number,
  additionalClass: String,
  setPaginationItems: () => {},
  selectPage: () => {},
};

const Pagination = ({
  data,
  currentPage,
  max,
  additionalClass,
  refreshData,
  selectPage,
}: Props) =>
  data &&
  data.length > 0 && (
    <div className="row space-bottom">
      <div className={`col-lg-8 ${additionalClass ? 'align-items' : ''}`}>
        <div className="pagination-bar">
          {currentPage > 1 && (
            <a
              className="page-arrow"
              key="key-page-previous"
              onClick={() => selectPage(currentPage - 1)}
            >
              &laquo;
            </a>
          )}
          {data &&
            data.map((item, index) => (
              <a
                className={`page-item ${
                  currentPage === index + 1 && 'current-page'
                  }`}
                key={`${Math.random().toString(36).slice(-5)}-${index}`}
                onClick={(e) => selectPage(parseInt(e.target.text, 10))}
              >
                {index + 1}
              </a>
            ))}
          {currentPage !== data.length && (
            <a
              className="page-arrow"
              key="key-page-next"
              onClick={() => selectPage(currentPage + 1)}
            >
              &raquo;
            </a>
          )}
        </div>
      </div>
      <div className={`col-lg-4 dropdown-per-page${additionalClass}`}>
        <div className="custom-label"> Per page: </div>
        <Dropdown
          inputData={{
            id: 'pagination-max-items',
            value: max,
            size: 'small',
            options: paginationOptions,
          }}
          onChangeHandler={refreshData}
        />
      </div>
      <div className="spacer" />
    </div>
  );

Pagination.defaultProps = {
  data: [],
  currentPage: 0,
  max: 0,
  additionalClass: '',
  refreshData: () => { },
  selectPage: () => { },
};

export default Pagination;
