/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import Helmet from 'react-helmet';
import {
  Context as ContextType,
  OrganizationContext as OrganizationContextType,
  Reducer,
  Dispatch,
} from '../../types/index';
import TextInput from '../../components/TextInput/index';
import Button from '../../components/Button/index';
import Alert from '../../components/Alert/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';
import './styles.scss';
import {
  PASSWORD_LENGTH_MAX_MESSAGE,
  PASSWORD_LENGTH_MIN_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
} from '../../utils/constants/messages';
import * as accessAction from './action';
import * as utility from './../../utils/utility';
import * as validations from '../../utils/form-validation';
import * as notify from '../../utils/notify';

type Props = {
  history: {},
  context: ContextType,
  organizationContext: OrganizationContextType,
  signupIQUser: () => void,
  resendVerificationEmail: () => void,
  processInviteIQUser: () => void,
};

// Export this for unit testing more easily
export class SignUp extends PureComponent {
  props: Props;
  state = {
    firstName: {
      displayName: 'First Name',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    lastName: {
      displayName: 'Last Name',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    email: {
      displayName: 'Email address',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required', 'email'],
    },
    password: {
      displayName: 'Password',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    confirmPassword: {
      displayName: 'Confirm Password',
      value: '',
      valid: true,
      isRequired: false,
      errorMessage: '',
      validators: [],
    },
    matching: { error: false, message: '' },
    loading: { submit: false, data: false },
    showBack: true,
  };
  static defaultProps: {
    context: {},
    organizationContext: {},
    signupIQUser: () => {},
    resendVerificationEmail: () => {},
    processInviteIQUser: () => {},
  };

  constructor(props) {
    super(props);
    this.handleLink = this.handleLink.bind(this);
    this.handlerTextInput = this.handlerTextInput.bind(this);
    this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
  }

  componentDidMount() {
    const { processInviteIQUser } = this.props;
    if (document.title !== 'Fund Formation Portal - Sign up')
      notify.hideNotification();
    const signupToken = utility.getUrlParameter('continue');
    if (signupToken) {
      processInviteIQUser({ token: signupToken }, (foundInvite) => {
        this.setState({
          ...this.state,
          firstName: { ...this.state.firstName, value: foundInvite.firstName },
          lastName: { ...this.state.lastName, value: foundInvite.lastName },
          email: { ...this.state.email, value: foundInvite.email },
        });
      });
    }
  }
  verifyFormValidity = () => {
    for (let i = 0; i < Object.keys(this.state).length; i += 1) {
      const keyName = Object.keys(this.state)[i];
      /** formValid is not a field * */
      if (
        keyName !== 'matching' &&
        keyName !== 'loading' &&
        keyName !== 'showBack'
      ) {
        const validationPassed = this.runFieldValidations(
          keyName,
          this.state[keyName].value
        );
        if (!validationPassed) {
          return false;
        }
      } else {
        return true;
      }
    }
  };
  runFieldValidations = (fieldName, value) => {
    let validationResult = {};
    for (let i = 0; i < this.state[fieldName].validators.length; i += 1) {
      const validator = this.state[fieldName].validators[i];
      let errorMessageToDisplay = '';
      switch (validator) {
        case 'required':
          validationResult = validations.required(value);
          if (!validationResult) {
            errorMessageToDisplay = `${this.state[fieldName].displayName} is required`;
          }
          break;
        case 'email':
          validationResult = validations.email(value);
          if (!validationResult) {
            errorMessageToDisplay = 'Wrong email format';
          }
          break;
        case 'number':
          validationResult = validations.number(value);
          if (!validationResult) {
            errorMessageToDisplay = `${this.state[fieldName].displayName} must be a number`;
          }
          break;
        default:
          return true;
      }
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          value,
          valid: validationResult,
          errorMessage: errorMessageToDisplay,
        },
        // eslint-disable-next-line consistent-return
      });
      if (!validationResult) {
        return false;
      }
    }
    return true;
  };
  // eslint-disable-next-line
  handleLink(event) {
    if (event) event.preventDefault();
    window.history.back();
  }

  handlerTextInput(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      [event.target.id]: {
        ...this.state[event.target.id],
        value: event.target.value,
      },
    });
  }

  validateConfirmPassword(pass, rePass) {
    if (pass.length < 14) {
      this.setState({
        ...this.state,
        matching: {
          ...this.state.matching,
          error: true,
          message: PASSWORD_LENGTH_MIN_MESSAGE,
        },
      });
      return false;
    }
    if (pass.length > 48) {
      this.setState({
        ...this.state,
        matching: {
          ...this.state.matching,
          error: true,
          message: PASSWORD_LENGTH_MAX_MESSAGE,
        },
      });
      return false;
    }

    if (pass === rePass) {
      this.setState({
        ...this.state,
        matching: { ...this.state.matching, error: false, message: '' },
      });
      return true;
    } else {
      this.setState({
        ...this.state,
        matching: {
          ...this.state.matching,
          error: true,
          message: PASSWORD_NOT_MATCH_MESSAGE,
        },
      });
      return false;
    }
  }
  clearInputs() {
    this.setState({
      ...this.state,
      firstName: { ...this.state.firstName, value: '' },
      lastName: { ...this.state.lastName, value: '' },
      email: { ...this.state.email, value: '' },
      password: { ...this.state.password, value: '' },
      confirmPassword: { ...this.state.confirmPassword, value: '' },
    });
  }

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
      tooltipExtraClass: 'tooltip-margin',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={this.handlerTextInput}
      />
    );
  };

  // eslint-disable-next-line
  handlerFormSubmit(event) {
    event.preventDefault();
    const { signupIQUser, history } = this.props;

    const pass = this.state.password.value;
    const rePass = this.state.confirmPassword.value;

    if (this.verifyFormValidity()) {
      const iqUserPayload = {
        firstName: this.state.firstName.value,
        lastName: this.state.lastName.value,
        email: this.state.email.value,
        password: this.state.password.value,
      };
      if (this.validateConfirmPassword(pass, rePass)) {
        signupIQUser(iqUserPayload, history).then((res) => {

          if (!res.data.success) {
            this.setState({
              ...this.state,
              email: { ...this.state.email,
                errorMessage: 'This account already exists. Please go back to the previous screen to sign in, or use another email to create your account',
                valid: false 
              }
            })
          }
        });
      }
    }
  }

  resendVerification = (event) => {
    event.preventDefault();
    const { resendVerificationEmail, organizationContext } = this.props;
    resendVerificationEmail({ email: organizationContext.orgSignUpUser.email });
  };

  render() {
    const { context, organizationContext } = this.props;
    return (
      <div className="main-container">
        <DynamicHeader
          type={'user'}
          homePath={'/'}
          data={{
            name: context.profile.name,
          }}
        />
        <div className="container">
          <Helmet title="Sign up" />
          {/* <Alert
            inputData={{
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="page-top-row">
                {!utility.getUrlParameter('tab') && (
                  <a
                    href="#/"
                    onClick={this.handleLink}
                    className="back-to color-black"
                  >
                    <i className="fa fa-angle-left" aria-hidden="true" /> Back
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {!organizationContext.orgSignUpConfirm && (
                <form onSubmit={this.handlerFormSubmit}>
                  <div className="content-bg signup-main">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center center-block">
                        <h3 className="color-dark-green">SIGN UP</h3>
                        <p>
                          Create your account for Gunderson Dettmer&#39;s Fund
                          Formation Portal <br />
                          to complete Investor Questionnaires and track the
                          process.
                        </p>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="profile-main-content">
                            {this.renderTextInput(
                              'firstName',
                              'firstName',
                              'text',
                              this.state.firstName.value,
                              'First Name',
                              false,
                              'large',
                              !this.state.firstName.valid,
                              this.state.firstName.errorMessage,
                              ''
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          {this.renderTextInput(
                            'lastName',
                            'lastName',
                            'text',
                            this.state.lastName.value,
                            'Last Name',
                            false,
                            'large',
                            !this.state.lastName.valid,
                            this.state.lastName.errorMessage,
                            ''
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          {this.renderTextInput(
                            'email',
                            'email',
                            'text',
                            this.state.email.value,
                            'Email Address',
                            false,
                            'large',
                            !this.state.email.valid,
                            this.state.email.errorMessage,
                            ''
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="profile-main-content">
                            {this.renderTextInput(
                              'password',
                              'password',
                              'password',
                              this.state.password.value,
                              'New Password',
                              false,
                              'large',
                              !this.state.password.valid,
                              this.state.password.errorMessage,
                              'Your password must be 14 characters long.'
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="profile-main-content">
                            {this.renderTextInput(
                              'confirmPassword',
                              'confirmPassword',
                              'password',
                              this.state.confirmPassword.value,
                              'Confirm Password',
                              false,
                              'large',
                              !this.state.confirmPassword.valid,
                              this.state.confirmPassword.errorMessage,
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
                          {this.state.matching.error ? (
                            <div className="error-message not-match-pass red-color">
                              <span className="text">
                                {this.state.matching.message}
                              </span>
                            </div>
                          ) : (
                            <div className="error-message not-match-pass green-color">
                              <span className="text">
                                {this.state.matching.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <br />
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
                          <Button
                            inputData={{
                              id: 'admin-user-submit',
                              displayName: 'Submit',
                              type: 'submit',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {organizationContext.orgSignUpUser &&
                organizationContext.orgSignUpConfirm && (
                  <div className="content-bg signup-main">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center center-block">
                      <h3 className="color-dark-green">SIGN UP</h3>
                      {/* eslint-disable react/no-unescaped-entities */}
                      <p>
                        Thank you for joining Gunderson Dettmer's Fund Formation
                        Portal. Please use
                        <br />
                        the link sent to your email,{' '}
                        {organizationContext.orgSignUpUser.email || 'unknown'},
                        to verify your account.
                      </p>
                      <p>
                        {'Did not receive a verification email? '}
                        <a href="#/" onClick={this.resendVerification}>
                          Resend
                        </a>
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  organizationContext: state.organizationContext,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    signupIQUser: (payload, history) =>
      dispatch(accessAction.signupIQUser(payload, history)),
    resendVerificationEmail: (payload) =>
      dispatch(accessAction.resendVerificationEmail(payload)),
    processInviteIQUser: (payload, cb) =>
      dispatch(accessAction.processInviteIQUser(payload, cb)),
  })
);
export default connector(SignUp);
