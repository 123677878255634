import React from 'react';

type Props = { id: '', text: Object, icon: '', hasIcon: boolean, isLink: boolean, actionHandler: void, bold: boolean, underline: boolean };

const defaultNonLink = (event) => {
  event.preventDefault();
};

const SubTitle = ({ id, text, icon, hasIcon, isLink, actionHandler, bold, underline }: Props) => (
  <a href="#/" onClick={isLink ? actionHandler : defaultNonLink}>
    {hasIcon ?
      <div>
        {bold ? <h5 className="color-black highlight-drop-down-title-float"><b>{text}</b></h5> : <h5 className="color-black highlight-drop-down-title-float">{text}</h5>}
        <span id={id} className={`color-black highlight-drop-down glyphicon glyphicon-${icon}`} />
      </div> : <h5 className="color-black">{underline ? <u>{text}</u> : text}</h5>}
  </a>
);

SubTitle.defaultProps = {
  id: '',
  text: '',
  icon: '',
  hasIcon: false,
  isLink: true, // default behavior
  actionHandler: () => { },
  bold: false,
  underline: false,
};

export default SubTitle;

