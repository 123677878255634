/* eslint-disable array-callback-return */

const iqID = require('../utils/constants/questionnaire/identifiers');
const utilQuestions = require('../utils/constants/questionnaire/foresite/v1/iq-questions.foresite.v1');

const parseYesId = (id) => `${id}_yes`;
// const parseNoId = id => `${id}_no`;

module.exports = {
  getInvestorFundQuestions: (questionsArray, isShortIQ) => {
    const questions = {};
    // questions.lpa = _.find(questionsArray, item => item.descriptionIdentifier === '');
    questions.taxExemptPartner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_8
    )[0];
    questions.naturalPerson = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_1
    )[0];
    questions.finraAffiliation = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_4
    )[0];
    questions.foia_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_a
    )[0];
    questions.foia_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_b
    )[0];
    questions.foia_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_c
    )[0];
    questions.foia_d = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_d
    )[0];
    questions.foia_e = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_e
    )[0];
    questions.foia_f = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_5_f
    )[0];
    questions.badActor = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_6
    )[0];
    // HCM question has several options so right now the logic will be contained here to find it
    // by building the question as an array [mainQuestion,<triggerQuestion>]
    questions.hcm = [];
    const foundHCMQuestions = questionsArray.filter((q) =>
      q.descriptionIdentifier.includes('investor_status_7_hcm_')
    );
    // there should only be one main question so grab first position
    const foundHCMMainQuestions = foundHCMQuestions.filter(
      (f) => !f.descriptionIdentifier.includes('_trigger')
    );
    const mainQuestion = foundHCMMainQuestions[0];
    questions.hcm.push(mainQuestion);
    // still not deleting the trigger so look based on the main question
    const foundHCMTriggerQuestions = foundHCMQuestions.filter((f) =>
      f.descriptionIdentifier.includes('_trigger')
    );
    let triggerQuestion = null;
    if (foundHCMTriggerQuestions && foundHCMTriggerQuestions.length) {
      const matchingTriggers = foundHCMTriggerQuestions.filter(
        (f) =>
          f.descriptionIdentifier ===
          `${mainQuestion.descriptionIdentifier}_trigger`
      );
      if (matchingTriggers && matchingTriggers.length) {
        triggerQuestion = matchingTriggers[0];
        questions.hcm.push(triggerQuestion);
      }
    }

    //end hcm question logic

    questions.institutionalAccountStatus = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7
    )[0];
    questions.investorStatus7HCM = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm
    )[0];

    questions.investorStatus7HCMa = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_a
    )[0];
    questions.investorStatus7HCMb = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_b
    )[0];
    questions.investorStatus7HCMc = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_c
    )[0];
    questions.investorStatus7HCMd = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_d
    )[0];
    questions.investorStatus7HCMe = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_e
    )[0];
    questions.investorStatus7HCMf = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_f
    )[0];
    questions.investorStatus7HCMg = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_g
    )[0];
    questions.investorStatus7HCMh = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_h
    )[0];
    questions.investorStatus7HCMi = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_i
    )[0];
    questions.investorStatus7HCMj = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_j
    )[0];
    questions.investorStatus7HCMk = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_k
    )[0];
    questions.investorStatus7HCMl = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_l
    )[0];
    questions.investorStatus7HCMm = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_m
    )[0];
    questions.investorStatus7HCMmTrigger = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.id_investor_status_7_hcm_m_trigger
    )[0];
    questions.investorStatus7HCMn = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_hcm_n
    )[0];
    questions.investorStatus7HCMother = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.id_investor_status_7_hcm_other
    )[0];
    questions.investorStatus7HCMotherTrigger = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier ===
        iqID.id_investor_status_7_hcm_other_trigger
    )[0];

    questions.investorStatus7TCGa = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_a
    )[0];
    questions.investorStatus7TCGb = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_b
    )[0];
    questions.investorStatus7TCGc = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_c
    )[0];
    questions.investorStatus7TCGd = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_d
    )[0];
    questions.investorStatus7TCGe = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_e
    )[0];
    questions.investorStatus7TCGf = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_f
    )[0];
    questions.investorStatus7TCGg = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_g
    )[0];
    questions.investorStatus7TCGh = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_h
    )[0];
    questions.investorStatus7TCGi = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_i
    )[0];
    questions.investorStatus7TCGj = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_j
    )[0];
    questions.investorStatus7TCGk = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_k
    )[0];
    questions.investorStatus7TCGl = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_l
    )[0];
    questions.investorStatus7TCGm = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_m
    )[0];
    questions.investorStatus7TCGn = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_n
    )[0];
    questions.investorStatus7TCGo = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_7_tcg_o
    )[0];

    questions.srOnePartA1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_1
    )[0];
    questions.srOnePartA2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_2
    )[0];
    questions.srOnePartA3 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_3
    )[0];
    questions.srOnePartA3a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_3_a
    )[0];
    questions.srOnePartA3b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_3_b
    )[0];
    questions.srOnePartA3bOpt = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_3_b_opt
    )[0];
    questions.srOnePartA3c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_3_c
    )[0];
    questions.srOnePartAor = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_a_or
    )[0];

    questions.srOnePartB1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_1
    )[0];
    questions.srOnePartB1a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_1_a
    )[0];
    questions.srOnePartB1b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_1_b
    )[0];
    questions.srOnePartB1c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_1_c
    )[0];
    questions.srOnePartB1TextNote = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_1_text_note
    )[0];
    questions.srOnePartB2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_2
    )[0];
    questions.srOnePartB3 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_3
    )[0];
    questions.srOnePartB3a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_3_a
    )[0];
    questions.srOnePartB3b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_3_b
    )[0];
    questions.srOnePartB3bOpt = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_3_b_opt
    )[0];
    questions.srOnePartB3c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_3_c
    )[0];
    questions.srOnePartBor = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_or
    )[0];
    questions.srOnePartB4CompanyName1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_name_of_company_1
    )[0];
    questions.srOnePartB4CompanyName2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_name_of_company_2
    )[0];
    questions.srOnePartB4CompanyName3 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_name_of_company_3
    )[0];
    questions.srOnePartB4Beneficial1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_beneficial_interest_1
    )[0];
    questions.srOnePartB4Beneficial2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_beneficial_interest_2
    )[0];
    questions.srOnePartB4Beneficial3 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_sr_one_part_b_4_beneficial_interest_3
    )[0];

    const exhibitCAIds = [
      iqID.id_sr_one_a_exhibit_c_1,
      iqID.id_sr_one_a_exhibit_c_2,
      iqID.id_sr_one_a_exhibit_c_3,
      iqID.id_sr_one_a_exhibit_c_4,
      iqID.id_sr_one_a_exhibit_c_5,
      iqID.id_sr_one_a_exhibit_c_6,
      iqID.id_sr_one_a_exhibit_c_7,
      iqID.id_sr_one_a_exhibit_c_8,
      iqID.id_sr_one_a_exhibit_c_9,
      iqID.id_sr_one_a_exhibit_c_10,
     iqID. id_sr_one_a_exhibit_c_11,
    ]

    questions.exhibitCA = questionsArray.filter(
      (item) => exhibitCAIds.includes(item.descriptionIdentifier) && item.answer === 'Yes'
    )[0];

      const exhibitCBIds = [
      iqID.id_sr_one_b_exhibit_c_1,
      iqID.id_sr_one_b_exhibit_c_2,
      iqID.id_sr_one_b_exhibit_c_3,
      iqID.id_sr_one_b_exhibit_c_4,
      iqID.id_sr_one_b_exhibit_c_5,
      iqID.id_sr_one_b_exhibit_c_6,
      iqID.id_sr_one_b_exhibit_c_7,
      iqID.id_sr_one_b_exhibit_c_8,
      iqID.id_sr_one_b_exhibit_c_9,
      iqID.id_sr_one_b_exhibit_c_10,
     iqID. id_sr_one_b_exhibit_c_11,
    ]

    questions.exhibitCB = questionsArray.filter(
      (item) => exhibitCBIds.includes(item.descriptionIdentifier) && item.answer === 'Yes'
    )[0];

    questions.coveredInvestorStatusa = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_covered_investor_status_a
    )[0];

    questions.coveredInvestorStatusb = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_covered_investor_status_b
    )[0];

    questions.coveredInvestorStatusor = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_covered_investor_status_or
    )[0]

    questionsArray.map((item) => {
      if (
        item.descriptionIdentifier === iqID.id_covered_investor_status_a ||
        item.descriptionIdentifier === iqID.id_covered_investor_status_b ||
        item.descriptionIdentifier === iqID.id_covered_investor_status_or 
      ) {
        if (item.answer === 'Yes') {
              questions.coveredInvestorStatus = item;
        }
      }
    });

    questions.restrictedPersonStatusa = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_a
    )[0];
    questions.restrictedPersonStatusb = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_b
    )[0];
    questions.restrictedPersonStatusc = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_c
    )[0];
    questions.restrictedPersonStatusd = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_d
    )[0];
    questions.restrictedPersonStatuse = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_e
    )[0];
    questions.restrictedPersonStatusf = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_f
    )[0];
    questions.restrictedPersonStatusg = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_g
    )[0];
    questions.restrictedPersonStatush = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_h
    )[0];
    questions.restrictedPersonStatusi = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_i
    )[0];
    questions.restrictedPersonStatusj = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_j
    )[0];
    questions.restrictedPersonStatusk = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_k
    )[0];
    questions.restrictedPersonStatusor = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_restricted_person_status_or
    )[0];

    questions.exemptedEntityStatusa = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_a
    )[0];
    questions.exemptedEntityStatusb = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_b
    )[0];
    questions.exemptedEntityStatusc = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_c
    )[0];
    questions.exemptedEntityStatusd = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_d
    )[0];
    questions.exemptedEntityStatuse = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_e
    )[0];
    questions.exemptedEntityStatusf = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_f
    )[0];
    questions.exemptedEntityStatusg = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_g
    )[0];
    questions.exemptedEntityStatush = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_h
    )[0];
    questions.exemptedEntityStatusi = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_exempted_entity_status_i
    )[0];
  


    questions.privateFoundationPartner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_5
    )[0];
    questions.publicPensionPartner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_6
    )[0];
    questions.fundOfFunds = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_7
    )[0];
    questions.customerOfBank = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_wiring_bank_status_2
    )[0];
    questions.wiringBankFATF = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_wiring_bank_status_1
    )[0];
    questions.bhcaPartner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_4
    )[0];
    questions.erisa_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_1_a
    )[0];
    questions.erisa_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_1_b
    )[0];
    questions.erisaPercentage = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_1_b_opt
    )[0];
    questions.usPerson = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_investor_status_2
    )[0];

    
    // if the IQ version that is being targeted is the SHORT IQ type, the the identifiers are swaped, same as what happens in the questionnaire
    if (isShortIQ){
      questions.usPersonIRC = questionsArray.filter(
        (item) => item.descriptionIdentifier === iqID.id_investor_status_2
      )[0];
    }else {
      questions.usPersonIRC = questionsArray.filter(
        (item) => item.descriptionIdentifier === iqID.id_investor_status_3
      )[0];
    
    }
      

    questions.look_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_2_a
    )[0];
    questions.look_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_2_b
    )[0];
    questions.look_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_2_c
    )[0];
    questions.look_d = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_2_d
    )[0];
    questions.look_e = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_2_e_opt
    )[0];
    questions.governmentEntity = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_3_a
    )[0];
    questions.wiringBankCountry = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_wiring_bank_status_1_opt
    )[0];
    questions.taxedPartnership = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_9_a
    )[0];
    questions.usBeneficial = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_9_b
    )[0];
    questions.beneficialOwner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_9_c
    )[0];

    questions.accreditedInvestorRep1 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_1)
    )[0];
    questions.accreditedInvestorRep2 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_2)
    )[0];
    questions.accreditedInvestorRep3 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_3)
    )[0];
    questions.accreditedInvestorRep4 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_4)
    )[0];
    questions.accreditedInvestorRep5 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_5)
    )[0];
    questions.accreditedInvestorRep6 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_6)
    )[0];
    questions.accreditedInvestorRep6Extra = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_investor_representations_6_extra)
    )[0];
    questions.accreditedInvestorRep7 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_7)
    )[0];
    questions.accreditedInvestorRep8 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_8)
    )[0];
    questions.accreditedInvestorRep9 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_investor_representations_9)
    )[0];
    questions.accreditedInvestorRep10 = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_investor_representations_10)
    )[0];
    questions.accreditedInvestorRep11 = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_investor_representations_11)
    )[0];
    questions.accreditedInvestorRep12 = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_investor_representations_12)
    )[0];
    questions.accreditedInvestorOr = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_investor_representations_or)
    )[0];

    questions.qualifiedPurchaserRep1 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_1)
    )[0];
    questions.qualifiedPurchaserRep2 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_2)
    )[0];
    questions.qualifiedPurchaserRep3 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_3)
    )[0];
    questions.qualifiedPurchaserRep4 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_4)
    )[0];
    questions.qualifiedPurchaserRep5 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_5)
    )[0];
    questions.qualifiedPurchaserRep6 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_6)
    )[0];
    questions.qualifiedPurchaserRep7 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_purchase_representations_7)
    )[0];
    questions.qualifiedPurchaserOr = questionsArray.filter(
      (item) =>
        item.answerIdentifier ===
        parseYesId(iqID.id_purchase_representations_or)
    )[0];

    questions.qualifiedClientRep1 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_client_representations_1)
    )[0];
    questions.qualifiedClientRep2 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_client_representations_2)
    )[0];
    questions.qualifiedClientRep2_a = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_client_representations_2_a)
    )[0];
    questions.qualifiedClientRep2_b = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_client_representations_2_b)
    )[0];
    questions.qualifiedClientOr = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_client_representations_or)
    )[0];

    questions.committeeOnForeignInvestment1 = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_1)
    )[0];
    questions.committeeOnForeignInvestment2 = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_2)
    )[0];
    questions.committeeOnForeignInvestment3 = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_3)
    )[0];
    questions.committeeOnForeignInvestment4 = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_4)
    )[0];
    questions.committeeOnForeignInvestmentOr = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_or)
    )[0];

    questions.committeeOnForeignInvestment2_1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_foreign_person_2_1
    )[0];

    questions.committeeOnForeignInvestment2_2 = questionsArray.filter(
      (item) => item.answerIdentifier === parseYesId(iqID.id_foreign_person_2_2)
    )[0];
    questions.committeeOnForeignInvestmentOr2 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_foreign_person_2_or)
    )[0];

    questions.specialEntities3a = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_special_entities_3_a)
    )[0];
    questions.specialEntities3b = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_special_entities_3_b)
    )[0];
    questions.specialEntities3c = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_special_entities_3_c)
    )[0];
    questions.specialEntities3d = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_special_entities_3_d)
    )[0];
    questions.exceptions = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_warranties_exceptions
    )[0];

    questions.additionContactInfo = questionsArray.filter(
      (item) => item.descriptionIdentifier === parseYesId(iqID.id_additional_contact_info)
    )[0];

    questions.foresite_investor_type_1 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_foresite_investor_type_1)
    )[0];
    questions.foresite_investor_type_2 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_foresite_investor_type_2)
    )[0];
    questions.foresite_investor_type_3 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_foresite_investor_type_3)
    )[0];
    questions.foresite_investor_type_4 = questionsArray.filter(
      (item) =>
        item.answerIdentifier === parseYesId(iqID.id_foresite_investor_type_4)
    )[0];

    questionsArray.map((item) => {
      if (
        item.descriptionIdentifier === iqID.id_foresite_investor_type_1 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_2 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_2 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_3 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_4 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_5 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_6 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_7 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_8 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_10 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_11 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_12 ||
        item.descriptionIdentifier === iqID.id_foresite_investor_type_13
      ) {
        if (item.answer === 'Yes') {
          utilQuestions.questions.foresiteAdditionalQuestions.map((q) => {
            if (q.id === item.descriptionIdentifier) {
              questions.foresite_investor_type = q;
            }
          });
        }
      }
    });

    questions.foresiteFinraAffiliation = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_foresiteFinraAffiliation
    )[0];
    questions.foresiteFinraAffiliationFurtherDetails = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier ===
        iqID.id_foresiteFinraAffiliationFurtherDetails
    )[0];
    questions.foresiteFinraAffiliationFurtherDetails = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier ===
        iqID.id_foresiteFinraAffiliationFurtherDetails
    )[0];
    questions.foresitePublicCompanyAffiliation = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.id_foresitePublicCompanyAffiliation
    )[0];
    questions.foresitePublicCompanyAffiliation_opt = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier ===
        iqID.id_foresitePublicCompanyAffiliation_opt
    )[0];
    questions.foresiteEntities = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_foresiteEntities
    )[0];
    questions.foresiteEntities_opt = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_foresiteEntities_opt
    )[0];

    questions.rule5130_finraNewIssues_1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.rule5130_finraNewIssues_1
    )[0];
    questions.rule5130_finraNewIssues_1_opt_1 = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.rule5130_finraNewIssues_1_opt_1
    )[0];
    questions.rule5130_finraNewIssues_1_opt_2 = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.rule5130_finraNewIssues_1_opt_2
    )[0];
    questions.rule5130_finraNewIssues_1_opt_3 = questionsArray.filter(
      (item) =>
        item.descriptionIdentifier === iqID.rule5130_finraNewIssues_1_opt_3
    )[0];
    questions.rule5130_finraNewIssues_2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.rule5130_finraNewIssues_2
    )[0];

    questions.rule5131_finraNewIssues_1 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.rule5131_finraNewIssues_1
    )[0];
    questions.rule5131_finraNewIssues_2 = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.rule5131_finraNewIssues_2
    )[0];

     questions.id_girw_7_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_a
    )[0];
    questions.id_girw_7_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_b
    )[0];
    questions.id_girw_7_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_c
    )[0];
    questions.id_girw_7_d = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_d
    )[0];
    questions.id_girw_7_e = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_e
    )[0];
    questions.id_girw_7_f = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_f
    )[0];
    questions.id_girw_7_g = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_g
    )[0];
    questions.id_girw_7_h = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_h
    )[0];
    questions.id_girw_7_i = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_i
    )[0];

    questions.id_girw_7_2_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_a
    )[0];
    questions.id_girw_7_2_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_b
    )[0];
    questions.id_girw_7_2_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_c
    )[0];
    questions.id_girw_7_2_d = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_d
    )[0];
    questions.id_girw_7_2_e = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_e
    )[0];
    questions.id_girw_7_2_f = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_7_2_f
    )[0];


    questions.id_griw7_2CompanyBasis_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_griw7_2CompanyBasis_a
    )[0];

    questions.id_griw7_2CompanyBasis_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_griw7_2CompanyBasis_b
    )[0];

    questions.id_griw7_2CompanyBasis_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_griw7_2CompanyBasis_c
    )[0];

    questions.id_girw_8_1_a = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_a
    )[0];
    questions.id_girw_8_1_b = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_b
    )[0];
    questions.id_girw_8_1_c = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_c
    )[0];
    questions.id_girw_8_1_d = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_d
    )[0];
    questions.id_girw_8_1_e = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_e
    )[0];
    questions.id_girw_8_1_f = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_f
    )[0];
    questions.id_girw_8_1_g = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_g
    )[0];
    questions.id_girw_8_1_h = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_h
    )[0];
    questions.id_girw_8_1_i = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_i
    )[0];
    questions.id_girw_8_1_j = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_j
    )[0];
    questions.id_girw_8_1_k = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_k
    )[0];
    questions.id_girw_8_1_l = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_girw_8_1_l
    )[0];

    questions.foreignPartner = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_special_entities_10
    )[0];

    questions.retirementPlan = questionsArray.filter(
      (item) => item.descriptionIdentifier === iqID.id_retirementPlan
    )[0];

    return questions;
  },
};
