import { SERVICE_CONFIG_QUESTIONNAIRE } from 'utils/service-commons';
import type { Dispatch, GetState, ThunkAction } from '../../types';

const API_URL = '/api';
// eslint-disable-next-line
export const sendSubmitConfirmation = (
  investorFund,
  signatoryInfo,
  callback,
  fund,
  at, //needed ?
  hasInvestorFund
): ThunkAction =>
  // eslint-disable-next-line arrow-body-style
  (dispatch: Dispatch, getState: GetState, axios: any) => {
    const confirmationPayload = { signatoryInfo, fundName: fund.fundLegalName };
    if (hasInvestorFund) {
      confirmationPayload.investorFund = investorFund;
    } else {
      confirmationPayload.limitedPartnerFund = investorFund;
    }
    return axios
      .post(
        `${API_URL}/sendSubmitConfirmation`,
        confirmationPayload,
        SERVICE_CONFIG_QUESTIONNAIRE()
      )
      .then((res) => {
        if (res.data.success) {
          callback();
        }
      });
  };
