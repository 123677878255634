/* eslint-disable
 react/sort-comp,react/prop-types,jsx-a11y/anchor-has-content,consistent-return, jsx-a11y/anchor-is-valid */
/* @flow */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
import TextInput from './../../components/TextInput';
import Dropdown from './../../components/Dropdown';
import * as action from './action';
import TextArea from './../../components/TextArea/index.uncontrolled';
import FormLayout from './../../components/FormLayout';
import {
  OrganizationContext as OrganizationContextType,
  FundLimitedPartners as FundLimitedPartnersType,
  Dispatch,
  Reducer,
} from '../../types';
import TableContainer from '../../components/TableContainer/index';
import * as validations from '../../utils/form-validation';
import * as utility from '../../utils/utility';
import {
  CANADA,
  caStatesAbbr,
  countries,
  USA,
  usStatesAbbr,
} from 'utils/location.util';

import {
  naturalPersonDisabledList,
  entityDisabledList,
} from './../../utils/constants/questionnaire/disabledQuestions';

import MultiSelect from 'components/MultiSelect';
import Paragraph from 'components/Paragraph';

import { questions } from 'utils/constants/questionnaire/limitedPartner/v4/iq-questions.lp.v4';
import ConfirmCheckBox from 'components/ConfirmCheckbox';
// import ConfirmCheckBoxWithRef from 'components/ConfirmCheckBoxWithRef';

const iqID = require('./../../utils/constants/questionnaire/identifiers');

const {
  getInitialData,
  orderByPage,
  getPages,
  paginationMAX,
} = require('../../utils/pagination');
const entities = [
  {
    id: 'org-type-radio-1',
    displayName: 'Corporation',
    isSelected: false,
    name: '',
    identifier: 'Corporation',
  },
  {
    id: 'org-type-radio-2',
    displayName: 'Estate',
    isSelected: false,
    name: '',
    identifier: 'Estate',
  },
  {
    id: 'org-type-radio-3',
    displayName: 'US Tax-Exempt Organization',
    isSelected: false,
    name: '',
    identifier: 'US Tax-Exempt Organization',
  },
  {
    id: 'org-type-radio-4',
    displayName: 'Fiduciary',
    isSelected: false,
    name: '',
    identifier: 'Fiduciary',
  },
  {
    id: 'org-type-radio-5',
    displayName: 'Grantor Trust',
    isSelected: false,
    name: '',
    identifier: 'Grantor Trust',
  },
  {
    id: 'org-type-radio-6',
    displayName: 'Natural Person',
    isSelected: false,
    name: '',
    identifier: 'Natural Person',
  },
  {
    id: 'org-type-radio-7',
    displayName: 'Limited Liability Company',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Company',
  },
  {
    id: 'org-type-radio-8',
    displayName: 'Limited Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Partnership',
  },
  {
    id: 'org-type-radio-9',
    displayName: 'Limited Liability Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Partnership',
  },
  {
    id: 'org-type-radio-10',
    displayName: 'Nominee',
    isSelected: false,
    name: '',
    identifier: 'Nominee',
  },
  {
    id: 'org-type-radio-11',
    displayName: 'General Partnership',
    isSelected: false,
    name: '',
    identifier: 'General Partnership',
  },
  {
    id: 'org-type-radio-12',
    displayName: 'Individual Retirement Account',
    isSelected: false,
    name: '',
    identifier: 'IRA',
  },
  {
    id: 'org-type-radio-13',
    displayName: 'S-Corporation',
    isSelected: false,
    name: '',
    identifier: 'S-Corporation',
  },
  {
    id: 'org-type-radio-14',
    displayName: 'Revocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Revocable Trust',
  },
  {
    id: 'org-type-radio-15',
    displayName: 'Irrevocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Irrevocable Trust',
  },
  {
    id: 'org-type-radio-16',
    displayName: 'Business Trust',
    isSelected: false,
    name: '',
    identifier: 'Business Trust',
  },
  {
    id: 'org-type-radio-17',
    displayName: 'Joint Tenants with Right of Survivorship',
    isSelected: false,
    name: '',
    identifier: 'Joint Tenants with Right of Survivorship',
  },
  {
    id: 'org-type-radio-18',
    displayName: 'Tenants in Common',
    isSelected: false,
    name: '',
    identifier: 'Tenants in Common',
  },
  {
    id: 'org-type-radio-other',
    displayName: 'Other',
    isSelected: false,
    name: '',
    identifier: 'Other',
  },
];
type Props = {
  history: {},
  organizationContext: OrganizationContextType,
  fundLimitedPartners: FundLimitedPartnersType,
  saveLimitedPartner: () => void,
  editLimitedPartner: () => void,
  setFormField: () => void,
  resetForms: () => void,
};
const FundLimitedPartners = (props: Props) => {
  const [isEntity, setIsEntity] = useState(false);
  const [isEditingLP, setIsEditingLP] = useState(false);
  const [targetLPEdit, setTargetLPEdit] = useState(null);
  const [sortByCol, setSortByCol] = useState('-1');
  const [sortDirection, setSortDirection] = useState('desc');
  const [invsPage, setInvsPage] = useState(1);
  const [invsMaxItems, setInvsMaxItems] = useState(paginationMAX);
  const [invsLength, setInvsLength] = useState(0);
  const [invsPages, setInvsPages] = useState(null);
  const [addInvestor, setAddInvestor] = useState(false);
  const [addAnotherContactPerson, setAddAnotherContactPerson] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [requiredQuestionCount, setRequiredQuestionCount] = useState(0);
  const [completedQuestionCount, setCompletedQuestionCount] = useState(0);
  // not very good var name , but clearer that nested if in return statement, use with useEffect
  const [showInvestorStatus5F, setShowInvestorStatus5F] = useState(false);
  const [showWiringBankOptional, setShowWiringBankOptional] = useState(false);
  const [showFlowthroughOptional, setShowFlowthroughOptional] = useState(false);
  const [showErisaOptional, setShowErisaOptional] = useState(false);
  const [showClientRepresentations, setShowClientRepresentations] =
    useState(false);
  const [
    showPotentialLookthroughOptional,
    setShowPotentialLookthroughOptional,
  ] = useState(false);
  const NO_ENTITY_SELECTED = 6; // no entity selected
  const IS_ENTITY = 29; // corp, not counting location fields 1 \ 2
  const IS_NOT_ENTITY = 15; // natural person ,not counting location fields 2 \ 3

  const yesIsNaturalRef = useRef(null);
  const noIsNaturalRef = useRef(null);

  useEffect(() => {
    if (utility.getUrlParameter('add')) {
      handleAddLPv2();
    }
  }, []);

  useEffect(() => {
    // need to update question progress when there are changes to the main form
    checkQuestionProgress();
  }, [
    props.fundLimitedPartners.form,
    addInvestor,
    showWiringBankOptional,
    showInvestorStatus5F,
    showFlowthroughOptional,
    showErisaOptional,
    showPotentialLookthroughOptional,
    props.fundLimitedPartners.form.entityType.value,
    props.fundLimitedPartners.form.countryPlace.value,
    props.fundLimitedPartners.form.principalPlaceOfResidencePrimaryContact
      .value,
    props.fundLimitedPartners.form.principalPlaceOfBusinessPrimaryContact.value,
  ]);

  // combined required questionCounts
  useEffect(() => {
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    let targetRequiredCount = 0;
    if (addInvestor) {
      if (currentTypeValue !== '') {
        let ADDRESS_FIELDS = 0;
        const country = props.fundLimitedPartners.form.countryPlace.value;
        if (getIsEntity(currentTypeValue)) {
          if (country === CANADA || country === USA) {
            ADDRESS_FIELDS = 2;
          } else {
            ADDRESS_FIELDS = 1;
          }
          targetRequiredCount =
            targetRequiredCount +
            NO_ENTITY_SELECTED +
            // IS_ENTITY +
            ADDRESS_FIELDS;
        } else if (!getIsEntity(currentTypeValue)) {
          // natural person
          if (country === CANADA || country === USA) {
            ADDRESS_FIELDS = 3;
          } else {
            ADDRESS_FIELDS = 2;
          }
          targetRequiredCount =
            targetRequiredCount +
            NO_ENTITY_SELECTED +
            // IS_NOT_ENTITY +
            ADDRESS_FIELDS;
        }
        // if other add one more for the text input
        if (currentTypeValue === 'Other') {
          targetRequiredCount = targetRequiredCount + 1;
        }
      } else {
        //not selected yet
        targetRequiredCount = targetRequiredCount + NO_ENTITY_SELECTED;
      }
      setRequiredQuestionCount(targetRequiredCount);
    }
  }, [
    addInvestor,
    showWiringBankOptional,
    showInvestorStatus5F,
    showFlowthroughOptional,
    showErisaOptional,
    showPotentialLookthroughOptional,
    showClientRepresentations,
    props.fundLimitedPartners.form.entityType.value,
    props.fundLimitedPartners.form.countryPlace.value,
    props.fundLimitedPartners.form.principalPlaceOfResidencePrimaryContact
      .value,
    props.fundLimitedPartners.form.principalPlaceOfBusinessPrimaryContact.value,
  ]);

  // wiring bank
  useEffect(() => {
    // check value
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    const selectionValues =
      props.fundLimitedPartners.form.id_wiring_bank_status_1.isSelected;
    if (
      selectionValues[0] === false &&
      selectionValues[1] === true &&
      hasTypeAssigned
    ) {
      // means "No" is selected
      setShowWiringBankOptional(true);
    } else {
      setShowWiringBankOptional(false);
    }
  }, [props.fundLimitedPartners.form.id_wiring_bank_status_1]);
  // client Representations
  useEffect(() => {
    // check value
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    const orQuestionSelected =
      props.fundLimitedPartners.form.id_purchase_representations_or.isSelected;
    if (orQuestionSelected && hasTypeAssigned) {
      setShowClientRepresentations(true);
    } else {
      const { setFormField } = props;
      setShowClientRepresentations(false);
      setFormField('id_client_representations_1', 'isSelected', false);
      setFormField('id_client_representations_2', 'isSelected', false);
      setFormField('id_client_representations_or', 'isSelected', false);
    }
  }, [props.fundLimitedPartners.form.id_purchase_representations_or]);
  //erisa partner
  useEffect(() => {
    // check value
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    const selectionValues =
      props.fundLimitedPartners.form.id_special_entities_1_b.isSelected;
    if (addInvestor) {
      if (
        selectionValues[0] === true &&
        selectionValues[1] === false &&
        hasTypeAssigned
      ) {
        // means "Yes" is selected
        setShowErisaOptional(true);
      } else {
        setShowErisaOptional(false);
      }
    }
  }, [props.fundLimitedPartners.form.id_special_entities_1_b, addInvestor]);

  //potential lookthrough
  useEffect(() => {
    // if any of 5a to 5e is selected then show 5f
    const {
      id_special_entities_2_a,
      id_special_entities_2_b,
      id_special_entities_2_c,
      id_special_entities_2_d,
    } = props.fundLimitedPartners.form;

    const questionSectionList = [
      id_special_entities_2_a,
      id_special_entities_2_b,
      id_special_entities_2_c,
      id_special_entities_2_d,
    ];
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    if (hasTypeAssigned && addInvestor) {
      if (
        questionSectionList.some(
          (q) => q.isSelected[0] === true && q.isSelected[1] === false
        )
      ) {
        setShowPotentialLookthroughOptional(true);
      } else {
        setShowPotentialLookthroughOptional(false);
      }
    }
  }, [
    props.fundLimitedPartners.form.id_special_entities_2_a,
    props.fundLimitedPartners.form.id_special_entities_2_b,
    props.fundLimitedPartners.form.id_special_entities_2_c,
    props.fundLimitedPartners.form.id_special_entities_2_d,
    addInvestor,
  ]);

  // flowThrough
  useEffect(() => {
    // check value
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    const selectionValues =
      props.fundLimitedPartners.form.id_special_entities_9_a.isSelected;
    if (addInvestor) {
      if (
        selectionValues[0] === true &&
        selectionValues[1] === false &&
        hasTypeAssigned
      ) {
        // means "Yes" is selected
        setShowFlowthroughOptional(true);
      } else {
        setShowFlowthroughOptional(false);
      }
    }
  }, [props.fundLimitedPartners.form.id_special_entities_9_a, addInvestor]);
  useEffect(() => {
    // if any of 5a to 5e is selected then show 5f
    const {
      id_investor_status_5_a,
      id_investor_status_5_b,
      id_investor_status_5_c,
      id_investor_status_5_d,
      id_investor_status_5_e,
    } = props.fundLimitedPartners.form;

    const questionSectionList = [
      id_investor_status_5_a,
      id_investor_status_5_b,
      id_investor_status_5_c,
      id_investor_status_5_d,
      id_investor_status_5_e,
    ];
    const currentTypeValue = props.fundLimitedPartners.form.entityType.value;
    const hasTypeAssigned = currentTypeValue !== '';
    if (hasTypeAssigned && addInvestor) {
      if (
        questionSectionList.some(
          (q) => q.isSelected[0] === true && q.isSelected[1] === false
        )
      ) {
        setShowInvestorStatus5F(true);
      } else {
        setShowInvestorStatus5F(false);
      }
    }
  }, [
    props.fundLimitedPartners.form.id_investor_status_5_a,
    props.fundLimitedPartners.form.id_investor_status_5_b,
    props.fundLimitedPartners.form.id_investor_status_5_c,
    props.fundLimitedPartners.form.id_investor_status_5_d,
    props.fundLimitedPartners.form.id_investor_status_5_e,
    addInvestor,
  ]);

  const verifyFormValidity = () => {
    const { fundLimitedPartners, setFormField } = props;
    for (let i = 0; i < Object.keys(fundLimitedPartners.form).length; i += 1) {
      const keyName = Object.keys(fundLimitedPartners.form)[i];
      /** formValid is not a field * */
      if (keyName !== 'loading') {
        const validationPassed = runFieldValidations(
          keyName,
          fundLimitedPartners.form[keyName].value
        );
        if (!validationPassed) {
          console.log(`not passed  ${keyName}`);
          return false;
        }
        setFormField(keyName, 'valid', true);
        setFormField(keyName, 'errorMessage', '');
      }
    }
    return true;
  };
  const runFieldValidations = (fieldName, value) => {
    const { fundLimitedPartners, setFormField } = props;
    let validationResult = {};
    if (fundLimitedPartners.form[fieldName].validators) {
      for (
        let i = 0;
        i < fundLimitedPartners.form[fieldName].validators.length;
        i += 1
      ) {
        const validator = fundLimitedPartners.form[fieldName].validators[i];
        let errorMessageToDisplay = '';
        switch (validator) {
          case 'required':
            validationResult = validations.required(value);
            if (!validationResult) {
              errorMessageToDisplay = `${fundLimitedPartners.form[fieldName].displayName} is required`;
            }
            break;
          case 'uniqueLpName':
            validationResult = checkInvestorLpName(value);
            if (!validationResult) {
              errorMessageToDisplay = `Investor name already used`;
            }
            break;
          case 'email':
            validationResult = validations.email(value);
            if (!validationResult) {
              errorMessageToDisplay = 'Wrong email format';
            }
            break;
          case 'number':
            validationResult = validations.number(value);
            if (!validationResult) {
              errorMessageToDisplay = `${fundLimitedPartners.form[fieldName].displayName} must be a number`;
            }
            break;
          // case 'phone':
          //   validationResult = validations.phone(value);
          //   if (!validationResult) {
          //     errorMessageToDisplay = 'Invalid phone format expecting: ### ### ####';
          //   }
          //   break;
          default:
            return true;
        }
        setFormField(fieldName, 'value', value);
        setFormField(fieldName, 'valid', validationResult);
        setFormField(fieldName, 'errorMessage', errorMessageToDisplay);
        if (!validationResult) {
          return false;
        }
      }
      // });
    }

    return true;
  };

  const handlerChangeTextInput = (event) => {
    const { setFormField } = props;
    event.preventDefault();
    checkOptionalValidators(event.target.name, event.target.value);
    setFormField(event.target.name, 'value', event.target.value);
  };
  // Only used for entity type
  const handleDropdownChange = (event) => {
    event.preventDefault();
    const { setFormField } = props;
    const selectedEntity = event.target.value;
    setFormField('entityType', 'value', selectedEntity);
    setIsEntity(getIsEntity(selectedEntity));
    clearDisabledQuestions();
    if (getIsEntity(selectedEntity)) {
      noIsNaturalRef.current.click();
    } else {
      yesIsNaturalRef.current.click();
    }
  };

  const checkOptionalValidators = (field, value) => {
    const { setFormField } = props;
    const checkedEmailPrincipalValidators = value !== '' ? ['email'] : [];
    const checkedPhonePrincipalValidators = value !== '' ? ['phone'] : [];
    switch (field) {
      case 'emailPrincipalContact':
        setFormField(
          'emailPrincipalContact',
          'validators',
          checkedEmailPrincipalValidators
        );
        break;
      case 'phonePrincipalContact':
        setFormField(
          'phonePrincipalContact',
          'validators',
          checkedPhonePrincipalValidators
        );
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line
  const handleBackButton = (event) => {
    event.preventDefault();
    document.getElementById('close-lp-modal').click();
  };

  const formSubmitV2 = (event) => {
    if (event) event.preventDefault();
    const {
      organizationContext,
      saveLimitedPartner,
      fundLimitedPartners,
      setFormField,
    } = props;
    if (verifyFormValidity()) {
      const lpPayload = {
        name: fundLimitedPartners.form.name.value,
        entityType: fundLimitedPartners.form.entityType.value,
        country: fundLimitedPartners.form.country.value,
        countryPlace: fundLimitedPartners.form.countryPlace.value,
        principalPlaceOfResidencePrimaryContact:
          fundLimitedPartners.form.principalPlaceOfResidencePrimaryContact
            .value,
        principalPlaceOfBusinessPrimaryContact:
          fundLimitedPartners.form.principalPlaceOfBusinessPrimaryContact.value,
        principalPlaceOfResidence:
          fundLimitedPartners.form.principalPlaceOfResidence.value,
        principalPlaceOfBusiness:
          fundLimitedPartners.form.principalPlaceOfBusiness.value,
        namePrincipalContact:
          fundLimitedPartners.form.namePrincipalContact.value,
        emailPrincipalContact:
          fundLimitedPartners.form.emailPrincipalContact.value,
        phonePrincipalContact:
          fundLimitedPartners.form.phonePrincipalContact.value,

        reportsPrimaryContactName:
          fundLimitedPartners.form.reportsPrimaryContactName.value,
        reportsPrimaryContactEmail:
          fundLimitedPartners.form.reportsPrimaryContactEmail.value,
        reportsPrimaryContactPhone:
          fundLimitedPartners.form.reportsPrimaryContactPhone.value,
        reportsSecondaryContactName:
          fundLimitedPartners.form.reportsSecondaryContactName.value,
        reportsSecondaryContactEmail:
          fundLimitedPartners.form.reportsSecondaryContactEmail.value,
        reportsSecondaryContactPhone:
          fundLimitedPartners.form.reportsSecondaryContactPhone.value,

        partnerStatementsPrimaryContactName:
          fundLimitedPartners.form.partnerStatementsPrimaryContactName.value,
        partnerStatementsPrimaryContactEmail:
          fundLimitedPartners.form.partnerStatementsPrimaryContactEmail.value,
        partnerStatementsPrimaryContactPhone:
          fundLimitedPartners.form.partnerStatementsPrimaryContactPhone.value,
        partnerStatementsSecondaryContactName:
          fundLimitedPartners.form.partnerStatementsSecondaryContactName.value,
        partnerStatementsSecondaryContactEmail:
          fundLimitedPartners.form.partnerStatementsSecondaryContactEmail.value,
        partnerStatementsSecondaryContactPhone:
          fundLimitedPartners.form.partnerStatementsSecondaryContactPhone.value,

        taxPrimaryContactName:
          fundLimitedPartners.form.taxPrimaryContactName.value,
        taxPrimaryContactEmail:
          fundLimitedPartners.form.taxPrimaryContactEmail.value,
        taxPrimaryContactPhone:
          fundLimitedPartners.form.taxPrimaryContactPhone.value,
        taxSecondaryContactName:
          fundLimitedPartners.form.taxSecondaryContactName.value,
        taxSecondaryContactEmail:
          fundLimitedPartners.form.taxSecondaryContactEmail.value,
        taxSecondaryContactPhone:
          fundLimitedPartners.form.taxSecondaryContactPhone.value,

        capitalPrimaryContactName:
          fundLimitedPartners.form.capitalPrimaryContactName.value,
        capitalPrimaryContactEmail:
          fundLimitedPartners.form.capitalPrimaryContactEmail.value,
        capitalPrimaryContactPhone:
          fundLimitedPartners.form.capitalPrimaryContactPhone.value,
        capitalSecondaryContactName:
          fundLimitedPartners.form.capitalSecondaryContactName.value,
        capitalSecondaryContactEmail:
          fundLimitedPartners.form.capitalSecondaryContactEmail.value,
        capitalSecondaryContactPhone:
          fundLimitedPartners.form.capitalSecondaryContactPhone.value,

        stockPrimaryContactName:
          fundLimitedPartners.form.stockPrimaryContactName.value,
        stockPrimaryContactEmail:
          fundLimitedPartners.form.stockPrimaryContactEmail.value,
        capitalPrimaryContactPhone:
          fundLimitedPartners.form.capitalPrimaryContactPhone.value,
        stockSecondaryContactName:
          fundLimitedPartners.form.stockSecondaryContactName.value,
        stockSecondaryContactEmail:
          fundLimitedPartners.form.stockSecondaryContactEmail.value,
        stockSecondaryContactPhone:
          fundLimitedPartners.form.stockSecondaryContactPhone.value,

        legalPrimaryContactName:
          fundLimitedPartners.form.legalPrimaryContactName.value,
        legalPrimaryContactEmail:
          fundLimitedPartners.form.legalPrimaryContactEmail.value,
        legalPrimaryContactPhone:
          fundLimitedPartners.form.legalPrimaryContactPhone.value,
        legalSecondaryContactName:
          fundLimitedPartners.form.legalSecondaryContactName.value,
        legalSecondaryContactEmail:
          fundLimitedPartners.form.legalSecondaryContactEmail.value,
        legalSecondaryContactPhone:
          fundLimitedPartners.form.legalSecondaryContactPhone.value,

        addressPrincipalContact:
          fundLimitedPartners.form.addressPrincipalContact.value,
        bankName: fundLimitedPartners.form.bankName.value,
        acctName: fundLimitedPartners.form.acctName.value,
        acctNumber: fundLimitedPartners.form.acctNumber.value,
        swiftCode: fundLimitedPartners.form.swiftCode.value,
        bankaba: fundLimitedPartners.form.bankaba.value,
        furtherCredit: fundLimitedPartners.form.furtherCredit.value,
        location: fundLimitedPartners.form.location.value,

        id_investor_status_1: fundLimitedPartners.form.id_investor_status_1
          .isSelected || [false, false],
        id_investor_status_2: fundLimitedPartners.form.id_investor_status_2
          .isSelected || [false, false],
        id_investor_status_3: fundLimitedPartners.form.id_investor_status_3
          .isSelected || [false, false],
        id_investor_status_4: fundLimitedPartners.form.id_investor_status_4
          .isSelected || [false, false],
        id_investor_status_5_a: fundLimitedPartners.form.id_investor_status_5_a
          .isSelected || [false, false],
        id_investor_status_5_b: fundLimitedPartners.form.id_investor_status_5_b
          .isSelected || [false, false],
        id_investor_status_5_c: fundLimitedPartners.form.id_investor_status_5_c
          .isSelected || [false, false],
        id_investor_status_5_d: fundLimitedPartners.form.id_investor_status_5_d
          .isSelected || [false, false],
        id_investor_status_5_e: fundLimitedPartners.form.id_investor_status_5_e
          .isSelected || [false, false],
        id_investor_status_5_f: fundLimitedPartners.form.id_investor_status_5_f
          .isSelected || [false, false],
        id_investor_status_6: fundLimitedPartners.form.id_investor_status_6
          .isSelected || [false, false],

        id_investor_representations_1:
          fundLimitedPartners.form.id_investor_representations_1.isSelected,
        id_investor_representations_2:
          fundLimitedPartners.form.id_investor_representations_2.isSelected,
        id_investor_representations_3:
          fundLimitedPartners.form.id_investor_representations_3.isSelected,
        id_investor_representations_4:
          fundLimitedPartners.form.id_investor_representations_4.isSelected,
        id_investor_representations_5:
          fundLimitedPartners.form.id_investor_representations_5.isSelected,
        id_investor_representations_6:
          fundLimitedPartners.form.id_investor_representations_6.isSelected,
        id_investor_representations_7:
          fundLimitedPartners.form.id_investor_representations_7.isSelected,
        id_investor_representations_8:
          fundLimitedPartners.form.id_investor_representations_8.isSelected,
        id_investor_representations_9:
          fundLimitedPartners.form.id_investor_representations_9.isSelected,
        id_investor_representations_or:
          fundLimitedPartners.form.id_investor_representations_or.isSelected,

        id_purchase_representations_1:
          fundLimitedPartners.form.id_purchase_representations_1.isSelected,
        id_purchase_representations_2:
          fundLimitedPartners.form.id_purchase_representations_2.isSelected,
        id_purchase_representations_3:
          fundLimitedPartners.form.id_purchase_representations_3.isSelected,
        id_purchase_representations_4:
          fundLimitedPartners.form.id_purchase_representations_4.isSelected,
        id_purchase_representations_5:
          fundLimitedPartners.form.id_purchase_representations_5.isSelected,
        id_purchase_representations_6:
          fundLimitedPartners.form.id_purchase_representations_6.isSelected,
        id_purchase_representations_7:
          fundLimitedPartners.form.id_purchase_representations_7.isSelected,
        id_purchase_representations_or:
          fundLimitedPartners.form.id_purchase_representations_or.isSelected,

        id_client_representations_1:
          fundLimitedPartners.form.id_client_representations_1.isSelected,
        id_client_representations_2:
          fundLimitedPartners.form.id_client_representations_2.isSelected,
        id_client_representations_or:
          fundLimitedPartners.form.id_client_representations_or.isSelected,

        id_foreign_person_1:
          fundLimitedPartners.form.id_foreign_person_1.isSelected,
        id_foreign_person_2:
          fundLimitedPartners.form.id_foreign_person_2.isSelected,
        id_foreign_person_3:
          fundLimitedPartners.form.id_foreign_person_3.isSelected,
        id_foreign_person_3:
          fundLimitedPartners.form.id_foreign_person_4.isSelected,
        id_foreign_person_or:
          fundLimitedPartners.form.id_foreign_person_or.isSelected,

        id_foreign_person_2_1: fundLimitedPartners.form
          .id_foreign_person_2_1.isSelected || [false, false], 
        id_foreign_person_2_2:
          fundLimitedPartners.form.id_foreign_person_2_2.isSelected,
        id_foreign_person_2_or:
          fundLimitedPartners.form.id_foreign_person_2_or.isSelected,

        id_wiring_bank_status_1: fundLimitedPartners.form
          .id_wiring_bank_status_1.isSelected || [false, false],
        id_wiring_bank_status_1_opt:
          fundLimitedPartners.form.id_wiring_bank_status_1_opt.value || '',
        id_wiring_bank_status_2: fundLimitedPartners.form
          .id_wiring_bank_status_2.isSelected || [false, false],

        id_special_entities_1_a: fundLimitedPartners.form
          .id_special_entities_1_a.isSelected || [false, false],
        id_special_entities_1_b: fundLimitedPartners.form
          .id_special_entities_1_b.isSelected || [false, false],
        id_special_entities_1_b_opt:
          fundLimitedPartners.form.id_special_entities_1_b_opt.value || '',
        id_special_entities_2_a: fundLimitedPartners.form
          .id_special_entities_2_a.isSelected || [false, false],
        id_special_entities_2_b: fundLimitedPartners.form
          .id_special_entities_2_b.isSelected || [false, false],
        id_special_entities_2_c: fundLimitedPartners.form
          .id_special_entities_2_c.isSelected || [false, false],
        id_special_entities_2_d: fundLimitedPartners.form
          .id_special_entities_2_d.isSelected || [false, false],
        id_special_entities_2_e_opt:
          fundLimitedPartners.form.id_special_entities_2_e_opt.value,

        id_special_entities_3_a: fundLimitedPartners.form
          .id_special_entities_3_a.isSelected || [false, false],

        id_special_entities_4: fundLimitedPartners.form.id_special_entities_4
          .isSelected || [false, false],
        id_special_entities_5: fundLimitedPartners.form.id_special_entities_5
          .isSelected || [false, false],
        id_special_entities_6: fundLimitedPartners.form.id_special_entities_6
          .isSelected || [false, false],
        id_special_entities_7: fundLimitedPartners.form.id_special_entities_7
          .isSelected || [false, false],
        id_special_entities_8: fundLimitedPartners.form.id_special_entities_8
          .isSelected || [false, false],

        id_special_entities_9_a: fundLimitedPartners.form
          .id_special_entities_9_a.isSelected || [false, false],
        id_special_entities_9_b: fundLimitedPartners.form
          .id_special_entities_9_b.isSelected || [false, false],
        id_special_entities_9_c: fundLimitedPartners.form
          .id_special_entities_9_c.isSelected || [false, false],

        id_warranties_exceptions:
          fundLimitedPartners.form.id_warranties_exceptions.value,
      };
      if (fundLimitedPartners.form.entityType.value === 'Other') {
        lpPayload.otherValue = fundLimitedPartners.form.otherValue.value;
      }
      lpPayload.organization = organizationContext.organization._id;
      saveLimitedPartner(lpPayload);
      const { history, resetForms } = props;
      setAddInvestor(false);
      resetForms(); // possible useEffect here
      history.push('limitedPartners');
    }
  };

  const handleAddLPv2 = () => {
    const { resetForms, setFormField } = props;
    resetForms();
    setAddInvestor(true);
    setAddAnotherContactPerson(false);
    setFormField('name', 'validators', ['uniqueLpName']);
    setFormField('entityType', 'validators', ['required']);
    setFormField('entityType', 'value', '');
  };

  const getTwoLetterStates = () => {
    let country = '';
    country = props.fundLimitedPartners.form.countryPlace.value;

    switch (country) {
      case CANADA:
        return caStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });

      case USA:
        return usStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });

      default:
        return [];
    }
  };

  const clearMultiSelectQuestions = (sectionIdentifier) => {
    const { setFormField } = props;
    switch (sectionIdentifier) {
      case 'accreditedInvestorRepresentations':
        questions.accreditedInvestorRepresentations.forEach((item) => {
          if (!item.id.includes('_or')) {
            setFormField(item.id, 'isSelected', false);
          }
        });
        break;
      case 'qualifiedPurchaserRepresentations':
        questions.qualifiedPurchaserRepresentations.forEach((item) => {
          if (!item.id.includes('_or')) {
            setFormField(item.id, 'isSelected', false);
          }
        });
        break;

      case 'qualifiedClientRepresentations':
        questions.qualifiedClientRepresentations.forEach((item) => {
          if (!item.id.includes('_or')) {
            setFormField(item.id, 'isSelected', false);
          }
        });
        break;
      case 'foreignPersonStatusRepresentations':
        questions.foreignPersonStatusRepresentations.forEach((item) => {
          if (!item.id.includes('_or')) {
            setFormField(item.id, 'isSelected', false);
          }
        });
        break;
      case 'foreignPersonStatusRepresentations2':
        questions.foreignPersonStatusRepresentations2.forEach((item) => {
          if (!item.id.includes('_or')) {
            setFormField(item.id, 'isSelected', false);
          }
        });
        break;
    }
  };

  const clearMultiSelectOtherQuestions = (sectionIdentifier) => {
    const { setFormField } = props;
    switch (sectionIdentifier) {
      case 'accreditedInvestorRepresentations':
        setFormField('id_investor_representations_or', 'isSelected', false);
        break;
      case 'qualifiedPurchaserRepresentations':
        setFormField('id_purchase_representations_or', 'isSelected', false);
        break;
      case 'qualifiedClientRepresentations':
        setFormField('id_client_representations_or', 'isSelected', false);
        break;
      case 'foreignPersonStatusRepresentations':
        setFormField('id_foreign_person_or', 'isSelected', false);
        break;
      case 'foreignPersonStatusRepresentations2':
        setFormField('id_foreign_person_2_or', 'isSelected', false);
        break;
    }
  };

  const parseMultiSelectStateFromId = (e, sectionIdentifier) => {
    const _identifier = e.target.id;
    const { form } = props.fundLimitedPartners;
    const { setFormField } = props;
    if (_identifier.includes('_or')) {
      clearMultiSelectQuestions(sectionIdentifier);
      setFormField(_identifier, 'isSelected', !form[_identifier].isSelected);
    } else {
      clearMultiSelectOtherQuestions(sectionIdentifier);
      setFormField(_identifier, 'isSelected', !form[_identifier].isSelected);
    }
  };

  const parseSingleSelectStateFromId = (e) => {
    const _identifier = e.target.id;
    const { form } = props.fundLimitedPartners;
    const { setFormField } = props;
    setFormField(_identifier, 'isSelected', !form[_identifier].isSelected);
  };

  const renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled
  ) => {
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
      shouldDisableClickOnSelectedOption: true,
    };

    let forwardRefTemplate = { ref1: null, ref2: null };
    if (id && id.includes('id_investor_status_1_yes')) {
      forwardRefTemplate = {
        ref1: yesIsNaturalRef,
        ref2: noIsNaturalRef,
      };
    }

    return (
      <ConfirmCheckBox
        forwardRef={forwardRefTemplate}
        data={inputDataTemp}
        onChangeHandler={changeHandler}
      />
    );
  };
  const checkInvestorLpName = (lpName) => {
    let valid = true;
    allRecords.forEach((e) => {
      e.forEach((item) => {
        if (item.displayName === lpName) valid = false;
      });
    });
    return valid;
  };

  const editLimitedPartner = (event) => {
    // if (event) event.preventDefault();
    const { editLimitedPartner, fundLimitedPartners, setFormField } = props;
    const checkedEmailPrincipalValidators =
      fundLimitedPartners.form.emailPrincipalContact.value !== ''
        ? ['email']
        : [];
    const checkedPhonePrincipalValidators =
      fundLimitedPartners.form.phonePrincipalContact.value !== ''
        ? ['phone']
        : [];
    setFormField(
      'emailPrincipalContact',
      'validators',
      checkedEmailPrincipalValidators
    );
    setFormField(
      'phonePrincipalContact',
      'validators',
      checkedPhonePrincipalValidators
    );

    if (verifyFormValidity()) {
      const currentLP = targetLPEdit;
      currentLP.name = fundLimitedPartners.form.name.value;
      currentLP.entityType = fundLimitedPartners.form.entityType.value;
      if (fundLimitedPartners.form.entityType.value === 'Other') {
        currentLP.otherValue = fundLimitedPartners.form.otherValue.value;
      }
      currentLP.citizenship = fundLimitedPartners.form.citizenship.value;
      currentLP.countryPlace = fundLimitedPartners.form.countryPlace.value;
      currentLP.principalPlaceOfResidencePrimaryContact =
        fundLimitedPartners.form.principalPlaceOfResidencePrimaryContact.value;
      currentLP.principalPlaceOfBusinessPrimaryContact =
        fundLimitedPartners.form.principalPlaceOfBusinessPrimaryContact.value;
      currentLP.principalPlaceOfResidence =
        fundLimitedPartners.form.principalPlaceOfResidence.value;
      currentLP.principalPlaceOfBusiness =
        fundLimitedPartners.form.principalPlaceOfBusiness.value;
      currentLP.namePrincipalContact =
        fundLimitedPartners.form.namePrincipalContact.value;
      currentLP.emailPrincipalContact =
        fundLimitedPartners.form.emailPrincipalContact.value;
      currentLP.phonePrincipalContact =
        fundLimitedPartners.form.phonePrincipalContact.value;
      currentLP.addressPrincipalContact =
        fundLimitedPartners.form.addressPrincipalContact.value;
      currentLP.bankName = fundLimitedPartners.form.bankName.value;
      currentLP.acctName = fundLimitedPartners.form.acctName.value;
      currentLP.acctNumber = fundLimitedPartners.form.acctNumber.value;
      currentLP.swiftCode = fundLimitedPartners.form.swiftCode.value;
      currentLP.bankaba = fundLimitedPartners.form.bankaba.value;
      currentLP.furtherCredit = fundLimitedPartners.form.furtherCredit.value;
      currentLP.location = fundLimitedPartners.form.location.value;

      currentLP.reportsPrimaryContactName =
        fundLimitedPartners.form.reportsPrimaryContactName.value;
      currentLP.reportsPrimaryContactEmail =
        fundLimitedPartners.form.reportsPrimaryContactEmail.value;
      currentLP.reportsPrimaryContactPhone =
        fundLimitedPartners.form.reportsPrimaryContactPhone.value;

      currentLP.reportsSecondaryContactName =
        fundLimitedPartners.form.reportsSecondaryContactName.value;
      currentLP.reportsSecondaryContactEmail =
        fundLimitedPartners.form.reportsSecondaryContactEmail.value;
      currentLP.reportsSecondaryContactPhone =
        fundLimitedPartners.form.reportsSecondaryContactPhone.value;

      currentLP.partnerStatementsPrimaryContactName =
        fundLimitedPartners.form.partnerStatementsPrimaryContactName.value;
      currentLP.partnerStatementsPrimaryContactEmail =
        fundLimitedPartners.form.partnerStatementsPrimaryContactEmail.value;
      currentLP.partnerStatementsPrimaryContactPhone =
        fundLimitedPartners.form.partnerStatementsPrimaryContactPhone.value;

      currentLP.partnerStatementsSecondaryContactName =
        fundLimitedPartners.form.partnerStatementsSecondaryContactName.value;
      currentLP.partnerStatementsSecondaryContactEmail =
        fundLimitedPartners.form.partnerStatementsSecondaryContactEmail.value;
      currentLP.partnerStatementsSecondaryContactPhone =
        fundLimitedPartners.form.partnerStatementsSecondaryContactPhone.value;

      currentLP.taxPrimaryContactName =
        fundLimitedPartners.form.taxPrimaryContactName.value;
      currentLP.taxPrimaryContactEmail =
        fundLimitedPartners.form.taxPrimaryContactEmail.value;
      currentLP.taxPrimaryContactPhone =
        fundLimitedPartners.form.taxPrimaryContactPhone.value;

      currentLP.taxSecondaryContactName =
        fundLimitedPartners.form.taxSecondaryContactName.value;
      currentLP.taxSecondaryContactEmail =
        fundLimitedPartners.form.taxSecondaryContactEmail.value;
      currentLP.taxSecondaryContactPhone =
        fundLimitedPartners.form.taxSecondaryContactPhone.value;

      currentLP.capitalPrimaryContactName =
        fundLimitedPartners.form.capitalPrimaryContactName.value;
      currentLP.capitalPrimaryContactEmail =
        fundLimitedPartners.form.capitalPrimaryContactEmail.value;
      currentLP.capitalPrimaryContactPhone =
        fundLimitedPartners.form.capitalPrimaryContactPhone.value;

      currentLP.capitalSecondaryContactName =
        fundLimitedPartners.form.capitalSecondaryContactName.value;
      currentLP.capitalSecondaryContactEmail =
        fundLimitedPartners.form.capitalSecondaryContactEmail.value;
      currentLP.capitalSecondaryContactPhone =
        fundLimitedPartners.form.capitalSecondaryContactPhone.value;

      currentLP.stockPrimaryContactName =
        fundLimitedPartners.form.stockPrimaryContactName.value;
      currentLP.stockPrimaryContactEmail =
        fundLimitedPartners.form.stockPrimaryContactEmail.value;
      currentLP.stockPrimaryContactPhone =
        fundLimitedPartners.form.stockPrimaryContactPhone.value;

      currentLP.stockSecondaryContactName =
        fundLimitedPartners.form.stockSecondaryContactName.value;
      currentLP.stockSecondaryContactEmail =
        fundLimitedPartners.form.stockSecondaryContactEmail.value;
      currentLP.stockSecondaryContactPhone =
        fundLimitedPartners.form.stockSecondaryContactPhone.value;

      currentLP.legalPrimaryContactName =
        fundLimitedPartners.form.legalPrimaryContactName.value;
      currentLP.legalPrimaryContactEmail =
        fundLimitedPartners.form.legalPrimaryContactEmail.value;
      currentLP.legalPrimaryContactPhone =
        fundLimitedPartners.form.legalPrimaryContactPhone.value;

      currentLP.legalSecondaryContactName =
        fundLimitedPartners.form.legalSecondaryContactName.value;
      currentLP.legalSecondaryContactEmail =
        fundLimitedPartners.form.legalSecondaryContactEmail.value;
      currentLP.legalSecondaryContactPhone =
        fundLimitedPartners.form.legalSecondaryContactPhone.value;

      currentLP.id_investor_status_1 =
        fundLimitedPartners.form.id_investor_status_1.isSelected;
      currentLP.id_investor_status_2 =
        fundLimitedPartners.form.id_investor_status_2.isSelected;
      currentLP.id_investor_status_3 =
        fundLimitedPartners.form.id_investor_status_3.isSelected;
      currentLP.id_investor_status_4 =
        fundLimitedPartners.form.id_investor_status_4.isSelected;
      currentLP.id_investor_status_5_a =
        fundLimitedPartners.form.id_investor_status_5_a.isSelected;
      currentLP.id_investor_status_5_b =
        fundLimitedPartners.form.id_investor_status_5_b.isSelected;
      currentLP.id_investor_status_5_c =
        fundLimitedPartners.form.id_investor_status_5_c.isSelected;
      currentLP.id_investor_status_5_d =
        fundLimitedPartners.form.id_investor_status_5_d.isSelected;
      currentLP.id_investor_status_5_e =
        fundLimitedPartners.form.id_investor_status_5_e.isSelected;
      currentLP.id_investor_status_5_f =
        fundLimitedPartners.form.id_investor_status_5_f.isSelected;
      currentLP.id_investor_status_6 =
        fundLimitedPartners.form.id_investor_status_6.isSelected;

      currentLP.id_investor_representations_1 =
        fundLimitedPartners.form.id_investor_representations_1.isSelected;
      currentLP.id_investor_representations_2 =
        fundLimitedPartners.form.id_investor_representations_2.isSelected;
      currentLP.id_investor_representations_3 =
        fundLimitedPartners.form.id_investor_representations_3.isSelected;
      currentLP.id_investor_representations_4 =
        fundLimitedPartners.form.id_investor_representations_4.isSelected;
      currentLP.id_investor_representations_5 =
        fundLimitedPartners.form.id_investor_representations_5.isSelected;
      currentLP.id_investor_representations_6 =
        fundLimitedPartners.form.id_investor_representations_6.isSelected;
      currentLP.id_investor_representations_7 =
        fundLimitedPartners.form.id_investor_representations_7.isSelected;
      currentLP.id_investor_representations_8 =
        fundLimitedPartners.form.id_investor_representations_8.isSelected;
      currentLP.id_investor_representations_9 =
        fundLimitedPartners.form.id_investor_representations_9.isSelected;
      currentLP.id_investor_representations_or =
        fundLimitedPartners.form.id_investor_representations_or.isSelected;

      currentLP.id_purchase_representations_1 =
        fundLimitedPartners.form.id_purchase_representations_1.isSelected;
      currentLP.id_purchase_representations_2 =
        fundLimitedPartners.form.id_purchase_representations_2.isSelected;
      currentLP.id_purchase_representations_3 =
        fundLimitedPartners.form.id_purchase_representations_3.isSelected;
      currentLP.id_purchase_representations_4 =
        fundLimitedPartners.form.id_purchase_representations_4.isSelected;
      currentLP.id_purchase_representations_5 =
        fundLimitedPartners.form.id_purchase_representations_5.isSelected;
      currentLP.id_purchase_representations_6 =
        fundLimitedPartners.form.id_purchase_representations_6.isSelected;
      currentLP.id_purchase_representations_7 =
        fundLimitedPartners.form.id_purchase_representations_7.isSelected;
      currentLP.id_purchase_representations_or =
        fundLimitedPartners.form.id_purchase_representations_or.isSelected;

      currentLP.id_client_representations_1 =
        fundLimitedPartners.form.id_client_representations_1.isSelected;
      currentLP.id_client_representations_2 =
        fundLimitedPartners.form.id_client_representations_2.isSelected;
      currentLP.id_client_representations_or =
        fundLimitedPartners.form.id_client_representations_or.isSelected;

      currentLP.id_foreign_person_1 =
        fundLimitedPartners.form.id_foreign_person_1.isSelected;
      currentLP.id_foreign_person_2 =
        fundLimitedPartners.form.id_foreign_person_2.isSelected;
      currentLP.id_foreign_person_3 =
        fundLimitedPartners.form.id_foreign_person_3.isSelected;
      currentLP.id_foreign_person_4 =
        fundLimitedPartners.form.id_foreign_person_4.isSelected;
      currentLP.id_foreign_person_or =
        fundLimitedPartners.form.id_foreign_person_or.isSelected;

      currentLP.id_foreign_person_2_1 =
        fundLimitedPartners.form.id_foreign_person_2_1.isSelected;
      currentLP.id_foreign_person_2_2 =
        fundLimitedPartners.form.id_foreign_person_2_2.isSelected;
      currentLP.id_foreign_person_2_or =
        fundLimitedPartners.form.id_foreign_person_2_or.isSelected;

      currentLP.id_wiring_bank_status_1 =
        fundLimitedPartners.form.id_wiring_bank_status_1.isSelected;
      currentLP.id_wiring_bank_status_1_opt =
        fundLimitedPartners.form.id_wiring_bank_status_1_opt.value;
      currentLP.id_wiring_bank_status_2 =
        fundLimitedPartners.form.id_wiring_bank_status_2.isSelected;

      currentLP.id_special_entities_1_a =
        fundLimitedPartners.form.id_special_entities_1_a.isSelected;
      currentLP.id_special_entities_1_b =
        fundLimitedPartners.form.id_special_entities_1_b.isSelected;
      currentLP.id_special_entities_1_b_opt =
        fundLimitedPartners.form.id_special_entities_1_b_opt.value || '';
      currentLP.id_special_entities_2_a =
        fundLimitedPartners.form.id_special_entities_2_a.isSelected;
      currentLP.id_special_entities_2_b =
        fundLimitedPartners.form.id_special_entities_2_b.isSelected;
      currentLP.id_special_entities_2_c =
        fundLimitedPartners.form.id_special_entities_2_c.isSelected;
      currentLP.id_special_entities_2_d =
        fundLimitedPartners.form.id_special_entities_2_d.isSelected;
      currentLP.id_special_entities_2_e_opt =
        fundLimitedPartners.form.id_special_entities_2_e_opt.value;

      currentLP.id_special_entities_3_a =
        fundLimitedPartners.form.id_special_entities_3_a.isSelected;
      currentLP.id_special_entities_4 =
        fundLimitedPartners.form.id_special_entities_4.isSelected;
      currentLP.id_special_entities_5 =
        fundLimitedPartners.form.id_special_entities_5.isSelected;
      currentLP.id_special_entities_6 =
        fundLimitedPartners.form.id_special_entities_6.isSelected;
      currentLP.id_special_entities_7 =
        fundLimitedPartners.form.id_special_entities_7.isSelected;
      currentLP.id_special_entities_8 =
        fundLimitedPartners.form.id_special_entities_8.isSelected;
      currentLP.id_special_entities_9_a =
        fundLimitedPartners.form.id_special_entities_9_a.isSelected;
      currentLP.id_special_entities_9_b =
        fundLimitedPartners.form.id_special_entities_9_b.isSelected;
      currentLP.id_special_entities_9_c =
        fundLimitedPartners.form.id_special_entities_9_c.isSelected;
      currentLP.id_warranties_exceptions =
        fundLimitedPartners.form.id_warranties_exceptions.value;
      editLimitedPartner(currentLP);
    }
  };
  const selectLPEdit = (event) => {
    const { organizationContext, setFormField } = props;
    event.preventDefault();
    setFormField('name', 'validators', []);
    setFormField('entityType', 'validators', []);
    const targetLPId = event.target.dataset.identifier;
    const targetLPEdit = organizationContext.organization.limitedPartners.find(
      (orgLp) => orgLp._id.toString() === targetLPId
    );
    const isEntityResponse = getIsEntity(targetLPEdit.entityType);
    setIsEntity(isEntityResponse);
    setIsEditingLP(true);
    setTargetLPEdit(targetLPEdit);

    // this was on state callback
    setFormField('name', 'value', targetLPEdit.name);
    setFormField('entityType', 'value', targetLPEdit.entityType);
    if (targetLPEdit.entityType === 'Other') {
      setFormField('otherValue', 'value', targetLPEdit.otherValue);
    }
    // setFormField('citizenship', 'value', targetLPEdit.citizenship);
    setFormField(
      'principalPlaceOfResidence',
      'value',
      targetLPEdit.principalPlaceOfResidence
    );
    setFormField(
      'principalPlaceOfBusiness',
      'value',
      targetLPEdit.principalPlaceOfBusiness
    );
    setFormField(
      'namePrincipalContact',
      'value',
      targetLPEdit.namePrincipalContact
    );
    setFormField(
      'emailPrincipalContact',
      'value',
      targetLPEdit.emailPrincipalContact
    );
    setFormField(
      'phonePrincipalContact',
      'value',
      targetLPEdit.phonePrincipalContact
    );

    setFormField(
      'reportsPrimaryContactName',
      'value',
      targetLPEdit.reportsPrimaryContactName
    );
    setFormField(
      'reportsPrimaryContactEmail',
      'value',
      targetLPEdit.reportsPrimaryContactEmail
    );
    setFormField(
      'reportsPrimaryContactPhone',
      'value',
      targetLPEdit.reportsPrimaryContactPhone
    );

    setFormField(
      'reportsSecondaryContactName',
      'value',
      targetLPEdit.reportsSecondaryContactName
    );
    setFormField(
      'reportsSecondaryContactEmail',
      'value',
      targetLPEdit.reportsSecondaryContactEmail
    );
    setFormField(
      'reportsSecondaryContactPhone',
      'value',
      targetLPEdit.reportsSecondaryContactPhone
    );

    setFormField(
      'partnerStatementsPrimaryContactName',
      'value',
      targetLPEdit.partnerStatementsPrimaryContactName
    );
    setFormField(
      'partnerStatementsPrimaryContactEmail',
      'value',
      targetLPEdit.partnerStatementsPrimaryContactEmail
    );
    setFormField(
      'partnerStatementsPrimaryContactPhone',
      'value',
      targetLPEdit.partnerStatementsPrimaryContactPhone
    );

    setFormField(
      'partnerStatementsSecondaryContactName',
      'value',
      targetLPEdit.partnerStatementsSecondaryContactName
    );
    setFormField(
      'partnerStatementsSecondaryContactEmail',
      'value',
      targetLPEdit.partnerStatementsSecondaryContactEmail
    );
    setFormField(
      'partnerStatementsSecondaryContactPhone',
      'value',
      targetLPEdit.partnerStatementsSecondaryContactPhone
    );

    setFormField(
      'taxPrimaryContactName',
      'value',
      targetLPEdit.taxPrimaryContactName
    );
    setFormField(
      'taxPrimaryContactEmail',
      'value',
      targetLPEdit.taxPrimaryContactEmail
    );
    setFormField(
      'taxPrimaryContactPhone',
      'value',
      targetLPEdit.taxPrimaryContactPhone
    );

    setFormField(
      'taxSecondaryContactName',
      'value',
      targetLPEdit.taxSecondaryContactName
    );
    setFormField(
      'taxSecondaryContactEmail',
      'value',
      targetLPEdit.taxSecondaryContactEmail
    );
    setFormField(
      'taxSecondaryContactPhone',
      'value',
      targetLPEdit.taxSecondaryContactPhone
    );

    setFormField(
      'capitalPrimaryContactName',
      'value',
      targetLPEdit.capitalPrimaryContactName
    );
    setFormField(
      'capitalPrimaryContactEmail',
      'value',
      targetLPEdit.capitalPrimaryContactEmail
    );
    setFormField(
      'capitalPrimaryContactPhone',
      'value',
      targetLPEdit.capitalPrimaryContactPhone
    );

    setFormField(
      'capitalSecondaryContactName',
      'value',
      targetLPEdit.capitalSecondaryContactName
    );
    setFormField(
      'capitalSecondaryContactEmail',
      'value',
      targetLPEdit.capitalSecondaryContactEmail
    );
    setFormField(
      'capitalSecondaryContactPhone',
      'value',
      targetLPEdit.capitalSecondaryContactPhone
    );

    setFormField(
      'stockPrimaryContactName',
      'value',
      targetLPEdit.stockPrimaryContactName
    );
    setFormField(
      'stockPrimaryContactEmail',
      'value',
      targetLPEdit.stockPrimaryContactEmail
    );
    setFormField(
      'stockPrimaryContactPhone',
      'value',
      targetLPEdit.stockPrimaryContactPhone
    );

    setFormField(
      'stockSecondaryContactName',
      'value',
      targetLPEdit.stockSecondaryContactName
    );
    setFormField(
      'stockSecondaryContactEmail',
      'value',
      targetLPEdit.stockSecondaryContactEmail
    );
    setFormField(
      'stockSecondaryContactPhone',
      'value',
      targetLPEdit.stockSecondaryContactPhone
    );

    setFormField(
      'legalPrimaryContactName',
      'value',
      targetLPEdit.legalPrimaryContactName
    );
    setFormField(
      'legalPrimaryContactEmail',
      'value',
      targetLPEdit.legalPrimaryContactEmail
    );
    setFormField(
      'legalPrimaryContactPhone',
      'value',
      targetLPEdit.legalPrimaryContactPhone
    );

    setFormField(
      'legalSecondaryContactName',
      'value',
      targetLPEdit.legalSecondaryContactName
    );
    setFormField(
      'legalSecondaryContactEmail',
      'value',
      targetLPEdit.legalSecondaryContactEmail
    );
    setFormField(
      'legalSecondaryContactPhone',
      'value',
      targetLPEdit.legalSecondaryContactPhone
    );
    setFormField(
      'addressPrincipalContact',
      'value',
      targetLPEdit.addressPrincipalContact
    );
    setFormField('country', 'value', targetLPEdit.country);
    setFormField('countryPlace', 'value', targetLPEdit.countryPlace || USA);
    setFormField(
      'principalPlaceOfBusinessPrimaryContact',
      'value',
      targetLPEdit.principalPlaceOfBusinessPrimaryContact
    );
    setFormField(
      'principalPlaceOfResidencePrimaryContact',
      'value',
      targetLPEdit.principalPlaceOfResidencePrimaryContact
    );
    setFormField('bankName', 'value', targetLPEdit.bankName);
    setFormField('acctName', 'value', targetLPEdit.acctName);
    setFormField('acctNumber', 'value', targetLPEdit.acctNumber);
    setFormField('swiftCode', 'value', targetLPEdit.swiftCode);
    setFormField('bankaba', 'value', targetLPEdit.bankaba);
    setFormField('furtherCredit', 'value', targetLPEdit.furtherCredit);
    setFormField('location', 'value', targetLPEdit.location);

    setFormField(
      'id_investor_status_1',
      'isSelected',
      targetLPEdit.id_investor_status_1 || [false, false]
    );
    setFormField(
      'id_investor_status_2',
      'isSelected',
      targetLPEdit.id_investor_status_2 || [false, false]
    );
    setFormField(
      'id_investor_status_3',
      'isSelected',
      targetLPEdit.id_investor_status_3 || [false, false]
    );
    setFormField(
      'id_investor_status_4',
      'isSelected',
      targetLPEdit.id_investor_status_4 || [false, false]
    );
    setFormField(
      'id_investor_status_5_a',
      'isSelected',
      targetLPEdit.id_investor_status_5_a || [false, false]
    );
    setFormField(
      'id_investor_status_5_b',
      'isSelected',
      targetLPEdit.id_investor_status_5_b || [false, false]
    );
    setFormField(
      'id_investor_status_5_c',
      'isSelected',
      targetLPEdit.id_investor_status_5_c || [false, false]
    );
    setFormField(
      'id_investor_status_5_d',
      'isSelected',
      targetLPEdit.id_investor_status_5_d || [false, false]
    );
    setFormField(
      'id_investor_status_5_e',
      'isSelected',
      targetLPEdit.id_investor_status_5_e || [false, false]
    );
    setFormField(
      'id_investor_status_5_f',
      'isSelected',
      targetLPEdit.id_investor_status_5_f || [false, false]
    );
    setFormField(
      'id_investor_status_6',
      'isSelected',
      targetLPEdit.id_investor_status_6 || [false, false]
    );

    setFormField(
      'id_investor_representations_1',
      'isSelected',
      targetLPEdit.id_investor_representations_1 || false
    );
    setFormField(
      'id_investor_representations_2',
      'isSelected',
      targetLPEdit.id_investor_representations_2 || false
    );
    setFormField(
      'id_investor_representations_3',
      'isSelected',
      targetLPEdit.id_investor_representations_3 || false
    );
    setFormField(
      'id_investor_representations_4',
      'isSelected',
      targetLPEdit.id_investor_representations_4 || false
    );
    setFormField(
      'id_investor_representations_5',
      'isSelected',
      targetLPEdit.id_investor_representations_5 || false
    );
    setFormField(
      'id_investor_representations_6',
      'isSelected',
      targetLPEdit.id_investor_representations_6 || false
    );
    setFormField(
      'id_investor_representations_7',
      'isSelected',
      targetLPEdit.id_investor_representations_7 || false
    );
    setFormField(
      'id_investor_representations_8',
      'isSelected',
      targetLPEdit.id_investor_representations_8 || false
    );
    setFormField(
      'id_investor_representations_9',
      'isSelected',
      targetLPEdit.id_investor_representations_9 || false
    );
    setFormField(
      'id_investor_representations_or',
      'isSelected',
      targetLPEdit.id_investor_representations_or || false
    );

    setFormField(
      'id_purchase_representations_1',
      'isSelected',
      targetLPEdit.id_purchase_representations_1 || false
    );
    setFormField(
      'id_purchase_representations_2',
      'isSelected',
      targetLPEdit.id_purchase_representations_2 || false
    );
    setFormField(
      'id_purchase_representations_2',
      'isSelected',
      targetLPEdit.id_purchase_representations_2 || false
    );
    setFormField(
      'id_purchase_representations_3',
      'isSelected',
      targetLPEdit.id_purchase_representations_3 || false
    );
    setFormField(
      'id_purchase_representations_4',
      'isSelected',
      targetLPEdit.id_purchase_representations_4 || false
    );
    setFormField(
      'id_purchase_representations_5',
      'isSelected',
      targetLPEdit.id_purchase_representations_5 || false
    );
    setFormField(
      'id_purchase_representations_6',
      'isSelected',
      targetLPEdit.id_purchase_representations_6 || false
    );
    setFormField(
      'id_purchase_representations_7',
      'isSelected',
      targetLPEdit.id_purchase_representations_7 || false
    );
    setFormField(
      'id_purchase_representations_or',
      'isSelected',
      targetLPEdit.id_purchase_representations_or || false
    );

    setFormField(
      'id_client_representations_1',
      'isSelected',
      targetLPEdit.id_client_representations_1 || false
    );
    setFormField(
      'id_client_representations_2',
      'isSelected',
      targetLPEdit.id_client_representations_2 || false
    );
    setFormField(
      'id_client_representations_or',
      'isSelected',
      targetLPEdit.id_client_representations_or || false
    );

    setFormField(
      'id_foreign_person_1',
      'isSelected',
      targetLPEdit.id_foreign_person_1 || false
    );
    setFormField(
      'id_foreign_person_2',
      'isSelected',
      targetLPEdit.id_foreign_person_2 || false
    );
    setFormField(
      'id_foreign_person_3',
      'isSelected',
      targetLPEdit.id_foreign_person_3 || false
    );
    setFormField(
      'id_foreign_person_4',
      'isSelected',
      targetLPEdit.id_foreign_person_4 || false
    );
    setFormField(
      'id_foreign_person_or',
      'isSelected',
      targetLPEdit.id_foreign_person_or || false
    );

    setFormField(
      'id_foreign_person_2_1',
      'isSelected',
      targetLPEdit.id_foreign_person_2_1 || [false, false]
    );
    setFormField(
      'id_foreign_person_2_2',
      'isSelected',
      targetLPEdit.id_foreign_person_2_2 || false
    );
    setFormField(
      'id_foreign_person_2_or',
      'isSelected',
      targetLPEdit.id_foreign_person_2_or || false
    );

    setFormField(
      'id_wiring_bank_status_1',
      'isSelected',
      targetLPEdit.id_wiring_bank_status_1 || [false, false]
    );
    setFormField(
      'id_wiring_bank_status_1_opt',
      'value',
      targetLPEdit.id_wiring_bank_status_1_opt || ''
    );
    setFormField(
      'id_wiring_bank_status_2',
      'isSelected',
      targetLPEdit.id_wiring_bank_status_2 || [false, false]
    );

    setFormField(
      'id_special_entities_1_a',
      'isSelected',
      targetLPEdit.id_special_entities_1_a || [false, false]
    );
    setFormField(
      'id_special_entities_1_b',
      'isSelected',
      targetLPEdit.id_special_entities_1_b || [false, false]
    );
    setFormField(
      'id_special_entities_1_b_opt',
      'value',
      targetLPEdit.id_special_entities_1_b_opt || ''
    );
    setFormField(
      'id_special_entities_2_a',
      'isSelected',
      targetLPEdit.id_special_entities_2_a || [false, false]
    );
    setFormField(
      'id_special_entities_2_b',
      'isSelected',
      targetLPEdit.id_special_entities_2_b || [false, false]
    );
    setFormField(
      'id_special_entities_2_c',
      'isSelected',
      targetLPEdit.id_special_entities_2_c || [false, false]
    );
    setFormField(
      'id_special_entities_2_d',
      'isSelected',
      targetLPEdit.id_special_entities_2_d || [false, false]
    );
    setFormField(
      'id_special_entities_2_e_opt',
      'value',
      targetLPEdit.id_special_entities_2_e_opt
    );

    setFormField(
      'id_special_entities_3_a',
      'isSelected',
      targetLPEdit.id_special_entities_3_a || [false, false]
    );
    setFormField(
      'id_special_entities_4',
      'isSelected',
      targetLPEdit.id_special_entities_4 || [false, false]
    );
    setFormField(
      'id_special_entities_5',
      'isSelected',
      targetLPEdit.id_special_entities_5 || [false, false]
    );
    setFormField(
      'id_special_entities_6',
      'isSelected',
      targetLPEdit.id_special_entities_6 || [false, false]
    );
    setFormField(
      'id_special_entities_7',
      'isSelected',
      targetLPEdit.id_special_entities_7 || [false, false]
    );
    setFormField(
      'id_special_entities_8',
      'isSelected',
      targetLPEdit.id_special_entities_8 || [false, false]
    );

    setFormField(
      'id_special_entities_9_a',
      'isSelected',
      targetLPEdit.id_special_entities_9_a || [false, false]
    );
    setFormField(
      'id_special_entities_9_b',
      'isSelected',
      targetLPEdit.id_special_entities_9_b || [false, false]
    );
    setFormField(
      'id_special_entities_9_c',
      'isSelected',
      targetLPEdit.id_special_entities_9_c || [false, false]
    );

    setFormField(
      'id_warranties_exceptions',
      'value',
      targetLPEdit.id_warranties_exceptions
    );

    setAddInvestor(true);
    setAddAnotherContactPerson(
      targetLPEdit.additionalSecondaryNamePrincipalContact &&
        targetLPEdit.additionalSecondaryNamePrincipalContact.value !== ''
    );
    clearDisabledQuestions();
  };

  const clearDisabledQuestions = () => {
    const { setFormField } = props;
    const _questions = [
      ...questions.accreditedInvestorRepresentations,
      ...questions.qualifiedPurchaserRepresentations,
      ...questions.qualifiedClientRepresentations,
    ];
    for (const item of _questions) {
      if (checkDisabledQuestion(item.id)) {
        setFormField(item.id, 'isSelected', false);
      }
    }
    const _confirmQuestions = [
      ...questions.identificationofInvestorStatus,
      ...questions.wiringBankStatus,
      ...questions.identificationofSpecialEntities,
    ];
    for (const item of _confirmQuestions) {
      if (checkDisabledQuestion(item.id)) {
        setFormField(item.id, 'isSelected', [false, false]);
        const ePositive = document.getElementById(`${item.id}_yes`);
        const eNegative = document.getElementById(`${item.id}_no`);
        if (ePositive) ePositive.checked = false;
        if (eNegative) eNegative.checked = false;
      }
    }
  };

  const renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    helpText,
    disabled,
    placeholder
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: 'col-content',
      showHelp: helpText && helpText !== '',
      helpText,
      noTitle: !displayName,
      disabled,
      placeholder,
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={handlerChangeTextInput}
      />
    );
  };

  const renderDropdown = (
    id,
    value,
    title,
    displayName,
    size,
    handleChange,
    options,
    hasError,
    errorMessage
  ) => {
    const inputDataTemp = {
      id,
      value: value || '',
      displayName,
      title,
      size,
      options,
      hasError,
      errorMessage,
    };
    return (
      <Dropdown inputData={inputDataTemp} onChangeHandler={handleChange} />
    );
  };

  const handlerConfirm = (event) => {
    event.stopPropagation();
    const { setFormField } = props;
    const answer = event.target.nextSibling.innerHTML;
    const identifier = event.target.name;
    const negative = document.getElementById(`${identifier}_no`);
    const positive = document.getElementById(`${identifier}_yes`);
    if (answer === 'Yes') {
      if (negative && negative.checked) negative.checked = false;
      if (positive && !positive.checked) positive.click();
    } else if (answer === 'No') {
      if (positive && positive.checked) positive.checked = false;
      if (negative && !negative.checked) negative.click();
    }
    setFormField(identifier, 'isSelected', parseSelectedResponse(answer));
    clearSpecialEntitiesDisabledQuestions(identifier, answer);
  };

  const clearSpecialEntitiesDisabledQuestions = (identifier, answer) => {
    const { setFormField } = props;
    if (identifier === 'id_special_entities_9_a' && answer === 'No') {
      ['id_special_entities_9_b', 'id_special_entities_9_c'].forEach((item) => {
        const negative = document.getElementById(`${item}_no`);
        const positive = document.getElementById(`${item}_yes`);
        if (positive && positive.checked) positive.checked = false;
        if (negative && negative.checked) negative.checked = false;
        setFormField(item, 'isSelected', [false, false]);
      });
    }
  };

  const parseSelectedResponse = (answer) => {
    switch (answer) {
      case 'Yes':
        return [true, false];
      case 'No':
        return [false, true];
      default:
        return [false, false];
    }
  };

  const handleChangeFilterItem = (event) => {
    event.preventDefault();
    let index = '-1';
    switch (event.target.innerText) {
      case 'Name':
        index = '0';
        break;
      case 'Entity Type':
        index = '1';
        break;
      case 'Contact Person':
        index = '2';
        break;
      case 'Contact Email':
        index = '3';
        break;
      default:
        index = '-1';
        break;
    }
    if (sortByCol === index) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortByCol(index.toString());
      setSortDirection('desc');
    }
  };

  // eslint-disable-next-line
  const renderTableContents = () => {
    // eslint-disable-next-line react/prop-types
    const { organizationContext } = props;
    const rowActions = [];

    const headers = [
      {
        title: 'Name',
        class: 'low-table-item',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '0',
        filteringOrder: sortDirection,
      },
      {
        title: 'Entity Type',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '1',
        filteringOrder: sortDirection,
      },
      {
        title: 'Contact Person',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '2',
        filteringOrder: sortDirection,
      },
      {
        title: 'Contact Email',
        class: 'firm-table-item',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '3',
        filteringOrder: sortDirection,
      },
    ];

    const results = organizationContext.organization.limitedPartners.map(
      (lp) => [
        {
          id: 'name-res-col',
          displayName: lp.name,
          type: 'item',
          class: 'low-table-item',
        },
        {
          id: 'entity-res-col',
          displayName: lp.entityType,
          type: 'description',
        },
        {
          id: 'contact-res-col',
          displayName: lp.namePrincipalContact,
          type: 'description',
        },
        {
          id: 'email-res-col',
          displayName: lp.emailPrincipalContact,
          type: 'description',
          class: 'custom-table-item wrap-cell',
        },
        {
          id: 'edit-res-col',
          type: 'button',
          actionResult: true,
          customActionRender: 'link',
          customActionRenderDisplayName: 'Edit',
          actionResultIdentifier: lp._id,
          actionResultText: '',
          actionCallback: selectLPEdit,
          class: 'table-edit-button custom-margin-edit-button float-right',
        },
      ]
    );

    const sortData =
      sortByCol !== '-1' ? utility.gunderSort(results, sortByCol) : results;

    const paginationData = getInitialData(sortData, invsMaxItems);

    if (!invsPages) {
      setAllRecords(sortData);
      setInvsPages(getPages(paginationData.length, invsMaxItems) || []);
      setInvsLength(paginationData.length);
    }

    if (invsPage > 1) {
      paginationData.records = orderByPage(sortData, invsMaxItems, invsPage);
    }

    return {
      headers,
      results:
        sortDirection === 'desc'
          ? paginationData.records
          : paginationData.records.reverse(),
      rowActions,
    };
  };

  const setPaginationsItems = (event) => {
    const maxItems = event.target.value;
    setInvsMaxItems(maxItems);
    setInvsPage(1);
    setInvsPages(null);
    renderTableContents();
  };

  const selectPage = (page) => {
    setInvsPage(page);
    renderTableContents();
  };

  const checkDisabledQuestion = (identifier) => {
    const { entityType } = props.fundLimitedPartners.form;
    const isNaturalPerson =
      entityType.value === 'Natural Person' ||
      entityType.value === 'Joint Tenants with Right of Survivorship' ||
      entityType.value === 'IRA' ||
      entityType.value === 'Tenants in Common';
    if (entityType.value === '') {
      // if entity is not selected then gray out
      return true;
    }
    return isNaturalPerson
      ? naturalPersonDisabledList.includes(identifier)
      : entityDisabledList.includes(identifier);
  };

  const accreditedInvestorRepresentationsContent = () => {
    const {
      id_investor_representations_1,
      id_investor_representations_2,
      id_investor_representations_3,
      id_investor_representations_4,
      id_investor_representations_5,
      id_investor_representations_6,
      id_investor_representations_7,
      id_investor_representations_8,
      id_investor_representations_9,
      id_investor_representations_or,
    } = props.fundLimitedPartners.form;

    const accreditedInvestorRepresentationsQuestions = [
      {
        id: iqID.id_investor_representations_1,
        showHelp: true,
        helpText: id_investor_representations_1.helpText,
        displayName: id_investor_representations_1.displayName,
        isSelected: id_investor_representations_1.isSelected,
        name: '',
        identifier: 'Inv_Rep_1',
      },
      {
        id: iqID.id_investor_representations_2,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_2.displayName,
        isSelected: id_investor_representations_2.isSelected,
        name: '',
        identifier: 'Inv_Rep_2',
      },
      {
        id: iqID.id_investor_representations_3,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_3.displayName,
        isSelected: id_investor_representations_3.isSelected,
        name: '',
        identifier: 'Inv_Rep_3',
      },
      {
        id: iqID.id_investor_representations_4,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_4.displayName,
        isSelected: id_investor_representations_4.isSelected,
        name: '',
        identifier: 'Inv_Rep_4',
      },
      {
        id: iqID.id_investor_representations_5,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_5.displayName,
        isSelected: id_investor_representations_5.isSelected,
        name: '',
        identifier: 'Inv_Rep_5',
      },
      {
        id: iqID.id_investor_representations_6,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_6.displayName,
        isSelected: id_investor_representations_6.isSelected,
        name: '',
        identifier: 'Inv_Rep_6',
      },
      {
        id: iqID.id_investor_representations_7,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_7.displayName,
        isSelected: id_investor_representations_7.isSelected,
        name: '',
        identifier: 'Inv_Rep_7',
      },
      {
        id: iqID.id_investor_representations_8,
        showHelp: true,
        helpText: id_investor_representations_8.helpText,
        displayName: id_investor_representations_8.displayName,
        isSelected: id_investor_representations_8.isSelected,
        name: '',
        identifier: 'Inv_Rep_8',
      },
      {
        id: iqID.id_investor_representations_9,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_9.displayName,
        isSelected: id_investor_representations_9.isSelected,
        name: '',
        identifier: 'Inv_Rep_9',
      },
      {
        id: iqID.id_investor_representations_or,
        showHelp: false,
        helpText: '',
        displayName: id_investor_representations_or.displayName,
        isSelected: id_investor_representations_or.isSelected,
        name: '',
        identifier: 'acre-Or',
      },
    ];

    const results = [];
    for (
      let i = 0;
      i < accreditedInvestorRepresentationsQuestions.length;
      i += 1
    ) {
      results.push({
        id: accreditedInvestorRepresentationsQuestions[i].id,
        displayName: accreditedInvestorRepresentationsQuestions[i].displayName,
        name: accreditedInvestorRepresentationsQuestions[i].name,
        identifier: accreditedInvestorRepresentationsQuestions[i].identifier,
        isSelected: accreditedInvestorRepresentationsQuestions[i].isSelected,
        helpText: accreditedInvestorRepresentationsQuestions[i].helpText,
        showHelp: accreditedInvestorRepresentationsQuestions[i].showHelp,
        disabled: checkDisabledQuestion(
          accreditedInvestorRepresentationsQuestions[i].id
        ),
      });
    }
    return results;
  };

  const qualifiedPurchaserRepresentationsContent = () => {
    const {
      id_purchase_representations_1,
      id_purchase_representations_2,
      id_purchase_representations_3,
      id_purchase_representations_4,
      id_purchase_representations_5,
      id_purchase_representations_6,
      id_purchase_representations_7,
      id_purchase_representations_or,
    } = props.fundLimitedPartners.form;

    const qualifiedPurchaserRepresentations = [
      {
        id: iqID.id_purchase_representations_1,
        showHelp: true,
        helpText: id_purchase_representations_1.helpText,
        displayName: id_purchase_representations_1.displayName,
        isSelected: id_purchase_representations_1.isSelected,
        name: '',
        identifier: 'Pur_Rep_1',
      },
      {
        id: iqID.id_purchase_representations_2,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_2.displayName,
        isSelected: id_purchase_representations_2.isSelected,
        name: '',
        identifier: 'Pur_Rep_2',
      },
      {
        id: iqID.id_purchase_representations_3,
        showHelp: true,
        helpText: id_purchase_representations_3.helpText,
        displayName: id_purchase_representations_3.displayName,
        isSelected: id_purchase_representations_3.isSelected,
        name: '',
        identifier: 'Pur_Rep_3',
      },
      {
        id: iqID.id_purchase_representations_4,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_4.displayName,
        isSelected: id_purchase_representations_4.isSelected,
        name: '',
        identifier: 'Pur_Rep_4',
      },
      {
        id: iqID.id_purchase_representations_5,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_5.displayName,
        isSelected: id_purchase_representations_5.isSelected,
        name: '',
        identifier: 'Pur_Rep_5',
      },
      {
        id: iqID.id_purchase_representations_6,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_6.displayName,
        isSelected: id_purchase_representations_6.isSelected,
        name: '',
        identifier: 'Pur_Rep_6',
      },
      {
        id: iqID.id_purchase_representations_7,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_7.displayName,
        isSelected: id_purchase_representations_7.isSelected,
        name: '',
        identifier: 'Pur_Rep_7',
      },
      {
        id: iqID.id_purchase_representations_or,
        showHelp: false,
        helpText: '',
        displayName: id_purchase_representations_or.displayName,
        isSelected: id_purchase_representations_or.isSelected,
        name: '',
        identifier: 'pur-or',
      },
    ];

    const results = [];
    for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
      results.push({
        id: qualifiedPurchaserRepresentations[i].id,
        displayName: qualifiedPurchaserRepresentations[i].displayName,
        name: qualifiedPurchaserRepresentations[i].name,
        identifier: qualifiedPurchaserRepresentations[i].identifier,
        isSelected: qualifiedPurchaserRepresentations[i].isSelected,
        helpText: qualifiedPurchaserRepresentations[i].helpText,
        showHelp: qualifiedPurchaserRepresentations[i].showHelp,
        disabled: checkDisabledQuestion(
          qualifiedPurchaserRepresentations[i].id
        ),
      });
    }
    return results;
  };

  const qualifiedClientRepresentationsContent = () => {
    const {
      id_client_representations_1,
      id_client_representations_2,
      id_client_representations_or,
    } = props.fundLimitedPartners.form;

    const qualifiedClientRepresentations = [
      {
        id: iqID.id_client_representations_1,
        showHelp: false,
        helpText: '',
        displayName: id_client_representations_1.displayName,
        isSelected: id_client_representations_1.isSelected,
        name: '',
        identifier: 'Cli_Rep_1',
      },
      {
        id: iqID.id_client_representations_2,
        showHelp: true,
        helpText: id_client_representations_2.helpText,
        displayName: id_client_representations_2.displayName,
        isSelected: id_client_representations_2.isSelected,
        name: '',
        identifier: 'Cli_Rep_2',
      },
      {
        id: iqID.id_client_representations_or,
        showHelp: false,
        helpText: '',
        displayName: id_client_representations_or.displayName,
        isSelected: id_client_representations_or.isSelected,
        name: '',
        identifier: 'cli-or',
      },
    ];

    const results = [];
    for (let i = 0; i < qualifiedClientRepresentations.length; i += 1) {
      results.push({
        id: qualifiedClientRepresentations[i].id,
        displayName: qualifiedClientRepresentations[i].displayName,
        name: qualifiedClientRepresentations[i].name,
        identifier: qualifiedClientRepresentations[i].identifier,
        isSelected: qualifiedClientRepresentations[i].isSelected,
        helpText: qualifiedClientRepresentations[i].helpText,
        showHelp: qualifiedClientRepresentations[i].showHelp,
        disabled: !showClientRepresentations,
      });
    }
    return results;
  };

  const foreignPersonStatusRepresentationsContent = () => {
    const {
      id_foreign_person_1,
      id_foreign_person_2,
      id_foreign_person_3,
      id_foreign_person_4,
      id_foreign_person_or,
    } = props.fundLimitedPartners.form;

    const foreignPersonStatusRepresentations = [
      {
        id: iqID.id_foreign_person_1,
        showHelp: false,
        helpText: '',
        displayName: id_foreign_person_1.displayName,
        isSelected: id_foreign_person_1.isSelected,
        name: '',
        identifier: 'For_Per_Stat_1',
      },
      {
        id: iqID.id_foreign_person_2,
        showHelp: false,
        displayName: id_foreign_person_2.displayName,
        isSelected: id_foreign_person_2.isSelected,
        name: '',
        identifier: 'For_Per_Stat_2',
      },
      {
        id: iqID.id_foreign_person_3,
        showHelp: false,
        displayName: id_foreign_person_3.displayName,
        isSelected: id_foreign_person_3.isSelected,
        name: '',
        identifier: 'For_Per_Stat_3',
      },
      {
        id: iqID.id_foreign_person_4,
        showHelp: false,
        displayName: id_foreign_person_4.displayName,
        isSelected: id_foreign_person_4.isSelected,
        name: '',
        identifier: 'For_Per_Stat_4',
      },
      {
        id: iqID.id_foreign_person_or,
        showHelp: false,
        helpText: '',
        displayName: id_foreign_person_or.displayName,
        isSelected: id_foreign_person_or.isSelected,
        name: '',
        identifier: 'cli-For_Per_Stat_or',
      },
    ];

    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations[i].id,
        displayName: foreignPersonStatusRepresentations[i].displayName,
        name: foreignPersonStatusRepresentations[i].name,
        identifier: foreignPersonStatusRepresentations[i].identifier,
        isSelected: foreignPersonStatusRepresentations[i].isSelected,
        helpText: foreignPersonStatusRepresentations[i].helpText,
        showHelp: foreignPersonStatusRepresentations[i].showHelp,
        disabled: checkDisabledQuestion(
          foreignPersonStatusRepresentations[i].id
        ),
      });
    }
    return results;
  };

  const foreignPersonStatusRepresentationsContent2 = () => {
    const {
      id_foreign_person_2_1,
      id_foreign_person_2_2,
      id_foreign_person_2_or,
    } = props.fundLimitedPartners.form;

    const foreignPersonStatusRepresentations2 = [
      {
        id: iqID.id_foreign_person_2_1,
        showHelp: false,
        helpText: '',
        displayName: id_foreign_person_2_1.displayName,
        isSelected: id_foreign_person_2_1.isSelected,
        name: '',
        identifier: 'For_Per_Stat_2_1',
      },
      {
        id: iqID.id_foreign_person_2_2,
        showHelp: false,
        displayName: id_foreign_person_2_2.displayName,
        isSelected: id_foreign_person_2_2.isSelected,
        name: '',
        identifier: 'For_Per_Stat_2_2',
      },
      {
        id: iqID.id_foreign_person_2_or,
        showHelp: false,
        helpText: '',
        displayName: id_foreign_person_2_or.displayName,
        isSelected: id_foreign_person_2_or.isSelected,
        name: '',
        identifier: 'cli-For_Per_Stat_2_or',
      },
    ];

    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations2.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations2[i].id,
        displayName: foreignPersonStatusRepresentations2[i].displayName,
        name: foreignPersonStatusRepresentations2[i].name,
        identifier: foreignPersonStatusRepresentations2[i].identifier,
        isSelected: foreignPersonStatusRepresentations2[i].isSelected,
        helpText: foreignPersonStatusRepresentations2[i].helpText,
        showHelp: foreignPersonStatusRepresentations2[i].showHelp,
        disabled: checkDisabledQuestion(
          foreignPersonStatusRepresentations2[i].id
        ),
      });
    }
    return results;
  };

  const removeSecondaryContactPerson = (e) => {
    e.preventDefault();
    const { setFormField } = props;

    setFormField('additionalSecondaryNamePrincipalContact', 'value', '');
    setFormField('additionalSecondaryEmailPrincipalContact', 'value', '');
    setFormField('additionalSecondaryPhonePrincipalContact', 'value', '');

    setFormField('responsabilitesSecondaryQuarterly', 'isSelected', false);
    setFormField('responsabilitesSecondaryTaxInfo', 'isSelected', false);
    setFormField('responsabilitesSecondaryCapitalCall', 'isSelected', false);
    setFormField('responsabilitesSecondaryCashAndStock', 'isSelected', false);
    setFormField('responsabilitesSecondaryLegalDocuments', 'isSelected', false);

    setAddAnotherContactPerson(false);
  };

  const getIsEntity = (chosenEntity) => {
    let { entityType } = props.fundLimitedPartners.form;
    if (chosenEntity) {
      entityType.value = chosenEntity;
    }

    return (
      entityType.value !== 'Natural Person' &&
      entityType.value !== 'IRA' &&
      entityType.value !== 'Joint Tenants with Right of Survivorship' &&
      entityType.value !== 'Tenants in Common'
    );
  };

  const checkQuestionProgress = () => {
    let acc = 0;

    const investorRepresentations = Object.values(
      props.fundLimitedPartners.form
    ).filter(
      (e) => e.type === 'investor_representations' && e.isSelected === true
    );
    const purchaseRepresentations = Object.values(
      props.fundLimitedPartners.form
    ).filter(
      (e) => e.type === 'purchase_representations' && e.isSelected === true
    );
    const clientRepresentations = Object.values(
      props.fundLimitedPartners.form
    ).filter(
      (e) => e.type === 'client_representations' && e.isSelected === true
    );

    const foreignPerson1 = Object.values(props.fundLimitedPartners.form).filter(
      (e) => e.type === 'foreign_person_1' && e.isSelected === true
    );
    const foreignPerson2 = Object.values(props.fundLimitedPartners.form).filter(
      (e) => e.type === 'foreign_person_2' && e.isSelected === true
    );

    if (investorRepresentations.length > 0) acc += 1;
    if (purchaseRepresentations.length > 0) acc += 1;
    if (showClientRepresentations && clientRepresentations.length > 0) acc += 1;
    if (foreignPerson1.length > 0) acc += 1;
    if (foreignPerson2.length > 0) acc += 1;
    Object.entries(props.fundLimitedPartners.form).map(([key, item]) => {
      const entityTypeSelected =
        props.fundLimitedPartners.form.entityType.value !== '';
      if (
        item.templateItem &&
        !item.optional &&
        item.value &&
        item.value !== ''
      ) {
        // when type is not selected do not count the place if already filled in
        const locationFieldKeysNatural = [
          'country',
          'countryPlace',
          'principalPlaceOfResidencePrimaryContact',
        ];
        const locationFieldKeysEntity = [
          'countryPlace',
          'principalPlaceOfBusinessPrimaryContact',
        ];
        if (entityTypeSelected) {
          if (
            getIsEntity() &&
            locationFieldKeysEntity.includes(key) &&
            item.value !== ''
          ) {
            if (key === 'principalPlaceOfBusinessPrimaryContact') {
              // only count if country is USA or Canada
              const country = props.fundLimitedPartners.form.countryPlace.value;
              if (country === CANADA || country === USA) {
                acc += 1;
              }
            } else {
              acc += 1;
            }
          } else if (
            !getIsEntity() &&
            locationFieldKeysNatural.includes(key) &&
            item.value !== ''
          ) {
            if (key === 'principalPlaceOfResidencePrimaryContact') {
              // only count if country is USA or Canada
              const country = props.fundLimitedPartners.form.countryPlace.value;
              if (country === CANADA || country === USA) {
                acc += 1;
              }
            } else {
              acc += 1;
            }
          }
        }
        // this fields have been saved already and not related to the location
        if (
          !locationFieldKeysEntity.includes(key) &&
          !locationFieldKeysNatural.includes(key)
        ) {
          acc += 1;
        }
      }
      if (
        item.templateItem &&
        !item.optional &&
        item.isSelected &&
        (item.isSelected[0] === true || item.isSelected[1] === true)
      ) {
        // confirms and regular checks
        acc += 1;
      }
      // item has to be selected or have a value
      if (
        item.optional &&
        ((item.isSelected &&
          (item.isSelected[0] === true || item.isSelected[1] === true)) ||
          (item.value && item.value !== ''))
      ) {
        // if optional it has to meet criteria to count
        // verbose logic here should be updated / optimized
        const optionalKeys = [
          'id_wiring_bank_status_1_opt',
          'id_special_entities_1_b_opt',
          'id_special_entities_2_e_opt',
          'id_special_entities_9_b',
          'id_special_entities_9_c',
          'id_investor_status_5_f',
          'otherValue',
        ];

        if (optionalKeys.includes(key)) {
          // check logic for each optional to see if it should count or not
          if (
            key === 'otherValue' &&
            props.fundLimitedPartners.form.entityType.value === 'Other'
          ) {
            acc += 1;
          }
          if (key === 'id_wiring_bank_status_1_opt' && showWiringBankOptional) {
            acc += 1;
          }
          if (key === 'id_investor_status_5_f' && showInvestorStatus5F) {
            acc += 1;
          }
          if (key === 'id_special_entities_1_b_opt' && showErisaOptional) {
            acc += 1;
          }
          if (
            key === 'id_special_entities_2_e_opt' &&
            showPotentialLookthroughOptional
          ) {
            acc += 1;
          }
          if (
            (key === 'id_special_entities_9_b' ||
              key === 'id_special_entities_9_c') &&
            showFlowthroughOptional
          ) {
            acc += 1;
          }
        }
      }
    });
    setCompletedQuestionCount(acc);
  };

  const getIdentificationOfInvestorStatusSection = () => {
    const { fundLimitedPartners } = props;
    return (
      <div className="row custom-margin-flex-columns-questions">
        <div className="row">
          {renderConfirm(
            [
              `${iqID.id_investor_status_1}_yes`,
              `${iqID.id_investor_status_1}_no`,
            ],
            '1. Natural Person: Is the undersigned a natural person? (For clarity, the Investor is a “natural person” if the Investor is an individual investing through a personal account, and not through a trust, LLC, partnership or other entity. If the investment is being made through an entity, the Investor is not a natural person.)',
            checkDisabledQuestion('id_investor_status_1')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_1.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_1.isSelected[1],
                ],
            'id_investor_status_1',
            'large',
            '',
            handlerConfirm,
            [false, false]
          )}
          {renderConfirm(
            [
              `${iqID.id_investor_status_2}_yes`,
              `${iqID.id_investor_status_2}_no`,
            ],
            '2. U.S. Person (U.S. Securities Act): Is the undersigned a “U.S. Person” as defined in Rule 902(k) promulgated under the United States Securities Act of 1933, as amended (the “Securities Act”)?',
            checkDisabledQuestion('id_investor_status_2')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_2.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_2.isSelected[1],
                ],
            'id_investor_status_2',
            'large',
            '',
            handlerConfirm,
            [
              checkDisabledQuestion('id_investor_status_2'),
              checkDisabledQuestion('id_investor_status_2'),
            ]
          )}
          {renderConfirm(
            [
              `${iqID.id_investor_status_3}_yes`,
              `${iqID.id_investor_status_3}_no`,
            ],
            '3. U.S. Person (U.S. Internal Revenue Code): Is the undersigned a “United States person” as defined in section 7701(a)(30) of the Internal Revenue Code?',
            checkDisabledQuestion('id_investor_status_3')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_3.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_3.isSelected[1],
                ],
            'id_investor_status_3',
            'large',
            '',
            handlerConfirm,
            [
              checkDisabledQuestion('id_investor_status_3'),
              checkDisabledQuestion('id_investor_status_3'),
            ]
          )}
          {renderConfirm(
            [
              `${iqID.id_investor_status_4}_yes`,
              `${iqID.id_investor_status_4}_no`,
            ],
            '4. FINRA Affiliation: Is the undersigned (a) a member of the Financial Industry Regulatory Authority (“FINRA,” which was formerly known as the National Association of Securities Dealers or NASD), (b) a person affiliated with a FINRA member, (c) a person associated with a FINRA member or (d) a person directly or indirectly engaged in the securities business as a broker, dealer or underwriter, whether as an employee, director, partner, registered representative or otherwise?',
            checkDisabledQuestion('id_investor_status_4')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_4.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_4.isSelected[1],
                ],
            'id_investor_status_4',
            'large',
            '',
            handlerConfirm,
            [
              checkDisabledQuestion('id_investor_status_4'),
              checkDisabledQuestion('id_investor_status_4'),
            ]
          )}
        </div>
        <p>5. FOIA Partner / Public Disclosure Laws:</p>
        {renderConfirm(
          [
            `${iqID.id_investor_status_5_a}_yes`,
            `${iqID.id_investor_status_5_a}_no`,
          ],
          `(a). Is the undersigned a person that is directly or
                  indirectly subject to either section 552(a) of Title 5, United
                  States Code (commonly known as the “Freedom of Information
                  Act”) or any similar federal, state, county or municipal
                  public disclosure law, whether foreign or domestic?:`,
          checkDisabledQuestion('id_investor_status_5_a')
            ? [false, false]
            : [
                fundLimitedPartners.form.id_investor_status_5_a.isSelected[0],
                fundLimitedPartners.form.id_investor_status_5_a.isSelected[1],
              ],
          'id_investor_status_5_a',
          'large',
          '',
          handlerConfirm,
          [
            checkDisabledQuestion('id_investor_status_5_a'),
            checkDisabledQuestion('id_investor_status_5_a'),
          ]
        )}
        {renderConfirm(
          [
            `${iqID.id_investor_status_5_b}_yes`,
            `${iqID.id_investor_status_5_b}_no`,
          ],
          `(b). Is the undersigned a person that is subject, by regulation, contract or otherwise, to disclose Fund information due to regulatory or trading exchange or other market requirements where interests in such Person are registered, regulated, sold or traded, whether foreign or domestic?`,
          checkDisabledQuestion('id_investor_status_5_b')
            ? [false, false]
            : [
                fundLimitedPartners.form.id_investor_status_5_b.isSelected[0],
                fundLimitedPartners.form.id_investor_status_5_b.isSelected[1],
              ],
          'id_investor_status_5_b',
          'large',
          '',
          handlerConfirm,
          [
            checkDisabledQuestion('id_investor_status_5_b'),
            checkDisabledQuestion('id_investor_status_5_b'),
          ]
        )}
        {renderConfirm(
          [
            `${iqID.id_investor_status_5_c}_yes`,
            `${iqID.id_investor_status_5_c}_no`,
          ],
          `(c). Is the undersigned a person that is required to or will likely be required to disclose Fund information to a governmental body, agency or committee (including, without limitation, any disclosures required in accordance with the Ethics in Government Act of 1978, as amended, and any rules and regulations of any executive, legislative or judiciary organization), whether foreign or domestic, by virtue of such person’s (or any of its affiliate’s) current or proposed involvement in government office?`,
          checkDisabledQuestion('id_investor_status_5_c')
            ? [false, false]
            : [
                fundLimitedPartners.form.id_investor_status_5_c.isSelected[0],
                fundLimitedPartners.form.id_investor_status_5_c.isSelected[1],
              ],
          'id_investor_status_5_c',
          'large',
          '',
          handlerConfirm,
          [
            checkDisabledQuestion('id_investor_status_5_c'),
            checkDisabledQuestion('id_investor_status_5_c'),
          ]
        )}
        {renderConfirm(
          [
            `${iqID.id_investor_status_5_d}_yes`,
            `${iqID.id_investor_status_5_d}_no`,
          ],
          `(d). Is the undersigned a person that is an agent, nominee, fiduciary, custodian or trustee for any person described in the preceding questions 5(a)-(c) where Fund information provided or disclosed to the undersigned, as a limited partner of the Fund, by or on behalf of the Fund, its General Partner or its management company is provided or could at any time become available to such person described by the preceding questions 5(a)-(c)?`,
          checkDisabledQuestion('id_investor_status_5_d')
            ? [false, false]
            : [
                fundLimitedPartners.form.id_investor_status_5_d.isSelected[0],
                fundLimitedPartners.form.id_investor_status_5_d.isSelected[1],
              ],
          'id_investor_status_5_d',
          'large',
          '',
          handlerConfirm,
          [
            checkDisabledQuestion('id_investor_status_5_d'),
            checkDisabledQuestion('id_investor_status_5_d'),
          ]
        )}
        {renderConfirm(
          [
            `${iqID.id_investor_status_5_e}_yes`,
            `${iqID.id_investor_status_5_e}_no`,
          ],
          `(e). Is the undersigned a person that is an investment fund or other entity that has any person described in the preceding questions 5(a)-(d) as a partner, member or other beneficial owner where Fund information provided or disclosed to the undersigned, as a limited partner of the Fund, by or on behalf of the Fund, its General Partner or its management company is disclosed to or could at any time become available to such person described by the preceding questions 5(a)-(d)?`,
          checkDisabledQuestion('id_investor_status_5_e')
            ? [false, false]
            : [
                fundLimitedPartners.form.id_investor_status_5_e.isSelected[0],
                fundLimitedPartners.form.id_investor_status_5_e.isSelected[1],
              ],
          'id_investor_status_5_e',
          'large',
          '',
          handlerConfirm,
          [
            checkDisabledQuestion('id_investor_status_5_e'),
            checkDisabledQuestion('id_investor_status_5_e'),
          ]
        )}
        {/* Only show this is any from 5a to 5e is yes */}
        {showInvestorStatus5F &&
          renderConfirm(
            [
              `${iqID.id_investor_status_5_f}_yes`,
              `${iqID.id_investor_status_5_f}_no`,
            ],
            `(f). If the answer to any of 5(a)-(e) above is “YES,” does the undersigned reasonably believe (based on the advice of counsel) that it would not be required by law to disclose any Portfolio Company Information that is provided to the undersigned by the Fund, the General Partner or any affiliate or agent thereof? (For clarity, a “No” answer indicates that the undersigned reasonably believes it could be required by law to disclose Portfolio Company Information.)`,
            checkDisabledQuestion('id_investor_status_5_f')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_5_f.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_5_f.isSelected[1],
                ],
            'id_investor_status_5_f',
            'large',
            '',
            handlerConfirm,
            [
              checkDisabledQuestion('id_investor_status_5_f'),
              checkDisabledQuestion('id_investor_status_5_f'),
            ]
          )}
        <div className="row">
          {renderConfirm(
            [
              `${iqID.id_investor_status_6}_yes`,
              `${iqID.id_investor_status_6}_no`,
            ],
            `6. “Bad Actor” Disqualification: Is the undersigned subject to
                “Bad Actor” disqualification, as such term is used in Rule
                506(d) of Regulation D promulgated by the Securities and
                Exchange Commission?`,
            checkDisabledQuestion('id_investor_status_6')
              ? [false, false]
              : [
                  fundLimitedPartners.form.id_investor_status_6.isSelected[0],
                  fundLimitedPartners.form.id_investor_status_6.isSelected[1],
                ],
            'id_investor_status_6',
            'large',
            '',
            handlerConfirm,
            [
              checkDisabledQuestion('id_investor_status_6'),
              checkDisabledQuestion('id_investor_status_6'),
            ]
          )}
        </div>
      </div>
    );
  };

  const renderAddInvestorLayout = () => {
    const { fundLimitedPartners } = props;
    const { isEntity } = props;
    return (
      <FormLayout
        cancelRoute="/organization/limitedPartners"
        description="Create a new investor to be used in submitting investor questionnaires for new funds. All of the information provided here will be stored and used to pre-populate new investor questionnaires. Some funds may include questions not asked here in their investor questionnaire, but most of your answers should apply - allowing you to reduce the time to complete necessary documents for all future funds. THE ONLY REQUIRED SECTIONS ARE: GENERAL INFORMATION AND PRINCIPAL CONTACT INFORMATION. The rest can be filled out when you log in at the fund-specific link."
        onCancel={() => {
          confirmAlert({
            title: 'Are you sure?',
            message: 'Your changes will be lost.',
            buttons: [
              {
                label: 'Confirm',
                onClick: () => setAddInvestor(false),
              },
              {
                label: 'Cancel',
                onClick: () => {},
              },
            ],
          });
        }}
        onSubmit={isEditingLP ? editLimitedPartner : formSubmitV2}
        questionsProgress={{
          completed: completedQuestionCount,
          total: requiredQuestionCount,
        }}
        title={`${isEditingLP ? 'Edit' : 'Create New'} Investor`}
      >
        {({ FormSection }) => (
          <form>
            <FormSection title="General Information">
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'name',
                  'name',
                  'text',
                  fundLimitedPartners.form.name.value,
                  'Name of Investor',
                  true,
                  'large',
                  !fundLimitedPartners.form.name.valid,
                  fundLimitedPartners.form.name.errorMessage,
                  ''
                  // true : disabled
                )}
                {renderDropdown(
                  'entityType',
                  fundLimitedPartners.form.entityType.value,
                  'Entity Type',
                  'Select entity',
                  'large',
                  handleDropdownChange,
                  entities.map((entity) => ({
                    id: entity.identifier,
                    value: entity.displayName,
                  })),
                  !fundLimitedPartners.form.entityType.valid,
                  fundLimitedPartners.form.entityType.errorMessage
                )}
                <div className="custom-padding-text-input">
                  {fundLimitedPartners.form.entityType.value === 'Other' &&
                    renderTextInput(
                      'otherValue',
                      'otherValue',
                      'text',
                      fundLimitedPartners.form.otherValue.value,
                      '',
                      true,
                      'large',
                      !fundLimitedPartners.form.otherValue.valid,
                      fundLimitedPartners.form.otherValue.errorMessage,
                      'enter "Other" entity'
                      // true : disabled
                    )}
                </div>

                {!getIsEntity() &&
                fundLimitedPartners.form.entityType.value !== '' ? (
                  <Dropdown
                    inputData={{
                      id: 'country',
                      name: 'country',
                      value: fundLimitedPartners.form.country.value,
                      displayName: '',
                      title: 'Country of Citizenship',
                      size: 'large',
                      options: countries.map((item) => {
                        return {
                          id: item.code,
                          value: item.name,
                        };
                      }),
                      hasError: !fundLimitedPartners.form.country.valid,
                      errorMessage:
                        fundLimitedPartners.form.country.errorMessage,
                      showHelp: false,
                      helpText: '',
                    }}
                    onChangeHandler={handlerChangeTextInput}
                    //onBlurHandler={handleSaveFieldOnBlur}
                  />
                ) : null}

                {!getIsEntity() &&
                fundLimitedPartners.form.entityType.value !== '' ? (
                  <Dropdown
                    inputData={{
                      id: 'countryPlace',
                      name: 'countryPlace',
                      value: fundLimitedPartners.form.countryPlace.value,
                      displayName: '',
                      title: 'Principal Place of Residence',
                      size: 'large',
                      options: countries.map((item) => {
                        return {
                          id: item.code,
                          value: item.name,
                        };
                      }),
                      hasError: !fundLimitedPartners.form.countryPlace.valid,
                      errorMessage:
                        fundLimitedPartners.form.countryPlace.errorMessage,
                      showHelp: false,
                      helpText: '',
                    }}
                    onChangeHandler={handlerChangeTextInput}
                    //onBlurHandler={handleSaveFieldOnBlur}
                  />
                ) : null}
                {getIsEntity() &&
                fundLimitedPartners.form.entityType.value !== '' ? (
                  <Dropdown
                    inputData={{
                      id: 'countryPlace',
                      name: 'countryPlace',
                      value: fundLimitedPartners.form.countryPlace.value,
                      displayName: '',
                      title: 'Principal Place of Business',
                      size: 'large',
                      options: countries.map((item) => {
                        return {
                          id: item.code,
                          value: item.name,
                        };
                      }),
                      hasError: !fundLimitedPartners.form.countryPlace.valid,
                      errorMessage:
                        fundLimitedPartners.form.countryPlace.errorMessage,
                      showHelp: false,
                      helpText: '',
                    }}
                    onChangeHandler={handlerChangeTextInput}
                    // onBlurHandler={handleSaveFieldOnBlur}
                  />
                ) : null}

                {getIsEntity() &&
                fundLimitedPartners.form.entityType.value !== '' &&
                (fundLimitedPartners.form.countryPlace.value === USA ||
                  fundLimitedPartners.form.countryPlace.value === CANADA) ? (
                  <Dropdown
                    inputData={{
                      id: 'principalPlaceOfBusinessPrimaryContact',
                      name: 'principalPlaceOfBusinessPrimaryContact',
                      value:
                        fundLimitedPartners.form
                          .principalPlaceOfBusinessPrimaryContact.value,
                      displayName: 'State/Province',
                      title: '',
                      size: 'large',
                      options: getTwoLetterStates(),
                      hasError:
                        !fundLimitedPartners.form
                          .principalPlaceOfBusinessPrimaryContact.valid,
                      errorMessage:
                        fundLimitedPartners.form
                          .principalPlaceOfBusinessPrimaryContact.errorMessage,
                    }}
                    onChangeHandler={handlerChangeTextInput}
                    // onBlurHandler={handleSaveFieldOnBlur}
                  />
                ) : null}
                {!getIsEntity() &&
                fundLimitedPartners.form.entityType.value !== '' &&
                (fundLimitedPartners.form.countryPlace.value === USA ||
                  fundLimitedPartners.form.countryPlace.value === CANADA) ? (
                  <Dropdown
                    inputData={{
                      id: 'principalPlaceOfResidencePrimaryContact',
                      name: 'principalPlaceOfResidencePrimaryContact',
                      value:
                        fundLimitedPartners.form
                          .principalPlaceOfResidencePrimaryContact.value,
                      displayName: 'State/Province',
                      title: '',
                      size: 'large',
                      options: getTwoLetterStates(),
                      hasError:
                        !fundLimitedPartners.form
                          .principalPlaceOfResidencePrimaryContact.valid,
                      errorMessage:
                        fundLimitedPartners.form
                          .principalPlaceOfResidencePrimaryContact.errorMessage,
                    }}
                    onChangeHandler={handlerChangeTextInput}
                    // onBlurHandler={handleSaveFieldOnBlur}
                  />
                ) : null}
              </div>
            </FormSection>

            <FormSection title="Principal Contact Information">
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'namePrincipalContact',
                  'namePrincipalContact',
                  'text',
                  fundLimitedPartners.form.namePrincipalContact.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.namePrincipalContact.valid,
                  fundLimitedPartners.form.namePrincipalContact.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'emailPrincipalContact',
                  'emailPrincipalContact',
                  'text',
                  fundLimitedPartners.form.emailPrincipalContact.value,
                  'Email Address',
                  true,
                  'large',
                  !fundLimitedPartners.form.emailPrincipalContact.valid,
                  fundLimitedPartners.form.emailPrincipalContact.errorMessage,
                  'Most communications will be sent via email'
                )}
                {renderTextInput(
                  'phonePrincipalContact',
                  'phonePrincipalContact',
                  'text',
                  fundLimitedPartners.form.phonePrincipalContact.value,
                  'Phone Number',
                  true,
                  'large',
                  !fundLimitedPartners.form.phonePrincipalContact.valid,
                  fundLimitedPartners.form.phonePrincipalContact.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'addressPrincipalContact',
                  'addressPrincipalContact',
                  'text',
                  fundLimitedPartners.form.addressPrincipalContact.value,
                  'Address',
                  true,
                  'large',
                  !fundLimitedPartners.form.addressPrincipalContact.valid,
                  fundLimitedPartners.form.addressPrincipalContact.errorMessage,
                  '',
                  '',
                  'Street Address'
                )}
              </div>
            </FormSection>

            <FormSection title="Additional Contact Information for Investor (OPTIONAL)">
              <div className="row custom-margin-flex-columns margin-bottom-spacing">
                <h6>Quarterly and Annual Reports</h6>
                {renderTextInput(
                  'reportsPrimaryContactName',
                  'reportsPrimaryContactName',
                  'text',
                  fundLimitedPartners.form.reportsPrimaryContactName.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.reportsPrimaryContactName.valid,
                  fundLimitedPartners.form.reportsPrimaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'reportsPrimaryContactEmail',
                  'reportsPrimaryContactEmail',
                  'text',
                  fundLimitedPartners.form.reportsPrimaryContactEmail.value,
                  'Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.reportsPrimaryContactEmail.valid,
                  fundLimitedPartners.form.reportsPrimaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'reportsPrimaryContactPhone',
                  'reportsPrimaryContactPhone',
                  'text',
                  fundLimitedPartners.form.reportsPrimaryContactPhone.value,
                  'Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.reportsPrimaryContactPhone.valid,
                  fundLimitedPartners.form.reportsPrimaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'reportsSecondaryContactName',
                  'reportsSecondaryContactName',
                  'text',
                  fundLimitedPartners.form.reportsSecondaryContactName.value,
                  'Secondary Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.reportsSecondaryContactName.valid,
                  fundLimitedPartners.form.reportsSecondaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'reportsSecondaryContactEmail',
                  'reportsSecondaryContactEmail',
                  'text',
                  fundLimitedPartners.form.reportsSecondaryContactEmail.value,
                  'Secondary Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.reportsSecondaryContactEmail.valid,
                  fundLimitedPartners.form.reportsSecondaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'reportsSecondaryContactPhone',
                  'reportsSecondaryContactPhone',
                  'text',
                  fundLimitedPartners.form.reportsSecondaryContactPhone.value,
                  'Secondary Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.reportsSecondaryContactPhone.valid,
                  fundLimitedPartners.form.reportsSecondaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>

              <div className="row custom-margin-flex-columns margin-bottom-spacing">
                <hr />
                <h6>Tax Information / K-1s</h6>
                {renderTextInput(
                  'taxPrimaryContactName',
                  'taxPrimaryContactName',
                  'text',
                  fundLimitedPartners.form.taxPrimaryContactName.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.taxPrimaryContactName.valid,
                  fundLimitedPartners.form.taxPrimaryContactName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'taxPrimaryContactEmail',
                  'taxPrimaryContactEmail',
                  'text',
                  fundLimitedPartners.form.taxPrimaryContactEmail.value,
                  'Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.taxPrimaryContactEmail.valid,
                  fundLimitedPartners.form.taxPrimaryContactEmail.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'taxPrimaryContactPhone',
                  'taxPrimaryContactPhone',
                  'text',
                  fundLimitedPartners.form.taxPrimaryContactPhone.value,
                  'Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.taxPrimaryContactPhone.valid,
                  fundLimitedPartners.form.taxPrimaryContactPhone.errorMessage,
                  ''
                )}
              </div>
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'taxSecondaryContactName',
                  'taxSecondaryContactName',
                  'text',
                  fundLimitedPartners.form.taxSecondaryContactName.value,
                  'Secondary Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.taxSecondaryContactName.valid,
                  fundLimitedPartners.form.taxSecondaryContactName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'taxSecondaryContactEmail',
                  'taxSecondaryContactEmail',
                  'text',
                  fundLimitedPartners.form.taxSecondaryContactEmail.value,
                  'Secondary Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.taxSecondaryContactEmail.valid,
                  fundLimitedPartners.form.taxSecondaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'taxSecondaryContactPhone',
                  'taxSecondaryContactPhone',
                  'text',
                  fundLimitedPartners.form.taxSecondaryContactPhone.value,
                  'Secondary Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.taxSecondaryContactPhone.valid,
                  fundLimitedPartners.form.taxSecondaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>

              <div className="row custom-margin-flex-columns margin-bottom-spacing">
                <hr />
                <h6>Capital Call Notices</h6>
                {renderTextInput(
                  'capitalPrimaryContactName',
                  'capitalPrimaryContactName',
                  'text',
                  fundLimitedPartners.form.capitalPrimaryContactName.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.capitalPrimaryContactName.valid,
                  fundLimitedPartners.form.capitalPrimaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'capitalPrimaryContactEmail',
                  'capitalPrimaryContactEmail',
                  'text',
                  fundLimitedPartners.form.capitalPrimaryContactEmail.value,
                  'Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.capitalPrimaryContactEmail.valid,
                  fundLimitedPartners.form.capitalPrimaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'capitalPrimaryContactPhone',
                  'capitalPrimaryContactPhone',
                  'text',
                  fundLimitedPartners.form.capitalPrimaryContactPhone.value,
                  'Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.capitalPrimaryContactPhone.valid,
                  fundLimitedPartners.form.capitalPrimaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'capitalSecondaryContactName',
                  'capitalSecondaryContactName',
                  'text',
                  fundLimitedPartners.form.capitalSecondaryContactName.value,
                  'Secondary Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.capitalSecondaryContactName.valid,
                  fundLimitedPartners.form.capitalSecondaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'capitalSecondaryContactEmail',
                  'capitalSecondaryContactEmail',
                  'text',
                  fundLimitedPartners.form.capitalSecondaryContactEmail.value,
                  'Secondary Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.capitalSecondaryContactEmail.valid,
                  fundLimitedPartners.form.capitalSecondaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'capitalSecondaryContactPhone',
                  'capitalSecondaryContactPhone',
                  'text',
                  fundLimitedPartners.form.capitalSecondaryContactPhone.value,
                  'Secondary Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.capitalSecondaryContactPhone.valid,
                  fundLimitedPartners.form.capitalSecondaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>

              <div className="row custom-margin-flex-columns margin-bottom-spacing">
                <hr />
                <h6>Cash and Stock Distributions</h6>
                {renderTextInput(
                  'stockPrimaryContactName',
                  'stockPrimaryContactName',
                  'text',
                  fundLimitedPartners.form.stockPrimaryContactName.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.stockPrimaryContactName.valid,
                  fundLimitedPartners.form.stockPrimaryContactName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'stockPrimaryContactEmail',
                  'stockPrimaryContactEmail',
                  'text',
                  fundLimitedPartners.form.stockPrimaryContactEmail.value,
                  'Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.stockPrimaryContactEmail.valid,
                  fundLimitedPartners.form.stockPrimaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'stockPrimaryContactPhone',
                  'stockPrimaryContactPhone',
                  'text',
                  fundLimitedPartners.form.stockPrimaryContactPhone.value,
                  'Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.stockPrimaryContactPhone.valid,
                  fundLimitedPartners.form.stockPrimaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'stockSecondaryContactName',
                  'stockSecondaryContactName',
                  'text',
                  fundLimitedPartners.form.stockSecondaryContactName.value,
                  'Secondary Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.stockSecondaryContactName.valid,
                  fundLimitedPartners.form.stockSecondaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'stockSecondaryContactEmail',
                  'stockSecondaryContactEmail',
                  'text',
                  fundLimitedPartners.form.stockSecondaryContactEmail.value,
                  'Secondary Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.stockSecondaryContactEmail.valid,
                  fundLimitedPartners.form.stockSecondaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'stockSecondaryContactPhone',
                  'stockSecondaryContactPhone',
                  'text',
                  fundLimitedPartners.form.stockSecondaryContactPhone.value,
                  'Secondary Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.stockSecondaryContactPhone.valid,
                  fundLimitedPartners.form.stockSecondaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>

              <div className="row custom-margin-flex-columns margin-bottom-spacing">
                <hr />
                <h6>Legal Documents</h6>
                {renderTextInput(
                  'legalPrimaryContactName',
                  'legalPrimaryContactName',
                  'text',
                  fundLimitedPartners.form.legalPrimaryContactName.value,
                  'Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.legalPrimaryContactName.valid,
                  fundLimitedPartners.form.legalPrimaryContactName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'legalPrimaryContactEmail',
                  'legalPrimaryContactEmail',
                  'text',
                  fundLimitedPartners.form.legalPrimaryContactEmail.value,
                  'Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.legalPrimaryContactEmail.valid,
                  fundLimitedPartners.form.legalPrimaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'legalPrimaryContactPhone',
                  'legalPrimaryContactPhone',
                  'text',
                  fundLimitedPartners.form.legalPrimaryContactPhone.value,
                  'Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.legalPrimaryContactPhone.valid,
                  fundLimitedPartners.form.legalPrimaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'legalSecondaryContactName',
                  'legalSecondaryContactName',
                  'text',
                  fundLimitedPartners.form.legalSecondaryContactName.value,
                  'Secondary Contact Person',
                  true,
                  'large',
                  !fundLimitedPartners.form.legalSecondaryContactName.valid,
                  fundLimitedPartners.form.legalSecondaryContactName
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'legalSecondaryContactEmail',
                  'legalSecondaryContactEmail',
                  'text',
                  fundLimitedPartners.form.legalSecondaryContactEmail.value,
                  'Secondary Email Address',
                  true,
                  'medium',
                  !fundLimitedPartners.form.legalSecondaryContactEmail.valid,
                  fundLimitedPartners.form.legalSecondaryContactEmail
                    .errorMessage,
                  ''
                )}
                {renderTextInput(
                  'legalSecondaryContactPhone',
                  'legalSecondaryContactPhone',
                  'text',
                  fundLimitedPartners.form.legalSecondaryContactPhone.value,
                  'Secondary Phone Number',
                  true,
                  'medium',
                  !fundLimitedPartners.form.legalSecondaryContactPhone.valid,
                  fundLimitedPartners.form.legalSecondaryContactPhone
                    .errorMessage,
                  ''
                )}
              </div>
            </FormSection>

            <FormSection title="Wire Instructions for Distribution (OPTIONAL)">
              <div className="row custom-margin-flex-columns">
                {renderTextInput(
                  'bankName',
                  'bank-name',
                  'text',
                  fundLimitedPartners.form.bankName.value,
                  'Bank Name',
                  false,
                  'large',
                  !fundLimitedPartners.form.bankName.valid,
                  fundLimitedPartners.form.bankName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'acctName',
                  'acct-name',
                  'text',
                  fundLimitedPartners.form.acctName.value,
                  'Accountholder Name',
                  false,
                  'large',
                  !fundLimitedPartners.form.acctName.valid,
                  fundLimitedPartners.form.acctName.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'acctNumber',
                  'acct-number',
                  'text',
                  fundLimitedPartners.form.acctNumber.value,
                  'Account Number',
                  false,
                  'large',
                  !fundLimitedPartners.form.acctNumber.valid,
                  fundLimitedPartners.form.acctNumber.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'swiftCode',
                  'swift-code',
                  'text',
                  fundLimitedPartners.form.swiftCode.value,
                  'Swift Code',
                  false,
                  'large',
                  !fundLimitedPartners.form.swiftCode.valid,
                  fundLimitedPartners.form.swiftCode.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'bankaba',
                  'bank-aba',
                  'text',
                  fundLimitedPartners.form.bankaba.value,
                  'Bank ABA#',
                  false,
                  'large',
                  !fundLimitedPartners.form.bankaba.valid,
                  fundLimitedPartners.form.bankaba.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'furtherCredit',
                  'further-credit',
                  'text',
                  fundLimitedPartners.form.furtherCredit.value,
                  'For further credit to',
                  false,
                  'large',
                  !fundLimitedPartners.form.furtherCredit.valid,
                  fundLimitedPartners.form.furtherCredit.errorMessage,
                  ''
                )}
                {renderTextInput(
                  'location',
                  'location',
                  'text',
                  fundLimitedPartners.form.location.value,
                  'Address',
                  true,
                  'large',
                  !fundLimitedPartners.form.location.valid,
                  fundLimitedPartners.form.location.errorMessage,
                  '',
                  '',
                  'City/State/Country'
                )}
              </div>
            </FormSection>

            <FormSection title="Identification of Investor Status">
              {getIdentificationOfInvestorStatusSection()}
            </FormSection>
            <FormSection title="Accredited Investor Representations">
              <div className="row custom-margin-flex-columns-questions">
                <MultiSelect
                  data={{
                    id: 'org-types-multiselect',
                    size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                  }}
                  content={accreditedInvestorRepresentationsContent()}
                  onChangeHandler={(e) =>
                    parseMultiSelectStateFromId(
                      e,
                      'accreditedInvestorRepresentations'
                    )
                  }
                />
              </div>
            </FormSection>
            <FormSection title="Qualified Purchaser Representations">
              <div className="row custom-margin-flex-columns-questions">
                <MultiSelect
                  data={{
                    id: 'org-types-multiselect2',
                    size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                  }}
                  content={qualifiedPurchaserRepresentationsContent()}
                  onChangeHandler={(e) =>
                    parseMultiSelectStateFromId(
                      e,
                      'qualifiedPurchaserRepresentations'
                    )
                  }
                />
              </div>
            </FormSection>
            <FormSection title="Qualified Client Representations">
              <div className="row custom-margin-flex-columns-questions">
                <MultiSelect
                  data={{
                    id: 'org-types-multiselect3',
                    size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                  }}
                  content={qualifiedClientRepresentationsContent()}
                  onChangeHandler={(e) =>
                    parseMultiSelectStateFromId(
                      e,
                      'qualifiedClientRepresentations'
                    )
                  }
                />
              </div>
            </FormSection>
            <FormSection title="Committee on Foreign Investment in the United States (“CFIUS”) Foreign Person Status Representations">
              <div className="row custom-margin-flex-columns-questions">
                <Paragraph text="1. The undersigned hereby represents and warrants that the undersigned is (check applicable box):" />
                <MultiSelect
                  data={{
                    id: 'org-types-foreign-representations',
                    size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                  }}
                  content={foreignPersonStatusRepresentationsContent()}
                  onChangeHandler={(e) =>
                    parseMultiSelectStateFromId(
                      e,
                      'foreignPersonStatusRepresentations'
                    )
                  }
                />
              </div>
              <div className="row custom-margin-flex-columns-questions">
                <Paragraph
                  text={
                    '2. If the undersigned is an entity (i.e., not a natural person), do national or subnational governments of a single foreign (i.e., non-U.S.) state hold a voting interest, directly or indirectly, of 49 percent or more in the undersigned?'
                  }
                  tooltip={`
                        For purposes of this Section F, "investment fund" means any entity that is an "investment company," as defined in Section 3(a) of the 1940 Act, or would be an "investment company" but for one or more of the exemptions provided in Section 3(b) or 3(c) thereunder.

                        <br /><br />
                  For purposes of determining the percentage of voting interest held indirectly by one entity in another entity, any voting interest of a parent will be deemed to be a 100 percent voting interest in any entity of which it is a parent.
                  `}
                  className={`w-full remove-margin-tooltip ${
                    !getIsEntity() && 'color-gray'
                  }`}
                />
                {renderConfirm(
                  [
                    `${iqID.id_foreign_person_2_1}_yes`,
                    `${iqID.id_foreign_person_2_1}_no`,
                  ],
                 ``,
                    checkDisabledQuestion('id_foreign_person_2_1')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_foreign_person_2_1
                            .isSelected[0],
                          fundLimitedPartners.form.id_foreign_person_2_1
                            .isSelected[1],
                        ],
                    'id_foreign_person_2_1',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_foreign_person_2_1'),
                      checkDisabledQuestion('id_foreign_person_2_1'),
                    ]
                )}
              </div>
            </FormSection>
            <FormSection title="Wiring Bank Status">
              <div className="row custom-margin-flex-columns-questions">
                {renderConfirm(
                  [
                    `${iqID.id_wiring_bank_status_1}_yes`,
                    `${iqID.id_wiring_bank_status_1}_no`,
                  ],
                  `1. Is the bank or other financial institution from which the
                undersigned’s funds will be wired (the “Wiring Bank”) located in
                the United States or another “FATF Country”?`,
                  checkDisabledQuestion('id_wiring_bank_status_1')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_wiring_bank_status_1
                          .isSelected[0],
                        fundLimitedPartners.form.id_wiring_bank_status_1
                          .isSelected[1],
                      ],
                  'id_wiring_bank_status_1',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_wiring_bank_status_1'),
                    checkDisabledQuestion('id_wiring_bank_status_1'),
                  ]
                )}
                {showWiringBankOptional ? (
                  <div className="row custom-margin-optionals-column">
                    <p>
                      In what non-”FATF Country” is the Wiring Bank located?
                      (See tooltip above for a current list of “FATF
                      Countries.”)
                    </p>
                    {renderTextInput(
                      'id_wiring_bank_status_1_opt',
                      'id_wiring_bank_status_1_opt',
                      'text',
                      fundLimitedPartners.form.id_wiring_bank_status_1_opt
                        .value,
                      '',
                      true,
                      'large',
                      !fundLimitedPartners.form.id_wiring_bank_status_1_opt
                        .valid,
                      fundLimitedPartners.form.id_wiring_bank_status_1_opt
                        .errorMessage,
                      ''
                      // [
                      //   checkDisabledQuestion('id_wiring_bank_status_1_opt'),
                      //   checkDisabledQuestion('id_wiring_bank_status_1_opt'),
                      // ]
                    )}
                  </div>
                ) : null}

                {renderConfirm(
                  [
                    `${iqID.id_wiring_bank_status_2}_yes`,
                    `${iqID.id_wiring_bank_status_2}_no`,
                  ],
                  `2. Is the undersigned a customer of the Wiring Bank?`,
                  checkDisabledQuestion('id_wiring_bank_status_2')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_wiring_bank_status_2
                          .isSelected[0],
                        fundLimitedPartners.form.id_wiring_bank_status_2
                          .isSelected[1],
                      ],
                  'id_wiring_bank_status_2',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_wiring_bank_status_2'),
                    checkDisabledQuestion('id_wiring_bank_status_2'),
                  ]
                )}
              </div>
            </FormSection>
            <FormSection title="Identification of Special Entities">
              <div className="row">
                <p className={getIsEntity() ? '' : 'color-gray'}>
                  1. ERISA Partner
                </p>
              </div>
              <div className="custom-margin-flex-columns-questions">
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_1_a}_yes`,
                    `${iqID.id_special_entities_1_a}_no`,
                  ],
                  `(a). Is the undersigned \n(a) an employee benefit plan subject to Part 4 of Subtitle B of Title I of the Employee Retirement Income Security Act of 1974, as amended (“ERISA”) or \n(b) a plan described in Section 4975(e)(1) of the Internal Revenue Code?`,
                  checkDisabledQuestion('id_special_entities_1_a')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_1_a
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_1_a
                          .isSelected[1],
                      ],
                  'id_special_entities_1_a',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_1_a'),
                    checkDisabledQuestion('id_special_entities_1_a'),
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_1_b}_yes`,
                    `${iqID.id_special_entities_1_b}_no`,
                  ],
                  `(b). Is the undersigned (a) an entity whose underlying assets are considered “plan assets” of an employee benefit plan that is subject to Part 4 of Subtitle B of Title I of ERISA and that invested in such entity or (b) an entity whose underlying assets are considered “plan assets” of a plan that is described in Section 4975(e)(1) of the Internal Revenue Code and that invested in such entity?`,
                  checkDisabledQuestion('id_special_entities_1_b')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_1_b
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_1_b
                          .isSelected[1],
                      ],
                  'id_special_entities_1_b',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_1_b'),
                    checkDisabledQuestion('id_special_entities_1_b'),
                  ]
                )}
                <div className="row">
                  {showErisaOptional
                    ? renderTextInput(
                        'id_special_entities_1_b_opt',
                        'id_special_entities_1_b_opt',
                        'number',
                        fundLimitedPartners.form.id_special_entities_1_b_opt
                          .value,
                        'If "YES", what percentage of the undersigned’s underlying assets is considered “plan assets”?',
                        true,
                        'large',
                        !fundLimitedPartners.form.id_special_entities_1_b_opt
                          .valid,
                        fundLimitedPartners.form.id_special_entities_1_b_opt
                          .errorMessage,
                        ''
                        // true : disabled
                      )
                    : null}
                </div>
                <div className="row">
                  <p className={getIsEntity() ? '' : 'color-gray'}>
                    2. Potential Lookthrough Entity
                  </p>
                </div>
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_2_a}_yes`,
                    `${iqID.id_special_entities_2_a}_no`,
                  ],
                  `(a). Is the undersigned, or would the undersigned be but for the exception provided in either Section 3(c)(1) or Section 3(c)(7) of the 1940 Act, an “investment company,” as defined in the 1940 Act?`,
                  checkDisabledQuestion('id_special_entities_2_a')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_2_a
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_2_a
                          .isSelected[1],
                      ],
                  'id_special_entities_2_a',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_2_a'),
                    checkDisabledQuestion('id_special_entities_2_a'),
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_2_b}_yes`,
                    `${iqID.id_special_entities_2_b}_no`,
                  ],
                  `(b). Was the undersigned formed for the specific purpose of investing in the Fund?`,
                  checkDisabledQuestion('id_special_entities_2_b')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_2_b
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_2_b
                          .isSelected[1],
                      ],
                  'id_special_entities_2_b',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_2_b'),
                    checkDisabledQuestion('id_special_entities_2_b'),
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_2_c}_yes`,
                    `${iqID.id_special_entities_2_c}_no`,
                  ],
                  `(c). Are the beneficial owners of the undersigned able to decide individually whether to participate or not participate in, or share in the profits and losses of, the undersigned’s investment in the Fund? (For clarity, an entity with a single beneficial owner should check “No” to this question.)`,
                  checkDisabledQuestion('id_special_entities_2_c')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_2_c
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_2_c
                          .isSelected[1],
                      ],
                  'id_special_entities_2_c',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_2_c'),
                    checkDisabledQuestion('id_special_entities_2_c'),
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_2_d}_yes`,
                    `${iqID.id_special_entities_2_d}_no`,
                  ],
                  `(d). Does the amount of the undersigned’s capital commitment in the Fund exceed 40% of the undersigned’s total assets?`,
                  checkDisabledQuestion('id_special_entities_2_d')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_2_d
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_2_d
                          .isSelected[1],
                      ],
                  'id_special_entities_2_d',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_2_d'),
                    checkDisabledQuestion('id_special_entities_2_d'),
                  ]
                )}

                {showPotentialLookthroughOptional ? (
                  <div className="row custom-margin-optionals-column">
                    <p>
                      (e). If the answer to any of 2(a)-(d) above is “YES,” how
                      many beneficial owners does the undersigned have for
                      purposes of Section 3(c)(1) of the 1940 Act?
                    </p>
                    {renderTextInput(
                      'id_special_entities_2_e_opt',
                      'id_special_entities_2_e_opt',
                      'number',
                      fundLimitedPartners.form.id_special_entities_2_e_opt
                        .value,
                      '',
                      true,
                      'large',
                      !fundLimitedPartners.form.id_special_entities_2_e_opt
                        .valid,
                      fundLimitedPartners.form.id_special_entities_2_e_opt
                        .errorMessage,
                      ''
                      // [
                      //   checkDisabledQuestion('id_special_entities_2_e_opt'),
                      //   checkDisabledQuestion('id_special_entities_2_e_opt'),
                      // ]
                    )}{' '}
                  </div>
                ) : null}
                <div className="row">
                  <p className={getIsEntity() ? '' : 'color-gray'}>
                    3. Government Entity: Is the undersigned a state or
                    political subdivision of a state, including
                  </p>
                </div>
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_3_a}_yes`,
                    `${iqID.id_special_entities_3_a}_no`,
                  ],
                  ReactHtmlParser(`(a). any agency, authority or instrumentality of the state or political subdivision;
<br/><br/>
(b). a pool of assets sponsored or established by the state or political subdivision or any agency, authority or instrumentality thereof, including but not limited to a “defined benefit plan” as defined in section 414(j) of the Internal Revenue Code, or a state general fund;
<br/><br/>
(c). a plan or program of a government entity; and
<br/><br/>
(d). officers, agents or employees of the state or political subdivision or any agency, authority or instrumentality thereof, acting in their official capacity?`),
                  checkDisabledQuestion('id_special_entities_3_a')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_3_a
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_3_a
                          .isSelected[1],
                      ],
                  'id_special_entities_3_a',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_3_a'),
                    checkDisabledQuestion('id_special_entities_3_a'),
                  ]
                )}
                <div className="row">
                  {renderConfirm(
                    [
                      `${iqID.id_special_entities_4}_yes`,
                      `${iqID.id_special_entities_4}_no`,
                    ],
                    `4. Bank Holding Company Partner: Is the undersigned a bank holding company or a company otherwise subject to the Bank Holding Company Act of 1956, as amended, or a subsidiary of such bank holding company?`,
                    checkDisabledQuestion('id_special_entities_4')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_special_entities_4
                            .isSelected[0],
                          fundLimitedPartners.form.id_special_entities_4
                            .isSelected[1],
                        ],
                    'id_special_entities_4',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_special_entities_4'),
                      checkDisabledQuestion('id_special_entities_4'),
                    ]
                  )}
                  {renderConfirm(
                    [
                      `${iqID.id_special_entities_5}_yes`,
                      `${iqID.id_special_entities_5}_no`,
                    ],
                    `5. Private Foundation Partner: Is the undersigned a “private foundation” as described in Section 509 of the Internal Revenue Code?`,
                    checkDisabledQuestion('id_special_entities_5')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_special_entities_5
                            .isSelected[0],
                          fundLimitedPartners.form.id_special_entities_5
                            .isSelected[1],
                        ],
                    'id_special_entities_5',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_special_entities_5'),
                      checkDisabledQuestion('id_special_entities_5'),
                    ]
                  )}
                  {renderConfirm(
                    [
                      `${iqID.id_special_entities_6}_yes`,
                      `${iqID.id_special_entities_6}_no`,
                    ],
                    `6. Public Pension Partner: Is the undersigned (a) a pension fund or retirement system for a government entity, or (b) a partnership of which one or more partners is a pension fund or retirement system for a government entity?`,
                    checkDisabledQuestion('id_special_entities_6')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_special_entities_6
                            .isSelected[0],
                          fundLimitedPartners.form.id_special_entities_6
                            .isSelected[1],
                        ],
                    'id_special_entities_6',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_special_entities_6'),
                      checkDisabledQuestion('id_special_entities_6'),
                    ]
                  )}
                  {renderConfirm(
                    [
                      `${iqID.id_special_entities_7}_yes`,
                      `${iqID.id_special_entities_7}_no`,
                    ],
                    `7. Fund-of-Funds: Is the undersigned a “fund-of-funds”?`,
                    checkDisabledQuestion('id_special_entities_7')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_special_entities_7
                            .isSelected[0],
                          fundLimitedPartners.form.id_special_entities_7
                            .isSelected[1],
                        ],
                    'id_special_entities_7',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_special_entities_7'),
                      checkDisabledQuestion('id_special_entities_7'),
                    ]
                  )}
                  {renderConfirm(
                    [
                      `${iqID.id_special_entities_8}_yes`,
                      `${iqID.id_special_entities_8}_no`,
                    ],
                    `8. United States Tax-Exempt Partner: Is the undersigned exempt from the payment of United States federal income tax (i.e., has the undersigned applied for and received special tax exempt status from the United States Internal Revenue Service)?`,
                    checkDisabledQuestion('id_special_entities_8')
                      ? [false, false]
                      : [
                          fundLimitedPartners.form.id_special_entities_8
                            .isSelected[0],
                          fundLimitedPartners.form.id_special_entities_8
                            .isSelected[1],
                        ],
                    'id_special_entities_8',
                    'large',
                    '',
                    handlerConfirm,
                    [
                      checkDisabledQuestion('id_special_entities_8'),
                      checkDisabledQuestion('id_special_entities_8'),
                    ]
                  )}
                </div>
                <div className="row">
                  <p className={getIsEntity() ? '' : 'color-gray'}>
                    9. Flow-Through Entity:
                  </p>
                </div>
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_9_a}_yes`,
                    `${iqID.id_special_entities_9_a}_no`,
                  ],
                  `(a). Is the undersigned classified as a partnership for U.S. federal income tax purposes?`,
                  checkDisabledQuestion('id_special_entities_9_a')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_9_a
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_9_a
                          .isSelected[1],
                      ],
                  'id_special_entities_9_a',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    checkDisabledQuestion('id_special_entities_9_a'),
                    checkDisabledQuestion('id_special_entities_9_a'),
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_9_b}_yes`,
                    `${iqID.id_special_entities_9_b}_no`,
                  ],
                  `(b). If the answer to 9(a) above is “YES,” is every beneficial owner of the undersigned a “United States person,” as defined in section 7701(a)(30) of the Internal Revenue Code? (For clarity, a “Yes” answer indicates that every underlying owner of the undersigned is a “United States person.”)`,
                  checkDisabledQuestion('id_special_entities_9_b')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_9_b
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_9_b
                          .isSelected[1],
                      ],
                  'id_special_entities_9_b',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    fundLimitedPartners.form.id_special_entities_9_a
                      .isSelected[0] !== true,
                    fundLimitedPartners.form.id_special_entities_9_a
                      .isSelected[0] !== true,
                  ]
                )}
                {renderConfirm(
                  [
                    `${iqID.id_special_entities_9_c}_yes`,
                    `${iqID.id_special_entities_9_c}_no`,
                  ],
                  `(c). If the answer to 9(a) above is “YES,” is any beneficial owner of the undersigned exempt from the payment of United States federal income tax (i.e., because such beneficial owner has applied for and received special tax exempt status from the United States Internal Revenue Service)?`,
                  checkDisabledQuestion('id_special_entities_9_c')
                    ? [false, false]
                    : [
                        fundLimitedPartners.form.id_special_entities_9_c
                          .isSelected[0],
                        fundLimitedPartners.form.id_special_entities_9_c
                          .isSelected[1],
                      ],
                  'id_special_entities_9_c',
                  'large',
                  '',
                  handlerConfirm,
                  [
                    fundLimitedPartners.form.id_special_entities_9_a
                      .isSelected[0] !== true,
                    fundLimitedPartners.form.id_special_entities_9_a
                      .isSelected[0] !== true,
                  ]
                )}
              </div>
            </FormSection>
            <FormSection title="Exceptions to Representations and Warranties">
              <TextArea
                inputData={{
                  id: iqID.id_warranties_exceptions,
                  name: 'id_warranties_exceptions',
                  displayName: 'Warranties Exceptions',
                  isRequired: false,
                  hasError: false,
                  value:
                    fundLimitedPartners.form.id_warranties_exceptions.value,
                  title: '',
                  description: '',
                }}
                onChangeHandler={(e) => {
                  props.setFormField(
                    'id_warranties_exceptions',
                    'value',
                    e.target.value
                  );
                }}
              />
            </FormSection>
            <Description>
              TO COMPLETE YOUR FUND SUBSCRIPTION, PLEASE RETURN TO THE FUND LINK
              YOU RECEIVED, LOG IN, AND USE THE THE INVESTOR PROFILE YOU
              CREATED.
            </Description>
          </form>
        )}
      </FormLayout>
    );
  };

  if (addInvestor) return renderAddInvestorLayout();
  const formValues = props.fundLimitedPartners.form;
  // eslint-disable-next-line no-unused-vars
  return (
    <div className="main-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3 className="new-sections-header-title-style">INVESTORS</h3>
                <p className="description-text-size">
                  Add or edit the <b>Investors</b> (i.e., the persons or
                  investment vehicles that will invest in one or more funds) for
                  which you will fill out Investor Questionnaires.
                  <br />
                  <br />
                  <b>Note:</b> If you just created a <b>new</b> Investor to
                  subscribe to a <b>specific</b> fund, please{' '}
                  <b>access the emailed link to that fund</b>. If a browser tab
                  is still open for that fund’s Investor Questionnaire, you may
                  need to <b>refresh</b> the browser to see the new Investor.
                </p>
              </div>
            </div>
            <div className="limited-partner-custom-float">
              <button onClick={handleAddLPv2} id="handle-add-lp-btn">
                + Investor
              </button>
              <a
                id="modal-limitedPartners-trigger"
                href="#/"
                data-toggle="modal"
                data-target="#limitedPartnersModal"
              />
            </div>
            <TableContainer
              content={renderTableContents()}
              loadMoreHandler={() => {}} // TODO: implement load more
              noResultsText="Please add at least one Investor in order to begin filling out Investor Questionnaires."
              paginationConf={{
                data: invsPages,
                currentPage: invsPage,
                max: invsMaxItems,
                refreshData: setPaginationsItems,
                selectPage: selectPage,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: Reducer) => ({
  organizationContext: state.organizationContext,
  fundLimitedPartners: state.fundLimitedPartners,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    saveLimitedPartner: (payload) =>
      dispatch(action.saveLimitedPartner(payload)),
    editLimitedPartner: (payload) =>
      dispatch(action.editLimitedPartner(payload)),
    resetForms: () => dispatch({ type: 'RESET_FORMS_LP' }),
    setFormField: (fieldName, fieldProperty, fieldPropertyValue) =>
      dispatch({
        type: 'SET_LP_FORM',
        fieldName,
        fieldProperty,
        fieldPropertyValue,
      }),
  })
);
export default connector(FundLimitedPartners);

const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 56px;
  color: #848484;
`;
