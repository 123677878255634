/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import * as action from './action';
import * as utility from './../../../utils/utility';
import './styles.scss';
import {
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../types/index';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  sendSubmitConfirmation: () => {},
  questionnaireContext: QuestionnaireExperienceContext,
};
const initialState = {
  message: '',
};

export class SignaturesCallback extends PureComponent {
  props: Props;

  static defaultProps: {
    sendSubmitConfirmation: () => {},
    questionnaireContext: {},
  };

  constructor(state) {
    super(state);
    this.state = initialState;
    this.setSubmittedMessage = this.setSubmittedMessage.bind(this);
  }

  setSubmittedMessage() {
    this.setState({
      ...this.state,
      message: `
      Thank you for completing your subscription documents on 
      Gunderson Dettmer’s Fund Formation Portal. You will receive a copy of 
      your completed documents via email. Your documents are currently under 
      review by our team. You may now close your browser tab if you need nothing 
      further.&nbsp;
      <br />
      <br />
      <u>
        Frequently Asked Questions
      </u>
      <br />
      <br />
      How do I make a change to my subscription documents?
      <br />
      <br />
      <ol class="dots-ol">
        <li>
          <strong>For Guest Checkout Users,</strong> return to the original link you received 
          via email. You will be able to access your questionnaire using 
          the same email address you originally used.
        </li>
        <li>
          <strong>For Account Users,</strong> log in to your account 
          at <a href="https://www.iq.gunder.com">www.iq.gunder.com</a> to select your submission and make changes.
        </li>
      </ol>
      <br />
        <br />
      How do I fill out a questionnaire for this fund for an additional investor entity?
      <br />
      <br />
      <ol class="dots-ol">
        <li>
          <strong>For Guest Checkout Users,</strong>  return to the original link you received via email, but use 
          an <u>alternate email address</u> to access the questionnaire. You must use different email 
          addresses for any additional subscriptions to this fund, unless you’d like to create an 
          account. To create an account, go to 
          <a href='https://www.iq.gunder.com/signup'>www.iq.gunder.com/signup</a>.
        </li>
        <li>
          <strong> For Account Users investing with an existing investor profile,</strong> simply return 
          to the original link you received via email, log back into your account, and choose 
          the desired investor profile to complete the process.
        </li>
        <li>
          <strong>For Account Users investing with a new investor entity,</strong> log into your account at 
          <a href="https://www.iq.gunder.com">www.iq.gunder.com</a> and create your new investor profile(s). 
          Once you create the investor profile(s), return to the original fund link you 
          received via email, log in again, and select the desired 
          investor profile to complete the process.
        </li>
      </ol>

      <br />
      <br />
        <strong><u>Contact Us</u></strong> 
        <br />
      <br />
      <p>
        Legal Questions - Contact your Gunderson Dettmer legal team
      </p>
      <p>
        Technical Support - Contact <a href="mailto:fundportaladmin@gunder.com"> fundportaladmin@gunder.com</a>
      </p>`,
    });
  }

  selectCallbackMessage(triggeredEvent) {
    const { sendSubmitConfirmation, questionnaireContext } = this.props;
    const signator = {
      name: utility.getUrlParameter('n'),
      email: utility.getUrlParameter('e'),
    };
    console.log('Triggered Event', triggeredEvent);
    if (!triggeredEvent) {
      /* Case where LP from login does get No event provided since event=abc is not present in the callback for some reason */
      console.log('Test case No event provided');
      if (utility.getUrlParameter('lpf')) {
        const confirmationParam = utility.getUrlParameter('lpf');
        console.log('sendSubmitConfirmation', confirmationParam, signator);
        sendSubmitConfirmation(
          confirmationParam,
          signator,
          this.setSubmittedMessage,
          questionnaireContext.fund,
          utility.getUrlParameter('at'),
          false
        );
      }
    } else {
      let callbackMessage = '';
      switch (triggeredEvent) {
        case 'signing_complete': {
          callbackMessage = '';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          // check if its an limitedPartnerFund `lpf` or investorFund `if`
          // for now it seems like not required since we are sending
          // signature over email
          const includedInvestorFund = utility.getUrlParameter('if');
          const hasInvestorFund =
            includedInvestorFund && includedInvestorFund !== '';
          const confirmationParam =
            includedInvestorFund || utility.getUrlParameter('lpf');
          sendSubmitConfirmation(
            confirmationParam,
            signator,
            this.setSubmittedMessage,
            questionnaireContext.fund,
            utility.getUrlParameter('at'),
            hasInvestorFund
          );
          break;
        }
        case 'decline': {
          callbackMessage = 'You declined to sign';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
        case 'exception': {
          callbackMessage = 'Exception occurred during signing';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
        case 'cancel': {
          callbackMessage = `When you are ready to sign, please return to the original fund link, access your investor questionnaire again, and sign at Part 3. If you'd like to download the PDF to sign offline, please click Continue at the DocuSign window and choose the Download option in the center near the top of the screen.`;
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
        case 'ttl_expired ': {
          callbackMessage = 'Session expired please login again to the fund';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
        case 'session_timeout ': {
          callbackMessage = 'Session timed out please login again to the fund';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
        default: {
          callbackMessage = 'No event provided';
          this.setState({
            ...this.state,
            message: callbackMessage,
          });
          break;
        }
      }
    }
  }

  componentDidMount() {
    this.selectCallbackMessage(utility.getUrlParameter('event'));
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container-height">
        <h4 className="color-dark-green signature-message">
          {ReactHtmlParser(this.state.message)}
        </h4>
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    sendSubmitConfirmation: (
      investorFund,
      signatoryInfo,
      callback,
      fund,
      at,
      hasInvestorFund
    ) =>
      dispatch(
        action.sendSubmitConfirmation(
          investorFund,
          signatoryInfo,
          callback,
          fund,
          at,
          hasInvestorFund
        )
      ),
  })
);
export default connector(SignaturesCallback);
