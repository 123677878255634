import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FormSection from './FormSection';
import Tooltip from './../Tooltip/index';

type Props = {
	cancelRoute: string,
	children: any,
	description?: string,
	layoutProps?: {
		isModal?: boolean,
		showLogout?: boolean,
	},
	onCancel: () => void,
	onSubmit: () => void,
	questionsProgress?: {
		completed: string,
		total: string,
	},
	title?: string,
};

const Form = (props: Props) => {
	const { cancelRoute, children, description, layoutProps, onCancel, onSubmit, questionsProgress, title } = props;
	const { goBack, length: historyLength, push } = useHistory();

	const close = (e) => {
		e.preventDefault();

		if (historyLength <= 2) {
			push(cancelRoute);
		} else {
			if (onCancel) {
				onCancel();
			}
			// goBack();
		}
	};

	const saveAndClose = (e) => {
		e.preventDefault();

		onSubmit();
		// close && close(e);
	};

	const getProps = () => ({
		FormSection,
	});

	return (
		<Container isModal={layoutProps && layoutProps.isModal}>
			<WrapperContent>
				<Header>
					<Wrapper>
						<Title>{title}</Title>
						<CTAContainer>
							{questionsProgress && (
								<Progress>
									<ProgressData>{questionsProgress.completed}</ProgressData> &nbsp;
									<ProgressTextLast>{`/ ${questionsProgress.total} Required questions answered`}</ProgressTextLast>
									<Tooltip
										overlayExtraClass={'custom-message-top'}
										data={{
											message: 'The number of required questions will change based on the answers provided.',
										}}
									/>
								</Progress>
							)}

							<SaveButton onClick={saveAndClose}>Save and Close</SaveButton>
							<CloseButton onClick={close}>Cancel</CloseButton>
						</CTAContainer>
					</Wrapper>
				</Header>

				<Description>{description}</Description>

				<main>{typeof children === 'function' ? children(getProps()) : children}</main>
			</WrapperContent>
		</Container>
	);
};

export default Form;

const CloseButton = styled.button`
	background-color: transparent;
	color: inherit;
	font-size: 16px;
	font-weight: initial;
	height: initial;
	line-height: 19px;
	padding: 0;
	text-decoration: none;
	width: initial;

	&:hover {
		background-color: transparent;
		color: inherit;
	}
`;

const WrapperContent = styled.div`
	max-width: 1530px;
	margin: 0 auto;
	padding: 50px 102px 100px 102px;
`;

const Container = styled.div`
	color: #444444;
	font-family: 'Franklin Gothic Medium', sans-serif;
	width: 100%;
	background: white;

	& > * {
		color: #444444;
		font-family: 'Franklin Gothic Medium', sans-serif;
	}
`;

const CTAContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${({ padding }) => `1.25rem ${padding}%`};
	align-items: center;
`;

const Description = styled.p`
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 56px;
	color: #848484;
`;

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 1330px;
	max-width: 100%;
	margin: 0 auto;
`;

const Header = styled.header`
	margin-bottom: 30px;
	position: fixed;
	background: white;
	width: 100%;
	z-index: 99999;
	padding: 50px 100px 10px;
	top: 0;
	left: 0;
`;

const Progress = styled.div`
	margin-right: 56px;
`;

const ProgressData = styled.span`
	font-size: 18px;
	line-height: 28px;
`;

const ProgressTextFirst = styled.span`
	color: #285e83;
	font-size: 16px;
	line-height: 28px;
`;

const ProgressTextLast = styled.span`
	font-size: 14px;
	line-height: 28px;
`;

const SaveButton = styled.button`
	background-color: #285E83;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	font-weight: initial;
	height: initial;
	margin-right: 18px;
	padding: 8px 12px;
	width: initial;
`;

const Title = styled.h1`
	font-size: 26px;
	line-height: 30px;
	margin: 0;
`;
