import React from 'react';

type Props = { containerContent: Object, collapsed: Object };

const CollapseContainer = ({ containerContent, collapsed }: Props) => (
  <div id="collapse-toggler" className={collapsed ? 'fund-topic collapse' : 'fund-topic'}>
    {containerContent}
  </div>
);

CollapseContainer.defaultProps = {
  containerContent: {},
  collapsed: true,
};

export default CollapseContainer;

