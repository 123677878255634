import React from 'react';
import './styles.scss';
import Tooltip from '../Tooltip/index';

const handleUnderline = (displayName) => {
  const hasUnderline = /\[[^\]]|]]/g
  if(hasUnderline.test(displayName) && typeof displayName === 'string') {
    const text = displayName.split(hasUnderline)
    let result = []
   
    for (var i = 0; i < text.length; i++) {
      if (text[i].indexOf('<') !== -1) {
         result.push(<u> {text[i].replace('<', '')} </u>)
      } else {
        result.push(text[i])
      }
      
    }
    return result
  }
  return displayName
}

type Props = { inputData: Object, onChangeHandler: () => void };
// todo rename this component since it is no longer a radio button
const RadioButton = ({ inputData, onChangeHandler }: Props) => (
  <div className="form-group input-group-style">
    <fieldset id={`title-${inputData.id}`} className={inputData.className}>
      <input
        type="checkbox"
        data-identifier={inputData.identifier}
        name={inputData.name}
        id={inputData.id}
        checked={!inputData.disabled && inputData.isSelected}
        onChange={onChangeHandler}
        disabled={inputData.disabled}
      />
      <label className="color-black" htmlFor={inputData.id}>
        <div
          id={`colorText${inputData.id}`}
          className={`${inputData.disabled ? 'color-gray' : ''}`}
        >
          {handleUnderline(inputData.displayName)}
        </div>
      </label>
      {inputData.showHelp ? (
        <span>
          <br />
          <Tooltip data={{ message: inputData.helpText, className: 'tooltip-pos-right' }} />
        </span>
      ) : null}
    </fieldset>
  </div>
);

RadioButton.defaultProps = {
  inputData: {
    id: '',
    displayName: '',
    isSelected: false,
    name: '',
    identifier: '',
    helpText: '',
    showHelp: false,
    disabled: false,
  },
};

export default RadioButton;
