/* @flow */

import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';

import type { Store } from '../types';
import rootReducer from './reducers';

export default (history: Object): Store => {
  const middlewares = [
    thunk.withExtraArgument(axios),
    routerMiddleware(history)
  ];

  const enhancers = [applyMiddleware(...middlewares)];
  const store: Store = createStore(
    connectRouter(history)(rootReducer(history)),
    {},
    compose(...enhancers)
  );
  /*   if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      try {
        const nextReducer = createRootReducer(history);

        store.replaceReducer(nextReducer);
      } catch (error) {
        console.error(
          chalk.red(`==> 😭  Reducer hot reloading error ${error}`)
        );
      }
    });
  } */
  return store;
};
