import React from 'react';
import './styles.scss';
import TableResultRowItem from '../TableResultRowItem/index';
import Dropdown from '../Dropdown/index';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Swal from 'sweetalert2';
import moment from 'moment';
import { STATUS_TYPES } from './../../utils/constants/status';
import DateTimeZone from './../../utils/date-time-zone';

/**
 * @typedef {object} Props
 * @property {Array<object>} items
 * @property {boolean} actionRow
 * @property {string} actionIdentifier
 * @property {number} selectedIndex
 * @property {Function} actionRowClickHandler
 * @property {Function} statusMenuCallback
*/

const renderDropdown = (
  id,
  value,
  title,
  displayName,
  size,
  isValid,
  errMessage,
  options,
  handler,
  blurHandler,
  dataTarget,
  dataType
) => {
  const inputDataTemp = {
    id,
    value: value || '',
    displayName,
    title,
    size,
    hasError: !isValid,
    errorMessage: errMessage,
    options,
    wrapperExtraClass: 'positioning-especial',
    dataTarget,
    dataType,
  };
  return (
    <Dropdown
      inputData={inputDataTemp}
      onChangeHandler={handler}
      onBlurHandler={blurHandler}
    />
  );
};

const renderResults = (items) => (
  <div className="result-row result-row-col-5 background-color-white clearfix">
    {items.map((item) => (
      <TableResultRowItem key={item.id} data={item} />
    ))}
  </div>
);

export const DropdownStatusRowItem = ({ item, rowIndex, isUpdatingStatus, selectedIndex, itemsToUpdate, resultCounts, outRowTable }) => {
  const [showSecondCalendar, setShowSecondCalendar] = React.useState(false);
  const [dropdownUseCalendar, setDropdownUseCalendar] = React.useState(-1);

  React.useEffect(() => {
    if (outRowTable) {
        setShowSecondCalendar(false);
        setDropdownUseCalendar(-1);
    }
    
  }, [selectedIndex])

  if (!item || selectedIndex === -1 || rowIndex === -1) return <></>;
  const translateYpx = (rowIndex * 70) + 160;
  return (
     <div style={outRowTable ? { position: 'relative', transform:  `translate(-1100px, ${translateYpx}px)` } : {}} 
     className={`${item.uniqueIdentifier === selectedIndex ? 'display-dropdown' : 'hidden-dropdown'} 
     custom-dropdown-menu dropdown-header-line-space datepicker-column-result-list box-container-menu-statuses `}>
          {!isUpdatingStatus && !itemsToUpdate.length && 
          <div className={`custom-dropdown-statuses-dropdown box-container-menu-statuses ${
                item.uniqueIdentifier === selectedIndex && showSecondCalendar && dropdownUseCalendar === rowIndex ? 'custom-status-box' : ''}  
                  ${resultCounts >= 9 ? 'custom-dropdown-statuses-absolute' : ''}`}>
              <ul>
                <li>
                    <a onClick={() => item.clickHandler(item, STATUS_TYPES.IN_PROGRESS.label)}>
                      {item.displayName === STATUS_TYPES.IN_PROGRESS.label || 
                      item.displayName === STATUS_TYPES.PENDING.label ? (
                        <b style={{ fontWeight: 800 }}>{STATUS_TYPES.IN_PROGRESS.label}</b>
                      ) : (
                        STATUS_TYPES.IN_PROGRESS.label
                      )}
                    </a>
                  </li>
                <li>
                  <a onClick={() => item.clickHandler(item, STATUS_TYPES.SUBMITTED.label)}>
                    {item.displayName === STATUS_TYPES.SUBMITTED.label ? (
                      <b style={{ fontWeight: 800 }}>{STATUS_TYPES.SUBMITTED.label}</b>
                    ) : (
                      STATUS_TYPES.SUBMITTED.label
                    )}
                  </a>
                </li>
                <li>
                  <a onClick={() => item.clickHandler(item, STATUS_TYPES.READY_TO_CLOSE.label)}>
                    {item.displayName ===
                    STATUS_TYPES.READY_TO_CLOSE.label ? (
                      <b style={{ fontWeight: 800 }}>{STATUS_TYPES.READY_TO_CLOSE.label}</b>
                    ) : (
                      STATUS_TYPES.READY_TO_CLOSE.label
                    )}
                  </a>
                </li>
                <li>
                  <a onClick={() => {
                    // item.clickHandler( item, STATUS_TYPES.CLOSED.label, new Date());
                    setShowSecondCalendar(!showSecondCalendar);
                    setDropdownUseCalendar(rowIndex);
                    }}>
                    {item.displayName === STATUS_TYPES.CLOSED.label ? (
                      <>
                        <b style={{ fontWeight: 800 }}>{STATUS_TYPES.CLOSED.label}</b>
                      </>
                    ) : (
                      STATUS_TYPES.CLOSED.label
                    )}
                    &nbsp;&nbsp;&nbsp;
                    <span className="fa fa-calendar" />
                    <span
                      onClick={() => {
                        setShowSecondCalendar(!showSecondCalendar);
                        setDropdownUseCalendar(rowIndex);
                        }}
                      className={`${
                        showSecondCalendar ? 'fa-rotate-90' : 'fa-rotate-270'
                      } status-arrow fa fa-sort-down`}
                    />
                  </a>
                </li>
                  <li>
                  <a onClick={() => item.clickHandler(item, STATUS_TYPES.OMIT_FROM_GP_ACCESS.label)}>
                    {item.displayName === STATUS_TYPES.OMIT_FROM_GP_ACCESS.value ? (
                      <b style={{ fontWeight: 800 }}>{STATUS_TYPES.OMIT_FROM_GP_ACCESS.label}</b>
                    ) : (
                      STATUS_TYPES.OMIT_FROM_GP_ACCESS.label 
                    )} 
                  </a>
                </li>
              </ul>
              {showSecondCalendar && dropdownUseCalendar === rowIndex
              ? ( 
                <DayPicker
                  className="align-datepicker"
                  onDayClick={(day) => {
                    Swal.fire({
                      title: `Set ${moment(day).format(
                        'MMM DD'
                      )} as closing date?`,
                      showCancelButton: true,
                      confirmButtonText: `Save`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        item.clickHandler(item, 'Closed', day, true);
                      }
                    });
                  }}
                  selectedDays={new DateTimeZone(item.closedAt, true)}
                />
            ) : null}
            </div>}
          </div>
  )
}

export const DropdownStatus = ({addStatus, showCalendar, setShowCalendar, selectedDay, setSelectedDay, resultCounts}) => (
  <div
    className={`display-dropdown status-dropdown-style custom-dropdown-statuses-dropdown box-container-menu-statuses
    ${showCalendar ? 'custom-status-box': ''}`}>
    <ul>
      <li>
        <a onClick={() => addStatus(STATUS_TYPES.IN_PROGRESS.label)}>
            {STATUS_TYPES.IN_PROGRESS.label}
        </a>
      </li>
      <li>
        <a onClick={() => addStatus(STATUS_TYPES.SUBMITTED.label)}>
            {STATUS_TYPES.SUBMITTED.label}
        </a>
      </li>
      <li>
        <a onClick={() => addStatus(STATUS_TYPES.READY_TO_CLOSE.label)}>
            {STATUS_TYPES.READY_TO_CLOSE.label}
        </a>
      </li>
      <li>
        <a onClick={() => setShowCalendar(!showCalendar)}>
            {STATUS_TYPES.CLOSED.label}
          &nbsp;&nbsp;&nbsp;
          <span className="fa fa-calendar" />
          <span
            onClick={() => setShowCalendar(!showCalendar)}
            className={`${
              showCalendar ? 'fa-rotate-90' : 'fa-rotate-270'
            } status-arrow fa fa-sort-down`}
          />
        </a>
        
      </li>
        <li>
        <a onClick={() => addStatus(STATUS_TYPES.OMIT_FROM_GP_ACCESS.label)}>
            {STATUS_TYPES.OMIT_FROM_GP_ACCESS.label}
        </a>
      </li>
    </ul>
    {showCalendar ? (
        <DayPicker
          className="align-datepicker"
          onDayClick={(day) => {
            Swal.fire({
              title: `Set ${moment(day).format(
                'MMM DD'
              )} as closing date?`,
              showCancelButton: true,
              confirmButtonText: `Save`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                setSelectedDay(day)
                 addStatus(STATUS_TYPES.CLOSED.label)
              }
            });
          }}
          selectedDays={new DateTimeZone(selectedDay, true)}
        />
      ) : null}
  </div>
)

export const CheckboxButton = ({isChecked, item, setItemsToUpdate, removeItem}) => (
 isChecked ? <p 
    onClick={ () => setItemsToUpdate(item)} 
    className="checkbox-status-style">&#9744;
  </p>
  :
  <p onClick={() => removeItem(item.dataTarget)} 
  style={{ color: '#285E83' }}
    className="checkbox-status-style is-checked">&#9745;
  </p>
)

const statusValue = (item) => {
  switch(item.displayName) {
    case STATUS_TYPES.SUBMITTED.label:
      return STATUS_TYPES.SUBMITTED.value
    case STATUS_TYPES.READY_TO_CLOSE.label:
      return STATUS_TYPES.READY_TO_CLOSE.value
    case STATUS_TYPES.CLOSED.label:
      return STATUS_TYPES.CLOSED.value
    case STATUS_TYPES.OMIT_FROM_GP_ACCESS.value:
      return STATUS_TYPES.OMIT_FROM_GP_ACCESS.value
    case STATUS_TYPES.IN_PROGRESS.label:
      return 'in-progress'
    default:
      return ''
  }
}


const renderFundResults = (items, statusMenuCallback, isUpdatingStatus, itemsToUpdate, setItemsToUpdate, removeItem, hasSome, setRowItem, setRowIndex, index, resultCounts, selectedIndex) => (
  <div className="result-row background-color-white clearfix" onClick={() => setRowIndex(index)}>
    {items.map((item, idx) => {
      item.displayName = item.displayName === STATUS_TYPES.PENDING.label ? STATUS_TYPES.IN_PROGRESS.label : item.displayName;
      if (item.id !== 'investment-status-col') {
        return <TableResultRowItem key={item.id} data={item} />;
      } else {
        return (  
        <div key={`${idx}-${item.id}-dropdown-result`} className={`result-item-2 cursor-pointer box-container-menu-statuses ${statusValue(item)}`}>
            <div className='status-box'>
              <span className="adjust-result-row-position">
              <CheckboxButton isChecked={hasSome(item.dataTarget)} removeItem={removeItem} item={item} setItemsToUpdate={setItemsToUpdate} />
                <p onClick={(e) => {
                e.preventDefault();
                if(!isUpdatingStatus) {
                  statusMenuCallback(item.uniqueIdentifier);
                  setRowItem(item);
                }}}>
                {item.displayName}
                </p> 
              </span>
                <span 
                className="status-menu-arrow fa fa-sort-down"
                  onClick={(e) => {
                    e.preventDefault();
                    if(!isUpdatingStatus) {
                      statusMenuCallback(item.uniqueIdentifier);
                      setRowItem(item);
                    } 
                  }}
                  /> 
            </div>
           {resultCounts >= 9 ? 
            <DropdownStatusRowItem
              item={item}
              rowIndex={index}
              isUpdatingStatus={isUpdatingStatus}
              selectedIndex={selectedIndex}
              itemsToUpdate={itemsToUpdate}
              outRowTable={false}
              resultCounts={resultCounts} /> : null }
        </div>
    )}
    })}
  </div>
);

/**
 * @param {Props}
*/
const TableResultRow = ({
  items,
  actionRow,
  actionIdentifier,
  actionRowClickHandler,
  statusMenuCallback,
  isUpdatingStatus,
  itemsToUpdate,
  setItemsToUpdate,
  removeItem,
  setRowItem,
  setRowIndex,
  index,
  resultCounts,
  hasSome,
  selectedIndex,
  actionRowNoAnchor,
}) =>
  actionRow ? (
   (actionRowNoAnchor ? renderResults(items) :
    <a
      key={actionIdentifier}
      href="#/"
      data-identifier={actionIdentifier}
      onClick={(e) => actionRowClickHandler(e, actionIdentifier)}>
      {renderResults(items)}
    </a> )
  ) : (
     renderFundResults(items, statusMenuCallback, isUpdatingStatus,itemsToUpdate, setItemsToUpdate, removeItem, hasSome, setRowItem, setRowIndex, index, resultCounts, selectedIndex)
  );

TableResultRow.defaultProps = {
  items: [],
  actionRow: false,
  actionIdentifier: '',
  isUpdatingStatus: false,
  actionRowClickHandler: () => {},
};

export default TableResultRow;
