import React from 'react';
import './styles.scss';

type Props = { progress: Object };

const resolveProgress = progress => `progress-row progress-${progress}`;

const ProgressBar = ({ progress }: Props) => (
  <div className={resolveProgress(progress)}>
    <div className="bar" />
  </div>
);

export default ProgressBar;
