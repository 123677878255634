/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */
import React, { PureComponent } from 'react';
import ProgressBar from './../../../../components/ProgressBar/index';
import ProgressBarDescription from './../../../../components/ProgressBarDescription/index';

type Props = {
  step: number,
};

const stepProgressMap = {
  0: '0',
  1: '33',
  2: '66',
  3: '100',
};

export class Progress extends PureComponent {
  props: Props;

  static defaultProps: {
    step: 0,
  };
  render() {
    const { step } = this.props;
    return (
      <div className="row">
        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 center-block">
          <ProgressBarDescription progress={stepProgressMap[step]} />
          <ProgressBar progress={stepProgressMap[step]} />
        </div>
      </div>
    );
  }
}
