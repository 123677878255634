/* eslint-disable react/sort-comp, jsx-a11y/anchor-is-valid */
/* @flow */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import TableContainer from '../../components/TableContainer/index';
import {
  OrganizationContext as OrganizationContextType,
  Reducer
} from '../../types';
import * as utility from '../../utils/utility';
import './styles.scss';

const { INVESTMENT_TYPES } = require('../../utils/constants/types');
const { STATUS_TYPES } = require('../../utils/constants/status');
const { getPages, orderByPage, getInitialData, paginationMAX } = require('../../utils/pagination');


type Props = {
  history: {},
  organizationContext: OrganizationContextType
};

const FundDashboard = (props) => {

  const [sortByCol, setSortByCol] = useState('-1');
  const [sortDirection, setSortDirection] = useState('desc');
  const [iQsPage, setIQsPage] = useState(1);
  const [iQsMaxItems, setIQsMaxItems] = useState(paginationMAX);
  const [iQsPages, setIQsPages] = useState(null);

  const redirectToInvestorQuestionnaire = event => {
    // eslint-disable-next-line react/prop-types
    const { organizationContext } = props;
    event.preventDefault();
    if (typeof window !== 'undefined') {
      const queryParam = `lpf=${event.target.getAttribute(
        'data-identifier'
      )}&orgUser=${organizationContext.user._id}&token=${localStorage.getItem(
        'organization-auth-token'
      )}`;
      window.open(
        `/access/login/?${queryParam}${event.target.getAttribute(
          'data-identifier'
        )}`,
        '_blank'
      );
    }
  };

  const handleChangeFilterItem = (event) => {
    event.preventDefault();
    let index = '-1';
    switch (event.target.innerText) {
      case 'Venture Fund':
        index = '0';
        break;
      case 'Investor':
        index = '1';
        break;
      case 'Status':
        index = '2';
        break;
      default:
        index = '-1';
        break;
    }
    if (sortByCol === index) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortByCol(index.toString());
      setSortDirection('desc');
    }
  }

  const renderTableContents = () => {
    const { organizationContext } = props;
    const rowActions = [];
    const headers = [
      {
        title: 'Venture Fund',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '0',
        filteringOrder: sortDirection
      },
      {
        title: 'Investor',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '1',
        filteringOrder: sortDirection
      },
      {
        title: 'Status',
        filteringArrow: true,
        filteringHandler: handleChangeFilterItem,
        selected: sortByCol === '2',
        filteringOrder: sortDirection
      },
      { title: '' }
    ];
    const orgLps = organizationContext.organization.limitedPartners;
    const investmentFundsPerLP = orgLps.map(lp => lp.limitedPartnerFunds);
    const allInvestments =
      investmentFundsPerLP.length > 0
        ? investmentFundsPerLP.reduce((prev, curr) => prev.concat(curr))
        : [];

    const results = allInvestments.map((lpInvest, index) => {
      let investStatus = STATUS_TYPES.IN_PROGRESS.label;
      if (lpInvest.status === STATUS_TYPES.PENDING.label) {
        investStatus = STATUS_TYPES.UNDER_REVIEW.label;
      } else if (lpInvest.status) {
        investStatus = lpInvest.status;
      }
      const rowsInfo = [
        {
          id: 'VentureFund-res-col',
          displayName: lpInvest.fund.fundLegalName,
          type: 'item'
        },
        {
          id: 'limitedPartners-res-col',
          displayName: lpInvest.limitedPartner.name,
          type: 'description'
        },
        {
          id: 'status-res-col',
          displayName: investStatus,
          type: 'description'
        },

        { id: 'view-result-col', type: 'description' }
      ];
      if (
        investStatus !== STATUS_TYPES.CLOSED.label &&
        investStatus !== STATUS_TYPES.READY_TO_CLOSE.label
      ) {
        rowsInfo[3].actionResult = true;
        rowsInfo[3].customActionRender = 'button';
        rowsInfo[3].customActionRenderDisplayName = 'View/Edit';
        rowsInfo[3].actionResultIdentifier = lpInvest._id;
        rowsInfo[3].actionResultText = '';
        rowsInfo[3].dataType = lpInvest.investor
          ? INVESTMENT_TYPES.INVESTOR_FUND
          : INVESTMENT_TYPES.LIMITED_PARTNER_FUND;
        rowsInfo[3].actionCallback = redirectToInvestorQuestionnaire;
      }
      return rowsInfo;
    });

    const sortData =
      sortByCol !== '-1'
        ? utility.gunderSort(results, sortByCol)
        : results;

    const paginationData = getInitialData(sortData, iQsMaxItems);

    if (!iQsPages) {
      setIQsPages(getPages(paginationData.length, iQsMaxItems) || []);
    }

    if (iQsPage > 1) {
      paginationData.records = orderByPage(sortData, iQsMaxItems, iQsPage);
    }

    return {
      headers,
      results:
        sortDirection === 'desc' ? paginationData.records : paginationData.records.reverse(),
      rowActions
    };
  };

  const setPaginationsItems = event => {
    const maxItems = event.target.value;
    setIQsMaxItems(maxItems);
    setIQsPage(1);
    setIQsPages(null);
    renderTableContents();
  }

  const selectPage = page => {
    setIQsPage(page);
    renderTableContents();
  }

  return (
    <div className="main-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 className="new-sections-header-title-style">
              INVESTOR QUESTIONNAIRES
              </h3>
            <p className="description-text-size">
              <b>View Investor Questionnaires</b> submitted previously. You
                can <b>edit</b> any Investor Questionnaires which have not yet
                been reviewed and locked by the Gunderson Dettmer team. If you
                received a link via email to subscribe to a new fund (not listed
                below),{' '}
              <b>
                please return to the email message to access the Investor
                Questionnaire via that link.
                </b>
            </p>
          </div>
          <div className="col-lg-12">
            <div className={'limited-partner-custom-float'}>
              <button
                onClick={() => {
                  props.history.push('limitedPartners?add=true');
                }}
                id="handle-add-lp-btn"
              >
                + Investor
                </button>
            </div>
            <TableContainer
              content={renderTableContents()}
              noResultsText="You have not filled out any Investor Questionnaires. You will need to create at least one Investor using the <b>+&nbsp;Investor</b> button above. If you received an email with a link to a fund, please use that link to fill out the Investor Questionnaire for that fund."
              paginationConf={{
                data: iQsPages,
                currentPage: iQsPage,
                max: iQsMaxItems,
                refreshData: setPaginationsItems,
                selectPage: selectPage
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state: Reducer) => ({
  organizationContext: state.organizationContext
});
const connector: Connector<{}, Props> = connect(mapStateToProps, null);
export default connector(FundDashboard);
