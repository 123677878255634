import React from 'react';
import './styles.scss';
import Tooltip from '../../components/Tooltip/index';

type Props = { inputData: Object, onChangeHandler: () => {}, onBlurHandler: () => {} };

const TextArea = ({ inputData, onChangeHandler, onBlurHandler }: Props) => (
  <div className={`form-group input-group-style ${inputData.hasError ? 'error' : ''}`}>
    {inputData.showHelp ? <Tooltip className="tooltip-positioning" data={{ message: inputData.helpText, extraClass: 'tooltip-margin' }} /> : null}
    {(inputData.description && inputData.description !== '') ?
      <div className="text-area-description color-gray">
        <h5 className="color-black">{inputData.title}</h5>
        <p className="color-black">{inputData.description}</p>
      </div> : null}
    <fieldset>
      <textarea id={inputData.id} name={inputData.name} cols="" rows="" value={inputData.value} disabled={inputData.disabled} className="form-control input-style" onBlur={onBlurHandler} onChange={onChangeHandler} placeholder={inputData.displayName} />
    </fieldset>
    {inputData.hasError ? <div className="error-message">
      <span className="text">{inputData.errorMessage}</span>
    </div> : null}
  </div>
);

TextArea.defaultProps = {
  data: {
    id: '',
    displayName: '',
    description: '',
    title: '',
    size: '',
    isRequired: false,
    hasError: false,
    errorMessage: '',
    value: '',
    wrapperClass: '',
    helpText: 'tooltip text',
    showHelp: false,
  },
  onChangeHandler: () => {},
  onBlurHandler: () => {},
};

export default TextArea;

