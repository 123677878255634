import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';

type Props = { inputData: Object };

const renderAlertContent = (styleClass, data) => (
  <div className={styleClass}>
    <h3>{data.title}</h3>
    <p>{data.message}</p>
  </div>
);

const Alert = ({ inputData }: Props) => {
  const dispatch = useDispatch();
  let showingTimer = null;
  const alertContainerReference = useRef();
  useEffect(() => {
    if (!inputData.showAlways) {
      const timeToShow = inputData.timeout || 10000;
      showingTimer = setTimeout(() => {
        alertContainerReference.current.hidden = true;
        // clear the notification from context
        dispatch({ type: 'HIDE_NOTIFICATION' });
      }, timeToShow);
    }

    return () => {
      if (showingTimer) {
        clearTimeout(showingTimer);
      }
    };
  }, [inputData.showAlways]);
  useEffect(() => {
    if (inputData.message !== '') {
      alertContainerReference.current.hidden = false;
    }
  }, [inputData.message]);
  return (
    <span
      ref={alertContainerReference}
      id={inputData.showAlways ? 'notify-fixed' : 'container-notify'}
      className="notification-alert-container"
      onClick={() => {
        alertContainerReference.current.hidden = true;
      }}
    >
      {renderAlertContent(
        `message-${inputData.type} ${
          inputData.showAlways ? 'alert' : 'alert alert-add-animation'
        }`,
        inputData
      )}
    </span>
  );
};

Alert.defaultProps = {
  inputData: {
    id: '',
    title: '',
    message: '',
    type: '',
    showAlways: false,
  },
};

export default Alert;
