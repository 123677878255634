/* @flow */

import _ from 'lodash';

/* import {
} from './action'; */
import type { AuthContainer, Action } from '../../types';

type State = AuthContainer;

const initialState = {
  isLoading: false,
  sessionTimeout: false,
};

// eslint-disable-next-line consistent-return
export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_LOADER_STATUS':
      return _.assign({}, state, { isLoading: action.isLoading });
    case 'SHOW_SESSION_TIMEOUT_ALERT':
      return _.assign({}, state, { sessionTimeout: action.sessionTimeout });
    default:
      return state;
  }
};
