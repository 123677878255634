// import Cookies from 'universal-cookie';
import type { Dispatch, GetState, ThunkAction } from '../../types';
// import { apiUrl } from './../../config';

export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';

const API_URL = '/api';
/* istanbul ignore next */
export const resetPassword = (
  password: String,
  confirmPassword: String,
  email,
  token: String,
  history
): ThunkAction =>
  // eslint-disable-next-line arrow-body-style
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    const res = await axios
      .put(`${API_URL}/iqUsers/resetPassword`, {
        password,
        confirmPassword,
        email,
        token
      });
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: res.data.success ? 'success' : 'error',
      message: res.data.message,
      title: res.data.success ? 'Success' : 'Error'
    });
    if (res.data.success) {
      history.push('/');
    }
  };
