/* eslint-disable react/sort-comp, react-hooks/exhaustive-deps */
/* @flow */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import _ from 'lodash';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'; // Redirect,
import Notifications from 'react-notify-toast';
import BlockUi from 'react-block-ui';
import ReactConfirmAlert, { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-block-ui/style.css';
import * as action from './action';
import './styles.scss';
import {
  Context as ContextType,
  AuthContainer as AuthContainerReType,
  Reducer,
  Dispatch,
} from '../../types/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import Footer from '../../components/Footer/index';
import AdminDashboardPage from './../AdminDashboard';
import FundFormPage from './../FundForm';
import FirmFormPage from './../FirmForm';
import FirmFundsDetailPage from '../FirmFundsDetail';
import NotFoundPage from './../NotFound';
import FundDetailPage from './../FundDetail';
import AdminProfilePage from './../AdminProfile';

type Props = {
  history: Object,
  context: ContextType,
  authContainerRe: AuthContainerReType,
  logout: () => void,
  refetchUser: () => void,
  sessionExpire: () => void,
};

// Export this for unit testing more easily
const AuthenticatedContainer = (props) => {
  useEffect(() => {
    if (props.context && props.context.user && props.context.user._id) {
      checkUserIsAvailable();
    }
  }, [props.context]);

  useEffect(() => {
    if (props.authContainerRe.sessionTimeout) {
      confirmAlert({
        title: 'Session Expired',
        message: 'Your current session has expired. Please log back in.',
        buttons: [
          {
            label: 'Continue',
            onClick: () => handleSessionExpire(),
          },
          {
            label: 'Cancel',
            onClick: () => handleSessionExpire(),
          },
        ],
      });
    }
  }, [props.authContainerRe.sessionTimeout]);

  const checkUserIsAvailable = () => {
    const { context, refetchUser, history } = props;
    if (
      typeof window !== 'undefined' &&
      !localStorage.getItem('auth-token') &&
      !localStorage.getItem('rs-token')
    ) {
      history.push('/admin');
    }
    if (!context.user._id) {
      refetchUser(history);
    }
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const { logout, history } = props;
    logout(history);
  };
  const handleSessionExpire = () => {
    const { sessionExpire, history } = props;
    sessionExpire(history);
  };
  const handleProfile = (event) => {
    event.preventDefault();
    const { history } = props;
    if (document.title !== 'Fund Formation Portal - Admin Profile')
      history.push('/admin/main/profile');
  };
  // eslint-disable-next-line class-methods-use-this
  const adminRoutes = () => {
    return [
      {
        path: '/admin/main/dashboard',
        exact: true,
        component: AdminDashboardPage,
      },
      {
        path: '/admin/main/editFund/:id',
        exact: true,
        component: FundFormPage,
      },
      {
        path: '/admin/main/newFund',
        exact: true,
        component: FundFormPage,
      },
      {
        path: '/admin/main/editFirm/:id',
        exact: true,
        component: FirmFormPage,
      },
      {
        path: '/admin/main/newFirm',
        exact: true,
        component: FirmFormPage,
      },
      {
        path: '/admin/main/firmDetail/:id',
        exact: true,
        component: FirmFundsDetailPage,
      },
      {
        path: '/admin/main/fundDetail/:id',
        exact: true,
        component: FundDetailPage,
      },
      {
        path: '/admin/main/profile',
        exact: true,
        component: AdminProfilePage,
      },
      {
        path: '*',
        component: NotFoundPage,
      },
    ];
  };

  const routeWithSubRoutes = (route) => {
    const { context } = props;
    return (
      <Route
        key={_.uniqueId()}
        exact={route.exact || false}
        path={route.path}
        render={(props) => {
          // check access is allowed else redirect to login
          if (!context.user._id) {
            return null;
          }
          return <route.component {...props} routes={route.routes || null} />;

          // Pass the sub-routes down to keep nesting
        }}
      />
    );
  };

  const { context, authContainerRe } = props;
  return context.user && context.user._id ? (
    <div>
      <div className="content-wrapper">
        <DynamicHeader
          type={'authenticated'}
          homePath={'/admin'}
          data={{
            name: context.profile.name,
            profileHandler: handleProfile,
            logoutHandler: handleLogout,
          }}
        />
        <Notifications />
        <BlockUi tag="div" blocking={authContainerRe.isLoading}>
          <Switch>
            {adminRoutes().map((route) => routeWithSubRoutes(route))}
          </Switch>
        </BlockUi>
      </div>
      <Footer />
    </div>
  ) : (
    <Redirect to="/admin" />
  );
};
const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  authContainerRe: state.authContainerRe,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    logout: (history) => dispatch(action.logout(history)),
    sessionExpire: (history) => dispatch(action.sessionExpire(history)),
    refetchUser: (history) => dispatch(action.refetchUser(history)),
  })
);
export default withRouter(connector(AuthenticatedContainer));
