/**
 * Created by Elias on 5/22/2017.
 */

import type { Dispatch, GetState, ThunkAction } from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

const API_URL = '/api';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
        }`
    }
  };
}

export const sendMailShareFund = (to, text, fundId, fundName): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios
        .post(
          `${API_URL}/sendMailShareFund`,
          { to, text, fundId, fundName },
          getConfig()
        );
      const closeModal = document.getElementById(
        `close_modal_icon${fundId}`
      );
      if (closeModal) closeModal.click();
      dispatch({
        type: RENDER_NOTIFICATION,
        notificationType: res.data.success ? 'success' : 'error',
        message: res.data.message,
        title: `Share Fund ${res.data.success ? 'Success' : 'Failed'}`
      });
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const fetchFirm = (id, callback): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios
        .get(`${API_URL}/firm/${id}`, getConfig());
      callback(res.data.firm);
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };
