/* eslint-disable react/sort-comp,import/prefer-default-export */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import './styles.scss';
import Title from '../../../../../components/Title/index';
import SubTitle from '../../../../../components/SubTitle/index';
import Paragraph from '../../../../../components/Paragraph/index';
import Button from '../../../../../components/Button/index';
import {
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
} from '../../../../../types/index';

type Props = {
  history: Object,
  questionnaireContext: QuestionnaireExperienceContext,
  handleRedirect: () => void,
};

// Export this for unit testing more easily
export class Instructions extends PureComponent {
  props: Props;

  static defaultProps: {
    history: {},
  };

  render() {
    const { questionnaireContext } = this.props;
    const instructionsParagraph = `Welcome to the electronic investor questionnaire for ${
      questionnaireContext.fund.fundLegalName
      } (the “Fund”). You must carefully complete this investor questionnaire by completing the General Information required under Part 1, checking the appropriate boxes in the questionnaire in Part 2, and executing this questionnaire. Once you are finished, this electronic investor questionnaire has been integrated with DocuSign, which will allow you to designate a signatory to receive and sign the documents electronically. We will automatically receive the executed documents from DocuSign. You may also download the unsigned questionnaire from DocuSign for review or manual signature.`;
    const noticeParagraph = `Please note that in addition to this electronic investor questionnaire, we will also require an IRS Form W-9 (for U.S. investors) or the applicable IRS Form W-8 (for non-U.S. investors) to be submitted. Please send your tax form separately to ${
      questionnaireContext.fund.taxFormsEmail
        ? questionnaireContext.fund.taxFormsEmail
        : 'Tax Forms Email'
      }.`;
    const generalnoticeParagraph =
      'THE LIMITED PARTNERSHIP INTERESTS REFERRED TO IN THIS INVESTOR QUESTIONNAIRE (THE “SECURITIES”) HAVE NOT BEEN REGISTERED UNDER THE UNITED STATES SECURITIES ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”) OR THE SECURITIES LAWS OF ANY OTHER STATE OR JURISDICTION. THE SECURITIES MAY NOT BE SOLD, OFFERED FOR SALE, PLEDGED OR HYPOTHECATED IN THE ABSENCE OF A REGISTRATION STATEMENT IN EFFECT WITH RESPECT TO SUCH INTERESTS UNDER THE SECURITIES ACT OR AN EXEMPTION THEREFROM. INVESTORS SHOULD BE AWARE THAT THEY WILL BE REQUIRED TO BEAR THE FINANCIAL RISKS OF THIS INVESTMENT FOR AN INDEFINITE PERIOD OF TIME. IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR OWN EXAMINATION OF THE FUND AND THE TERMS OF THE OFFERING, INCLUDING THE MERITS AND RISKS INVOLVED. INVESTORS ARE ENCOURAGED TO SEEK INDEPENDENT LEGAL, INVESTMENT AND TAX ADVICE REGARDING INDIVIDUAL CIRCUMSTANCES AND FINANCIAL OBJECTIVES IN DETERMINING WHETHER TO ACQUIRE AN INTEREST IN THE FUND. THE SECURITIES HAVE NOT BEEN APPROVED OR DISAPPROVED BY ANY FEDERAL OR STATE SECURITIES COMMISSION OR REGULATORY AUTHORITY NOR HAVE THE FOREGOING AUTHORITIES PASSED UPON THE ACCURACY OR ADEQUACY OF THE OFFERING MATERIALS. ANY REPRESENTATION TO THE CONTRARY IS A CRIMINAL OFFENSE.';
    const nonUsResidentNotice =
      'EXCEPT AS OTHERWISE INDICATED, NO ACTION HAS BEEN OR WILL BE TAKEN IN ANY JURISDICTION OUTSIDE THE UNITED STATES OF AMERICA THAT WOULD PERMIT AN OFFERING OF THESE SECURITIES, OR POSSESSION OR DISTRIBUTION OF OFFERING MATERIALS IN CONNECTION WITH THE ISSUE OF THESE SECURITIES, IN ANY COUNTRY OR JURISDICTION WHERE ACTION FOR THAT PURPOSE IS REQUIRED. IT IS THE RESPONSIBILITY OF ANY PERSON WISHING TO PURCHASE THESE SECURITIES TO SATISFY HIMSELF AS TO FULL OBSERVANCE OF THE LAWS OF ANY RELEVANT TERRITORY OUTSIDE THE UNITED STATES OF AMERICA IN CONNECTION WITH ANY SUCH PURCHASE, INCLUDING OBTAINING ANY REQUIRED GOVERNMENTAL OR OTHER CONSENTS OR OBSERVING ANY OTHER APPLICABLE FORMALITIES.';
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="content-bg main-content-area clearfix">
            <div className="row instructions-container">
              <Title text={questionnaireContext.fund.fundLegalName} />
              <Title text={'Investor Questionnaire'} />
              <br />
              <SubTitle isLink={false} text={'INSTRUCTIONS'} underline />
              <b>
                <Paragraph text={instructionsParagraph} />
              </b>
              <br />
              <b>
                <Paragraph text={noticeParagraph} />
              </b>
              <br />
              <SubTitle isLink={false} text={'GENERAL NOTICE'} underline />
              <Paragraph text={generalnoticeParagraph} />
              <br />
              <SubTitle isLink={false} text={'NOTICE TO NON-UNITED STATES RESIDENTS'} underline />
              <Paragraph text={nonUsResidentNotice} />
            </div>
          </div>
          <br />
          <div className="next-btn">
            <Button
              inputData={{ id: 'next-btn', displayName: 'Next', type: 'button' }}
              clickHandler={event =>
                this.props.handleRedirect(event, questionnaireContext.currentStep + 1)
              }
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  null,
);

export default connector(Instructions);
