import React from 'react';
import './styles.scss';
import IconTextLink from '../../components/IconTextLink/index';

type Props = { data: Object, saveLaterHandler: ()=>void };

const renderIconTextLink = (id, icon, text, color, dataToggle, dataTarget, clickHandler) => {
  const data = {
    id,
    icon,
    text,
    color,
    dataToggle,
    dataTarget,
  };
  return <IconTextLink data={data} clickHandler={clickHandler} />;
};

const FundTool = ({ data, saveLaterHandler }: Props) => (
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="tools content-bg clearfix">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {renderIconTextLink('saveLater', 'fa-cloud-upload', 'Finish Later', 'color-dark-green', data.modalToggle, `#${data.modalTarget}`, saveLaterHandler)}
            {renderIconTextLink('saveTimeStamp', 'fa-check-circle-o', `Last saved ${data.lastSavedTimeStamp}`, 'color-soft-gray')}
          </div>
        </div>
      </div>
    </div>
  </div>
);

FundTool.defaultProps = {
  data: {
    saveLaterAble: false,
    modalToggle: '',
    modalTarget: '',
    lastSavedTimeStamp: '',
  },
  printHandler: () => {},
  saveLaterHandler: () => {},
};

export default FundTool;
