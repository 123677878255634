import React from 'react';
import './styles.scss';
import './../TextInput/styles.scss';
import Button from '../Button/index';

type Props = {
  mailTo: Object,
  clickHandler: () => void,
  onChangeHandler: () => void,
  //eslint-disable-next-line
  initAccess: () => void,
};

const renderSubmit = (id, displayName, type, clickHandle) => {
  const inputDataTemp = {
    id,
    type,
    displayName,
  };
  return (
    <a id={id} href="#/" className="button" type={type} onClick={clickHandle}>
      {displayName}
    </a>
  );
};

const Access = ({
  mailTo,
  clickHandler,
  onChangeHandler,
  initAccess,
}: Props) => (
  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 center-block">
    <div>
      <div className="fund-code clearfix">
        <div className="fund-title color-black">ENTER ACCESS CODE</div>
        <div className="fund-title-desc">
          <p>Your access code was sent to {mailTo}</p>
          {/* <a href="#/" className="send-btn" onClick={initAccess}>
            {' '}
            resend
          </a> */}
          <p>
            Please wait up to ten minutes to receive your code. If you need a
            new access code, please return to the original fund link.
          </p>
        </div>
        <hr />
        <div className="fund-access-code">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <fieldset>
              <input
                onChange={onChangeHandler}
                type="text"
                className="form-control input-style"
                placeholder="Enter access code"
              />
            </fieldset>
          </div>
        </div>
        <hr />
        <div className="submit-margin">
          {renderSubmit('submitCode', 'Start', 'submit', clickHandler)}
        </div>
      </div>
    </div>
  </div>
);

Access.defaultProps = {
  mailTo: 'default@email.com',
  accessInfo: {
    fund: '',
    email: '',
  },
  clickHandler: () => {},
  onChangeHandler: () => {},
  initAccess: () => {},
};

export default Access;
