/* eslint-disable
 react/sort-comp,no-unused-vars,react/prop-types,no-loop-func,jsx-a11y/anchor-has-content */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import type { Connector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import Button from './../../components/Button';
import TextInput from './../../components/TextInput';
import VerticalMenu from './../../components/VerticalMenu';
import TableContainer from '../../components/TableContainer/index';
import {
  Dispatch,
  Context as ContextType,
  FundSettings as FundSettingsType,
  OrganizationContext as OrganizationContextType,
  Reducer,
} from '../../types';
import Dropdown from '../../components/Dropdown';
import Alert from '../../components/Alert';
import {
  PASSWORD_LENGTH_MAX_MESSAGE,
  PASSWORD_LENGTH_MIN_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
} from '../../utils/constants/messages';
import * as notify from '../../utils/notify';
import * as validations from '../../utils/form-validation';
import * as action from './action';
import './styles.scss';
import * as utility from '../../utils/utility';

type Props = {
  organizationContext: OrganizationContextType,
  context: ContextType,
  fundSettings: FundSettingsType,
  inviteUser: () => void,
  editOrganization: () => void,
  setCurrentPath: () => void,
  setUserFormField: () => void,
  setInviteFormField: () => void,
  setOrganizationFormField: () => void,
  hideNotification: () => void,
  editUserProfile: () => void,
  editOrganizationUser: () => void,
  resetInviteForm: () => void,
  deleteOrganizationUser: () => void,
  renderNotification: () => void,
  resetForms: () => void,
};

export class FundSettings extends PureComponent {
  state = {
    targetUserEdit: null,
    isEditingUser: false,
    sortByCol: '-1',
    sortDirection: 'desc',
  };

  static defaultProps = {
    organizationContext: {},
    context: {},
    fundSettings: {},
    inviteUser: () => {},
    editOrganization: () => {},
    setCurrentPath: () => {},
    hideNotification: () => {},
    editUserProfile: () => {},
    editOrganizationUser: () => {},
    deleteOrganizationUser: () => {},
    resetInviteForm: () => {},
    resetForms: () => {},
    renderNotification: () => {},
  };

  constructor(props) {
    super(props);
    this.renderTextInput = this.renderTextInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.inviteSubmit = this.inviteSubmit.bind(this);
    this.initSettings = this.initSettings.bind(this);
    this.handleInviteFieldsChange = this.handleInviteFieldsChange.bind(this);
    this.updateOrganizationInfo = this.updateOrganizationInfo.bind(this);
    this.updateUserProfileInfo = this.updateUserProfileInfo.bind(this);
    this.handleChangeFilterItem = this.handleChangeFilterItem.bind(this);
  }

  componentDidMount() {
    this.initSettings();
    if (document.title !== 'Fund Formation Portal - Settings')
      notify.hideNotification();
  }
  componentWillUnmount() {
    notify.hideNotification();
  }

  verifyFormValidity = (targetForm) => {
    // patch test
    const {
      setOrganizationFormField,
      setInviteFormField,
      setUserFormField,
      fundSettings,
    } = this.props;
    let dispatchAction = null;
    if (targetForm === 'organizationForm') {
      dispatchAction = setOrganizationFormField;
    } else if (targetForm === 'userForm') {
      dispatchAction = setUserFormField;
    } else if (targetForm === 'inviteForm') {
      dispatchAction = setInviteFormField;
    }
    for (let i = 0; i < Object.keys(fundSettings[targetForm]).length; i += 1) {
      const keyName = Object.keys(fundSettings[targetForm])[i];
      if (
        keyName !== 'loading' &&
        keyName !== 'matching' &&
        keyName !== 'userType'
      ) {
        const validationPassed = this.runFieldValidations(
          keyName,
          fundSettings[targetForm][keyName].value,
          targetForm
        );
        if (validationPassed.field) {
          // test
          dispatchAction(
            validationPassed.field,
            'value',
            validationPassed.value
          );
          dispatchAction(validationPassed.field, 'valid', false);
          dispatchAction(
            validationPassed.field,
            'errorMessage',
            validationPassed.errorMessage
          );
          return false;
        }
        // setOrganizationFormField(keyName, 'value', fundSettings[targetForm][keyName].value);
        dispatchAction(keyName, 'valid', true);
        dispatchAction(keyName, 'errorMessage', '');
      }
    }
    return true;
  };
  runFieldValidations = (fieldName, _value, targetForm) => {
    const { fundSettings } = this.props;
    let validationResult = {};
    // patch test
    for (
      let i = 0;
      i < fundSettings[targetForm][fieldName].validators.length;
      i += 1
    ) {
      const validator = fundSettings[targetForm][fieldName].validators[i];
      let errorMessageToDisplay = '';
      switch (validator) {
        case 'required':
          validationResult = validations.required(_value);
          if (!validationResult) {
            errorMessageToDisplay = `${fundSettings[targetForm][fieldName].displayName} is required`;
            return {
              field: fieldName,
              value: _value,
              errorMessage: errorMessageToDisplay,
            };
          }
          break;
        case 'email':
          validationResult = validations.email(_value);
          if (!validationResult) {
            errorMessageToDisplay = 'Wrong email format';
            return {
              field: fieldName,
              value: _value,
              errorMessage: errorMessageToDisplay,
            };
          }
          break;
        default:
          return true;
      }
    }
    return true;
  };
  initSettings() {
    const {
      organizationContext,
      setOrganizationFormField,
      setUserFormField,
    } = this.props;
    setOrganizationFormField(
      'organizationName',
      'value',
      organizationContext.organization.name
    );
    setOrganizationFormField(
      'city',
      'value',
      organizationContext.organization.city
    );
    setOrganizationFormField(
      'state',
      'value',
      organizationContext.organization.state
    );
    setOrganizationFormField(
      'country',
      'value',
      organizationContext.organization.country
    );

    setUserFormField('firstName', 'value', organizationContext.user.firstName);
    setUserFormField('lastName', 'value', organizationContext.user.lastName);
    setUserFormField('email', 'value', organizationContext.user.email);
  }

  handleInviteFieldsChange(event) {
    event.preventDefault();
    const { setInviteFormField } = this.props;
    setInviteFormField(event.target.id, 'value', event.target.value);
  }
  handleUserFieldsChange = (event) => {
    event.preventDefault();
    const { setUserFormField } = this.props;
    setUserFormField(event.target.id, 'value', event.target.value);
  };
  handleOrganizationFieldsChange = (event) => {
    event.preventDefault();
    const { setOrganizationFormField } = this.props;
    setOrganizationFormField(event.target.id, 'value', event.target.value);
  };
  renderDropdown = (
    id,
    value,
    title,
    displayName,
    size,
    handleChange,
    options,
    hasError,
    errorMessage
  ) => {
    const inputDataTemp = {
      id,
      value: value || '',
      displayName,
      title,
      size,
      options,
      hasError,
      errorMessage,
    };
    return (
      <Dropdown inputData={inputDataTemp} onChangeHandler={handleChange} />
    );
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    helpText,
    disabled,
    customHandler
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
    };
    return (
      <TextInput inputData={inputDataTemp} onChangeHandler={customHandler} />
    );
  };

  handleChange(event) {
    const { setCurrentPath, hideNotification } = this.props;
    event.preventDefault();
    // notify.hideNotification();
    hideNotification();
    setCurrentPath(event.target.name);
  }
  updateOrganizationInfo(event) {
    event.preventDefault();
    const { organizationContext, editOrganization, fundSettings } = this.props;
    const currentOrgClone = { ...organizationContext.organization };
    // only update is name changed
    const needsToUpdate =
      currentOrgClone.name !==
        fundSettings.organizationForm.organizationName.value ||
      currentOrgClone.city !== fundSettings.organizationForm.city.value ||
      currentOrgClone.state !== fundSettings.organizationForm.state.value ||
      currentOrgClone.country !== fundSettings.organizationForm.country.value;
    if (needsToUpdate) {
      if (this.verifyFormValidity('organizationForm')) {
        currentOrgClone.name =
          fundSettings.organizationForm.organizationName.value;
        currentOrgClone.city = fundSettings.organizationForm.city.value;
        currentOrgClone.state = fundSettings.organizationForm.state.value;
        currentOrgClone.country = fundSettings.organizationForm.country.value;
        editOrganization(currentOrgClone);
      }
    }
  }

  updateUserProfileInfo(event) {
    event.preventDefault();
    const { organizationContext, editUserProfile, fundSettings } = this.props;
    const currentUserClone = { ...organizationContext.user };
    let validPassword = true;
    if (this.verifyFormValidity('userForm')) {
      const updatePayload = {
        email: currentUserClone.email, // avoid changes to these value
        firstName: fundSettings.userForm.firstName.value,
        lastName: fundSettings.userForm.lastName.value,
      };
      if (
        fundSettings.userForm.password.value &&
        fundSettings.userForm.confirmPassword.value
      ) {
        updatePayload.password = fundSettings.userForm.password.value;
        updatePayload.confirmPassword =
          fundSettings.userForm.confirmPassword.value;
        validPassword = this.checkPassword();
      }
      if (validPassword) {
        editUserProfile(updatePayload);
      }
    }
  }
  handleInviteUser = () => {
    const { resetInviteForm } = this.props;
    this.setState(
      {
        ...this.state,
        isEditingUser: false,
        targetUserEdit: null,
      },
      () => {
        resetInviteForm();
        document.getElementById('modal-invite-trigger').click();
      }
    );
  };

  deleteOrgUser = (event) => {
    event.preventDefault();
    const {
      deleteOrganizationUser,
      organizationContext,
      renderNotification,
    } = this.props;
    const userId = event.target.dataset.identifier;

    if (organizationContext.user._id.toString() !== userId) {
      confirmAlert({
        title: 'Confirm',
        message: 'Are you sure you want to delete the user?',
        confirmLabel: 'Confirm',
        cancelLabel: 'Cancel',
        onConfirm: () => deleteOrganizationUser(userId),
      });
    } else {
      renderNotification();
    }
  };
  selectUserEdit = (event) => {
    const { organizationContext, setInviteFormField } = this.props;
    event.preventDefault();
    const targetUserId = event.target.dataset.identifier;
    const targetUserEdit = organizationContext.organization.iqUsers.find(
      (user) => user._id.toString() === targetUserId
    );
    this.setState(
      {
        ...this.state,
        isEditingUser: true,
        targetUserEdit,
      },
      () => {
        setInviteFormField('firstName', 'value', targetUserEdit.firstName);
        setInviteFormField('lastName', 'value', targetUserEdit.lastName);
        setInviteFormField('emailAddress', 'value', targetUserEdit.email);
        setInviteFormField(
          'userType',
          'value',
          targetUserEdit.role.toLowerCase()
        );
        document.getElementById('modal-invite-trigger').click();
      }
    );
  };
  updateOrganizationUserInfo = (event) => {
    event.preventDefault();
    const { editOrganizationUser, fundSettings } = this.props;
    const currentUserClone = { ...this.state.targetUserEdit };
    if (this.verifyFormValidity('inviteForm')) {
      const updatePayload = {
        email: currentUserClone.email, // avoid changes to these value
        firstName: fundSettings.inviteForm.firstName.value,
        lastName: fundSettings.inviteForm.lastName.value,
      };
      editOrganizationUser(updatePayload);
    }
  };
  inviteSubmit(event) {
    const { inviteUser, organizationContext, fundSettings } = this.props;
    event.preventDefault();
    if (this.verifyFormValidity('inviteForm')) {
      const payload = {
        email: fundSettings.inviteForm.emailAddress.value,
        firstName: fundSettings.inviteForm.firstName.value,
        lastName: fundSettings.inviteForm.lastName.value,
        organization: organizationContext.organization._id,
        inviteCreator: `${organizationContext.user.firstName} ${organizationContext.user.lastName}`,
      };
      inviteUser(payload);
    }
  }
  checkPassword() {
    const { fundSettings } = this.props;
    const pass = fundSettings.userForm.password.value;
    const rePass = fundSettings.userForm.confirmPassword.value;
    if (pass !== '' && rePass !== '') {
      return this.validateConfirmPassword(pass, rePass);
    }
    return null;
  }
  validateConfirmPassword(pass, rePass) {
    const { setUserFormField } = this.props;
    if (pass.length < 14) {
      setUserFormField('matching', 'error', true);
      setUserFormField('matching', 'message', PASSWORD_LENGTH_MIN_MESSAGE);
      return false;
    }
    if (pass.length > 48) {
      setUserFormField('matching', 'error', true);
      setUserFormField('matching', 'message', PASSWORD_LENGTH_MAX_MESSAGE);
      return false;
    }
    if (pass === rePass) {
      return true;
    }
    setUserFormField('matching', 'error', true);
    setUserFormField('matching', 'message', PASSWORD_NOT_MATCH_MESSAGE);
    return false;
  }
  renderModal() {
    const { fundSettings } = this.props;
    return (
      <div
        className="modal fade"
        id="invite"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a
                id="close-invite-modal"
                href="#/"
                data-dismiss="modal"
                aria-label="Close"
                className="pop-up-close color-white background-color-dark-green "
              >
                <i className="fa fa-times" aria-hidden="true" />
              </a>
            </div>
            <div className="modal-body">
              `
              <div className="popup">
                <h4 className="color-dark-green text-center">
                  {this.state.isEditingUser ? 'Edit User' : 'Invite User'}
                </h4>
                <hr />
                <div className="row">
                  <div className="col-lg-9 center-block">
                    {!this.state.isEditingUser && (
                      <p>
                        Invite another member of your organization. They will
                        receive an email with a link to create an account under
                        your organization on the Fund Formation Portal.
                      </p>
                    )}
                  </div>
                  <br />
                </div>
                <form
                  onSubmit={
                    this.state.isEditingUser
                      ? this.updateOrganizationUserInfo
                      : this.inviteSubmit
                  }
                >
                  <div className="row">
                    <div className="col-lg-6">
                      {this.renderTextInput(
                        'firstName',
                        'firstName',
                        'text',
                        fundSettings.inviteForm.firstName.value,
                        'First Name',
                        false,
                        'large',
                        !fundSettings.inviteForm.firstName.valid,
                        fundSettings.inviteForm.firstName.errorMessage,
                        '',
                        false,
                        this.handleInviteFieldsChange
                      )}
                    </div>
                    <div className="col-lg-6">
                      {this.renderTextInput(
                        'lastName',
                        'lastName',
                        'text',
                        fundSettings.inviteForm.lastName.value,
                        'Last Name',
                        false,
                        'large',
                        !fundSettings.inviteForm.lastName.valid,
                        fundSettings.inviteForm.lastName.errorMessage,
                        '',
                        false,
                        this.handleInviteFieldsChange
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      {this.renderTextInput(
                        'emailAddress',
                        'emailAddress',
                        'text',
                        fundSettings.inviteForm.emailAddress.value,
                        'Email',
                        false,
                        'large',
                        !fundSettings.inviteForm.emailAddress.valid,
                        fundSettings.inviteForm.emailAddress.errorMessage,
                        '',
                        this.state.isEditingUser,
                        this.handleInviteFieldsChange
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="row adminform-footer text-align-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <Button
                        inputData={{
                          id: 'new-fund-back',
                          displayName: 'Back',
                          isBack: true,
                          type: 'button',
                        }}
                        clickHandler={(e) => {
                          e.preventDefault();
                          document
                            .getElementById('invite-modal-dismiss')
                            .click();
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        inputData={{
                          id: 'new-invite-submit',
                          displayName: 'Submit',
                          type: 'submit',
                        }}
                      />
                    </div>
                  </div>
                </form>
                <div className="row text-center">
                  <p>
                    <a id="invite-modal-dismiss" href="#/" data-dismiss="modal">
                      Return to Settings
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    );
  }

  handleChangeFilterItem(event) {
    event.preventDefault();
    let index = '-1';
    switch (event.target.innerText) {
      case 'First Name':
        index = '0';
        break;
      case 'Last Name':
        index = '1';
        break;
      case 'Email':
        index = '2';
        break;
      default:
        index = '-1';
        break;
    }
    if (this.state.sortByCol === index) {
      this.setState({
        sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({ sortByCol: index.toString(), sortDirection: 'desc' });
    }
  }

  // eslint-disable-next-line
  renderTableContents() {
    // eslint-disable-next-line react/prop-types
    const { organizationContext } = this.props;
    const rowActions = [];

    const headers = [
      {
        title: 'First Name',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        selected: this.state.sortByCol === '0',
        filteringOrder: this.state.sortDirection,
      },
      {
        title: 'Last Name',
        class: 'investment-sm horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        selected: this.state.sortByCol === '1',
        filteringOrder: this.state.sortDirection,
      },
      {
        title: 'Email',
        class: 'investment-lg horizontal-col',
        filteringArrow: true,
        filteringHandler: this.handleChangeFilterItem,
        selected: this.state.sortByCol === '2',
        filteringOrder: this.state.sortDirection,
      },
      { title: '', class: '' },
      { title: '', class: '' },
    ];

    const results = organizationContext.organization.iqUsers.map((iqU) => {
      const rowTemplate = [
        {
          id: 'firstName-res-col',
          displayName: iqU.firstName,
          type: 'item',
          class: 'investment-sm horizontal-col overflow-scroll-large-content',
        },
        {
          id: 'lastName-res-col',
          displayName: iqU.lastName,
          type: 'description',
          class: 'investment-sm horizontal-col',
        },
        {
          id: 'email-res-col',
          displayName: iqU.email,
          type: 'description',
          class: 'investment-md horizontal-col overflow-scroll-large-content',
        },
        {
          id: 'edit-res-col',
          type: 'button',
          actionResult: true,
          customActionRender: 'link',
          customActionRenderDisplayName: 'Edit',
          actionResultIdentifier: iqU._id,
          actionResultText: '',
          actionCallback: this.selectUserEdit,
          class: 'table-edit-button',
        },
        {
          id: 'delete-res-col',
          type: 'button',
          actionResult: true,
          customActionRender: 'link',
          customActionRenderDisplayName: 'Delete',
          actionResultIdentifier: iqU._id,
          actionResultText: '',
          actionCallback: this.deleteOrgUser,
          class: 'table-delete-button',
        },
      ];
      return rowTemplate;
    });
    const sortData =
      this.state.sortByCol !== '-1'
        ? utility.gunderSort(results, this.state.sortByCol)
        : results;
    return {
      headers,
      results:
        this.state.sortDirection === 'desc' ? sortData : sortData.reverse(),
      rowActions,
    };
  }

  renderOrganizationSection = () => {
    const { fundSettings } = this.props;
    return (
      <div className="col-lg-10 col-md-10 col-sm-11 col-xs-12 white-col-bg main-box">
        <h3 className="color-dark-green box-heading">Manage Organization</h3>
        <hr className="row" />
        <form onSubmit={this.updateOrganizationInfo}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="profile-main-content">
                {this.renderTextInput(
                  'organizationName',
                  'organizationName',
                  'text',
                  fundSettings.organizationForm.organizationName.value,
                  'Organization Name (or your Full Name, if this is a personal account)',
                  false,
                  'large',
                  !fundSettings.organizationForm.organizationName.valid,
                  fundSettings.organizationForm.organizationName.errorMessage,
                  '',
                  false,
                  this.handleOrganizationFieldsChange
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              {this.renderTextInput(
                'city',
                'city',
                'text',
                fundSettings.organizationForm.city.value,
                'City',
                false,
                'large',
                !fundSettings.organizationForm.city.valid,
                fundSettings.organizationForm.city.errorMessage,
                '',
                false,
                this.handleOrganizationFieldsChange
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="profile-main-content">
                {this.renderTextInput(
                  'state',
                  'state',
                  'text',
                  fundSettings.organizationForm.state.value,
                  'State/Province',
                  false,
                  'large',
                  !fundSettings.organizationForm.state.valid,
                  fundSettings.organizationForm.state.errorMessage,
                  '',
                  false,
                  this.handleOrganizationFieldsChange
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="profile-main-content">
                {this.renderTextInput(
                  'country',
                  'country',
                  'text',
                  fundSettings.organizationForm.country.value,
                  'Country',
                  false,
                  'large',
                  !fundSettings.organizationForm.country.valid,
                  fundSettings.organizationForm.country.errorMessage,
                  '',
                  false,
                  this.handleOrganizationFieldsChange
                )}
              </div>
            </div>
          </div>
          <div className="bottom-divider-line">
            <hr className="row" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
            <br />
            <Button
              inputData={{
                id: 'admin-user-submit',
                displayName: 'Submit',
                type: 'submit',
              }}
            />
            <br />
            <br />
          </div>
        </form>
      </div>
    );
  };

  renderUsersSection = () => (
    <div className="col-lg-10 col-md-10 col-sm-11 col-xs-12 white-col-bg main-box">
      <div className="">
        <h3 className="color-dark-green highlight-drop-down-title-float box-heading">
          Manage Users
        </h3>
        <div className="text-right invite-btn-container">
          <button onClick={this.handleInviteUser} id="handle-invite-user-btn">
            + Invite User
          </button>
          <a
            id="modal-invite-trigger"
            href="#/"
            data-toggle="modal"
            data-target="#invite"
          />
        </div>
      </div>
      <hr className="row" />
      <TableContainer
        content={this.renderTableContents()}
        loadMoreHandler={this.handleLoadMore}
      />
      {this.renderModal()}
    </div>
  );

  renderProfileSection = () => {
    const { fundSettings } = this.props;

    return (
      <div className="col-lg-10 col-md-10 col-sm-11 col-xs-12 white-col-bg main-box">
        <h3 className="color-dark-green box-heading">Manage Your Profile</h3>
        <hr className="row" />
        <form onSubmit={this.updateUserProfileInfo}>
          <div className="">
            <div className="row">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="profile-main-content">
                    {this.renderTextInput(
                      'firstName',
                      'firstName',
                      'text',
                      fundSettings.userForm.firstName.value,
                      'First Name',
                      false,
                      'large',
                      !fundSettings.userForm.firstName.valid,
                      fundSettings.userForm.firstName.errorMessage,
                      '',
                      false,
                      this.handleUserFieldsChange
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  {this.renderTextInput(
                    'lastName',
                    'lastName',
                    'text',
                    fundSettings.userForm.lastName.value,
                    'Last Name',
                    false,
                    'large',
                    !fundSettings.userForm.lastName.valid,
                    fundSettings.userForm.lastName.errorMessage,
                    '',
                    false,
                    this.handleUserFieldsChange
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  {this.renderTextInput(
                    'email',
                    'email',
                    'text',
                    fundSettings.userForm.email.value,
                    'Email',
                    false,
                    'large',
                    !fundSettings.userForm.email.valid,
                    fundSettings.userForm.email.errorMessage,
                    '',
                    true,
                    this.handleUserFieldsChange
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="profile-main-content">
                    {this.renderTextInput(
                      'password',
                      'password',
                      'password',
                      fundSettings.userForm.password.value,
                      'New Password',
                      false,
                      'large',
                      !fundSettings.userForm.password.valid,
                      fundSettings.userForm.password.errorMessage,
                      'Your password must be 14 characters long.',
                      false,
                      this.handleUserFieldsChange
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="profile-main-content">
                    {this.renderTextInput(
                      'confirmPassword',
                      'confirmPassword',
                      'password',
                      fundSettings.userForm.confirmPassword.value,
                      'Confirm Password',
                      false,
                      'large',
                      !fundSettings.userForm.confirmPassword.valid,
                      fundSettings.userForm.confirmPassword.errorMessage,
                      '',
                      false,
                      this.handleUserFieldsChange
                    )}
                  </div>
                </div>
              </div>
              <div className="bottom-divider-line">
                <hr />
              </div>
              <div className="row">
                {fundSettings.userForm.matching.error ? (
                  <div className="error-message not-match-pass red-color">
                    <span className="text">
                      {fundSettings.userForm.matching.message}
                    </span>
                  </div>
                ) : (
                  <div className="error-message not-match-pass green-color">
                    <span className="text">
                      {fundSettings.userForm.matching.message}
                    </span>
                  </div>
                )}
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
                  <Button
                    inputData={{
                      id: 'admin-user-submit',
                      displayName: 'Submit',
                      type: 'submit',
                    }}
                  />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };
  render() {
    const { context, fundSettings } = this.props;
    return (
      <div className="main-container">
        <Helmet title="Settings" />
        <div className="container">
          <h3 className="new-sections-header-title-style"> SETTINGS</h3>
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-11 col-xs-12">
              <VerticalMenu
                active={fundSettings.content}
                clickHandler={this.handleChange}
              />
            </div>
            {/* <Alert
              inputData={{
                type: context.notificationType,
                title: context.title,
                message: context.message,
              }}
            /> */}
            <Notifications />
            {fundSettings.content === 'profile' && this.renderProfileSection()}
            {fundSettings.content === 'organization' &&
              this.renderOrganizationSection()}
            {fundSettings.content === 'users' && this.renderUsersSection()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: Reducer) => ({
  organizationContext: state.organizationContext,
  context: state.context,
  fundSettings: state.fundSettings,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    inviteUser: (payload) => dispatch(action.inviteUser(payload)),
    editOrganization: (payload) => dispatch(action.editOrganization(payload)),
    editUserProfile: (payload) => dispatch(action.editUserProfile(payload)),
    editOrganizationUser: (payload) =>
      dispatch(action.editOrganizationUser(payload)),
    deleteOrganizationUser: (userId) =>
      dispatch(action.deleteOrganizationUser(userId)),
    setCurrentPath: (content) => dispatch({ type: 'SET_CONTENT', content }),
    setUserFormField: (fieldName, fieldProperty, fieldPropertyValue) =>
      dispatch({
        type: 'SET_USER_FORM',
        fieldName,
        fieldProperty,
        fieldPropertyValue,
      }),
    setInviteFormField: (fieldName, fieldProperty, fieldPropertyValue) =>
      dispatch({
        type: 'SET_INVITE_FORM',
        fieldName,
        fieldProperty,
        fieldPropertyValue,
      }),
    setOrganizationFormField: (fieldName, fieldProperty, fieldPropertyValue) =>
      dispatch({
        type: 'SET_ORGANIZATION_FORM',
        fieldName,
        fieldProperty,
        fieldPropertyValue,
      }),
    hideNotification: () => dispatch({ type: 'HIDE_NOTIFICATION' }),
    resetForms: () => dispatch({ type: 'RESET_FORMS' }),
    resetInviteForm: () => dispatch({ type: 'RESET_INVITE_FORM' }),
    renderNotification: () =>
      dispatch({
        type: action.RENDER_NOTIFICATION,
        notificationType: 'error',
        message: "Can't delete current user",
        title: 'Something went wrong',
      }),
  })
);
export default connector(FundSettings);
