/* eslint-disable*/
import { notify } from 'react-notify-toast';
import clone from 'clone';
import { indexOf } from 'lodash';

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const locationSearch = (location && location.search) ? location.search : '';
  const results = regex.exec(locationSearch);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const showNotification = (msg) => {
  const toastColor = { background: '#285E83', text: '#FFFFFF' };
  notify.show(msg, 'custom', 100000, toastColor);
}

const sortByKey = (array, sortKey) => {
  const key = 'displayName';
  if (sortKey && sortKey.indexOf('Capital Committed') === 1) {
    sortKey = 'Capital Committed';
  }
  switch (sortKey) {
    case 'Amount':
    case 'Capital Committed':
      return array.sort((a, b) => parseExpr(b[key]) - parseExpr(a[key]));
    case 'createdAt':
       return array.sort((a, b) => new Date(b[key]) - new Date(a[key]));
    default:
      return array.sort((a, b) => {
        const aSort = a[key].toLowerCase();
        const bSort = b[key].toLowerCase();
        if (aSort === '') {
          return 1;
        }
        if (bSort === '') {
          return -1;
        }
        if (aSort === bSort) {
          return 0;
        }
         return aSort.localeCompare(bSort);
      });
  }
}

export const gunderSort = (array, indexCol, sortKey = null) => {
  const tempArray = [];
  const arrayClone = clone(array);
  const allItems = [];
  for (let i = 0; i < arrayClone.length; i += 1) {
    const item = arrayClone[i];

    if (sortKey) {
      // indexCol is the id of the column to sort by
      tempArray.push(item.find(x => x.id === indexCol));
    } else {
      tempArray.push(item[indexCol]);
    }
    
    for (let j = 0; j < item.length; j += 1) {
      item[j].uniqueIdentifier = i;
      allItems.push(item[i]);
    }
  }
  const arraySorted = sortByKey(tempArray, sortKey);
  const results = [];
  for (let i = 0; i < arraySorted.length; i += 1) {
    if (arraySorted[i] && arraySorted[i].uniqueIdentifier !== undefined) {
      results[i] = arrayClone[arraySorted[i].uniqueIdentifier];
    }
  }
  return results;
}

const parseExpr = exp => {
  if (!exp) return 0;
  return (exp[0] == '<' ? -1 : 1) + parseInt(exp.replace(/[,.]/g, '').substr(1));
}


export const passwordStrengthValidation = (pwString) => {
    let strength = 0;
    let errorMsj = ''
    strength += /[A-Z]+/.test(pwString) ? 1 : 0;
    strength += /[a-z]+/.test(pwString) ? 1 : 0;
    strength += /[0-9]+/.test(pwString) ? 1 : 0;
    strength += /[\W]+/.test(pwString) ? 1 : 0;
    switch(strength) {
        case 3: // medium
          errorMsj = 'Please include at least one number, special character and lower and upper case characters';
          break;
        case 4: // strong
          errorMsj = '';
          break;

        default: // weak
          errorMsj = 'Password strength is too weak';
          break;
    }
    return { strength, errorMsj };
}