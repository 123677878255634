/* eslint-disable react/sort-comp */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import Helmet from 'react-helmet';
import * as action from './action';
import {
  Context as ContextType,
  OrganizationContext as OrganizationContextType,
  Reducer,
  Dispatch,
} from '../../types/index';
import TextInput from '../../components/TextInput/index';
import Button from '../../components/Button/index';
import Alert from '../../components/Alert/index';
import * as validations from '../../utils/form-validation';
import * as notify from '../../utils/notify';

type Props = {
  history: {},
  context: ContextType,
  organizationContext: OrganizationContextType,
  addOrganization: () => {},
  notifyAlreadyCreatedOrg: () => {},
};

// Export this for unit testing more easily
export class AddOrganization extends PureComponent {
  props: Props;
  state = {
    organizationName: {
      displayName: 'Name',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    city: {
      displayName: 'City',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    state: {
      displayName: 'State',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },
    country: {
      displayName: 'Country',
      value: '',
      valid: true,
      isRequired: true,
      errorMessage: '',
      validators: ['required'],
    },

    loading: { submit: false, data: false },
  };
  static defaultProps: {
    history: {},
    context: {},
    organizationContext: {},
    addOrganization: () => {},
    notifyAlreadyCreatedOrg: () => {},
  };

  constructor(props) {
    super(props);
    this.handleLink = this.handleLink.bind(this);
    this.handlerTextInput = this.handlerTextInput.bind(this);
    this.handlerFormSubmit = this.handlerFormSubmit.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
  }
  componentDidMount() {
    if (document.title !== 'Fund Formation Portal - Add Organization')
      notify.hideNotification();
  }
  verifyFormValidity = () => {
    for (let i = 0; i < Object.keys(this.state).length; i += 1) {
      const keyName = Object.keys(this.state)[i];
      /** formValid is not a field * */
      if (keyName !== 'loading') {
        const validationPassed = this.runFieldValidations(
          keyName,
          this.state[keyName].value
        );
        if (!validationPassed) {
          return false;
        }
      }
    }
    return true;
  };
  runFieldValidations = (fieldName, value) => {
    let validationResult = {};
    for (let i = 0; i < this.state[fieldName].validators.length; i += 1) {
      const validator = this.state[fieldName].validators[i];
      switch (validator) {
        case 'required':
          validationResult = validations.required(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} is required`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          });
          if (!validationResult) {
            return false;
          }
          break;
        case 'email':
          validationResult = validations.email(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false ? 'Wrong email format' : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'number':
          validationResult = validations.number(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} must be a number`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        default:
          return true;
      }
    }
    return true;
  };
  // eslint-disable-next-line
  handleLink(event) {
    if (event) event.preventDefault();
    // window.history.back();
  }

  handlerTextInput(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      [event.target.id]: {
        ...this.state[event.target.id],
        value: event.target.value,
      },
    });
  }

  clearInputs() {
    this.setState({
      ...this.state,
      organizationName: { ...this.state.organizationName, value: '' },
      city: { ...this.state.city, value: '' },
      state: { ...this.state.state, value: '' },
      country: { ...this.state.country, value: '' },
    });
  }

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={this.handlerTextInput}
      />
    );
  };

  handlerFormSubmit(event) {
    event.preventDefault();
    const {
      addOrganization,
      organizationContext,
      history,
      notifyAlreadyCreatedOrg,
    } = this.props;
    const payload = {
      name: this.state.organizationName.value,
      city: this.state.city.value,
      state: this.state.state.value,
      country: this.state.country.value,
      userId: organizationContext.user._id,
      token: localStorage.getItem('organization-auth-token') || '',
    };
    if (this.verifyFormValidity()) {
      if (!this.props.organizationContext.user.organization) {
        addOrganization(payload, history);
      } else {
        notifyAlreadyCreatedOrg();
      }
    }
  }

  render() {
    const { context } = this.props;
    return (
      <div className="main-container">
        <div className="container">
          <Helmet title="Add Organization" />
          {/* <Alert
            inputData={{
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="page-top-row">
                {/* <a href="" onClick={this.handleLink} className="back-to color-black">
                  <i className="fa fa-angle-left" aria-hidden="true" /> Back
                </a> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <form onSubmit={this.handlerFormSubmit}>
                <div className="content-bg signup-main">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center center-block">
                      <h3 className="color-dark-green">ADD ORGANIZATION</h3>
                      <p>
                        Please enter the name of your organization or firm (or
                        your name, if you are creating an account for your
                        personal investments). You will be asked to add one or
                        more profiles for investors in the next step.
                      </p>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="profile-main-content">
                          {this.renderTextInput(
                            'organizationName',
                            'organizationName',
                            'text',
                            this.state.organizationName.value,
                            'Organization Name (or your Full Name, if this is a personal account)',
                            false,
                            'large',
                            !this.state.organizationName.valid,
                            this.state.organizationName.errorMessage,
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        {this.renderTextInput(
                          'city',
                          'city',
                          'text',
                          this.state.city.value,
                          'City',
                          false,
                          'large',
                          !this.state.city.valid,
                          this.state.city.errorMessage
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="profile-main-content">
                          {this.renderTextInput(
                            'state',
                            'state',
                            'text',
                            this.state.state.value,
                            'State/Province',
                            false,
                            'large',
                            !this.state.state.valid,
                            this.state.state.errorMessage,
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="profile-main-content">
                          {this.renderTextInput(
                            'country',
                            'country',
                            'text',
                            this.state.country.value,
                            'Country',
                            false,
                            'large',
                            !this.state.country.valid,
                            this.state.country.errorMessage,
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-align-center">
                        <br />
                        <br />
                        <Button
                          inputData={{
                            id: 'admin-user-submit',
                            displayName: 'Submit',
                            type: 'submit',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  organizationContext: state.organizationContext,
});

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    addOrganization: (payload, history) =>
      dispatch(action.addOrganization(payload, history)),
    notifyAlreadyCreatedOrg: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Organization for your user is already created',
        title: 'Error',
      }),
  })
);

export default connector(AddOrganization);
