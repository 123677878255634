import React from 'react';
import './styles.scss';
import Popup from '../Popup/index';
import Button from '../Button';


type Props = { data: Object };

const renderIconItemContent = data => (
  <div>{
    (data.displayName) ?
      <div>
        <span className={data.iconPos === 'right' ? 'pull-left' : 'align-text-pull-right investment-status'}>{data.displayName}</span>
        {(data.dataTarget && data.dataToggle) ?
          <a href="#/" data-toggle={data.dataToggle} data-target={`#${data.dataTarget}`} onClick={data.clickHandler} className={data.iconPos === 'right' ? 'share-btn pull-right' : 'share-btn pull-left'}>
            <span id={`icon-share1/${data.id}`} data={data.modalDisplayName} className="color-black">share</span> &nbsp;&nbsp;
            <i id={`icon-share2/${data.id}`} data={data.modalDisplayName} className={`fa ${data.icon} color-light-black`} aria-hidden="true" />
          </a> :
          <a href="#/" onClick={data.clickHandler} className={data.iconPos === 'right' ? 'share-btn align-text-pull-right' : 'share-btn pull-left'}>
            <i className={`fa ${data.icon} color-light-black`} aria-hidden="true" data-target={`${data.dataTarget}`} data-type={`${data.dataType}`} />
          </a>}
        {(data.popup) ?
          <Popup
            data={{
              id: `modalShareFund-${data.id}`,
              shareId: data.id,
              displayName: data.modalDisplayName,
              popupType: 'share',
              title: data.modalTitle,
              messageValue: data.messageValue,
              pathValue: data.pathValue,
            }}
            emailChangeHandler={data.emailChangeHandler}
            messageChangeHandler={data.messageChangeHandler}
            shareLinkChangeHandler={data.shareLinkChangeHandler}
            actionCallback={data.actionCallback}
          /> : null}
      </div> :
      <a href="#/" className="share-btn pull-right">
        <i className={`fa ${data.icon} color-light-black`} />
      </a>}
  </div>
);

const renderResultContent = (data) => {
  if (!data.actionResult) {
    return (<div>{data.displayName}</div>);
  } else if (data.editable) {
    return (<div key={data.id}>
      {data.displayName !== '' ?
        (<a href="#/" className={`color-light-black ${data.editing ? 'hidden' : ''}`} data-identifier={data.actionResultIdentifier} data-type={data.dataType} data-property={data.editingProperty} data-textarea-ref={`textarea-${data.inputIdentifier}`} onClick={data.editFieldHandler} >
          {data.displayName}
        </a>) :
        (<a href="#/" className={`color-light-black ${data.editing ? 'hidden' : ''}`} data-identifier={data.actionResultIdentifier} data-type={data.dataType} data-property={data.editingProperty} data-textarea-ref={`textarea-${data.inputIdentifier}`} onClick={data.editFieldHandler} >
          {data.emptyText}
        </a>)}
      <textarea className={`editing-textarea ${data.editing ? '' : 'hidden'}`} data-identifier={data.actionResultIdentifier} id={`textarea-${data.inputIdentifier}`} data-type={data.dataType} data-property={data.editingProperty} onChange={data.inputChangeHandler} onBlur={data.actionCallback} value={data.displayName} />
    </div>);
  } else if (data.actionResult && data.actionResultText !== '') {
    return (<div className="row">
      <span
        role="link"
        tabIndex="0"
        data-identifier={data.actionResultIdentifier}
        data-type={data.dataType}
        onClick={data.link}
      >{data.displayName}</span>
      <a
        href="#/"
        className="color-light-black action-margin"
        data-identifier={data.actionResultIdentifier}
        data-type={data.dataType}
        onClick={data.actionCallback}
      >
        {data.actionResultText}
      </a>
    </div>);
  } else if (data.customActionRender && data.actionResult) {
    return (<div className="row">
      {data.customActionRender === 'button' && <Button
        inputData={{
          id: `custom-action-button-${data.actionResultIdentifier}`,
          displayName: data.customActionRenderDisplayName,
          dataIdentifier: data.actionResultIdentifier,
          size: 'small',
        }}
        clickHandler={data.actionCallback}
      />}
      {data.customActionRender === 'link' &&
        <a
          href="#/"
          className={data.class}
          data-identifier={data.actionResultIdentifier}
          onClick={data.actionCallback}
        >{data.customActionRenderDisplayName}</a>}
    </div>);
  }
  return (<div className="row">
    <a href="#/" className="color-light-black" data-identifier={data.actionResultIdentifier} data-type={data.dataType} onClick={data.actionCallback}>
      {data.displayName}
    </a>
  </div>);
};


const TableResultRowItem = ({ data }: Props) => (
  <div className={`result-${data.type} ${data.class} `}>
    {(data.icon && data.icon !== '') ? renderIconItemContent(data) : renderResultContent(data)}
  </div>
);

TableResultRowItem.defaultProps = {
  data: {
    displayName: '',
    type: '',
    dataToggle: '',
    dataTarget: '',
    modalDisplayName: '',
    modalTitle: '',
    icon: '', // cannot be both icon and actionResult
    actionResult: false,
    actionResultText: '',
    actionResultIdentifier: '', // use if you need to have a value for callback
    emailChangeHandler: () => { },
    messageChangeHandler: () => { },
    actionCallback: () => { },
    clickHandler: () => { },
    editing: false,
    editFieldHandler: () => { },
    inputChangeHandler: () => { },
    editingProperty: '',
    inputIdentifier: '',
    emptyText: '',
    link: () => { },
  },
};

export default TableResultRowItem;

