import React from 'react';
import './styles.scss';

import Button from '../Button/index';
import TextInput from '../TextInput/index';

type Props = {
  data: Object,
  emailChangeHandler: () => void,
  messageChangeHandler: () => void,
  shareLinkChangeHandler: () => void,
  actionCallback: () => void,
  leaveAction: () => void,
};
// FIXME need to change so its dynamic independent from where its added

const renderSharePopup = (
  data,
  emailChangeHandler,
  messageChangeHandler,
  shareLinkChangeHandler,
  actionCallback
) => (
  <div
    className="modal fade share_modal ng-scope in"
    id={data.id}
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <a
            id={`close_modal_icon${data.shareId}`}
            data-dismiss="modal"
            aria-label="Close"
            className="pop-up-close color-white background-color-dark-green"
            href="#/"
          >
            <i className="fa fa-times icon-pop-up-close" aria-hidden="true" />
          </a>
        </div>
        <div className="modal-body">
          <div className="popup text-align-center">
            <h4 className="color-dark-green">{data.title}</h4>
            <h5>{data.displayName}
            {data.displayNameFund2 ? 
            `${!data.displayNameFund3 ? ' and' : ',' } ${data.displayNameFund2} ${data.displayNameFund3 ? 'and' + data.displayNameFund3 
            : '' }` 
            : ''} 
            </h5>
            <hr />
            <div className="row text-align-left">
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 center-block clearfix">
                <TextInput
                  inputData={{
                    id: `input-team-member${data.shareId}`,
                    displayName: 'Invite Investors',
                    placeholder: 'Email (separate multiple emails with commas)',
                    name: 'emailsText',
                    isRequired: true,
                    hasError: false,
                  }}
                  onChangeHandler={emailChangeHandler}
                />
                <h5 className="color-black">Message</h5>
                <div className="form-group input-group-style ng-scope">
                  <fieldset>
                    <textarea
                      value={data.messageValue}
                      className="form-control input-style small"
                      name="messageText"
                      onChange={messageChangeHandler}
                      placeholder="Cover letter or unique message"
                    />
                  </fieldset>
                </div>
                <div className="pull-right">
                  <Button
                    inputData={{ id: 'sendMail-button', displayName: 'Send' }}
                    clickHandler={actionCallback}
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 center-block clearfix or-center-arrow">
                <div className="divider-row background-color-green">
                  <span className="divider-text color-green">OR</span>
                </div>
              </div>
              <br />
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 center-block clearfix">
                <TextInput
                  inputData={{
                    displayName: 'Copy Share Link',
                    name: 'shareLink_text',
                    isRequired: true,
                    requiredLegendIndicator: false,
                    hasError: false,
                    value: `${data.pathValue}/access/login?f=${data.shareId}`,
                    noTitle: false,
                    wrapperClass: 'input-share-link-general-class',
                    hasCopyButton: true,
                  }}
                  onChangeHandler={shareLinkChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer" />
      </div>
    </div>
  </div>
);

const renderInfoPopup = (data, leaveAction) => (
  <div
    className="modal fade"
    id={data.id}
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <a
            href="#/"
            data-dismiss="modal"
            aria-label="Close"
            className="pop-up-close color-white background-color-dark-green "
          >
            <i className="fa fa-times" aria-hidden="true" />
          </a>
        </div>
        <div className="modal-body">
          `
          <div className="text-align-center">
            <h4 className="color-dark-green">{data.title}</h4>
            <p className="color-black">
              Your progress has been securely saved.
            </p>
            <p className="color-black">
              Please follow the link in your email to return later <br />
              to complete the investor Questionnaire.
            </p>
            <Button
              inputData={{ id: 'leaveBtn', displayName: 'Leave' }}
              clickHandler={leaveAction}
            />
            <p>
              <a href="#/" data-dismiss="modal">
                Return to Questionnaire
              </a>
            </p>
          </div>
        </div>
        <div className="modal-footer" />
      </div>
    </div>
  </div>
);

const Popup = ({
  data,
  emailChangeHandler,
  messageChangeHandler,
  shareLinkChangeHandler,
  actionCallback,
  leaveAction,
}: Props) => (
  <div>
    {data.popupType === 'share'
      ? renderSharePopup(
          data,
          emailChangeHandler,
          messageChangeHandler,
          shareLinkChangeHandler,
          actionCallback
        )
      : renderInfoPopup(data, leaveAction)}
  </div>
);

Popup.defaultProps = {
  data: {
    id: '',
    shareId: '',
    displayName: '',
    displayNameFund2: '',
    displayNameFund3: '',
    popupType: '',
    title: '',
    messageValue: '',
    pathValue: '',
  },
  emailChangeHandler: () => {},
  messageChangeHandler: () => {},
  shareLinkChangeHandler: () => {},
  actionCallback: () => {},
  leaveAction: () => {},
};

export default Popup;
