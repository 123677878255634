import { IQ_SHOW_EXPIRATION_NOTICE } from '../containers/QuestionnaireExperience/reducer';

// eslint-disable-next-line import/prefer-default-export
export const handleError = (err, state, dispatch) => {
  if (
    (err.request && err.request.status === 401) ||
    (err.response && err.response.status === 401)
  ) {
    if (!state.authContainerRe.sessionTimeout) {
      dispatch({ type: 'SHOW_SESSION_TIMEOUT_ALERT', sessionTimeout: true });
    }
  }
};

export const handleIQError = (err, state, dispatch) => {
  if (err.request.status === 401) {
    if (!state.tracker.iqAccessExpired) {
      dispatch({ type: 'SHOW_IQ_EXPIRED_ALERT', iqAccessExpired: true });
    }
  }
};
export const handleQuestionnaireError = (_error, _currentContext, _dispatch) => {
  if (_error.request.status === 401) {
    if (!_currentContext.questionnaireAccessExpired) {
      _dispatch({ type: IQ_SHOW_EXPIRATION_NOTICE, questionnaireAccessExpired: true });
    }
  } else {
    console.error(_error);
  }
};
