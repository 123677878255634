import React, { useEffect, useState } from 'react';
import './styles.scss';
import TableColumn from '../TableColumn/index';
import TableResultRow, { DropdownStatus, DropdownStatusRowItem } from '../TableResultRow/index';
import Pagination from '../Pagination/index';
import ReactHtmlParser from 'react-html-parser';
import { STATUS_TYPES } from './../../utils/constants/status';
import DateTimeZone from './../../utils/date-time-zone';

/**
 * @typedef {object} Props
 * @property {object} content
 * @property {Function} loadMoreHandler
 * @property {boolean} canLoad
 * @property {boolean} horizontalScroll
 * @property {string} noResultsText
 * @property {any} paginationConf
 * @property {Number} selectedIndex
 * @property {Function} statusMenuCallback
 * @property {boolean} scrollable
*/

const CheckboxButton = ({isChecked, setItemsToUpdate, removeItem}) => (
 !isChecked ? <p 
    onClick={setItemsToUpdate} 
    className="checkbox-status-style">&#9744;
  </p>
  :
  <p onClick={removeItem} 
  style={{ color: '#285E83' }}
    className="checkbox-status-style is-checked">&#9745;
  </p>
)

const TableContainer = ({
  content,
  loadMoreHandler,
  canLoad,
  horizontalScroll,
  noResultsText,
  selectedIndex,
  statusMenuCallback,
  paginationConf,
  scrollable,
}) => {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [selectedDay, setSelectedDay] = useState(new DateTimeZone())
  const [itemsToUpdate,  setItemsToUpdate] = useState([])
  const [rowItem, setRowItem] = useState(null)
  const [rowIndex, setRowIndex] = useState(-1)


  useEffect(() => {
    // Detect all clicks on the document
    document.addEventListener('click', function (event) {
      // If user clicks inside the element, do nothing
      if (event.target.closest('.update-status-button-parent')) return;
      // If user clicks outside the element, hide it!
      setIsUpdatingStatus(false)
    });
  }, [])


  const itemsResults = () => {
  let items = []
  content.results.forEach((result) => {
    if (result && result.length > 0){
      result.forEach(item => {
        if(item.id === 'investment-status-col') {
          return  items.push(item)
        }
      })
    } 
  })
  return items
}
  const addStatus = (status) => {
    itemsToUpdate.forEach(item => {
    switch(status) {
      case STATUS_TYPES.READY_TO_CLOSE.label:
        return item.clickHandler(item, STATUS_TYPES.READY_TO_CLOSE.label)
      case STATUS_TYPES.SUBMITTED.label:
        return item.clickHandler(item,  STATUS_TYPES.SUBMITTED.label)
      case STATUS_TYPES.CLOSED.label:
        return item.clickHandler(item, STATUS_TYPES.CLOSED.label, selectedDay, true)
      case STATUS_TYPES.OMIT_FROM_GP_ACCESS.label:
        return item.clickHandler(item, STATUS_TYPES.OMIT_FROM_GP_ACCESS.label)
      case STATUS_TYPES.IN_PROGRESS.label:
        return item.clickHandler(item, STATUS_TYPES.IN_PROGRESS.label)
        default:
          return null
    }
    })
    setIsUpdatingStatus(false)
    setItemsToUpdate([])
  }

  const removeItem = (itemDataTarget) => {
    const newArray = itemsToUpdate.filter(i => i.dataTarget !== itemDataTarget)
    setItemsToUpdate(newArray);
    setIsUpdatingStatus(false);
  };

  const hasSome = (dataTarget) => {
    const has = itemsToUpdate.some((i) => i.dataTarget === dataTarget)
    return !has
  }

  const handleSetItemsToUpdate = (item) => {
    setItemsToUpdate(oldArray => [...oldArray, item])
  }


  return(
  <div className="row">
    <div
      className={
        !horizontalScroll ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12' : ''
      }
    >
    {/* add a dropdown menu to the tracking sheet table */}
    {content.results && content.results.length < 9 ?
        <DropdownStatusRowItem 
          item={rowItem}
          rowIndex={rowIndex}
          selectedIndex={selectedIndex}
          itemsToUpdate={itemsToUpdate}
          setItemsToUpdate={setItemsToUpdate}
          removeItem={removeItem}
          outRowTable={true}
          resultCounts={content.results.length}
          /> : null}

      <div className={`result-table ${scrollable && 'scrollable'}`}>
        <div className="result-table-thead clearfix">
          {content.headers.map((column, index) => (
            <TableColumn key={`${index + 99}col`} data={column} />
          ))}
        </div>
       
        <div
          className={`result-table-body clearfix ${
            !scrollable && 'scrollable-content'
          }`}
        >
        {paginationConf && paginationConf.additionalClass === '-tracking-sheet' ?
           itemsToUpdate.length > 0  &&   
            <div className='update-status-button-parent'>  
              <span className="update-status-button">
                <CheckboxButton 
                    isChecked={itemsToUpdate.length === itemsResults().length} 
                    removeItem={() => setItemsToUpdate([])}  
                    setItemsToUpdate={() => setItemsToUpdate(itemsResults())} 
                    />
                    
              <p onClick={() => setIsUpdatingStatus(!isUpdatingStatus)}>Bulk Status 
                <span style={{top: '-3px', marginLeft: '17px' }} className="status-menu-arrow fa fa-sort-down" />
              </p>
            </span>
          
         {isUpdatingStatus && 
         <DropdownStatus 
            addStatus={addStatus} 
            selectedIndex={selectedIndex}
            showCalendar={showCalendar} 
            selectedDay={selectedDay} 
            setSelectedDay={setSelectedDay}
            setShowCalendar={setShowCalendar}/>}
          </div>
          : null}
          {content.results.length
            ? content.results.map((result, index) => (
                <TableResultRow
                  resultCounts={content.results.length}
                  key={`${index + 99}res`}
                  items={result} // array of results
                  isUpdatingStatus={isUpdatingStatus}
                  itemsToUpdate={itemsToUpdate}
                  setItemsToUpdate={handleSetItemsToUpdate}
                  statusMenuCallback={statusMenuCallback}
                  hasSome={hasSome}
                  actionRowNoAnchor={content.actionRowNoAnchor || false}
                  selectedIndex={selectedIndex}
                  removeItem={removeItem}
                  setRowItem={setRowItem}
                  setRowIndex={setRowIndex}
                  index={index}
                  actionRow={
                    content.rowActions &&
                    content.rowActions.length &&
                    content.rowActions[index]
                      ? content.rowActions[index].actionIdentifier !== ''
                      : false
                  }
                  actionIdentifier={
                    content.rowActions &&
                    content.rowActions.length &&
                    content.rowActions[index]
                      ? content.rowActions[index].actionIdentifier
                      : () => {}
                  }
                  actionRowClickHandler={
                    content.rowActions &&
                    content.rowActions.length &&
                    content.rowActions[index]
                      ? content.rowActions[index].actionRowClickHandler
                      : () => {}
                  }
                />
              ))
            : null}
           
          {!content.results.length && (
            <div className="result-row result-row-col-5 background-color-white clearfix no-results-row">
              <p className="no-results-row-text">
                {ReactHtmlParser(noResultsText) || ''}
              </p>
            </div>
          )}
        </div>
        {canLoad ? (
          <a onClick={loadMoreHandler} href="#/" className="button load-more">
            Load More
          </a>
        ) : null}
      </div>
      <Pagination {...paginationConf} />
    </div>
  </div>
)};

TableContainer.defaultProps = {
  content: {
    headers: [],
    results: [],
    rowActions: [],
  },
  selectedIndex: -1,
  loadMoreHandler: () => {},
  paginationConf: {},
  scrollable: false,
};

export default TableContainer;
