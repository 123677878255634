import type { Dispatch, GetState, ThunkAction } from '../../types';

export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';

const API_URL = '/api';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' &&
        localStorage.getItem('organization-auth-token')
          ? localStorage.getItem('organization-auth-token')
          : 'invalid'
      }`,
    },
  };
}
export const addOrganization = (
  payload: Object,
  history
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  try {
    const res = await axios.post(
      `${API_URL}/iqUsers/addFirstOrganization`,
      payload,
      getConfig()
    );
    if (res.data.success) {
      dispatch({ type: SET_ORGANIZATION_USER, user: res.data.user });
      dispatch({
        type: SET_ORGANIZATION,
        organization: res.data.user.organization,
      });
      setTimeout(() => history.push('/organization/questionnaires'), 1000);
    } else {
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: res.data.message,
        title: 'Error',
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: 'RENDER_NOTIFICATION',
      notificationType: 'error',
      message: err,
      title: 'Error',
    });
  }
};
