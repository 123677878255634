import React from 'react';
import styled from 'styled-components';

const FormSection = ({ children, title }) => {
  return (
    <FormSectionContainer>
      <TitleColumn>
        <Title>{title}</Title>
      </TitleColumn>

      <QuestionsColumn>
        <QuestionsWrapper>{children}</QuestionsWrapper>
      </QuestionsColumn>
    </FormSectionContainer>
  );
};

export default FormSection;

const FormSectionContainer = styled.div`
  display: flex;
  margin-bottom: 64px;
`;

const QuestionsColumn = styled.div`
  width: 60%;
`;

const QuestionsWrapper = styled.div`
  width: 60%;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 21px;
  margin: 0;
`;

const TitleColumn = styled.div`
  width: 38%;
  margin-top: 7px;
`;
