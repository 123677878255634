export const API_URL = () => {
  return '/api';
};

export const SERVICE_CONFIG = () => ({
  headers: {
    Authorization: `Bearer ${
      typeof window !== 'undefined' && localStorage.getItem('auth-token')
        ? localStorage.getItem('auth-token')
        : 'invalid'
    }`
  }
});

export const SERVICE_CONFIG_ORGANIZATION = () => ({
  headers: {
    Authorization: `Bearer ${
      typeof window !== 'undefined' &&
      localStorage.getItem('organization-auth-token')
        ? localStorage.getItem('organization-auth-token')
        : 'invalid'
    }`
  }
});
export const SERVICE_CONFIG_QUESTIONNAIRE = () => ({
  headers: {
    Authorization: `Bearer ${
      typeof window !== 'undefined' && sessionStorage.getItem('iq-cb-token')
        ? sessionStorage.getItem('iq-cb-token')
        : 'invalid'
    }`
  }
});
