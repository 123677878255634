export const resolveSizeClass = (size) => {
  let sizeClass;
  switch (size) {
    case 'small':
      sizeClass = '3';
      break;
    case 'medium':
      sizeClass = '6';
      break;
    case 'large':
      sizeClass = '12';
      break;
    default:
      return size; // expecting a custom class here
  }
  if (size === 'xsmall') {
    return '';
  }
  return `col-lg-${sizeClass} col-md-${sizeClass} col-sm-${sizeClass} col-xs-12`;
};
