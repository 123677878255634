/* eslint-disable react/sort-comp,import/prefer-default-export,class-methods-use-this,max-len,spaced-comment,consistent-return */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import * as notify from '../../../../../../utils/notify';
import Dropdown from '../../../../../../components/Dropdown/index';
import TextInput from '../../../../../../components/TextInput/index';
import Title from '../../../../../../components/Title/index';
import '../../../LP/GeneralInformation/styles.scss';
import SubTitle from '../../../../../../components/SubTitle/index';
import Button from '../../../../../../components/Button/index';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import FundTool from '../../../../../../components/FundTool/index';
import Alert from '../../../../../../components/Alert/index';
import ConfirmCheckbox from '../../../../../../components/ConfirmCheckbox/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import ReactHtmlParser from 'react-html-parser';
import {
  IQ_SET_LAST_FOCUSED_FIELD,
  IQ_IS_SAVING_GENERAL_INFORMATION,
  IQ_IS_ADDITIONAL_INFO,
} from '../../../../reducer';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import { saveGeneralInformation } from '../../../../util';
import {
  caStatesAbbr,
  usStatesAbbr,
  countries,
  checkSelectedCountries,
  checkOptionals,
  CANADA,
  USA,
  permittedStates,
  permittedCountries,
} from '../../../../../../utils/location.util';
import TextArea from 'components/TextArea';
import { API_URL, SERVICE_CONFIG_QUESTIONNAIRE } from 'utils/service-commons';
import CustomTooltip from 'components/Tooltip';
import DateTimeZone from '../../../../../../utils/date-time-zone';

type Props = {
  history: Object,
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};

const checkOptions = [
  {
    id: 'org-type-radio-1',
    displayName: 'Corporation',
    isSelected: false,
    name: '',
    identifier: 'Corporation',
  },
  {
    id: 'org-type-radio-2',
    displayName: 'Estate',
    isSelected: false,
    name: '',
    identifier: 'Estate',
  },
  {
    id: 'org-type-radio-3',
    displayName: 'US Tax-Exempt Organization',
    isSelected: false,
    name: '',
    identifier: 'US Tax-Exempt Organization',
  },
  {
    id: 'org-type-radio-4',
    displayName: 'Fiduciary',
    isSelected: false,
    name: '',
    identifier: 'Fiduciary',
  },
  {
    id: 'org-type-radio-5',
    displayName: 'Grantor Trust',
    isSelected: false,
    name: '',
    identifier: 'Grantor Trust',
  },
  {
    id: 'org-type-radio-6',
    displayName: 'Natural Person',
    isSelected: false,
    name: '',
    identifier: 'Natural Person',
  },
  {
    id: 'org-type-radio-7',
    displayName: 'Limited Liability Company',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Company',
  },
  {
    id: 'org-type-radio-8',
    displayName: 'Limited Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Partnership',
  },
  {
    id: 'org-type-radio-9',
    displayName: 'Limited Liability Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Partnership',
  },
  {
    id: 'org-type-radio-10',
    displayName: 'Nominee',
    isSelected: false,
    name: '',
    identifier: 'Nominee',
  },
  {
    id: 'org-type-radio-11',
    displayName: 'General Partnership',
    isSelected: false,
    name: '',
    identifier: 'General Partnership',
  },
  {
    id: 'org-type-radio-12',
    displayName: 'Individual Retirement Account',
    isSelected: false,
    name: '',
    identifier: 'IRA',
  },
  {
    id: 'org-type-radio-13',
    displayName: 'S-Corporation',
    isSelected: false,
    name: '',
    identifier: 'S-Corporation',
  },
  {
    id: 'org-type-radio-14',
    displayName: 'Revocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Revocable Trust',
  },
  {
    id: 'org-type-radio-15',
    displayName: 'Irrevocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Irrevocable Trust',
  },
  {
    id: 'org-type-radio-16',
    displayName: 'Business Trust',
    isSelected: false,
    name: '',
    identifier: 'Business Trust',
  },
  {
    id: 'org-type-radio-17',
    displayName: 'Joint Tenants with Right of Survivorship',
    isSelected: false,
    name: '',
    identifier: 'Joint Tenants with Right of Survivorship',
  },
  {
    id: 'org-type-radio-18',
    displayName: 'Tenants in Common',
    isSelected: false,
    name: '',
    identifier: 'Tenants in Common',
  },
  {
    id: 'org-type-radio-other-1',
    displayName: 'Other - Entity',
    isSelected: false,
    name: '',
    identifier: 'Other - Entity',
  },
  {
    id: 'org-type-radio-other-2',
    displayName: 'Other - Individual',
    isSelected: false,
    name: '',
    identifier: 'Other - Individual',
  },
];

const initialState = {
  previousInfo: false,
  additionalContactInfo: false,
  additionalDistributionInfo: false,
  name: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  signatory: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  tax: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  country: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  countryPlace: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  types: [],
  otherValue: '',
  dayBirth: '',
  monthBirth: '',
  yearBirth: '',
  namePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  emailPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  addressPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfResidence: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfResidencePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfBusiness: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfBusinessPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  jurisdictionOrganitzationPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },

  phonePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalCommitment: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required', 'number'],
  },
  reportsPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  bankName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  acctName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  swiftCode: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  ibanNumber: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  interBankAcctNumber: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  interBankAcctName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  interBankAba: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  interBankName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  interBankSwift: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  additionalComment: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  acctNumber: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  bankaba: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  furtherCredit: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  furtherCreditNumber: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  location: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  lastFocusedField: {},
  unsavedInfo: false,
  ignoreFocus: false,
};
// Export this for unit testing more easily
export class GeneralInfo extends PureComponent {
  props: Props;
  state = initialState;
  static defaultProps: {
    history: {},
    context: {},
  };

  componentDidMount() {
    notify.hideNotification();
    this.checkPreviousInformation();
    this.updateTextAnswersState();
  }

  updateTextAnswersState = () => {
    // repopulate text inputs values if they exist
    const id_investor_status_7_tcg_o_Data = this.parseQuestionDataFromId(
      iqID.id_investor_status_7_tcg_o
    );

    if (this.id_investor_status_7_tcg_o_Ref) {
      this.id_investor_status_7_tcg_o_Ref.value = id_investor_status_7_tcg_o_Data && id_investor_status_7_tcg_o_Data.answer
      ? id_investor_status_7_tcg_o_Data.description
      : ' ';
    }
  };

  mapDateOfBirthFields = (birth) => {
    if (birth && birth.length) {
      const splittedResult = birth.split('-');
      const day = splittedResult[1];
      const month = splittedResult[0];
      const year = splittedResult[2];
      return {
        dayBirth: day,
        monthBirth: month,
        yearBirth: year,
      };
    }
  };

  parseCheckboxStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );

      if (targetQuestion) {
        return [
          targetQuestion.answer === 'Yes',
          targetQuestion.answer === 'No',
        ];
      }
      return [false, false];
    }
    return [false, false];
  };

  handlerConfirm = (event) => {
    event.preventDefault();
    const answer = event.target.nextSibling.innerHTML;
    const descriptionIdentifier =
      event.target.closest('div').parentElement.parentElement.id;
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  checkPreviousInformation = () => {
    const { questionnaireContext } = this.props;
       const interestLetter = JSON.parse(sessionStorage.getItem('interestLetter') && sessionStorage.getItem('interestLetter'));
    if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo._id
    ) {
      const birthData = this.mapDateOfBirthFields(
        questionnaireContext.investorFund.generalInfo.birth
      );
      this.setState(
        {
          unsavedInfo: false,
          previousInfo: true,
           nameLetter: {
            ...this.state.nameLetter,
            value: interestLetter && interestLetter.nameLetter || questionnaireContext.investorFund.generalInfo.nameLetter,
          },
          additionalContactInfo:
              questionnaireContext.investorFund.generalInfo.additionalContactInfo ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.additionalContactInfo) ||
              false,
          additionalDistributionInfo:
          questionnaireContext.investorFund.generalInfo.additionalDistributionInfo ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.additionalDistributionInfo) ||
              false,
          ignoreFocus: questionnaireContext.lastFocusedField.ignoreFocus,
          lastFocusedField: questionnaireContext.lastFocusedField,
          ...birthData,
          types: questionnaireContext.investorFund.generalInfo.types || [],
          otherValue: questionnaireContext.investorFund.generalInfo.otherValue,
          name: {
            ...this.state.name,
            value: questionnaireContext.investorFund.generalInfo.name || '',
          },
          signatory: {
            ...this.state.signatory,
            value:
              questionnaireContext.investorFund.generalInfo.signatory || '',
          },
          tax: {
            ...this.state.tax,
            value: questionnaireContext.investorFund.generalInfo.tax || '',
          },
          country: {
            ...this.state.country,
            value: permittedCountries.includes(
              questionnaireContext.investorFund.generalInfo.country
            )
              ? questionnaireContext.investorFund.generalInfo.country
              : USA,
          },
          countryPlace: {
            ...this.state.countryPlace,
            value: permittedCountries.includes(
              questionnaireContext.investorFund.generalInfo.countryPlace
            )
              ? questionnaireContext.investorFund.generalInfo.countryPlace
              : USA,
          },
          namePrimaryContact: {
            ...this.state.namePrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .namePrimaryContact || '',
          },
          emailPrimaryContact: {
            ...this.state.emailPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .emailPrimaryContact || '',
          },
          addressPrimaryContact: {
            ...this.state.addressPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .addressPrimaryContact || '',
          },
          phonePrimaryContact: {
            ...this.state.phonePrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .phonePrimaryContact || '',
          },
          capitalCommitment: {
            ...this.state.capitalCommitment,
            value:
              questionnaireContext.investorFund.generalInfo.capitalCommitment,
          },
          principalPlaceOfResidence: {
            ...this.state.principalPlaceOfResidence,
            value:
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfResidence || '',
          },
          principalPlaceOfResidencePrimaryContact: {
            ...this.state.principalPlaceOfResidencePrimaryContact,
            value: permittedStates.includes(
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfResidencePrimaryContact
            )
              ? questionnaireContext.investorFund.generalInfo
                  .principalPlaceOfResidencePrimaryContact
              : '',
          },
          principalPlaceOfBusiness: {
            ...this.state.principalPlaceOfBusiness,
            value:
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfBusiness || '',
          },
          principalPlaceOfBusinessPrimaryContact: {
            ...this.state.principalPlaceOfBusinessPrimaryContact,
            value: permittedStates.includes(
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfBusinessPrimaryContact
            )
              ? questionnaireContext.investorFund.generalInfo
                  .principalPlaceOfBusinessPrimaryContact
              : '',
          },
          jurisdictionOrganitzationPrimaryContact: {
            ...this.state.jurisdictionOrganitzationPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .jurisdictionOrganitzationPrimaryContact || '',
          },
          reportsPrimaryContactName: {
            ...this.state.reportsPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactName || '',
          },
          reportsPrimaryContactEmail: {
            ...this.state.reportsPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactEmail || '',
          },
          reportsPrimaryContactPhone: {
            ...this.state.reportsPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactPhone || '',
          },
          reportsSecondaryContactName: {
            ...this.state.reportsSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactName || '',
          },
          reportsSecondaryContactEmail: {
            ...this.state.reportsSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactEmail || '',
          },
          reportsSecondaryContactPhone: {
            ...this.state.reportsSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactPhone || '',
          },
          taxPrimaryContactName: {
            ...this.state.taxPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactName || '',
          },
          taxPrimaryContactEmail: {
            ...this.state.taxPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactEmail || '',
          },
          taxPrimaryContactPhone: {
            ...this.state.taxPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactPhone || '',
          },
          taxSecondaryContactName: {
            ...this.state.taxSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactName || '',
          },
          taxSecondaryContactEmail: {
            ...this.state.taxSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactEmail || '',
          },
          taxSecondaryContactPhone: {
            ...this.state.taxSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactPhone || '',
          },
          capitalPrimaryContactName: {
            ...this.state.capitalPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactName || '',
          },
          capitalPrimaryContactEmail: {
            ...this.state.capitalPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactEmail || '',
          },
          capitalPrimaryContactPhone: {
            ...this.state.capitalPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactPhone || '',
          },
          capitalSecondaryContactName: {
            ...this.state.capitalSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactName || '',
          },
          capitalSecondaryContactEmail: {
            ...this.state.capitalSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactEmail || '',
          },
          capitalSecondaryContactPhone: {
            ...this.state.capitalSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactPhone || '',
          },
          stockPrimaryContactName: {
            ...this.state.stockPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactName || '',
          },
          stockPrimaryContactEmail: {
            ...this.state.stockPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactEmail || '',
          },
          stockPrimaryContactPhone: {
            ...this.state.stockPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactPhone || '',
          },
          stockSecondaryContactName: {
            ...this.state.stockSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactName || '',
          },
          stockSecondaryContactEmail: {
            ...this.state.stockSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactEmail || '',
          },
          stockSecondaryContactPhone: {
            ...this.state.stockSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactPhone || '',
          },
          legalPrimaryContactName: {
            ...this.state.legalPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactName || '',
          },
          legalPrimaryContactEmail: {
            ...this.state.legalPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactEmail || '',
          },
          legalPrimaryContactPhone: {
            ...this.state.legalPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactPhone || '',
          },
          legalSecondaryContactName: {
            ...this.state.legalSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactName || '',
          },
          legalSecondaryContactEmail: {
            ...this.state.legalSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactEmail || '',
          },
          legalSecondaryContactPhone: {
            ...this.state.legalSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactPhone || '',
          },
          bankName: {
            ...this.state.bankName,
            value:
              questionnaireContext.investorFund.generalInfo.bankName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.bankName) ||
              '',
          },
          acctName: {
            ...this.state.acctName,
            value:
              questionnaireContext.investorFund.generalInfo.acctName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.acctName) ||
              '',
          },
          swiftCode: {
            ...this.state.swiftCode,
            value:
              questionnaireContext.investorFund.generalInfo.swiftCode ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.swiftCode) ||
              '',
          },
          interBankAcctNumber: {
            ...this.state.interBankAcctNumber,
            value:
              questionnaireContext.investorFund.generalInfo.interBankAcctNumber ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.interBankAcctNumber) ||
              '',
          },
          interBankAcctName: {
            ...this.state.interBankAcctName,
            value:
              questionnaireContext.investorFund.generalInfo.interBankAcctName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.interBankAcctName) ||
              '',
          },
          interBankAba: {
            ...this.state.interBankAba,
            value:
              questionnaireContext.investorFund.generalInfo.interBankAba ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.interBankAba) ||
              '',
          },
          interBankName: {
            ...this.state.interBankName,
            value:
              questionnaireContext.investorFund.generalInfo.interBankName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.interBankName) ||
              '',
          },
          interBankSwift: {
            ...this.state.interBankSwift,
            value:
              questionnaireContext.investorFund.generalInfo.interBankSwift ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.interBankSwift) ||
              '',
          },
          additionalComment: {
            ...this.state.additionalComment,
            value:
              questionnaireContext.investorFund.generalInfo.additionalComment ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.additionalComment) ||
              '',
          },
          ibanNumber: {
            ...this.state.ibanNumber,
            value:
              questionnaireContext.investorFund.generalInfo.ibanNumber ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.ibanNumber) ||
              '',
          },
          acctNumber: {
            ...this.state.acctNumber,
            value:
              questionnaireContext.investorFund.generalInfo.acctNumber ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.acctNumber) ||
              '',
          },
          bankaba: {
            ...this.state.bankaba,
            value:
              questionnaireContext.investorFund.generalInfo.bankaba ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.bankaba) ||
              '',
          },
          furtherCredit: {
            ...this.state.furtherCredit,
            value:
              questionnaireContext.investorFund.generalInfo.furtherCredit ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner
                  .furtherCredit) ||
              '',
          },
          furtherCreditNumber: {
            ...this.state.furtherCreditNumber,
            value:
              questionnaireContext.investorFund.generalInfo.furtherCreditNumber ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.furtherCreditNumber) ||
              '',
          },
          location: {
            ...this.state.location,
            value:
              questionnaireContext.investorFund.generalInfo.location ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.location) ||
              '',
          },
        },
        () => {
          sessionStorage.setItem(
            'generalInfo',
            JSON.stringify(questionnaireContext.investorFund.generalInfo)
          );
        }
      );
    } else {
      // fixme needs to set an initial generalInfo with the initialState info
      // fixme this part can be simplified
      const templateGeneralInfo = {
        name: this.state.name.value,
        signatory: this.state.signatory.value,
        tax: this.state.tax.value,
        nameLetter: this.state.nameLetter.value,
        country: this.state.country.value || USA,
        countryPlace: this.state.countryPlace.value || USA,
        types: this.state.types,
        otherValue: this.state.otherValue,
        birth: `${this.state.monthBirth}-${this.state.dayBirth}-${this.state.yearBirth}`,
        namePrimaryContact: this.state.namePrimaryContact.value,
        emailPrimaryContact: this.state.emailPrimaryContact.value,
        addressPrimaryContact: this.state.addressPrimaryContact.value,
        phonePrimaryContact: this.state.phonePrimaryContact.value,
        // faxPrimaryContact: this.state.faxPrimaryContact.value,
        capitalCommitment: this.state.capitalCommitment.value,
        // eslint-disable-next-line
        additionalContactInfo: this.state.additionalContactInfo,
        additionalDistributionInfo: this.state.additionalDistributionInfo,
        principalPlaceOfBusiness:
          this.state.principalPlaceOfBusiness.value || '',
        principalPlaceOfBusinessPrimaryContact:
          this.state.principalPlaceOfBusinessPrimaryContact.value || '',
        // eslint-disable-next-line
        principalPlaceOfResidence:
          this.state.principalPlaceOfResidence.value || '',
        principalPlaceOfResidencePrimaryContact:
          this.state.principalPlaceOfResidencePrimaryContact.value || '',
        // eslint-disable-next-line
        jurisdictionOrganitzationPrimaryContact:
          this.state.jurisdictionOrganitzationPrimaryContact.value,
        investorFund: questionnaireContext.investorFund._id,
        reportsPrimaryContactName: this.state.reportsPrimaryContactName.value,
        reportsPrimaryContactEmail: this.state.reportsPrimaryContactEmail.value,
        reportsPrimaryContactPhone: this.state.reportsPrimaryContactPhone.value,
        reportsSecondaryContactName:
          this.state.reportsSecondaryContactName.value,
        reportsSecondaryContactEmail:
          this.state.reportsSecondaryContactEmail.value,
        reportsSecondaryContactPhone:
          this.state.reportsSecondaryContactPhone.value,
        taxPrimaryContactName: this.state.taxPrimaryContactName.value,
        taxPrimaryContactEmail: this.state.taxPrimaryContactEmail.value,
        taxPrimaryContactPhone: this.state.taxPrimaryContactPhone.value,
        taxSecondaryContactName: this.state.taxSecondaryContactName.value,
        taxSecondaryContactEmail: this.state.taxSecondaryContactEmail.value,
        taxSecondaryContactPhone: this.state.taxSecondaryContactPhone.value,
        capitalPrimaryContactName: this.state.capitalPrimaryContactName.value,
        capitalPrimaryContactEmail: this.state.capitalPrimaryContactEmail.value,
        capitalPrimaryContactPhone: this.state.capitalPrimaryContactPhone.value,
        capitalSecondaryContactName:
          this.state.capitalSecondaryContactName.value,
        capitalSecondaryContactEmail:
          this.state.capitalSecondaryContactEmail.value,
        capitalSecondaryContactPhone:
          this.state.capitalSecondaryContactPhone.value,
        stockPrimaryContactName: this.state.stockPrimaryContactName.value,
        stockPrimaryContactEmail: this.state.stockPrimaryContactEmail.value,
        stockPrimaryContactPhone: this.state.stockPrimaryContactPhone.value,
        stockSecondaryContactName: this.state.stockSecondaryContactName.value,
        stockSecondaryContactEmail: this.state.stockSecondaryContactEmail.value,
        stockSecondaryContactPhone: this.state.stockSecondaryContactPhone.value,
        legalPrimaryContactName: this.state.legalPrimaryContactName.value,
        legalPrimaryContactEmail: this.state.legalPrimaryContactEmail.value,
        legalPrimaryContactPhone: this.state.legalPrimaryContactPhone.value,
        legalSecondaryContactName: this.state.legalSecondaryContactName.value,
        legalSecondaryContactEmail: this.state.legalSecondaryContactEmail.value,
        legalSecondaryContactPhone: this.state.legalSecondaryContactPhone.value,
        bankName: this.state.bankName.value,
        acctName: this.state.acctName.value,
        swiftCode: this.state.swiftCode.value,
        ibanNumber: this.state.ibanNumber.value,
        acctNumber: this.state.acctNumber.value,
        interBankAcctNumber: this.state.interBankAcctNumber.value,
        interBankAcctName: this.state.interBankAcctName.value,
        interBankAba: this.state.interBankAba.value,
        interBankName: this.state.interBankName.value,
        interBankSwift: this.state.interBankSwift.value,
        additionalComment: this.state.additionalComment.value,
        bankaba: this.state.bankaba.value,
        furtherCredit: this.state.furtherCredit.value,
        furtherCreditNumber: this.state.furtherCreditNumber.value,
        location: this.state.location.value,
      };
      sessionStorage.setItem(
        'generalInfo',
        JSON.stringify(templateGeneralInfo)
      );
    }
  };
  // todo review this with onblur change
  handleOtherTypeValueChange = (event) => {
    event.preventDefault();
    const optionToTrack = event.target.value;
    this.setState(
      {
        ...this.state,
        otherValue: optionToTrack,
      },
      () => {
        if (sessionStorage.getItem('generalInfo')) {
          const info = JSON.parse(sessionStorage.getItem('generalInfo'));
          info.otherValue = optionToTrack;
          sessionStorage.setItem('generalInfo', JSON.stringify(info));
        }
      }
    );
  };


   renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled,
  
  ) => {
    const setCheckboxState = () => {
      if(name.includes('additionalContactInfo')) {
      const value = !this.state.additionalContactInfo
        this.setState({
        ...this.state,
        additionalContactInfo: value,
        },()=>{
            if (sessionStorage.getItem('generalInfo')) {
            const info = JSON.parse(sessionStorage.getItem('generalInfo'));
            info.additionalContactInfo = value;
            sessionStorage.setItem('generalInfo', JSON.stringify(info));
          }
        })
        }
      
      if (name.includes('additionalDistributionInfo')) {
        const value = !this.state.additionalDistributionInfo
        this.setState({
        ...this.state,
        additionalDistributionInfo: value,
        },()=>{
            if (sessionStorage.getItem('generalInfo')) {
            const info = JSON.parse(sessionStorage.getItem('generalInfo'));
            info.additionalDistributionInfo = value;
            sessionStorage.setItem('generalInfo', JSON.stringify(info));
          }
        })
      }
    }
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
    };
    
    return (
      <ConfirmCheckbox data={inputDataTemp} onChangeHandler={(e) => setCheckboxState(e)} />
    );
  };


  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      unsavedInfo: true,
      [event.target.name]: {
        ...this.state[event.target.name],
        value: event.target.value,
      },
    });
  };

  handleSaveFieldOnBlur = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem('generalInfo')) {
      const info = JSON.parse(sessionStorage.getItem('generalInfo'));
      if (event.target.name === 'capitalCommitment') {
        info[event.target.name] = this.state.capitalCommitment.value;
      }
      else {
        info[event.target.name] = event.target.value;
      }
      sessionStorage.setItem('generalInfo', JSON.stringify(info));
    }
  };
  //eslint-disable-next-line
  handleFieldFocus = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { questionnaireContext } = this.props;
    if (questionnaireContext.lastFocusedField.field !== event.target.name) {
      this.props.setLastFocusedField({ field: event.target.name });
    }
  };

  handleDateOfBirthChange = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      [`${event.target.name}Birth`]: event.target.value,
    });
  };

  parseMultiSelectStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion.answer === 'Yes';
      }
      return false;
    }
    return false;
  };

  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };

  handleTextInputBlur = (event, currentValue) => {
    event.preventDefault();
    const hasId = [
      'id_investor_status_7_tcg_o',
      'id_investor_status_7_tcg_m',
    ]
    
    const answer = event.target.value;
    const descriptionIdentifier = event.target.id;
    const tcgCross = hasId.includes(descriptionIdentifier)
   
    const current = this.parseQuestionDataFromId(descriptionIdentifier);
    if (!current || current.answer !== currentValue && !tcgCross) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
    }
    if ((!current || current.answer) && tcgCross) {
     this.props.updateMultiSelectFormAnswer(descriptionIdentifier, 'Yes', answer);
      
    }
    
  };

   categoriesOfBeneficialOwnership = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList }, x
      },
    } = this.props;
    const categoriesOfBeneficialOwnershipQuestions = questionnaireContext && 
    questionnaireContext.currentVersionQuestions.categoriesOfBeneficialOwnershipQuestions;
   
    const indexToAlpha = (num = 1) => {
      // ASCII value of first character
      const A = 'A'.charCodeAt(0);
      let numberToCharacter = number => {
          return String.fromCharCode(A + number);
      };
      return numberToCharacter(num);
    };

    const getValue = (value) => this.parseQuestionDataFromId(value) ? this.parseQuestionDataFromId(value).description : ''

    const triggerOptions = (id) =>  {
      if (id === 'id_investor_status_7_tcg_m') {
        return { value: getValue('id_investor_status_7_tcg_m'), id: `id_investor_status_7_tcg_m`, input : 'select', 
          selectOptions: ['','a','b','c', 'd','e' ,'f', 'g', 'h', 'i', 'j', 'k', 'l'], changeHandler: this.handleTextInputBlur, style: {width: '60px'} }
      } else if (id === 'id_investor_status_7_tcg_o') {
        return { id: `id_investor_status_7_tcg_o`, style: {width: '500px'}, input : 'text2', 
        reference: (_node) => this.id_investor_status_7_tcg_o_Ref =_node, 
        blurHandler: (e) => this.handleTextInputBlur(e, this.id_investor_status_7_tcg_o_Ref.value)}
                                      
      } else {
         return {}   
      }
    }

     const results = [];
     if (categoriesOfBeneficialOwnershipQuestions) {
      for (
        let i = 0;
        i < categoriesOfBeneficialOwnershipQuestions.length;
        i += 1
      ) {
        results.push({
          id: categoriesOfBeneficialOwnershipQuestions[i].id,
          displayName: ReactHtmlParser(
            categoriesOfBeneficialOwnershipQuestions[i].displayName
          ),
          name: categoriesOfBeneficialOwnershipQuestions[i].name,
          identifier: categoriesOfBeneficialOwnershipQuestions[i].identifier,
          isSelected: this.parseMultiSelectStateFromId(
            categoriesOfBeneficialOwnershipQuestions[i].id
          ),
          helpText: categoriesOfBeneficialOwnershipQuestions[i].helpText,
          showHelp: categoriesOfBeneficialOwnershipQuestions[i].showHelp,
          disabled: disabledList.includes(categoriesOfBeneficialOwnershipQuestions[i].id),
          triggerOptions: triggerOptions(categoriesOfBeneficialOwnershipQuestions[i].id),
          letter: indexToAlpha(i),
           // this part should come from top part lists
        });
      }
      return results;
    }  
  };

  orQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id !== _identifier &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // right now this is saving one by one . But ideally we should do bulks updates or at least Promise all
        const answer = 'No';
        const descriptionIdentifier = item.id;

        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };

  // send the belonging section here
  handlerSingleSelect = (event, _targetSection) => {
    if (sessionStorage.getItem('generalInfo')) {
      const info = JSON.parse(sessionStorage.getItem('generalInfo'));
      sessionStorage.setItem('generalInfo', JSON.stringify(info));
      saveGeneralInformation(info, this.props);
    }

    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    this.orQuestionSelected(
      event.target.checked,
      event.target.id,
      _targetSection
    );
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  multiCheckboxHandler = (event) => {
    event.preventDefault();
    const updateStorage = (_stateTypes) => {
      if (sessionStorage.getItem('generalInfo')) {
        const info = JSON.parse(sessionStorage.getItem('generalInfo'));
        info.types = _stateTypes;
        sessionStorage.setItem('generalInfo', JSON.stringify(info));
      }
    };
    const optionToTrack = event.target.getAttribute('data-identifier');
    // search if options is already there and remove because it means
    // its triggering again
    if (this.state.types.indexOf(optionToTrack) > -1) {
      // is in list needs to be removed
      const currentTypes = this.state.types;
      const index = currentTypes.indexOf(optionToTrack);
      currentTypes.splice(index, 1);
      this.setState(
        {
          ...this.state,
          types: currentTypes,
        },
        () => {
          updateStorage(this.state.types);
        }
      );
    } else {
      // todo figure out this saving without going to the backend
      const newTypes = [optionToTrack];
      if (
        optionToTrack === 'Natural Person' ||
        optionToTrack === 'IRA' ||
        optionToTrack === 'Joint Tenants with Right of Survivorship' ||
        optionToTrack === 'Tenants in Common' || optionToTrack === 'Other - Individual'
      ) {
        this.setState(
          {
            ...this.state,
            types: newTypes,
          },
          () => {
            updateStorage(this.state.types);
            this.props.updateMultiSelectFormAnswer(
              iqID.id_investor_status_1,
              'Yes'
            );
            this.saveAllFields();
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            types: newTypes,
          },
          () => {
            updateStorage(this.state.types);
            this.props.updateMultiSelectFormAnswer(
              iqID.id_investor_status_1,
              'No'
            );
            this.saveAllFields();
          }
        );
      }
    }
  };

  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
    /*if (document.baseURI.split('/access/home/')[1] !== 'information') {
      window.scrollTo(0, 0);
    } */
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    helpText,
    disabled,
    prefix,
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
      moneyInput: id === 'capitalCommitment',
      prefix: prefix || '$',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };

  renderTextArea = (
    name,
    id,
    displayName,
    isRequired,
    hasError,
    title,
    description,
    value,
    changeHandler,
    disabled,
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      hasError,
      title,
      description,
      value,
      disabled,
    };
    return (
      <TextArea
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };
  // eslint-disable-next-line class-methods-use-this
  renderCheckOptions = () => {
    const { questionnaireContext } = this.props;
    const info = JSON.parse(sessionStorage.getItem('generalInfo'));
    let types = [];
    if (info && info.types) {
      types = info.types;
    } else if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.types
    ) {
      types = questionnaireContext.investorFund.generalInfo.types;
    }
    const results = [];
    for (let i = 0; i < checkOptions.length; i += 1) {
      if (checkOptions[i].id !== 'org-type-radio-other') {
        results.push({
          id: checkOptions[i].id,
          displayName: checkOptions[i].displayName,
          isSelected: types && types.indexOf(checkOptions[i].identifier) > -1,
          name: checkOptions[i].name,
          identifier: checkOptions[i].identifier,
        });
      }
    }
    return results;
  };

  checkOtherValueIsSelected = () => {
    const { questionnaireContext } = this.props;
    const info = JSON.parse(sessionStorage.getItem('generalInfo'));
    let types = [];
    if (info && info.types) {
      types = info.types;
    } else if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.types
    ) {
      types = questionnaireContext.investorFund.generalInfo.types;
    }

    return types && (types.indexOf('Other - Entity') > -1 || types.indexOf('Other - Individual') > -1);
  };

  handleFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    event.preventDefault();
    this.saveAllFields();
    if (event.target.id !== 'saveLater') {
      const nextStep = questionnaireContext.currentStep + 1;
      this.props.handleRedirect(event, nextStep);
    }
  };

  saveAllFields = () => {
    const { questionnaireContext } = this.props;
    if (!questionnaireContext.savingGeneralInformation) {
      const payload = {
        name: this.state.name.value,
        nameLetter: this.state.nameLetter.value,
        signatory: this.state.signatory.value,
        tax: this.state.tax.value,
        country: this.state.country.value || USA,
        countryPlace: this.state.countryPlace.value || USA,
        types: this.state.types,
        otherValue: this.state.otherValue,
        birth: `${this.state.monthBirth}-${this.state.dayBirth}-${this.state.yearBirth}`,
        namePrimaryContact: this.state.namePrimaryContact.value,
        emailPrimaryContact: this.state.emailPrimaryContact.value,
        addressPrimaryContact: this.state.addressPrimaryContact.value,
        phonePrimaryContact: this.state.phonePrimaryContact.value,
        // faxPrimaryContact: this.state.faxPrimaryContact.value,
        capitalCommitment: this.state.capitalCommitment.value,
        // eslint-disable-next-line
        principalPlaceOfBusiness:
          this.state.principalPlaceOfBusiness.value || '',
        principalPlaceOfBusinessPrimaryContact:
          this.state.principalPlaceOfBusinessPrimaryContact.value || '',
        // eslint-disable-next-line
        principalPlaceOfResidence:
          this.state.principalPlaceOfResidence.value || '',
        principalPlaceOfResidencePrimaryContact:
          this.state.principalPlaceOfResidencePrimaryContact.value || '',
        // eslint-disable-next-line
        jurisdictionOrganitzationPrimaryContact:
          this.state.jurisdictionOrganitzationPrimaryContact.value,
        investorFund: questionnaireContext.investorFund._id,
        reportsPrimaryContactName: this.state.reportsPrimaryContactName.value,
        reportsPrimaryContactEmail: this.state.reportsPrimaryContactEmail.value,
        reportsPrimaryContactPhone: this.state.reportsPrimaryContactPhone.value,
        reportsSecondaryContactName:
          this.state.reportsSecondaryContactName.value,
        reportsSecondaryContactEmail:
          this.state.reportsSecondaryContactEmail.value,
        reportsSecondaryContactPhone:
          this.state.reportsSecondaryContactPhone.value,
        taxPrimaryContactName: this.state.taxPrimaryContactName.value,
        taxPrimaryContactEmail: this.state.taxPrimaryContactEmail.value,
        taxPrimaryContactPhone: this.state.taxPrimaryContactPhone.value,
        taxSecondaryContactName: this.state.taxSecondaryContactName.value,
        taxSecondaryContactEmail: this.state.taxSecondaryContactEmail.value,
        taxSecondaryContactPhone: this.state.taxSecondaryContactPhone.value,
        capitalPrimaryContactName: this.state.capitalPrimaryContactName.value,
        capitalPrimaryContactEmail: this.state.capitalPrimaryContactEmail.value,
        capitalPrimaryContactPhone: this.state.capitalPrimaryContactPhone.value,
        capitalSecondaryContactName:
          this.state.capitalSecondaryContactName.value,
        capitalSecondaryContactEmail:
          this.state.capitalSecondaryContactEmail.value,
        capitalSecondaryContactPhone:
          this.state.capitalSecondaryContactPhone.value,
        stockPrimaryContactName: this.state.stockPrimaryContactName.value,
        stockPrimaryContactEmail: this.state.stockPrimaryContactEmail.value,
        stockPrimaryContactPhone: this.state.stockPrimaryContactPhone.value,
        stockSecondaryContactName: this.state.stockSecondaryContactName.value,
        stockSecondaryContactEmail: this.state.stockSecondaryContactEmail.value,
        stockSecondaryContactPhone: this.state.stockSecondaryContactPhone.value,
        legalPrimaryContactName: this.state.legalPrimaryContactName.value,
        legalPrimaryContactEmail: this.state.legalPrimaryContactEmail.value,
        legalPrimaryContactPhone: this.state.legalPrimaryContactPhone.value,
        legalSecondaryContactName: this.state.legalSecondaryContactName.value,
        legalSecondaryContactEmail: this.state.legalSecondaryContactEmail.value,
        legalSecondaryContactPhone: this.state.legalSecondaryContactPhone.value,
        bankName: this.state.bankName.value,
        acctName: this.state.acctName.value,
        swiftCode: this.state.swiftCode.value,
        ibanNumber: this.state.ibanNumber.value,
        acctNumber: this.state.acctNumber.value,
        bankaba: this.state.bankaba.value,
        furtherCredit: this.state.furtherCredit.value,
        furtherCreditNumber: this.state.furtherCreditNumber.value,
        interBankAcctNumber: this.state.interBankAcctNumber.value,
        interBankAcctName: this.state.interBankAcctName.value,
        interBankAba: this.state.interBankAba.value,
        interBankName: this.state.interBankName.value,
        interBankSwift: this.state.interBankSwift.value,
        additionalComment: this.state.additionalComment.value,
        location: this.state.location.value,
        additionalContactInfo: this.state.additionalContactInfo,
        additionalDistributionInfo: this.state.additionalDistributionInfo,
      };
      if (this.state.previousInfo) {
        payload._id = questionnaireContext.investorFund.generalInfo._id;
      }
      if (questionnaireContext.multiAccess) {
        payload.limitedPartnerFund =
          questionnaireContext.investorFund._id.toString();
      } else {
        payload.investorFund = questionnaireContext.investorFund._id.toString();
      }
      saveGeneralInformation(payload, this.props);
    
    }
    
  };

  // eslint-disable-next-line
  checkIsEntity = () => {
    let flag = false;
    checkOptions.forEach((e) => {
      const checkBox = document.getElementById(e.id);
      if (
        e.id !== 'org-type-radio-6' &&
        e.id !== 'org-type-radio-12' &&
        e.id !== 'org-type-radio-17' &&
        e.id !== 'org-type-radio-18' &&
        e.id !== 'org-type-radio-other-2'
      ) {
        if (checkBox && checkBox.checked) flag = true;
      }
    });
    return flag;
  };

  // eslint-disable-next-line
  checkIsPerson = () => {
    let flag = false;
    checkOptions.forEach((e) => {
      const checkBox = document.getElementById(e.id);
      if (
        e.id === 'org-type-radio-6' ||
        e.id === 'org-type-radio-12' ||
        e.id === 'org-type-radio-17' ||
        e.id === 'org-type-radio-18' ||
        e.id === 'org-type-radio-other-2'
      ) {
        if (checkBox && checkBox.checked) flag = true;
      }
    });
    return flag;
  };

  handleCapitalChange = (maskedValue, floatValue, event) => {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        capitalCommitment: {
          ...this.state.capitalCommitment,
          value: floatValue,
        },
      },
      () => {
        if (sessionStorage.getItem('generalInfo')) {
          const info = JSON.parse(sessionStorage.getItem('generalInfo'));
          info.capitalCommitment = floatValue;
          sessionStorage.setItem('generalInfo', JSON.stringify(info));
        }
      }
    );
  };

  renderCapitalCommitment = (fund) => {
    if (!fund) return null;
    const currency = fund.currency || 'usd';


    const currencies = {
      eur: {
        displayName: 'Capital Commitment (€)',
        tooltip: `Enter amount in Euros. If you are uncertain of your allocation in the Fund,
          you may leave the Capital Commitment line blank and this item will be completed with your emailed consent after you
          submit your materials.`,
        prefix: '€',
      },
      usd: {
        displayName: 'Capital Commitment ($)',
        tooltip: `Enter amount in US dollars. If you are uncertain of your allocation in the Fund,
          you may leave the Capital Commitment line blank and this item will be completed with your emailed consent after you
          submit your materials.`,
        prefix: '$',
      },
    }

    return (
      <div className="row">
        {this.renderTextInput(
          'capitalCommitment',
          'capitalCommitment',
          'text',
          this.state.capitalCommitment.value,
          currencies[currency].displayName,
          false,
          'medium',
          !this.state.capitalCommitment.valid,
          this.state.capitalCommitment.errorMessage,
          this.handleCapitalChange,
          currencies[currency].tooltip,
          false,
          currencies[currency].prefix,
        )}
      </div>
    )
  };

  getTwoLetterStates = () => {
    const { value } = this.state.countryPlace;
    switch (value) {
      case CANADA:
        return caStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });
      case USA:
        return usStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });
      default:
        return [];
    }
  };

  render() {
       const {
      context,
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { optionals, disabled: disabledList },
      },
    } = this.props;
    const { includesFormPFQuestion } = questionnaireContext.fund;

    const readableDate =
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.updatedAt
        ? new DateTimeZone(questionnaireContext.investorFund.generalInfo.updatedAt)
        : '';
    const formattedDate = readableDate !== ''
        ? readableDate.getLocalDate()
        : readableDate;
    const toParagraph = `TO: ${questionnaireContext.fund.generalPartnerLegalName}`;
    return (
      <div>
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          saveLaterHandler={this.handleFormSubmit}
        />
        <div className="row">
        <form onSubmit={this.handleFormSubmit}>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
              <div className="content-bg main-content-area clearfix">
                <div className="row container-content-padding">
                  <Title text={questionnaireContext.fund.fundLegalName} />
                  <Title text={'Signature Package'} />
                  <br />
                  <SubTitle isLink={false} text={'INSTRUCTIONS'} underline />
                    <div>
                      <b>
                        <p>
                          Welcome to the electronic investor questionnaire for Accel XVI Entrepreneurs L.P. (the <b>“Fund”</b>). {' '}
                          Please complete this investor questionnaire by completing the General Information below, {' '}
                          reviewing and completing the Form PF Response, and executing this questionnaire. {' '}
                          If you are uncertain of your allocation in the Fund, <u>you may leave the Capital Commitment line blank</u> {' '}
                          and this item will be completed with your emailed consent after you submit your materials.
                        </p>
                        <Paragraph text={`
                          Once you are finished, this electronic investor questionnaire has been integrated with DocuSign,
                          which will allow you to designate a signatory to receive and sign the documents electronically. We
                          will automatically receive the executed documents from DocuSign. You may also download the
                          unsigned questionnaire from DocuSign for review or manual signature.
                        `} />
                      </b>
                  </div>
                  <br />
                  <Title text={'Investor Questionnaire'} />
                  <br />
                  <SubTitle isLink={false} text={toParagraph} />
                  <SubTitle isLink={false} text={'Ladies and Gentlemen'} />
                  <br />
                  <div className="paragraph-container tooltip-positioning-wrap">
                    <p>
                     In connection with the formation of {questionnaireContext.fund.fundLegalName} (the <b>”Fund”</b>), and subject 
                     to the terms and conditions of the Fund’s 
                     Amended and Restated Agreement of Limited Partnership provided herewith (the <b>“Partnership Agreement”</b>), the 
                     undersigned hereby represents that each of the responses, representations and warranties contained in the 
                     undersigned’s Accel Leaders 4 Entrepreneurs L.P. Investor Questionnaire provided in connection with the 
                     undersigned’s investment in Accel Leaders 4 Entrepreneurs L.P. and in contemplation of the undersigned’s 
                     investment in the Fund, (the undersigned’s <b>“Investor Questionnaire”</b>), is true and correct as of the date 
                     hereof. In addition, the undersigned hereby agrees that {questionnaireContext.fund.generalPartnerLegalName}, 
                     the general partner of the Fund (the <b>“General Partner”</b>) may rely on such responses, 
                     representations and warranties contained in the 
                     undersigned’s Investor Questionnaire, mutatis mutandis, to the same extent as if such Investor Questionnaire 
                     had been delivered as of the date hereof. If any of the undersigned’s responses, representations or warranties 
                     in the Investor Questionnaire ceases to be true and accurate as of the closing of the undersigned’s investment 
                     in the Fund, the undersigned shall give prompt notice of such fact to the General Partner.
                    </p>
                    <CustomTooltip
                      className={'-mt-20'}
                      data={{
                        message: `The General Partner will provide a copy of the undersigned's responses to the investor Questionnaire upon request.`,
                      }}
                    />
                  </div>
                  <div className="paragraph-container">
                    <p>
                      The General Partner reserves the right to request additional information from the undersigned in 
                      connection with its proposed investment in the Fund.
                    </p>
                  </div>
                  <br />
                  <Title text={'General Information'} />
                  <div className="row">
                    {this.renderTextInput(
                      'name',
                      'name',
                      'text',
                      this.state.name.value,
                      'Name of Limited Partner',
                      false,
                      'medium',
                      !this.state.name.valid,
                      this.state.name.errorMessage,
                      this.handleTextInputChange,
                      'Name of L.P. as it should appear in the books of the Fund',
                    )}
                  </div>
                  {this.renderCapitalCommitment(questionnaireContext.fund)}
                 {includesFormPFQuestion &&
                 
                  <div className="row">
                  <br />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <Title text={'Form PF Response'} />
                      <div className="">
                          <p>
                            Categories of Beneficial Ownership 
                            for Advisers Act Reporting: {' '}
                            The Fund’s management 
                            company or an affiliate thereof may be required,
                            pursuant to the Investment Advisers Act 
                            of 1940 (as amended, the <b>“Advisers Act”</b>), to make periodic filings on Form PF with the 
                            Securities  and Exchange  Commission.  Form PF requires disclosure of, among other information, 
                            the percentage composition of the Fund’s equity by the type of beneficial owner. 
                            The undersigned hereby represents and warrants that the undersigned is one of the following 
                            (check exactly one (1) applicable box that best describes the undersigned):      
                          </p>
                        </div>
                      </div>
                    <MultiSelect
                      data={{
                        id: 'org-types-multiselect',
                        size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                      }}
                      content={this.categoriesOfBeneficialOwnership()}
                      onChangeHandler={(e) =>
                        this.handlerSingleSelect(
                          e,
                          'categoriesOfBeneficialOwnershipQuestions'
                        )
                    } />
                  </div>}
                  <br />

                </div>
              </div>
              <br />
              <div className="next-btn">
                <Button
                  inputData={{
                    id: 'next-btn',
                    displayName: 'Next',
                    type: 'submit',
                  }}
                />
              </div>
            </div>
           </div>
          </form>
        </div>
        {/* </BlockUi> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    setLastFocusedField: (_info) =>
      dispatch({ type: IQ_SET_LAST_FOCUSED_FIELD, lastFocusedField: _info }),
    isAdditionalContactInfo: (_enableInfo) =>
      dispatch({ type: IQ_IS_ADDITIONAL_INFO, AdditionalContactInfo: _enableInfo }),
    setSavingInfo: (_saving) =>
      dispatch({
        type: IQ_IS_SAVING_GENERAL_INFORMATION,
        savingGeneralInformation: _saving,
      }),
    // new items above
  })
);

export default connector(GeneralInfo);
